import React from 'react';

import pluralize from 'pluralize';

import { Typography } from '@mui/material';

import { Order } from '@luxuryescapes/contract-svc-order';
import { CancellationPolicy, formatDate } from '@luxuryescapes/lib-refunds';

import { formatCurrency } from '~/utils/currencyFormatter';

interface Props {
  policy: CancellationPolicy['policies'][number];
  nextPolicy: CancellationPolicy['policies'][number] | undefined;
  refundIssuer: 'Vendor' | 'LE';
  room: Order.BedbankItemRoom;
  isExpired?: boolean;
  isActive?: boolean;
  propertyTimezone: string;
}

export function CancellationPoliciesText({
  policy,
  nextPolicy,
  refundIssuer,
  room,
  isExpired,
  isActive,
  propertyTimezone,
}: Props) {
  let penaltyQuantity = `${policy.nights} ${pluralize('night', policy.nights)}`;
  if (policy.amount) {
    penaltyQuantity = formatCurrency(policy.amount, policy.currency, 'en-AU', { maximumFractionDigits: 2 });
  } else if (policy.percent) {
    switch (refundIssuer) {
      case 'Vendor':
        penaltyQuantity = `${policy.percent}% (${formatCurrency(
          room.cost_price * (policy.percent / 100),
          policy.currency,
          'en-AU',
          { maximumFractionDigits: 2 },
        )})`;
        break;
      case 'LE':
        // price is the actual price paid (LuxPlus or public)
        penaltyQuantity = `${policy.percent}% (${formatCurrency(
          room.price * (policy.percent / 100),
          policy.currency,
          'en-AU',
          { maximumFractionDigits: 2 },
        )})`;
        break;
      default:
        // eslint-disable-next-line no-case-declarations
        const exhaustiveCheck: never = refundIssuer;
        throw new Error(`Unhandled refund issuer: ${exhaustiveCheck}`);
    }
  }

  /*
    Bedbank customer cancellation policy can have overlapping periods. In these case, the next policy will take precedence - aka the current policy will end when the next policy starts.
    This is why we need to check if there is a next policy and use its start date as the end date of the current policy.
    See https://aussiecommerce.atlassian.net/wiki/spaces/HOT/pages/3223552089/Unify+Customer+Cancellation+Policy
  */
  const actualPolicyEnd = nextPolicy ? nextPolicy.start : policy.end;

  return (
    <Typography sx={{ textDecoration: isExpired ? 'line-through' : undefined, color: isActive ? 'red' : undefined }}>
      After this, a{' '}
      <Typography component="span" color="red">
        {penaltyQuantity}
      </Typography>{' '}
      penalty will apply until {formatDate.formattedDateByTimezone(actualPolicyEnd, propertyTimezone)} (local time of
      property)
    </Typography>
  );
}
