import React, { useCallback, useEffect, useState } from 'react';

import { DndContext } from '@dnd-kit/core';
import { SortableContext, arrayMove } from '@dnd-kit/sortable';
import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import { Box, Button, Grid, Typography } from '@mui/material';

import cruiseLineService, { CruiseLine } from '~/services/cruises/CruiseLineService';

import CruiseLineOrderingItem from './CruiseLineOrderingItem';

const CruiseLineOrdering: React.FC = (): JSX.Element => {
  const [cruiseLines, setCruiseLines] = useState<CruiseLine[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    async function fetchData() {
      const res = await cruiseLineService.listWithPagination({
        offersApproved: true,
        take: 50,
      });
      setCruiseLines(res.result);
    }
    fetchData();
  }, []);

  const handleDragSort = useCallback(
    ({ active, over }) => {
      if (active.id !== over.id) {
        const oldIndex = cruiseLines.map((e) => e.id).indexOf(active.id);
        const newIndex = cruiseLines.map((e) => e.id).indexOf(over.id);
        const newList = arrayMove(cruiseLines, oldIndex, newIndex).map((cruiseLine, index) => ({
          ...cruiseLine,
          order: index + 1,
        }));

        setCruiseLines(newList);
      }
    },
    [cruiseLines, setCruiseLines],
  );

  const saveCruiseLines = useCallback(() => {
    const orderCruiseLines = async () => {
      const updateData = cruiseLines.map(({ id, order }) => ({ id, order }));
      try {
        await cruiseLineService.sortCruiseLines(updateData);
        enqueueSnackbar('Successfully updated', { variant: 'success' });
      } catch (error) {
        enqueueSnackbar('There was an error updating the order of cruise lines', { variant: 'error' });
      }
    };

    orderCruiseLines();
  }, [cruiseLines, enqueueSnackbar]);

  return (
    <Box px={5}>
      <Helmet>
        <title>Cruise line ordering</title>
      </Helmet>
      <Link to={'/cruises/cruise-lines'} className="btn btn-default">
        Return to cruise line
      </Link>

      <h1 className="page-header">Cruise line ordering</h1>
      <Typography variant="h6">Drag and drop to change the display order of cruise lines in the carousel</Typography>

      <DndContext onDragEnd={handleDragSort}>
        <SortableContext items={cruiseLines || []}>
          <Grid container spacing={2} mt={4}>
            {cruiseLines?.map((cruiseLine) => (
              <Grid key={cruiseLine.id} item xs={3}>
                <CruiseLineOrderingItem cruiseLine={cruiseLine} />
              </Grid>
            ))}
          </Grid>
        </SortableContext>
      </DndContext>

      <Box mt={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button variant="contained" color="success" size="large" onClick={saveCruiseLines}>
          Save cruise lines
        </Button>
      </Box>
    </Box>
  );
};

export default CruiseLineOrdering;
