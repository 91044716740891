import { CruisesContract } from '@luxuryescapes/contract-svc-cruise';

import cruisesAPI from './CruisesApiService';

const resource = 'promotions';

type GenericMessageResponse = CruisesContract.Response<CruisesContract.GenericMessageResponse>;

const createPromotionByRateCodes = async (
  params: CruisesContract.CreatePromotionByRateCodesBody,
): Promise<GenericMessageResponse> => {
  try {
    return await cruisesAPI.httpV1.post({
      resource: `${resource}/rate-codes`,
      body: {
        name: params.name,
        rates: params.rates,
        endDate: params.endDate,
        startDate: params.startDate,
        leExclusive: params.leExclusive,
        sellingPoints: params.sellingPoints,
        deposit: params.deposit,
        onBoardCredit: params.onBoardCredit,
      },
    });
  } catch (error) {
    return error;
  }
};

type Promotion = CruisesContract.Response<CruisesContract.Promotion>;

const getPromotionsById = async (promotionId: string): Promise<Promotion> => {
  return await cruisesAPI.httpV1.get({
    resource: `${resource}/${promotionId}`,
  });
};

const getPromotions = async (
  params: CruisesContract.GetPromotionsQuery,
): Promise<CruisesContract.Response<CruisesContract.Promotion[]>> => {
  const queryParams = {
    ...(params?.skip && { skip: params.skip }),
    ...(params?.take && { take: params.take }),
    ...(params?.brand && { brand: params.brand }),
    ...(params?.status && { status: params.status }),
    ...(params?.rateCodes && { rateCodes: params.rateCodes }),
    ...(params?.vendorCode && { vendorCode: params.vendorCode }),
    ...(params?.promotionName && { promotionName: params.promotionName }),
  };

  return await cruisesAPI.httpV1.get({
    resource: `${resource}`,
    paginationParams: queryParams,
  });
};

const getPromotionsByRateId = async (
  rateId: string,
): Promise<CruisesContract.Response<CruisesContract.Promotion[]>> => {
  return await cruisesAPI.httpV1.get({
    resource: `${resource}/rate-codes/${rateId}`,
  });
};

const editPromotionById = async (
  promotionId: string,
  params: Omit<CruisesContract.CreatePromotionByRateCodesBody, 'rates'>,
): Promise<Promotion> => {
  return await cruisesAPI.httpV1.patch({
    resource: `${resource}/${promotionId}`,
    body: {
      name: params.name,
      endDate: params.endDate,
      startDate: params.startDate,
      leExclusive: params.leExclusive,
      sellingPoints: params.sellingPoints,
      deposit: params.deposit,
      onBoardCredit: params.onBoardCredit,
    },
  });
};

const deletePromotionByIdOnRateId = async (promotionId: string, rateId: string): Promise<GenericMessageResponse> => {
  return await cruisesAPI.httpV1.delete({ resource: `${resource}/${promotionId}/rate-codes/${rateId}` });
};

const deletePromotionById = async (promotionId: string): Promise<GenericMessageResponse> => {
  return await cruisesAPI.httpV1.delete({ resource: `${resource}/${promotionId}` });
};

export default {
  getPromotions,
  getPromotionsById,
  editPromotionById,
  deletePromotionById,
  getPromotionsByRateId,
  createPromotionByRateCodes,
  deletePromotionByIdOnRateId,
};
