import React, { useCallback, useState } from 'react';

import { useSnackbar } from 'notistack';
import pluralize from 'pluralize';
import { useParams } from 'react-router';

import {
  Alert,
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import { useAccommodationServiceFetch } from '~/components/Accommodation/hooks/useAccommodationServiceFetch';
import { useConfirmDialog } from '~/components/Common/Providers/ConfirmDialogProvider';

import useToggleState from '~/hooks/useToggleState';

import {
  ExtendedMappedInternalRoom,
  InternalRoom,
  MappedInternalRoom,
  Room,
  RoomMappingPayload,
  RoomMappingResponse,
  createAndMapRoom,
  deleteRoomMappings,
  getRoomMappings,
  postRoomMappings,
  verifyMappedRooms,
} from '~/services/AccommodationService';

import MappedRooms from './MappedRooms';
import UnmappedRooms from './UnmappedRooms';

function AccommodationPropertyRoomMappingSection() {
  const { propertyId } = useParams<{ propertyId: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [internalRooms, setInternalRooms] = useState<Array<InternalRoom>>([]);
  const [propertyRooms, setPropertyRooms] = useState<Array<Room>>([]);

  const [updatePayload, setUpdatePayload] = useState<RoomMappingPayload>([]);
  const [removePayload, setRemovePayload] = useState<RoomMappingPayload>([]);

  const [selectedControlRoom, setSelectedControlRoom] = useState<Room>();
  const [selectedInternalRooms, setSelectedInternalRooms] = useState<Set<string>>(new Set()); // Set of internalRoomId

  const [alreadyMappedRooms, setAlreadyMappedRooms] = useState<Record<string, boolean>>({});
  const [appliedButNotSavedRooms, setAppliedButNotSavedRooms] = useState<Array<InternalRoom>>([]);

  const [verifiedRoomMappingIds, setVerifiedRoomMappingIds] = useState<Set<string>>(new Set());
  const [verificationComments, setVerificationComments] = useState('');

  const showConfirmDialog = useConfirmDialog();
  const {
    isToggled: isVerificationCommentsModalOpen,
    toggleOn: setOpenVerificationCommentsModal,
    toggleOff: setCloseVerificationCommentsModal,
  } = useToggleState(false);

  const handleRoomSelection = useCallback((room: Room) => {
    setSelectedControlRoom(room);
  }, []);

  const handleInternalRoomSelection = useCallback(
    (internalRoomId: string, selected: boolean) => {
      const update = new Set([...selectedInternalRooms]);
      if (!selected) {
        update.delete(internalRoomId);
      } else {
        update.add(internalRoomId);
      }
      setSelectedInternalRooms(update);
    },
    [selectedInternalRooms],
  );

  // Used in updating both the update and remove payloads
  const addToUpdateOrRemovePayload = useCallback(
    (payload: RoomMappingPayload, roomId, mappingsToAdd): RoomMappingPayload => {
      const newPayload = [...payload];
      mappingsToAdd.forEach((internalRoomId: string) => {
        let newMappings = newPayload.find((mapping) => mapping.roomId === roomId);
        if (!newMappings) {
          newMappings = { roomId, internalRoomIds: [internalRoomId] };
          newPayload.push(newMappings);
        } else {
          newMappings.internalRoomIds.push(internalRoomId);
        }
      });
      return newPayload;
    },
    [],
  );

  const handleUpdateMapping = useCallback(() => {
    if (!selectedControlRoom) {
      enqueueSnackbar('Please select a room from property rooms', { variant: 'error' });
      return;
    }

    if (Array.from(selectedInternalRooms.entries()).length === 0) {
      enqueueSnackbar('Please select at least one internal room', { variant: 'error' });
      return;
    }

    // update payload
    const newUpload = addToUpdateOrRemovePayload(updatePayload, selectedControlRoom.id, selectedInternalRooms);
    setUpdatePayload(newUpload);

    setAppliedButNotSavedRooms([
      ...appliedButNotSavedRooms,
      ...internalRooms.filter((room) => selectedInternalRooms.has(room.id)),
    ]);
    const newAlreadyMappedRooms = { ...alreadyMappedRooms };
    selectedInternalRooms.forEach((key) => {
      newAlreadyMappedRooms[key] = true;
    });

    setAlreadyMappedRooms(newAlreadyMappedRooms);
    // clear rooms from selection
    setSelectedInternalRooms(new Set());
    setSelectedControlRoom(undefined);
  }, [
    selectedControlRoom,
    selectedInternalRooms,
    addToUpdateOrRemovePayload,
    updatePayload,
    appliedButNotSavedRooms,
    internalRooms,
    alreadyMappedRooms,
    enqueueSnackbar,
  ]);

  const sortAndSavePropertyRooms = useCallback(
    (newPropertyRooms: Array<Room>) => {
      setPropertyRooms(newPropertyRooms.sort((a, b) => a.name.localeCompare(b.name)));
    },
    [setPropertyRooms],
  );

  const temporarilyRemoveFromPropertyRoomMappings = useCallback(
    (roomId: string, internalRoomId: string) => {
      const newPropertyRooms = [...propertyRooms];
      const roomIndex = newPropertyRooms.findIndex((room) => room.id === roomId);
      if (roomIndex === -1) {
        throw new Error(`Could not find room with id ${roomId}`);
      }
      const newMappedRooms = newPropertyRooms[roomIndex].mappedRooms.filter(
        (room) => room.internalRoomId !== internalRoomId,
      );
      newPropertyRooms[roomIndex] = { ...newPropertyRooms[roomIndex], mappedRooms: newMappedRooms };
      sortAndSavePropertyRooms(newPropertyRooms);
    },
    [propertyRooms, sortAndSavePropertyRooms],
  );

  const handleRemove = useCallback(
    (mappedRoom: ExtendedMappedInternalRoom) => {
      const remove = addToUpdateOrRemovePayload(removePayload, mappedRoom.roomId, [mappedRoom.internalRoomId]);
      setAppliedButNotSavedRooms(appliedButNotSavedRooms.filter((r) => r.id !== mappedRoom.internalRoomId));
      const newAlreadyMappedRooms = { ...alreadyMappedRooms };
      newAlreadyMappedRooms[mappedRoom.internalRoomId] = false;
      setAlreadyMappedRooms(newAlreadyMappedRooms);
      temporarilyRemoveFromPropertyRoomMappings(mappedRoom.roomId, mappedRoom.internalRoomId);
      setRemovePayload(remove);
    },
    [
      addToUpdateOrRemovePayload,
      removePayload,
      appliedButNotSavedRooms,
      alreadyMappedRooms,
      temporarilyRemoveFromPropertyRoomMappings,
    ],
  );

  const setRoomState = useCallback(
    (rooms: Array<Room>, unmappedInternalRooms: Array<InternalRoom>) => {
      sortAndSavePropertyRooms(rooms);
      const mappedInternalRooms: Array<InternalRoom> = [];
      rooms.forEach((room) => {
        room.mappedRooms.forEach((mappedRoom) => {
          mappedInternalRooms.push({
            id: mappedRoom.internalRoomId,
            internalService: mappedRoom.internalService,
            name: mappedRoom.name,
            internalServiceId: mappedRoom.internalServiceId,
            supplierRooms: mappedRoom.supplierRooms,
          });
        });
      });
      const alreadyMapped = {};
      mappedInternalRooms.forEach((room) => {
        alreadyMapped[room.id] = true;
      });
      unmappedInternalRooms.forEach((room) => {
        alreadyMapped[room.id] = false;
      });
      setAlreadyMappedRooms(alreadyMapped);
      setInternalRooms(unmappedInternalRooms);
    },
    [sortAndSavePropertyRooms],
  );

  const fetchRoomMappings = useCallback(() => getRoomMappings(propertyId), [propertyId]);

  const onFetchSuccess = useCallback(
    (result: RoomMappingResponse) => {
      setRoomState(result.rooms, result.unmappedInternalRooms);
      setIsLoading(false);
    },
    [setRoomState],
  );

  const { fetchReqState } = useAccommodationServiceFetch<RoomMappingResponse>({
    fetchFn: fetchRoomMappings,
    onSuccess: onFetchSuccess,
  });

  const clearNewMappings = useCallback(
    (room: Room) => {
      const mappingsToClear = updatePayload.find((r) => r.roomId === room.id);
      const newAlreadyMappedRooms = { ...alreadyMappedRooms };
      mappingsToClear.internalRoomIds.forEach((internalRoomId) => {
        newAlreadyMappedRooms[internalRoomId] = false;
      });
      setAlreadyMappedRooms(newAlreadyMappedRooms);
      const otherMappings = updatePayload.filter((update) => update.roomId !== room.id);
      setUpdatePayload(otherMappings);
      // Any applied but not saved rooms that is not in the update payload should be cleared (only ones in the update payload should be kept)
      setAppliedButNotSavedRooms(
        appliedButNotSavedRooms.filter((r) => otherMappings.some((mappedRoom) => mappedRoom.roomId !== r.id)),
      );
    },
    [updatePayload, alreadyMappedRooms, appliedButNotSavedRooms],
  );

  const handleSave = useCallback(async () => {
    setCloseVerificationCommentsModal();
    setIsLoading(true);
    try {
      // if verifications then show verification comments dialog
      const [updateResponse, removeResponse, verifyResponse] = await Promise.all([
        updatePayload.length > 0 && postRoomMappings(propertyId, updatePayload),
        removePayload.length > 0 && deleteRoomMappings(propertyId, removePayload),
        verifiedRoomMappingIds.size > 0 &&
          verifyMappedRooms(propertyId, {
            mappedRoomIds: [...verifiedRoomMappingIds],
            verified: true,
            verifiedComments: verificationComments,
          }),
      ]);
      if (
        updateResponse?.message === 'failed' ||
        removeResponse?.message === 'failed' ||
        verifyResponse?.message === 'failed'
      ) {
        throw new Error('Failed to save room mappings');
      }
      const { rooms, unmappedInternalRooms } = updateResponse?.result ??
        removeResponse?.result ??
        verifyResponse?.result ?? { rooms: [], unmappedInternalRooms: [] };
      setRoomState(rooms, unmappedInternalRooms);

      enqueueSnackbar('Successfully saved room mappings', { variant: 'success' });
      setUpdatePayload([]);
      setRemovePayload([]);
      setVerificationComments('');
      setVerifiedRoomMappingIds(new Set());
    } catch (error) {
      enqueueSnackbar('Failed to save room mappings', { variant: 'error' });
    }
    setIsLoading(false);
  }, [
    setCloseVerificationCommentsModal,
    updatePayload,
    propertyId,
    removePayload,
    verifiedRoomMappingIds,
    verificationComments,
    setRoomState,
    enqueueSnackbar,
  ]);

  const checkVerificationComments = useCallback(() => {
    if (Array.from(verifiedRoomMappingIds.values()).length > 0) {
      setOpenVerificationCommentsModal();
    } else {
      handleSave();
    }
  }, [verifiedRoomMappingIds, setOpenVerificationCommentsModal, handleSave]);

  const handleCreateAndMapRoom = useCallback(async () => {
    const confirmed = await showConfirmDialog({
      title: 'Create and map to new room?',
      description:
        'This will create a new room in the property and map the selected internal rooms to it. Are you sure you want to proceed?',
    });

    if (!confirmed) {
      return;
    }
    if (Array.from(selectedInternalRooms.entries()).length === 0) {
      enqueueSnackbar('Please select at least one internal room', { variant: 'error' });
      return;
    }

    setIsLoading(true);
    try {
      const response = await createAndMapRoom(propertyId, Array.from(selectedInternalRooms.values()));
      const { rooms, unmappedInternalRooms } = response.result;
      setRoomState(rooms, unmappedInternalRooms);

      enqueueSnackbar('Successfully saved new room and mapping', { variant: 'success' });
      setSelectedInternalRooms(new Set());
    } catch (error) {
      enqueueSnackbar('Failed to save room mappings', { variant: 'error' });
    }
    setIsLoading(false);
  }, [showConfirmDialog, selectedInternalRooms, enqueueSnackbar, propertyId, setRoomState]);

  const handleVerifyMappedRoom = useCallback(
    async (mappedRoom: MappedInternalRoom) => {
      const roomToUpdate = new Set([...verifiedRoomMappingIds]);
      roomToUpdate.add(mappedRoom.mappedRoomId);
      setVerifiedRoomMappingIds(roomToUpdate);
      let propertyRoomNowVerified;
      propertyRooms.forEach((room) => {
        room.mappedRooms.forEach((mr) => {
          if (mr.mappedRoomId === mappedRoom.mappedRoomId) {
            mr.verificationStatus = 'Approved';
            propertyRoomNowVerified = room;
          }
        });
      });
      const filteredRooms = [
        ...propertyRooms.filter((room) => room.id !== propertyRoomNowVerified.id),
        propertyRoomNowVerified,
      ];
      sortAndSavePropertyRooms(filteredRooms);
    },
    [propertyRooms, sortAndSavePropertyRooms, verifiedRoomMappingIds],
  );

  return (
    <>
      {fetchReqState.status === 'failed' && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {JSON.stringify(fetchReqState.error)}
        </Alert>
      )}
      <Container maxWidth="xl" disableGutters>
        <Stack direction="row" spacing={2} mb={2} justifyContent="space-between">
          <Typography variant="h6" display="flex" justifyContent="space-evenly" width="100%">
            {updatePayload.length > 0 && (
              <Alert color="success">
                {updatePayload.length} {pluralize('change', updatePayload.length)} to apply
              </Alert>
            )}
            {removePayload.length > 0 && (
              <Alert color="error">
                {removePayload.length} {pluralize('change', removePayload.length)} to apply
              </Alert>
            )}
            {updatePayload.length === 0 && removePayload.length === 0 && 'Map Internal Rooms to Property Rooms'}
          </Typography>
          {(updatePayload.length > 0 ||
            removePayload.length > 0 ||
            Array.from(verifiedRoomMappingIds.values()).length > 0) && (
            <Box justifyContent="end">
              <Button variant="contained" onClick={checkVerificationComments}>
                Save
              </Button>
            </Box>
          )}
        </Stack>
        {isLoading && (
          <Box m={2}>
            <LinearProgress />
          </Box>
        )}
        <Box display="grid" gridTemplateColumns="480px 1fr" gap={3}>
          {/* Container for unmapped Internal Rooms */}
          <Box position="relative">
            <UnmappedRooms
              selectedInternalRooms={selectedInternalRooms}
              internalRooms={internalRooms}
              alreadyMappedRooms={alreadyMappedRooms}
              appliedButNotSavedRooms={appliedButNotSavedRooms}
              handleInternalRoomSelection={handleInternalRoomSelection}
            />
          </Box>
          {/* Container for base Rooms with mappings */}
          <MappedRooms
            propertyRooms={propertyRooms}
            selectedControlRoom={selectedControlRoom}
            selectedInternalRooms={selectedInternalRooms}
            handleCreateAndMapRoom={handleCreateAndMapRoom}
            handleRoomSelection={handleRoomSelection}
            handleUpdateMapping={handleUpdateMapping}
            handleVerifyMappedRoom={handleVerifyMappedRoom}
            appliedButNotSavedRooms={appliedButNotSavedRooms}
            handleRemove={handleRemove}
            updatePayload={updatePayload}
            removePayload={removePayload}
            clearNewMappings={clearNewMappings}
          />
        </Box>
      </Container>
      <Dialog open={isVerificationCommentsModalOpen} onClose={() => setCloseVerificationCommentsModal()}>
        {isVerificationCommentsModalOpen && (
          <>
            <DialogTitle>
              You've verified {Array.from(verifiedRoomMappingIds.values()).length} room{' '}
              {pluralize('mappings', Array.from(verifiedRoomMappingIds.values()).length)}{' '}
            </DialogTitle>
            <DialogContent>
              <Typography variant="body1">
                [Optional] Provide verification comments for the selected room mappings
              </Typography>
              <TextField
                sx={{ mt: 2 }}
                label="Verification comments"
                value={verificationComments}
                type="text"
                onChange={(e) => setVerificationComments(e.target.value)}
                fullWidth
              />
            </DialogContent>
            <Stack direction="row" justifyContent="end" p={3}>
              <Button onClick={handleSave} variant="outlined" color="primary">
                Continue
              </Button>
            </Stack>
          </>
        )}
      </Dialog>
    </>
  );
}

export default AccommodationPropertyRoomMappingSection;
