import React from 'react';

import CircleIcon from '@mui/icons-material/Circle';
import { Box, Chip, Link, Tooltip } from '@mui/material';

import { ACTIVE_TYPES, OFFER_TYPE_MAP } from '~/components/Accommodation/constants';

interface Props {
  offerId: string;
  isActive: boolean;
  offerType?: string;
}

export default function InternalOfferLink({ offerId, isActive, offerType }: Props) {
  return (
    <Box display="flex" alignItems="center" gap={1}>
      {offerType && (
        <Chip
          label={OFFER_TYPE_MAP[offerType] ?? offerType}
          variant="outlined"
          color={ACTIVE_TYPES.includes(offerType) ? 'success' : 'error'}
          size="small"
        />
      )}
      <Link href={`/offers/${offerId}`} target="_blank">
        {offerId}
      </Link>
      {isActive && (
        <Tooltip title="Live">
          <CircleIcon color="success" />
        </Tooltip>
      )}
      {!isActive && (
        <Tooltip title="Inactive">
          <CircleIcon color="disabled" />
        </Tooltip>
      )}
    </Box>
  );
}
