import React from 'react';

import { Alert } from '@mui/material';

import TENANTS from '~/consts/tenant';

import useCurrentTenant from '~/hooks/useCurrentTenant';

import TenantTitleLink from './TenantTitleLink';
import { validUserTenantForSiteTenant } from './utils';

type Props = {
  user: App.User;
};

const currentUserTenant = (user: App.User): App.Tenant => {
  return TENANTS[user.tenant];
};

function TenancyWarningMessage({ user }: Props) {
  const { tenant } = useCurrentTenant();
  const userTenant = currentUserTenant(user);
  const isTenantOfCurrentSite = validUserTenantForSiteTenant(tenant.value, user.tenant);

  if (isTenantOfCurrentSite) {
    return null;
  }

  return (
    <Alert severity="warning">
      This user is a tenant of <TenantTitleLink tenant={userTenant} />, but not <strong>{tenant.title}</strong>. Please
      view the customer's account details in the site where they are a tenant to unlock additional actions.
    </Alert>
  );
}

export default TenancyWarningMessage;
