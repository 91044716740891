import React, { ComponentProps, forwardRef, useCallback, useMemo, useState } from 'react';

import { Autocomplete, FormControl, TextField, createFilterOptions } from '@mui/material';

import { getCharacterCount, getWordCount } from '~/utils/stringUtils';

const autocompleteFilter = createFilterOptions<Utils.RecordTuple<string, string>>();
const optionLabelGetter = ([, value]: Utils.RecordTuple<string, string>) => value;
const EMPTY_TUPLE: Utils.RecordTuple<string, string> = ['', ''];

interface Props {
  loading?: boolean;
  options: Array<string>;
  name: string;
  defaultValue: string;
}

const ScheduleEmailPreHeaderInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { defaultValue = '', options, loading, name } = props;
  const initialPreHeader = useMemo<Utils.RecordTuple<string, string> | undefined>(() => {
    if (defaultValue && !options.find(([key]) => key === defaultValue)) {
      return [`INITIAL_${defaultValue}`, defaultValue] as Utils.RecordTuple<string, string>;
    }
  }, [defaultValue, options]);

  const [preHeaderValue, setPreHeaderValue] = useState<Utils.RecordTuple<string, string>>(() => initialPreHeader);

  const availableOptions = useMemo(() => {
    const mappedOptions = options.map((v) => [v, v]);

    if (initialPreHeader) {
      const [, initialValue] = initialPreHeader;
      if (!options.find((value) => value === initialValue)) {
        mappedOptions.unshift(initialPreHeader);
      }
    }

    return mappedOptions;
  }, [initialPreHeader, options]);

  const handleOptionsFilter = useCallback<ComponentProps<typeof Autocomplete>['filterOptions']>(
    (options: Array<Utils.RecordTuple<string, string>>, params) => {
      const filtered = autocompleteFilter(options, params);
      const { inputValue } = params;

      const isNew = inputValue && !options.find(([, value]) => value === inputValue);
      if (isNew) {
        filtered.push([`CUSTOM_${inputValue}`, inputValue]);
      }

      return filtered;
    },
    [],
  );

  const handleChange = useCallback<ComponentProps<typeof Autocomplete>['onChange']>(
    (event, value: Utils.RecordTuple<string, string>) => {
      setPreHeaderValue(value);
    },
    [],
  );

  return (
    <FormControl variant="standard" fullWidth>
      <Autocomplete
        value={preHeaderValue ?? EMPTY_TUPLE}
        multiple={false}
        options={availableOptions}
        loading={loading}
        disabled={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            variant="standard"
            label="Pre-Header"
            helperText={`Chars: ${getCharacterCount(preHeaderValue?.[1] ?? '')} Words: ${getWordCount(
              preHeaderValue?.[1] ?? '',
            )}`}
          />
        )}
        getOptionLabel={optionLabelGetter}
        filterOptions={handleOptionsFilter}
        noOptionsText="Either type in a new value or use the prefill button get options."
        selectOnFocus
        onChange={handleChange}
      />
      <input ref={ref} type="hidden" name={name} required value={preHeaderValue?.[1] ?? ''} />
    </FormControl>
  );
});
ScheduleEmailPreHeaderInput.displayName = 'ScheduleEmailPreHeaderInput';
export default ScheduleEmailPreHeaderInput;
