import React, { useEffect, useMemo, useState } from 'react';

import { debounce } from 'lodash';

import { Autocomplete, Box, Stack, TextField, Typography } from '@mui/material';

import { CommissionOption, RuleCondition } from '~/consts/agentHub';

import { listAgencies } from '~/services/AgentHub/AgentService';

const DEFAULT_SIZE_PER_PAGE = '20';

interface Props {
  setCreatedConditions: (condition: RuleCondition, replace?: boolean) => void;
  existingConditions: RuleCondition['value'];
}

function AgentHubCommissionsAgencyInput({ setCreatedConditions, existingConditions }: Props) {
  const [loadingState, setLoadingState] = useState<Utils.FetchingState>('idle');
  const [result, setResult] = useState<CommissionOption>([]);
  const [search, setSearch] = useState('');
  const [selectedAgencies, setSelectedAgencies] = useState<CommissionOption>(existingConditions || []);
  const [noOptionsText, setNoOptionsText] = useState<string>('Start typing to search for agencies');

  const debouncedGetAgencyList = useMemo(
    () =>
      debounce((search: string) => {
        if (!search) {
          setResult([]);
          return;
        }
        setLoadingState('loading');
        listAgencies({ page: '0', pageSize: DEFAULT_SIZE_PER_PAGE, ...(search ? { search } : {}) })
          .then(({ rows, total }) => {
            if (total === 0) {
              setNoOptionsText('No agencies found');
            }
            setResult(rows.map((agency) => ({ value: agency.id, label: agency.name })));
          })
          .finally(() => setLoadingState('success'));
      }, 500),
    [],
  );

  const handleSaveCondition = () => {
    setCreatedConditions(
      {
        type: 'agency',
        value: selectedAgencies,
      },
      true,
    );
  };

  const handleInputChange = (_: React.ChangeEvent, newInputValue: string) => {
    if (!newInputValue) {
      setNoOptionsText('Start typing to search for agencies');
      return;
    }
    setSearch(newInputValue);
  };

  const handleChange = (_: React.ChangeEvent, value: CommissionOption) => {
    setSelectedAgencies(value);
  };

  useEffect(() => {
    handleSaveCondition();
  }, [selectedAgencies]);

  useEffect(() => {
    debouncedGetAgencyList(search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <Stack spacing={2}>
      <Box>
        <Autocomplete
          multiple
          loading={loadingState === 'loading'}
          options={result}
          getOptionLabel={(option) => option.label}
          defaultValue={[]}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField {...params} label="Agencies" placeholder={selectedAgencies.length > 0 ? '' : 'Agencies'} />
          )}
          onInputChange={handleInputChange}
          value={selectedAgencies}
          onChange={handleChange}
          noOptionsText={noOptionsText}
          disablePortal
        />
        <Typography variant="caption" color="textSecondary">
          Start typing to search for agencies
        </Typography>
      </Box>
    </Stack>
  );
}

export default AgentHubCommissionsAgencyInput;
