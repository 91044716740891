import React, { useCallback } from 'react';

import { WidgetProps } from '@rjsf/utils';

import { Autocomplete, FormControl, FormLabel, TextField } from '@mui/material';

function TargetPackageUpgradeWidget({ value, onChange, options, label }: WidgetProps) {
  const { enumOptions } = options;

  const handleChange = useCallback(
    (_event, newValue) => {
      return onChange(newValue);
    },
    [onChange],
  );

  return (
    <FormControl fullWidth variant="outlined">
      <FormLabel>{label}</FormLabel>
      <Autocomplete
        value={value}
        onChange={handleChange}
        options={enumOptions.map((option) => option.value)}
        renderInput={(params) => <TextField {...params} />}
        getOptionLabel={(option) => enumOptions.find((enumOption) => enumOption.value === option)?.label ?? ''}
      />
    </FormControl>
  );
}

export default TargetPackageUpgradeWidget;
