import React, { useEffect, useMemo, useState } from 'react';

import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import {
  AccessTime as AccessTimeIcon,
  Link as LinkIcon,
  Pause as PauseIcon,
  PlayArrow as PlayArrowIcon,
} from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Chip,
  ChipProps,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { DataGrid, GridColDef, GridPagination, GridPaginationModel, MuiEvent } from '@mui/x-data-grid';

import PriceParrotService from '~/services/PriceParrotService';
import { formatDateSlashesWithClock } from '~/services/TimeService';

import {
  PaginatedPriceParrotQueryParams,
  ScheduleInterval,
  ScheduledPriceSummaryJob,
  ScheduledPriceSummaryJobStatus,
} from '~/types/services/priceParrot';

import { errorToString, extractUsernameFromEmail } from '~/utils/stringUtils';

type FormInput = {
  locationUrl: string;
  scheduleInterval: ScheduleInterval;
};

type PageState = {
  loading: boolean;
  error: Error | undefined;
  gridPaginationModel: GridPaginationModel;
  rowCount: number;
};

export default function PriceParrotScheduler() {
  const [pageState, setPageState] = useState<PageState>({
    loading: false,
    error: undefined,
    gridPaginationModel: { pageSize: 100, page: 0 },
    rowCount: 0,
  });
  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting, isValid },
  } = useForm<FormInput>({
    defaultValues: {
      locationUrl: '',
      scheduleInterval: ScheduleInterval.WEEKLY,
    },
  });
  const history = useHistory();
  const [scheduledJobData, setScheduledJobData] = useState<Array<ScheduledPriceSummaryJob>>([]);
  const jobQueryParams = useMemo<PaginatedPriceParrotQueryParams>(
    () => ({
      page: pageState.gridPaginationModel.page + 1,
      limit: pageState.gridPaginationModel.pageSize,
    }),
    [pageState.gridPaginationModel],
  );
  const columns: Array<GridColDef<ScheduledPriceSummaryJob>> = [
    {
      field: 'property',
      headerName: 'Property',
      flex: 4,
      display: 'flex',
      renderCell: ({ row: { property } }) => (
        <Link
          variant="body1"
          href={property.url}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => e.stopPropagation()}
        >
          {property.name ?? property.url}
        </Link>
      ),
    },
    { field: 'scheduleInterval', headerName: 'Schedule Interval', flex: 2, display: 'flex' },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      display: 'flex',
      renderCell: ({ row: { status } }) => {
        let color: ChipProps['color'] = 'default';
        switch (status) {
          case ScheduledPriceSummaryJobStatus.ACTIVE:
            color = 'success';
            break;
          case ScheduledPriceSummaryJobStatus.PAUSED:
            color = 'warning';
            break;
        }

        return (
          <Stack direction="row">
            <Chip
              size="small"
              color={color}
              label={status.toUpperCase()}
              style={{ transition: 'background-color 0.5s ease' }}
            />
          </Stack>
        );
      },
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      flex: 2,
      display: 'flex',
      renderCell: ({ row: { createdAt, createdBy, createdByEmail } }) => (
        <Box>
          <Typography component="div">{formatDateSlashesWithClock(createdAt)}</Typography>
          {createdBy && createdByEmail && (
            <Typography component="div" sx={{ wordBreak: 'break-word' }}>
              by{' '}
              <Link href={`/users/${createdBy}`} onClick={(e) => e.stopPropagation()}>
                {extractUsernameFromEmail(createdByEmail)}
              </Link>
            </Typography>
          )}
        </Box>
      ),
    },
    {
      field: 'nextRunAt',
      headerName: 'Next Run At',
      flex: 2,
      display: 'flex',
      valueFormatter: formatDateSlashesWithClock,
    },
    {
      field: 'variations',
      headerName: 'Variations',
      flex: 1,
      display: 'flex',
      valueGetter: (value, row) => row.variations.length,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 2,
      display: 'flex',
      renderCell: ({ row }) => (
        <Stack direction="row">
          {row.status === ScheduledPriceSummaryJobStatus.ACTIVE && (
            <Tooltip title="Pause Schedule">
              <IconButton
                onClick={async (event) =>
                  await handleUpdateJobStatus(event, row, ScheduledPriceSummaryJobStatus.PAUSED)
                }
                sx={{ padding: '4px' }}
              >
                <PauseIcon />
              </IconButton>
            </Tooltip>
          )}
          {row.status === ScheduledPriceSummaryJobStatus.PAUSED && (
            <Tooltip title="Start Schedule">
              <IconButton
                onClick={async (event) =>
                  await handleUpdateJobStatus(event, row, ScheduledPriceSummaryJobStatus.ACTIVE)
                }
                sx={{ padding: '4px' }}
              >
                <PlayArrowIcon />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
      ),
    },
  ];

  const handleSubmitJob = async (data: FormInput): Promise<void> => {
    try {
      const { job } = await PriceParrotService.postScheduledPriceSummaryJob({
        ...data,
        scheduledJobVariations: [
          {
            currencyCode: 'AUD',
            countryCode: 'AU',
            numberOfNights: 3,
            offsetDays: 1,
            totalDays: 365,
          },
        ],
      });
      // If the user is on the first page, add the new job to the top of the list
      pageState.gridPaginationModel.page === 0 && setScheduledJobData((prev) => [job, ...prev]);
      reset();
    } catch (e) {
      setPageState((prev) => ({ ...prev, error: e }));
      console.error(e);
    }
  };

  const handleUpdateJobStatus = async (
    event: MuiEvent<React.MouseEvent>,
    job: ScheduledPriceSummaryJob,
    newStatus: ScheduledPriceSummaryJobStatus,
  ): Promise<void> => {
    // prevents the row onclick from firing
    event.stopPropagation();

    try {
      const { id, scheduleInterval } = job;
      await PriceParrotService.patchScheduledPriceSummaryJob(id, {
        scheduleInterval,
        status: newStatus,
      });
      setScheduledJobData((prev) => prev.map((job) => (job.id === id ? { ...job, status: newStatus } : job)));
    } catch (e) {
      setPageState((prev) => ({ ...prev, error: e }));
      console.error(e);
    }
  };

  useEffect(() => {
    setPageState((prev) => ({ ...prev, loading: true }));
    PriceParrotService.getScheduledPriceSummaryJobs(jobQueryParams)
      .then(({ data, pagination }) => {
        setScheduledJobData(data);
        setPageState((prev) => ({ ...prev, rowCount: pagination.total }));
      })
      .catch((e) => setPageState((prev) => ({ ...prev, error: e })))
      .finally(() => setPageState((prev) => ({ ...prev, loading: false })));
  }, [jobQueryParams]);

  return (
    <Box>
      <Stack direction="column" gap={2}>
        {pageState.error && (
          <Alert severity="error" onClose={() => setPageState((prev) => ({ ...prev, error: undefined }))}>
            {errorToString(pageState.error)}
          </Alert>
        )}

        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={12} sm={8}>
            <Controller
              name="locationUrl"
              rules={{ required: 'Location Link is required' }}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="Location Link"
                  disabled={isSubmitting}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LinkIcon fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="scheduleInterval"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  fullWidth
                  label="Schedule Interval"
                  disabled={isSubmitting}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccessTimeIcon fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                >
                  {Object.values(ScheduleInterval)
                    .filter((i: ScheduleInterval) => i !== ScheduleInterval.ADHOC)
                    .map((interval: ScheduleInterval) => (
                      <MenuItem key={interval} value={interval}>
                        {interval}
                      </MenuItem>
                    ))}
                </TextField>
              )}
            />
          </Grid>
        </Grid>

        <Stack direction="row" justifyContent="end" gap={1}>
          <Button variant="text" disabled={isSubmitting} onClick={() => reset()}>
            Clear Input
          </Button>
          <Button
            variant="contained"
            type="submit"
            disabled={!isValid || isSubmitting}
            onClick={handleSubmit(handleSubmitJob)}
          >
            Submit Schedule
          </Button>
        </Stack>
      </Stack>

      <DataGrid
        autoHeight
        columns={columns}
        rows={scheduledJobData}
        loading={pageState.loading}
        getRowId={(row) => row.id}
        getRowHeight={() => 'auto'}
        /* Server Side Pagination */
        rowCount={pageState.rowCount}
        pagination
        paginationMode="server"
        paginationModel={pageState.gridPaginationModel}
        onPaginationModelChange={(model) => setPageState((prev) => ({ ...prev, gridPaginationModel: model }))}
        slots={{
          pagination: GridPagination,
        }}
        disableRowSelectionOnClick
        disableColumnSorting
        disableColumnMenu
        onRowClick={(params: { row: ScheduledPriceSummaryJob }) =>
          history.push(`/price-parrot/scheduler/jobs/${params.row.id}`)
        }
      />
    </Box>
  );
}
