import React from 'react';

import { Box, Typography } from '@mui/material';

import { Order } from '@luxuryescapes/contract-svc-order';

import { RefundPolicy } from '~/components/Purchases/OrderDetail/BedbankList/RefundPolicies';
import SimpleRefundPolicies from '~/components/Purchases/OrderDetail/BedbankList/SimpleRefundPolicies';

interface Props {
  room: Order.BedbankItemRoom;
  customerRefundPolicy: RefundPolicy;
  supplierRefundPolicy: RefundPolicy | null;
  propertyTimezone: string;
  item: Order.BedbankItem;
}

export default function SimpleBedbankRefundPolicies({
  room,
  customerRefundPolicy,
  item,
  propertyTimezone,
  supplierRefundPolicy,
}: Props) {
  return (
    <>
      <Box mb={3}>
        <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
          Customer Refund Policy
        </Typography>
        <SimpleRefundPolicies
          room={room}
          refundPolicy={customerRefundPolicy}
          propertyTimezone={propertyTimezone}
          refundIssuer="LE"
          nights={item.nights}
        />
      </Box>

      {!!supplierRefundPolicy && (
        <Box>
          <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
            Supplier Refund Policy (for internal use only)
          </Typography>
          <SimpleRefundPolicies
            room={room}
            refundPolicy={supplierRefundPolicy}
            propertyTimezone={propertyTimezone}
            refundIssuer="Vendor"
            nights={item.nights}
          />
        </Box>
      )}
    </>
  );
}
