import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { TourDetailsFormResources, useTourDetailsFormQuery } from '~/queries/tours/useTourDetailsFormQueries';

import CancelIcon from '@mui/icons-material/Cancel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SaveIcon from '@mui/icons-material/Save';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Container,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { Tour } from '@luxuryescapes/contract-svc-tour';

import PageHeader from '~/components/Common/Elements/PageHeader';
import { HotelSearchForm } from '~/components/Common/Forms/HotelSearchForm';
import Spinner from '~/components/Common/Spinner';
import { getAccommodationOffersMap, getBedbankRoomsById } from '~/components/Tours/utils/accommodationAddOns';

import { OFFER_TYPE_BED_BANK } from '~/consts/offerTypes';

import SearchService, { FlashLpcOfferTypes, HotelSearchList } from '~/services/SearchService';
import { getTourById } from '~/services/ToursService';

interface Props {
  tourId: string;
}

interface Items {
  id: string;
  name?: string;
  availableRooms?: Array<{ id: string; name: string }>;
  selectedRoom?: string;
}

export default function TourAccommodationAddOns({ tourId }: Props) {
  const [isFetching, setIsFetching] = useState(true);
  const [selectedItems, setSelectedItems] = useState<Record<string, Array<Items>>>({});
  const [availableLEOffers, setAvailableLEOffers] = useState<HotelSearchList>([]);
  const [searchByIdValue] = useState<Array<string>>([]);
  const [tourOptions, setTourOptions] = useState<Array<Tour.TourOption>>([]);

  const { patch, patchRequestInstance } = useTourDetailsFormQuery(TourDetailsFormResources.ACCOMMODATION_ADD_ONS);
  const { enqueueSnackbar } = useSnackbar();

  const fetchData = async () => {
    const tourResponse = await getTourById(tourId);
    const tourOptions = tourResponse.result.tourOptions;
    setTourOptions(tourOptions);

    const savedRecommendations = tourResponse.result.recommendedHotelAddOns ?? {};
    const uniqueOfferIds = Array.from(
      new Set(
        Object.values(savedRecommendations)
          .flatMap((tourOptionItemGroups) => Object.entries(tourOptionItemGroups))
          .filter(([key]) => !key.includes('Room'))
          .flatMap(([, value]) => value),
      ),
    );
    const accommodationOffers = await getAccommodationOffersMap(uniqueOfferIds);

    const savedItems = {};
    tourOptions.forEach((to) => {
      savedItems[`${to.id}:startBedbank`] = [];
      savedItems[`${to.id}:endBedbank`] = [];
      savedItems[`${to.id}:startLE`] = [];
      savedItems[`${to.id}:endLE`] = [];
    });
    for (const [tourOptionId, recommendOffers] of Object.entries(savedRecommendations)) {
      for (const accommodationType of Object.keys(recommendOffers)) {
        if (accommodationType.includes('Room')) {
          continue;
        }
        savedItems[`${tourOptionId}:${accommodationType}`] = await Promise.all(
          savedRecommendations[tourOptionId][accommodationType]
            .map(async (id, index) => {
              const offer = accommodationOffers[id];
              if (offer) {
                const availableRooms = offer.type === OFFER_TYPE_BED_BANK ? await getBedbankRoomsById(offer.id) : [];
                const selectedRoomId = savedRecommendations[tourOptionId][`${accommodationType}Room`]?.[index];
                return {
                  id,
                  name: 'name' in offer.property ? offer.property.name : offer.name,
                  selectedRoom: selectedRoomId ?? undefined,
                  availableRooms: availableRooms.length ? availableRooms : undefined,
                };
              }
              console.error(`hotel offer not found for id: ${id} from public offers api`);
              return undefined;
            })
            .filter(Boolean),
        );
      }
    }
    setSelectedItems(savedItems);

    const offerIdsResponse = await SearchService.getHotelOffers({
      offerTypes: FlashLpcOfferTypes,
    });
    setAvailableLEOffers(offerIdsResponse.result);
    setIsFetching(false);
  };

  const offerIds = availableLEOffers.map(
    (offer) => `${offer.id} | ${offer.type === 'hotel' ? 'Flash Hotel' : offer.type}`,
  );

  useEffect(() => {
    switch (patchRequestInstance.status) {
      case 'succeeded':
        enqueueSnackbar('Tour add ons updated successfully.', {
          variant: 'success',
        });
        break;
      case 'failed':
        enqueueSnackbar(`Submission failed! ${patchRequestInstance.error}`, {
          variant: 'error',
        });
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patchRequestInstance]);

  useEffect(() => {
    fetchData().catch(console.error);
  }, []);

  const handleItemSelect = async (selection, id) => {
    const availableRooms = await getBedbankRoomsById(selection.id);
    setSelectedItems((current) => {
      const updatedItems = [
        ...(current[id] ?? []),
        {
          id: selection.id,
          name: selection.name,
          availableRooms,
        },
      ];

      return {
        ...current,
        [id]: updatedItems,
      };
    });
  };

  const handleRoomSelection = (roomId, id, index) => {
    setSelectedItems((current) => {
      const updatedItems = [...(current[id] ?? [])];
      updatedItems[index].selectedRoom = roomId === 'none' ? null : roomId;
      return {
        ...current,
        [id]: updatedItems,
      };
    });
  };

  const onSubmit = async () => {
    setIsFetching(true);

    const recommendedOffers = {};
    tourOptions.forEach((tourOption) => {
      recommendedOffers[tourOption.id] = {};
    });
    Object.keys(selectedItems).forEach((itemId) => {
      const [tourOptionId, type] = itemId.split(':');
      recommendedOffers[tourOptionId] = recommendedOffers[tourOptionId] || {};
      recommendedOffers[tourOptionId][type] = selectedItems[itemId].map((item) => item.id);
      recommendedOffers[tourOptionId][`${type}Room`] = selectedItems[itemId].map((item) => item.selectedRoom);
    });

    await patch(tourId, {
      recommendedHotelAddOns: recommendedOffers,
    });
    setIsFetching(false);
  };

  if (isFetching) {
    return <Spinner />;
  }

  const handleRemoveItem = (id, index) => {
    setSelectedItems((current) => {
      const updatedItems = current[id];
      updatedItems.splice(index, 1);

      const newObj = {
        ...current,
        [id]: updatedItems,
      };
      return newObj;
    });
  };

  const startEnd = ['start', 'end'];

  return (
    <Container maxWidth="xl">
      <PageHeader title="Tour Add Ons - Accommodation"></PageHeader>
      {tourOptions.map((tourOption, key) => (
        <Accordion defaultExpanded={key === 0}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Grid container>
              <Grid xs={12}>
                <Typography variant="h5">
                  Tour Option {key}: {tourOption.sourceTourOptionName}
                </Typography>
              </Grid>
              <Grid xs={12}>
                <Typography>
                  Starts in {tourOption.seasons[0].startLocation}, Ends in {tourOption.seasons[0].endLocation}
                </Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails sx={{ bgcolor: 'grey.100' }}>
            <Grid container spacing={2}>
              {startEnd.map((column) => (
                <Grid xs={12} md={6}>
                  <Typography variant="h5" sx={{ mt: 1, mb: 2 }}>
                    {column === 'start' ? 'Start Location' : 'End Location'}
                  </Typography>
                  <Typography variant="h6">Recommended Flash/LPC hotels</Typography>
                  <List
                    sx={{ width: '100%', maxWidth: 400, pb: 0, mb: 2, maxHeight: '25rem', overflowY: 'auto' }}
                    component="nav"
                    subheader={
                      <ListSubheader sx={{ bgcolor: 'grey.100' }}>
                        {selectedItems[`${tourOption.id}:${column}LE`].length > 0 ? '' : 'None selected.'}
                      </ListSubheader>
                    }
                  >
                    {selectedItems[`${tourOption.id}:${column}LE`]?.map((item, index) => (
                      <ListItem key={index}>
                        <IconButton
                          edge="start"
                          aria-label="delete"
                          onClick={() => handleRemoveItem(`${tourOption.id}:${column}LE`, index)}
                        >
                          <CancelIcon />
                        </IconButton>
                        <ListItemText primary={item.id} secondary={item.name} />
                      </ListItem>
                    ))}
                  </List>
                  <Typography>Add hotels</Typography>
                  <Grid container>
                    <Grid sm={12} xl={5.5}>
                      <HotelSearchForm
                        label="Search by Location or Property Name"
                        onSelect={handleItemSelect}
                        type="le"
                        id={`${tourOption.id}:${column}LE`}
                      />
                    </Grid>
                    <Grid sm={12} xl={0.5}>
                      <Typography align="center">
                        <h5>or</h5>
                      </Typography>
                    </Grid>
                    <Grid sm={12} xl={5.5}>
                      {availableLEOffers.length > 0 && (
                        <Autocomplete
                          options={offerIds}
                          value={searchByIdValue}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Search by Offer Id"
                              placeholder="Search by Offer Id"
                              sx={{ bgcolor: 'white' }}
                            />
                          )}
                          onChange={(_, values: Array<string>) => {
                            const selection = {
                              id: values[0].split(' | ')[0],
                            };
                            handleItemSelect(selection, `${tourOption.id}:${column}LE`);
                          }}
                          multiple
                        />
                      )}
                    </Grid>
                  </Grid>
                  <Box sx={{ mt: 4 }}>
                    <Typography variant="h6">Recommended Bedbank hotels</Typography>
                  </Box>
                  <List
                    sx={{ width: '100%', maxWidth: 400, pb: 0, mb: 2, maxHeight: '25rem', overflowY: 'auto' }}
                    component="nav"
                    subheader={
                      <ListSubheader sx={{ bgcolor: 'grey.100' }}>
                        {selectedItems[`${tourOption.id}:${column}Bedbank`].length > 0 ? '' : 'None selected.'}
                      </ListSubheader>
                    }
                  >
                    {selectedItems[`${tourOption.id}:${column}Bedbank`]?.map((item, index) => (
                      <ListItem key={index}>
                        <IconButton
                          edge="start"
                          aria-label="delete"
                          onClick={() => handleRemoveItem(`${tourOption.id}:${column}Bedbank`, index)}
                        >
                          <CancelIcon />
                        </IconButton>
                        <ListItemText
                          primary={item.id}
                          secondary={
                            <div>
                              <div>{item.name}</div>
                              <FormControl sx={{ minWidth: 300 }}>
                                <InputLabel>Select Room</InputLabel>
                                <Select
                                  labelId="room-selection"
                                  id="room-select-bedbank"
                                  label="Select Room"
                                  value={item.selectedRoom}
                                  placeholder="Select Room"
                                  onChange={(e) =>
                                    handleRoomSelection(e.target.value, `${tourOption.id}:${column}Bedbank`, index)
                                  }
                                >
                                  <MenuItem key="none" value="none">
                                    None
                                  </MenuItem>
                                  {item.availableRooms?.map((room, idx) => (
                                    <MenuItem value={room.id} key={idx}>
                                      {room?.name} | {room?.id}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </div>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                  <Typography>Add hotels</Typography>
                  <Grid container>
                    <Grid xs={12} xl={8}>
                      <HotelSearchForm
                        label="Search by Location, Property Name or Id"
                        type="bedbank"
                        onSelect={handleItemSelect}
                        id={`${tourOption.id}:${column}Bedbank`}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}
      <Button onClick={onSubmit} variant="contained" color="success" size="large" endIcon={<SaveIcon />} sx={{ mt: 2 }}>
        Save
      </Button>
    </Container>
  );
}
