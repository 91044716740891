import React, { useCallback, useEffect, useRef, useState } from 'react';

import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';

import LoadingButton from '@mui/lab/LoadingButton';
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Stack,
  Typography,
} from '@mui/material';

import useToggleState from '~/hooks/useToggleState';

import { Dayjs } from '~/services/TimeService';

import { EmptyArray } from '~/utils/arrayUtils';

import {
  BannerPayload,
  CallToActionPayload,
  DefaultPushPayload,
  DreamPayload,
  DynamicSectionType,
  EveningPushPayload,
  FlightPayload,
  OfferPayload,
  SectionPayload,
  TrendingDestinationPayload,
  useDynamicSections,
} from '../context/useDynamicSections';

import DynamicSection, { getAvailableSections } from './DynamicSection';
import ScheduleDeletionButton from './ScheduleDeletionButton';
import DestinationContentForm, { DestinationFormRef } from './ScheduleForms/DestinationForm';
import { DESTINATION_SEGMENT_ID } from './ScheduleForms/Inputs/ScheduleSegmentInput';
import ScheduleBannerForm, { parseScheduleBannerFormData } from './ScheduleForms/ScheduleBannerForm';
import { parseBannerSectionFormData } from './ScheduleForms/ScheduleBannerSectionForm';
import ScheduleBaseForm, { parseScheduleBaseFormData } from './ScheduleForms/ScheduleBaseForm';
import { parseCallToActionFormData } from './ScheduleForms/ScheduleCallToActionSectionForm';
import { parseDreamSectionFormData } from './ScheduleForms/ScheduleDreamSectionForm';
import ScheduleEmailContentForm, { parseScheduleEmailContentFormData } from './ScheduleForms/ScheduleEmailContentForm';
import { parseFlightSectionFormData } from './ScheduleForms/ScheduleFlightSectionForm';
import ScheduleHeroOffersForm, { parseScheduleHeroOffersFormData } from './ScheduleForms/ScheduleHeroOffersForm';
import { parseOfferSectionFormData } from './ScheduleForms/ScheduleOfferSectionForm';
import SchedulePushContentForm, { parseSchedulePushContentFormData } from './ScheduleForms/SchedulePushContentForm';
import { parseSchedulePushContentSectionFormData } from './ScheduleForms/SchedulePushContentSectionForm';
import ScheduleTestEmailModal from './ScheduleForms/ScheduleTestEmailModal';
import ScheduleTpfmForm, { parseScheduleTpfmFormData } from './ScheduleForms/ScheduleTpfmForm';
import { parseTrendingDestinationFormData } from './ScheduleForms/ScheduleTrendingDestinationSectionForm';
import SectionMenu from './SectionMenu';

// Helper functions to create blank payloads
const createBlankPayload = (sectionType: DynamicSectionType, position: number): SectionPayload => {
  const basePayload = {
    id: `${sectionType}_${Date.now()}`,
    position,
  };

  switch (sectionType) {
    case DynamicSectionType.BANNER:
      return {
        ...basePayload,
        type: DynamicSectionType.BANNER,
        bannerName: '',
        bannerUrl: '',
        desktopImageId: '',
        mobileImageId: '',
      } as BannerPayload;

    case DynamicSectionType.OFFER:
      return {
        ...basePayload,
        type: DynamicSectionType.OFFER,
        heading: '',
        offerIds: [],
      } as OfferPayload;

    case DynamicSectionType.FLIGHT:
      return {
        ...basePayload,
        type: DynamicSectionType.FLIGHT,
        heading: '',
        flights: [],
      } as FlightPayload;

    case DynamicSectionType.DREAM_ARTICLE:
      return {
        ...basePayload,
        type: DynamicSectionType.DREAM_ARTICLE,
        destination: '',
        heading: '',
        subheading: '',
        articleUrl: '',
        desktopImageId: '',
        mobileImageId: '',
      } as DreamPayload;

    case DynamicSectionType.TRENDING_DESTINATION:
      return {
        ...basePayload,
        type: DynamicSectionType.TRENDING_DESTINATION,
        heading: '',
        destinations: [],
      } as TrendingDestinationPayload;

    case DynamicSectionType.CALL_TO_ACTION:
      return {
        ...basePayload,
        type: DynamicSectionType.CALL_TO_ACTION,
        text: '',
        url: '',
      } as CallToActionPayload;

    case DynamicSectionType.DEFAULT_PUSH:
      return {
        ...basePayload,
        type: DynamicSectionType.DEFAULT_PUSH,
        pushTitle: '',
        pushSubtitle: '',
        pushMessage: '',
        pushLink: '',
        pushSvcImageId: '',
      } as DefaultPushPayload;

    case DynamicSectionType.EVENING_PUSH:
      return {
        ...basePayload,
        type: DynamicSectionType.EVENING_PUSH,
        pushTitle: '',
        pushSubtitle: '',
        pushMessage: '',
        pushLink: '',
        pushSvcImageId: '',
      } as EveningPushPayload;
    default:
      throw new Error(`Unknown section type: ${sectionType}`);
  }
};

// Helper function to create payload from schedule data
const createPayloadFromSchedule = (
  sectionType: DynamicSectionType,
  scheduleData: any,
  position: number,
): SectionPayload => {
  const basePayload = {
    id: `${sectionType}_${Date.now()}`,
    position,
  };

  switch (sectionType) {
    case DynamicSectionType.BANNER:
      return {
        ...basePayload,
        type: DynamicSectionType.BANNER,
        bannerName: scheduleData.bannerName || '',
        bannerUrl: scheduleData.bannerUrl || '',
        desktopImageId: scheduleData.desktopImageId || '',
        mobileImageId: scheduleData.mobileImageId || '',
      } as BannerPayload;

    case DynamicSectionType.OFFER:
      return {
        ...basePayload,
        type: DynamicSectionType.OFFER,
        heading: scheduleData.heading || '',
        offerIds: scheduleData.offerIds || [],
      } as OfferPayload;

    case DynamicSectionType.FLIGHT:
      return {
        ...basePayload,
        type: DynamicSectionType.FLIGHT,
        heading: scheduleData.heading || '',
        flights:
          scheduleData.flights?.map((flight: any) => ({
            departingCity: flight.departingCity || '',
            arrivalCity: flight.arrivalCity || '',
            pricePerPerson: flight.pricePerPerson || '',
            tripType: flight.tripType || '',
            url: flight.url || '',
            imageId: flight.imageId,
          })) || [],
      } as FlightPayload;

    case DynamicSectionType.DREAM_ARTICLE:
      return {
        ...basePayload,
        type: DynamicSectionType.DREAM_ARTICLE,
        destination: scheduleData.destination || '',
        heading: scheduleData.heading || '',
        subheading: scheduleData.subheading || '',
        articleUrl: scheduleData.articleUrl || '',
        desktopImageId: scheduleData.desktopImageId || '',
        mobileImageId: scheduleData.mobileImageId || '',
      } as DreamPayload;

    case DynamicSectionType.TRENDING_DESTINATION:
      return {
        ...basePayload,
        type: DynamicSectionType.TRENDING_DESTINATION,
        heading: scheduleData.heading || '',
        destinations:
          scheduleData.destinations?.map((dest: any) => ({
            name: dest.name || '',
            redirectUrl: dest.redirectUrl || '',
            imageId: dest.imageId,
          })) || [],
      } as TrendingDestinationPayload;

    case DynamicSectionType.CALL_TO_ACTION:
      return {
        ...basePayload,
        type: DynamicSectionType.CALL_TO_ACTION,
        text: scheduleData.text || '',
        url: scheduleData.url || '',
      } as CallToActionPayload;

    case DynamicSectionType.DEFAULT_PUSH:
      return {
        ...basePayload,
        type: DynamicSectionType.DEFAULT_PUSH,
        pushTitle: scheduleData.title || '',
        pushSubtitle: scheduleData.subtitle || '',
        pushMessage: scheduleData.message || '',
        pushLink: scheduleData.link || '',
        pushSvcImageId: scheduleData.imageId || '',
      } as DefaultPushPayload;

    case DynamicSectionType.EVENING_PUSH:
      return {
        ...basePayload,
        type: DynamicSectionType.EVENING_PUSH,
        pushTitle: scheduleData.title || '',
        pushSubtitle: scheduleData.subtitle || '',
        pushMessage: scheduleData.message || '',
        pushLink: scheduleData.link || '',
        pushSvcImageId: scheduleData.imageId || '',
      } as EveningPushPayload;

    default:
      throw new Error(`Unknown section type: ${sectionType}`);
  }
};

// type guard to check if a schedule is a custom schedule
function isCustomSchedule(
  schedule: CustomerCommunication.CombinedHPSchedule | CustomerCommunication.CombinedUnsavedHPSchedule,
): schedule is CustomerCommunication.CustomSchedule | CustomerCommunication.UnsavedCustomSchedule {
  return 'customSections' in schedule;
}

function parseFormData(forms: {
  baseFormData: FormData;
  heroOffersFormData: FormData;
  emailContentFormData: FormData;
  pushContentFormData?: FormData;
  tpfmFormData: FormData;
  bannerFormData: FormData;
}): CustomerCommunication.UnsavedHeroPlannerSchedule {
  const { baseFormData, heroOffersFormData, emailContentFormData, pushContentFormData, tpfmFormData, bannerFormData } =
    forms;

  let unsaved: CustomerCommunication.UnsavedHeroPlannerSchedule = {
    ...parseScheduleBaseFormData(baseFormData),
    ...parseScheduleHeroOffersFormData(heroOffersFormData),
    ...parseScheduleEmailContentFormData(emailContentFormData),
    ...parseScheduleTpfmFormData(tpfmFormData),
    ...parseScheduleBannerFormData(bannerFormData),
  };

  if (pushContentFormData) {
    unsaved = { ...unsaved, ...parseSchedulePushContentFormData(pushContentFormData) };
  }

  return unsaved;
}

type Props = {
  busy?: boolean;
  onSubmit: (schedule: CustomerCommunication.CombinedUnsavedHPSchedule) => void;
} & (
  | { editorType: 'new'; schedule: CustomerCommunication.CombinedUnsavedHPSchedule }
  | { editorType: 'existing'; schedule: CustomerCommunication.CombinedHPSchedule }
  | { editorType: 'duplicate'; schedule: CustomerCommunication.CombinedUnsavedHPSchedule }
);

interface ParsedDynamicData {
  pushSchedules?: Array<ReturnType<typeof parseSchedulePushContentFormData>>;
  additionalSections?: Array<Record<string, unknown>>;
}

function ScheduleEntityForm({ busy = false, editorType, schedule, onSubmit }: Props) {
  const history = useHistory();

  const [sendDate, setSendDate] = useState<Dayjs>(schedule.sendDate ?? '');
  const [brand, setBrand] = useState<string>(schedule.brandId ?? '');
  const [countryGroupId, setCountryGroupId] = useState<string>(schedule.countryGroupId ?? '');
  const [countryId, setCountryId] = useState<string>(schedule.countryId ?? '');
  const [offerIds, setOfferIds] = useState<Array<string>>(
    'emailHeroOfferIds' in schedule ? schedule.emailHeroOfferIds ?? EmptyArray : [],
  );
  const [isDestinationMode, setIsDestinationMode] = useState(schedule.segmentId === DESTINATION_SEGMENT_ID);

  const baseFormRef = useRef<HTMLFormElement>(null);
  const heroOffersFormRef = useRef<HTMLFormElement>(null);
  const emailContentFormRef = useRef<HTMLFormElement>(null);
  const pushContentFormRef = useRef<HTMLFormElement>(null);
  const tpfmFormRef = useRef<HTMLFormElement>(null);
  const bannerFormRef = useRef<HTMLFormElement>(null);

  const {
    isToggled: isTestEmailModalOpen,
    toggleOn: openTestEmailModal,
    toggleOff: closeTestEmailModal,
  } = useToggleState(false);

  const {
    sections,
    deleteConfirmation,
    getSectionCount,
    addSection,
    handleDeleteClick,
    handleDeleteCancel,
    handleDeleteConfirm,
    setSections,
  } = useDynamicSections();

  // Create a map of form refs for dynamic sections
  const dynamicFormRefs = useRef<Map<string, HTMLFormElement>>(new Map());

  // Initialize dynamic sections based on existing push schedules
  // Initialize dynamic sections based on existing schedule data
  const initializeDynamicSections = useCallback(() => {
    if (editorType === 'existing' || editorType === 'duplicate') {
      // Initialize custom sections if it's a custom schedule
      if ('customSections' in schedule) {
        const customSections = schedule.customSections as Array<any>;

        // Sort customSections by position
        const orderedCustomSectionsByPosition = [...customSections].sort((a, b) => a.position - b.position);

        orderedCustomSectionsByPosition.forEach((customSection, index) => {
          let sectionType: DynamicSectionType;

          switch (customSection.type) {
            case 'OFFER':
              sectionType = DynamicSectionType.OFFER;
              break;
            case 'FLIGHT':
              sectionType = DynamicSectionType.FLIGHT;
              break;
            case 'BANNER':
              sectionType = DynamicSectionType.BANNER;
              break;
            case 'DREAM_ARTICLE':
              sectionType = DynamicSectionType.DREAM_ARTICLE;
              break;
            case 'DESTINATION':
              sectionType = DynamicSectionType.TRENDING_DESTINATION;
              break;
            case 'CALL_TO_ACTION':
              sectionType = DynamicSectionType.CALL_TO_ACTION;
              break;
            default:
              return; // Skip unknown section types
          }

          const payload = createPayloadFromSchedule(sectionType, customSection, index + 1);
          addSection(sectionType, payload);
        });
      }

      // Initialize push schedules
      schedule.pushSchedules?.forEach((pushSchedule, index) => {
        if ('scheduleType' in pushSchedule) {
          let pushScheduleType: DynamicSectionType;
          if (pushSchedule.scheduleType === 'DEFAULT') {
            pushScheduleType = DynamicSectionType.DEFAULT_PUSH;
          } else if (pushSchedule.scheduleType === 'EVENING') {
            pushScheduleType = DynamicSectionType.EVENING_PUSH;
          }

          const payload = createPayloadFromSchedule(pushScheduleType, pushSchedule, index + 1);
          addSection(pushScheduleType, payload);
        }
      });
    }
  }, [editorType, schedule, addSection]);

  // Call initialization on mount
  useEffect(() => {
    initializeDynamicSections();
  }, [initializeDynamicSections]);

  const handleSubmissionClick = useCallback(() => {
    if (
      baseFormRef.current?.reportValidity() === false ||
      heroOffersFormRef.current?.reportValidity() === false ||
      emailContentFormRef.current?.reportValidity() === false ||
      pushContentFormRef.current?.reportValidity() === false ||
      bannerFormRef.current?.reportValidity() === false ||
      Array.from(dynamicFormRefs.current.values()).some((form) => form?.reportValidity() === false)
    ) {
      return;
    }

    const baseFormData = new FormData(baseFormRef.current);
    const heroOffersFormData = new FormData(heroOffersFormRef.current);
    const emailContentFormData = new FormData(emailContentFormRef.current);
    const pushContentFormData = pushContentFormRef.current ? new FormData(pushContentFormRef.current) : undefined;
    const tpfmFormData = new FormData(tpfmFormRef.current);
    const bannerFormData = new FormData(bannerFormRef.current);

    const initialData: ParsedDynamicData = {
      pushSchedules: [],
      additionalSections: [],
    };

    // Collect dynamic section form data
    const dynamicSectionData = sections
      .map((section) => {
        const formRef = dynamicFormRefs.current.get(section.id);
        if (!formRef) {
          return null;
        }

        const formData = new FormData(formRef);
        return {
          id: section.id,
          type: section.sectionId,
          position: section.position,
          formData,
        };
      })
      .filter(Boolean);

    // Parse dynamic section data based on type
    const parsedDynamicData = dynamicSectionData.reduce((acc, section) => {
      if (!section) {
        return acc;
      }

      switch (section.type) {
        case DynamicSectionType.EVENING_PUSH:
          return {
            ...acc,
            pushSchedules: [
              ...acc.pushSchedules,
              parseSchedulePushContentSectionFormData(section.formData, section.type),
            ],
          };
        default:
          return acc;
      }
    }, initialData);

    const unsaved = {
      ...parseFormData({
        baseFormData,
        heroOffersFormData,
        emailContentFormData,
        pushContentFormData,
        tpfmFormData,
        bannerFormData,
      }),
      ...parsedDynamicData,
    };

    onSubmit(unsaved);
  }, [onSubmit, sections]);

  const destinationFormRef = useRef<DestinationFormRef>(null);

  const handleDestinationSubmit = useCallback(async () => {
    if (!destinationFormRef.current) {
      return;
    }

    const formData = await destinationFormRef.current?.getFormData();
    if (!formData) {
      console.error('No form data found');
      return;
    }

    // Get base form data for common fields
    const baseFormData = new FormData(baseFormRef.current);
    const baseData = parseScheduleBaseFormData(baseFormData);

    // Structure the intro section data
    const introSection = {
      headerDesktopImage: formData.webImageId,
      headerMobileImage: formData.mobileImageId,
      introHeading: formData.introHeading,
      introCopy: formData.introCopy,
      introCta: formData.introCta,
      introCtaUrl: formData.introCtaUrl,
      headerUrl: formData.headerUrl,
    };

    // Collect and parse dynamic section form data
    const dynamicSectionData = sections
      .map((section) => {
        const formRef = dynamicFormRefs.current.get(section.id);
        if (!formRef) {
          return null;
        }
        let formData: FormData;
        if (formRef.makeFormData && typeof formRef.makeFormData === 'function') {
          formData = formRef.makeFormData();
        } else {
          formData = new FormData(formRef);
        }
        return {
          id: section.id,
          type: section.sectionId,
          position: section.position,
          formData,
        };
      })
      .filter(Boolean);

    // Parse dynamic section data based on type
    const customSections = [];
    for (const section of dynamicSectionData) {
      switch (section.type) {
        case DynamicSectionType.OFFER: {
          customSections.push(parseOfferSectionFormData(section.formData, section.position));
          break;
        }
        case DynamicSectionType.FLIGHT: {
          customSections.push(parseFlightSectionFormData(section.formData, section.position));
          break;
        }
        case DynamicSectionType.BANNER: {
          customSections.push(parseBannerSectionFormData(section.formData, section.position));
          break;
        }
        case DynamicSectionType.DREAM_ARTICLE: {
          customSections.push(parseDreamSectionFormData(section.formData, section.position));
          break;
        }
        case DynamicSectionType.TRENDING_DESTINATION: {
          customSections.push(parseTrendingDestinationFormData(section.formData, section.position));
          break;
        }
        case DynamicSectionType.CALL_TO_ACTION: {
          customSections.push(parseCallToActionFormData(section.formData, section.position));
          break;
        }
        case DynamicSectionType.DEFAULT_PUSH:
        case DynamicSectionType.EVENING_PUSH: {
          customSections.push(parseSchedulePushContentSectionFormData(section.formData, section.type));
          break;
        }
        default:
          continue;
      }
    }

    const unsaved: CustomerCommunication.UnsavedCustomSchedule = {
      ...baseData,
      type: 'DESTINATION',
      destination: formData.destinationId,
      emailSubjectLine: formData.emailSubjectLine,
      emailStatus: 'DRAFT',
      pushStatus: 'DRAFT',
      emailPreheader: formData.emailPreHeader,
      introSection,
      pushSchedules: customSections.filter((section) => section.scheduleType),
      customSections: customSections.filter((section) => !section.scheduleType),
    };

    onSubmit(unsaved);
  }, [sections, onSubmit]);

  const handleDeletion = useCallback(() => {
    history.push('/customer-communication/hero-planner/schedules');
  }, [history]);

  const handleAddSection = useCallback(
    (sectionType: DynamicSectionType) => {
      const blankPayload = createBlankPayload(sectionType, sections.length + 1);
      addSection(sectionType, blankPayload);
    },
    [addSection, sections.length],
  );

  const { enqueueSnackbar } = useSnackbar();

  const handleSectionTypeChange = useCallback(
    (sectionId: string, newType: DynamicSectionType) => {
      // Check if adding a new section of this type would exceed the limit
      const currentTypeCount = getSectionCount(newType);
      const sectionType = getAvailableSections(isDestinationMode).find((type) => type.id === newType);
      const currentSection = sections.find((section) => section.id === sectionId);

      // Don't count the current section if we're changing its type
      const adjustedCount = currentSection?.sectionId === newType ? currentTypeCount - 1 : currentTypeCount;

      if (sectionType && adjustedCount >= sectionType.limit) {
        enqueueSnackbar(`Cannot add more than ${sectionType.limit} ${sectionType.label} sections`, {
          variant: 'warning',
        });
        return;
      }

      // Update the section type and create a new blank payload for the new type
      const updatedSections = sections.map((section) => {
        if (section.id === sectionId) {
          // Create a new blank payload for the new section type
          const newPayload = createBlankPayload(newType, section.position);
          return {
            ...section,
            sectionId: newType,
            payload: newPayload,
          };
        }
        return section;
      });

      setSections(updatedSections);
    },
    [sections, getSectionCount, setSections, enqueueSnackbar, isDestinationMode],
  );

  const handlePositionChange = useCallback(
    (sectionId: string, newPosition: number) => {
      setSections((prevSections) => {
        const currentSection = prevSections.find((s) => s.id === sectionId);
        if (!currentSection) {
          return prevSections;
        }

        const currentPosition = currentSection.position;
        const maxPosition = prevSections.length;

        // Ensure newPosition is within bounds
        const targetPosition = Math.min(Math.max(1, newPosition), maxPosition);

        // Create a new array with sections in their new order
        return prevSections
          .map((section) => {
            if (section.id === sectionId) {
              // Move the target section to its new position
              return { ...section, position: targetPosition };
            } else {
              if (targetPosition > currentPosition) {
                // Moving down: shift sections up that are between old and new positions
                if (section.position <= targetPosition && section.position > currentPosition) {
                  return { ...section, position: section.position - 1 };
                }
              } else if (targetPosition < currentPosition) {
                // Moving up: shift sections down that are between new and old positions
                if (section.position >= targetPosition && section.position < currentPosition) {
                  return { ...section, position: section.position + 1 };
                }
              }
              return section;
            }
          })
          .sort((a, b) => a.position - b.position);
      });
    },
    [setSections],
  );

  const [isDragging, setIsDragging] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);

  const handleDragStart = useCallback(() => {
    setIsDragging(true);
  }, []);

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;
      setIsDragging(false);

      if (!over || active.id === over.id) {
        return;
      }

      setSections((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);

        const reorderedItems = arrayMove(items, oldIndex, newIndex);

        // Update positions after reordering
        return reorderedItems.map((item, index) => ({
          ...item,
          position: index + 1,
        }));
      });
    },
    [setSections],
  );

  return (
    <>
      <Stack direction="column" gap={3}>
        <Paper sx={{ position: 'relative' }}>
          <ScheduleBaseForm
            ref={baseFormRef}
            sendDateValue={sendDate}
            brandValue={brand}
            countryGroupIdValue={countryGroupId}
            countryIdValue={countryId}
            stateIdDefaultValue={schedule.stateId}
            cityIdDefaultValue={schedule.cityId}
            segmentIdDefaultValue={schedule.segmentId}
            cadenceIdDefaultValue={schedule.cadenceId}
            membershipIdDefaultValue={schedule.membershipId}
            onSendDateChange={setSendDate}
            onBrandChange={setBrand}
            onCountryChange={setCountryId}
            onCountryGroupChange={setCountryGroupId}
            onSegmentChange={(segment) => {
              setIsDestinationMode(segment === DESTINATION_SEGMENT_ID);
            }}
          />
          <Backdrop open={busy} sx={{ position: 'absolute' }}>
            <CircularProgress color="primary" />
          </Backdrop>
        </Paper>

        {isDestinationMode ? (
          <DestinationContentForm
            brandId={brand}
            ref={destinationFormRef}
            countryGroupId={countryGroupId}
            countryId={countryId}
            offerIds={offerIds}
            schedule={schedule as CustomerCommunication.CustomSchedule}
          />
        ) : // if condition for isCustomSchedule(schedule)
        !isCustomSchedule(schedule) ? (
          <>
            <Paper variant="outlined" sx={{ p: 2, position: 'relative' }}>
              <Stack direction="column" gap={2}>
                <Typography variant="h4">Hero Offers</Typography>

                <ScheduleHeroOffersForm
                  ref={heroOffersFormRef}
                  sendDate={sendDate}
                  brand={brand}
                  countryGroupId={countryGroupId}
                  countryId={countryId}
                  offerIds={offerIds}
                  onOfferIdsChange={setOfferIds}
                />
              </Stack>

              <Backdrop open={busy} sx={{ position: 'absolute' }}>
                <CircularProgress color="primary" />
              </Backdrop>
            </Paper>

            <Paper variant="outlined" sx={{ p: 2, position: 'relative' }}>
              <Stack direction="column" gap={2}>
                <Typography variant="h4">Top Picks For Me Email</Typography>
                <ScheduleTpfmForm ref={tpfmFormRef} isApprovedForTpfmInitial={schedule.isApprovedForTpfm} />
              </Stack>
            </Paper>

            <Paper variant="outlined" sx={{ p: 2, position: 'relative' }}>
              <Stack direction="column" gap={2}>
                <Typography variant="h4">Banners</Typography>
                <ScheduleBannerForm
                  ref={bannerFormRef}
                  defaultEmailBannerInitial={schedule.emailBannerId}
                  emailBannerPosition2Initial={schedule.emailBannerPosition2Id}
                  brand={brand}
                />
              </Stack>
            </Paper>

            <Paper variant="outlined" sx={{ p: 2, position: 'relative' }}>
              <Stack direction="column" gap={2}>
                <Typography variant="h4">Email Content</Typography>
                <ScheduleEmailContentForm
                  ref={emailContentFormRef}
                  preHeaderDefaultValue={schedule.emailPreHeader}
                  subjectLineDefaultValue={schedule.emailSubjectLine}
                  countryGroupId={countryGroupId}
                  countryId={countryId}
                  offerIds={offerIds}
                />
              </Stack>
              <Backdrop open={busy} sx={{ position: 'absolute' }}>
                <CircularProgress color="primary" />
              </Backdrop>
            </Paper>

            {brand === 'luxuryescapes' && (
              <Paper variant="outlined" sx={{ p: 2, position: 'relative' }}>
                <Stack direction="column" gap={2}>
                  <Typography variant="h4">Push Content</Typography>
                  <SchedulePushContentForm
                    ref={pushContentFormRef}
                    titleDefaultValue={schedule.pushTitle}
                    subtitleDefaultValue={schedule.pushSubtitle}
                    messageDefaultValue={schedule.pushMessage}
                    linkDefaultValue={schedule.pushLink}
                    imageIdDefaultValue={schedule.pushSvcImageId}
                    countryGroupId={countryGroupId}
                    countryId={countryId}
                    offerId={offerIds[0]}
                  />
                </Stack>
                <Backdrop open={busy} sx={{ position: 'absolute' }}>
                  <CircularProgress color="primary" />
                </Backdrop>
              </Paper>
            )}
          </>
        ) : (
          <></>
        )}

        {/* Dynamic Sections */}
        {/* Show only when there are more than 1 section */}
        {sections.length > 0 && (
          <Stack direction="row" justifyContent="flex-left">
            <Button variant="outlined" size="small" onClick={() => setIsExpanded(!isExpanded)}>
              {isExpanded ? 'Collapse All' : 'Expand All'}
            </Button>
          </Stack>
        )}

        <DndContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
          <SortableContext items={sections.map((s) => s.id)} strategy={verticalListSortingStrategy}>
            {sections.map((section) => {
              const sectionType = getAvailableSections(isDestinationMode).find((type) => type.id === section.sectionId);
              if (!sectionType) {
                return null;
              }
              return (
                <DynamicSection
                  key={section.id}
                  section={{
                    id: section.id,
                    type: section.sectionId,
                    position: section.position,
                    payload: section.payload,
                  }}
                  sectionType={sectionType}
                  onDeleteClick={handleDeleteClick}
                  onSectionTypeChange={handleSectionTypeChange}
                  onPositionChange={handlePositionChange}
                  busy={busy}
                  isGlobalDragging={isDragging}
                  isExpanded={isExpanded}
                  formRef={(el) => {
                    if (el) {
                      dynamicFormRefs.current.set(section.id, el);
                    } else {
                      dynamicFormRefs.current.delete(section.id);
                    }
                  }}
                  brand={brand}
                  countryGroupId={countryGroupId}
                  countryId={countryId}
                  sendDate={sendDate}
                  allowedSections={getAvailableSections(isDestinationMode)}
                />
              );
            })}
          </SortableContext>
        </DndContext>

        <SectionMenu
          onAddSection={handleAddSection}
          getSectionCount={getSectionCount}
          allowedSections={getAvailableSections(isDestinationMode)}
        />

        <Stack direction="row-reverse" gap={2}>
          <LoadingButton
            loading={busy}
            variant="contained"
            size="large"
            onClick={isDestinationMode ? handleDestinationSubmit : handleSubmissionClick}
          >
            {(editorType === 'new' || editorType === 'duplicate') && <span>Create new schedule</span>}
            {editorType === 'existing' && <span>Save schedule</span>}
          </LoadingButton>
          {editorType === 'existing' && (
            <Button type="button" variant="outlined" size="large" onClick={openTestEmailModal} disabled={busy}>
              Send test email
            </Button>
          )}
          {editorType === 'existing' && (
            <ScheduleDeletionButton
              scheduleId={schedule.id}
              isCustomSchedule={isDestinationMode}
              disabled={busy}
              onDelete={handleDeletion}
            />
          )}
        </Stack>
      </Stack>
      {editorType === 'existing' && (
        <ScheduleTestEmailModal
          scheduleId={schedule.id}
          baseFormRef={baseFormRef}
          emailContentFormRef={emailContentFormRef}
          open={isTestEmailModalOpen}
          onClose={closeTestEmailModal}
        />
      )}

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteConfirmation.open} onClose={handleDeleteCancel} aria-labelledby="delete-dialog-title">
        <DialogTitle id="delete-dialog-title">Delete Section</DialogTitle>
        <DialogContent>Are you sure you want to delete this section? This action cannot be undone.</DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ScheduleEntityForm;
