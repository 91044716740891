import React, { useEffect, useMemo, useState } from 'react';

import fileDownload from 'react-file-download';
import { Controller, useForm } from 'react-hook-form';

import {
  AttachMoney as AttachMoneyIcon,
  BedtimeOutlined as BedtimeOutlinedIcon,
  Circle as CircleIcon,
  Language as LanguageIcon,
  Link as LinkIcon,
} from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Chip,
  ChipOwnProps as ChipProps,
  FormControlLabel,
  Grid,
  InputAdornment,
  Link,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DataGrid, GridColDef, GridPaginationModel } from '@mui/x-data-grid';
import { DateRange, DateRangePicker, PickersShortcutsItem } from '@mui/x-date-pickers-pro';

import GridPagination from '~/components/Common/Elements/GridPagination';
import SecondsSince from '~/components/Common/SecondsSince';

import { COUNTRIES } from '~/consts/priceParrot';

import useCurrentUser from '~/hooks/useCurrentUser';
import { useEventSource, useEventSourceListener } from '~/hooks/useEventSource';

import PriceParrotService from '~/services/PriceParrotService';
import {
  Dayjs,
  diffDays,
  formatDateISO,
  formatDateLongISO,
  formatDateSlashes,
  formatDateSlashesWithClock,
  toDayJs,
} from '~/services/TimeService';

import { PriceSummaryJob, PriceSummaryJobQueryParams, PriceSummaryJobStatus } from '~/types/services/priceParrot';

import getAllCurrencyCodes from '~/utils/getAllCurrencyCodes';
import { parsePropertyNameFromTAURL } from '~/utils/priceParrot';
import { errorToString, extractUsernameFromEmail } from '~/utils/stringUtils';

import GridColActions from './GridColActions';
import PriceSummaryPreviewModal from './PriceSummaryPreviewModal';

type FormInput = {
  locationUrl: string;
  numberOfNights: number;
  dateRange: [Dayjs, Dayjs];
  currencyCode: string;
  countryCode: string;
};

type PageState = {
  loading: boolean;
  error: Error | undefined;
  gridPaginationModel: GridPaginationModel;
  rowCount: number;
  myJobsOnly: boolean;
  lastHeartbeat: string;
};

type PreviewModalState = {
  open: boolean;
  jobId?: string;
};

const DATE_FORMAT = 'DD/MM/YYYY';
const TODAY: Dayjs = toDayJs();
const DATE_RANGE_PICKER_SHORTCUT_ITEMS: Array<PickersShortcutsItem<DateRange<Dayjs>>> = [
  {
    label: 'Next Week',
    getValue: () => [TODAY, TODAY.add(1, 'week')],
  },
  {
    label: 'Next Month',
    getValue: () => [TODAY, TODAY.add(1, 'month')],
  },
  {
    label: 'Next 3 Months',
    getValue: () => [TODAY, TODAY.add(3, 'month')],
  },
  {
    label: 'Next 6 Months',
    getValue: () => [TODAY, TODAY.add(6, 'month')],
  },
  {
    label: 'Next 12 Months',
    getValue: () => [TODAY, TODAY.add(12, 'month')],
  },
];

export default function PriceParrotShopper() {
  const { user: currentUser } = useCurrentUser();
  const [pageState, setPageState] = useState<PageState>({
    loading: false,
    error: undefined,
    gridPaginationModel: { pageSize: 100, page: 0 },
    rowCount: 0,
    myJobsOnly: true,
    lastHeartbeat: formatDateLongISO(),
  });
  const [previewModalState, setPreviewModalState] = useState<PreviewModalState>({ open: false, jobId: undefined });
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { isSubmitting, isValid },
  } = useForm<FormInput>({
    defaultValues: {
      locationUrl: '',
      numberOfNights: 1,
      dateRange: [TODAY, TODAY.add(12, 'month')],
      currencyCode: 'AUD',
      countryCode: 'AU',
    },
  });
  const [jobData, setJobData] = useState<Array<PriceSummaryJob>>([]);
  const jobQueryParams = useMemo<PriceSummaryJobQueryParams>(
    () => ({
      page: pageState.gridPaginationModel.page + 1,
      limit: pageState.gridPaginationModel.pageSize,
      user_id: pageState.myJobsOnly ? currentUser?.memberId : undefined,
    }),
    [pageState.gridPaginationModel, pageState.myJobsOnly, currentUser],
  );
  const columns: Array<GridColDef<PriceSummaryJob>> = [
    {
      field: 'locationUrl',
      headerName: 'Location Url',
      flex: 3,
      renderCell: ({ row: { locationUrl } }) => (
        <Link href={locationUrl} target="_blank" rel="noopener noreferrer">
          {parsePropertyNameFromTAURL(locationUrl) ?? locationUrl}
        </Link>
      ),
    },
    { field: 'numberOfNights', headerName: 'No. of Nights', flex: 1 },
    { field: 'startDate', headerName: 'Start Date', flex: 1, valueFormatter: formatDateSlashes },
    { field: 'endDate', headerName: 'End Date', flex: 1, valueFormatter: formatDateSlashes },
    { field: 'currencyCode', headerName: 'Currency', flex: 0.75 },
    { field: 'countryCode', headerName: 'Country', flex: 0.75 },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1.5,
      display: 'flex',
      renderCell: ({ row: { status, progress, days, cancelledBy, cancelledByEmail, updatedAt, retries } }) => {
        let color: ChipProps['color'] = 'default';
        let label = '';
        let showCancelledBy = false;
        switch (status) {
          case PriceSummaryJobStatus.COMPLETED:
            color = 'success';
            break;
          case PriceSummaryJobStatus.FAILED:
            color = 'error';
            break;
          case PriceSummaryJobStatus.PENDING:
            color = 'warning';
            break;
          case PriceSummaryJobStatus.PROCESSING:
            color = 'info';
            label = `(${Math.floor((progress / days) * 100)}%) `;
            break;
          case PriceSummaryJobStatus.CANCELLED:
            color = 'error';
            showCancelledBy = true;
            break;
          case PriceSummaryJobStatus.RETRYING:
            color = 'warning';
            label = `(${retries}/3) `;
            break;
        }

        return (
          <Stack direction="row" gap={0.5} flexWrap="wrap">
            <Chip
              size="small"
              color={color}
              label={label + status.toUpperCase()}
              style={{ transition: 'background-color 0.5s ease' }}
            />
            <Typography component="div">at {formatDateSlashesWithClock(updatedAt)}</Typography>
            {showCancelledBy && cancelledBy && cancelledByEmail && (
              <Typography sx={{ wordBreak: 'break-word' }}>
                by <Link href={`/users/${cancelledBy}`}>{extractUsernameFromEmail(cancelledByEmail)}</Link>
              </Typography>
            )}
          </Stack>
        );
      },
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      flex: 1.5,
      renderCell: ({ row: { createdAt, submittedBy, submittedByEmail } }) => (
        <Box>
          <Typography component="div">{formatDateSlashesWithClock(createdAt)}</Typography>
          {submittedBy && submittedByEmail && (
            <Typography component="div" sx={{ wordBreak: 'break-word' }}>
              by <Link href={`/users/${submittedBy}`}>{extractUsernameFromEmail(submittedByEmail)}</Link>
            </Typography>
          )}
        </Box>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      display: 'flex',
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: ({ row }) => (
        <GridColActions
          job={row}
          onCancel={async () => await onCancelJob(row.jobId)}
          onDownload={async () => await onDownloadJob(row.jobId)}
          onPreview={async () => setPreviewModalState({ open: true, jobId: row.jobId })}
          onResubmit={async () =>
            await onSubmit({
              locationUrl: row.locationUrl,
              numberOfNights: row.numberOfNights,
              dateRange: [toDayJs(row.startDate), toDayJs(row.endDate)],
              currencyCode: row.currencyCode,
              countryCode: row.countryCode,
            })
          }
        />
      ),
    },
  ];

  const eventSource = useEventSource(PriceParrotService.getEventSourceURL(), true);
  useEventSourceListener(
    eventSource,
    ['update'],
    (event) => {
      const eventData: PriceSummaryJob = JSON.parse(event.data);

      // Only listen for events for the current user's jobs if we are only showing my jobs
      if (pageState.myJobsOnly && eventData.submittedBy !== currentUser?.memberId) {
        return;
      }

      setJobData((prev) => {
        const jobExists = prev.some((job) => job.jobId === eventData.jobId);
        const isFirstPage = pageState.gridPaginationModel.page === 0;
        if (jobExists) {
          return prev.map((job) => (job.jobId === eventData.jobId ? { ...job, ...eventData } : job));
        } else if (isFirstPage) {
          return [eventData, ...prev];
        }

        return prev;
      });
    },
    [setJobData, pageState.myJobsOnly, pageState.gridPaginationModel.page],
  );
  useEventSourceListener(
    eventSource,
    ['heartbeat'],
    (event) => {
      const eventData: { timestamp: string } = JSON.parse(event.data);
      setPageState((prev) => ({ ...prev, lastHeartbeat: eventData.timestamp }));
    },
    [setPageState],
  );

  useEffect(() => {
    setPageState((prev) => ({ ...prev, loading: true }));
    PriceParrotService.getPriceSummaryJobs(jobQueryParams)
      .then(({ data, pagination }) => {
        setJobData(data);
        setPageState((prev) => ({ ...prev, rowCount: pagination.total, lastHeartbeat: formatDateLongISO() }));
      })
      .catch((e) => setPageState((prev) => ({ ...prev, error: e })))
      .finally(() => setPageState((prev) => ({ ...prev, loading: false })));
  }, [jobQueryParams]);

  const onSubmit: (data: FormInput) => Promise<void> = async (data) => {
    const {
      locationUrl,
      numberOfNights,
      dateRange: [start, end],
      currencyCode,
      countryCode,
    } = data;
    const now = formatDateLongISO();
    const startDate = start.toDate();
    const endDate = end.toDate();

    try {
      const {
        result: { job_id: jobId },
      } = await PriceParrotService.postPriceSummaryJob({
        location_url: locationUrl,
        start_date: formatDateISO(startDate),
        end_date: formatDateISO(endDate),
        number_of_nights: numberOfNights,
        currency_code: currencyCode,
        country_code: countryCode,
      });
      // Only add the job to state if we are on the first page
      pageState.gridPaginationModel.page === 0 &&
        setJobData((prev) => [
          {
            jobId,
            status: PriceSummaryJobStatus.PENDING,
            createdAt: now,
            updatedAt: now,
            locationUrl,
            startDate: formatDateLongISO(startDate),
            endDate: formatDateLongISO(endDate),
            numberOfNights,
            currencyCode,
            countryCode,
            progress: 0,
            days: diffDays(startDate, endDate),
            submittedBy: currentUser.memberId,
            submittedByEmail: currentUser.email,
            cancelledBy: undefined,
            cancelledByEmail: undefined,
            retries: 0,
          },
          ...prev,
        ]);
      reset();
    } catch (e) {
      setPageState((prev) => ({ ...prev, error: e }));
      console.error(e);
    }
  };

  const onDownloadJob: (jobId: string) => Promise<void> = async (jobId) => {
    try {
      const response = await PriceParrotService.downloadPriceSummaryJob(jobId);
      const contentDisposition: string | null = response.headers.get('Content-Disposition');

      const blob = await response.blob();
      // attachment; filename="price-summary-test.csv" -> price-summary-test.csv
      const fileName = contentDisposition?.match(/filename="?([^";]+)"?/)?.[1] ?? `price-summary-${jobId}.csv`;

      fileDownload(blob, fileName);
    } catch (e) {
      setPageState((prev) => ({ ...prev, error: e }));
      console.error(e);
    }
  };

  const onCancelJob: (jobId: string) => Promise<void> = async (jobId) => {
    try {
      await PriceParrotService.cancelPriceSummaryJob(jobId);
      setJobData((prev) =>
        prev.map((job) =>
          job.jobId === jobId
            ? {
                ...job,
                status: PriceSummaryJobStatus.CANCELLED,
                cancelledBy: currentUser.memberId,
                cancelledByEmail: currentUser.email,
              }
            : job,
        ),
      );
    } catch (e) {
      setPageState((prev) => ({ ...prev, error: e }));
      console.error(e);
    }
  };

  return (
    <Box>
      <PriceSummaryPreviewModal
        open={previewModalState.open}
        onClose={() => setPreviewModalState({ open: false, jobId: undefined })}
        jobId={previewModalState.jobId}
      />

      <Stack direction="column" gap={2}>
        {pageState.error && (
          <Alert severity="error" onClose={() => setPageState((prev) => ({ ...prev, error: undefined }))}>
            {errorToString(pageState.error)}
          </Alert>
        )}

        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <Controller
              name="locationUrl"
              rules={{ required: 'Location Link is required' }}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="Location Link"
                  disabled={isSubmitting}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LinkIcon fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={4} sm={4} md={2} lg={1.5}>
            <Controller
              name="numberOfNights"
              rules={{ required: 'Location Link is required', min: { value: 1, message: 'Value must be positive' } }}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  onChange={(e) => field.onChange(+e.target.value)}
                  fullWidth
                  label="No. of Nights"
                  type="number"
                  disabled={isSubmitting}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <BedtimeOutlinedIcon fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={8} sm={8} md={4} lg={3.25}>
            <Controller
              name="dateRange"
              rules={{ required: 'Date Range is required' }}
              control={control}
              render={({ field }) => (
                <DateRangePicker
                  {...field}
                  localeText={{ start: 'Start Date', end: 'End Date' }}
                  format={DATE_FORMAT}
                  disabled={isSubmitting}
                  slotProps={{
                    shortcuts: {
                      items: DATE_RANGE_PICKER_SHORTCUT_ITEMS,
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={1.5}>
            <Controller
              name="currencyCode"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  onChange={(e) => {
                    // i.e. AUD -> AU
                    const matchedCountry: string | undefined = COUNTRIES.find(
                      (c) => c.code === e.target.value.substring(0, 2),
                    )?.code;
                    matchedCountry && setValue('countryCode', matchedCountry);
                    field.onChange(e.target.value);
                  }}
                  select
                  fullWidth
                  label="Currency"
                  disabled={isSubmitting}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AttachMoneyIcon fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                >
                  {getAllCurrencyCodes().map((currenyCode: string) => (
                    <MenuItem key={currenyCode} value={currenyCode}>
                      {currenyCode}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={1.75}>
            <Controller
              name="countryCode"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  fullWidth
                  label="Country"
                  disabled={isSubmitting}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LanguageIcon fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                >
                  {COUNTRIES.map(({ name, code }) => (
                    <MenuItem key={code} value={code}>
                      {name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={pageState.myJobsOnly}
                  onChange={(e) => setPageState((prev) => ({ ...prev, myJobsOnly: !!e.target.checked }))}
                />
              }
              label="Show my Jobs only"
            />
          </Grid>
        </Grid>

        <Stack direction="row" justifyContent="end" gap={1}>
          <Button variant="text" disabled={isSubmitting} onClick={() => reset()}>
            Clear Input
          </Button>
          <Button
            variant="contained"
            type="submit"
            disabled={!isValid || isSubmitting}
            onClick={handleSubmit(onSubmit)}
          >
            Submit Job
          </Button>
        </Stack>

        <Stack direction="row" gap={1} alignItems="center">
          <CircleIcon
            fontSize="small"
            color={eventSource?.readyState === EventSource.OPEN ? 'success' : 'error'}
            sx={
              eventSource?.readyState === EventSource.OPEN
                ? {
                    animation: 'pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite',
                    '@keyframes pulse': {
                      '0%, 100%': {
                        opacity: 1,
                      },
                      '50%': {
                        opacity: 0.5,
                      },
                    },
                  }
                : {}
            }
          />
          <Typography fontSize="small">
            Page last updated <SecondsSince startTime={pageState.lastHeartbeat} /> seconds ago
          </Typography>
        </Stack>
      </Stack>

      <DataGrid
        autoHeight
        columns={columns}
        rows={jobData}
        loading={pageState.loading}
        getRowId={(row) => row.jobId}
        getRowHeight={() => 'auto'}
        /* Server Side Pagination */
        rowCount={pageState.rowCount}
        pagination
        paginationMode="server"
        paginationModel={pageState.gridPaginationModel}
        onPaginationModelChange={(model) => setPageState((prev) => ({ ...prev, gridPaginationModel: model }))}
        slots={{
          pagination: GridPagination,
        }}
        disableRowSelectionOnClick
        disableColumnSorting
        disableColumnMenu
      />
    </Box>
  );
}
