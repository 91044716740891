import { useCallback, useEffect, useState } from 'react';

import { FlightCampaignType, getFlightCampaigns } from '~/services/FlightsService';

export function useFlightCampaigns(filters: { type?: FlightCampaignType } = {}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [data, setData] = useState<Array<App.FlightCampaign>>([]);

  const fetchCampaignsData = useCallback(async () => {
    setLoading(true);

    try {
      const { result } = await getFlightCampaigns({ type: filters.type });
      setData(result.rows);
    } catch (error) {
      setError(error);
    }

    setLoading(false);
  }, [setLoading, setData, setError, filters.type]);

  useEffect(() => {
    fetchCampaignsData();
  }, [fetchCampaignsData]);

  return { loading, error, data, revalidate: fetchCampaignsData };
}
