import React from 'react';

import { Box, Stack } from '@mui/material';

import useAccommodationPropertyDetailsContext from '~/components/Accommodation/Pages/PropertyDetails/context/useAccommodationPropertyDetailsContext';
import DefinitionText from '~/components/Common/Elements/DefinitionText';
import DefinitionTitle from '~/components/Common/Elements/DefinitionTitle';

export default function AccommodationPropertyDetailsSection() {
  const { propertyDetails } = useAccommodationPropertyDetailsContext();

  return (
    <Stack direction="column" gap={4}>
      <Box>
        <DefinitionTitle>Country</DefinitionTitle>
        <DefinitionText>{propertyDetails.country || 'Unknown'}</DefinitionText>
      </Box>
      <Box>
        <DefinitionTitle>City</DefinitionTitle>
        <DefinitionText>{propertyDetails.city || 'Unknown'}</DefinitionText>
      </Box>
      <Box>
        <DefinitionTitle>Rating</DefinitionTitle>
        <DefinitionText>{propertyDetails.rating}</DefinitionText>
      </Box>
    </Stack>
  );
}
