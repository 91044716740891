import React, { useMemo } from 'react';

import {
  DataGridPro,
  GridCallbackDetails,
  GridColDef,
  GridFilterModel,
  GridSortModel,
  getGridDateOperators,
  getGridStringOperators,
} from '@mui/x-data-grid-pro';

import { formatDateShort } from '~/services/TimeService';

import { FlightDealsTableActionButtons } from './FlightDealsTableActionButtons';

interface FlightDealsTableProps {
  isLoading: boolean;
  onPageChange: (page: number) => void;
  total: number;
  page: number;
  records: Array<App.FlightDeal>;
  onDelete: (id: string) => void;
  onFilterChange: (model: GridFilterModel, details: GridCallbackDetails<'filter'>) => void;
  onSortChange: (model: GridSortModel, details: GridCallbackDetails) => void;
  activeFilters: GridFilterModel | undefined;
  activeSorts: GridSortModel | undefined;
  pageSize?: number;
}

export function FlightDealsTable({
  isLoading,
  onPageChange,
  total,
  page,
  records,
  onDelete,
  onFilterChange,
  onSortChange,
  activeFilters,
  activeSorts,
  pageSize = 20,
}: FlightDealsTableProps) {
  const COLUMNS: Array<GridColDef<App.FlightDeal>> = useMemo(() => {
    const commonColumnProps = {
      minWidth: 120,
      flex: 1,
    };

    return [
      {
        ...commonColumnProps,
        field: 'deal_name',
        headerName: 'Deal Name',
        renderCell: ({ row }) => getName(row.dealName),
        filterOperators: stringFilterOperators,
      },
      {
        ...commonColumnProps,
        field: 'category_group',
        headerName: 'Category',
        filterOperators: stringFilterOperators,
      },
      {
        ...commonColumnProps,
        field: 'region',
        headerName: 'Region',
        filterOperators: stringFilterOperators,
      },
      {
        ...commonColumnProps,
        field: 'fare_type',
        headerName: 'Type',
        filterOperators: stringFilterOperators,
      },
      {
        ...commonColumnProps,
        field: 'carrier_code',
        headerName: 'Airline Code',
        filterOperators: stringFilterOperators,
      },
      {
        ...commonColumnProps,
        field: 'route',
        headerName: 'Route',
        renderCell: ({ row }) => formatRoute(row),
        filterOperators: stringFilterOperators,
      },
      {
        ...commonColumnProps,
        field: 'sales_period',
        headerName: 'Sales Period',
        renderCell: ({ row }) => formatSalesPeriod(row),
        filterOperators: dateFilterOperators,
      },
      {
        ...commonColumnProps,
        field: 'travel_period',
        headerName: 'Travel Period',
        renderCell: ({ row }) => formatTravelPeriod(row),
        filterOperators: dateFilterOperators,
      },
      {
        ...commonColumnProps,
        field: 'fare_class',
        headerName: 'Fare Class',
        filterOperators: stringFilterOperators,
      },
      {
        field: 'actions',
        headerName: 'Actions',
        renderCell: ({ row }) => <FlightDealsTableActionButtons row={row} onDelete={onDelete} />,
        sortable: false,
      },
    ];
  }, [onDelete]);

  return (
    <DataGridPro
      autoHeight
      columns={COLUMNS}
      loading={isLoading}
      onPaginationModelChange={({ page }) => onPageChange(page)}
      pageSizeOptions={[10]}
      filterMode="server"
      onFilterModelChange={onFilterChange}
      sortingMode="server"
      onSortModelChange={onSortChange}
      sortModel={activeSorts}
      filterModel={activeFilters}
      pagination
      paginationMode="server"
      paginationModel={{
        page: page,
        pageSize,
      }}
      initialState={{ pinnedColumns: { right: ['actions'] } }}
      rows={records}
      rowCount={total}
      density="standard"
      disableRowSelectionOnClick
    />
  );
}

function getDate(val: string) {
  return formatDateShort(val);
}

function getName(val: string) {
  return val?.length > 20 ? val.substring(0, 20) + '...' : val;
}

function formatRoute(row): string {
  return `${row.origin_airport_code} - ${row.destination_airport_code}`;
}

function formatSalesPeriod(row): string {
  return `${getDate(row.sales_start_date)} - ${getDate(row.sales_end_date)}`;
}

function formatTravelPeriod(row) {
  const travelPeriods = [1, 2, 3]
    .map((i) => ({
      start: row[`departure_date_${i}`],
      end: row[`arrival_date_${i}`],
    }))
    .filter((period) => period.start && period.end);

  return (
    <>
      {travelPeriods.map((period, index) => (
        <React.Fragment key={index}>
          {index > 0 && ', '}
          {getDate(period.start)} - {getDate(period.end)}
        </React.Fragment>
      ))}
    </>
  );
}

const stringFilterOperators = getGridStringOperators().filter((operator) => operator.value === 'contains');

const dateFilterOperators = getGridDateOperators().filter(
  (operator) => operator.value === 'onOrAfter' || operator.value === 'onOrBefore',
);
