import React from 'react';

import { Dialog, DialogContent, DialogTitle } from '@mui/material';

import MappingLogTable from '~/components/Accommodation/Pages/PropertyDetails/sections/AccommodationPropertyRoomMapping/MappingLogTable';

interface Props {
  isMappingLogModalOpen: boolean;
  setCloseMappingLogModal: () => void;
  logsTargetId?: string;
  logsSourceId?: string;
  type: 'room' | 'property';
}

export default function MappingLogModal({
  isMappingLogModalOpen,
  setCloseMappingLogModal,
  logsTargetId,
  logsSourceId,
  type,
}: Props) {
  return (
    <Dialog
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            maxWidth: '100%',
          },
        },
      }}
      open={isMappingLogModalOpen}
      onClose={() => setCloseMappingLogModal()}
    >
      {isMappingLogModalOpen && (
        <>
          <DialogTitle>Mapping Logs</DialogTitle>
          <DialogContent>
            <MappingLogTable type={type} targetId={logsTargetId} sourceId={logsSourceId} />
          </DialogContent>
        </>
      )}
    </Dialog>
  );
}
