import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { debounce } from 'lodash';
import { useSnackbar } from 'notistack';
import { useHistory, useParams } from 'react-router';

import { Button, FormControl, Stack, TextField } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import GridPagination from '~/components/Common/Elements/GridPagination';

import useQuery from '~/hooks/useQuery';

import { listAgencies } from '~/services/AgentHub/AgentService';

import AgencyDetailsModal from './AgencyDetails/AgencyDetailsModal';

const DEFAULT_SIZE_PER_PAGE = 10;

export default function AgencyList() {
  const params = useParams<{ id?: string }>();
  const queryFilters = useQuery();

  const { push: setQueryString } = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [loadingState, setLoadingState] = useState<Utils.FetchingState>('idle');
  const [result, setResult] = useState([]);
  const [total, setTotal] = useState(0);

  const [details, setDetails] = useState({ id: params.id ?? null, opened: !!params.id });

  const filter = useMemo(() => {
    return {
      page: parseInt(queryFilters.get('page') ?? '0'),
      search: queryFilters.get('search') ?? '',
    };
  }, [queryFilters]);

  const onPageChange = useCallback(
    (page: number) => {
      queryFilters.set('page', page.toString());
      setQueryString({
        search: queryFilters.toString(),
      });
    },
    [queryFilters, setQueryString],
  );

  const onSearchChange = useCallback(
    (search: string) => {
      if (search === '') {
        queryFilters.delete('search');
      } else {
        queryFilters.set('search', search);
      }
      setQueryString({
        search: queryFilters.toString(),
      });
    },
    [queryFilters, setQueryString],
  );

  const onSearchChangeDebounced = debounce(onSearchChange, 500);

  const fetchAgencyList = useCallback(async (): Promise<void> => {
    try {
      setLoadingState('loading');
      const { search } = filter;
      const { rows, total } = await listAgencies({
        page: filter.page.toString(),
        pageSize: DEFAULT_SIZE_PER_PAGE.toString(),
        ...(search ? { search } : {}),
      });

      setTotal(total);
      setResult(rows);
      setLoadingState('success');
    } catch (e) {
      setLoadingState('failed');
      enqueueSnackbar('Failed to load data', {
        variant: 'error',
      });
    }
  }, [filter, enqueueSnackbar]);

  const openDetailsModal = useCallback(
    (id: string) => {
      setQueryString(`agencies/${id}`, { replace: true });
      setDetails({ id: id, opened: true });
    },
    [setDetails, setQueryString],
  );

  const closeDetailsModal = useCallback(() => {
    setDetails({ id: null, opened: false });
    fetchAgencyList();
  }, [setDetails, fetchAgencyList]);

  useEffect(() => {
    fetchAgencyList();
  }, [fetchAgencyList]);

  useEffect(() => {
    if (params.id) {
      setDetails({ id: params.id, opened: true });
    }
  }, [params]);

  const columns: Array<GridColDef> = useMemo(() => {
    return [
      { field: 'name', headerName: 'Name', flex: 1, sortable: false, display: 'flex' },
      { field: 'city', headerName: 'City', flex: 1, sortable: false, display: 'flex' },
      {
        field: 'identity',
        headerName: 'Identity',
        flex: 1,
        renderCell: (params) => {
          return (
            <>
              {params.row.businessCodeType} - {params.row.businessCodeValue}
            </>
          );
        },
        display: 'flex',
      },
      { field: 'affiliation', headerName: 'Affiliation', flex: 1, sortable: false, display: 'flex' },
      { field: 'tier', headerName: 'Tier', flex: 1, sortable: false, display: 'flex' },
      {
        field: 'action',
        headerName: '',
        flex: 1,
        renderCell: (params) => {
          return (
            <Button variant="text" onClick={() => openDetailsModal(params.row.id)}>
              Show
            </Button>
          );
        },
        display: 'flex',
      },
    ];
  }, [openDetailsModal]);

  return (
    <>
      <Stack display="flex" justifyContent="end">
        <FormControl fullWidth>
          <TextField
            id="search-input-label"
            data-testid="search"
            type="text"
            name="search"
            defaultValue={filter.search}
            label="Search"
            placeholder="Search"
            onChange={(e) => onSearchChangeDebounced(e.target.value)}
            fullWidth
          />
        </FormControl>
      </Stack>
      <Stack mt={1}>
        <DataGrid
          rows={result}
          rowCount={total}
          columns={columns}
          loading={loadingState == 'loading'}
          pagination
          paginationMode="server"
          paginationModel={{ page: filter.page, pageSize: DEFAULT_SIZE_PER_PAGE }}
          pageSizeOptions={[DEFAULT_SIZE_PER_PAGE]}
          onPaginationModelChange={({ page }) => onPageChange(page)}
          slots={{ pagination: GridPagination }}
          disableColumnFilter
          disableColumnMenu
          disableRowSelectionOnClick
          autoHeight
        />
        <AgencyDetailsModal onClose={closeDetailsModal} id={details.id} opened={details.opened}></AgencyDetailsModal>
      </Stack>
    </>
  );
}
