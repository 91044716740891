import React, { useCallback, useMemo } from 'react';

import { Link } from 'react-router-dom';

import { Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import GridPagination from '~/components/Common/Elements/GridPagination';

import { GetRateCodes } from '~/services/cruises/RateCodeService';

import { buildDepositDetails, buildOnBoardCreditDetails } from '../helpers';
import { DataRow } from '../types';

type RateCodeRow = {
  id: number;
  rateId: string;
  rateCode: string;
  vendorCode: string;
  promotionName?: string;
  promotionStartDate?: string;
  promotionEndDate?: string;
  leExclusive: string;
  sellingPoints?: string;
  depositDetails?: string;
  onBoardCreditDetails?: string;
};

const urlBuilder = (id: string): string => `/cruises/rate-code-management/${id}/edit`;

const columns: GridColDef[] = [
  {
    width: 100,
    editable: true,
    field: 'vendorCode',
    headerName: 'Vendor',
    display: 'flex',
  },
  {
    width: 200,
    editable: true,
    field: 'rateCode',
    headerName: 'Rate Code',
    display: 'flex',
    renderCell: (params: GridRenderCellParams<RateCodeRow>) => (
      <Typography variant="inherit" noWrap>
        <Link to={urlBuilder(params.row.rateId)}>
          <Typography variant="inherit" noWrap>
            {params.row.rateCode}
          </Typography>
        </Link>
      </Typography>
    ),
  },
  {
    width: 250,
    editable: true,
    field: 'promotionName',
    headerName: 'Promo Name',
    display: 'flex',
  },
  {
    width: 150,
    editable: true,
    field: 'promotionStartDate',
    headerName: 'Start Date',
    display: 'flex',
  },
  {
    width: 150,
    editable: true,
    field: 'promotionEndDate',
    headerName: 'End Date',
    display: 'flex',
  },
  {
    width: 200,
    editable: true,
    field: 'depositDetails',
    headerName: 'Deposit Details',
    display: 'flex',
  },
  {
    width: 200,
    editable: true,
    field: 'onBoardCreditDetails',
    headerName: 'OBC Details',
    display: 'flex',
  },
  {
    width: 100,
    editable: true,
    field: 'leExclusive',
    headerName: 'LE Exclusive',
    display: 'flex',
  },
  {
    width: 200,
    editable: true,
    field: 'sellingPoints',
    headerName: 'Selling Points',
    display: 'flex',
  },
];

const dataGridInitialState = {
  pagination: { paginationModel: { pageSize: 10 } },
};

type Props = {
  page: number;
  total: number;
  perPage: number;
  loading: boolean;
  onPaginate: (page: number) => void;
  onRowClick: (rowData: DataRow) => void;
  rateCodes: Array<GetRateCodes>;
};

const RateCodeList = (props: Props) => {
  const { loading, rateCodes, page, perPage, total, onPaginate, onRowClick } = props;

  const dataRows: RateCodeRow[] = useMemo(() => {
    return rateCodes.map((rate, id) => ({
      id,
      rateId: rate.id,
      rateCode: rate.code,
      vendorCode: rate.vendor.code,
      promotionName: rate.promotion?.name,
      promotionStartDate: rate.promotion?.startDate,
      promotionEndDate: rate.promotion?.endDate,
      leExclusive: rate.promotion?.leExclusive ? 'YES' : 'NO',
      sellingPoints: rate.promotion?.sellingPoints?.join(', '),
      depositDetails: rate.promotion?.deposit && buildDepositDetails(rate.promotion?.deposit),
      onBoardCreditDetails: rate.promotion?.onBoardCredit && buildOnBoardCreditDetails(rate.promotion?.onBoardCredit),
    }));
  }, [rateCodes]);

  const handleRowClick = useCallback(({ row }) => onRowClick(row), [onRowClick]);

  return (
    <DataGrid
      rows={dataRows}
      columns={columns}
      loading={loading}
      pagination
      rowCount={total}
      getRowId={(row) => row.id}
      paginationMode="server"
      paginationModel={{ page: page, pageSize: perPage }}
      onPaginationModelChange={({ page }) => onPaginate(page)}
      slots={{ pagination: GridPagination }}
      pageSizeOptions={[10]}
      onRowClick={handleRowClick}
      initialState={dataGridInitialState}
      autoHeight
    />
  );
};

export default RateCodeList;
