import React from 'react';

import { Stack } from '@mui/material';

import { PropertyResponse } from '~/services/AccommodationService';

import { StateChip } from './StateChip';

interface Props {
  property: PropertyResponse;
}

type ChipStatus = 'default' | 'error' | 'warning' | 'success' | 'info';

interface StateConfig {
  label: string;
  conditions: Array<{
    when: boolean;
    status: ChipStatus;
    tooltip?: string;
  }>;
  shouldShow?: boolean;
}

export default function AccommodationPropertyStateIndicator({ property }: Props) {
  const { indicativePropertyState: state } = property;

  const stateConfigs: Array<StateConfig> = [
    {
      label: 'LPC',
      shouldShow: property.hasReservationMappings,
      conditions: [
        {
          when: !state.hasLpcOffer,
          status: 'default',
          tooltip: 'No linked offer',
        },
        {
          when: state.hasLpcOffer && (!state.hasApprovedLpcOffer || !state.hasScheduledLpcOffer),
          status: 'error',
          tooltip: !state.hasApprovedLpcOffer
            ? 'Offer content is not approved'
            : 'Offer does not have an active schedule',
        },
        {
          when:
            state.hasLpcOffer &&
            state.hasApprovedLpcOffer &&
            state.hasScheduledLpcOffer &&
            (!state.hasLpcRatesEnabled || !state.hasLpcRoomsMapped),
          status: 'warning',
          tooltip: !state.hasLpcRatesEnabled ? 'LPC rates are not enabled' : 'No verified room mappings',
        },
        {
          when:
            state.hasLpcOffer &&
            state.hasApprovedLpcOffer &&
            state.hasScheduledLpcOffer &&
            state.hasLpcRatesEnabled &&
            state.hasLpcRoomsMapped,
          status: 'success',
        },
      ],
    },
    {
      label: 'Bedbank',
      shouldShow: property.hasBedbankMappings,
      conditions: [
        {
          when: !state.hasValidBedbankStatus || !state.hasValidBedbankDomain,
          status: 'error',
          tooltip: !state.hasValidBedbankStatus
            ? 'Property does not have a valid status'
            : 'Property does not have a valid domain',
        },
        {
          when:
            state.hasValidBedbankStatus &&
            state.hasValidBedbankDomain &&
            (!state.hasBedbankRoomsMapped || !state.hasBedbankRatesEnabled),
          status: 'warning',
          tooltip: !state.hasBedbankRoomsMapped ? 'No verified room mappings' : 'Bedbank rates are not enabled',
        },
        {
          when:
            state.hasValidBedbankStatus &&
            state.hasValidBedbankDomain &&
            state.hasBedbankRoomsMapped &&
            state.hasBedbankRatesEnabled,
          status: 'success',
        },
      ],
    },
    {
      label: 'Flash',
      shouldShow: state.hasFlashOffer,
      conditions: [
        {
          when: !state.hasApprovedFlashOffer || !state.hasScheduledFlashOffer,
          status: 'default',
          tooltip: !state.hasApprovedFlashOffer
            ? 'Offer content is not approved'
            : 'Offer does not have an active schedule',
        },
        {
          when: state.hasApprovedFlashOffer && state.hasScheduledFlashOffer,
          status: 'info',
        },
      ],
    },
  ];

  return (
    <Stack direction="row" spacing={1}>
      {stateConfigs.map(({ label, conditions, shouldShow = true }) => {
        if (!shouldShow) {
          return null;
        }

        const matchingCondition = conditions.find(({ when }) => when);
        if (!matchingCondition) {
          return null;
        }

        return (
          <StateChip key={label} label={label} status={matchingCondition.status} tooltip={matchingCondition.tooltip} />
        );
      })}
    </Stack>
  );
}
