import { CruisesContract, definitions } from '@luxuryescapes/contract-svc-cruise';

import cruisesAPI from './CruisesApiService';

const resource = 'bookings';

type BookingStepsByBookingId = definitions['BOOKING_STEPS_BY_BOOKING_ID'];
type FetchBookingsParams = definitions['BOOKING_LIST_QUERY_PARAMS'];
type BookingList = definitions['BOOKING_LIST'];
export type ConsolidatedPaymentSchedule = definitions['GET_CONSOLIDATED_PAYMENT_SCHEDULE'];

type BookingOrderResponse = CruisesContract.Response<CruisesContract.BookingOrderResponse>;
export type BatchBookingsByIdsResponse = CruisesContract.Response<CruisesContract.BookingBatchDetailsResponse[]>;
export type BookingStepsByBookingIdResponse = CruisesContract.Response<BookingStepsByBookingId>;
export type BookingListResponse = CruisesContract.Response<BookingList>;
export type ConsolidatedPaymentScheduleResponse = CruisesContract.Response<ConsolidatedPaymentSchedule>;

export type Booking = BookingList[0];

const getById = async (id: string): Promise<BookingOrderResponse> => {
  let status: number;

  try {
    const res: BookingOrderResponse = await cruisesAPI.httpV1.get({
      resource: `${resource}/${id}`,
    });

    status = res.status;
    if (res.result && !res.errors) return res;
  } catch (err) {
    console.error('[Cruise Booking - Get By Id]: ', err);
    return { status: status, message: err, result: null };
  }
};

const getBatchBookingsById = async (ids: string[]): Promise<BatchBookingsByIdsResponse> => {
  let status: number;
  const formattedIds = ids.join(',');

  const params = {
    resource,
    queryString: JSON.stringify({ bookingIds: formattedIds }),
  };

  try {
    const res: BatchBookingsByIdsResponse = await cruisesAPI.httpV1.get(params);

    status = res.status;
    if (res.result && !res.errors) return res;
  } catch (err) {
    console.error('[Cruise Booking - Get Batch By Id]: ', err);
    return { status: status, message: err, result: null };
  }
};

const getBookingStepsByBookingId = async (bookingId: string): Promise<BookingStepsByBookingIdResponse> => {
  try {
    const res: BookingStepsByBookingIdResponse = await cruisesAPI.http.get({
      resource: `${resource}/${bookingId}/steps`,
    });

    if (!res.errors && res.result) {
      return res;
    }
  } catch (err) {
    console.error('[Cruise Booking - Get Booking Steps By Id]: ', err);
    return { status: err.status, message: err.message, result: null };
  }
};

const getBookings = async (params: FetchBookingsParams): Promise<BookingListResponse> => {
  try {
    const res: BookingListResponse = await cruisesAPI.http.get({
      resource,
      queryString: JSON.stringify(params),
    });

    if (!res.errors && res.result) {
      return res;
    }
  } catch (err) {
    console.error('[Cruise Bookings - Booking List]: ', err);
    return { status: err.status, message: err.message, result: null };
  }
};

export default {
  getById,
  getBatchBookingsById,
  getBookingStepsByBookingId,
  getBookings,
};
