export const highRiskIPCountries = ['BR', 'CN', 'IR', 'NG', 'RU', 'IN', 'HK', 'TW', 'VN'];
export const lowRiskDestinations = ['AU', 'NZ', 'ID', 'FJ', 'MV'];
export const countriesMap: { [key: string]: { name: string; flag: string } } = {
  AD: { name: 'Andorra', flag: '🇦🇩' },
  AE: { name: 'United Arab Emirates', flag: '🇦🇪' },
  AF: { name: 'Afghanistan', flag: '🇦🇫' },
  AG: { name: 'Antigua and Barbuda', flag: '🇦🇬' },
  AI: { name: 'Anguilla', flag: '🇦🇮' },
  AL: { name: 'Albania', flag: '🇦🇱' },
  AM: { name: 'Armenia', flag: '🇦🇲' },
  AO: { name: 'Angola', flag: '🇦🇴' },
  AQ: { name: 'Antarctica', flag: '🇦🇶' },
  AR: { name: 'Argentina', flag: '🇦🇷' },
  AS: { name: 'American Samoa', flag: '🇦🇸' },
  AT: { name: 'Austria', flag: '🇦🇹' },
  AU: { name: 'Australia', flag: '🇦🇺' },
  AW: { name: 'Aruba', flag: '🇦🇼' },
  AX: { name: 'Åland Islands', flag: '🇦🇽' },
  AZ: { name: 'Azerbaijan', flag: '🇦🇿' },
  BA: { name: 'Bosnia and Herzegovina', flag: '🇧🇦' },
  BB: { name: 'Barbados', flag: '🇧🇧' },
  BD: { name: 'Bangladesh', flag: '🇧🇩' },
  BE: { name: 'Belgium', flag: '🇧🇪' },
  BF: { name: 'Burkina Faso', flag: '🇧🇫' },
  BG: { name: 'Bulgaria', flag: '🇧🇬' },
  BH: { name: 'Bahrain', flag: '🇧🇭' },
  BI: { name: 'Burundi', flag: '🇧🇮' },
  BJ: { name: 'Benin', flag: '🇧🇯' },
  BL: { name: 'Saint Barthélemy', flag: '🇧🇱' },
  BM: { name: 'Bermuda', flag: '🇧🇲' },
  BN: { name: 'Brunei', flag: '🇧🇳' },
  BO: { name: 'Bolivia', flag: '🇧🇴' },
  BQ: { name: 'Caribbean Netherlands', flag: '🇧🇶' },
  BR: { name: 'Brazil', flag: '🇧🇷' },
  BS: { name: 'Bahamas', flag: '🇧🇸' },
  BT: { name: 'Bhutan', flag: '🇧🇹' },
  BV: { name: 'Bouvet Island', flag: '🇧🇻' },
  BW: { name: 'Botswana', flag: '🇧🇼' },
  BY: { name: 'Belarus', flag: '🇧🇾' },
  BZ: { name: 'Belize', flag: '🇧🇿' },
  CA: { name: 'Canada', flag: '🇨🇦' },
  CC: { name: 'Cocos Islands', flag: '🇨🇨' },
  CD: { name: 'Democratic Republic of the Congo', flag: '🇨🇩' },
  CF: { name: 'Central African Republic', flag: '🇨🇫' },
  CG: { name: 'Republic of the Congo', flag: '🇨🇬' },
  CH: { name: 'Switzerland', flag: '🇨🇭' },
  CI: { name: 'Ivory Coast', flag: '🇨🇮' },
  CK: { name: 'Cook Islands', flag: '🇨🇰' },
  CL: { name: 'Chile', flag: '🇨🇱' },
  CM: { name: 'Cameroon', flag: '🇨🇲' },
  CN: { name: 'China', flag: '🇨🇳' },
  CO: { name: 'Colombia', flag: '🇨🇴' },
  CR: { name: 'Costa Rica', flag: '🇨🇷' },
  CU: { name: 'Cuba', flag: '🇨🇺' },
  CV: { name: 'Cape Verde', flag: '🇨🇻' },
  CW: { name: 'Curaçao', flag: '🇨🇼' },
  CX: { name: 'Christmas Island', flag: '🇨🇽' },
  CY: { name: 'Cyprus', flag: '🇨🇾' },
  CZ: { name: 'Czech Republic', flag: '🇨🇿' },
  DE: { name: 'Germany', flag: '🇩🇪' },
  DJ: { name: 'Djibouti', flag: '🇩🇯' },
  DK: { name: 'Denmark', flag: '🇩🇰' },
  DM: { name: 'Dominica', flag: '🇩🇲' },
  DO: { name: 'Dominican Republic', flag: '🇩🇴' },
  DZ: { name: 'Algeria', flag: '🇩🇿' },
  EC: { name: 'Ecuador', flag: '🇪🇨' },
  EE: { name: 'Estonia', flag: '🇪🇪' },
  EG: { name: 'Egypt', flag: '🇪🇬' },
  EH: { name: 'Western Sahara', flag: '🇪🇭' },
  ER: { name: 'Eritrea', flag: '🇪🇷' },
  ES: { name: 'Spain', flag: '🇪🇸' },
  ET: { name: 'Ethiopia', flag: '🇪🇹' },
  FI: { name: 'Finland', flag: '🇫🇮' },
  FJ: { name: 'Fiji', flag: '🇫🇯' },
  FK: { name: 'Falkland Islands', flag: '🇫🇰' },
  FM: { name: 'Micronesia', flag: '🇫🇲' },
  FO: { name: 'Faroe Islands', flag: '🇫🇴' },
  FR: { name: 'France', flag: '🇫🇷' },
  GA: { name: 'Gabon', flag: '🇬🇦' },
  GB: { name: 'United Kingdom', flag: '🇬🇧' },
  GD: { name: 'Grenada', flag: '🇬🇩' },
  GE: { name: 'Georgia', flag: '🇬🇪' },
  GF: { name: 'French Guiana', flag: '🇬🇫' },
  GG: { name: 'Guernsey', flag: '🇬🇬' },
  GH: { name: 'Ghana', flag: '🇬🇭' },
  GI: { name: 'Gibraltar', flag: '🇬🇮' },
  GL: { name: 'Greenland', flag: '🇬🇱' },
  GM: { name: 'Gambia', flag: '🇬🇲' },
  GN: { name: 'Guinea', flag: '🇬🇳' },
  GP: { name: 'Guadeloupe', flag: '🇬🇵' },
  GQ: { name: 'Equatorial Guinea', flag: '🇬🇶' },
  GR: { name: 'Greece', flag: '🇬🇷' },
  GS: { name: 'South Georgia and the South Sandwich Islands', flag: '🇬🇸' },
  GT: { name: 'Guatemala', flag: '🇬🇹' },
  GU: { name: 'Guam', flag: '🇬🇺' },
  GW: { name: 'Guinea-Bissau', flag: '🇬🇼' },
  GY: { name: 'Guyana', flag: '🇬🇾' },
  HK: { name: 'Hong Kong', flag: '🇭🇰' },
  HM: { name: 'Heard Island and McDonald Islands', flag: '🇭🇲' },
  HN: { name: 'Honduras', flag: '🇭🇳' },
  HR: { name: 'Croatia', flag: '🇭🇷' },
  HT: { name: 'Haiti', flag: '🇭🇹' },
  HU: { name: 'Hungary', flag: '🇭🇺' },
  ID: { name: 'Indonesia', flag: '🇮🇩' },
  IE: { name: 'Ireland', flag: '🇮🇪' },
  IL: { name: 'Israel', flag: '🇮🇱' },
  IM: { name: 'Isle of Man', flag: '🇮🇲' },
  IN: { name: 'India', flag: '🇮🇳' },
  IO: { name: 'British Indian Ocean Territory', flag: '🇮🇴' },
  IQ: { name: 'Iraq', flag: '🇮🇶' },
  IR: { name: 'Iran', flag: '🇮🇷' },
  IS: { name: 'Iceland', flag: '🇮🇸' },
  IT: { name: 'Italy', flag: '🇮🇹' },
  JE: { name: 'Jersey', flag: '🇯🇪' },
  JM: { name: 'Jamaica', flag: '🇯🇲' },
  JO: { name: 'Jordan', flag: '🇯🇴' },
  JP: { name: 'Japan', flag: '🇯🇵' },
  KE: { name: 'Kenya', flag: '🇰🇪' },
  KG: { name: 'Kyrgyzstan', flag: '🇰🇬' },
  KH: { name: 'Cambodia', flag: '🇰🇭' },
  KI: { name: 'Kiribati', flag: '🇰🇮' },
  KM: { name: 'Comoros', flag: '🇰🇲' },
  KN: { name: 'Saint Kitts and Nevis', flag: '🇰🇳' },
  KP: { name: 'North Korea', flag: '🇰🇵' },
  KR: { name: 'South Korea', flag: '🇰🇷' },
  KW: { name: 'Kuwait', flag: '🇰🇼' },
  KY: { name: 'Cayman Islands', flag: '🇰🇾' },
  KZ: { name: 'Kazakhstan', flag: '🇰🇿' },
  LA: { name: 'Laos', flag: '🇱🇦' },
  LB: { name: 'Lebanon', flag: '🇱🇧' },
  LC: { name: 'Saint Lucia', flag: '🇱🇨' },
  LI: { name: 'Liechtenstein', flag: '🇱🇮' },
  LK: { name: 'Sri Lanka', flag: '🇱🇰' },
  LR: { name: 'Liberia', flag: '🇱🇷' },
  LS: { name: 'Lesotho', flag: '🇱🇸' },
  LT: { name: 'Lithuania', flag: '🇱🇹' },
  LU: { name: 'Luxembourg', flag: '🇱🇺' },
  LV: { name: 'Latvia', flag: '🇱🇻' },
  LY: { name: 'Libya', flag: '🇱🇾' },
  MA: { name: 'Morocco', flag: '🇲🇦' },
  MC: { name: 'Monaco', flag: '🇲🇨' },
  MD: { name: 'Moldova', flag: '🇲🇩' },
  ME: { name: 'Montenegro', flag: '🇲🇪' },
  MF: { name: 'Saint Martin', flag: '🇲🇫' },
  MG: { name: 'Madagascar', flag: '🇲🇬' },
  MH: { name: 'Marshall Islands', flag: '🇲🇭' },
  MK: { name: 'North Macedonia', flag: '🇲🇰' },
  ML: { name: 'Mali', flag: '🇲🇱' },
  MM: { name: 'Myanmar', flag: '🇲🇲' },
  MN: { name: 'Mongolia', flag: '🇲🇳' },
  MO: { name: 'Macao', flag: '🇲🇴' },
  MP: { name: 'Northern Mariana Islands', flag: '🇲🇵' },
  MQ: { name: 'Martinique', flag: '🇲🇶' },
  MR: { name: 'Mauritania', flag: '🇲🇷' },
  MS: { name: 'Montserrat', flag: '🇲🇸' },
  MT: { name: 'Malta', flag: '🇲🇹' },
  MU: { name: 'Mauritius', flag: '🇲🇺' },
  MV: { name: 'Maldives', flag: '🇲🇻' },
  MW: { name: 'Malawi', flag: '🇲🇼' },
  MX: { name: 'Mexico', flag: '🇲🇽' },
  MY: { name: 'Malaysia', flag: '🇲🇾' },
  MZ: { name: 'Mozambique', flag: '🇲🇿' },
  NA: { name: 'Namibia', flag: '🇳🇦' },
  NC: { name: 'New Caledonia', flag: '🇳🇨' },
  NE: { name: 'Niger', flag: '🇳🇪' },
  NF: { name: 'Norfolk Island', flag: '🇳🇫' },
  NG: { name: 'Nigeria', flag: '🇳🇬' },
  NI: { name: 'Nicaragua', flag: '🇳🇮' },
  NL: { name: 'Netherlands', flag: '🇳🇱' },
  NO: { name: 'Norway', flag: '🇳🇴' },
  NP: { name: 'Nepal', flag: '🇳🇵' },
  NR: { name: 'Nauru', flag: '🇳🇷' },
  NU: { name: 'Niue', flag: '🇳🇺' },
  NZ: { name: 'New Zealand', flag: '🇳🇿' },
  OM: { name: 'Oman', flag: '🇴🇲' },
  PA: { name: 'Panama', flag: '🇵🇦' },
  PE: { name: 'Peru', flag: '🇵🇪' },
  PF: { name: 'French Polynesia', flag: '🇵🇫' },
  PG: { name: 'Papua New Guinea', flag: '🇵🇬' },
  PH: { name: 'Philippines', flag: '🇵🇭' },
  PK: { name: 'Pakistan', flag: '🇵🇰' },
  PL: { name: 'Poland', flag: '🇵🇱' },
  PM: { name: 'Saint Pierre and Miquelon', flag: '🇵🇲' },
  PN: { name: 'Pitcairn', flag: '🇵🇳' },
  PR: { name: 'Puerto Rico', flag: '🇵🇷' },
  PS: { name: 'Palestine', flag: '🇵🇸' },
  PT: { name: 'Portugal', flag: '🇵🇹' },
  PW: { name: 'Palau', flag: '🇵🇼' },
  PY: { name: 'Paraguay', flag: '🇵🇾' },
  QA: { name: 'Qatar', flag: '🇶🇦' },
  RE: { name: 'Réunion', flag: '🇷🇪' },
  RO: { name: 'Romania', flag: '🇷🇴' },
  RS: { name: 'Serbia', flag: '🇷🇸' },
  RU: { name: 'Russia', flag: '🇷🇺' },
  RW: { name: 'Rwanda', flag: '🇷🇼' },
  SA: { name: 'Saudi Arabia', flag: '🇸🇦' },
  SB: { name: 'Solomon Islands', flag: '🇸🇧' },
  SC: { name: 'Seychelles', flag: '🇸🇨' },
  SD: { name: 'Sudan', flag: '🇸🇩' },
  SE: { name: 'Sweden', flag: '🇸🇪' },
  SG: { name: 'Singapore', flag: '🇸🇬' },
  SH: { name: 'Saint Helena', flag: '🇸🇭' },
  SI: { name: 'Slovenia', flag: '🇸🇮' },
  SJ: { name: 'Svalbard and Jan Mayen', flag: '🇸🇯' },
  SK: { name: 'Slovakia', flag: '🇸🇰' },
  SL: { name: 'Sierra Leone', flag: '🇸🇱' },
  SM: { name: 'San Marino', flag: '🇸🇲' },
  SN: { name: 'Senegal', flag: '🇸🇳' },
  SO: { name: 'Somalia', flag: '🇸🇴' },
  SR: { name: 'Suriname', flag: '🇸🇷' },
  SS: { name: 'South Sudan', flag: '🇸🇸' },
  ST: { name: 'Sao Tome and Principe', flag: '🇸🇹' },
  SV: { name: 'El Salvador', flag: '🇸🇻' },
  SX: { name: 'Sint Maarten', flag: '🇸🇽' },
  SY: { name: 'Syria', flag: '🇸🇾' },
  SZ: { name: 'Eswatini', flag: '🇸🇿' },
  TC: { name: 'Turks and Caicos Islands', flag: '🇹🇨' },
  TD: { name: 'Chad', flag: '🇹🇩' },
  TF: { name: 'French Southern Territories', flag: '🇹🇫' },
  TG: { name: 'Togo', flag: '🇹🇬' },
  TH: { name: 'Thailand', flag: '🇹🇭' },
  TJ: { name: 'Tajikistan', flag: '🇹🇯' },
  TK: { name: 'Tokelau', flag: '🇹🇰' },
  TL: { name: 'Timor-Leste', flag: '🇹🇱' },
  TM: { name: 'Turkmenistan', flag: '🇹🇲' },
  TN: { name: 'Tunisia', flag: '🇹🇳' },
  TO: { name: 'Tonga', flag: '🇹🇴' },
  TR: { name: 'Turkey', flag: '🇹🇷' },
  TT: { name: 'Trinidad and Tobago', flag: '🇹🇹' },
  TV: { name: 'Tuvalu', flag: '🇹🇻' },
  TW: { name: 'Taiwan', flag: '🇹🇼' },
  TZ: { name: 'Tanzania', flag: '🇹🇿' },
  UA: { name: 'Ukraine', flag: '🇺🇦' },
  UG: { name: 'Uganda', flag: '🇺🇬' },
  UM: { name: 'United States Minor Outlying Islands', flag: '🇺🇲' },
  US: { name: 'United States', flag: '🇺🇸' },
  UY: { name: 'Uruguay', flag: '🇺🇾' },
  UZ: { name: 'Uzbekistan', flag: '🇺🇿' },
  VA: { name: 'Vatican City', flag: '🇻🇦' },
  VC: { name: 'Saint Vincent and the Grenadines', flag: '🇻🇨' },
  VE: { name: 'Venezuela', flag: '🇻🇪' },
  VG: { name: 'British Virgin Islands', flag: '🇻🇬' },
  VI: { name: 'U.S. Virgin Islands', flag: '🇻🇮' },
  VN: { name: 'Vietnam', flag: '🇻🇳' },
  VU: { name: 'Vanuatu', flag: '🇻🇺' },
  WF: { name: 'Wallis and Futuna', flag: '🇼🇫' },
  WS: { name: 'Samoa', flag: '🇼🇸' },
  YE: { name: 'Yemen', flag: '🇾🇪' },
  YT: { name: 'Mayotte', flag: '🇾🇹' },
  ZA: { name: 'South Africa', flag: '🇿🇦' },
  ZM: { name: 'Zambia', flag: '🇿🇲' },
  ZW: { name: 'Zimbabwe', flag: '🇿🇼' },
};
