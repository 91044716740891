import qs from 'qs';

import { operations } from '@luxuryescapes/contract-svc-public-offer-feed/generated';

import { json_headers, request } from '~/services/common';

export type AdGroup = {
  campaignName: string;
  campaignId: string;
  adGroupName: string;
  adGroupId: string;
  adGroupResourceName: string;
  tcpa: number;
  cpa: number;
  expectedConversionMargin: number;
  bidPercentage: number;
  searchImpressionShare?: number;
};

export type AdGroupResponse = operations['googleAdsGetAdGroupsV2']['responses']['200']['content']['application/json'];

export type AdCampaign = {
  id: string;
  name: string;
  campaignBudget: CampaignBudget;
};

export type AdCustomer = {
  id: string;
  descriptiveName: string;
  campaigns: Array<AdCampaign>;
};

export type Campaign =
  operations['googleAdsCustomers']['responses']['200']['content']['application/json']['result']['0']['campaigns']['0'];

export type CampaignBudget =
  operations['googleAdsCustomers']['responses']['200']['content']['application/json']['result']['0']['campaigns']['0']['campaignBudget'];

function basePath() {
  return window.configs.API_HOST + '/api/public-offer-feed';
}

export type GetAdGroupProps = {
  customerId: string;
  campaignIds: Array<string>;
  pageToken?: string;
};

export async function getAdGroups({ customerId, campaignIds, pageToken }: GetAdGroupProps): Promise<AdGroupResponse> {
  const queryParams = {
    pageToken,
    campaignIds: campaignIds.join(','),
  };
  const url = `${basePath()}/google-ads/${customerId}/campaigns/ad-groups?${qs.stringify(queryParams)}`;
  return request(url, {
    method: 'GET',
  });
}

export async function mutateAdgroup(
  customerId: string,
  adGroupId: string,
  resourceName: string,
  targetCPA: number,
): Promise<void> {
  const url = `${basePath()}/google-ads/${customerId}/ad-group/${adGroupId}`;
  return request(url, {
    method: 'PATCH',
    body: JSON.stringify({ resourceName, targetCPA }),
    headers: json_headers,
  });
}

type UpdateCampaignBudgetProps = {
  customerId: string;
  campaignId: string;
  payload: operations['GoogleAdsUpdateCampaignBudget']['parameters']['body']['payload'];
};

export async function updateCampaignBudget({
  customerId,
  campaignId,
  payload,
}: UpdateCampaignBudgetProps): Promise<void> {
  const url = `${basePath()}/google-ads/${customerId}/campaigns/${campaignId}/budgets`;
  return request(url, {
    method: 'PUT',
    body: JSON.stringify(payload),
    headers: json_headers,
  });
}

export async function getCustomers(): Promise<{ result: Array<AdCustomer> }> {
  const url = `${basePath()}/google-ads/customers`;
  return request(url, {
    method: 'GET',
    headers: json_headers,
  });
}

type ModificationResponse = operations['getAllModifications']['responses']['200']['content']['application/json'];

export type ModificationLog = ModificationResponse['result']['0'];

export type ModificationFilters = {
  adGroupIds?: Array<string>;
  customerId: string;
  campaignIds?: Array<string>;
  limit: number;
  page: number;
};

export async function getModifications(params: ModificationFilters): Promise<ModificationResponse> {
  const query: operations['getAllModifications']['parameters']['query'] = {
    campaignIds: params.campaignIds?.join(','),
    limit: params.limit,
    page: params.page,
  };
  const queryParams = `?${qs.stringify(query)}`;
  const url = `${basePath()}/google-ads/modifications${queryParams}`;
  return request(url, {
    method: 'GET',
    headers: json_headers,
  });
}

export type ghaPricingOverview =
  operations['getGHAPricingOverview']['responses']['200']['content']['application/json']['result'];

export async function getGHAPricingOverview({
  page,
  limit,
}: {
  page: number;
  limit: number;
}): Promise<{ result: ghaPricingOverview; count: number }> {
  const queryParams = {
    page,
    limit,
  };
  const url = `${basePath()}/google-hotel-ads/pricing/overview?${qs.stringify(queryParams)}`;
  return request(url, {
    method: 'GET',
  });
}

export function downloadGHAPricingReport(propertyId: string) {
  const downloadUrl = `${basePath()}/google-hotel-ads/${propertyId}/price-report`;
  return request(downloadUrl, {
    download: true,
    method: 'GET',
  });
}

export function downloadRecentPricingReport() {
  const downloadUrl = `${basePath()}/google-hotel-ads/pricing/all`;
  return request(downloadUrl, {
    download: true,
    method: 'GET',
  });
}

export async function createNewVid(data, offerId) {
  return request(`${basePath()}/video/${offerId}`, {
    method: 'POST',
    credentils: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });
}

export async function getVidByOfferId(offerId) {
  return request(`${basePath()}/video/${offerId}`, {
    method: 'GET',
    credentils: 'include',
  });
}
