import React, { useEffect, useState } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useSnackbar } from 'notistack';
import { useRouteMatch } from 'react-router';

import { Box } from '@mui/material';

import { SiteBannerCampaign, getBannerCampaign } from '~/services/SupportService';

import SiteBannerCampaignEdit from './SiteBannerCampaignEdit';

function SiteBannerCampaignEditContainer() {
  const {
    params: { campaignId },
  } = useRouteMatch<{ campaignId: string }>();

  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [campaign, setCampaign] = useState<SiteBannerCampaign | undefined>();

  useEffect(() => {
    const fetchCampaign = async () => {
      try {
        setIsLoading(true);
        const data = await getBannerCampaign(campaignId);
        setCampaign(data);
        setIsLoading(false);
      } catch (error) {
        enqueueSnackbar('Failed to load banner data', { variant: 'error' });
        setIsLoading(false);
      }
    };

    fetchCampaign();
  }, [campaignId, enqueueSnackbar]);

  if (isLoading) {
    return <Box>Loading banner data...</Box>;
  }

  return <SiteBannerCampaignEdit campaign={campaign} />;
}

export default SiteBannerCampaignEditContainer;
