import React from 'react';

import groupBy from 'lodash/groupBy';

import { Box } from '@mui/material';

import FoldableSection from '~/components/Common/Blocks/FoldableSection';

import AddonItem from './item';

interface Props {
  addons: Array<any>;
  refunds: Array<any>;
  payments: Array<any>;
  isBookingDates: boolean;
  isChangingDates: boolean;
  showRefundModal: (params: any) => void;
  checkInStatuses: Array<any>;
}

const getAddonsWithRefundedInfo = (addons, payments, refunds) => {
  return addons
    .filter((addon) => {
      // Exclude cancelled addons without payments
      if (addon.status === 'cancelled' && !addon.complimentary) {
        return payments.some((payment) => payment.transaction_key === addon.transaction_key);
      }
      return true;
    })
    .map((addon) => {
      const addonRefunds = refunds.result.filter((refund) => refund.fk_items === addon.id);

      if (addon.complimentary) {
        addon.is_refunded = addonRefunds.length > 0 && addon.status === 'cancelled';
      } else {
        const refundedAmount = addonRefunds.reduce((sum, refund) => {
          return sum + Number(refund.accounting_amount);
        }, 0);
        addon.is_refunded = addon.total <= refundedAmount;
      }

      return addon;
    });
};

export default function AddonsList({
  addons,
  refunds,
  payments,
  isBookingDates,
  isChangingDates,
  showRefundModal,
  checkInStatuses,
}: Props) {
  const formattedAddons = getAddonsWithRefundedInfo(addons, payments, refunds);

  const disableInteraction = isBookingDates || isChangingDates;

  const grouped = groupBy(formattedAddons, (addon) => addon.channel_booking_id);

  let notBookedAddons = [];
  const bookedAddons = Object.keys(grouped)
    .map((k) => {
      if (k == 'undefined' || k == 'null') {
        notBookedAddons.push(grouped[k]);
        return null;
      } else {
        return grouped[k];
      }
    })
    .filter((addon) => addon != null);

  notBookedAddons = notBookedAddons.flat();

  return (
    <Box mt={2} id="addons">
      <FoldableSection title="Add-ons (Old experiences)" initiallyExpanded>
        {bookedAddons
          .filter((addon) => addon !== null)
          .map((addonList, i) => (
            <AddonItem
              key={i}
              count={i + 1}
              addons={addonList}
              disableInteraction={disableInteraction}
              showRefundModal={showRefundModal}
              checkInStatuses={checkInStatuses}
            />
          ))}

        {notBookedAddons.map((addon, i) => (
          <AddonItem
            key={i}
            count={i + 1 + bookedAddons.length}
            addons={[addon]}
            disableInteraction={disableInteraction}
            showRefundModal={showRefundModal}
            checkInStatuses={checkInStatuses}
          />
        ))}
      </FoldableSection>
    </Box>
  );
}
