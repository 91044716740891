import React from 'react';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { Card, CardContent, Typography } from '@mui/material';

import Image from '~/components/Common/Image';

import { CruiseLine } from '~/services/cruises/CruiseLineService';

interface Props {
  cruiseLine: CruiseLine;
}

const CruiseLineSortItem = ({ cruiseLine }: Props) => {
  const { attributes, isDragging, listeners, setNodeRef, transform, transition } = useSortable({ id: cruiseLine.id });

  return (
    <Card
      {...listeners}
      {...attributes}
      raised={isDragging}
      ref={setNodeRef}
      sx={{
        flexGrow: 1,
        transform: CSS.Transform.toString(transform),
        transition,
        cursor: isDragging ? 'grabbing' : 'grab',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      variant="outlined"
    >
      <CardContent>
        <Image className="img-responsive mx-auto" publicId={cruiseLine.imageId} />
        <Typography variant="h6" sx={{ textAlign: 'center', marginTop: '15px' }}>
          {cruiseLine.name}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CruiseLineSortItem;
