import React from 'react';

import { useHistory } from 'react-router';
import BedIcon from '~/icons/BedIcon';

import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import { Box, Grid, Typography } from '@mui/material';

import { definitions } from '@luxuryescapes/contract-svc-promo';

import BorderedGridItem from '~/components/Common/BorderedGridItem';
import PromoItemField, { isExpectedField } from '~/components/PromoV3/PromoItemField';

import { getPromoProductDisplayName, sortPromoItemByCategoryAndId } from '~/services/PromoService';

import currencyFormatter from '~/utils/currencyFormatter';
import { getCurrencyCodeByRegionCode } from '~/utils/getCurrencyCodeByRegionCode';

import TravellersList from '../PromoPage/TravellersList';

type PromoOrderTableCellProps = {
  order: definitions['Discount Request']['order'] | definitions['Discount Request']['preCheckoutOrder'] | null;
  meta: definitions['PromoMeta'];
};

function PromoOrderTableCell({ order, meta }: PromoOrderTableCellProps) {
  const history = useHistory();
  if (!order?.region) {
    return null;
  }

  const currencyCode = getCurrencyCodeByRegionCode(order.region);

  const handleGridClick = (item: PromoOrderTableCellProps['order']['items']['0']) => {
    if (item.categoryBK == 'subscription') {
      return;
    }
    history.push(`/offers/${item.offerId}`);
  };

  return (
    <Box sx={{ width: '100%' }} title={JSON.stringify(order, null, 4)}>
      {order.items.sort(sortPromoItemByCategoryAndId).map((item, i) => (
        <Grid container key={`${item.offerId}_${i}`} spacing={1} title={`${JSON.stringify(item, null, 4)}`}>
          <BorderedGridItem noBorder={i === order.items.length - 1}>
            {currencyFormatter(currencyCode, item.luxPlusPrice || item.discountableTotal)}
            {item.luxPlusPrice > 0 && (
              <Typography
                sx={{
                  textTransform: 'uppercase',
                  display: 'inline',
                  fontSize: 14,
                  fontWeight: 300,
                  textDecoration: 'line-through',
                  marginLeft: 1,
                }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {currencyFormatter(currencyCode, item.discountableTotal)}
              </Typography>
            )}
            <PromoItemField
              title="Product Type"
              field="productType"
              categoryBK={item.categoryBK}
              value={meta && getPromoProductDisplayName(meta.promo_product_types, item.categoryBK, item.subCategoryBK)}
            />
            <PromoItemField title="Offer ID" field="offerId" categoryBK={item.categoryBK} value={item.offerId} />
            <PromoItemField title="Item ID" field="itemId" categoryBK={item.categoryBK} value={item.itemId} />
            {isExpectedField(item.categoryBK, 'travellers') && <TravellersList travellers={item.travellers} />}
            <PromoItemField
              title="Reservation Type"
              field="reservationType"
              categoryBK={item.categoryBK}
              value={item.reservationType}
            />
            <PromoItemField title="Provider" field="provider" categoryBK={item.categoryBK} value={item.provider} />
            <PromoItemField
              title="Origin Airport Code"
              field="originAirportCode"
              categoryBK={item.categoryBK}
              value={item.originAirportCode}
            />
            <PromoItemField
              title="Item Country Code"
              field="itemCountryCode"
              categoryBK={item.categoryBK}
              value={item.itemCountryCode}
            />
            <PromoItemField
              title="Number of Nights"
              field="numberOfNights"
              categoryBK={item.categoryBK}
              value={item.numberOfNights}
            />
            <PromoItemField
              title="Number of Adults"
              field="numberOfAdults"
              categoryBK={item.categoryBK}
              value={item.numberOfAdults}
            />
            <PromoItemField
              title="Number of Children"
              field="numberOfChildren"
              categoryBK={item.categoryBK}
              value={item.numberOfChildren}
            />
            <PromoItemField
              title="Item Info String"
              field="itemInfoString"
              categoryBK={item.categoryBK}
              value={item.itemInfoString}
            />
            {order.isGiftOrder ?? (
              <Typography title="(Gift Order)">
                <CardGiftcardIcon />
              </Typography>
            )}
            {order.hasBedbankPromotion && (
              <Box title="Has Bedbank Promotion">
                <BedIcon />
              </Box>
            )}
          </BorderedGridItem>
        </Grid>
      ))}
    </Box>
  );
}

export { PromoOrderTableCell };
