import React, { useEffect, useMemo, useState } from 'react';

import { debounce } from 'lodash';

import { Autocomplete, Box, Stack, TextField, Typography } from '@mui/material';

import { CommissionOption, RuleCondition } from '~/consts/agentHub';

import useCurrentBrand from '~/hooks/useCurrentBrand';

import UsersService from '~/services/UsersService';

interface Props {
  setCreatedConditions: (condition: RuleCondition, replace?: boolean) => void;
  existingConditions: RuleCondition['value'];
}

function AgentHubCommissionsAgentInput({ setCreatedConditions, existingConditions }: Props) {
  const [loadingState, setLoadingState] = useState<Utils.FetchingState>('idle');
  const [result, setResult] = useState<CommissionOption>([]);
  const [search, setSearch] = useState('');
  const [selectedAgents, setSelectedAgents] = useState<CommissionOption>(existingConditions || []);
  const [noOptionsText, setNoOptionsText] = useState<string>('Start typing to search for agents');
  const brand = useCurrentBrand();

  const debouncedGetAgencyList = useMemo(
    () =>
      debounce((search: string) => {
        if (!search) {
          setResult([]);
          return;
        }
        setLoadingState('loading');
        UsersService.getUsers({ filter: search, brand })
          .then((rows) => {
            if (rows.length === 0) {
              setNoOptionsText('No agents found');
            }
            setResult(
              rows.map((agent) => ({ value: agent.id_member, label: `${agent.full_name || ' '} [${agent.email}]` })),
            );
          })
          .finally(() => setLoadingState('success'));
      }, 500),
    [brand],
  );

  const handleSaveRule = () => {
    setCreatedConditions(
      {
        type: 'agent',
        value: selectedAgents,
      },
      true,
    );
  };

  useEffect(() => {
    handleSaveRule();
  }, [selectedAgents]);

  const handleInputChange = (_: React.ChangeEvent, newInputValue: string) => {
    if (!newInputValue) {
      setNoOptionsText('Start typing to search for agents');
      return;
    }
    setSearch(newInputValue);
  };

  const handleChange = (_: React.ChangeEvent, value: CommissionOption) => {
    setSelectedAgents(value);
  };

  useEffect(() => {
    debouncedGetAgencyList(search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <Stack spacing={2}>
      <Box>
        <Autocomplete
          multiple
          options={result}
          loading={loadingState === 'loading'}
          getOptionLabel={(option) => option.label}
          defaultValue={[]}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField {...params} label="Agents" placeholder={selectedAgents.length > 0 ? '' : 'Agents'} />
          )}
          onInputChange={handleInputChange}
          value={selectedAgents}
          onChange={handleChange}
          noOptionsText={noOptionsText}
          disablePortal
        />
        <Typography variant="caption" color="textSecondary">
          Start typing to search for agents
        </Typography>
      </Box>
    </Stack>
  );
}

export default AgentHubCommissionsAgentInput;
