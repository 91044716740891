import React, { useCallback, useState } from 'react';

import { Box, Button, Stack, TextField } from '@mui/material';

import InputCruiseLineSelect from '~/components/Cruises/components/Selects/InputCruiseLineSelect';
import InputRateCodeSelect from '~/components/Cruises/components/Selects/InputRateCodeSelect';

import { CruiseLine } from '~/services/cruises/CruiseLineService';

import { CruiseDealPageListQuery } from '../types';

const INITIAL_SEARCH_STATE = {
  name: '',
  cruiseLineId: undefined,
  rateCodes: [],
};

type Props = {
  isLoading: boolean;
  onSearch: (searchValues: CruiseDealPageListQuery) => void;
};

function DealPageSearchForm({ isLoading, onSearch }: Props) {
  const [searchValues, setSearchValues] = useState<CruiseDealPageListQuery>(INITIAL_SEARCH_STATE);

  const handleChangeField = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.persist();

      if (event?.target && event?.target?.name) {
        setSearchValues({
          ...searchValues,
          [event.target.name]: event.target.value,
        });
      }
    },
    [searchValues],
  );

  const handleCruiseLineField = useCallback(
    (cruiseLine: CruiseLine) => {
      setSearchValues({
        ...searchValues,
        cruiseLineId: cruiseLine.id,
      });
    },
    [searchValues],
  );

  const handleRateCodesField = useCallback(
    (rateCodes: Array<string>) => {
      setSearchValues({
        ...searchValues,
        rateCodes,
      });
    },
    [searchValues],
  );

  const handleSearch = useCallback(() => {
    onSearch({
      name: searchValues.name || undefined,
      cruiseLineId: searchValues.cruiseLineId || undefined,
      rateCodes: searchValues.rateCodes || undefined,
    });
  }, [searchValues, onSearch]);

  return (
    <Stack direction="column" spacing={2} border={1} borderColor="divider" borderRadius={1} p={2}>
      <Stack direction="row" spacing={2}>
        <Box flexGrow={2}>
          <TextField fullWidth name="name" label="Deal Name" onChange={handleChangeField} value={searchValues.name} />
        </Box>
        <Box flexGrow={2}>
          <InputCruiseLineSelect
            label="Cruise line"
            value={searchValues.cruiseLineId}
            onChange={handleCruiseLineField}
            allowEmpty
          />
        </Box>
      </Stack>
      <Stack direction="row" spacing={2}>
        <Box flexGrow={2}>
          <InputRateCodeSelect
            selectedRateIds={searchValues.rateCodes}
            onChange={handleRateCodesField}
            allowEmptyVendorCode
            shouldReturnRateCodes
          />
        </Box>
        <Box>
          <Button variant="contained" disabled={isLoading} onClick={handleSearch}>
            Search Content
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
}

export default DealPageSearchForm;
