import { useCallback, useState } from 'react';

export enum DynamicSectionType {
  BANNER = 'BANNER',
  OFFER = 'OFFER',
  FLIGHT = 'FLIGHT',
  DREAM_ARTICLE = 'DREAM_ARTICLE',
  TRENDING_DESTINATION = 'DESTINATION',
  CALL_TO_ACTION = 'CALL_TO_ACTION',
  DEFAULT_PUSH = 'DEFAULT',
  EVENING_PUSH = 'EVENING',
}

// Base payload interface
interface BasePayload {
  id: string;
  position: number;
}

// Specific payload types for each section
export interface BannerPayload extends BasePayload {
  type: DynamicSectionType.BANNER;
  bannerName: string;
  bannerUrl: string;
  desktopImageId: string;
  mobileImageId: string;
}

export interface OfferPayload extends BasePayload {
  type: DynamicSectionType.OFFER;
  heading: string;
  offerIds: Array<string>;
}

export interface FlightRow {
  departingCity: string;
  arrivalCity: string;
  pricePerPerson: string;
  tripType: string;
  url: string;
  imageId?: string;
}

export interface FlightPayload extends BasePayload {
  type: DynamicSectionType.FLIGHT;
  heading: string;
  flights: Array<FlightRow>;
}

export interface DreamPayload extends BasePayload {
  type: DynamicSectionType.DREAM_ARTICLE;
  destination: string;
  heading: string;
  subheading: string;
  articleUrl: string;
  desktopImageId: string;
  mobileImageId: string;
}

export interface DestinationRow {
  name: string;
  redirectUrl: string;
  imageId?: string;
}

export interface TrendingDestinationPayload extends BasePayload {
  type: DynamicSectionType.TRENDING_DESTINATION;
  heading: string;
  destinations: Array<DestinationRow>;
}

export interface CallToActionPayload extends BasePayload {
  type: DynamicSectionType.CALL_TO_ACTION;
  text: string;
  url: string;
}

export interface PushPayload extends BasePayload {
  pushTitle: string;
  pushSubtitle: string;
  pushMessage: string;
  pushLink: string;
  pushSvcImageId: string;
}

export interface DefaultPushPayload extends PushPayload {
  type: DynamicSectionType.DEFAULT_PUSH;
}

export interface EveningPushPayload extends PushPayload {
  type: DynamicSectionType.EVENING_PUSH;
}

// Union type for all possible payloads
export type SectionPayload =
  | BannerPayload
  | OfferPayload
  | FlightPayload
  | DreamPayload
  | TrendingDestinationPayload
  | CallToActionPayload
  | DefaultPushPayload
  | EveningPushPayload;

// Type guard functions to check payload types
export const isBannerPayload = (payload: SectionPayload): payload is BannerPayload =>
  payload.type === DynamicSectionType.BANNER;

export const isOfferPayload = (payload: SectionPayload): payload is OfferPayload =>
  payload.type === DynamicSectionType.OFFER;

export const isFlightPayload = (payload: SectionPayload): payload is FlightPayload =>
  payload.type === DynamicSectionType.FLIGHT;

export const isDreamPayload = (payload: SectionPayload): payload is DreamPayload =>
  payload.type === DynamicSectionType.DREAM_ARTICLE;

export const isTrendingDestinationPayload = (payload: SectionPayload): payload is TrendingDestinationPayload =>
  payload.type === DynamicSectionType.TRENDING_DESTINATION;

export const isCallToActionPayload = (payload: SectionPayload): payload is CallToActionPayload =>
  payload.type === DynamicSectionType.CALL_TO_ACTION;

export const isDefaultPushPayload = (payload: SectionPayload): payload is DefaultPushPayload =>
  payload.type === DynamicSectionType.DEFAULT_PUSH;

export const isEveningPushPayload = (payload: SectionPayload): payload is EveningPushPayload =>
  payload.type === DynamicSectionType.EVENING_PUSH;

export type DynamicSection = {
  id: string;
  sectionId: DynamicSectionType;
  position: number;
  payload: SectionPayload;
};

export type DeleteConfirmation = {
  open: boolean;
  sectionId: string | null;
};

export const useDynamicSections = () => {
  const [sections, setSections] = useState<Array<DynamicSection>>([]);
  const [deleteConfirmation, setDeleteConfirmation] = useState<DeleteConfirmation>({
    open: false,
    sectionId: null,
  });

  const getSectionCount = useCallback(
    (sectionType: DynamicSectionType) => sections.filter((section) => section.sectionId === sectionType).length,
    [sections],
  );

  const addSection = useCallback((sectionType: DynamicSectionType, payload: SectionPayload) => {
    setSections((prev) => {
      const newSection: DynamicSection = {
        sectionId: sectionType,
        id: `${sectionType}_${Date.now()}`,
        position: prev.length + 1,
        payload,
      };
      const newSections = [...prev, newSection];
      return newSections.map((section, index) => ({
        ...section,
        position: index + 1,
      }));
    });
  }, []);

  const handleDeleteClick = useCallback((sectionId: string) => {
    setDeleteConfirmation({
      open: true,
      sectionId,
    });
  }, []);

  const handleDeleteCancel = useCallback(() => {
    setDeleteConfirmation({
      open: false,
      sectionId: null,
    });
  }, []);

  const handleDeleteConfirm = useCallback(() => {
    if (deleteConfirmation.sectionId) {
      setSections((prev) => {
        const newSections = prev.filter((section) => section.id !== deleteConfirmation.sectionId);
        return newSections.map((section, index) => ({
          ...section,
          position: index + 1,
        }));
      });
    }
    handleDeleteCancel();
  }, [deleteConfirmation.sectionId, handleDeleteCancel]);

  return {
    sections,
    deleteConfirmation,
    getSectionCount,
    addSection,
    handleDeleteClick,
    handleDeleteCancel,
    handleDeleteConfirm,
    setSections,
  };
};
