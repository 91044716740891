import React, { useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';

import { Check, Close, SvgIconComponent } from '@mui/icons-material';
import { Box, CircularProgress, Fab } from '@mui/material';
import { green, red } from '@mui/material/colors';

const progressStyle = {
  color: green[500],
  position: 'absolute',
  top: -6,
  left: -6,
  zIndex: 1,
};

interface Props {
  DefaultIcon: SvgIconComponent;
  onSave: () => Promise<void>;
  disabled?: boolean;
  width?: number;
  height?: number;
}

export default function LoadingButton({ onSave, DefaultIcon, disabled = false, width = 40, height = 40 }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const styles = useMemo(
    () => ({
      base: { width, height },
      error: { width, height, bgcolor: red[500], '&:hover': { bgcolor: red[700] } },
      success: { width, height, bgcolor: green[500], '&:hover': { bgcolor: green[700] } },
    }),
    [width, height],
  );
  const [{ loading, success, error }, setState] = useState({
    loading: false,
    success: false,
    error: false,
  });

  const handleClick = async () => {
    setState({ loading: true, success: false, error: false });
    try {
      await onSave();
      setState({ loading: false, success: true, error: false });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error?.errors?.message || error?.errors?.[0]?.message || error?.message || 'An error occurred.', {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
        variant: 'error',
      });
      setState({ loading: false, success: false, error: true });
    }
  };

  return (
    <Box sx={{ m: 1, position: 'relative' }}>
      <Fab
        color="primary"
        sx={success ? styles.success : error ? styles.error : styles.base}
        disabled={loading || disabled}
        onClick={handleClick}
      >
        {success && <Check sx={{ color: 'white' }} />}
        {error && <Close sx={{ color: 'white' }} />}
        {!success && !error && <DefaultIcon sx={{ color: 'white' }} />}
      </Fab>
      {loading && <CircularProgress size={52} sx={progressStyle} />}
    </Box>
  );
}
