import { GridColDef } from '@mui/x-data-grid';

import {
  customerDataFormatter,
  internalInformationFormatter,
  orderCurrencyFormatter,
  orderDateFormatter,
  orderInformationFormatter,
  orderLinkFormatter,
  orderTotalFormatter,
  packageInformationFormatter,
} from '../formatters';

export const columns: Array<GridColDef> = [
  {
    field: 'fk_customer_id',
    headerName: 'Customer',
    sortable: false,
    width: 250,
    renderCell: customerDataFormatter,
    display: 'flex',
  },
  {
    field: 'order_information',
    headerName: 'Order information',
    sortable: false,
    flex: 2,
    renderCell: orderInformationFormatter,
    display: 'flex',
  },
  {
    field: 'checkin_departure',
    headerName: 'Check In / Departure',
    sortable: false,
    flex: 2,
    renderCell: orderDateFormatter,
    display: 'flex',
  },
  {
    field: 'package_information',
    headerName: 'Package Information',
    sortable: false,
    flex: 2,
    renderCell: packageInformationFormatter,
    display: 'flex',
  },
  {
    field: 'internal_information',
    headerName: 'Internal Information',
    sortable: false,
    flex: 3,
    renderCell: internalInformationFormatter,
    display: 'flex',
  },
  {
    field: 'currency',
    headerName: 'Currency',
    sortable: false,
    flex: 1,
    renderCell: orderCurrencyFormatter,
    display: 'flex',
  },
  {
    field: 'order_total',
    headerName: 'Order Total',
    sortable: false,
    flex: 1,
    renderCell: orderTotalFormatter,
    display: 'flex',
  },
  { field: 'actions', headerName: 'Details', sortable: false, renderCell: orderLinkFormatter, display: 'flex' },
];
