export const filterFields = ({
  showRebookableFilter = false,
  showMultiSearch = false,
  showRoomMappingCheckFilter = false,
}) => {
  let fields = [
    {
      key: 'currency_code',
      value: 'Currency',
    },
    {
      key: 'status',
      value: 'Status',
    },
    {
      key: 'utm_source',
      value: 'Source',
    },
    {
      key: 'created_at',
      value: 'Purchase Date',
    },
  ];

  if (showRebookableFilter) {
    fields.push({
      key: 'rebookable',
      value: 'Rebookable',
    });
  }

  if (showRoomMappingCheckFilter) {
    fields.push({
      key: 'room_mapping_check',
      value: 'Room Mapping Check',
    });
  }

  if (showMultiSearch) {
    fields.push({
      key: 'multi_search',
      value: 'Multi Search',
    });
  }

  return fields;
};

export const filterDropDownList = {
  status: [
    {
      key: 'pending',
      value: 'Pending',
    },
    {
      key: 'awaiting_purchase',
      value: 'Awaiting Purchase',
    },
    {
      key: 'awaiting_payment',
      value: 'Awaiting Payment',
    },
    {
      key: 'processing',
      value: 'Processing',
    },
    {
      key: 'payments_created',
      value: 'Payments Created',
    },
    {
      key: 'abandoned',
      value: 'Abandoned',
    },
    {
      key: 'cancelled',
      value: 'Cancelled',
    },
    {
      key: 'completed',
      value: 'completed',
    },
    {
      key: 'payment_failed',
      value: 'Payment Failed',
    },
  ],
  utm_source: [
    {
      value: 'Luxury Escapes',
      key: 'luxuryescapes',
    },
    {
      value: 'Scoopon',
      key: 'scoopontravel',
    },
    {
      value: 'Cudo',
      key: 'cudo',
    },
    {
      value: 'Deals',
      key: 'dealstravel',
    },
    {
      value: 'Treat Me',
      key: 'treatmetravel',
    },
    {
      value: 'Kogan Travel',
      key: 'kogantravel',
    },
    {
      value: 'LE Business Traveller',
      key: 'lebusinesstraveller',
    },
    {
      value: 'LE Agent Hub',
      key: 'leagenthub',
    },
  ],
};

export const sortFields = [
  {
    key: 'created_at',
    value: 'Created at',
  },
];

export const sortRebookableFields = [
  {
    key: 'created_at',
    value: 'Last Scanned',
  },
  {
    key: 'check_in',
    value: 'Check In',
  },
  {
    key: 'margin_increase_percentage',
    value: 'Incremental Margin Percentage',
  },
  {
    key: 'margin_increase',
    value: 'Incremental Margin',
  },
  {
    key: 'margin_increase_in_aud',
    value: 'Incremental Margin (AUD)',
  },
];

export const multiSearchItemTypes = [
  {
    key: 'Accommodation items',
    value: 'accommodation',
  },
  {
    key: 'Bundle and Save items',
    value: 'bundle_and_save',
  },
  {
    key: 'Bedbank items',
    value: 'bedbank',
  },
  {
    key: 'Tour items',
    value: 'tour',
  },
  {
    key: 'Cruise',
    value: 'cruise',
  },
];
