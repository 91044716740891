import React from 'react';

import { Info as InfoIcon } from '@mui/icons-material';
import { Box, Button, Collapse, Stack, Typography } from '@mui/material';

import useToggleState from '~/hooks/useToggleState';

import { diffDays, isBefore } from '~/services/TimeService';

import { getCancellationPolicyLabel } from '~/utils/cancellationPolicy';
import { formatOrderDate } from '~/utils/order';

type Props = {
  policy: App.CancellationPolicyDisplay;
  isItemCancelled: boolean;
};

const buildMultiLinePolicyDetail = (details) => {
  const detailTextArray = Array.isArray(details) ? details : details.split('\n');

  return detailTextArray.map((p, i) => (
    <Typography key={i} variant="body2">
      {p}
    </Typography>
  ));
};

function CancellationPolicy({ policy, isItemCancelled }: Props) {
  const { isToggled, toggle } = useToggleState(false);

  const hasPolicyDate = !!policy.deadline;
  const lastDayCancellation = formatOrderDate(policy.deadline);

  const willExpireToday = diffDays(policy.deadline, new Date(), 'day') === 0;
  const isLastDayPast = isBefore(policy.deadline, new Date()) && !willExpireToday;

  let baseColor = isItemCancelled ? 'grey' : 'black';
  let detailColor = 'green';
  if (isItemCancelled) {
    detailColor = 'grey';
  } else if (isLastDayPast) {
    baseColor = 'red';
    detailColor = 'red';
  }

  return (
    <Box>
      {policy.title && (
        <Stack direction="row" spacing={1} alignItems="center">
          <InfoIcon fontSize="small" color="secondary" />

          <Button onClick={toggle} variant="text" size="small">
            {getCancellationPolicyLabel(policy.title)}
          </Button>
        </Stack>
      )}

      <Collapse in={isToggled} timeout="auto" unmountOnExit>
        <Stack direction="column" spacing={1} maxWidth={250}>
          {!policy.description && <p>No Policy text defined</p>}
          {policy.description && buildMultiLinePolicyDetail(policy.description)}
        </Stack>
      </Collapse>

      {hasPolicyDate && policy.isRefundable && (
        <Box>
          <Typography component="span" color={baseColor}>
            Cancellable until{' '}
          </Typography>
          <Typography fontWeight="bold" component="span" color={detailColor}>
            {lastDayCancellation}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default CancellationPolicy;
