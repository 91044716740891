import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';

import { CircularProgress } from '@mui/material';

import BedbankService from '~/services/BedbankService';

import NestedAccordion from './NestedAccordion';

export default function DynamicConfigurationsPage() {
  const [data, setData] = useState<Record<string, unknown> | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    async function fetchConfiguration() {
      try {
        const data = await BedbankService.getDynamicConfigurations();
        setData(data.result);
      } catch (e) {
        console.error(e);
        enqueueSnackbar('Error loading dynamic configurations', { variant: 'error' });
      }
    }

    fetchConfiguration();
  }, []);

  if (data === null) {
    return <CircularProgress />;
  }

  return (
    <>
      {Object.entries(data).map(([key, value]) => (
        <NestedAccordion key={key} keyName={key} value={value} />
      ))}
    </>
  );
}
