import React from 'react';

import { GREY } from '~/configs/colours';

import { Box, Button, SxProps, Typography } from '@mui/material';

import { DEFAULT_HEADER_HEIGHT } from './constants';

const HEADER_STYLE: SxProps = {
  display: 'flex',
  flexDirection: { xs: 'column', md: 'row' },
  justifyContent: { md: 'space-between' },
  borderBottom: { xs: 'none', md: `1px solid ${GREY[100]}` },
  bgcolor: 'background.paper',
  height: DEFAULT_HEADER_HEIGHT,
  px: { xs: 2, md: 3 },
  py: { xs: 1, md: 2 },
};

export default function OpportunityHeader() {
  return (
    <Box sx={HEADER_STYLE}>
      <Typography
        variant="h3"
        sx={{
          fontSize: '1.5rem',
        }}
      >
        Opportunity Title
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: '16px', py: { xs: 1, md: 0 } }}>
        <Button variant="outlined" color="primary">
          Inventory
        </Button>
        <Button variant="outlined" color="primary">
          Property Info
        </Button>
      </Box>
    </Box>
  );
}
