import React from 'react';

import currencyFormatter from 'currency-formatter';
import { last } from 'lodash';
import { useSelector } from 'react-redux';

import { Box, Stack, Typography } from '@mui/material';

import { getDisputeBoxStyle, selectShowDisputeProcess } from '~/components/Purchases/utils/getDisputeProcess';

import { titleCase } from '~/utils/stringUtils';

import Flights from './Flights';
import OrderFlightFareFamilyInclusions from './OrderFlightFareFamilyInclusions';

export const mapPassengerPlural = {
  child: 'children',
  adult: 'adults',
  infant: 'infants',
};

const mapPassengerSingular = {
  children: 'child',
  adults: 'adult',
  infants: 'infant',
};

export const getPerPersonBreakdown = (priceBreakdown, travellers: FlightTraveller[]) => {
  const travellerCount = travellers.reduce(
    (acc, t) => {
      acc[mapPassengerPlural[t.type]] += 1;
      return acc;
    },
    {
      adults: 0,
      children: 0,
      infants: 0,
    },
  );

  return Object.keys(travellerCount).reduce((acc, travellerType) => {
    if (travellerCount[travellerType] > 0 && priceBreakdown[travellerType] !== undefined) {
      acc[travellerType] = priceBreakdown[travellerType].cost;
    }
    return acc;
  }, {});
};

export const getPerPersonBreakdownWithJourneyFares = (fares, travellers: FlightTraveller[]) => {
  const travellerCount = travellers.reduce(
    (acc, t) => {
      acc[mapPassengerPlural[t.type]] += 1;
      return acc;
    },
    {
      adults: 0,
      children: 0,
      infants: 0,
    },
  );

  const accumulatedBreakdown = fares.reduce((acc, fare) => {
    const farePrice = fare.price;
    const keys = ['adult', 'child', 'infant'];
    for (const key of keys) {
      const priceBreakdown = farePrice[key];
      if (priceBreakdown) {
        if (!(key in acc)) {
          acc[key] = {};
        }

        const accKey = acc[key];
        for (const breakdownAttribute of Object.keys(priceBreakdown)) {
          if (!accKey[breakdownAttribute]) {
            accKey[breakdownAttribute] = priceBreakdown[breakdownAttribute];
          } else {
            accKey[breakdownAttribute] += priceBreakdown[breakdownAttribute];
          }
        }
      }
    }
    return acc;
  }, {});

  return Object.keys(travellerCount).reduce((acc, travellerType) => {
    const key = mapPassengerSingular[travellerType];
    if (travellerCount[travellerType] > 0 && accumulatedBreakdown[key] !== undefined) {
      acc[travellerType] = accumulatedBreakdown[key].total;
    }
    return acc;
  }, {});
};

interface Props {
  refreshData: () => void;
  itemId: string;
  itemStatus: string;
  orderId: string;
  flightDetails: any;
  bookingJourneyLeg: App.BookingJourneyLeg;
  updateTravellerRefundStatus: (orderId: string, itemId: string, travellerId: string, status: string) => void;
  itemRefundStatus: string;
  fareType: string;
  fees: number;
  currency: string;
}

const OrderFlightItem = (props: Props) => {
  const { itemStatus, flightDetails, bookingJourneyLeg, fareType, fees, currency } = props;
  const departingFlight = bookingJourneyLeg.flights[0];
  const arrivalFlight = last(bookingJourneyLeg.flights);
  const showDisputeProcess = useSelector(selectShowDisputeProcess);

  return (
    <>
      <div className="T-Order-Flight-Item-List">
        <Box sx={getDisputeBoxStyle(showDisputeProcess)}>
          <Typography>
            {departingFlight.departureCity} ({departingFlight.departureAirport}) {'>'} {arrivalFlight.arrivalCity} (
            {arrivalFlight.arrivalAirport})
          </Typography>

          <Stack direction="row" my={1} justifyContent="space-between" alignItems="center">
            <Stack direction="column" justifyContent="space-between" spacing={1}>
              <Typography fontWeight="bold">Provider Booking Ref (PNR)</Typography>
              <Typography>{flightDetails.pnr_id}</Typography>
            </Stack>
            <Stack direction="column" justifyContent="space-between" spacing={1}>
              <Typography fontWeight="bold">Airline Booking Ref (PNR)</Typography>
              <Typography>{bookingJourneyLeg.pnr ?? '-'}</Typography>
            </Stack>
            <Stack direction="column" justifyContent="space-between" spacing={1}>
              <Typography fontWeight="bold">Provider</Typography>
              <Typography>{bookingJourneyLeg.provider}</Typography>
            </Stack>
            <Stack direction="column" justifyContent="space-between" spacing={1}>
              <Typography fontWeight="bold">Fare Type</Typography>
              <Typography>{fareType}</Typography>
            </Stack>
            {bookingJourneyLeg.selectedFare?.selectionType === 'UPSELL' && (
              <Stack direction="column" justifyContent="space-between" spacing={1}>
                <Typography fontWeight="bold">Fare Family</Typography>
                <Typography>{titleCase(bookingJourneyLeg.selectedFare?.description ?? '')}</Typography>
              </Stack>
            )}
            <Stack direction="column" justifyContent="space-between" spacing={1}>
              <Typography fontWeight="bold">Airline</Typography>
              <Typography>{departingFlight.marketingAirline.name}</Typography>
            </Stack>

            <Stack direction="column" justifyContent="space-between" spacing={1}>
              <Typography fontWeight="bold">{`Price ${fees ? '+ fees' : ''}`}</Typography>
              <Typography>
                {currencyFormatter.format(bookingJourneyLeg.price.total.total, { code: currency })}
              </Typography>
            </Stack>
            <Stack direction="column" justifyContent="space-between" spacing={1}>
              <Typography fontWeight="bold">Flight Item Status</Typography>
              <Typography>{itemStatus}</Typography>
            </Stack>
          </Stack>
          <Flights leg={bookingJourneyLeg} />
          <Stack direction="row" gap={2}>
            <Typography fontWeight="bold">Fare class:</Typography>
            <Typography>
              {bookingJourneyLeg.bookingInfo.cabinClass} ({bookingJourneyLeg.bookingInfo.bookingClass})
            </Typography>
          </Stack>
        </Box>
      </div>

      <OrderFlightFareFamilyInclusions inclusions={bookingJourneyLeg.inclusions} />
    </>
  );
};

export default OrderFlightItem;
