import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';

import { useProgressPolling } from '~/components/Accommodation/hooks/useProgressPolling';
import Spinner from '~/components/Common/Spinner';

import { ProgressStatusResponse } from '~/services/AccommodationService';
import { HttpResponse } from '~/services/types';

import ProgressStateItem from './ProgressStateItem';

type Props = {
  open: boolean;
  apiCall: () => Promise<HttpResponse<ProgressStatusResponse>>;
  onClose: () => void;
};

function AccommodationReimportSuppliersProgressModal({ open, apiCall, onClose }: Props) {
  const {
    isLoading,
    isComplete,
    statusMessage,
    errorMessage,
    propertyImportSvcRes,
    propertyImportSvcBedbank,
    validationErrors,
    runStartJob,
    resetAndClose,
  } = useProgressPolling({ apiCall, onClose });

  if (validationErrors.length) {
    return (
      <Dialog open={open} onClose={resetAndClose}>
        <DialogTitle>Resync all supplier properties?</DialogTitle>
        <DialogContent>
          <Stack spacing={4}>
            <Stack spacing={2}>
              {validationErrors.map(({ code, message }) => (
                <DialogContentText key={code}>{message}</DialogContentText>
              ))}
            </Stack>
            <DialogContentText>
              Are you sure you want to{' '}
              <Typography component="span" color="error">
                resync
              </Typography>{' '}
              all supplier properties?
            </DialogContentText>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={resetAndClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={() => runStartJob(validationErrors.map((e) => e.code))} color="error">
            Yes, resync properties
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Dialog open={open} onClose={resetAndClose}>
      <DialogTitle>Resync Supplier Properties</DialogTitle>
      <DialogContent sx={{ minWidth: '400px' }}>
        <Stack spacing={2}>
          {!isLoading && !isComplete && (
            <DialogContentText>
              This will resync all supplier properties. Are you sure you want to continue?
            </DialogContentText>
          )}

          {propertyImportSvcRes && (
            <ProgressStateItem label="Importing LE properties" state={propertyImportSvcRes.state} />
          )}
          {propertyImportSvcBedbank && (
            <ProgressStateItem label="Importing Bedbank properties" state={propertyImportSvcBedbank.state} />
          )}
          {isComplete && statusMessage && <DialogContentText color="primary">{statusMessage}</DialogContentText>}
          {errorMessage && <DialogContentText color="error">{errorMessage}</DialogContentText>}
        </Stack>
      </DialogContent>
      {!isComplete && (
        <DialogActions>
          <Button variant="text" onClick={resetAndClose} disabled={isLoading}>
            Cancel
          </Button>
          <Button variant="contained" onClick={() => runStartJob()} disabled={isLoading}>
            {isLoading ? <Spinner size={15} inline /> : 'Resync'}
          </Button>
        </DialogActions>
      )}
      {isComplete && (
        <DialogActions>
          <Stack direction="row" spacing={2} alignItems="center">
            <Button variant="contained" onClick={resetAndClose} disabled={isLoading}>
              Close
            </Button>
          </Stack>
        </DialogActions>
      )}
    </Dialog>
  );
}

export default AccommodationReimportSuppliersProgressModal;
