import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Autocomplete, Chip, TextField } from '@mui/material';

import { RESULTS_PER_PAGE } from '~/components/Cruises/context/usePorts';
import { Port } from '~/components/Cruises/pages/Ports/types';
import { Option } from '~/components/Cruises/pages/Promotions/types';

import portService from '~/services/cruises/PortService';

type DeparturePortResponse = {
  loading: boolean;
  result: Array<Port>;
};

interface Props {
  selectedValues: Array<Port>;
  setSelectedValues: (values: (prevState: Array<Port>) => Array<Port>) => void;
  departurePorts: Array<number>;
}

const InputDeparturePortSelect = (props: Props) => {
  const { selectedValues, setSelectedValues, departurePorts } = props;
  const [departures, setDepartures] = useState<DeparturePortResponse>({
    result: [],
    loading: false,
  });

  const getDeparturePortList = useCallback(async (name?: string) => {
    setDepartures((prev) => ({ ...prev, loading: true }));
    const res = await portService.listWithPagination({
      ...(!!name && { name }),
      take: RESULTS_PER_PAGE,
    });
    const resultMapped = res.result?.map((item) => ({
      ...item,
    }));

    setDepartures({ loading: false, result: resultMapped } as DeparturePortResponse);
  }, []);

  const departuresSelected = useMemo(() => {
    return selectedValues?.map((item) => ({
      label: item.name,
      value: item.externalId ? String(item.externalId) : undefined,
    }));
  }, [selectedValues]);

  const handleDeparturesChange = useCallback(
    (_, selectedOptions: Option[] | null) => {
      setSelectedValues((prevState: Array<Port>) => {
        if (!selectedOptions) {
          return prevState;
        }

        return selectedOptions
          .map((selectedOffer) => {
            return {
              id: '',
              code: null,
              timeZone: '',
              externalId: selectedOffer.value ? Number(selectedOffer.value) : null,
              name: selectedOffer.label,
              nearestAirportCode: null,
              address: null,
              city: null,
              stateProvince: null,
              countryCode: null,
              latitude: null,
              longitude: null,
              portInfo: null,
            } as Port;
          })
          .filter(Boolean) as Array<Port>;
      });
    },
    [setSelectedValues],
  );

  const departureSelectOptions = useMemo(() => {
    return departures.result
      ?.filter((item) => !selectedValues.find((selected) => selected.externalId === item.externalId))
      ?.map((item) => ({
        label: item.name,
        value: item.externalId ? String(item.externalId) : undefined,
      }));
  }, [departures.result, selectedValues]);

  const setDeparturesFromQuery = useCallback(
    async (externalIds?: Array<number>) => {
      if (!externalIds?.length) {
        return;
      }

      const result = await Promise.all(
        externalIds?.map(async (externalId) => {
          const { result } = await portService.listWithPagination({
            ...(externalId && { externalId }),
            take: RESULTS_PER_PAGE,
          });
          return result;
        }),
      );
      const externalIdPorts = result.flat();
      setSelectedValues(() => {
        return externalIdPorts;
      });
    },
    [setSelectedValues],
  );

  useEffect(() => {
    getDeparturePortList();
    setDeparturesFromQuery(departurePorts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Autocomplete
      data-testid="cruise-input-departure-port"
      multiple
      selectOnFocus
      clearOnBlur
      value={departuresSelected}
      loading={departures.loading}
      onChange={handleDeparturesChange}
      options={departureSelectOptions}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            size="small"
            variant="outlined"
            key={option.value}
            label={option.label ?? ''}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        <TextField {...params} fullWidth label="Departure ports" placeholder={`Please Enter Departure Ports`} />
      )}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      onInputChange={(_, value) => getDeparturePortList(value)}
    />
  );
};

export default InputDeparturePortSelect;
