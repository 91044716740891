import React, { forwardRef } from 'react';

import { Dayjs } from 'dayjs';

import { FormControl, Stack } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers-pro';

import { newDate } from '~/services/TimeService';

import ScheduleUserProfileInputsGroup from './Inputs/ScheduleUserProfileInputsGroup';

export enum SCHEDULE_BASE_INPUT_NAMES {
  SEND_DATE = 'schedule_base_send_date',
  BRAND_ID = 'schedule_base_brand_id',
  COUNTRY_GROUP_ID = 'schedule_base_country_group_id',
  COUNTRY_ID = 'schedule_base_country_id',
  STATE_ID = 'schedule_base_state_id',
  CITY_ID = 'schedule_base_city_id',
  SEGMENT_ID = 'schedule_base_segment_id',
  CADENCE_ID = 'schedule_base_cadence_id',
  MEMBERSHIP_ID = 'schedule_base_membership_id',
}

export function parseScheduleBaseFormData(
  formData: FormData,
): Pick<
  CustomerCommunication.UnsavedHeroPlannerSchedule,
  | 'sendDate'
  | 'countryGroupId'
  | 'countryId'
  | 'stateId'
  | 'cityId'
  | 'segmentId'
  | 'brandId'
  | 'cadenceId'
  | 'membershipId'
> {
  return {
    sendDate: newDate(String(formData.get(SCHEDULE_BASE_INPUT_NAMES.SEND_DATE)), 'DD/MM/YYYY'),
    brandId: String(formData.get(SCHEDULE_BASE_INPUT_NAMES.BRAND_ID)),
    countryGroupId: String(formData.get(SCHEDULE_BASE_INPUT_NAMES.COUNTRY_GROUP_ID)),
    countryId: String(formData.get(SCHEDULE_BASE_INPUT_NAMES.COUNTRY_ID)),
    stateId: String(formData.get(SCHEDULE_BASE_INPUT_NAMES.STATE_ID)),
    cityId: String(formData.get(SCHEDULE_BASE_INPUT_NAMES.CITY_ID)),
    segmentId: String(formData.get(SCHEDULE_BASE_INPUT_NAMES.SEGMENT_ID)),
    cadenceId: String(formData.get(SCHEDULE_BASE_INPUT_NAMES.CADENCE_ID)),
    membershipId: String(formData.get(SCHEDULE_BASE_INPUT_NAMES.MEMBERSHIP_ID)),
  };
}

interface Props {
  sendDateValue: Dayjs;
  onSendDateChange: (sendDate: Dayjs) => void;
  brandValue: string;
  onBrandChange: (brand: string) => void;
  countryGroupIdValue: string;
  onCountryGroupChange: (countryGroup: string) => void;
  countryIdValue: string;
  onCountryChange: (country: string) => void;
  stateIdDefaultValue: string;
  cityIdDefaultValue: string;
  segmentIdDefaultValue: string;

  cadenceIdDefaultValue: string;
  membershipIdDefaultValue: string;
  onSegmentChange?: (segment: string) => void;
}

const ScheduleBaseForm = forwardRef<HTMLFormElement, Props>((props, ref) => {
  const {
    sendDateValue,
    brandValue,
    countryGroupIdValue,
    countryIdValue,

    stateIdDefaultValue,
    segmentIdDefaultValue,
    cityIdDefaultValue,
    cadenceIdDefaultValue,
    membershipIdDefaultValue,

    onSendDateChange,
    onBrandChange,
    onCountryChange,
    onCountryGroupChange,
    onSegmentChange,
  } = props;

  return (
    <Stack component="form" ref={ref} direction="column" gap={2}>
      <Stack direction="row" gap={2}>
        <FormControl>
          <DatePicker
            name={SCHEDULE_BASE_INPUT_NAMES.SEND_DATE}
            value={sendDateValue}
            onChange={onSendDateChange}
            label="Send Date"
            disablePast
            format="DD/MM/YYYY"
            slotProps={{
              textField: {
                variant: 'filled',
                required: true,
              },
            }}
          />
        </FormControl>
      </Stack>

      <ScheduleUserProfileInputsGroup
        brandIdInputName={SCHEDULE_BASE_INPUT_NAMES.BRAND_ID}
        brandIdValue={brandValue}
        onBrandChange={onBrandChange}
        countryGroupIdInputName={SCHEDULE_BASE_INPUT_NAMES.COUNTRY_GROUP_ID}
        countryGroupIdValue={countryGroupIdValue}
        onCountryGroupChange={onCountryGroupChange}
        countryIdInputName={SCHEDULE_BASE_INPUT_NAMES.COUNTRY_ID}
        countryIdValue={countryIdValue}
        onCountryChange={onCountryChange}
        stateIdInputName={SCHEDULE_BASE_INPUT_NAMES.STATE_ID}
        stateIdDefaultValue={stateIdDefaultValue}
        cityIdInputName={SCHEDULE_BASE_INPUT_NAMES.CITY_ID}
        cityIdDefaultValue={cityIdDefaultValue}
        segmentIdDefaultValue={segmentIdDefaultValue}
        segmentIdInputName={SCHEDULE_BASE_INPUT_NAMES.SEGMENT_ID}
        cadenceIdDefaultValue={cadenceIdDefaultValue}
        cadenceIdInputName={SCHEDULE_BASE_INPUT_NAMES.CADENCE_ID}
        membershipIdInputName={SCHEDULE_BASE_INPUT_NAMES.MEMBERSHIP_ID}
        membershipIdDefaultValue={membershipIdDefaultValue}
        onSegmentChange={onSegmentChange}
      />
    </Stack>
  );
});

ScheduleBaseForm.displayName = 'ScheduleBaseForm';
export default ScheduleBaseForm;
