import React, { useCallback, useMemo, useState } from 'react';

import capitalize from 'lodash/capitalize';

import { Button, Link, Stack } from '@mui/material';
import { GridCellParams } from '@mui/x-data-grid';
import {
  DataGridPro,
  GridCellModes,
  GridCellModesModel,
  GridColDef,
  GridRenderCellParams,
  GridRowSelectionModel,
} from '@mui/x-data-grid-pro';

import GridPagination from '~/components/Common/Elements/GridPagination';

import {
  BEDBANK_COMMENTS_ENUM,
  BEDBANK_PROFILES_ENUM,
  BEDBANK_PROPERTY_DOMAINS_ENUM,
  BEDBANK_PROPERTY_TYPES_ENUM,
  SUPPLIER_NAMES,
} from '~/consts/bedbank';

import BedbankRowExpansionContent from './BedbankRowExpansionContent';
import CheckboxGroup from './CheckboxGroup';
import DomainCell from './DomainCell';
import PreviewCell from './PreviewCell';

interface Property {
  id: string;
  name: string;
  rating: number;
}

interface Props {
  count: number;
  data: Array<Property>;
  handleSelect: (selectedIds: GridRowSelectionModel) => void;
  onOpenModalBulkUpdate: () => void;
  onOpenModalImage: (id: string) => void;
  onPageChange: (page: number, sizePerPage: number) => void;
  onStatusUpdate: (newRow) => any;
  page: number;
  selected: GridRowSelectionModel;
  sizePerPage: number;
  updateImageData: (images: Array<any>, rowId: string) => void;
}

const urlBuilder = (bedbankPropertyId: string) => {
  return `/bedbank/properties/${bedbankPropertyId}`;
};

export default function BedbankPropertiesTable({
  count,
  data,
  handleSelect,
  onOpenModalBulkUpdate,
  onOpenModalImage,
  onPageChange,
  onStatusUpdate,
  page,
  selected,
  sizePerPage,
  updateImageData,
}: Props) {
  const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({});

  // src: https://mui.com/x/react-data-grid/recipes-editing/#single-click-editing
  const handleCellClick = useCallback((params: GridCellParams, event: React.MouseEvent) => {
    if (!params.isEditable) {
      return;
    }

    // Ignore portal
    if ((event.target as any).nodeType === 1 && !event.currentTarget.contains(event.target as Element)) {
      return;
    }

    setCellModesModel((prevModel) => {
      return {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View },
              }),
              {},
            ),
          }),
          {},
        ),
        [params.id]: {
          // Revert the mode of other cells in the same row
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
            {},
          ),
          [params.field]: { mode: GridCellModes.Edit },
        },
      };
    });
  }, []);

  const handleOpenModalBulkUpdate = (event) => {
    event.preventDefault();
    onOpenModalBulkUpdate();
  };

  const columns: Array<GridColDef> = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'Id',
        disableColumnMenu: true,
        renderCell: ({ row, value }) => (
          <Link color="inherit" target="_blank" rel="noreferrer" href={urlBuilder(row.id)} underline="hover">
            {value ?? '-'}
          </Link>
        ),
        flex: 2,
        display: 'flex',
      },
      {
        field: 'expediaId',
        headerName: 'Suppliers',
        disableColumnMenu: true,
        display: 'flex',
        renderCell: ({ row }: GridRenderCellParams) => (
          <Stack>
            {SUPPLIER_NAMES.map((supplier) => (
              <>
                {row[`${supplier}Id`] ? (
                  <b>
                    {capitalize(supplier)}: {row[`${supplier}Id`]}
                  </b>
                ) : (
                  ''
                )}
              </>
            ))}
          </Stack>
        ),
        flex: 2,
      },
      {
        field: 'rating',
        headerName: 'Rating',
        disableColumnMenu: true,
        flex: 1,
        display: 'flex',
      },
      {
        field: 'name',
        headerName: 'Name',
        disableColumnMenu: true,
        flex: 2,
        display: 'flex',
      },
      {
        field: 'country',
        headerName: 'Address',
        disableColumnMenu: true,
        display: 'flex',
        renderCell: ({ row }: GridRenderCellParams) => (
          <Link href={urlBuilder(row.id)} target="_blank" rel="noreferrer" underline="hover" color="inherit">
            {row.country ? <b>{row.country},</b> : '-'}
            <br />
            {row.stateProvinceName ? `${row.stateProvinceName},` : '-'}
            <br />
            {row.city ?? '-'}
          </Link>
        ),
        flex: 2,
      },
      {
        field: 'type',
        headerName: 'Type',
        type: 'singleSelect',
        valueOptions: BEDBANK_PROPERTY_TYPES_ENUM,
        editable: true,
        disableColumnMenu: true,
        renderCell: ({ value }: GridRenderCellParams) => <Link>{value}</Link>,
        flex: 2,
        display: 'flex',
      },
      {
        field: 'profile',
        headerName: 'Profile',
        disableColumnMenu: true,
        type: 'singleSelect',
        valueOptions: BEDBANK_PROFILES_ENUM,
        editable: true,
        renderCell: ({ value }: GridRenderCellParams) => <Link>{value}</Link>,
        valueFormatter: (value) => capitalize(value),
        flex: 2,
        display: 'flex',
      },
      {
        field: 'chainName',
        headerName: 'Chain Name',
        disableColumnMenu: true,
        flex: 2,
        display: 'flex',
      },
      {
        field: 'status',
        headerName: 'Status',
        disableColumnMenu: true,
        display: 'flex',
        renderCell: ({ row, value }: GridRenderCellParams) => (
          <Stack>
            <CheckboxGroup
              id={row.id}
              value={value}
              onStatusUpdate={(value) => onStatusUpdate({ ...row, status: value })}
            />
          </Stack>
        ),
        flex: 3,
        minWidth: 180,
      },
      {
        field: 'domain',
        headerName: 'Domain',
        editable: true,
        type: 'singleSelect',
        valueOptions: BEDBANK_PROPERTY_DOMAINS_ENUM,
        disableColumnMenu: true,
        align: 'center',
        renderCell: DomainCell,
        flex: 3,
        display: 'flex',
      },
      {
        field: 'isPreferred',
        headerName: 'Is Preferred',
        type: 'boolean',
        editable: true,
        disableColumnMenu: true,
        renderCell: ({ value }: GridRenderCellParams) => <Link>{value ? 'Yes' : 'No'}</Link>,
        flex: 1,
        display: 'flex',
      },
      {
        field: 'preview',
        headerName: 'Preview',
        disableColumnMenu: true,
        renderCell: PreviewCell,
        flex: 2,
        display: 'flex',
      },
      {
        field: 'comment',
        headerName: 'Comment',
        disableColumnMenu: true,
        type: 'singleSelect',
        valueOptions: BEDBANK_COMMENTS_ENUM,
        editable: true,
        renderCell: ({ value }: GridRenderCellParams) => (value ? <Link>{value}</Link> : '-'),
        valueFormatter: (value) => capitalize(value),
        flex: 2,
        display: 'flex',
      },
    ],
    [onStatusUpdate],
  );

  return (
    <>
      <Button disabled={selected.length === 0} onClick={handleOpenModalBulkUpdate}>
        Update Selected Properties
      </Button>
      <DataGridPro
        autoHeight
        cellModesModel={cellModesModel}
        columns={columns}
        checkboxSelection
        disableRowSelectionOnClick
        getDetailPanelHeight={() => 'auto'}
        getDetailPanelContent={({ row }) => (
          <BedbankRowExpansionContent row={row} updateImageData={updateImageData} onOpenModalImage={onOpenModalImage} />
        )}
        getRowHeight={() => 'auto'}
        onRowSelectionModelChange={handleSelect}
        rowSelectionModel={selected}
        rows={data ?? []}
        pagination
        onCellModesModelChange={(newModel: GridCellModesModel) => setCellModesModel(newModel)}
        onCellClick={handleCellClick}
        processRowUpdate={(newRow, _oldRow) => onStatusUpdate(newRow)}
        onPaginationModelChange={({ page }) => onPageChange(page + 1, sizePerPage)}
        paginationModel={{ page: page - 1, pageSize: sizePerPage }}
        paginationMode="server"
        rowCount={count}
        density="compact"
        slots={{ pagination: GridPagination }}
      />
    </>
  );
}
