import React, { forwardRef } from 'react';

import { Stack, Typography } from '@mui/material';

import ScheduleContentBannerImageInput, { getBannerId } from './Inputs/ScheduleContentBannerImageInput';

interface Props {
  defaultEmailBannerInitial: string;
  emailBannerPosition2Initial: string;
  brand: string;
}
// we usually place a banner after the 4 deal (position 5) and a banner after the first deal (position 2)
enum SCHEDULE_BANNER_INPUT_NAMES {
  BANNER_IMAGE_ID_POS_2 = 'schedule_banner_image_id_pos_2', // banner in position 2 (after 1st deal)
  BANNER_IMAGE_ID_POS_5 = 'schedule_banner_image_id_pos_5', // Default Banner (after 4th deal)
}

export function parseScheduleBannerFormData(
  formData: FormData,
): Pick<CustomerCommunication.UnsavedHeroPlannerSchedule, 'emailBannerId' | 'emailBannerPosition2Id'> {
  return {
    emailBannerId: getBannerId(String(formData.get(SCHEDULE_BANNER_INPUT_NAMES.BANNER_IMAGE_ID_POS_5))),
    emailBannerPosition2Id: getBannerId(String(formData.get(SCHEDULE_BANNER_INPUT_NAMES.BANNER_IMAGE_ID_POS_2))),
  };
}

const ScheduleBannerForm = forwardRef<HTMLFormElement, Props>((props, ref) => {
  const { defaultEmailBannerInitial, emailBannerPosition2Initial, brand } = props;
  return (
    <Stack component="form" ref={ref} direction="column" gap={8}>
      <Stack direction="column" gap={2}>
        <Typography variant="h5">Default Banner (after 4th deal)</Typography>

        <ScheduleContentBannerImageInput
          name={SCHEDULE_BANNER_INPUT_NAMES.BANNER_IMAGE_ID_POS_5}
          initialBannerId={defaultEmailBannerInitial}
          label="Default Banner (after 4th deal)"
          brand={brand}
        />
      </Stack>

      <Stack direction="column" gap={2}>
        <Typography variant="h5">Banner (after 1st deal)</Typography>

        <ScheduleContentBannerImageInput
          name={SCHEDULE_BANNER_INPUT_NAMES.BANNER_IMAGE_ID_POS_2}
          initialBannerId={emailBannerPosition2Initial}
          label="Banner (after 1st deal)"
          brand={brand}
        />
      </Stack>
    </Stack>
  );
});

ScheduleBannerForm.displayName = 'ScheduleBannerForm';

export default ScheduleBannerForm;
