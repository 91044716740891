import React, { ComponentProps, useCallback, useEffect } from 'react';

import { Alert, AlertTitle, Button, Tab, Tabs } from '@mui/material';

import useHeroPlannerCountryGroups from '~/hooks/customerCommunication/useHeroPlannerCountryGroups';

import { isRequestRejected } from '~/utils/requestUtils';

interface Props {
  /**
   * countryGroupId
   */
  brand: string;
  value: string;
  onChange: (countryGroup: string) => void;
}

function SchedulesCountryGroupsTabs(props: Props) {
  const { brand, value, onChange } = props;

  const handleChange = useCallback<NonNullable<ComponentProps<typeof Tabs>['onChange']>>(
    (event, value) => {
      onChange(value);
    },
    [onChange],
  );
  const { countryGroupsReq, fetchCountryGroups } = useHeroPlannerCountryGroups(brand);

  useEffect(() => {
    if (countryGroupsReq?.result?.length) {
      if (!value || !countryGroupsReq.result.find((i) => i.id === value)) {
        onChange(countryGroupsReq.result[0].id);
      }
    }
  }, [countryGroupsReq?.result, onChange, value]);

  if (isRequestRejected(countryGroupsReq))
    return (
      <Alert
        severity="error"
        action={
          <Button size="small" color="inherit" onClick={fetchCountryGroups}>
            Retry
          </Button>
        }
      >
        <AlertTitle>Couldn't fetch country groups for filtering.</AlertTitle>
        <pre>{countryGroupsReq.error}</pre>
      </Alert>
    );

  return (
    <Tabs value={value ?? false} variant="scrollable" onChange={handleChange}>
      {countryGroupsReq?.result?.map((countryGroup) => (
        <Tab key={countryGroup.id} value={countryGroup.id} label={countryGroup.name} />
      ))}
    </Tabs>
  );
}

export default SchedulesCountryGroupsTabs;
