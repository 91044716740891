import React from 'react';

import currencyFormatter from 'currency-formatter';

import { Divider, Stack, Typography } from '@mui/material';
import { blue, red } from '@mui/material/colors';

import { formatDateOrdinalWithClock } from '~/services/TimeService';

import { toOrdinal } from '~/utils/toOrdinal';

type Props =
  | {
      roomIndex: number;
      rebookableData: App.Bedbank.ReservationRebookInfo;
      currencyCode: string;
      // we only need to pass in sourceSupplierRoomName if we're displaying the full info (shortInfo is undefined or false)
      shortInfo?: false;
      sourceSupplierRoomName: string;
    }
  | {
      roomIndex: number;
      rebookableData: App.Bedbank.ReservationRebookInfo;
      currencyCode: string;
      shortInfo: true;
      sourceSupplierRoomName?: never;
    };

function RebookInfo({ rebookableData, currencyCode, roomIndex, shortInfo, sourceSupplierRoomName }: Props) {
  const { isVerifiedRoom, isInclusionsEqualOrBetter } = rebookableData;
  return (
    <Stack direction="column" sx={{ '& strong': { fontWeight: 500 } }}>
      <Typography>
        <Typography fontWeight="bold" component="span" color="inherit">
          Room
        </Typography>
        : {roomIndex}
      </Typography>

      {rebookableData.rebookCount > 1 && <Typography>{toOrdinal(rebookableData.rebookCount)} Opportunity</Typography>}

      <Divider />

      {!shortInfo && (
        <>
          <Typography>
            <Typography fontWeight="bold" component="span" color="inherit">
              Current Room Supplier Name
            </Typography>
            : {sourceSupplierRoomName}
          </Typography>
          <Typography sx={{ color: blue[900] }}>
            <Typography fontWeight="bold" component="span" color="inherit">
              New Room Supplier Name
            </Typography>
            : {rebookableData.supplierRoomName ?? 'N/A'}
          </Typography>
          <Typography>
            <Typography fontWeight="bold" component="span" color="inherit">
              Current cost price
            </Typography>
            :{' '}
            {currencyFormatter.format(rebookableData.originalCost, {
              code: currencyCode,
            })}
          </Typography>
          <Typography sx={{ color: blue[900] }}>
            <Typography fontWeight="bold" component="span" color="inherit">
              New cost price
            </Typography>
            :{' '}
            {currencyFormatter.format(rebookableData.newCost, {
              code: currencyCode,
            })}
          </Typography>
          <Typography>
            <Typography fontWeight="bold" component="span" color="inherit">
              Original Margin
            </Typography>
            :{' '}
            {currencyFormatter.format(rebookableData.originalMarketingFee, {
              code: currencyCode,
            })}{' '}
            ({((rebookableData.originalMarketingFee / rebookableData.originalPrice) * 100).toFixed(2)}%)
          </Typography>
          <Typography sx={{ color: blue[900] }}>
            <Typography fontWeight="bold" component="span" color="inherit">
              New Margin
            </Typography>
            :{' '}
            {currencyFormatter.format(rebookableData.originalPrice - rebookableData.newCost, {
              code: currencyCode,
            })}{' '}
            (
            {(((rebookableData.originalPrice - rebookableData.newCost) / rebookableData.originalPrice) * 100).toFixed(
              2,
            )}
            %)
          </Typography>
          <Typography sx={{ color: blue[900] }}>
            <Typography fontWeight="bold" component="span" color="inherit">
              Incremental Margin
            </Typography>
            :{' '}
            {currencyFormatter.format(rebookableData.marginIncrease, {
              code: currencyCode,
            })}{' '}
            ({rebookableData.marginIncreasePercentage.toFixed(2)}
            %)
          </Typography>
        </>
      )}

      <Typography sx={{ color: blue[900] }}>
        <Typography fontWeight="bold" component="span" color="inherit">
          Incremental Margin (AUD)
        </Typography>
        :{' '}
        {currencyFormatter.format(rebookableData.marginIncreaseInAUD, {
          code: 'AUD',
        })}{' '}
        ({rebookableData.marginIncreasePercentage.toFixed(2)}
        %)
      </Typography>
      {rebookableData.originalSupplier && (
        <Typography>
          <Typography fontWeight="bold" component="span" color="inherit">
            Original Supplier
          </Typography>
          : {rebookableData.originalSupplier}
        </Typography>
      )}
      {rebookableData.newSupplier && (
        <Typography sx={{ color: blue[900] }}>
          <Typography fontWeight="bold" component="span" color="inherit">
            New Supplier
          </Typography>
          : {rebookableData.newSupplier}
        </Typography>
      )}
      <Typography>
        <Typography fontWeight="bold" component="span" color="inherit">
          Original Facilities
        </Typography>
        : {rebookableData.originalFacilities?.length > 0 ? rebookableData.originalFacilities.join(', ') : 'N/A'}
      </Typography>

      <Typography sx={{ color: blue[900] }}>
        <Typography fontWeight="bold" component="span" color="inherit">
          New Facilities
        </Typography>
        : {rebookableData.newFacilities?.length > 0 ? rebookableData.newFacilities.join(', ') : 'N/A'}
      </Typography>

      <Typography>
        <Typography fontWeight="bold" component="span" color="inherit">
          Last Scanned
        </Typography>
        : {formatDateOrdinalWithClock(rebookableData.createdAt)}
      </Typography>
      {!shortInfo && isVerifiedRoom && !isInclusionsEqualOrBetter && (
        <Typography
          fontWeight="bold"
          color={red[900]}
          sx={{ display: 'flex', alignSelf: 'center', marginTop: 2 }}
          component="div"
        >
          Please double check the new inclusions
        </Typography>
      )}
    </Stack>
  );
}

export default RebookInfo;
