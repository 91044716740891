import React from 'react';

import { Box, Stack } from '@mui/material';

import { PriceDetail } from '~/components/Tours/Pages/Lists/ToursDepartureInsightsPage';

interface Props {
  pricing: Array<PriceDetail>;
}
export function PricingDetailCell({ pricing }: Props) {
  return (
    <Stack direction="column" spacing={2} justifyContent="flex-start" alignItems="center">
      {pricing
        .filter((priceDetail) => priceDetail.roomType === 'twin_regular')
        ?.map((priceDetail) => (
          <ul key={priceDetail.id}>
            <li>
              Room type: <Box component="b">{priceDetail.roomType}</Box>
            </li>
            <li>
              current price: <Box component="b">{priceDetail.discountedPrice}</Box>
            </li>
            <li>
              {priceDetail.adminDiscountPercentage > 0 ? 'Markdown' : 'Markup'}:{' '}
              <Box component="b">{priceDetail.adminDiscountPercentage * -100}%</Box>
            </li>
            <li>
              Room left: <Box component="b">{priceDetail.inventoryLeft}</Box>
            </li>
          </ul>
        ))}
    </Stack>
  );
}
