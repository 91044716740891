import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';

import { Box, Stack, TextField } from '@mui/material';

import { RuleCondition } from '~/consts/agentHub';

interface Props {
  setCreatedConditions: (condition: RuleCondition, replace?: boolean) => any;
  existingConditions: RuleCondition['value'];
}

function AgentHubCommissionsMarginInput({ setCreatedConditions, existingConditions }: Props) {
  const [minMargin, setMinMargin] = useState<number>((existingConditions?.[0]?.value as number) || 0);
  const [maxMargin, setMaxMargin] = useState<number>((existingConditions?.[1]?.value as number) || 1);
  const { enqueueSnackbar } = useSnackbar();

  function handleMargin() {
    if (minMargin >= maxMargin) {
      return enqueueSnackbar('Min margin must be less than the max margin', { variant: 'error' });
    }

    setCreatedConditions(
      {
        type: 'margin',
        value: [
          { label: 'Min', value: minMargin },
          { label: 'Max', value: maxMargin },
        ],
      },
      true,
    );
  }

  useEffect(() => {
    handleMargin();
  }, [minMargin, maxMargin]);

  return (
    <Stack spacing={2} direction="row">
      <Box>
        <TextField
          name="Min"
          value={minMargin}
          onChange={(e) => {
            setMinMargin(parseInt(e.target.value));
          }}
          InputProps={{ inputProps: { min: 0, max: 100 }, sx: { borderRadius: 0.5 } }}
          label="Min"
          type="number"
          fullWidth
        />
      </Box>
      <Box>
        <TextField
          name="Max"
          value={maxMargin}
          onChange={(e) => {
            setMaxMargin(parseInt(e.target.value));
          }}
          InputProps={{ inputProps: { min: 1, max: 100 }, sx: { borderRadius: 0.5 } }}
          label="Max"
          type="number"
          fullWidth
        />
      </Box>
    </Stack>
  );
}

export default AgentHubCommissionsMarginInput;
