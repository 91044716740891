import React, { useState } from 'react';

import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';

import { Container, Dialog, DialogContent, Tab, Tabs } from '@mui/material';

import { property as propertyGlobals } from '@luxuryescapes/lib-global';

import PageHeader from '~/components/Common/Elements/PageHeader';
import Image from '~/components/Common/Image';

import useCurrentUser from '~/hooks/useCurrentUser';
import useQuery from '~/hooks/useQuery';

import { isAdmin } from '~/utils/adminPermission';

import DynamicConfigurationsPage from './DynamicConfiguration/DynamicConfigurationsPage';
import PageContainer from './PageContainer';

const DYNAMIC_CONFIGURATIONS_TYPE = 'Dynamic Configurations';

function BedbankPropertiesPage() {
  const query = useQuery();
  const history = useHistory();
  const { user } = useCurrentUser();
  const userIsAdmin = isAdmin(user);

  const [selectedTab, setSelectedTab] = useState(query.get('propertyType') ?? propertyGlobals.HOTEL_TYPE);
  const [selectedImageId, setSelectedImageId] = useState(null);

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>Bedbank - Properties</title>
      </Helmet>
      <PageHeader title="Bedbank - Properties" />
      <Tabs sx={{ mb: 2 }} value={selectedTab} onChange={(_, value) => setSelectedTab(value)}>
        <Tab key={propertyGlobals.HOTEL_TYPE} value={propertyGlobals.HOTEL_TYPE} label="Hotels & Resorts" />
        <Tab key={propertyGlobals.UNIQUE_STAYS_TYPE} value={propertyGlobals.UNIQUE_STAYS_TYPE} label="Unique Stays" />
        {userIsAdmin && (
          <Tab
            key={DYNAMIC_CONFIGURATIONS_TYPE}
            value={DYNAMIC_CONFIGURATIONS_TYPE}
            label={DYNAMIC_CONFIGURATIONS_TYPE}
          />
        )}
      </Tabs>

      {selectedTab !== DYNAMIC_CONFIGURATIONS_TYPE && (
        <>
          <PageContainer
            onOpenModalImage={setSelectedImageId}
            location={location}
            history={history}
            propertyType={selectedTab}
          />
          <Dialog
            sx={{
              '& .MuiDialog-container': {
                '& .MuiPaper-root': {
                  maxWidth: '100%',
                },
              },
            }}
            open={!!selectedImageId}
            onClose={() => setSelectedImageId(null)}
          >
            <DialogContent sx={{ display: 'flex', height: '90vh' }}>
              {selectedImageId && (
                <Image className="bedbank-img-responsive" publicId={selectedImageId} options={{ width: 1024 }} />
              )}
            </DialogContent>
          </Dialog>
        </>
      )}

      {selectedTab === DYNAMIC_CONFIGURATIONS_TYPE && userIsAdmin && <DynamicConfigurationsPage />}
    </Container>
  );
}

export default BedbankPropertiesPage;
