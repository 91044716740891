import React from 'react';

import { Link } from 'react-router-dom';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton, Stack, Tooltip } from '@mui/material';

import useToggleState from '~/hooks/useToggleState';

import ScheduleDeletionDialog from './ScheduleDeletionDialog';

interface Props {
  scheduleId: string;
  segment?: string;
}

function ScheduleRowActions({ scheduleId, segment }: Props) {
  const {
    isToggled: isDeletionModalOpen,
    toggleOn: openDeletionModal,
    toggleOff: closeDeletionModal,
  } = useToggleState(false);

  return (
    <Stack direction="column">
      <Tooltip title="Edit in the editor">
        <IconButton
          component={Link}
          to={`/customer-communication/hero-planner/schedules/${scheduleId}?segment=${segment}`}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Duplicate as a new schedule">
        <IconButton
          component={Link}
          to={`/customer-communication/hero-planner/schedules/${scheduleId}?duplicate=true&segment=${segment}`}
        >
          <ContentCopyIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Open the  deletion modal">
        <IconButton onClick={openDeletionModal}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <ScheduleDeletionDialog
        scheduleId={scheduleId}
        isCustomSchedule={segment === 'DESTINATION'}
        open={isDeletionModalOpen}
        onClose={closeDeletionModal}
      />
    </Stack>
  );
}

export default ScheduleRowActions;
