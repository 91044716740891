import React from 'react';

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocationIcon from '@mui/icons-material/FmdGood';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import { Box, Typography } from '@mui/material';

import AppSidebar from '~/components/layouts/AppSidebar';
import IconList from '~/components/v2/common/IconList/IconList';
import { ReadOnlyFieldType } from '~/components/v2/common/ReadOnlyField/types';

import OpportunityPropertyInfoSection from './OpportuntityPropertyInfoSection';

const sections = [
  {
    title: 'Unique Selling Point',
    fields: [
      {
        title: 'USP',
        value:
          'One of Bali most iconic beachfront hotels – amazing dining, rooftop sunset cocktails, large suites and located in the hear of Seminyak dining and shopping area.',
        type: 'text' as ReadOnlyFieldType,
      },
    ],
  },
  {
    title: "What's Around",
    fields: [
      { title: 'Closest Airport', value: 'Denpasar - 25min · 11km', type: 'text' as ReadOnlyFieldType },
      {
        title: 'Airport Transfer Cost',
        value: 'Pick up IDR 400,000 · Drop off IDR 300,000',
        type: 'text' as ReadOnlyFieldType,
      },
      {
        title: 'Local Attractions',
        value:
          'Situated ON Double Six Beach\nCocoon Beach Club 160m\nLegian Beach 200m\nKu Da Ta 2.1km\nWaterbom Bali 4.8km',
        type: 'text' as ReadOnlyFieldType,
      },
      { title: 'Local Attractions Shuttle', value: 'No', type: 'text' as ReadOnlyFieldType },
    ],
  },
  {
    title: 'Amenities & Services',
    fields: [
      { title: 'Wi-fi', value: 'Free of charge', type: 'text' as ReadOnlyFieldType },
      { title: 'Parking', value: 'Free of charge', type: 'text' as ReadOnlyFieldType },
      {
        title: 'Smoking Policy',
        value: 'All rooms are non smoking, but allowed on balcony',
        type: 'text' as ReadOnlyFieldType,
      },
      {
        title: 'Disability Access',
        value:
          'Yes, but not in rooms that are contracted. Please advise guest to book direct for Premium Suite Pool Access',
        type: 'text' as ReadOnlyFieldType,
      },
      { title: 'Kids Club', value: '-', type: 'text' as ReadOnlyFieldType },
      { title: 'Babysitting', value: '-', type: 'text' as ReadOnlyFieldType },
    ],
  },
  {
    title: 'Highlights',
    fields: [
      { title: 'Recent Awards/Accolades', value: '-', type: 'text' as ReadOnlyFieldType },
      { title: 'Year Opened', value: 'Feb 2003', type: 'text' as ReadOnlyFieldType },
      { title: 'Last Renovated', value: 'May 2024', type: 'text' as ReadOnlyFieldType },
    ],
  },
];

export default function OpportunityPropertyInfo() {
  return (
    <AppSidebar>
      <Box
        sx={{
          py: { md: 0.5 },
        }}
      >
        <Typography variant="overline">Property Info</Typography>
        <Typography variant="h6">Property Name</Typography>
      </Box>

      <Box>
        <IconList
          items={[
            {
              value: '14:00 / 12:00',
              icon: <MeetingRoomIcon />,
              tooltipText: 'Check in/out Time',
            },
            {
              value: 'IDR - Indonesian Rupiah',
              icon: <AttachMoneyIcon />,
              tooltipText: 'Local Currency',
            },
            {
              value: 'Tue, Feb 09, 12:33am',
              icon: <WatchLaterOutlinedIcon />,
              tooltipText: 'Local Time',
            },
            {
              value: 'booking@double-six.com',
              icon: <EmailOutlinedIcon />,
              tooltipText: 'Reservation Email',
              copyable: true,
            },
            {
              value: '+62 123 456 789',
              icon: <PhoneOutlinedIcon />,
              tooltipText: 'Reservation Phone',
              copyable: true,
            },
            {
              value: 'Seminyak, Bali, Indonesia',
              icon: <LocationIcon />,
              tooltipText: 'Location',
            },
          ]}
        />
      </Box>

      {sections.map((section) => {
        return <OpportunityPropertyInfoSection key={section.title} title={section.title} fields={section.fields} />;
      })}
    </AppSidebar>
  );
}
