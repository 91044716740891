import React from 'react';

import { useSnackbar } from 'notistack';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

import { convertAddonsToExperiences } from '~/services/OrdersService';

interface Props {
  toggle: () => void;
  offerId: string;
}

export default function ConvertAddonsToExperiencesDialog({ toggle, offerId }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async () => {
    try {
      await convertAddonsToExperiences(offerId);
      enqueueSnackbar('Addons converted to experiences successfully', { variant: 'success' });
      toggle();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };
  return (
    <Dialog open onClose={toggle}>
      <DialogTitle>Convert Addons to Experiences?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          For all orders with a check-in past 21 days, this will rebook all complimentary experiences then cancel all
          addons.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggle}>Cancel</Button>
        <Button onClick={onSubmit} variant="contained">
          Proceed
        </Button>
      </DialogActions>
    </Dialog>
  );
}
