import React from 'react';

import { CalendarMonth, CorporateFare, CreditCard } from '@mui/icons-material';
import { Box, Stack } from '@mui/material';

import { operations } from '@luxuryescapes/contract-svc-promo';

import { PromoMeta } from '~/services/PromoService';

import { PromoToggleFormatter } from '../formatters/PromoToggleFormatter';

type PromoResponseDetailsProps = {
  isDev: boolean;
  response: operations['calculateDiscount']['responses']['200']['content']['application/json']['result']['promo'];
  meta: PromoMeta;
};

function PromoResponseDetails({ isDev, response, meta }: PromoResponseDetailsProps) {
  if (!response) {
    return null;
  }

  const {
    corporate_name,
    promo_toggles,
    is_corporate,
    has_bin_numbers,
    limit_per_user_per_year,
    limit_per_user_per_year_remaining,
    promo_category,
  } = response;

  if (!isDev) {
    return null;
  }

  return (
    <Stack direction="row" spacing={2}>
      <PromoToggleFormatter promoToggles={promo_toggles} promoToggleInfo={meta?.promo_toggles} />
      {is_corporate && (
        <Box title={`Corporate Promo for ${corporate_name}`}>
          <CorporateFare />
        </Box>
      )}
      {has_bin_numbers && (
        <Box title="On Payment, we will check this BIN number matches what is configured on this promo">
          <CreditCard />
        </Box>
      )}
      {limit_per_user_per_year !== undefined ||
        (limit_per_user_per_year_remaining !== undefined && (
          <Box title={`Limit per user per year: ${limit_per_user_per_year_remaining}/${limit_per_user_per_year}`}>
            <CalendarMonth /> ({limit_per_user_per_year_remaining}/{limit_per_user_per_year})
          </Box>
        ))}
      {promo_category !== 'regular' && <Box title={`Promo Category: ${promo_category}`}>{promo_category}</Box>}
    </Stack>
  );
}

export default PromoResponseDetails;
