import { CruisesContract as API } from '@luxuryescapes/contract-svc-cruise';

import { Port, PortImage } from '~/components/Cruises/pages/Ports/types';

import cruisesAPI from './CruisesApiService';

const resource = 'ports';

const listWithPagination = async (paginationParams: API.QueryParams): Promise<API.Response<Array<Port>>> => {
  return await cruisesAPI.http.get({ resource, paginationParams });
};

const getPortById = async (portId: string): Promise<Port> => {
  const response = await cruisesAPI.http.get<Port>({
    resource: `${resource}/${portId}`,
  });

  return response.result;
};

const getPortImages = async (portId: string): Promise<API.Response<Array<PortImage>>> => {
  const response = await cruisesAPI.http.get<PortImage[]>({
    resource: `${resource}/${portId}/images`,
  });

  return {
    ...response,
    result: response.result,
  };
};

const createPortImage = async (
  portId: string,
  image: {
    imageId: string;
    order: number;
  },
): Promise<PortImage> => {
  const response = await cruisesAPI.http.post<API.Response<PortImage>>({
    resource: `${resource}/${portId}/images`,
    body: image,
  });
  return response.result;
};

const updatePortImages = async (
  portId: string,
  images: {
    id: string;
    imageId: string;
    order: string;
    description: string | null;
  }[],
) => {
  await cruisesAPI.http.put({
    resource: `${resource}/${portId}/images`,
    body: { images },
  });
};

const deletePortImage = async (portId: string, imageId: string) => {
  await cruisesAPI.http.delete({
    resource: `${resource}/${portId}/images/${imageId}`,
  });
};

export default {
  listWithPagination,
  getPortById,
  getPortImages,
  createPortImage,
  updatePortImages,
  deletePortImage,
};
