import { ComponentProps, useCallback, useMemo } from 'react';

import { useHistory, useLocation } from 'react-router';

import { getSearchInputFromSearchParams } from '~/components/Accommodation/Pages/Properties/components/utils';

import AccommodationPropertiesSearchForm from '../Pages/Properties/components/AccommodationPropertiesSearchForm';

export default function usePagedSearch() {
  const history = useHistory();
  const location = useLocation();

  const handleSearchFormSubmission = useCallback<ComponentProps<typeof AccommodationPropertiesSearchForm>['onSubmit']>(
    (formObject) => {
      const urlSearchParamsToPush = new URLSearchParams(formObject);
      history.push({
        search: urlSearchParamsToPush.toString(),
      });
    },
    [history],
  );

  const urlSearchParams = useMemo<URLSearchParams>(() => new URLSearchParams(location.search), [location.search]);

  const handlePageChange = useCallback(
    (pageIndex: number) => {
      const urlSearchParamsToPush = new URLSearchParams(urlSearchParams);
      if (pageIndex) {
        urlSearchParamsToPush.set('page', String(pageIndex + 1));
      } else {
        urlSearchParamsToPush.delete('page');
      }
      history.push({
        search: urlSearchParamsToPush.toString(),
      });
    },
    [urlSearchParams, history],
  );
  return useMemo(() => {
    const pageFromSearchParams = urlSearchParams.get('page');
    return {
      searchInput: getSearchInputFromSearchParams(urlSearchParams),
      pageNumber: pageFromSearchParams ? Number(pageFromSearchParams) : 1,
      handlePageChange,
      handleSearchFormSubmission,
    };
  }, [handlePageChange, urlSearchParams, handleSearchFormSubmission]);
}
