import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';

import { Chip, Divider, Paper, Stack, Typography } from '@mui/material';

import useCustomScheduleDestinations from '~/hooks/customerCommunication/useCustomScheduleDestinations';

import ScheduleDestinationInput, { parseDestinationFormData } from './Inputs/ScheduleDestinationInput';
import ScheduleHeaderImageInput, { parseHeaderImageFormData } from './Inputs/ScheduleHeaderImageInput';
import ScheduleIntroSectionForm, { parseIntroSectionFormData } from './Inputs/ScheduleIntroSectionForm';
import ScheduleEmailContentForm, { parseScheduleEmailContentFormData } from './ScheduleEmailContentForm';

interface DestinationFormData {
  destinationId: string;
  webImageId: string;
  mobileImageId: string;
  headerUrl: string;
  introHeading: string;
  introCopy: string;
  introCta: string;
  introCtaUrl: string;
  emailPreHeader: string;
  emailSubjectLine: string;
}

export interface DestinationFormRef {
  getFormData: () => Promise<DestinationFormData | null>;
  validateForms: () => boolean;
}

interface Props {
  brandId: string;
  countryGroupId: string;
  countryId: string;
  offerIds: Array<string>;
  schedule?: Partial<CustomerCommunication.CustomSchedule>;
}

const DestinationContentForm = forwardRef<DestinationFormRef, Props>((props, ref) => {
  const { brandId, countryGroupId, countryId, offerIds, schedule } = props;

  const destinationFormRef = useRef<HTMLFormElement>(null);
  const headerImageFormRef = useRef<HTMLFormElement>(null);
  const introSectionFormRef = useRef<HTMLFormElement>(null);
  const emailContentFormRef = useRef<HTMLFormElement>(null);

  const [selectedDestination, setSelectedDestination] = useState(schedule?.destination || '');
  const [webImageId, setWebImageId] = useState(schedule?.introSection?.headerDesktopImage || '');
  const [mobileImageId, setMobileImageId] = useState(schedule?.introSection?.headerMobileImage || '');
  const [headerUrl, setHeaderUrl] = useState(schedule?.introSection?.headerUrl || '');
  const [introHeading, setIntroHeading] = useState(schedule?.introSection?.introHeading || '');
  const [introCopy, setIntroCopy] = useState(schedule?.introSection?.introCopy || '');
  const [introCta, setIntroCta] = useState(schedule?.introSection?.introCta || '');
  const [introCtaUrl, setIntroCtaUrl] = useState(schedule?.introSection?.introCtaUrl || '');

  // Fetch destinations
  const useGetCustomScheduleDestinations = () => useCustomScheduleDestinations(brandId);

  const validateForms = useCallback(() => {
    return (
      destinationFormRef.current?.reportValidity() &&
      headerImageFormRef.current?.reportValidity() &&
      introSectionFormRef.current?.reportValidity() &&
      emailContentFormRef.current?.reportValidity()
    );
  }, []);

  const getFormData = useCallback(async () => {
    if (!validateForms()) {
      return null;
    }

    const destinationData = parseDestinationFormData(new FormData(destinationFormRef.current));
    const headerImageData = parseHeaderImageFormData(new FormData(headerImageFormRef.current));
    const introSectionData = parseIntroSectionFormData(new FormData(introSectionFormRef.current));
    const emailContentData = parseScheduleEmailContentFormData(new FormData(emailContentFormRef.current));

    const formDataValues = {
      ...destinationData,
      ...headerImageData,
      ...introSectionData,
      ...emailContentData,
    };
    return formDataValues;
  }, [validateForms]);

  useImperativeHandle(ref, () => ({
    getFormData,
    validateForms,
  }));

  return (
    <Stack direction="column" gap={3}>
      <Paper variant="outlined" sx={{ p: 2, position: 'relative' }}>
        <Stack direction="column" gap={2}>
          <Typography variant="h4">Destination</Typography>
          <ScheduleDestinationInput
            ref={destinationFormRef}
            value={selectedDestination}
            onChange={setSelectedDestination}
            destinationsInputData={useGetCustomScheduleDestinations}
          />
        </Stack>
      </Paper>

      <Paper variant="outlined" sx={{ p: 2, position: 'relative' }}>
        <Stack direction="column" gap={2}>
          <Typography variant="h4">Email Content</Typography>
          <ScheduleEmailContentForm
            ref={emailContentFormRef}
            countryGroupId={countryGroupId}
            countryId={countryId}
            offerIds={offerIds}
            showPrefillButton={false}
            preHeaderDefaultValue={schedule?.emailPreheader}
            subjectLineDefaultValue={schedule?.emailSubjectLine}
          />
        </Stack>
      </Paper>

      <Paper variant="outlined" sx={{ p: 2, position: 'relative' }}>
        <Stack direction="column" gap={2}>
          <Typography variant="h4">Header Image</Typography>
          <form ref={headerImageFormRef}>
            <ScheduleHeaderImageInput
              webImageId={webImageId}
              mobileImageId={mobileImageId}
              headerUrl={headerUrl}
              onWebImageIdChange={setWebImageId}
              onMobileImageIdChange={setMobileImageId}
              onHeaderUrlChange={setHeaderUrl}
            />
          </form>
        </Stack>
      </Paper>

      <Paper variant="outlined" sx={{ p: 2, position: 'relative' }}>
        <Stack direction="column" gap={2}>
          <Typography variant="h4">Intro Section</Typography>
          <ScheduleIntroSectionForm
            ref={introSectionFormRef}
            introHeading={introHeading}
            introCopy={introCopy}
            introCta={introCta}
            introCtaUrl={introCtaUrl}
            onIntroHeadingChange={setIntroHeading}
            onIntroCopyChange={setIntroCopy}
            onIntroCtaChange={setIntroCta}
            onIntroCtaUrlChange={setIntroCtaUrl}
          />
        </Stack>
      </Paper>

      <Divider>
        <Chip label="Additional Sections" />
      </Divider>
    </Stack>
  );
});

DestinationContentForm.displayName = 'DestinationContentForm';
export default DestinationContentForm;
