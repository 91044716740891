import { json_headers, request } from './common';

function basePath() {
  return window.configs.AUTH_HOST;
}

async function getIpLocations(ipAddresses: Array<string>) {
  const chunkSize = 100;
  const chunks = [];

  // Split ipAddresses into chunks of 100
  for (let i = 0; i < ipAddresses.length; i += chunkSize) {
    chunks.push(ipAddresses.slice(i, i + chunkSize));
  }

  // Make requests for each chunk and combine results
  const results = await Promise.all(
    chunks.map((chunk) =>
      request(`${basePath()}/info/ip-location`, {
        method: 'POST',
        headers: json_headers,
        body: JSON.stringify(chunk),
      }),
    ),
  );

  return results.flat();
}

export default {
  getIpLocations,
};
