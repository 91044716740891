import React, { ReactNode } from 'react';

import { Box, SxProps } from '@mui/material';

import PageAreas from './PageAreas';
import { INVALID_PAGE_COMPONENT_DESCENDENCE } from './pageSupportingStyles';

const rootStyle: SxProps = {
  ...INVALID_PAGE_COMPONENT_DESCENDENCE,
  gridArea: PageAreas.body,
};

interface Props {
  children: ReactNode;
}

function PageBody({ children }: Props) {
  return (
    <Box className={PageAreas.body} py={2} sx={rootStyle}>
      {children}
    </Box>
  );
}

export default PageBody;
