import React, { useCallback } from 'react';

import { useForm } from 'react-hook-form';

import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  TextField,
} from '@mui/material';

const LAYER_TYPES = ['video', 'image', 'slide-in-text', 'news-title'];

const POSITIONS = [
  'top',
  'bottom',
  'center',
  'top-left',
  'top-right',
  'center-left',
  'center-right',
  'bottom-left',
  'bottom-right',
];

const RESIZE_MODES = ['contain', 'contain-blur', 'stretch', 'cover'];

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  activeLayerIndex: number | null | undefined;
  saveNewLayerToClip: (value) => void;
  updateLayerInClip: (value) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  activeClip: any;
}

function LayerForm({ setOpen, activeLayerIndex, saveNewLayerToClip, activeClip, updateLayerInClip }: Props) {
  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);
  const { type, ...attributes } =
    activeLayerIndex >= 0 ? activeClip.layers[activeLayerIndex] : { type: LAYER_TYPES[0] };
  const [layerType, setLayerType] = React.useState(type);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [layerAttributes, setLayerAttributes] = React.useState(activeLayerIndex >= 0 ? attributes : ({} as any));

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleCreate = useCallback(async () => {
    saveNewLayerToClip({
      type: layerType,
      ...layerAttributes,
    });
  }, [saveNewLayerToClip, layerType, layerAttributes]);
  const handleUpdateClipComponents = useCallback(async () => {
    updateLayerInClip({
      type: layerType,
      ...layerAttributes,
    });
  }, [layerAttributes, layerType, updateLayerInClip]);

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      open
      onClose={handleClose}
      component="form"
      onSubmit={handleSubmit(activeLayerIndex >= 0 ? handleUpdateClipComponents : handleCreate)}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Create a Clip Component
      </DialogTitle>
      <DialogContent dividers>
        <Stack spacing={2}>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <Autocomplete
                id="layer_type"
                options={LAYER_TYPES}
                value={layerType}
                onChange={(_, newValue) => {
                  setLayerType(newValue);
                }}
                renderInput={(params) => <TextField {...params} label="Layer Type" />}
              />
            </Grid>
          </Grid>
          {/* STARTING OF DIFFERENT VARIATIONS FOR CLIP FORM */}
          {/* VIDEO VARIATION */}
          {layerType === 'video' && (
            <Stack spacing={2}>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    id="path"
                    label="Video Path"
                    variant="outlined"
                    {...register('path', { required: 'Video path is required' })}
                    error={!!errors.path}
                    value={layerAttributes.path}
                    helperText={errors.path?.message}
                    autoComplete="off"
                    onChange={(e) => setLayerAttributes({ ...layerAttributes, path: e.target.value })}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    id="resize_mode"
                    options={RESIZE_MODES}
                    defaultValue={RESIZE_MODES[0]}
                    value={layerAttributes.resizeMode}
                    onChange={(_, newValue) => {
                      setLayerAttributes({ ...layerAttributes, resizeMode: newValue });
                    }}
                    renderInput={(params) => <TextField {...params} label="Resize Mode" />}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    id="cut_from"
                    label="Cut From"
                    variant="outlined"
                    value={layerAttributes.cutFrom}
                    {...register('cut_from')}
                    inputProps={{ min: 0.0, step: 0.01 }}
                    type="number"
                    error={!!errors.cut_from}
                    helperText={errors.cut_from?.message}
                    autoComplete="off"
                    onChange={(e) => setLayerAttributes({ ...layerAttributes, cutFrom: e.target.value })}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    id="cut_to"
                    label="Cut To"
                    variant="outlined"
                    value={layerAttributes.cutTo}
                    {...register('cut_to')}
                    inputProps={{ min: 0.0, step: 0.01 }}
                    type="number"
                    error={!!errors.cut_to}
                    helperText={errors.cut_to?.message}
                    autoComplete="off"
                    onChange={(e) => setLayerAttributes({ ...layerAttributes, cutTo: e.target.value })}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    id="width"
                    label="Video Width"
                    value={layerAttributes.width}
                    variant="outlined"
                    {...register('width')}
                    inputProps={{ min: 0 }}
                    type="number"
                    error={!!errors.width}
                    helperText={errors.width?.message}
                    autoComplete="off"
                    onChange={(e) => setLayerAttributes({ ...layerAttributes, width: e.target.value })}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    id="height"
                    label="Video height"
                    variant="outlined"
                    value={layerAttributes.height}
                    {...register('height')}
                    inputProps={{ min: 0.0 }}
                    type="number"
                    error={!!errors.height}
                    helperText={errors.height?.message}
                    autoComplete="off"
                    onChange={(e) => setLayerAttributes({ ...layerAttributes, height: e.target.value })}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    id="left"
                    label="X-position relative to screen width"
                    variant="outlined"
                    value={layerAttributes.left}
                    {...register('left')}
                    inputProps={{ min: 0.0, max: 1.0, step: 0.01 }}
                    type="number"
                    error={!!errors.left}
                    helperText={errors.left?.message}
                    autoComplete="off"
                    onChange={(e) => setLayerAttributes({ ...layerAttributes, left: e.target.value })}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    id="right"
                    label="Y-position relative to screen width"
                    variant="outlined"
                    value={layerAttributes.right}
                    {...register('right')}
                    inputProps={{ min: 0.0, max: 1.0, step: 0.01 }}
                    type="number"
                    error={!!errors.right}
                    helperText={errors.right?.message}
                    autoComplete="off"
                    onChange={(e) => setLayerAttributes({ ...layerAttributes, right: e.target.value })}
                  />
                </Grid>
              </Grid>
            </Stack>
          )}
          {/* IMAGE VARIATION */}
          {layerType === 'image' && (
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  id="path"
                  label="Image Path"
                  variant="outlined"
                  {...register('path', { required: 'Image path is required' })}
                  value={layerAttributes.path}
                  error={!!errors.path}
                  helperText={errors.path?.message}
                  autoComplete="off"
                  onChange={(e) => setLayerAttributes({ ...layerAttributes, path: e.target.value })}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  id="resize_mode"
                  options={RESIZE_MODES}
                  defaultValue={RESIZE_MODES[0]}
                  value={layerAttributes.resizeMode}
                  onChange={(_, newValue) => {
                    setLayerAttributes({ ...layerAttributes, resizeMode: newValue });
                  }}
                  renderInput={(params) => <TextField {...params} label="Resize Mode" />}
                />
              </Grid>
            </Grid>
          )}
          {/* SLIDE-IN-TEXT VARIATION */}
          {layerType === 'slide-in-text' && (
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  id="font_path"
                  label="Font Path"
                  variant="outlined"
                  {...register('font_path', { required: 'Font Path is required' })}
                  value={layerAttributes.fontPath}
                  error={!!errors.font_path}
                  helperText={errors.font_path?.message}
                  autoComplete="off"
                  onChange={(e) => setLayerAttributes({ ...layerAttributes, fontPath: e.target.value })}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  id="text"
                  label="Text"
                  variant="outlined"
                  value={layerAttributes.text}
                  {...register('text', { required: 'Font Path is required' })}
                  error={!!errors.text}
                  helperText={errors.text?.message}
                  autoComplete="off"
                  onChange={(e) => setLayerAttributes({ ...layerAttributes, text: e.target.value })}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  id="font_size"
                  label="Font Size"
                  variant="outlined"
                  value={layerAttributes.fontSize}
                  {...register('font_size', { required: 'Font Size is required' })}
                  error={!!errors.font_size}
                  helperText={errors.font_size?.message}
                  autoComplete="off"
                  type="number"
                  onChange={(e) => setLayerAttributes({ ...layerAttributes, fontSize: e.target.value })}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  id="position"
                  options={POSITIONS}
                  defaultValue={POSITIONS[0]}
                  value={layerAttributes.position}
                  onChange={(_, newValue) => {
                    setLayerAttributes({ ...layerAttributes, position: newValue });
                  }}
                  renderInput={(params) => <TextField {...params} label="Position" />}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  id="color"
                  label="Text Color"
                  variant="outlined"
                  value={layerAttributes.color}
                  {...register('color', { required: 'Font Path is required' })}
                  error={!!errors.color}
                  helperText={errors.color?.message}
                  autoComplete="off"
                  onChange={(e) => setLayerAttributes({ ...layerAttributes, color: e.target.value })}
                />
              </Grid>
            </Grid>
          )}
          {/* NEWS-TITLE VARIATION */}
          {layerType === 'news-title' && (
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  id="font_path"
                  label="Font Path"
                  variant="outlined"
                  {...register('font_path', { required: 'Font Path is required' })}
                  value={layerAttributes.fontPath}
                  error={!!errors.font_path}
                  helperText={errors.font_path?.message}
                  autoComplete="off"
                  onChange={(e) => setLayerAttributes({ ...layerAttributes, fontPath: e.target.value })}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  id="text"
                  label="Text"
                  variant="outlined"
                  value={layerAttributes.text}
                  {...register('text', { required: 'Font Path is required' })}
                  error={!!errors.text}
                  helperText={errors.text?.message}
                  autoComplete="off"
                  onChange={(e) => setLayerAttributes({ ...layerAttributes, text: e.target.value })}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  id="position"
                  options={POSITIONS}
                  defaultValue={POSITIONS[0]}
                  value={layerAttributes.position}
                  onChange={(_, newValue) => {
                    setLayerAttributes({ ...layerAttributes, position: newValue });
                  }}
                  renderInput={(params) => <TextField {...params} label="Position" />}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  id="text_color"
                  label="Text Color"
                  variant="outlined"
                  value={layerAttributes.color}
                  {...register('text_color', { required: 'Text Color is required' })}
                  error={!!errors.text_color}
                  helperText={errors.text_color?.message}
                  autoComplete="off"
                  onChange={(e) => setLayerAttributes({ ...layerAttributes, textColor: e.target.value })}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  id="originX"
                  label="originX"
                  variant="outlined"
                  value={layerAttributes.position?.originX}
                  {...register('originX')}
                  error={!!errors.originX}
                  helperText={errors.originX?.message}
                  autoComplete="off"
                  onChange={(e) =>
                    setLayerAttributes({
                      ...layerAttributes,
                      position: { ...layerAttributes.position, originX: e.target.value },
                    })
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  id="originY"
                  label="originY"
                  variant="outlined"
                  value={layerAttributes.position?.originY}
                  {...register('originY')}
                  error={!!errors.originY}
                  helperText={errors.originY?.message}
                  autoComplete="off"
                  onChange={(e) =>
                    setLayerAttributes({
                      ...layerAttributes,
                      position: { ...layerAttributes.position, originY: e.target.value },
                    })
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  id="x"
                  label="x"
                  variant="outlined"
                  value={layerAttributes.position?.x}
                  {...register('x')}
                  error={!!errors.x}
                  helperText={errors.x?.message}
                  autoComplete="off"
                  onChange={(e) =>
                    setLayerAttributes({
                      ...layerAttributes,
                      position: { ...layerAttributes.position, x: e.target.value },
                    })
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  id="y"
                  label="y"
                  variant="outlined"
                  value={layerAttributes.position?.y}
                  {...register('x')}
                  error={!!errors.y}
                  helperText={errors.y?.message}
                  autoComplete="off"
                  onChange={(e) =>
                    setLayerAttributes({
                      ...layerAttributes,
                      position: { ...layerAttributes.position, y: e.target.value },
                    })
                  }
                />
              </Grid>
            </Grid>
          )}
          {/* ENDING OF DIFFERENT VARIATIONS FOR CLIP FORM */}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant="contained" type="submit">
          {activeLayerIndex >= 0 ? 'Update' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default LayerForm;
