import React from 'react';

import { Box, Typography } from '@mui/material';

import { formatDateWeekDayShortMonth } from '~/services/TimeService';

const isNewDay = (idx: number, date: string, recentUsers: Array<any>) => {
  if (idx === 0) {
    return true;
  }
  const previousDate = new Date(recentUsers[idx - 1].date);
  const currentDate = new Date(date);
  return previousDate.toDateString() !== currentDate.toDateString();
};

export default function RecentlyViewedPurchases() {
  const savedOrders = localStorage.getItem('recentlyViewedOrders');
  const recentOrders = savedOrders ? JSON.parse(savedOrders) : [];

  return (
    <Box>
      <Typography variant="h4">Recently viewed orders</Typography>
      {recentOrders.map(({ order, date, id }, idx) => (
        <Box key={id}>
          {isNewDay(idx, date, recentOrders) && (
            <Typography component="pre" variant="body2" sx={{ marginTop: 2, fontFamily: 'monospace' }}>
              {formatDateWeekDayShortMonth(date)}
            </Typography>
          )}
          <a href={`/purchases/${id}`}>view</a> {order.customer_full_name} {order.currency_code} {order.total} on{' '}
          {formatDateWeekDayShortMonth(order.created_at)}
        </Box>
      ))}
    </Box>
  );
}
