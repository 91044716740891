import React from 'react';

import { Box } from '@mui/material';

import { Payment } from '~/types/services/payment';

interface Props {
  payment: Payment;
}

function StripePaymentLine({ payment }: Props) {
  const charge = (payment.response_json?.data as any)?.object?.charges?.data?.[0];
  const riskScore = charge?.outcome?.risk_score;
  const last4 = charge?.payment_method_details?.card?.last4 || charge?.payment_method_details?.card?.last_4;

  return (
    <Box>
      {Math.abs(Number(payment.amount)).toLocaleString('en-US', { minimumFractionDigits: 0 })}{' '}
      <a
        href={`https://dashboard.stripe.com/payments/${(payment.response_json as any)?.data?.object?.id}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {payment.type}
      </a>{' '}
      {last4 && <span> card *{last4}</span>} {riskScore >= 0 && <span>risk {riskScore}</span>}
    </Box>
  );
}

export { StripePaymentLine };
