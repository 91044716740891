import React, { useMemo } from 'react';

import styled from 'styled-components';

import { Box } from '@mui/material';

import { Code } from '~/components/Common/Code';

const EditorContainer = styled(Box)`
  border: 1px solid #dedede;
`;

interface Props {
  code: string;
  kind: App.FlightTraceKind;
  theme: 'light' | 'dark';
}

export function FlightsLogsViewerCode({ code, kind, theme }: Props) {
  const codeValue = useMemo(() => {
    if (kind === 'json') {
      return JSON.stringify(JSON.parse(code), null, 2);
    }

    return code;
  }, [code, kind]);

  return (
    <EditorContainer>
      <Code value={codeValue} lang={kind} darkMode={theme === 'dark' ? true : false} />
    </EditorContainer>
  );
}
