import React, { useCallback, useState } from 'react';

import { useParams } from 'react-router';

import ListAltIcon from '@mui/icons-material/ListAlt';
import { Box, Button, Stack, Tooltip } from '@mui/material';

import AccommodationAddPropertyFinder from '~/components/Accommodation/Pages/Properties/components/AccommodationAddPropertyFinder';
import AccommodationReimportSuppliersProgressModal from '~/components/Accommodation/Pages/Properties/components/AccommodationReimportSuppliersProgressModal';
import MappingLogModal from '~/components/Accommodation/Pages/Properties/components/MappingLogModal';

import useToggleState from '~/hooks/useToggleState';

import { reimportSuppliers } from '~/services/AccommodationService';

import AccommodationPropertyPotentialSuppliersResultsTable from './AccommodationPropertyPotentialSuppliersResultsTable';
import AccommodationPropertySupplierResultsTable, {
  AccommodationPropertySupplierResultsTableRef,
} from './AccommodationPropertySupplierResultsTable';

export default function AccommodationPropertySuppliersSection() {
  const { propertyId } = useParams<{ propertyId: string }>();
  const supplierTableRef = React.createRef<AccommodationPropertySupplierResultsTableRef>();
  const [logsId, setLogsId] = useState<string | null>(null);

  const [addPropertyModalVisible, setAddPropertyModalVisible] = useState(false);
  const [reimportSuppliersModalVisible, setReimportSuppliersModalVisible] = useState(false);

  const showAddPropertyModal = useCallback(() => setAddPropertyModalVisible(true), []);
  const closeAddPropertyModal = useCallback(() => {
    supplierTableRef.current?.refresh();
    setAddPropertyModalVisible(false);
  }, [supplierTableRef]);

  const {
    isToggled: isMappingLogModalOpen,
    toggleOn: setOpenMappingLogModal,
    toggleOff: setCloseMappingLogModal,
  } = useToggleState(false);

  const showMappingLogs = useCallback(
    async (logsId: string) => {
      setLogsId(logsId);
      setOpenMappingLogModal();
    },
    [setOpenMappingLogModal],
  );

  const showReimportSuppliersModal = useCallback(() => setReimportSuppliersModalVisible(true), []);
  const closeReimportSuppliersModal = useCallback(() => {
    supplierTableRef.current?.refresh();
    setReimportSuppliersModalVisible(false);
  }, [supplierTableRef]);

  const startReimportSuppliers = useCallback(() => reimportSuppliers(propertyId), [propertyId]);

  return (
    <>
      <Stack direction="column" gap={4}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Box display="flex" gap={2}>
            <Button variant="contained" size="small" onClick={showAddPropertyModal}>
              Add new supplier
            </Button>
            <Button variant="contained" size="small" onClick={showReimportSuppliersModal}>
              Manually sync suppliers
            </Button>
          </Box>
          <Box>
            <Tooltip title="Show verification history">
              <Button startIcon={<ListAltIcon />} color="info" onClick={() => showMappingLogs(propertyId)}>
                Mapping Logs
              </Button>
            </Tooltip>
          </Box>
        </Stack>
        <AccommodationPropertySupplierResultsTable ref={supplierTableRef} />
        <AccommodationPropertyPotentialSuppliersResultsTable externalRef={supplierTableRef} />
      </Stack>
      <AccommodationAddPropertyFinder
        open={addPropertyModalVisible}
        onClose={closeAddPropertyModal}
        propertyId={propertyId}
      />
      {isMappingLogModalOpen && (
        <MappingLogModal
          isMappingLogModalOpen={isMappingLogModalOpen}
          setCloseMappingLogModal={setCloseMappingLogModal}
          logsTargetId={logsId}
          type="property"
        />
      )}
      <AccommodationReimportSuppliersProgressModal
        open={reimportSuppliersModalVisible}
        onClose={closeReimportSuppliersModal}
        apiCall={startReimportSuppliers}
      />
    </>
  );
}
