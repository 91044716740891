import React, { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet';
import { useHistory, useParams } from 'react-router';

import { Box, Button, Container, Stack, Typography } from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';
import Spinner from '~/components/Common/Spinner';

import useCurrentTenant from '~/hooks/useCurrentTenant';

import { formatUTCDateDateTimeWidget } from '~/services/TimeService';
import dealPageService from '~/services/cruises/DealPageService';

import DealPageForm from '../components/DealPageForm';
import { CruiseDealPage, CruiseDealPageCreation } from '../types';

function DealPageEdit() {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [dealPage, setDealPage] = useState<CruiseDealPage | null>(null);
  const [fetching, setFetching] = useState(false);

  const { tenant } = useCurrentTenant();

  const handleSubmit = useCallback(
    async (formValues: CruiseDealPageCreation) => {
      setFetching(true);

      try {
        await dealPageService.update(id, {
          ...formValues,
          id,
          startDate: formatUTCDateDateTimeWidget(new Date(formValues.startDate)),
          endDate: formatUTCDateDateTimeWidget(new Date(formValues.endDate)),
        });
        enqueueSnackbar('Deal page updated successfully', { variant: 'success' });
        history.push('/cruises/deals');
      } catch (error) {
        enqueueSnackbar(`Error updating deal page: ${error}`, { variant: 'error' });
      } finally {
        setFetching(false);
      }
    },
    [enqueueSnackbar, history, id],
  );

  const fetchDealPage = useCallback(async () => {
    const { result } = await dealPageService.getById(id);
    setDealPage(result);
  }, [id]);

  useEffect(() => {
    fetchDealPage();
  }, [fetchDealPage]);

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>Edit Deal Page</title>
      </Helmet>

      <PageHeader title="Edit Deal Page" backButton="/cruises/deals">
        {dealPage && (
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>Preview on:</Typography>
            <Button
              variant="text"
              target="_blank"
              rel="noreferrer"
              href={`${window.configs.LUX_CUSTOMER_PORTAL}/cruises/deal/${dealPage.slug || dealPage.id}`}
            >
              {tenant.title}
            </Button>
          </Stack>
        )}
      </PageHeader>

      {fetching && <Spinner size={30} />}

      {dealPage && !fetching && (
        <Box>
          <Box mt={1}>
            <DealPageForm dealPage={dealPage} onSubmit={handleSubmit} />
          </Box>
        </Box>
      )}
    </Container>
  );
}

export default DealPageEdit;
