import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';

import { Box, LinearProgress, Typography } from '@mui/material';
import { DataGrid, GridPaginationModel } from '@mui/x-data-grid';

import PageSubheader from '~/components/Common/Elements/PageSubheader';

import { formatDateSlashes } from '~/services/TimeService';
import VendorsService from '~/services/VendorsService';

interface SalesHedgeLog {
  id_sales_hedge_log: string;
  created_at: string;
  file_sent: boolean;
  notes: string;
}

export default function SalesFxHedgingLog() {
  const [isLoading, setIsLoading] = useState(false);
  const [logData, setLogData] = useState<Array<SalesHedgeLog>>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: 10,
    page: 0,
  });
  const { enqueueSnackbar } = useSnackbar();

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      const data = await VendorsService.getSalesHedgeLog(paginationModel.page, paginationModel.pageSize);
      const salesHedgeLogs = data.result?.sales_hedge_logs;
      const totalLogCount = data.result?.log_count;
      setLogData(salesHedgeLogs);
      setTotalCount(totalLogCount);
    } catch (e) {
      enqueueSnackbar(`Error fetching sales hedge log: ${e.message}`, { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  }, [paginationModel.page, paginationModel.pageSize, enqueueSnackbar]);

  useEffect(() => {
    fetchData();
  }, [paginationModel, fetchData]);

  const columns = useMemo(
    () => [
      {
        field: 'id_sales_hedge_log',
        headerName: 'ID',
        width: 400,
        renderCell: (params) => {
          return <Typography>{params.row.id_sales_hedge_log}</Typography>;
        },
      },
      {
        field: 'created_at',
        headerName: 'Date',
        width: 150,
        valueFormatter: (value) => {
          return value ? formatDateSlashes(value) : 'N/A';
        },
      },
      {
        field: 'file_sent',
        headerName: 'File sent?',
        width: 100,
        renderCell: (params) => {
          return <Typography>{params.row.file_sent ? 'Yes' : 'No'}</Typography>;
        },
      },
      {
        field: 'notes',
        headerName: 'Notes',
        width: 400,
        renderCell: (params) => {
          return <Typography>{params.row.notes}</Typography>;
        },
      },
    ],
    [],
  );

  return (
    <Box>
      <PageSubheader title="Send log" />
      {isLoading && <LinearProgress />}
      <DataGrid
        rows={logData}
        columns={columns}
        rowCount={totalCount}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        getRowId={(row) => row.id_sales_hedge_log}
        getRowHeight={() => 'auto'}
        autoHeight
        disableRowSelectionOnClick={isLoading}
        initialState={{
          sorting: {
            sortModel: [{ field: 'created_at', sort: 'desc' }],
          },
        }}
      />
    </Box>
  );
}
