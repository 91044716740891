import { json_headers, request } from '~/services/common';

interface PriceComponent {
  price: number;
  supplierCode: string;
  roomID?: string;
}

interface Mismatch {
  isMismatch: boolean;
  reason: string;
  difference: number;
}

export interface GHAPriceCheckRow {
  propertyId: string;
  checkIn: string;
  checkOut: string;
  occupancy: string;
  publicOffer: PriceComponent;
  metaSearch: PriceComponent;
  mismatch: Mismatch;
}

export interface GHAPriceSummary {
  mismatchCount: number;
  totalItineraries: number;
  mismatchPercentage: number;
}

interface GHAPriceDiagnoseResponse {
  results: GHAPriceCheckRow[];
  summary: GHAPriceSummary;
}

function getBaseURL() {
  return window.configs.API_HOST + '/api';
}

export async function ghaPriceDiagnoseQuery({
  propertyIds,
  checkIn,
  nights,
  isMobile,
  combinations,
  occupancy,
}: {
  propertyIds: string;
  checkIn: string;
  nights: number;
  isMobile: boolean;
  combinations: number;
  occupancy: number;
}): Promise<{ result: GHAPriceDiagnoseResponse }> {
  const baseURL = getBaseURL();
  const url = `${baseURL}/connections/google/meta-search/query/diagnose?`;

  const body = {
    propertyIds: propertyIds.length > 0 ? propertyIds.split(',') : [],
    checkIn,
    nights,
    isMobile,
    combinations,
    occupancy,
  };

  return request(url, {
    method: 'POST',
    headers: json_headers,
    body: JSON.stringify(body),
  });
}
