import React from 'react';

import { Stack } from '@mui/material';

import usePagedSearch from '~/components/Accommodation/hooks/usePagedSearch';

import { AccommodationTabs } from '../../Home/AccommodationHomePage';

import AccommodationPropertiesSearchForm from './AccommodationPropertiesSearchForm';
import PotentialMappingsResultsTable from './PotentialMappingsResultsTable';

export default function PotentialMappingsResultsContainer() {
  const { searchInput, pageNumber, handleSearchFormSubmission, handlePageChange } = usePagedSearch();

  return (
    <Stack direction="column" spacing={2}>
      <AccommodationPropertiesSearchForm
        initialInput={searchInput}
        onSubmit={handleSearchFormSubmission}
        selectedTab={AccommodationTabs.POTENTIAL_MAPPINGS}
      />
      <PotentialMappingsResultsTable
        searchInput={searchInput}
        pageIndex={pageNumber - 1}
        onPageChange={handlePageChange}
      />
    </Stack>
  );
}
