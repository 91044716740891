import React from 'react';

import { Stack } from '@mui/material';

import { AccommodationTabs } from '~/components/Accommodation/Pages/Home/AccommodationHomePage';
import AccommodationPropertiesResultsContainer from '~/components/Accommodation/Pages/Properties/components/AccommodationPropertiesResultsContainer';
import AccommodationUnmappedSuppliersResultsContainer from '~/components/Accommodation/Pages/Properties/components/AccommodationPropertiesUnmappedSuppliersResultsContainer';
import PotentialMappingsResultsContainer from '~/components/Accommodation/Pages/Properties/components/PotentialMappingsResultsContainer';

interface Props {
  selectedTab: AccommodationTabs;
}

function AccommodationProperties({ selectedTab }: Props) {
  return (
    <Stack direction="column" spacing={2}>
      {selectedTab === AccommodationTabs.PROPERTIES && <AccommodationPropertiesResultsContainer />}
      {selectedTab === AccommodationTabs.UNMAPPED_SUPPLIERS && <AccommodationUnmappedSuppliersResultsContainer />}
      {selectedTab === AccommodationTabs.POTENTIAL_MAPPINGS && <PotentialMappingsResultsContainer />}
    </Stack>
  );
}

export default AccommodationProperties;
