import React from 'react';

import { Button } from '@mui/material';

import Spinner from '~/components/Common/Spinner';

import useReferralProcessor from '../hooks/useReferralProcessor';

type Props = {
  referralLogId: string;
};

function ForceReferralLogButton({ referralLogId }: Props) {
  const { processLog, isLoading } = useReferralProcessor();

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Button
      variant="contained"
      color="primary"
      title="When pressed, the referral log will be processed regardless of the order status"
      onClick={() => processLog({ referralLogId, ignoreOrderStatus: true })}
    >
      Force
    </Button>
  );
}

export default ForceReferralLogButton;
