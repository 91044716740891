import {
  DYNAMIC_PRICED_HOTEL_OFFER_TYPES,
  HOTEL_OFFER_TYPES,
  OFFER_TYPE_HOTEL,
  TOUR_OFFER_TYPES,
} from '~/consts/offerTypes';

export function isOfferTypeHotel(offer: App.Offer) {
  return HOTEL_OFFER_TYPES.includes(offer.type);
}

export function isOfferTypeTour(offer: App.Offer) {
  return TOUR_OFFER_TYPES.includes(offer.type);
}

export function isOfferTypeDynamicPricedHotel(offer: App.Offer) {
  return DYNAMIC_PRICED_HOTEL_OFFER_TYPES.includes(offer.type);
}

export function isLTLEHotelOffer(offer: App.Offer) {
  return offer.type === OFFER_TYPE_HOTEL;
}

export function isLTLEHotelOfferType(offerType: App.OfferType): offerType is 'hotel' {
  return offerType === OFFER_TYPE_HOTEL;
}
