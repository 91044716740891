import React, { ReactNode } from 'react';

import clsx from 'clsx';

import { Stack, SxProps } from '@mui/material';

import PageAreas from './PageAreas';
import {
  EMPTY_PAGE_COMPONENT_STYLE,
  INVALID_PAGE_COMPONENT_DESCENDENCE,
  PAGE_GUTTER_SIZE,
} from './pageSupportingStyles';

const rootStyle: SxProps = {
  ...EMPTY_PAGE_COMPONENT_STYLE,
  ...INVALID_PAGE_COMPONENT_DESCENDENCE,
  paddingInline: PAGE_GUTTER_SIZE,
  [`&.${PageAreas.startSide}`]: {
    gridArea: PageAreas.startSide,
    paddingInlineEnd: { md: 0 },
    borderRightWidth: { md: 1 },
    borderRightStyle: { md: 'solid' },
    borderRightColor: { md: 'neutral.dark' },
  },
  [`&.${PageAreas.endSide}`]: {
    gridArea: PageAreas.endSide,
    paddingInlineStart: { md: 0 },
    borderLeftWidth: { md: 1 },
    borderLeftStyle: { md: 'solid' },
    borderLeftColor: { md: 'neutral.dark' },
  },
  '&.width-small': { width: { md: 200 } },
  '&.width-medium': { width: { md: 280 } },
  '&.width-large': { width: { md: 300 } },
};

interface Props {
  placement: 'start' | 'end';
  /**
   * @default medium
   */
  width?: 'small' | 'medium' | 'large';
  children: ReactNode;
}

const PLACEMENT_AREAS: Record<Props['placement'], PageAreas> = {
  start: PageAreas.startSide,
  end: PageAreas.endSide,
};

function PageSidebar({ placement, width = 'medium', children }: Props) {
  return (
    <Stack direction="column" className={clsx(PLACEMENT_AREAS[placement], `width-${width}`)} sx={rootStyle}>
      {children}
    </Stack>
  );
}

export default PageSidebar;
