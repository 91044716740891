import React from 'react';

import { GREY } from '~/configs/colours';

import { SxProps } from '@mui/material';

import AppSidebar from '~/components/layouts/AppSidebar';

import SidebarSection, { SidebarSectionProps } from './SidebarSection';

const SIDEBAR_STYLE: SxProps = {
  borderRight: { xs: 0, md: `1px solid ${GREY[100]}` },
  borderColor: 'divider',
};

interface OpportunitySidebarProps {
  sections: Array<SidebarSectionProps>;
}

export default function OpportunitySidebar({ sections }: OpportunitySidebarProps) {
  return (
    <AppSidebar sx={SIDEBAR_STYLE}>
      {sections.map((section, index) => (
        <SidebarSection key={`${section.title}-${index}`} {...section} />
      ))}
    </AppSidebar>
  );
}
