import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Link } from 'react-router-dom';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, CircularProgress, Stack, TextField, Typography } from '@mui/material';

import { Inclusion, InclusionItem } from '~/components/Cruises/types';

import { formatDateISO } from '~/services/TimeService';
import inclusionsService from '~/services/cruises/InclusionsService';

import { INITIAL_REQUEST_STATE } from '../constants';
import { buildInclusionDepositDetails } from '../helpers';
import { DataRow, Request } from '../types';

type Props = {
  onClose: () => void;
  managementValues: DataRow;
};

type InclusionManagement = Inclusion & {
  items?: Array<InclusionItem>;
};

function RatePromosManagement({ managementValues, onClose }: Props): JSX.Element {
  const [inclusionNameFilter, setInclusionNameFilter] = useState('');
  const [filteredInclusions, setFilteredInclusions] = useState<Array<InclusionManagement>>([]);
  const [inclusions, setInclusions] = useState<Request<Array<InclusionManagement>>>(INITIAL_REQUEST_STATE);

  const fetchInclusionItems = useCallback(async (inclusion: Inclusion) => {
    const inclusionItems = await inclusionsService.getItems(inclusion.id);
    if (inclusionItems.status === 200) {
      return { ...inclusion, items: inclusionItems.result };
    }
    return inclusion;
  }, []);

  const fetchInclusionsByRateId = useCallback(
    async (rateId: string) => {
      setInclusions({ loading: true, result: null });
      const res = await inclusionsService.listWithPagination({
        rateCodes: [rateId],
      });
      if (res.status === 200) {
        const inclusionsPromise = res.result.map((inclusion) => {
          return fetchInclusionItems(inclusion);
        });
        const inclusions = await Promise.all(inclusionsPromise);
        setInclusions({ loading: false, result: inclusions });
      }
    },
    [fetchInclusionItems],
  );

  const handleChangeInclusionsNameField = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const fieldValue = event.target.value;
      setInclusionNameFilter(fieldValue);
      setFilteredInclusions(
        inclusions.result.filter((inclusion) => inclusion.name.toLowerCase().includes(fieldValue.toLowerCase())),
      );
    },
    [inclusions.result],
  );

  const resultsInclusions = useMemo(() => {
    if (inclusionNameFilter) {
      return filteredInclusions;
    }
    return inclusions.result;
  }, [filteredInclusions, inclusionNameFilter, inclusions.result]);

  useEffect(() => {
    if (managementValues.rateId) {
      fetchInclusionsByRateId(managementValues.rateId);
    }
  }, [fetchInclusionsByRateId, managementValues.rateId]);

  return (
    <Stack
      flexDirection="column"
      justifyContent="space-between"
      sx={{ height: '100vh', width: '600px', padding: '32px 40px' }}
    >
      <Stack
        alignItems="center"
        flexDirection="row"
        justifyContent="space-between"
        sx={{ svg: { width: '24px', height: '24px', cursor: 'pointer' } }}
      >
        <Typography variant="h5">
          Management Inclusions By Rate Code
          <br />
          {managementValues.rateCode}
        </Typography>
        <CloseIcon onClick={onClose} />
      </Stack>

      <Stack>
        {!inclusions.loading && !inclusions.result?.length && (
          <Stack alignItems="center" justifyContent="center" sx={{ height: '80vh' }}>
            <Typography>No inclusions found</Typography>
          </Stack>
        )}

        {inclusions.loading && (
          <Stack alignItems="center" justifyContent="center" sx={{ height: '80vh' }}>
            <CircularProgress size={32} />
          </Stack>
        )}

        {inclusions.result?.length > 0 && (
          <Stack minHeight="85vh" direction="column" justifyContent="space-between">
            <Box>
              <TextField
                fullWidth
                name="inclusionName"
                value={inclusionNameFilter}
                label="Filter By Inclusion Name"
                onChange={handleChangeInclusionsNameField}
              />
            </Box>

            <Stack maxHeight="75vh" sx={{ overflowY: 'scroll' }} mt={4}>
              {resultsInclusions?.map((inclusion) => (
                <Box
                  py={1}
                  my={1}
                  px={2}
                  key={inclusion.id}
                  sx={{
                    borderRadius: '8px',
                    border: '1px solid #e2e2e2',
                  }}
                >
                  <Box pb={1} sx={{ borderBottom: '0.5px solid #e2e2e2' }}>
                    <Stack
                      gap={2}
                      alignItems="center"
                      flexDirection="row"
                      sx={{ maxWidth: '100%' }}
                      justifyContent="space-between"
                    >
                      <Box sx={{ maxWidth: '65%' }}>
                        <Typography
                          variant="body2"
                          fontWeight="bold"
                          sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        >
                          {inclusion.name}
                        </Typography>
                      </Box>

                      <Stack gap={2} flexDirection="row" sx={{ maxWidth: '35%' }}>
                        <Button
                          size="small"
                          color="info"
                          variant="text"
                          component={Link}
                          target="_blank"
                          to={`/cruises/inclusions/edit/${inclusion.id}`}
                        >
                          Edit
                        </Button>
                      </Stack>
                    </Stack>
                  </Box>

                  <Stack mt={1} gap={2} flexDirection="row" sx={{ maxWidth: '100%' }}>
                    <Box sx={{ maxWidth: '50%' }}>
                      <Typography variant="body2">Lux Plus: {inclusion.type === 'LUX_PLUS' ? 'YES' : 'NO'}</Typography>
                      <Typography variant="body2">Standard: {inclusion.type === 'STANDARD' ? 'YES' : 'NO'}</Typography>
                      <Typography variant="body2">Start Date: {formatDateISO(inclusion.startDate)}</Typography>
                      <Typography variant="body2">End Date: {formatDateISO(inclusion.endDate)}</Typography>
                    </Box>

                    <Box sx={{ maxWidth: '50%' }}>
                      {inclusion.items?.map((item) => (
                        <>
                          {item.type === 'ONBOARD_CREDIT' && (
                            <Typography variant="body2">OBC: {buildInclusionDepositDetails(item)}</Typography>
                          )}
                          {item.type === 'FREE_TEXT' && (
                            <Typography variant="body2">Free Text: {item.description}</Typography>
                          )}
                        </>
                      ))}
                    </Box>
                  </Stack>
                </Box>
              ))}
            </Stack>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}

export default RatePromosManagement;
