import { useEffect, useRef } from 'react';

type EventSourceConstructor = { new (url: string, eventSourceInitDict?: EventSourceInit): EventSource };

type EventSourceEvent = Event & { data: string };

/**
 * @see https://lusito.github.io/react-nano/use-event-source/usage.html
 */
export function useEventSource(
  url: string,
  withCredentials?: boolean,
  ESClass: EventSourceConstructor = EventSource,
  maxRetries = 3,
  retryDelay = 1000,
): EventSource | undefined {
  const source = useRef<EventSource | undefined>(undefined);
  const retryCount = useRef(0);
  const retryTimeoutRef = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    function connect() {
      if (url) {
        const es = new ESClass(url, { withCredentials });
        source.current = es;

        es.addEventListener('open', () => {
          retryCount.current = 0;
        });

        es.addEventListener('error', () => {
          // If the event source isnt closed, don't retry
          if (source.current?.readyState !== EventSource.CLOSED) {
            return;
          }

          if (retryCount.current < maxRetries) {
            retryCount.current += 1;
            console.warn(`SSE Error. Retrying...${retryCount.current}/${maxRetries}`);
            retryTimeoutRef.current = setTimeout(() => {
              es.close();
              connect();
            }, retryDelay);
          } else {
            console.error('SSE Connection failed after maximum retries');
          }
        });

        return () => {
          if (retryTimeoutRef.current) {
            clearTimeout(retryTimeoutRef.current);
          }
          source.current = undefined;
          es.close();
        };
      }

      return undefined;
    }

    return connect();
  }, [url, withCredentials, ESClass, maxRetries, retryDelay]);

  return source.current;
}

/**
 * @see https://lusito.github.io/react-nano/use-event-source/usage.html
 */
export function useEventSourceListener(
  source: EventSource | null,
  types: Array<string>,
  listener: (e: EventSourceEvent) => void,
  dependencies: Array<unknown> = [],
) {
  useEffect(() => {
    if (source) {
      types.forEach((type) => source.addEventListener(type, listener));
      return () => types.forEach((type) => source.removeEventListener(type, listener));
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [source, ...dependencies]);
}
