import React from 'react';

import { Alert, Box, Grid, Stack, Typography } from '@mui/material';

import { definitions } from '@luxuryescapes/contract-svc-promo';

import { PROMO_TYPE_FIXED, PROMO_TYPE_PERCENTAGE } from '~/consts/promo';

import { DiscountItemInfo, getPromoItemDataWarnings, getPromoProductDisplayName } from '~/services/PromoService';

import currencyFormatter from '~/utils/currencyFormatter';

import BorderedGridItem from '../Common/BorderedGridItem';
import TravellersList from '../Promo/PromoPage/TravellersList';

import PromoItemField, { isExpectedField } from './PromoItemField';

type PromoItemResultProps = {
  discountItem: DiscountItemInfo;
  currency: string;
  isDevMode: boolean;
  meta: definitions['PromoMeta'];
};

function PromoItemResult({ discountItem, isDevMode, currency, meta }: PromoItemResultProps) {
  const {
    item,
    discountType,
    warning,
    discountAmount,
    matchingDiscount,
    luxPlusDiscountHigher,
    doesNotQualifyWithMemberPricing,
    discountHasCombinedWithLuxPlusPricing,
  } = discountItem;
  const { poffer, reqItem } = item;
  const { discountableTotal, luxPlusPrice, offerId, itemId } = reqItem;
  const hasLuxPlusPrice = luxPlusPrice && luxPlusPrice > 0;
  const originalPrice = hasLuxPlusPrice ? luxPlusPrice : discountableTotal;

  const warnings = getPromoItemDataWarnings(discountItem);

  return (
    <Box>
      <Grid
        container
        key={`${offerId}_${itemId}`}
        spacing={2}
        sx={{
          padding: '1px',
          margin: '1px',
        }}
      >
        <BorderedGridItem item title={`${JSON.stringify(discountItem, null, 4)}`}>
          <Stack direction="row" gap={1}>
            {discountAmount > 0 ? (
              <>
                <Typography sx={{ color: hasLuxPlusPrice ? 'blue' : '' }}>
                  {currencyFormatter(currency, originalPrice - discountAmount)}
                </Typography>

                <Typography sx={{ textDecoration: 'line-through', color: hasLuxPlusPrice ? 'blue' : '' }}>
                  {' '}
                  {currencyFormatter(currency, originalPrice)}
                </Typography>
                <Typography sx={{ color: 'green', marginLeft: 1 }}>
                  ({currencyFormatter(currency ?? 'AUD', discountAmount)})
                </Typography>
              </>
            ) : (
              <Typography>{currencyFormatter(currency, originalPrice)}</Typography>
            )}
            {warning && (
              <Typography variant="body2" component="div">
                {warning}
              </Typography>
            )}
            <Typography variant="body2" component="div">
              {discountType == PROMO_TYPE_PERCENTAGE && `${matchingDiscount.discount_value}%`}
              {discountType == PROMO_TYPE_FIXED && `${currencyFormatter(currency, matchingDiscount.discount_value)}`}
            </Typography>
            {luxPlusDiscountHigher && (
              <Typography variant="body2" component="div">
                <strong>luxPlusDiscountHigher: </strong>
              </Typography>
            )}
            {discountHasCombinedWithLuxPlusPricing && (
              <Typography variant="body2" component="div">
                <strong>(discountHasCombinedWithLuxPlusPricing)</strong>
              </Typography>
            )}
            {doesNotQualifyWithMemberPricing && (
              <Typography variant="body2" component="div">
                <strong>(doesNotQualifyWithMemberPricing)</strong>
              </Typography>
            )}
          </Stack>
          {matchingDiscount && matchingDiscount.discount_value > 0 && (
            <>
              <Typography variant="body2" component="div">
                <strong>Products: </strong>{' '}
                {matchingDiscount?.products && matchingDiscount?.products.length > 0 ? (
                  matchingDiscount.products.join(', ')
                ) : (
                  <Alert severity="warning">a matching discount? This is unexpected</Alert>
                )}
              </Typography>
              {matchingDiscount.min_spend && (
                <Typography variant="body2" component="div">
                  <strong>Minimum Spend: </strong> {currencyFormatter(currency, matchingDiscount.min_spend)}
                </Typography>
              )}
              {matchingDiscount.max_discount && (
                <Typography variant="body2" component="div">
                  <strong>Maximum Discount: </strong> {currencyFormatter(currency, matchingDiscount.max_discount)}
                </Typography>
              )}
              {matchingDiscount?.subscription_item_discount_type !== 'none' &&
                matchingDiscount?.subscription_item_discount_value > 0 && (
                  <Typography variant="body2" component="div">
                    <strong>Sub Discount: </strong>
                    {matchingDiscount.subscription_item_discount_type == 'fixed_amount'
                      ? currencyFormatter(currency, matchingDiscount.subscription_item_discount_value)
                      : `${matchingDiscount.subscription_item_discount_value}%`}
                  </Typography>
                )}
              <Typography variant="body2" component="div">
                <strong>Region: </strong> {matchingDiscount.region}
              </Typography>
            </>
          )}
        </BorderedGridItem>
        <BorderedGridItem>
          <PromoItemField
            title="Product Type"
            field="productType"
            categoryBK={reqItem.categoryBK}
            value={
              meta && getPromoProductDisplayName(meta.promo_product_types, reqItem.categoryBK, reqItem.subCategoryBK)
            }
          />
          <PromoItemField
            title="Reservation Type"
            field="reservationType"
            categoryBK={reqItem.categoryBK}
            value={reqItem.reservationType}
          />
          {isExpectedField(reqItem.categoryBK, 'travellers') && <TravellersList travellers={reqItem.travellers} />}
          <PromoItemField
            title="Item Country Code"
            field="itemCountryCode"
            categoryBK={reqItem.categoryBK}
            value={reqItem.itemCountryCode}
          />
          <PromoItemField
            title="Number of Nights"
            field="numberOfNights"
            categoryBK={reqItem.categoryBK}
            value={reqItem.numberOfNights}
          />
          <PromoItemField
            title="Number of Adults"
            field="numberOfAdults"
            categoryBK={reqItem.categoryBK}
            value={reqItem.numberOfAdults}
          />
          <PromoItemField
            title="Number of Children"
            field="numberOfChildren"
            categoryBK={reqItem.categoryBK}
            value={reqItem.numberOfChildren}
          />
          <PromoItemField
            title="Origin Airport Code"
            field="originAirportCode"
            categoryBK={reqItem.categoryBK}
            value={reqItem.originAirportCode}
          />
          {isDevMode && warnings.length > 0 && (
            <Stack direction="column" title={JSON.stringify({ warnings, reqItem: discountItem.item }, null, 4)}>
              {warnings.map((warning, i) => (
                <Alert key={i} severity="warning">
                  {warning.message}
                </Alert>
              ))}
            </Stack>
          )}
        </BorderedGridItem>
      </Grid>
    </Box>
  );
}

export default PromoItemResult;
