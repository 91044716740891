import React from 'react';

import { Link } from 'react-router-dom';

import ModeEditTwoToneIcon from '@mui/icons-material/ModeEditTwoTone';
import { Alert, Box, Grid, IconButton, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

import { OFFER_FACING_NAMES, OFFER_TYPE_BUNDLE_AND_SAVE } from '../../../consts/offerTypes';
import { customerPortalHost, salesforceHost } from '../../../services/common';
import { withTenant } from '../../hoc';

import OfferChecks from './offer-resident/OfferChecks';

const offerPage = (tenant, path) => {
  let tenantObject = {};
  tenantObject.value = tenant;
  const ending = !path.includes('?') ? '?preview=true' : '&preview=true';
  return customerPortalHost(tenantObject) + path + ending;
};

function OverviewPane({ id, offer, propertiesMissingGeodata, toursMissingGeodata, propertyData }) {
  const result = offer.result;
  const path = `/offer/${result.slug}/${result.id_salesforce_external}`;
  const customerPortals = {
    'Luxury Escapes': 'lux',
    Scoopon: 'scoopon',
    Cudo: 'cudo',
    TreatMe: 'treatme',
    Deals: 'deals',
    LEBT: 'lebusinesstraveller',
    'Agent Hub': 'leagenthub',
  };

  return (
    <Box id={id}>
      <Typography component="h1" variant="h3">
        {result.location}
        <Box>
          <IconButton href={'/edit-offers/' + result.id_salesforce_external}>
            <ModeEditTwoToneIcon color="primary" fontSize="large" />
          </IconButton>
        </Box>
      </Typography>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h5">{result.name}</Typography>
        <Typography variant="body1">
          {result.merchant_account_manager_email} &nbsp; &bull; &nbsp; View on
          {Object.keys(customerPortals).map(function (name) {
            return (
              <Box key={name} component="span" sx={{ ml: 1 }}>
                <a href={offerPage(customerPortals[name], path)} target="_blank" rel="noreferrer">
                  {name}
                </a>
              </Box>
            );
          })}
          <Box key={name} component="span" sx={{ ml: 1 }}>
            <a href={`${salesforceHost}/${result.id_salesforce_external}`} target="_blank" rel="noreferrer">
              Salesforce
            </a>
          </Box>
        </Typography>
      </Box>
      <Grid container>
        <Grid item sm={3} sx={{ mb: 3 }}>
          <Typography component="h6" color={[grey[600]]} sx={{ textTransform: 'uppercase', fontWeight: 500 }}>
            Offer Type
          </Typography>
          <Typography variant="body1">{OFFER_FACING_NAMES[result.type]}</Typography>
        </Grid>
        <Grid item sm={3} sx={{ mb: 3 }}>
          <Typography component="h6" color={[grey[600]]} sx={{ textTransform: 'uppercase', fontWeight: 500 }}>
            Vendor Id
          </Typography>
          {result.type !== OFFER_TYPE_BUNDLE_AND_SAVE && (
            <Typography variant="body1">
              <Link to={`/vendors/${result.vendor_account_id}`}>{result.vendor_account_id}</Link>
            </Typography>
          )}
          {result.type === OFFER_TYPE_BUNDLE_AND_SAVE && (
            <Typography variant="body1">
              {result.offer_bundles.map((bundled_offer) => (
                <>
                  <Link to={`/vendors/${bundled_offer.vendor_account_id}`}>{bundled_offer.vendor_account_id}</Link>
                  <br />
                </>
              ))}
            </Typography>
          )}
        </Grid>
        <Grid item sm={3} sx={{ mb: 3 }}>
          <Typography component="h6" color={[grey[600]]} sx={{ textTransform: 'uppercase', fontWeight: 500 }}>
            Vendor Booking Email
          </Typography>
          <Typography variant="body1">{result.vendor_booking_email}</Typography>
        </Grid>
        <Grid item sm={3} sx={{ mb: 3 }}>
          <Typography component="h6" color={[grey[600]]} sx={{ textTransform: 'uppercase', fontWeight: 500 }}>
            Slug
          </Typography>
          <Typography variant="body1">{result.slug}</Typography>
        </Grid>
        <Grid item sm={12} sx={{ mb: 3 }}>
          <OfferChecks offer={result} />
        </Grid>
      </Grid>
      <Grid container>
        {propertyData?.at(0)?.channel_managed && (
          <Grid item sm={3} sx={{ mb: 3 }}>
            <Typography component="h6" color={[grey[600]]} sx={{ textTransform: 'uppercase', fontWeight: 500 }}>
              Channel Manager
            </Typography>
            <Typography variant="body1">{propertyData?.at(0)?.channel_manager}</Typography>
          </Grid>
        )}
      </Grid>
      {result.partnerships.map((partnership) => (
        <Grid container key={partnership?.prefix}>
          <Grid item sm={3} sx={{ mb: 3 }}>
            <Typography component="h6" color={[grey[600]]} sx={{ textTransform: 'uppercase', fontWeight: 500 }}>
              {partnership?.prefix.toUpperCase()} enabled
            </Typography>
            <Typography variant="body1">Yes</Typography>
          </Grid>
        </Grid>
      ))}
      {propertiesMissingGeodata.length > 0 && (
        <Grid container spacing={2} mb={3}>
          <Grid item sm={12}>
            {propertiesMissingGeodata.map((propertyId) => (
              <Alert severity="warning" icon={false} key={propertyId}>
                A property for this offer is missing geolocation data.{' '}
                <Link to={`/vendors/${result.vendor_account_id}/properties/${propertyId}`}>See property.</Link>
              </Alert>
            ))}
          </Grid>
        </Grid>
      )}
      {toursMissingGeodata.length > 0 && (
        <Grid container spacing={2} mb={3}>
          <Grid item sm={12}>
            {toursMissingGeodata.map((tourId) => (
              <Alert severity="warning" icon={false} key={tourId}>
                A tour for this offer is missing geolocation data.
                <br />
                It will not be shown correctly on the map view feature on the website.
                <br />
                <Link to={`/vendors/${result.vendor_account_id}/tours/${tourId}`}>See tour.</Link>
              </Alert>
            ))}
          </Grid>
        </Grid>
      )}
    </Box>
  );
}

export default withTenant(OverviewPane);
export { OverviewPane };
