import React from 'react';

import { Grid } from '@mui/material';

import { operations } from '@luxuryescapes/contract-svc-promo';

import BorderedGridItem from '~/components/Common/BorderedGridItem';

import { PromoMeta, sortPromoItemByCategoryAndId } from '~/services/PromoService';

import { PromoResponseItem } from './PromoResponseItem';

type PromoOrderTableCellProps = {
  response: operations['calculateDiscount']['responses']['200']['content']['application/json'];
  meta: PromoMeta;
  isDev: boolean;
};

function PromoResponse({ response, meta, isDev }: PromoOrderTableCellProps) {
  if (!response || !response.result || !response.result.promo) {
    return null;
  }

  const { promo } = response.result;

  const {
    has_bin_numbers,
    is_corporate,
    items,
    promo_category,
    promo_toggles,
    limit_per_user_per_year_remaining,
    limit_per_user_per_year,
  } = promo;

  return (
    <Grid container>
      {items.sort(sortPromoItemByCategoryAndId).map((item, i) => (
        <BorderedGridItem key={`${item.offerId || item.itemId}_${i}`}>
          <PromoResponseItem
            key={`${item.offerId || item.itemId}_${i}`}
            item={item}
            currencyCode={promo.currency}
            meta={meta}
            extendedItem={item.categoryBK !== 'subscription'}
          />
        </BorderedGridItem>
      ))}
    </Grid>
  );
}

export { PromoResponse };
