import React, { useCallback, useEffect, useMemo, useState } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import { CruisesContract } from '@luxuryescapes/contract-svc-cruise';

import useToggleState from '~/hooks/useToggleState';

import cruiseLineService, { CruiseLine } from '~/services/cruises/CruiseLineService';
import departureService from '~/services/cruises/DepartureService';

import { CreateRateFormValues, Feedback } from '../types';

type Request<T> = {
  result: T;
  loading: boolean;
};

type Option = {
  value: string;
  label: string;
  name?: string;
};

type SelectFormValues = {
  cruiseLine: Option;
  departures: Array<Option>;
};

const INITIAL_REQUEST_STATE = {
  result: [],
  loading: false,
};

const INITIAL_SELECT_FORM_STATE: SelectFormValues = {
  departures: [],
  cruiseLine: null,
};

type Props = {
  feedback: Feedback;
  onClose: () => void;
  onSubmit: (formValues: CreateRateFormValues) => void;
};

const CreateRateCodeForm = (props: Props): JSX.Element => {
  const { feedback, onClose, onSubmit } = props;

  const [rateCode, setRateCode] = useState<string>(null);
  const [selectedValues, setSelectedValues] = useState<SelectFormValues>(INITIAL_SELECT_FORM_STATE);
  const [cruiseLines, setCruiseLines] = useState<Request<CruiseLine[]>>(INITIAL_REQUEST_STATE);
  const [departures, setDepartures] = useState<Request<CruisesContract.DepartureResponse[]>>(INITIAL_REQUEST_STATE);
  const { isToggled: isFlash, toggle: toggleFlash } = useToggleState(false);

  const handleChangeRateCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRateCode(event.target.value);
  };

  const getCruiseLines = useCallback(async () => {
    if (cruiseLines.loading) return;
    setCruiseLines((prev) => ({ ...prev, loading: true }));

    const res = await cruiseLineService.listWithPagination({ skip: 0, take: 1000 });

    if (res.status === 200 && res.result) {
      setCruiseLines({ loading: false, result: res.result });
    }
  }, [cruiseLines.loading]);

  const getDeparturesByCruiseLine = useCallback(async () => {
    if (departures.loading || !selectedValues.cruiseLine?.value) return;

    setDepartures((prev) => ({ ...prev, loading: true }));

    let skip = 0;
    const take = 500;

    const res = await departureService.listWithPagination({
      skip,
      take,
      vendorId: selectedValues.cruiseLine.value,
    });

    if (res.status === 200 && res.result) {
      const totalDepartures = res.total;
      const selectedCruiseLineDepartures: CruisesContract.DepartureResponse[] = [];
      selectedCruiseLineDepartures.push(...res.result);
      const requests = [];

      for (let i = 0; i < totalDepartures / 500; i++) {
        skip += take;
        requests.push(
          departureService.listWithPagination({
            skip,
            take,
            vendorId: selectedValues.cruiseLine.value,
          }),
        );
      }

      const results = await Promise.allSettled(requests);
      results.map((promise) => {
        if (promise.status === 'fulfilled') {
          selectedCruiseLineDepartures.push(...promise.value.result);
        }
      });
      setDepartures({ loading: false, result: selectedCruiseLineDepartures });
    }
  }, [departures.loading, selectedValues.cruiseLine?.value]);

  useEffect(() => {
    getCruiseLines();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedValues.cruiseLine?.value) getDeparturesByCruiseLine();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValues.cruiseLine?.value]);

  const handleSubmit = () => {
    onSubmit({
      rateCode,
      vendorId: selectedValues.cruiseLine?.value,
      vendorName: selectedValues.cruiseLine?.name,
      departureIds: [...new Set(selectedValues.departures?.map((item) => item?.value))],
      isFlash,
    });
  };

  const cruiseLinesSelectOptions = useMemo(() => {
    return cruiseLines.result
      ?.map((item) => ({
        value: item.id,
        name: item.name,
        label: item.code,
      }))
      ?.sort((a, b) => (a.name > b.name ? 1 : -1));
  }, [cruiseLines.result]);

  const departureSelectOptions = useMemo(() => {
    return departures.result?.map((item) => ({
      value: item.id,
      name: item.name,
      label: `${item.externalId} | ${item.name} `,
    }));
  }, [departures.result]);

  const departuresSelected = useMemo(() => {
    return selectedValues.departures?.map((item) => ({
      name: item.name,
      label: item.label,
      value: item.value,
    }));
  }, [selectedValues.departures]);

  const handleCruiseLineChange = (_, selectedOption: Option | null) => {
    setSelectedValues({
      departures: [],
      cruiseLine: selectedOption,
    });
  };

  const handleSailingsChange = (_, selectedOptions: Option[] | null) => {
    setSelectedValues((prev) => ({
      ...prev,
      departures: selectedOptions
        ?.map((selectedOffer) => ({
          label: selectedOffer.label,
          value: selectedOffer.value,
        }))
        ?.filter((item) => !!item),
    }));
  };

  const handlePaste = useCallback(
    (event: React.ClipboardEvent<HTMLInputElement>) => {
      const clipboardFormattedData: Array<number> = event.clipboardData
        .getData('Text')
        .split(',')
        .map((item) => Number(item.trim()))
        .filter((item) => !!item);

      const sailings: Option[] = departures.result
        ?.filter((item) => clipboardFormattedData.includes(item.externalId))
        ?.map((item) => ({ value: item.id, name: item.name, label: `${item.externalId} | ${item.name} ` }));

      if (sailings?.length) handleSailingsChange(null, sailings);
    },
    [departures.result],
  );

  return (
    <Stack
      flexDirection="column"
      justifyContent="space-between"
      sx={{ height: '100vh', width: '600px', padding: '32px 40px' }}
    >
      <Stack
        alignItems="center"
        flexDirection="row"
        justifyContent="space-between"
        sx={{ svg: { width: '24px', height: '24px', cursor: 'pointer' } }}
      >
        <Stack flexDirection="row" alignItems="center">
          <ArrowBackIcon onClick={onClose} sx={{ marginRight: '8px' }} />
          <Typography variant="h5">Create Rate Code Form</Typography>
        </Stack>
        <CloseIcon onClick={onClose} />
      </Stack>

      <Box>
        <Stack width="100%" flexDirection="row" justifyContent="center">
          {!!feedback.loading && !feedback.status && <CircularProgress size={40} />}

          {!!feedback.message && !!feedback.status && (
            <Alert sx={{ width: '100%' }} severity={feedback.status}>
              {feedback.message}
            </Alert>
          )}
        </Stack>

        <Box mt={4}>
          <Alert severity="info">Please, select a cruise line to filter the departure list content.</Alert>
        </Box>

        <Stack mt={3} gap={2} flexDirection="row">
          <FormControl fullWidth>
            <TextField required name="rateCode" label="Rate Code" onChange={handleChangeRateCode} />
          </FormControl>

          <FormControl fullWidth>
            <Autocomplete
              openOnFocus
              id="select-vendor"
              loading={cruiseLines.loading}
              loadingText="Searching for cruise lines"
              value={selectedValues.cruiseLine}
              onChange={handleCruiseLineChange}
              options={cruiseLinesSelectOptions}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  fullWidth
                  label="Cruise Lines"
                  placeholder={`Please Enter Cruise Line`}
                />
              )}
            />
          </FormControl>
        </Stack>

        <Box mt={3}>
          <FormControl fullWidth>
            <Autocomplete
              id="select-multi-sailings"
              multiple
              disableCloseOnSelect
              onPaste={handlePaste}
              value={departuresSelected}
              loading={departures.loading}
              onChange={handleSailingsChange}
              options={departureSelectOptions}
              loadingText={`Searching for departures by cruise line: ${selectedValues.cruiseLine?.name}`}
              disabled={!selectedValues.cruiseLine?.value}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    size="small"
                    variant="outlined"
                    key={option.value}
                    label={option.label?.split('|')[0] ?? ''}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField {...params} required fullWidth label="Sailings" placeholder={`Please Enter Sailings`} />
              )}
            />
          </FormControl>
        </Box>

        <Box mt={3}>
          <FormControlLabel
            control={<Checkbox checked={isFlash} onChange={toggleFlash} size="medium" />}
            label="Is Flash?"
          />
        </Box>

        <Box mt={5}>
          <Button fullWidth variant="contained" onClick={handleSubmit} disabled={feedback.loading}>
            Save Changes
          </Button>
        </Box>
      </Box>
    </Stack>
  );
};

export default CreateRateCodeForm;
