import React from 'react';

import { Stack, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';

import DebugModal from '~/components/DebugModal/DebugModal';

function PromoDetails({ row }: GridRenderCellParams<App.PromoData>) {
  return (
    <Stack direction="column" spacing={2}>
      <Typography>{row.popup_message_presented_example}</Typography>
      <DebugModal title="Details" data={row} type="generic" />
    </Stack>
  );
}

export default PromoDetails;
