import React, { useState } from 'react';

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import SouthIcon from '@mui/icons-material/South';
import { Box, Button, Checkbox, ListItemText, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import * as libRegions from '@luxuryescapes/lib-regions';

import DateTimeWidget from '~/components/Common/Elements/DateTimeWidget';

import { SUPPLIER_NAMES } from '~/consts/bedbank';
import { searchableOfferTypesOptions } from '~/consts/offerFilterOptions';
import { OFFER_TYPE_BUNDLE_AND_SAVE } from '~/consts/offerTypes';
import {
  filterDropDownList,
  filterFields,
  multiSearchItemTypes,
  sortFields,
  sortRebookableFields,
} from '~/consts/orderFilter';

import { addDays, formatDateLongISO } from '~/services/TimeService';

import { toTitleCase } from '~/utils/toTitleCase';

import UserSearchWidget from '../UserSearchWidget';

type Props = {
  bookingIdType?: string | null;
  bookingNumberPlaceholder?: string;
  e2eSuffix?: string;
  useUniversalSearch?: boolean;
  showProviderIdFilter?: boolean;
  showRebookableFilter?: boolean;
  showOfferIdFilter?: boolean;
  showOfferTypeFilter?: boolean;
  showDepartureByIdFilter?: boolean;
  hideReferenceNumberFilter?: boolean;
  showCarHireFilter?: boolean;
  showInsuranceIdFilter?: boolean;
  showOrderNumberFilter?: boolean;
  showMultiSearch?: boolean;
  showBedbankProviderFilter?: boolean;
  showExperienceProviderFilter?: boolean;
  showBedbankPropertyFilter?: boolean;
  showRoomMappingCheckFilter?: boolean;
  searchQuery: (value: unknown, sort?: unknown) => void;
  setAdditionalParams: any;
};

type State = {
  [field: string]: string | Array<string>;
  searchField: string;
  searchValue: string;
  insuranceContractNumber: string;
  searchCustomerDetail: string;
  searchBookingNumber: string;
  searchReferenceNumber: string;
  searchOfferType: Array<string>;
  searchOtherFields: string;
  searchCarHireReservationCode: string;
  searchOrderNumber: string;
  searchBedbankSupplier: string;
  searchBedbankPropertyNameOrId: string;
};

type FilterState = {
  [field: string]: string | Array<string>;
  filterField: string;
  filterValue: string;
};

export type MultiFilterState = {
  itemType?: string;
  offerId?: string;
  startDate?: string;
  endDate?: string;
  checkInDate?: string;
  checkOutDate?: string;
  sortField?: string;
  sortType?: string;
  rebookableType?: string;
  newSuppliers?: Array<string>;
};

const offerTypeFilterOptions = searchableOfferTypesOptions.filter(
  (option) => option.value !== OFFER_TYPE_BUNDLE_AND_SAVE,
);

const emptyState = {
  insuranceContractNumber: '',
  searchCustomerDetail: '',
  searchBookingNumber: '',
  searchOfferId: '',
  searchOfferType: [],
  searchDepartureId: '',
  searchReferenceNumber: '',
  searchSoldBy: '',
  searchOtherFields: '',
  searchOrderNumber: '',
  searchField: '',
  searchValue: '',
  searchCarHireReservationCode: '',
  searchBedbankSupplier: '',
  searchExperienceProvider: '',
  endDate: '',
  startDate: '',
  searchBedbankPropertyNameOrId: '',
};

const emptyFilterState = {
  filterField: '',
  filterValue: '',
  startDate: '',
  endDate: '',
};

const emptyMultiFilterState = {
  itemType: '',
  offerId: '',
  checkInDate: '',
  checkOutDate: '',
  sortField: '',
  sortType: '',
  startDate: '',
  endDate: '',
  rebookableType: 'verified',
  newSuppliers: ['expedia', 'hotelbeds'],
};

function OrderSearchForm({
  bookingNumberPlaceholder = 'Enter Booking Number or Order ID',
  e2eSuffix = '',
  showProviderIdFilter = false,
  showOfferIdFilter = false,
  showOfferTypeFilter = false,
  showDepartureByIdFilter = false,
  showRebookableFilter = false,
  hideReferenceNumberFilter = false,
  showInsuranceIdFilter = false,
  showCarHireFilter = false,
  showOrderNumberFilter = false,
  showMultiSearch = false,
  showBedbankProviderFilter = false,
  showExperienceProviderFilter = false,
  showBedbankPropertyFilter = false,
  showRoomMappingCheckFilter = false,
  searchQuery,
  setAdditionalParams,
  bookingIdType = 'booking_numbers',
}: Props) {
  const [state, setState] = useState<State>({
    searchField: 'created_at',
    searchValue: '',
    insuranceContractNumber: '',
    searchCustomerDetail: '',
    searchBookingNumber: '',
    searchOfferId: '',
    searchOfferType: [],
    searchDepartureId: '',
    searchReferenceNumber: '',
    searchOrderNumber: '',
    searchSoldBy: '',
    searchOtherFields: '',
    searchCarHireReservationCode: '',
    searchBedbankSupplier: '',
    searchExperienceProvider: '',
    searchBedbankPropertyNameOrId: '',
  });
  const [filterState, setFilterState] = useState<FilterState>({
    filterField: 'created_at',
    filterValue: '',
  });
  const [multiFilterState, setMultiFilterState] = useState<MultiFilterState>({
    ...emptyMultiFilterState,
    endDate: formatDateLongISO(),
    startDate: formatDateLongISO(addDays(-1)),
  });
  const [autoCompleteValue, setAutoCompleteValue] = useState();

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    const { searchField, searchValue } = state;
    const { filterField, filterValue } = filterState;
    const field = searchField || filterField;
    const value = searchValue || filterValue;

    setAdditionalParams(multiFilterState);
    searchQuery(field, value);
  };

  const updateStart = (time: string) => {
    setMultiFilterState({
      ...multiFilterState,
      startDate: time,
    });
  };

  const updateEnd = (time: string) => {
    setMultiFilterState({
      ...multiFilterState,
      endDate: time,
    });
  };

  const updateCheckIn = (time: string) => {
    setMultiFilterState({
      ...multiFilterState,
      checkInDate: time,
    });
  };

  const updateCheckOut = (time: string) => {
    setMultiFilterState({
      ...multiFilterState,
      checkOutDate: time,
    });
  };

  const handleItemTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMultiFilterState({ ...multiFilterState, itemType: event.target.value });
  };

  const handleOfferIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMultiFilterState({ ...multiFilterState, offerId: event.target.value });
  };

  const handleSortChange = (event) => {
    const sortField = event.target.value;
    setAutoCompleteValue(null);
    setMultiFilterState({
      ...multiFilterState,
      sortField,
    });
  };

  const handleSortTypeChange = (event) => {
    const sortType = event.target.value;
    setMultiFilterState({
      ...multiFilterState,
      sortType,
    });
    setAutoCompleteValue(null);
  };

  const handleRebookableTypeChange = (event) => {
    const rebookableType = event.target.value;
    setMultiFilterState({
      ...multiFilterState,
      rebookableType,
    });
  };

  const handleNewSuppliersChange = (event) => {
    const newSuppliers = event.target.value;

    // hacky way to do select all
    if (newSuppliers[newSuppliers.length - 1] === 'Select All') {
      setMultiFilterState({
        ...multiFilterState,
        newSuppliers: multiFilterState.newSuppliers?.length === SUPPLIER_NAMES.length ? [] : [...SUPPLIER_NAMES],
      });
      return;
    }

    setMultiFilterState({
      ...multiFilterState,
      newSuppliers,
    });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const field = event?.target?.name;
    // MUI Select do not pass id
    // MUI Select indeed does not pass id. Hacky fix to make bedbank supplier dropdown work
    let fieldId: string | undefined;
    switch (field) {
      case 'searchBedbankSupplier':
        fieldId = 'bedbank_supplier';
        break;
      case 'searchExperienceProvider':
        fieldId = 'experiences_provider';
        break;
      case 'searchOfferType':
        fieldId = 'offer_types';
        break;
      default:
        fieldId = event?.target?.id;
    }

    let value = event.target.value;
    if (field === 'searchBookingNumber') {
      value = value.toUpperCase();
    }

    setAutoCompleteValue(null);
    setState({
      ...emptyState,
      [field]: value,
      searchField: fieldId,
      searchValue: Array.isArray(value) ? value?.join(',') : value,
    });
    setFilterState({
      ...emptyFilterState,
    });
    setMultiFilterState({
      ...emptyMultiFilterState,
    });
  };

  const handleFilterChange = (event) => {
    const filterField = event.target.value;
    setAutoCompleteValue(null);
    setState({
      ...emptyState,
    });
    setFilterState({
      ...emptyFilterState,
      filterField,
      ...(filterField === 'rebookable' ? { filterValue: 'true' } : {}),
    });
    setMultiFilterState({
      ...emptyMultiFilterState,
    });
  };

  const handleSearchSoldByChange = (value) => {
    const searchSoldBy = value;
    const fieldId = 'sold_by';

    setState({
      ...emptyState,
      searchField: fieldId,
      searchValue: searchSoldBy,
      searchSoldBy,
    });
    setFilterState({
      ...emptyFilterState,
    });
    setMultiFilterState({
      ...emptyMultiFilterState,
    });
  };

  const renderDropDown = () => {
    const filterBy = filterState.filterField;
    if (filterBy === 'currency_code') {
      return libRegions.getRegions().map(
        (region) =>
          region.currencyCode && (
            <MenuItem key={region.name} value={region.currencyCode}>
              {region.name + ' (' + region.currencyCode + ')'}
            </MenuItem>
          ),
      );
    }

    if (filterDropDownList[filterBy]) {
      return filterDropDownList[filterBy].map((field: { key: string; value: string }) => (
        <MenuItem key={field.key} value={field.key}>
          {field.value}
        </MenuItem>
      ));
    }
  };

  const shouldFilterByDates = filterState.filterField === 'created_at';
  const shouldFilterByOrderFilters = ['currency_code', 'status', 'utm_source'].includes(filterState.filterField);

  return (
    <Box mb={4}>
      <form onSubmit={handleSubmit}>
        <Box>
          <Typography variant="h6">Filter By</Typography>
        </Box>

        <Box mt={1}>
          <Grid container spacing={2}>
            {showInsuranceIdFilter && (
              <Grid xs={12} sm={6} lg={4}>
                <TextField
                  variant="outlined"
                  type="text"
                  label="Insurance ID"
                  id="insurance_contract_number"
                  name="insuranceContractNumber"
                  placeholder="Enter Insurance ID"
                  inputProps={{
                    className: `T-booking-number-input${e2eSuffix}`,
                  }}
                  value={state.insuranceContractNumber}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
            )}

            {bookingIdType && (
              <Grid xs={12} sm={6} lg={4}>
                <TextField
                  variant="outlined"
                  type="text"
                  label="Booking Number"
                  name="searchBookingNumber"
                  id={bookingIdType}
                  placeholder={bookingNumberPlaceholder}
                  value={state.searchBookingNumber}
                  onChange={handleInputChange}
                  inputProps={{
                    className: `T-booking-number-input${e2eSuffix}`,
                  }}
                  fullWidth
                />
              </Grid>
            )}

            <Grid xs={12} sm={6} lg={4}>
              <TextField
                variant="outlined"
                type="text"
                label="Customer email / name"
                id="customer_details"
                name="searchCustomerDetail"
                placeholder="Enter customer email or name"
                value={state.searchCustomerDetail}
                onChange={handleInputChange}
                fullWidth
              />
            </Grid>

            {!hideReferenceNumberFilter && (
              <Grid xs={12} sm={6} lg={4}>
                <TextField
                  variant="outlined"
                  type="text"
                  label="Reference Number"
                  id="reference_number"
                  name="searchReferenceNumber"
                  placeholder="Reference Number (Latitude finance)"
                  value={state.searchReferenceNumber}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
            )}

            {showOfferIdFilter && (
              <Grid xs={12} sm={6} lg={4}>
                <TextField
                  variant="outlined"
                  type="text"
                  label="Offer ID"
                  id="offer_id"
                  name="searchOfferId"
                  placeholder="Enter offer ID"
                  value={state.searchOfferId}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
            )}

            {showOfferTypeFilter && (
              <Grid xs={12} sm={6} lg={4}>
                <TextField
                  variant="outlined"
                  label="Offer type"
                  id="offer_types"
                  name="searchOfferType"
                  placeholder="Select one or more offer types"
                  value={state.searchOfferType}
                  SelectProps={{
                    multiple: true,
                  }}
                  onChange={handleInputChange}
                  select
                  fullWidth
                >
                  {offerTypeFilterOptions.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}

            {bookingIdType && (
              <Grid xs={12} sm={6} lg={4}>
                <UserSearchWidget
                  value={state.searchSoldBy}
                  label="Purchases: Name / Email / Customer Support Code / User ID"
                  name="sold_by"
                  formErrors={[]}
                  onChange={(id, value) => {
                    handleSearchSoldByChange(value);
                  }}
                  passedAutocompleteValue={autoCompleteValue}
                  setPassedAutocompleteValue={setAutoCompleteValue}
                  clearOnBlur
                  showOnlyEmail
                />
              </Grid>
            )}

            {showOrderNumberFilter && (
              <Grid xs={12} sm={6} lg={4}>
                <TextField
                  variant="outlined"
                  type="text"
                  label="Order Number"
                  id="order_number"
                  name="searchOrderNumber"
                  placeholder="Enter Order Number"
                  value={state.searchOrderNumber}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
            )}

            {showDepartureByIdFilter && (
              <Grid xs={12} sm={6} lg={4}>
                <TextField
                  fullWidth
                  type="text"
                  variant="outlined"
                  label="Departure ID"
                  id="departure_id"
                  name="searchDepartureId"
                  placeholder="Enter Departure ID"
                  value={state.searchDepartureId}
                  onChange={handleInputChange}
                />
              </Grid>
            )}

            {showCarHireFilter && (
              <Grid xs={12} sm={6} lg={4}>
                <TextField
                  fullWidth
                  type="text"
                  variant="outlined"
                  label="Car Hire Reservation Code"
                  id="car_hire_reservation_code"
                  name="searchCarHireReservationCode"
                  placeholder="Enter Car Hire Reservation Code"
                  value={state.searchCarHireReservationCode}
                  onChange={handleInputChange}
                />
              </Grid>
            )}

            {showBedbankProviderFilter && (
              <Grid xs={12} sm={6} lg={4}>
                <TextField
                  variant="outlined"
                  type="text"
                  label="Bedbank Supplier"
                  id="bedbank_supplier"
                  name="searchBedbankSupplier"
                  placeholder="Select a bedbank supplier"
                  value={state.searchBedbankSupplier}
                  onChange={handleInputChange}
                  select
                  fullWidth
                >
                  {SUPPLIER_NAMES.map((supplier) => (
                    <MenuItem key={supplier} value={supplier}>
                      {toTitleCase(supplier)}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}

            {showBedbankPropertyFilter && (
              <Grid xs={12} sm={6} lg={4}>
                <TextField
                  variant="outlined"
                  type="text"
                  label="Bedbank Property Name or ID"
                  id="bedbank_property"
                  name="searchBedbankPropertyNameOrId"
                  placeholder="Enter Bedbank Property Name or ID"
                  value={state.searchBedbankPropertyNameOrId}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
            )}

            {showExperienceProviderFilter && (
              <Grid xs={12} sm={6} lg={4}>
                <TextField
                  variant="outlined"
                  type="text"
                  label="Experience Provider"
                  id="provider"
                  name="searchExperienceProvider"
                  placeholder="Enter experience provider"
                  value={state.searchExperienceProvider}
                  onChange={handleInputChange}
                  select
                  fullWidth
                >
                  <MenuItem key="dby" value="dby">
                    Derbysoft
                  </MenuItem>
                  <MenuItem key="rezdy" value="rez">
                    Rezdy
                  </MenuItem>
                  <MenuItem key="led" value="led">
                    Led
                  </MenuItem>
                </TextField>
              </Grid>
            )}

            {showProviderIdFilter && (
              <Grid xs={12} sm={6} lg={4}>
                <TextField
                  variant="outlined"
                  type="text"
                  label="Provider Order ID"
                  id="provider_order_id"
                  name="searchProviderId"
                  placeholder="Enter provider order ID"
                  value={state.searchProviderId}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
            )}
          </Grid>
        </Box>

        <Box mt={2}>
          <Typography variant="h6">Filter By Other Fields</Typography>
        </Box>

        <Box mt={1}>
          <Grid container spacing={2}>
            <Grid xs={12} md={3} lg={2}>
              <Select
                value={filterState.filterField}
                onChange={handleFilterChange}
                name="orderFilterField"
                id="order_filter_field"
                displayEmpty
                fullWidth
                variant="outlined"
              >
                <MenuItem disabled value="">
                  <Typography component="em" color="gray">
                    Select field
                  </Typography>
                </MenuItem>
                {filterFields({ showMultiSearch, showRebookableFilter, showRoomMappingCheckFilter }).map((field) => (
                  <MenuItem key={field.key} value={field.key}>
                    {field.value}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            {shouldFilterByOrderFilters && (
              <Grid xs={12} md={4} lg={3}>
                <Select
                  variant="outlined"
                  value={filterState.filterValue}
                  name="customOrderFilter"
                  id="custom_order_filter"
                  onChange={(event) => setFilterState({ ...filterState, filterValue: event.target.value })}
                  fullWidth
                  required
                >
                  <MenuItem disabled value="">
                    <Typography component="em" color="gray">
                      Select value
                    </Typography>
                  </MenuItem>
                  {renderDropDown()}
                </Select>
              </Grid>
            )}

            {filterState.filterField === 'multi_search' && (
              <>
                <Grid xs={12} md={3} lg={2}>
                  <Select
                    value={multiFilterState.itemType}
                    onChange={handleItemTypeChange}
                    name="multiFilterItemType"
                    id="multi_filter_item_type"
                    displayEmpty
                    variant="outlined"
                    required
                    fullWidth
                  >
                    <MenuItem disabled value="">
                      <Typography component="em" color="gray">
                        Select offer type
                      </Typography>
                    </MenuItem>
                    {multiSearchItemTypes.map((field) => (
                      <MenuItem key={field.key} value={field.value}>
                        {field.value}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid xs={12} md={3} lg={4}>
                  <TextField
                    variant="outlined"
                    type="text"
                    label="Offer ID"
                    id="offer_id_multi"
                    name="searchOfferIdMulti"
                    placeholder="Enter offer ID"
                    value={multiFilterState.offerId}
                    onChange={handleOfferIdChange}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid xs={12} md={10} lg={4}>
                  <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" justifyContent="center" gap={2}>
                    <DateTimeWidget
                      label="From"
                      value={multiFilterState.checkInDate}
                      onChange={updateCheckIn}
                      showDateOnly
                    />

                    <ArrowRightAltIcon sx={{ display: { xs: 'none', sm: 'block' } }} />
                    <SouthIcon sx={{ display: { xs: 'block', sm: 'none' } }} />

                    <DateTimeWidget
                      label="To"
                      value={multiFilterState.checkOutDate}
                      onChange={updateCheckOut}
                      showDateOnly
                    />
                  </Stack>
                </Grid>
              </>
            )}

            {shouldFilterByDates && (
              <Grid xs={12} md={8} lg={8}>
                <Stack direction={{ xs: 'column', sm: 'row' }} alignItems="center" justifyContent="center" gap={2}>
                  <DateTimeWidget label="From" value={multiFilterState.startDate} onChange={updateStart} />

                  <ArrowRightAltIcon sx={{ display: { xs: 'none', sm: 'block' } }} />
                  <SouthIcon sx={{ display: { xs: 'block', sm: 'none' } }} />

                  <DateTimeWidget label="To" value={multiFilterState.endDate} onChange={updateEnd} />
                </Stack>
              </Grid>
            )}

            {filterState.filterField === 'rebookable' && (
              <>
                <Grid xs={12} md={3} lg={2}>
                  <Select
                    value={multiFilterState.sortField}
                    onChange={handleSortChange}
                    name="orderSortField"
                    id="order_sort_field"
                    displayEmpty
                    fullWidth
                    variant="outlined"
                    required
                  >
                    <MenuItem disabled value="">
                      <Typography component="em" color="gray">
                        Sort By
                      </Typography>
                    </MenuItem>
                    {(showRebookableFilter ? sortRebookableFields : sortFields).map((field) => (
                      <MenuItem key={field.key} value={field.key}>
                        {field.value}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid xs={12} md={3} lg={2}>
                  <Select
                    value={multiFilterState.sortType}
                    onChange={handleSortTypeChange}
                    name="orderSortType"
                    id="order_sort_type"
                    fullWidth
                    variant="outlined"
                    required
                  >
                    <MenuItem disabled value="">
                      <Typography component="em" color="gray">
                        Sort order
                      </Typography>
                    </MenuItem>
                    <MenuItem key="asc" value="asc">
                      ASC
                    </MenuItem>
                    <MenuItem key="desc" value="desc">
                      DESC
                    </MenuItem>
                  </Select>
                </Grid>
                <Grid xs={12} md={3} lg={2}>
                  <Select
                    value={multiFilterState.rebookableType}
                    onChange={handleRebookableTypeChange}
                    name="rebookableType"
                    id="rebookable_type"
                    fullWidth
                    defaultValue="verified"
                    variant="outlined"
                    required
                  >
                    <MenuItem key="verified" value="verified">
                      Confirmed Match
                    </MenuItem>
                    <MenuItem key="unverified" value="unverified">
                      Check Room Mapping
                    </MenuItem>
                    <MenuItem key="inclusions_mismatch" value="inclusions_mismatch">
                      Inclusions Mismatch
                    </MenuItem>
                  </Select>
                </Grid>

                <Grid xs={12} md={3} lg={2}>
                  <Select
                    value={multiFilterState.newSuppliers ?? []}
                    onChange={handleNewSuppliersChange}
                    name="newSuppliers"
                    id="new_suppliers"
                    fullWidth
                    variant="outlined"
                    multiple
                    renderValue={(selected) => selected.map((s) => toTitleCase(s)).join(', ')}
                  >
                    <MenuItem disabled value="">
                      <Typography component="em" color="gray">
                        New Suppliers
                      </Typography>
                    </MenuItem>
                    {SUPPLIER_NAMES.map((name) => (
                      <MenuItem key={name} value={name}>
                        <Checkbox checked={Boolean(multiFilterState.newSuppliers?.includes(name))} />
                        <ListItemText primary={toTitleCase(name)} />
                      </MenuItem>
                    ))}
                    <MenuItem value="Select All">
                      <Checkbox checked={multiFilterState.newSuppliers.length === SUPPLIER_NAMES.length} />
                      <ListItemText primary="Select All" />
                    </MenuItem>
                  </Select>
                </Grid>
              </>
            )}

            <Grid xs={12} sm="auto" ml="auto">
              <Button className={`T-purchase-search-button${e2eSuffix}`} variant="contained" type="submit" fullWidth>
                {shouldFilterByDates ? 'Search By Date' : 'Search'}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>
    </Box>
  );
}

export default OrderSearchForm;
