import React from 'react';

import { Link } from 'react-router-dom';

import { Box, Typography } from '@mui/material';

import { UserType } from '~/types/responses';

interface OrderDetailsHeaderTitleProps {
  order: App.Order;
  customer?: UserType;
}

export default function OrderDetailsHeaderTitle({ order, customer }: OrderDetailsHeaderTitleProps) {
  if (!order) {
    return (
      <Box>
        <Typography variant="body1" sx={{ color: 'text.secondary', fontSize: '1rem' }}>
          Loading Order...
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="body1" sx={{ color: 'text.secondary', fontSize: '1rem' }}>
        <Link
          to={`/purchases/section/allOrders?customer_id=${customer?.id_member}`}
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          {customer?.fullName || 'Customer'}
        </Link>{' '}
        /{' '}
        <Link
          to={`/purchases/section/allOrders?customer_id=${customer?.id_member}`}
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          All Orders
        </Link>
      </Typography>
      <Typography variant="h5">Order details</Typography>
    </Box>
  );
}
