import { common } from '@mui/material/colors';
import { ThemeOptions, alpha, createTheme } from '@mui/material/styles';
import type {} from '@mui/x-data-grid/themeAugmentation';

import { GREEN, GREY, INTRINSIC_GREY, LIGHT_BLUE, ORANGE, RED, TEAL } from './colours';

declare module '@mui/material/styles' {
  interface Palette {
    neutral: Palette['primary'];
    tertiary: Palette['primary'];
  }

  interface PaletteOptions {
    neutral: PaletteOptions['primary'];
    tertiary: PaletteOptions['primary'];
  }
}
declare module '@mui/material/AppBar' {
  interface AppBarPropsColorOverrides {
    neutral: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true;
  }
}

export const lightTheme: ThemeOptions = createTheme({
  typography: {
    fontFamily: '"Suisse Intl", Arial, sans-serif',
    allVariants: {
      fontFamily: '"Suisse Intl", Arial, sans-serif',
    },
    h6: {
      fontSize: '1rem', // Set to match the figma file
    },
    body2: {
      fontSize: '0.875rem', // Set to match figma file = 14px
    },
  },
  palette: {
    mode: 'light',
    text: {
      primary: GREY[700],
      secondary: GREY[600],
      disabled: GREY[500],
    },
    primary: {
      main: TEAL[500],
      dark: TEAL[600],
      light: TEAL[200],
      contrastText: common.white,
    },
    secondary: {
      main: GREY[700],
      dark: GREY[800],
      light: GREY[300],
      contrastText: common.white,
    },
    tertiary: {
      main: GREY[500],
      dark: GREY[600],
      light: GREY[200],
      contrastText: common.white,
    },
    error: {
      main: RED[500],
      dark: RED[900],
      light: RED[50],
      contrastText: common.white,
    },
    warning: {
      main: ORANGE[500],
      dark: ORANGE[900],
      light: ORANGE[50],
      contrastText: common.white,
    },
    info: {
      main: LIGHT_BLUE[500],
      dark: LIGHT_BLUE[900],
      light: LIGHT_BLUE[50],
      contrastText: common.white,
    },
    success: {
      main: GREEN[500],
      dark: GREEN[900],
      light: GREEN[50],
      contrastText: common.white,
    },
    neutral: {
      main: common.white,
      light: common.white,
      dark: INTRINSIC_GREY[100],
      contrastText: common.black,
    },
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          borderRadius: 0,
          border: '1px solid',
          borderColor: alpha(GREY[700], 0.08),
          backgroundColor: alpha(GREY[700], 0.04),
          '&.Mui-expanded': {
            backgroundColor: alpha(TEAL[500], 0.08),
          },
          '&:hover': {
            backgroundColor: alpha(TEAL[500], 0.04),
          },
          '&.Mui-expanded, &:hover': {
            borderColor: alpha(TEAL[500], 0.08),
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          paddingTop: '1.5rem',
          backgroundColor: common.white,
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        expandIconWrapper: {
          color: TEAL[500],
        },
        root: {
          minHeight: '2.25rem !important',
        },
        content: {
          marginTop: 0,
          marginBottom: 0,
          '&.Mui-expanded': {
            marginTop: 0,
            marginBottom: 0,
          },
        },
      },
    },
    MuiAppBar: {
      variants: [{ props: { color: 'neutral' }, style: {} }],
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: GREY[700],
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
      variants: [
        {
          props: { variant: 'contained', color: 'neutral' },
          style: {},
        },
      ],
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: common.white,
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: '8px 16px 16px',
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: { variant: 'h6' },
      },
      styleOverrides: {
        root: {
          '& + .MuiCardContent-root': {
            paddingTop: 0,
          },
        },
        action: {
          alignSelf: 'unset',
          margin: 0,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          '&:last-child': {
            paddingBottom: '16px',
          },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          backgroundColor: common.white,
          borderColor: 'transparent',
        },
        columnHeader: (params) => ({
          backgroundColor: 'transparent',
          color: params.theme.palette.text.primary,
          typography: {
            fontWeight: 400,
          },
        }),
        cell: {
          display: 'flex',
          alignItems: 'center',
        },
        menuIconButton: (params) => ({
          color: params.theme.palette.primary.main,
        }),
        sortIcon: (params) => ({
          color: params.theme.palette.primary.main,
        }),
        menuIcon: (params) => ({
          color: params.theme.palette.primary.main,
        }),
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '8px 24px 24px',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          '.MuiDialogTitle-root + &': {
            paddingTop: '8px',
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          background: common.white,
          border: 'none',
          boxSizing: 'border-box',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          '&:hover': {
            backgroundColor: alpha(TEAL[500], 0.04),
          },
          '&.Mui-selected': {
            backgroundColor: alpha(TEAL[500], 0.08),
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: '14px',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: GREY[700],
          color: common.white,
          padding: '8px 16px',
        },
      },
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: 'overline' },
          style: {
            lineHeight: '11px',
            fontWeight: 500,
            fontSize: '11px',
            letterSpacing: '0.7px',
            color: GREY[500],
          },
        },
      ],
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: '2px',
        },
        standardSuccess: {
          backgroundColor: GREEN[50],
          color: GREEN[900],
        },
        standardWarning: {
          backgroundColor: 'transparent',
          color: ORANGE[900],
          border: `1px solid ${ORANGE[500]}`,
        },
        standardError: {
          backgroundColor: RED[50],
          color: RED[900],
        },
        standardInfo: {
          backgroundColor: LIGHT_BLUE[50],
          color: LIGHT_BLUE[900],
        },
      },
    },
  },
});
