import React from 'react';

import { Tooltip } from '@mui/material';

import { SingleHotelDetails } from './SingleHotelDetails';
import { SingleOrderPayments } from './SingleOrderPayments';

interface Props {
  order: App.Order;
}

function SingleOrder({ order }: Props) {
  const formattedTotal = order.total.toLocaleString(undefined, { maximumFractionDigits: 0 });

  if (order.has_bedbank) {
    const firstBedbankItem = order.bedbank_items[0];
    const address = firstBedbankItem.offer.address;
    const firstRoom = firstBedbankItem.rooms[0];

    return (
      <SingleHotelDetails
        order={order}
        checkIn={firstBedbankItem.check_in}
        bookingEmail={firstBedbankItem.booking_email}
        bookingPhone={firstBedbankItem.booking_phone}
        locationInfo={`Hotel in ${address.city}, ${address.countryName}`}
        guestFirstName={firstRoom.guest_first_name}
        guestLastName={firstRoom.guest_last_name}
        countryName={address.countryName}
      />
    );
  }

  if (order.accommodation_items.length > 0) {
    const firstHotelItem = order.accommodation_items[0];
    const reservation = firstHotelItem.reservation;
    let pendingBNBL = false;
    if (firstHotelItem?.reservation_type === 'buy_now_book_later' && firstHotelItem?.reservation_made === false) {
      pendingBNBL = true;
    }

    return (
      <SingleHotelDetails
        order={order}
        checkIn={reservation?.check_in}
        bookingEmail={reservation?.guest_email}
        bookingPhone={reservation?.guest_phone}
        locationInfo={firstHotelItem.offer.name}
        guestFirstName={reservation?.guest_first_name}
        guestLastName={reservation?.guest_last_name}
        pendingBNBL={pendingBNBL}
      />
    );
  }

  let mainType = 'unhandled type';
  if (order.tour_items.length > 0 || order.cruise_items.length > 0) {
    if (order.tour_items.length > 0) {
      mainType = 'tour';
    } else {
      mainType = 'cruise';
    }
    return (
      <div>
        {order.status} {mainType} order for {order.currency_code}
        {formattedTotal}{' '}
        <Tooltip title="Very unlikely a fraudster would book a tour or cruise">
          <span>🟢</span>
        </Tooltip>
      </div>
    );
  } else if (order.has_flight) {
    mainType = 'flight';
  } else if (order.has_experience) {
    mainType = 'experience';
  } else if (order.has_addons) {
    mainType = 'addon';
  } else if (order.has_gift_card) {
    mainType = 'gift_card';
  } else if (order.has_insurance) {
    mainType = 'insurance';
  }

  return (
    <div>
      {order.status}{' '}
      <a target="_blank" href={`/purchases/${order.id}`} rel="noreferrer">
        {mainType} order
      </a>{' '}
      for {order.currency_code}
      {formattedTotal}
      <br />
      {order.customer_full_name} {order.customer_email}
      <SingleOrderPayments order={order} />
    </div>
  );
}

export { SingleOrder };
