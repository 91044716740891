import React, { useCallback } from 'react';

import { useSnackbar } from 'notistack';
import { GREY } from '~/configs/colours';

import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { IconButton, InputAdornment, Link, TextField } from '@mui/material';

import { ReadOnlyFieldType } from './types';

export interface TextReadOnlyFieldProps {
  title: string;
  value: string;
  type: ReadOnlyFieldType;
  icon?: React.ReactNode;
  href?: string;
  copyable?: boolean;
  colour?: string;
}

export default function TextReadOnlyField({
  title,
  value,
  type,
  icon,
  href,
  copyable,
  colour,
}: TextReadOnlyFieldProps) {
  const { enqueueSnackbar } = useSnackbar();

  const copyValue = useCallback(() => {
    navigator.clipboard.writeText(value);
    enqueueSnackbar(`Copied ${title} to clipboard`, { variant: 'success', autoHideDuration: 3500 });
  }, [value, enqueueSnackbar, title]);

  return (
    <TextField
      InputProps={{
        readOnly: true,
        startAdornment:
          type === 'link' || icon ? (
            <InputAdornment position="start">
              {icon && icon}
              {type === 'link' && (
                <Link href={href} target="_blank" rel="noopener noreferrer">
                  {value}
                </Link>
              )}
            </InputAdornment>
          ) : undefined,
        endAdornment: copyable ? (
          <InputAdornment position="end">
            <IconButton onClick={copyValue}>
              <ContentCopyOutlinedIcon />
            </IconButton>
          </InputAdornment>
        ) : undefined,
        sx: {
          // remove disabled styling by overriding it and inheriting where needed
          '&.Mui-disabled': {
            WebkitTextFillColor: 'inherit',
            color: 'inherit',
          },
          typography: 'body2',
        },
      }}
      label={title}
      variant="standard"
      // omit the value here if it's a link because the values for links are instead shown in the startAdornment
      value={type !== 'link' ? value : ''}
      disabled // we want it to be disabled because this is a read only field
      multiline
      sx={{
        width: '100%',
        // this component is disabled by default, so we need to override the disabled styling
        '& .MuiInputBase-input.Mui-disabled': {
          color: colour || (type === 'link' ? 'primary.main' : 'text.primary'),
          WebkitTextFillColor: 'inherit',
        },
        '& .MuiInput-underline.Mui-disabled:before': {
          borderBottomStyle: 'solid',
          borderColor: GREY[200],
          opacity: 1,
        },
      }}
    />
  );
}
