import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useParams } from 'react-router';

import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';

import { COUNTRIES_LIST } from '~/components/Common/CustomOffers/constants';

import { getBusiness, patchBusiness } from '~/services/BusinessTraveller/BusinessTravellerService';

interface Params {
  businessId: string;
  tab: string;
}

const emptyBusiness: App.Business = {
  country: '',
  businessIdentifier: '',
  employeeCount: '',
  id: '',
  industry: '',
  name: '',
  phone: '',
  enableMerchantFees: false,
};

const industries = {
  accommodation: 'Accommodation and food services',
  administration: 'Administrative and support services',
  agriculture: 'Agriculture, forestry and fishing',
  arts: 'Arts and recreation services',
  construction: 'Construction',
  education: 'Education and training',
  finance: 'Financial and insurance services',
  health: 'Health care and social assistance',
  manufacturing: 'Manufacturing',
  mining: 'Mining',
  other: 'Other',
  publicadmin: 'Public administration and safety',
  realestate: 'Rental, hiring and real estate services',
  retail: 'Retail or wholesale trade',
  science: 'Professional, scientific and technical services',
  telecommunications: 'Information media and telecommunications',
  transport: 'Transport, postal and warehousing',
  utilities: 'Electricity, gas, water and waste services',
};

const businessIdentifierMap = {
  australian_business_number: 'ABN',
  default: 'ABN',
};

const BUSINESS_TRAVELLER_BUSINESS_EMPLOYEE_COUNT_OPTIONS = [
  ['1-20', '1-20'],
  ['21-50', '21-50'],
  ['51-200', '51-200'],
  ['200+', '200+'],
];

export default function BusinessTab() {
  const [business, setBusiness] = useState<App.Business>(emptyBusiness);
  const [fetchBusinessError, setFetchBusinessError] = useState(false);
  const { businessId } = useParams<Params>();
  const { enqueueSnackbar } = useSnackbar();

  async function fetchBusiness() {
    try {
      const result = await getBusiness(businessId);
      setBusiness(result);
    } catch (e) {
      setFetchBusinessError(true);
      console.error(e);
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;

    if (type === 'checkbox') {
      setBusiness((prevState) => ({ ...prevState, [name]: checked }));
    } else {
      setBusiness((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  async function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    try {
      await patchBusiness(businessId, business);
    } catch (e) {
      enqueueSnackbar(`Error patching business [${JSON.stringify(e, null, 2)}]`, {
        variant: 'error',
      });
    } finally {
      enqueueSnackbar(`Business updated successfully`, {
        variant: 'success',
      });
    }
  }

  useEffect(() => {
    fetchBusiness();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ maxWidth: 800 }}>
      <form onSubmit={onSubmit}>
        <Stack gap={2}>
          {fetchBusinessError && <Alert severity="error">Error fetching business with id {businessId}</Alert>}

          <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
            Business Information
          </Typography>

          <Grid2 container spacing={3}>
            <Grid2 xs={12} md={6}>
              <Typography variant="subtitle2" gutterBottom>
                Business Name
              </Typography>
              <FormControl fullWidth>
                <OutlinedInput
                  id="business_name"
                  name="name"
                  onChange={handleChange}
                  placeholder="Enter name of business"
                  value={business.name}
                  sx={{ mt: 1 }}
                />
              </FormControl>
            </Grid2>

            <Grid2 xs={12} md={6}>
              <Typography variant="subtitle2" gutterBottom>
                Phone Number
              </Typography>
              <FormControl fullWidth>
                <OutlinedInput
                  id="business_phone"
                  name="phone"
                  onChange={handleChange}
                  placeholder="Enter phone number"
                  value={business.phone}
                  sx={{ mt: 1 }}
                />
              </FormControl>
            </Grid2>

            <Grid2 xs={12} md={6}>
              <Typography variant="subtitle2" gutterBottom>
                Country
              </Typography>
              <FormControl fullWidth sx={{ mt: 1 }}>
                <Select
                  value={business.country}
                  id="business_country"
                  name="country"
                  onChange={handleChange}
                  displayEmpty
                >
                  {COUNTRIES_LIST.map((country) => (
                    <MenuItem value={country} key={country}>
                      {country}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid2>

            <Grid2 xs={12} md={6}>
              <Typography variant="subtitle2" gutterBottom>
                Industry
              </Typography>
              <FormControl fullWidth sx={{ mt: 1 }}>
                <Select
                  value={business.industry}
                  id="business_industry"
                  name="industry"
                  onChange={handleChange}
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    <em>Select an industry</em>
                  </MenuItem>
                  {Object.keys(industries).map((industry) => (
                    <MenuItem key={industry} value={industry}>
                      {industries[industry]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid2>

            <Grid2 xs={12} md={6}>
              <Typography variant="subtitle2" gutterBottom>
                Number of Employees
              </Typography>
              <FormControl fullWidth sx={{ mt: 1 }}>
                <Select
                  value={business.employeeCount}
                  id="business_employee_count"
                  name="employeeCount"
                  onChange={handleChange}
                  displayEmpty
                >
                  {BUSINESS_TRAVELLER_BUSINESS_EMPLOYEE_COUNT_OPTIONS.map(([key, label]) => (
                    <MenuItem value={key} key={key}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid2>

            <Grid2 xs={12} md={6}>
              <Typography variant="subtitle2" gutterBottom>
                {businessIdentifierMap[business.businessIdentifierType || 'default']}
              </Typography>
              <FormControl fullWidth>
                <OutlinedInput
                  id="business_identifier"
                  name="businessIdentifier"
                  onChange={handleChange}
                  placeholder={`Enter ${businessIdentifierMap[business.businessIdentifierType || 'default']}`}
                  value={business.businessIdentifier}
                  sx={{ mt: 1 }}
                />
              </FormControl>
            </Grid2>

            <Grid2 xs={12}>
              <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
                Referral Information
              </Typography>
            </Grid2>

            <Grid2 xs={12} md={6}>
              <Typography variant="subtitle2" gutterBottom>
                Referral Code
              </Typography>
              <Typography>{business.referralCode || 'Not available'}</Typography>
            </Grid2>

            <Grid2 xs={12} md={6}>
              <Typography variant="subtitle2" gutterBottom>
                Referral Email
              </Typography>
              <Typography> {business.referralEmail || 'Not available'}</Typography>
            </Grid2>

            <Grid2 xs={12}>
              <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
                Settings
              </Typography>
            </Grid2>

            <Grid2 xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'center', p: 2, bgcolor: 'grey.50', borderRadius: 1 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="enable_merchant_fees"
                      name="enableMerchantFees"
                      checked={business.enableMerchantFees}
                      onChange={handleChange}
                    />
                  }
                  label="Enable merchant fees"
                />
              </Box>
            </Grid2>
          </Grid2>

          <Box sx={{ mt: 3 }}>
            <Button type="submit" variant="contained" size="large" sx={{ px: 4 }}>
              Save Changes
            </Button>
          </Box>
        </Stack>
      </form>
    </Box>
  );
}
