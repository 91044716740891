import React, { MouseEventHandler, forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useSelector } from 'react-redux';
import requestGetHeroPlannerScheduleEmailContent from '~/queries/customerCommunication/requestGetHeroPlannerScheduleEmailContent';

import { Alert, AlertTitle, Box, Button, Stack, TextField } from '@mui/material';

import { RequestStatus } from '~/consts/requestConstants';

import { EmptyArray } from '~/utils/arrayUtils';
import { isRequestPending, isRequestRejected } from '~/utils/requestUtils';
import { getCharacterCount, getWordCount } from '~/utils/stringUtils';

import ScheduleEmailPreHeaderInput from './Inputs/ScheduleEmailPreHeaderInput';

export enum SCHEDULE_EMAIL_CONTENT_INPUT_NAMES {
  SUBJECT_LINE = 'schedule_email_content_subject_line',
  PRE_HEADER = 'schedule_email_content_pre_header',
  BANNER_IMAGE_ID = 'schedule_email_content_banner_image_id',
}

export function parseScheduleEmailContentFormData(
  formData: FormData,
): Pick<CustomerCommunication.UnsavedHeroPlannerSchedule, 'emailSubjectLine' | 'emailPreHeader'> {
  return {
    emailSubjectLine: String(formData.get(SCHEDULE_EMAIL_CONTENT_INPUT_NAMES.SUBJECT_LINE)),
    emailPreHeader: String(formData.get(SCHEDULE_EMAIL_CONTENT_INPUT_NAMES.PRE_HEADER)),
  };
}

interface Props {
  preHeaderDefaultValue: string;
  subjectLineDefaultValue: string;
  countryGroupId: string;
  countryId: string;
  offerIds: Array<string>;
  showPrefillButton?: boolean;
}

const ScheduleEmailContentForm = forwardRef<HTMLFormElement, Props>((props, ref) => {
  const {
    preHeaderDefaultValue,
    subjectLineDefaultValue,
    countryGroupId,
    countryId,
    offerIds,
    showPrefillButton = true,
  } = props;
  const brand = useSelector((state: App.State) => state.tenant.brand);

  const subjectLineInputRef = useRef<HTMLInputElement>(null);
  const preHeaderInputRef = useRef<HTMLInputElement>(null);
  const [subjectLine, setSubjectLine] = useState(subjectLineDefaultValue);

  const [emailContentRequest, setEmailContentRequest] = useState<
    Utils.RequestState<CustomerCommunication.HeroPlannerScheduleEmailContent, string>
  >({
    status: RequestStatus.INITIAL,
  });

  const params = useMemo(() => {
    if (brand && countryGroupId && countryId && offerIds?.length) {
      return [brand, countryGroupId, countryId, offerIds] as const;
    }
  }, [brand, countryGroupId, countryId, offerIds]);

  const handlePrefillClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    async (event) => {
      event.preventDefault();
      setEmailContentRequest({
        status: RequestStatus.PENDING,
        params,
      });

      try {
        const result = await requestGetHeroPlannerScheduleEmailContent(...params);
        setEmailContentRequest({
          status: RequestStatus.FULFILLED,
          params,
          result,
        });
      } catch (error) {
        setEmailContentRequest({
          status: RequestStatus.REJECTED,
          params,
          error,
        });
      }
    },
    [params],
  );

  const preHeaderOptions = emailContentRequest.result?.preHeaders ?? (EmptyArray as Array<string>);

  useEffect(() => {
    if (emailContentRequest.result?.subjectLine) {
      setSubjectLine(emailContentRequest.result.subjectLine);
    }
  }, [emailContentRequest.result]);

  return (
    <Stack component="form" ref={ref} direction="column" gap={2}>
      {showPrefillButton && (
        <Box>
          <Button variant="outlined" color="secondary" disabled={!params} onClick={handlePrefillClick}>
            Prefill subject line & pre-header
          </Button>
          {isRequestRejected(emailContentRequest) && (
            <Alert severity="error" sx={{ mt: 1 }}>
              <AlertTitle>Problem with prefilling!</AlertTitle>
              <pre>{emailContentRequest.error}</pre>
            </Alert>
          )}
        </Box>
      )}
      <TextField
        inputRef={subjectLineInputRef}
        name={SCHEDULE_EMAIL_CONTENT_INPUT_NAMES.SUBJECT_LINE}
        value={subjectLine}
        onChange={(e) => setSubjectLine(e.target.value)}
        type="text"
        label="Subject Line"
        variant="standard"
        multiline
        required
        InputLabelProps={{ shrink: true }}
        helperText={`Chars: ${getCharacterCount(subjectLine)} Words: ${getWordCount(subjectLine)}`}
      />
      <ScheduleEmailPreHeaderInput
        ref={preHeaderInputRef}
        name={SCHEDULE_EMAIL_CONTENT_INPUT_NAMES.PRE_HEADER}
        defaultValue={preHeaderDefaultValue}
        options={preHeaderOptions}
        loading={isRequestPending(emailContentRequest)}
      />
    </Stack>
  );
});

ScheduleEmailContentForm.displayName = 'ScheduleContentForm';

export default ScheduleEmailContentForm;
