import React, { Component } from 'react';

import { IChangeEvent } from '@rjsf/core';
import Form from '@rjsf/mui';
import { RJSFSchema, StrictRJSFSchema, UiSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import deepEqual from 'deep-equal';
import type { JSONSchema7Definition } from 'json-schema';
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';

import { Alert } from '@mui/material';

import * as libRegions from '@luxuryescapes/lib-regions';
import {
  REGIONS_COUNTRY_CODES,
  isPhoneNumberValidForRegion,
} from '@luxuryescapes/lib-regions/lib/phoneNumberValidation';

import { ROLE_ICS_STORE_TEAM } from '~/consts/roles';

import UsersService from '~/services/UsersService';

const defaultFieldsOrder = ['givenName', 'surname', 'email', 'email_verified', 'dob', 'customer_support_code', '*'];

const DEFAULT_PHONE_FORMAT_HELP_MESSAGE =
  'Please enter the local number only, without the country code or any prefixes (e.g. leading 0).';

const initUiSchema: UiSchema = {
  'ui:order': [...defaultFieldsOrder, 'resetPassword'],
  email: { 'ui:widget': 'email' },
  email_verified: { 'ui:readonly': true },
  dob: { 'ui:widget': 'date' },
  customer_support_code: { 'ui:readonly': true, 'ui:order': 4 },
  recently_used_airport_code: { 'ui:emptyValue': null },
  phone: {
    'ui:help': DEFAULT_PHONE_FORMAT_HELP_MESSAGE,
  },
};

const PHONE_FORMAT_HELP_MESSAGE_BY_REGION = {
  AU: 'Please enter the Australian local number only, without the country code (+61) or any prefixes (e.g. leading 0). The correct format is 4XXXXXXXX',
  GB: 'Please enter the GB local number only, without the country code (+44) or any prefixes (e.g. leading 0). The correct format is 7XXXXXXXXX',
  US: 'Please enter the US local number only, without the country code (+1). The correct format is XXXXXXXXXX',
  NZ: 'Please enter the NZ local number only, without the country code (+64), or any prefixes (e.g. leading 0). The correct format is 2XXXXXXXX (2 followed by 8 digits) or 2XXXXXXXXX (2 followed by 9 digits)',
  SG: 'Please enter the Singapore local number only, without the country code (+65). The correct format is 8XXXXXXX or 9XXXXXXX (8 digits total)',
};

type Props = {
  user: App.User;
  schema: RJSFSchema;
  brand: App.Brands;
  update?: (user: App.User) => void;
};

type FormData = Omit<App.User, 'gdpr'> & {
  resetPassword?: boolean;
  password?: string;
  vendors?: Array<string>;
  gdpr: boolean | string;
};

type State = {
  formData: FormData;
  schema: RJSFSchema;
  alertSuccess: boolean;
  alertError: boolean;
  errorMessage: string;
  partnershipsAlertError: boolean;
  partnershipsErrorMessage: string;
  flightEnabled: boolean;
  referralProgramEnabled: boolean;
  shadowBanUser: boolean;
};

export default class UpdateUser extends Component<Props, State> {
  static contextTypes: { user: PropTypes.Requireable<unknown> };
  submitButton: HTMLButtonElement;

  constructor(props) {
    super(props);

    const formData: FormData = cloneDeep(this.props.user);
    const schema = cloneDeep(this.props.schema);

    const allRegionsForBrand = libRegions.getRegions(this.props.brand);

    const phonePrefixSchemas: Array<JSONSchema7Definition> = Object.keys(REGIONS_COUNTRY_CODES).map((regionCode) => {
      const region = REGIONS_COUNTRY_CODES[regionCode];
      const internationalPhonePrefix = region.countryCode;
      const regionName = region.countryName;

      return {
        type: 'string',
        title: `${regionName} - ${regionCode} (+${internationalPhonePrefix})`,
        enum: [
          // what is this and why?
          // CA and US have the same international phone prefix of +1
          // if we give them the same value here, every time US is selected in the dropdown field for
          // phone prefix, it will select CA instead in the dropdown, which is confusing for the user
          //
          // so applying this transformation ensures that we can distinguish between CA and US, and that
          // the correct option is selected in the dropdown
          region === 'CA' || region.code === 'US'
            ? `${internationalPhonePrefix}_${regionCode}`
            : internationalPhonePrefix,
        ],

        // When CA or US is selected, we only want to capture the international phone prefix
        transform: (value) => value.split('_')[0],
      };
    });

    schema.properties.phone_prefix = {
      ...(schema.properties.phone_prefix as object),
      oneOf: phonePrefixSchemas,
    };

    // Set a default international phone prefix if not found
    // It might not be found initially if the phone prefix is a pre-existing invalid phone prefix
    const phonePrefixRegion = Object.keys(REGIONS_COUNTRY_CODES).find(
      (regionCode) => REGIONS_COUNTRY_CODES[regionCode].countryCode === formData.phone_prefix,
    );
    if (formData.phone_prefix !== '1_US' && formData.phone_prefix !== '1_CA' && !phonePrefixRegion) {
      formData.phone_prefix = '61';
    }

    this.state = {
      schema,
      formData,
      alertSuccess: false,
      alertError: !formData.country_code,
      errorMessage: !formData.country_code ? 'Country is a required field!' : '',
      partnershipsAlertError: false,
      partnershipsErrorMessage: '',
      flightEnabled: formData.flights_enabled,
      referralProgramEnabled: formData.referral_program_enabled,
      shadowBanUser: formData.shadow_ban_user,
    };

    this.customValidate = this.customValidate.bind(this);
  }

  customValidate(formData, errors, uiSchema) {
    const phonePrefix = formData.phone_prefix.split('_')[0];
    const regionCode = Object.keys(REGIONS_COUNTRY_CODES).find(
      (regionCode) => REGIONS_COUNTRY_CODES[regionCode].countryCode === phonePrefix,
    );

    const isPhoneNumberValid = isPhoneNumberValidForRegion({
      phoneNumber: formData.phone,
      regionCode,
    });

    if (!isPhoneNumberValid) {
      errors.phone.addError('Phone number is not valid for the selected region/phone prefix');
    }

    return errors;
  }

  getRegionCodeFromPhonePrefix(phonePrefix: string) {
    const phonePrefixParts = phonePrefix.split('_');
    let selectedRegionCode;

    if (phonePrefixParts.length > 1) {
      // e.g. in the format 1_US or 1_CA, we want to get the 'US' or 'CA'
      selectedRegionCode = phonePrefixParts[1];
    } else {
      const selectedRegion = Object.keys(REGIONS_COUNTRY_CODES).find(
        (regionCode) => REGIONS_COUNTRY_CODES[regionCode].countryCode === phonePrefixParts[0],
      );

      selectedRegionCode = selectedRegion;
    }

    return selectedRegionCode;
  }

  handleStateData = () => {
    const schema = { ...this.state.schema };
    const formData = { ...this.state.formData };
    if (this.context.user.roles.includes(ROLE_ICS_STORE_TEAM)) {
      delete schema.properties.shadow_ban_user;
    }
    let uiSchema: UiSchema;

    //reset password
    schema.properties = {
      ...schema.properties,
      email_verified: { type: 'boolean', title: 'Email Verified' },
      resetPassword: {
        type: 'boolean',
        title: 'Reset Password',
      },
      customer_support_code: {
        type: 'string',
        title: 'Customer Support Code',
      },
      roles: {
        ...(schema.properties.roles as object),
        items: {
          ...((schema.properties.roles as StrictRJSFSchema).items as object),
          title: 'Role',
        },
      },
      recently_used_airport_code: {
        ...(schema.properties.recently_used_airport_code as object),
        type: ['string', 'null'],
      },
      postcode: {
        ...(schema.properties.postcode as object),
        type: ['string', 'null'],
      },
      phone_prefix: {
        ...(schema.properties.phone_prefix as object),
        type: ['string', 'null'],
      },
    };

    if (formData.resetPassword) {
      schema.properties = {
        ...schema.properties,
        password: { type: 'string', title: 'Password' },
      };
      uiSchema = {
        'ui:order': [...defaultFieldsOrder, 'resetPassword', 'password'],
      };
    } else {
      delete formData.password;
      delete schema.properties.password;
      uiSchema = { ...initUiSchema };
    }

    //partnerships
    if (window.configs.KRIS_FLYER_ENABLED !== 'true') {
      delete (schema.properties.partnerships as StrictRJSFSchema).properties.kfp;
    }

    if (window.configs.QANTAS_EARN_ORDER_BASED_ENABLED === 'true') {
      delete (schema.properties.partnerships as StrictRJSFSchema).properties.qff;
    }

    //vendor
    if (!formData.roles.includes('vendor-user')) {
      schema.properties = { ...schema.properties };
      if (formData.vendors) {
        delete formData.vendors;
      }
      if (schema.properties.vendors) {
        delete schema.properties.vendors;
      }
    } else {
      schema.properties = {
        ...schema.properties,
        vendors: {
          type: 'array',
          title: 'Vendor ID',
          items: { type: 'string' },
        },
      };

      uiSchema = { ...initUiSchema };
    }

    if (formData.roles.length === 0 && formData.status === 'ENABLED') {
      formData.roles = ['customer'];
    }

    if (formData.gdpr) {
      formData.gdpr = true;
    } else {
      delete formData.gdpr;
    }

    if (!formData.phone) {
      delete formData.phone;
    }

    if (!formData.country_code) {
      delete formData.country_code;
    }

    if (!formData.dob) {
      delete formData.dob;
    }

    if (this.context.user.roles.includes(ROLE_ICS_STORE_TEAM)) {
      uiSchema.dob = { 'ui:widget': 'hidden' };
    }

    uiSchema.banned_led_user = { 'ui:widget': 'hidden' };

    const selectedRegionCode = this.getRegionCodeFromPhonePrefix(formData.phone_prefix);
    const phoneNumberHelpMessage =
      PHONE_FORMAT_HELP_MESSAGE_BY_REGION[selectedRegionCode] || DEFAULT_PHONE_FORMAT_HELP_MESSAGE;
    uiSchema.phone = {
      'ui:help': phoneNumberHelpMessage,
    };

    return { schema, formData, uiSchema };
  };

  handleChange = (data: IChangeEvent<FormData>) => {
    const { formData } = data;

    this.setState({
      formData,
      alertSuccess: false,
      alertError: false,
      errorMessage: '',
      partnershipsAlertError: false,
      partnershipsErrorMessage: '',
      flightEnabled: formData.flights_enabled,
      referralProgramEnabled: formData.referral_program_enabled,
      shadowBanUser: formData.shadow_ban_user,
    });
  };

  handleSubmit = async (event: IChangeEvent<FormData>) => {
    const { formData } = event;
    delete formData.flights_enabled;
    delete formData.referral_program_enabled;
    delete formData.shadow_ban_user;
    const shouldUpdateShadowBanUser = typeof this.state.shadowBanUser === 'boolean';

    if (!formData.dob) {
      formData.dob = null;
    }

    if (formData.phone) {
      // replace all non-numeric characters with an empty string
      formData.phone = formData.phone.replace(/[^0-9]/g, '');
    }

    let user = formData as App.User;
    let userSaved = false;
    try {
      user = await UsersService.updateUser(formData, this.props.brand);

      // todo: check if we can toggle all flags in parallel
      //       potential can lead to lock
      const toggleFlight = await UsersService.toggle({
        id_member: formData.id_member,
        toggle_name: 'flights_enabled',
        toggle_value: this.state.flightEnabled,
      });

      const toggleReferralProgram = await UsersService.toggle({
        id_member: formData.id_member,
        toggle_name: 'referral_program_enabled',
        toggle_value: this.state.referralProgramEnabled,
      });

      let toggleShadowBanUser;
      if (shouldUpdateShadowBanUser) {
        toggleShadowBanUser = await UsersService.toggle({
          id_member: formData.id_member,
          toggle_name: 'shadow_ban_user',
          toggle_value: this.state.shadowBanUser,
        });
      }
      if (
        user &&
        toggleFlight &&
        toggleReferralProgram &&
        (!shouldUpdateShadowBanUser || (shouldUpdateShadowBanUser && toggleShadowBanUser))
      ) {
        userSaved = true;
      }
    } catch (err) {
      const errorMessage = err.errors && err.errors.length ? err.errors[0] : err.message;
      this.setState({
        alertSuccess: false,
        alertError: true,
        errorMessage: errorMessage,
      });
    }

    if (userSaved && !deepEqual(formData.partnerships, this.props.user.partnerships)) {
      try {
        await UsersService.updatePartnershipsDetails(formData.id_member, formData.partnerships);
      } catch (err) {
        const errorMessage = err.errors && err.errors.length ? err.errors[0] : err.message;
        this.setState({
          partnershipsAlertError: true,
          partnershipsErrorMessage: errorMessage,
        });
      }
    }

    if (userSaved) {
      user = await UsersService.getUser(formData.id_member, {
        brand: this.props.brand,
      });

      this.setState(
        {
          formData: cloneDeep(user),
          alertSuccess: true,
          alertError: false,
          errorMessage: '',
        },
        () => {
          if (this.props?.update) {
            this.props.update(user);
          }
          //this.props.update causes handleChange to fire, which resets alertSuccess
          this.setState({
            alertSuccess: true,
          });
        },
      );
    }
  };

  nativeCurrencyCode() {
    const region = libRegions.getRegionByCode(this.state.formData.country_code);
    return region ? region.currencyCode : 'Currency is not available as country is not set';
  }

  render() {
    const alertError = (
      <Alert severity="error">
        <strong>Error!</strong> Unable to save user. {this.state.errorMessage}.
      </Alert>
    );

    const alertSuccess = (
      <Alert severity="success">
        <strong>Success!</strong> User Information Saved
      </Alert>
    );

    const partnershipsAlertError = (
      <Alert severity="error">
        <strong>Error!</strong> Unable to save partnership details. {this.state.partnershipsErrorMessage}.
      </Alert>
    );

    const { schema, formData, uiSchema } = this.handleStateData();

    return (
      <div>
        {this.state.alertError && alertError}
        {this.state.alertSuccess && alertSuccess}
        {this.state.partnershipsAlertError && partnershipsAlertError}
        <Form
          formData={formData}
          schema={schema}
          uiSchema={uiSchema}
          onSubmit={this.handleSubmit}
          onChange={this.handleChange}
          validator={validator}
          customValidate={this.customValidate}
        >
          <dl>
            <dt className="h6 text-muted text-uppercase">Native Currency Code</dt>
            <dd>{this.nativeCurrencyCode()}</dd>
          </dl>

          <button
            ref={(btn) => {
              this.submitButton = btn;
            }}
            className="hidden"
          />
        </Form>
        {this.state.alertError && alertError}
        {this.state.alertSuccess && alertSuccess}
        {this.state.partnershipsAlertError && partnershipsAlertError}
      </div>
    );
  }
}

UpdateUser.contextTypes = {
  user: PropTypes.object,
};
