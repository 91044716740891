import React, { useEffect, useRef, useState } from 'react';

import { useSnackbar } from 'notistack';
import fileDownload from 'react-file-download';
import { Helmet } from 'react-helmet';

import { Button, Container } from '@mui/material';

import { htmlToPdf } from '~/services/PDFService';
import { getSentEmail } from '~/services/SailthruService';

import Spinner from '../Common/Spinner';

const RenderEmailPage: React.FC = () => {
  const [htmlContent, setHtmlContent] = useState<string>('');
  const [id, setId] = useState('');
  const [isConvertingHtmlToPdf, setIsConvertingHtmlToPdf] = useState(false);
  const contentRef = useRef(null);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const id = searchParams.get('id');
    if (!id) {
      return;
    }

    setId(id);
    getSentEmail({ id }).then((data) => setHtmlContent(data));
  }, []);

  async function handleHtmlToPdf(html: string) {
    try {
      setIsConvertingHtmlToPdf(true);
      const pdf = await htmlToPdf(html);
      await fileDownload(pdf, `${id}.pdf`);
    } catch (err) {
      setIsConvertingHtmlToPdf(false);
      enqueueSnackbar('Unable to convert to pdf', { variant: 'error' });
    } finally {
      setIsConvertingHtmlToPdf(false);
    }
  }

  if (isConvertingHtmlToPdf) {
    return <Spinner />;
  }

  const title = `View Email`;
  return (
    <Container ref={contentRef}>
      <Container maxWidth="xl">
        <Button
          onClick={() =>
            handleHtmlToPdf(`
            <div style="max-width: 75%; margin: 0 auto;">
              ${contentRef.current.innerHTML}
            </div>`)
          }
          disabled={!htmlContent || isConvertingHtmlToPdf}
        >
          Download as PDF
        </Button>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
      </Container>
    </Container>
  );
};

export default RenderEmailPage;
