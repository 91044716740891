import React from 'react';

import currencyFormatter from 'currency-formatter';

import { CreditCard } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import PermissionedComponent from '~/components/Common/PermissionedComponent';

import { ROLE_ADMIN_USER, ROLE_EMPLOYEE_USER, ROLE_ICS_STORE_TEAM } from '~/consts/roles';

import { formatDateSlashes } from '~/services/TimeService';

import { getOrdinalText } from '~/utils/stringUtils';

import Log from './OrderItem/Log';

function OrderServiceFeeItem({ order, paymentScheduleDetails, showRefundModal, refunds }) {
  const formatDate = (cell) => {
    const date = new Date(cell);
    return formatDateSlashes(date);
  };

  const serviceFeePaidUpfront =
    order.payments[0]?.depositDetails?.deposit_metadata.serviceFeePaidUpfront ||
    order.payments[0]?.instalmentDetails?.metadata.serviceFeePaidUpfront;

  const getServiceFee = (serviceFeeItems) => {
    const serviceFee = serviceFeeItems[0];
    let instalmentDueDate = null;
    let serviceFeeDueDate = null;

    const scheduledInstalmentDueDates = order.payments[0]?.instalmentDetails?.scheduled_instalment_dates;
    if (scheduledInstalmentDueDates) {
      instalmentDueDate = scheduledInstalmentDueDates[scheduledInstalmentDueDates.length - 1];
    }

    const paymentScheduleChargeWithServiceFee = paymentScheduleDetails.result?.payment_schedules.find((charge) =>
      charge.items.find((item) => item.id_items === serviceFee.id),
    );
    if (paymentScheduleChargeWithServiceFee) {
      serviceFeeDueDate = paymentScheduleChargeWithServiceFee?.due_date;
    }

    return {
      type: serviceFee.type,
      itemId: serviceFee.id,
      total: serviceFee.total,
      status: serviceFee.status,
      percentage: serviceFee.service_fee_percentage,
      dueDate: serviceFeePaidUpfront
        ? order.payments[0]?.depositDetails?.deposit_paid_date ||
          order.payments[0]?.instalmentDetails?.initial_payment_date
        : order.payments[0]?.depositDetails?.balance_due_date || instalmentDueDate || serviceFeeDueDate,
    };
  };

  const serviceFee = getServiceFee(order?.service_fee_items);

  const isDepositBalancePaid = [
    'full_balance_refunded',
    'due_balance_manual_debit_taken',
    'due_balance_auto_debit_taken',
  ].includes(order.payments[0]?.depositDetails?.deposit_status);

  const isInstalmentsFullyPaid = ['payment_taken_complete', 'payment_manual_debit_taken_complete'].includes(
    order.payments[0]?.instalmentDetails?.instalment_status,
  );

  const paymentScheduleChargeWithServiceFee = paymentScheduleDetails.result?.payment_schedules
    .map((charge, index) => {
      return { charge_number: index + 1, ...charge };
    })
    .find((charge) => charge.items.find((item) => item.id_items === serviceFee.itemId));
  const isPaymentScheduleServiceFeePaid = paymentScheduleChargeWithServiceFee?.status === 'paid';

  const isServiceFeeFullyPaid = isDepositBalancePaid || isInstalmentsFullyPaid || isPaymentScheduleServiceFeePaid;

  return (
    <Accordion sx={{ mt: 2 }} id="service-fee">
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: '100%' }}>
          <Box display="flex" alignItems="center">
            <CreditCard sx={{ mr: 1 }} />
            <Typography variant="h6">Service Fee</Typography>
          </Box>
          <PermissionedComponent requiredRoles={[ROLE_ADMIN_USER, ROLE_EMPLOYEE_USER, ROLE_ICS_STORE_TEAM]}>
            {serviceFee.status !== 'cancelled' &&
              (isServiceFeeFullyPaid || serviceFeePaidUpfront) &&
              !paymentScheduleChargeWithServiceFee && (
                <Button
                  onClick={(event) => {
                    event.stopPropagation();
                    showRefundModal({
                      itemId: serviceFee.itemId,
                      itemType: serviceFee.type,
                    });
                  }}
                  variant="contained"
                  color="error"
                  size="small"
                >
                  Issue refund
                </Button>
              )}
          </PermissionedComponent>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={8}>
          <Grid xs={6}>
            <Typography variant="h5">Service Fee</Typography>
            <Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography>Service fee rate</Typography>
                <Typography fontWeight="bold">
                  {!serviceFee.percentage || serviceFee.percentage === 0 ? 'fixed_amount' : `${serviceFee.percentage}%`}
                </Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography>Fee Amount</Typography>
                <Typography fontWeight="bold">
                  {currencyFormatter.format(serviceFee.total, {
                    code: order.currency_code,
                    precision: 2,
                  })}
                </Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography>Due date</Typography>
                <Typography fontWeight="bold">{formatDate(serviceFee.dueDate)}</Typography>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Typography>Status</Typography>
                <Typography fontWeight="bold">
                  {!isServiceFeeFullyPaid && serviceFee.status === 'completed' && !serviceFeePaidUpfront
                    ? 'upcoming_payment'
                    : serviceFee.status}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid xs={6}>
            <Typography variant="h5">Service fee explanation</Typography>
            {!paymentScheduleChargeWithServiceFee && (
              <Typography>
                A service fee is payable on this booking as it was made with a deposit. The fee{' '}
                {serviceFeePaidUpfront
                  ? 'was paid by the customer when they paid their initial deposit '
                  : 'will be paid when the customer is charged the remaining balance '}
                and is calculated as a percentage of the remaining balance payable.
              </Typography>
            )}

            {paymentScheduleChargeWithServiceFee && (
              <Typography>
                A service fee is payable on this booking as it was made with a payment schedule. A{' '}
                {currencyFormatter.format(serviceFee.total, {
                  code: order.currency_code,
                  precision: 2,
                })}{' '}
                fee will be paid on the {getOrdinalText(paymentScheduleChargeWithServiceFee.charge_number)} payment.
              </Typography>
            )}
          </Grid>
        </Grid>
        <Log itemId={serviceFee.itemId} orderId={order.id_orders} />
      </AccordionDetails>
    </Accordion>
  );
}

export default OrderServiceFeeItem;
