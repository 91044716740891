import React, { useCallback, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';

import { Alert, Stack } from '@mui/material';
import type { GridFilterItem, GridFilterModel, GridSortModel } from '@mui/x-data-grid';
import { GridSortItem } from '@mui/x-data-grid';

import { FlightDealsForm } from '~/components/Flights/FlightDeals/FlightDealsForm';

import { FLIGHTS_DEALS_TABLE_SIZE } from '~/consts/flight';

import { CreateFlightDealData, createFlightDeal, deleteFlightDeal } from '~/services/FlightsService';

import { FLIGHT_DEALS_LIST_ATTRIBUTES } from '../constants';
import { useFlightDeals } from '../hooks/useFlightDeals';

import { FlightDealsTable } from './FlightDealsTable';

export function FlightDeals() {
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState(undefined);
  const [sort, setSort] = useState(undefined);
  const [creatingError, setCreatingError] = useState<string | undefined>();
  const [creating, setCreating] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const options = useMemo(() => {
    const filterValues = getFilterValues(filters);
    const sortValues = getSortValues(sort);

    return {
      page: page + 1,
      pageSize: FLIGHTS_DEALS_TABLE_SIZE,
      attributes: FLIGHT_DEALS_LIST_ATTRIBUTES,
      ...(filterValues ? { filter: filterValues } : {}),
      ...(sortValues ? { orderBy: sortValues } : {}),
    };
  }, [page, filters, sort]);

  const { data, error, loading, revalidate } = useFlightDeals(options);

  const handleCreate = useCallback(
    async (deal: CreateFlightDealData) => {
      setCreatingError(undefined);
      setCreating(true);

      try {
        const { result } = await createFlightDeal(deal);
        history.push(`/flights/flight-deal/${result.id}/edit`);
        enqueueSnackbar('Deal was created', { variant: 'success' });
      } catch {
        setCreatingError('Something went wrong, please try again');
      }

      setCreating(false);
    },
    [enqueueSnackbar, history],
  );

  const handleDelete = useCallback(
    async (id: string) => {
      try {
        await deleteFlightDeal(id);
        revalidate();
        enqueueSnackbar('Deal was deleted', { variant: 'success' });
      } catch {
        enqueueSnackbar('Something went wrong, please try again', { variant: 'error' });
      }
    },
    [enqueueSnackbar, revalidate],
  );

  const onPageChange = useCallback(
    (page: number) => {
      setPage(page);
    },
    [setPage],
  );

  return (
    <Stack gap={6}>
      <FlightDealsForm loading={creating} error={creatingError} onSubmit={handleCreate} />

      {error ? (
        <Alert severity="error">Something went wrong</Alert>
      ) : (
        <FlightDealsTable
          pageSize={FLIGHTS_DEALS_TABLE_SIZE}
          isLoading={loading}
          onPageChange={onPageChange}
          page={page}
          records={data?.rows}
          total={data?.count}
          onDelete={handleDelete}
          onFilterChange={setFilters}
          onSortChange={setSort}
          activeSorts={sort}
          activeFilters={filters}
        />
      )}
    </Stack>
  );
}

export default FlightDeals;

const columnMapper = {
  sales_period: 'sales_start_date',
  travel_period: 'travel_start_date',
};

function getFilterValues(filterModel?: GridFilterModel) {
  const item: GridFilterItem | undefined = filterModel?.items[0];

  if (item === undefined || typeof item.field !== 'string') {
    return;
  }

  if (typeof item.value === 'string') {
    return { column: item.field, value: item.value };
  }

  const value = item.value instanceof Date ? item.value.toISOString() : item.value;

  if (item.field === 'sales_period') {
    return { column: item.operator === 'onOrAfter' ? 'sales_start_date' : 'sales_end_date', value };
  }

  if (item.field === 'travel_period' && item.operator === 'onOrAfter') {
    return { column: item.operator === 'onOrAfter' ? 'travel_start_date' : 'travel_end_date', value };
  }
}

function getSortValues(sortModel: GridSortModel = []) {
  const item: GridSortItem = sortModel[0];

  if (!item || typeof item.field !== 'string') {
    return;
  }

  return {
    column: columnMapper[item.field] ?? item.field,
    sort: item.sort,
  };
}
