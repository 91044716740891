import { FETCH_BEDBANK_RESERVATION, FETCH_BEDBANK_RESERVATION_EMPLOYEE_VIEW } from '~/actions/bedbank';

const initialState: App.BedbankReservationState = {
  adminView: {},
  employeeView: {},
};

export default function (state = initialState, action): App.BedbankReservationState {
  switch (action.type) {
    case FETCH_BEDBANK_RESERVATION: {
      return {
        ...state,
        adminView: {
          ...state.adminView,
          [action.reservation.id]: action.reservation,
        },
      };
    }
    case FETCH_BEDBANK_RESERVATION_EMPLOYEE_VIEW: {
      return {
        ...state,
        employeeView: {
          ...state.employeeView,
          [action.reservationEmployeeView.id]: action.reservationEmployeeView,
        },
      };
    }
    default:
      return state;
  }
}
