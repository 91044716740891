import React, { useCallback, useState } from 'react';

import ListAltIcon from '@mui/icons-material/ListAlt';
import { Alert, Box, IconButton, Link, Stack, Tooltip, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';

import InternalPropertyLink from '~/components/Accommodation/Pages/Properties/components/InternalPropertyLink';
import MappingLogModal from '~/components/Accommodation/Pages/Properties/components/MappingLogModal';
import AccommodationVerifyMapping from '~/components/Accommodation/components/AccommodationVerifyMapping/AccommodationVerifyMapping';
import { useAccommodationServiceFetch } from '~/components/Accommodation/hooks/useAccommodationServiceFetch';

import useToggleState from '~/hooks/useToggleState';

import { MappedInternalProperty, getPotentialMappings } from '~/services/AccommodationService';

import { AccommodationPropertiesSearchInput } from './AccommodationPropertiesSearchForm';

interface Props {
  searchInput: AccommodationPropertiesSearchInput;
  pageIndex?: number;
  onPageChange: (pageIndex: number) => void;
}

const PAGE_SIZE = 20;

export default function PotentialMappingsResultsTable({ searchInput, pageIndex, onPageChange }: Props) {
  const [logsId, setLogsId] = useState<string | null>(null);

  const {
    isToggled: isMappingLogModalOpen,
    toggleOn: setOpenMappingLogModal,
    toggleOff: setCloseMappingLogModal,
  } = useToggleState(false);

  const showMappingLogs = useCallback(
    async (logsId: string) => {
      setLogsId(logsId);
      setOpenMappingLogModal();
    },
    [setOpenMappingLogModal],
  );

  const getProperties = useCallback(
    () =>
      getPotentialMappings({
        name: searchInput.propertyName,
        id: searchInput.id,
        internalService: searchInput.internalService || undefined,
        page: String((pageIndex ?? 0) + 1),
        limit: String(PAGE_SIZE),
      }),
    [pageIndex, searchInput.id, searchInput.propertyName, searchInput.internalService],
  );

  const { fetchReqState, refetch } = useAccommodationServiceFetch({
    fetchFn: getProperties,
  });

  const columns: Array<GridColDef<MappedInternalProperty>> = [
    {
      field: 'name',
      headerName: 'Accommodation Property Name',
      display: 'flex',
      minWidth: 350,
      flex: 1,
      hideable: false,
      renderCell: (params) => (
        <Link
          href={`/accommodation/properties/${params.row.property.id}`}
          target="_blank"
          className="accommodation-properties-search-result-link"
        >
          {params.row.property.name}
        </Link>
      ),
    },
    {
      field: 'supplier name',
      headerName: 'Supplier Property Name',
      display: 'flex',
      minWidth: 350,
      hideable: true,
      renderCell: (params) => {
        return <Box>{params.row.internalProperty.name}</Box>;
      },
    },
    {
      field: 'source',
      headerName: 'Source',
      display: 'flex',
      minWidth: 100,
      hideable: true,
      renderCell: (params) => {
        return <Box>{params.row.internalProperty.source}</Box>;
      },
    },
    {
      field: 'sourceId',
      headerName: 'Source ID',
      display: 'flex',
      minWidth: 350,
      hideable: true,
      renderCell: (params) => {
        return (
          <InternalPropertyLink
            source={params.row.internalProperty.source}
            sourceId={params.row.internalProperty.sourceId}
          />
        );
      },
    },
    {
      field: 'supplierProperties',
      headerName: 'Supplier - ID',
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        const supplierProperties = params.row.internalProperty.supplierProperties || [];
        return (
          <Box>
            {supplierProperties.map((supplier, index) => (
              <Typography key={index} variant="body2">
                {supplier.supplier} - {supplier.supplierId}
              </Typography>
            ))}
          </Box>
        );
      },
    },
    {
      field: ' ',
      headerName: 'Actions',
      headerAlign: 'center',
      width: 150,
      sortable: false,
      align: 'center',
      display: 'flex',
      renderCell: (params) => {
        return (
          <Stack direction="row" gap={1}>
            <IconButton onClick={() => showMappingLogs(params.row.internalProperty.id)} color="info">
              <Tooltip title="Show mapping logs">
                <ListAltIcon fontSize="medium" />
              </Tooltip>
            </IconButton>
            <AccommodationVerifyMapping
              propertyId={params.row.property.id}
              mappedPropertyId={params.row.id}
              onComplete={refetch}
            />
          </Stack>
        );
      },
    },
  ];

  return (
    <>
      {fetchReqState.status === 'failed' && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {JSON.stringify(fetchReqState.error)}
        </Alert>
      )}
      <DataGrid
        className="potential-mappings-results-table"
        columns={columns}
        rows={fetchReqState.status === 'succeeded' ? fetchReqState.result.mappedProperties : []}
        rowCount={fetchReqState.status === 'succeeded' ? fetchReqState.result.total : undefined}
        getRowHeight={() => 70}
        pageSizeOptions={[PAGE_SIZE]}
        paginationMode="server"
        paginationModel={{ page: pageIndex, pageSize: PAGE_SIZE }}
        onPaginationModelChange={({ page }) => onPageChange(page)}
        loading={fetchReqState.status === 'pending'}
        autoHeight
        disableColumnFilter
        disableDensitySelector
        slots={{
          toolbar: GridToolbar,
        }}
      />
      {isMappingLogModalOpen && (
        <MappingLogModal
          isMappingLogModalOpen={isMappingLogModalOpen}
          setCloseMappingLogModal={setCloseMappingLogModal}
          logsSourceId={logsId}
          type="property"
        />
      )}
    </>
  );
}
