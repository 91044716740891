import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

import { LinearProgress, Link, Paper, Typography } from '@mui/material';
import { DataGrid, GridPaginationModel } from '@mui/x-data-grid';

import PageSubheader from '~/components/Common/Elements/PageSubheader';

import { getOrderItemLogs, getOrdersByIds, getSpecialRequestsOrderItems } from '~/services/OrdersService';
import { formatDateSlashes } from '~/services/TimeService';

import ExpandableText from './ExpandableText';

export default function SpecialRequests() {
  const { enqueueSnackbar } = useSnackbar();
  const brand = useSelector((state: App.State) => state.tenant.brand);

  const [specialRequestsOrderItemsData, setSpecialRequestsOrderItemsData] = useState({
    specialRequestsOrderItems: [],
    total: 0,
  });

  const [loading, setLoading] = useState(false);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: 10,
    page: 0,
  });

  const fetchSpecialRequestsOrderItems = useCallback(
    async (currentPage, perPage) => {
      setLoading(true);
      try {
        const response = await getSpecialRequestsOrderItems(currentPage + 1, perPage);
        const specialRequestsOrderItems = response.result;

        const orderIds = [
          ...new Set<string>(
            specialRequestsOrderItems.map((specialRequestsOrderItem) => specialRequestsOrderItem.fk_order_id),
          ),
        ];

        const orders = await getOrdersByIds(orderIds, brand);

        const itemPromises = specialRequestsOrderItems.map(async (specialRequestsOrderItem) => {
          const matchedOrder = orders.find((order) => order.id === specialRequestsOrderItem.fk_order_id);
          const logs = await getOrderItemLogs(matchedOrder.id, specialRequestsOrderItem.id_items);

          let created_at = null;

          if (logs.result.some((log) => log.action.includes('change_guest_special_requests'))) {
            created_at = logs.result.find((log) => log.action.includes('change_guest_special_requests'))?.time;
          } else {
            created_at = logs.result.find((log) => log.action.includes('complete'))?.time;
          }

          const check_in = specialRequestsOrderItem?.reservation?.check_in || null;
          const customerNameTrimmed = matchedOrder?.customer_full_name?.trim() || '';
          const customer_name = customerNameTrimmed || matchedOrder?.customer_email || 'N/A';
          const customer_id = matchedOrder?.fk_customer_id || null;

          return {
            ...specialRequestsOrderItem,
            check_in,
            created_at,
            customer_id,
            customer_name,
          };
        });

        const updatedSpecialRequestsOrderItems = await Promise.all(itemPromises);

        setSpecialRequestsOrderItemsData({
          specialRequestsOrderItems: updatedSpecialRequestsOrderItems,
          total: response.total,
        });
      } catch (error) {
        enqueueSnackbar('Failed to fetch special requests order items.', { variant: 'error' });
      } finally {
        setLoading(false);
      }
    },
    [brand, enqueueSnackbar],
  );

  useEffect(() => {
    fetchSpecialRequestsOrderItems(paginationModel.page, paginationModel.pageSize);
  }, [fetchSpecialRequestsOrderItems, paginationModel.page, paginationModel.pageSize]);

  const vendorPortalLink = useCallback((vendorId: string) => {
    return `${window.configs.VENDOR_PORTAL}/${vendorId}/special-requests`;
  }, []);

  const ordersPageLink = useCallback((orderId: string) => {
    return `/purchases/${orderId}`;
  }, []);

  const columns = useMemo(
    () => [
      {
        field: 'id_items',
        headerName: 'Special Request ID',
        width: 150,
        renderCell: (params) => {
          return (
            <Link
              href={vendorPortalLink(params.row.fk_vendor_id)}
              target="_blank"
              rel="noopener"
              title={params.value}
              noWrap
            >
              {params.value}
            </Link>
          );
        },
      },
      {
        field: 'customer_name',
        headerName: 'Customer',
        width: 150,
        renderCell: (params) => {
          return (
            <Link href={`/users/${params.row.customer_id}`} target="_blank" rel="noopener" title={params.value} noWrap>
              {params.value}
            </Link>
          );
        },
      },
      {
        field: 'fk_order_id',
        headerName: 'Order ID',
        width: 150,
        renderCell: (params) => {
          return (
            <Link href={ordersPageLink(params.value)} target="_blank" rel="noopener" title={params.value} noWrap>
              {params.value}
            </Link>
          );
        },
      },
      {
        field: 'guest_special_requests_vendor_response',
        headerName: 'Vendor Response',
        width: 380,
        renderCell: (params) => {
          if (params.row.reservation.guest_special_requests_vendor_response !== 'N/A') {
            return <ExpandableText value={params.row.reservation.guest_special_requests_vendor_response} />;
          }

          return <Typography sx={{ color: 'red' }}>Vendor has not responded</Typography>;
        },
      },
      {
        field: 'guest_special_requests',
        headerName: 'Special Request',
        width: 380,
        renderCell: (params) => {
          return <ExpandableText value={params.row.reservation.guest_special_requests} />;
        },
      },
      {
        field: 'check_in',
        headerName: 'Check In',
        width: 120,
        valueFormatter: (value) => {
          return formatDateSlashes(value);
        },
      },
      {
        field: 'created_at',
        headerName: 'Created At',
        width: 120,
        valueFormatter: (value) => {
          return formatDateSlashes(value);
        },
      },
    ],
    [ordersPageLink, vendorPortalLink],
  );

  return (
    <Paper>
      <PageSubheader title="Special requests" />
      {loading && <LinearProgress />}
      <DataGrid
        rows={specialRequestsOrderItemsData.specialRequestsOrderItems}
        columns={columns}
        rowCount={specialRequestsOrderItemsData.total}
        paginationMode="server"
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        getRowId={(row) => row?.id_items}
        getRowHeight={() => 'auto'}
        autoHeight
        disableRowSelectionOnClick={loading}
      />
    </Paper>
  );
}
