import React, { useCallback, useEffect, useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import Spinner from '~/components/Common/Spinner';

import * as OrdersService from '~/services/OrdersService';
import { formatDateOrdinalWithClock, formatDateShortDD } from '~/services/TimeService';
import UsersService from '~/services/UsersService';

import type { OrderItemLogRecord, OrderItemLogReservation } from '~/types/responses';

type OrderItemLog = OrderItemLogRecord & { formattedDescription: string; user_type: string; user_name: string };

function roleToType(role) {
  return role.replace(/-.*/, '');
}

function roleToHuman(role) {
  return toProperCase(roleToType(role));
}

function toProperCase(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function actionToHuman(action: string) {
  return toProperCase(action.replace(/_/g, ' '));
}

function fromDate(reservation: OrderItemLogReservation | null) {
  if (!reservation) {
    return null;
  }

  if (reservation.check_in) {
    return reservation.check_in;
  }

  if (reservation.start_date) {
    return reservation.start_date;
  }

  return null;
}

function toDate(reservation: OrderItemLogReservation | null) {
  if (!reservation) {
    return null;
  }

  if (reservation.check_out) {
    return reservation.check_out;
  }

  if (reservation.end_date) {
    return reservation.end_date;
  }

  return null;
}

function humanDates(from, to) {
  return `${formatDateShortDD(from)} - ${formatDateShortDD(to)}`;
}

function humanFromToDates(from, to, previousFrom, previousTo) {
  return `${humanDates(previousFrom, previousTo)} to ${humanDates(from, to)}`;
}

function rolesToHuman(roles) {
  if (roles.length === 0) {
    return 'Customer';
  }

  return roles.map(roleToHuman).join(', ');
}

function formatSource(source: string) {
  switch (source) {
    case 'admin-portal':
      return 'Admin Portal';
    case 'customer-portal':
      return 'Customer Portal';
    case 'vendor-portal':
      return 'Vendor Portal';
    case 'svc-order-script':
      return 'System';
    default:
      return 'Unknown';
  }
}

export function humanDescription(log: OrderItemLogRecord): string {
  const reservation = log.reservation;
  const previousReservation = log.previous_reservation;

  if (log.action === 'create' && reservation) {
    return `Created with dates ${humanDates(
      fromDate(reservation as OrderItemLogReservation),
      toDate(reservation as OrderItemLogReservation),
    )}`;
  }

  if (log.action === 'create') {
    return 'Created';
  }

  if (log.action === 'complete') {
    return 'Transaction complete';
  }

  if (log.action === 'mark_reminder_sent') {
    return 'Reminder email sent';
  }

  if (log.action === 'change_dates' || log.action === 'change_reservation') {
    const mes = [
      `Changed dates from ${humanFromToDates(
        fromDate(reservation as OrderItemLogReservation),
        toDate(reservation as OrderItemLogReservation),
        fromDate(previousReservation),
        toDate(previousReservation),
      )}`,
    ];

    if (log.action === 'change_reservation') {
      const guestFirstName = (reservation as OrderItemLogReservation).guest_first_name;
      const guestLastName = (reservation as OrderItemLogReservation).guest_last_name;

      mes.push(
        `Changed guest name from ${previousReservation.guest_first_name} ${previousReservation.guest_last_name} to ${guestFirstName} ${guestLastName}`,
      );
    }

    return mes.join(' and ');
  }

  if (log.action === 'change_guest_details') {
    const guestFirstName = (reservation as OrderItemLogReservation).guest_first_name;
    const guestLastName = (reservation as OrderItemLogReservation).guest_last_name;

    return `Changed guest details from ${previousReservation.guest_first_name} ${previousReservation.guest_last_name} to ${guestFirstName} ${guestLastName}`;
  }

  if (log.action === 'change_guest_special_requests') {
    return `Special request updated`;
  }

  if (log.action === 'await_dates') {
    return 'Purchased and awaiting date selection';
  }

  if (log.action === 'guest_special_requests_vendor_response') {
    return 'Vendor responds to special request';
  }

  if (log.action === 'set_reservation') {
    return `Selected dates ${humanDates(
      fromDate(reservation as OrderItemLogReservation),
      toDate(reservation as OrderItemLogReservation),
    )}`;
  }

  if (log.action === 'set_booking_date') {
    return `Selected date ${formatDateShortDD(reservation as string)}`;
  }

  if (log?.description) {
    return log.description;
  }

  return actionToHuman(log.action);
}

const loadedUsers = new Map<string, App.User>();

async function addUserInfo(log: OrderItemLog): Promise<OrderItemLog> {
  if (log.system_user) {
    log.user_type = 'Admin';
    log.user_name = 'System';
    return log;
  }

  let user: App.User;

  if (loadedUsers.has(log._links.user.href)) {
    user = loadedUsers.get(log._links.user.href);
  } else {
    user = await UsersService.getUserByUrl(log._links.user.href);
    loadedUsers.set(log._links.user.href, user);
  }

  log.user_type = rolesToHuman(user.roles);
  log.user_name = user.fullName;

  return log;
}

async function getLogs(orderId: string, itemId: string): Promise<Array<OrderItemLog>> {
  const response = await OrdersService.getOrderItemLogs(orderId, itemId);

  const logs: Array<OrderItemLog> = [];

  for (const logItem of response.result) {
    const formattedDescription = humanDescription(logItem);
    const log: OrderItemLog = Object.assign({ formattedDescription, user_type: '', user_name: '' }, logItem);
    logs.push(await addUserInfo(log));
  }

  return logs;
}

const columns: Array<GridColDef<OrderItemLog>> = [
  {
    field: 'user_type',
    headerName: 'User Type',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    display: 'flex',
  },
  {
    field: 'user_name',
    headerName: 'User',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    display: 'flex',
  },
  {
    field: 'formattedDescription',
    headerName: 'Action',
    sortable: false,
    disableColumnMenu: true,
    flex: 2,
    display: 'flex',
    renderCell: (params) => <Typography sx={{ whiteSpace: 'pre-wrap', lineHeight: '2' }}>{params.value}</Typography>,
  },
  {
    field: 'source',
    headerName: 'Source',
    sortable: false,
    disableColumnMenu: true,
    flex: 0.7,
    display: 'flex',
    valueFormatter: (value) => formatSource(value),
  },
  {
    field: 'time',
    headerName: 'Happened at',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    valueFormatter: (value) => formatDateOrdinalWithClock(value),
    display: 'flex',
  },
];

interface Props {
  orderId: string;
  itemId: string;
}

export default function Log({ orderId, itemId }: Props) {
  const [loadingState, setLoadingState] = useState<Utils.FetchingState>('idle');
  const [logs, setLogs] = useState<Array<OrderItemLog>>([]);
  const [open, setOpen] = useState(false);

  const handleChange = useCallback(
    async (_: React.SyntheticEvent | null, isExpanded: boolean) => {
      try {
        setOpen(isExpanded);

        if (!isExpanded) {
          setLoadingState('idle');
          return;
        }

        setLoadingState('loading');
        setLogs([]);

        const logs = await getLogs(orderId, itemId);

        setLogs(logs);
        setLoadingState('success');
      } catch (err) {
        setLoadingState('failed');
      }
    },
    [itemId, orderId],
  );

  useEffect(() => {
    // Open logs by default
    handleChange(null, true);
  }, [handleChange]);

  return (
    <Accordion
      expanded={open}
      onChange={handleChange}
      variant="outlined"
      slotProps={{ transition: { unmountOnExit: true } }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>Item Log</AccordionSummary>
      <AccordionDetails>
        {loadingState === 'loading' && <Spinner size={32} />}
        {loadingState === 'success' && (
          <DataGrid
            rows={logs}
            columns={columns}
            getRowId={(row) => row.time}
            pageSizeOptions={[]}
            disableColumnFilter
            disableColumnMenu
            disableRowSelectionOnClick
            autoHeight
            hideFooter
          />
        )}
      </AccordionDetails>
    </Accordion>
  );
}
