import React, { useState } from 'react';

import { IChangeEvent } from '@rjsf/core';
import Form from '@rjsf/mui';
import { RJSFSchema } from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import fileDownload from 'react-file-download';

import { Button } from '@mui/material';

import { downloadFxHedgeOrderReport } from '~/services/ReportingService';
import { formatDateISO, startOfMonth, subMonths } from '~/services/TimeService';

import ErrorDisplay from '../ErrorDisplay';

const formSchema: RJSFSchema = {
  description: 'Please choose time period for export.',
  type: 'object',
  properties: {
    startAt: {
      title: 'Select Start Date',
      type: 'string',
      format: 'date',
    },
    endAt: {
      title: 'Select End Date',
      type: 'string',
      format: 'date',
    },
  },
};

const uiSchema = {
  startAt: {
    'ui:widget': 'date',
  },
  endAt: {
    'ui:widget': 'date',
  },
};

const formData = {
  startAt: formatDateISO(startOfMonth(subMonths(1))),
  endAt: formatDateISO(startOfMonth()),
};

export default function ExportFxHedgeOrderReportForm() {
  const [error, setError] = useState(null);

  const handleSubmit = (event: IChangeEvent) => {
    const { startAt, endAt } = event.formData;
    const fileName = `fx_hedge_order_report_${startAt}_${endAt}.xlsx`;

    downloadFxHedgeOrderReport({ startAt, endAt })
      .then(function (text) {
        fileDownload(text, fileName);
      })
      .catch(function (err) {
        setError(err.message);
      });
  };

  return (
    <Form
      schema={formSchema}
      uiSchema={uiSchema}
      formData={formData}
      onSubmit={handleSubmit}
      validator={validator}
      showErrorList={false}
    >
      <Button type="submit" variant="contained">
        Download FX Hedge Order Report
      </Button>

      {error && <ErrorDisplay message={error} />}
    </Form>
  );
}
