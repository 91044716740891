import { paths as CustomerCommunicationPaths } from '@luxuryescapes/contract-customer-communication';

import { getDayJs } from '~/services/TimeService';
import { request } from '~/services/common';

import { makeCustomerCommunicationV1URL } from './customerCommunicationRequestUtils';

type CustomScheduleGetContract =
  CustomerCommunicationPaths['/api/customer-communication/v1/custom-schedules/{scheduleId}']['get'];

function mapCustomSchedule(
  external: CustomScheduleGetContract['responses']['200']['content']['application/json']['result'],
): CustomerCommunication.CustomSchedule {
  const internalCustomSections: Array<CustomerCommunication.CustomScheduleDynamicSection> = [];

  external.customSections?.forEach((section) => {
    if (section.type === 'OFFER') {
      internalCustomSections.push({
        position: section.position,
        type: 'OFFER',
        heading: section.heading,
        offerIds: section.offerIds,
      });
    } else if (section.type === 'FLIGHT') {
      internalCustomSections.push({
        position: section.position,
        type: 'FLIGHT',
        heading: section.heading,
        flights: section.flights.map((flight) => ({
          departingCity: flight.departureCity,
          arrivalCity: flight.arrivalCity,
          pricePerPerson: flight.pricePerPerson,
          tripType: flight.tripType,
          url: flight.flightUrl,
          imageId: flight.airlineLogoImageId,
        })),
      });
    } else if (section.type === 'BANNER') {
      internalCustomSections.push({
        position: section.position,
        type: 'BANNER',
        bannerName: section.bannerName,
        bannerUrl: section.bannerUrl,
        desktopImageId: section.desktopImageId,
        mobileImageId: section.mobileImageId,
      });
    } else if (section.type === 'DREAM_ARTICLE') {
      internalCustomSections.push({
        position: section.position,
        type: 'DREAM_ARTICLE',
        destination: section.destination,
        heading: section.heading,
        subheading: section.subheading,
        articleUrl: section.articleUrl,
        desktopImageId: section.desktopImageId,
        mobileImageId: section.mobileImageId,
      });
    } else if (section.type === 'DESTINATION') {
      internalCustomSections.push({
        position: section.position,
        type: 'DESTINATION',
        heading: section.heading,
        destinations: section.destinations.map((destination) => ({
          name: destination.name,
          redirectUrl: destination.redirectUrl,
          imageId: destination.imageId,
        })),
      });
    } else if (section.type === 'CALL_TO_ACTION') {
      internalCustomSections.push({
        position: section.position,
        type: 'CALL_TO_ACTION',
        text: section.ctaText,
        url: section.ctaUrl,
      });
    }
  });

  let pushStatus: CustomerCommunication.CustomSchedule['pushStatus'] = 'APPROVED';
  const pushSchedules: Array<CustomerCommunication.CustomSchedulePushSection> = [];
  for (const pushSchedule of external.pushSchedules ?? []) {
    // all push status should be approved to be shown in the UI
    const status = pushSchedule.status;
    if (status !== 'APPROVED') {
      pushStatus = status;
    }
    pushSchedules.push({
      scheduleType: pushSchedule.scheduleType,
      title: pushSchedule.title,
      subtitle: pushSchedule.subtitle,
      message: pushSchedule.message,
      link: pushSchedule.link,
      imageId: pushSchedule.imageId,
    });
  }
  if (pushSchedules.length === 0) {
    pushStatus = 'DRAFT';
  }

  return {
    id: external.scheduleId,
    brandId: external.identifier.brand,
    countryGroupId: external.identifier.countryGroup,
    countryId: external.identifier.country,
    stateId: external.identifier.state,
    cityId: external.identifier.city,
    segmentId: external.identifier.segment,
    cadenceId: external.identifier.cadence,
    membershipId: external.identifier.loyalty,
    sendDate: getDayJs(external.sendDate).tz(window.configs.DEFAULT_TIMEZONE),
    type: 'DESTINATION',
    destination: external.destination,
    emailSubjectLine: external.emailSubjectLine,
    emailPreheader: external.emailPreheader,
    introSection: {
      headerDesktopImage: external.introSection?.headerDesktopImage,
      headerMobileImage: external.introSection?.headerMobileImage,
      headerUrl: external.introSection?.headerUrl ?? '',
      introHeading: external.introSection?.introHeading,
      introCopy: external.introSection?.introCopy,
      introCta: external.introSection?.introCta,
      introCtaUrl: external.introSection?.introCtaUrl,
    },
    customSections: internalCustomSections,
    emailStatus: external.status,
    pushSchedules,
    pushStatus,
  };
}

async function requestGetCustomSchedule(scheduleId: string) {
  if (!scheduleId) {
    throw 'missing scheduleId';
  }

  const url = makeCustomerCommunicationV1URL(`/custom-schedules/${scheduleId}`);

  return request(url.toString(), {
    method: 'GET',
    credentials: 'include',
  })
    .then((response: CustomScheduleGetContract['responses']['200']['content']['application/json']) => {
      return mapCustomSchedule(response.result);
    })
    .catch((error) => {
      throw JSON.stringify(error, null, 2);
    });
}

export default requestGetCustomSchedule;
