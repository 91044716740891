function basePath() {
  return window.configs.PDF_HOST + '/api/';
}

export async function getTaxInvoicePDF(taxInvoiceObj) {
  const response = await fetch(basePath() + 'pdf/tax-invoice', {
    ignoreResponse: true,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },

    body: JSON.stringify({
      ...taxInvoiceObj,
    }),
  });

  return await response.blob();
}

export async function getRatePlansPDF(payload) {
  const response = await fetch(basePath() + 'pdf/rate-plans', {
    ignoreResponse: true,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  });
  return await response.blob();
}

export async function getTaxesAndFeesPDF(payload) {
  const response = await fetch(basePath() + 'pdf/taxes-and-fees', {
    ignoreResponse: true,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  });
  return await response.blob();
}

export async function getCommissionStatementPDF(payload) {
  const response = await fetch(basePath() + 'pdf/commission-report', {
    ignoreResponse: true,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  });
  return await response.blob();
}

export async function htmlToPdf(html) {
  const response = await fetch(`${basePath()}pdf/html-printer`, {
    ignoreResponse: true,
    method: 'POST',
    headers: { 'Content-Type': 'text/plain' },
    body: html,
  });
  return await response.blob();
}
