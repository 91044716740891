export const TRAVELLER_TYPE_ADULT = 'Adult';
export const TRAVELLER_TYPE_CHILD = 'Child';
export const TRAVELLER_TYPE_INFANT = 'Infant';
export const TRAVELLER_REFUND_STATUS_OPTIONS = [
  'internal_not_refunded',
  'internal_refunded',
  'internal_refund_protect',
  'external_flights_on_credit',
  'external_flights_on_refund',
  'external_flights_rebooked',
];
export const TRAVELLER_REFUND_STATUS_OPTIONS_MAP = {
  internal_not_refunded: 'Completed',
  internal_refunded: 'Refunded',
  external_flights_on_credit: 'Flights on credit',
  external_flights_on_refund: 'Flights on refund',
  external_flights_rebooked: 'Flights rebooked',
};
export const TRAVELLER_TYPES = [TRAVELLER_TYPE_ADULT, TRAVELLER_TYPE_CHILD, TRAVELLER_TYPE_INFANT];

export const OPERATION_SET_TRAVELLER_REFUND_STATUS = 'set_traveller_refund_status';

export const FLIGHT_ORDER_TYPE_NEW = 'New';
export const FLIGHT_ORDER_TYPE_CHANGE = 'Change';

export const FLIGHT_ORDER_TYPE = [FLIGHT_ORDER_TYPE_NEW, FLIGHT_ORDER_TYPE_CHANGE];

export const FLIGHT_TYPE_DOMESTIC = 'Domestic';
export const FLIGHT_TYPE_INTERNATIONAL = 'International';

export const FLIGHT_TYPE = [FLIGHT_TYPE_DOMESTIC, FLIGHT_TYPE_INTERNATIONAL];

export const AIRLINE_FEE_TYPE_NO_FEE = 'No fee';
export const AIRLINE_FEE_TYPE_CHANGE_FEE = 'Change';
export const AIRLINE_FEE_TYPE_NAME_CORRECTION_FEE = 'Name correction';
export const AIRLINE_FEE_TYPE_OTHER_FEE = 'Other';

export const AIRLINE_FEE_TYPE = [
  AIRLINE_FEE_TYPE_NO_FEE,
  AIRLINE_FEE_TYPE_CHANGE_FEE,
  AIRLINE_FEE_TYPE_NAME_CORRECTION_FEE,
  AIRLINE_FEE_TYPE_OTHER_FEE,
];

export const SERVICE_FEE_TYPE_NO_FEE = 'No fee';
export const SERVICE_FEE_TYPE_BOOKING_FEE = 'Booking';
export const SERVICE_FEE_TYPE_DOMESTIC_FEE = 'Domestic';
export const SERVICE_FEE_TYPE_INTERNATIONAL_FEE = 'International';

export const SERVICE_FEE_TYPE = [
  SERVICE_FEE_TYPE_NO_FEE,
  SERVICE_FEE_TYPE_BOOKING_FEE,
  SERVICE_FEE_TYPE_DOMESTIC_FEE,
  SERVICE_FEE_TYPE_INTERNATIONAL_FEE,
];

export const MIN_PASSENGER_AGE = 3;

export const ADULT_TITLE_OPTIONS = [
  {
    value: 'mr',
    label: 'Mr',
  },
  {
    value: 'ms',
    label: 'Ms',
  },
  {
    value: 'mrs',
    label: 'Mrs',
  },
  {
    value: 'miss',
    label: 'Miss',
  },
];

export const CHILD_TITLE_OPTIONS = [
  {
    value: 'miss',
    label: 'Miss',
  },
  {
    value: 'master',
    label: 'Master',
  },
];

export const PASSENGER_TYPE_OPTIONS = [
  {
    value: 'adult',
    label: 'Adult',
  },
  {
    value: 'child',
    label: 'Child',
  },
  {
    value: 'infant',
    label: 'Infant',
  },
];

export const FLIGHTS_CAMPAIGNS_TABLE_SIZE = 15;
export const FLIGHTS_DEALS_TABLE_SIZE = 15;
