import featureToggle from './featureToggle';

export function isLuxLoyaltyEnabled(): boolean {
  return featureToggle.availableToShow('SHOW_LUX_LOYALTY');
}

export const luxLoyaltyBenefitDescriptionMap: Map<App.LuxLoyaltyBenefitType, string> = new Map([
  ['airport_transfer', 'Airport Transfers'],
  ['discounted_insurance', 'Discounted Travel & Booking Protection'],
  ['early_access', 'Early Access To Deals'],
  ['hidden_access', 'Hidden Access To Deals'],
  ['lounge_pass', 'Lounge Pass Access (AU Airports)'],
  ['phone_support', 'Priority 24/7 Phone Support'],
  ['tour_exp_credit', 'Tour Optional Extras'],
  ['travelshoot_credit', 'Travelshoot Vouchers'],
  ['hotel_upgrade', 'Complimentary Hotel Upgrades'],
]);
