import React, { Context, createContext, useCallback, useContext, useEffect, useState } from 'react';

import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';

import { Port } from '~/components/Cruises/pages/Ports/types';

import portService from '~/services/cruises/PortService';

import { parseSearchString } from '~/utils/url';

interface Filter {
  name?: string;
}

interface CruisesData {
  ports: Array<Port>;
  page: number;
  total: number;
  paginate: (page: number) => void;
  setFilter: (value: Filter) => void;
  filter: Filter;
}

interface PortProviderProps {
  children?: React.ReactNode;
}

export const PortContext: Context<CruisesData> = createContext({} as CruisesData);

export const RESULTS_PER_PAGE = 15;

export const PortProvider = ({ children }: PortProviderProps): JSX.Element => {
  const [filter, setFilter] = useState<Filter>({});
  const [ports, setPorts] = useState<Array<Port>>([]);
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);

  const { search } = useLocation();
  const { push: setQueryString } = useHistory();

  const getPortList = useCallback(async () => {
    const skip = (page - 1) * RESULTS_PER_PAGE;
    const res = await portService.listWithPagination({
      ...(filter.name && { name: filter.name }),
      skip,
      take: RESULTS_PER_PAGE,
    });
    setPorts(res.result);
    setTotal(res.total);
  }, [filter, page]);

  const paginate = (page: number): void => {
    const queryString = new URLSearchParams({
      page: page.toString(),
    }).toString();
    setQueryString({ search: queryString });
    setPage(page);
  };

  useEffect(() => {
    const queryString = parseSearchString(search);
    if (queryString) {
      const pageToGo = parseInt(queryString.page as string);
      if (pageToGo) setPage(pageToGo);
    }
  }, [search]);

  useEffect(() => {
    getPortList();
  }, [getPortList, page, filter]);

  return (
    <PortContext.Provider
      value={{
        ports,
        filter,
        setFilter,
        page,
        total,
        paginate,
      }}
    >
      {children}
    </PortContext.Provider>
  );
};

export const usePorts = (): CruisesData => useContext(PortContext);
