import React, { forwardRef, useState } from 'react';

import { Paper, Stack, TextField } from '@mui/material';

import { DynamicSectionType } from '../../context/useDynamicSections';

import ScheduleHeaderImageInput, { parseHeaderImageFormData } from './Inputs/ScheduleHeaderImageInput';

export enum SCHEDULE_DREAM_SECTION_FORM_NAMES {
  TITLE = 'dream_section_title',
  DESTINATION_NAME = 'dream_section_destination_name',
  SUBHEADING = 'dream_section_subheading',
  ARTICLE_URL = 'dream_section_article_url',
}

interface Props {
  existingTitle?: string;
  existingSubheading?: string;
  existingArticleUrl?: string;
  existingWebImageId?: string;
  existingMobileImageId?: string;
  existingDestinationName?: string;
}

export function parseDreamSectionFormData(formData: FormData, position = -1) {
  const imageData = parseHeaderImageFormData(formData);
  return {
    type: DynamicSectionType.DREAM_ARTICLE,
    heading: String(formData.get(SCHEDULE_DREAM_SECTION_FORM_NAMES.TITLE)),
    subheading: String(formData.get(SCHEDULE_DREAM_SECTION_FORM_NAMES.SUBHEADING)),
    articleUrl: String(formData.get(SCHEDULE_DREAM_SECTION_FORM_NAMES.ARTICLE_URL)),
    destination: String(formData.get(SCHEDULE_DREAM_SECTION_FORM_NAMES.DESTINATION_NAME)),
    desktopImageId: imageData.webImageId,
    mobileImageId: imageData.mobileImageId,
    position,
  };
}

const ScheduleDreamSectionForm = forwardRef<HTMLFormElement, Props>((props, ref) => {
  const {
    existingTitle = '',
    existingSubheading = '',
    existingArticleUrl = '',
    existingWebImageId = '',
    existingMobileImageId = '',
    existingDestinationName = '',
  } = props;

  const [title, setTitle] = useState(existingTitle);
  const [subheading, setSubheading] = useState(existingSubheading);
  const [destinationName, setDestinationName] = useState(existingDestinationName);
  const [articleUrl, setArticleUrl] = useState(existingArticleUrl);
  const [webImageId, setWebImageId] = useState(existingWebImageId);
  const [mobileImageId, setMobileImageId] = useState(existingMobileImageId);
  const [headerUrl, setHeaderUrl] = useState(''); // Required by ScheduleHeaderImageInput but not used here

  return (
    <Stack component="form" ref={ref} spacing={3}>
      <Paper variant="outlined" sx={{ p: 2 }}>
        <Stack spacing={2}>
          <TextField
            fullWidth
            label="Title"
            name={SCHEDULE_DREAM_SECTION_FORM_NAMES.TITLE}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />

          <TextField
            fullWidth
            label="Subheading"
            name={SCHEDULE_DREAM_SECTION_FORM_NAMES.SUBHEADING}
            value={subheading}
            onChange={(e) => setSubheading(e.target.value)}
            required
            multiline
            rows={2}
          />

          <TextField
            fullWidth
            label="Destination Name"
            name={SCHEDULE_DREAM_SECTION_FORM_NAMES.DESTINATION_NAME}
            value={destinationName}
            onChange={(e) => setDestinationName(e.target.value)}
            required
          />

          <TextField
            fullWidth
            label="Article URL"
            name={SCHEDULE_DREAM_SECTION_FORM_NAMES.ARTICLE_URL}
            value={articleUrl}
            onChange={(e) => setArticleUrl(e.target.value)}
            required
          />

          <ScheduleHeaderImageInput
            webImageId={webImageId}
            mobileImageId={mobileImageId}
            headerUrl={headerUrl}
            onWebImageIdChange={setWebImageId}
            onMobileImageIdChange={setMobileImageId}
            onHeaderUrlChange={setHeaderUrl}
            showHeaderUrl={false}
          />
        </Stack>
      </Paper>
    </Stack>
  );
});

ScheduleDreamSectionForm.displayName = 'ScheduleDreamSectionForm';
export default ScheduleDreamSectionForm;
