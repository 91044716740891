import React, { useCallback, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useHistory, useParams } from 'react-router';

import { Alert, Container } from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';

import { CreateFlightDealData, createFlightDeal, deleteFlightDeal, updateFlightDeal } from '~/services/FlightsService';
import { toDayJs } from '~/services/TimeService';

import { useFlightDeal } from '../hooks/useFlightDeal';

import { FlightDealsForm } from './FlightDealsForm';

export default function FlightsDealsEdit() {
  const { id } = useParams<{ id: string }>();
  const { data, error, loading, revalidate } = useFlightDeal(id);
  const { enqueueSnackbar } = useSnackbar();
  const [updating, setUpdating] = useState(false);
  const [updateError, setUpdateError] = useState<string | undefined>();
  const [deleting, setDeleting] = useState(false);
  const [cloning, setCloning] = useState(false);
  const history = useHistory();

  const handleClone = useCallback(
    async (deal: App.FlightDeal) => {
      setCloning(true);

      try {
        const offerDurations = deal.offerDuration.filter((duration) => duration.departureDate !== null);

        const dealData = {
          carrierCode: deal.carrierCode,
          region: deal.region,
          originAirportCode: deal.originAirportCode,
          destinationAirportCode: deal.destinationAirportCode,
          fareType: deal.fareType,
          perAdultPrice: parseFloat(deal.perAdultPrice),
          salesStartDate: deal.salesStartDate,
          salesEndDate: deal.salesEndDate,
          dealName: deal.dealName,
          heroImagePublicId: deal.heroImagePublicId,
          categoryGroup: deal.categoryGroup,
          fareClass: deal.fareClass,
          bookingClassCode: deal.bookingClassCode,
          adultCount: deal.adultCount,
          discountLabel: deal.discountLabel,
          searchText: 'View flights',
          dealDescription: deal.dealDescription,
          fareName: '',
          fareBasis: '',
          termsAndConditions: deal.termsAndConditions,
          travelDays: deal.travelDays,
          hierarchy: deal.hierarchy,
          offerDurations,
          airlineCampaign: deal.airlineCampaign,
          destinationCampaign: deal.destinationCampaign,
          departureAirportCampaign: deal.departureAirportCampaign,
        };

        const { result } = await createFlightDeal(dealData);
        window.open(`/flights/flight-deal/${result.id}/edit`, '_blank');
        enqueueSnackbar('Deal was cloned', { variant: 'success' });
      } catch {
        enqueueSnackbar('Something went wrong, please try again', { variant: 'error' });
      }

      setCloning(false);
    },
    [enqueueSnackbar],
  );

  const handleDelete = useCallback(
    async (id: string) => {
      setDeleting(true);

      try {
        await deleteFlightDeal(id);
        enqueueSnackbar('Deal was deleted', { variant: 'success' });
        history.replace('/flights?tab=deals');
      } catch {
        enqueueSnackbar('Something went wrong, please try again', { variant: 'error' });
      }

      setDeleting(false);
    },
    [enqueueSnackbar, history],
  );

  const handleUpdate = useCallback(
    async (data: CreateFlightDealData) => {
      setUpdateError(undefined);
      setUpdating(true);

      try {
        await updateFlightDeal(id, data);
        revalidate();
        enqueueSnackbar('Deal was updated', { variant: 'success' });
      } catch {
        setUpdateError('Something went wrong, please try again');
      }

      setUpdating(false);
    },
    [id, revalidate, enqueueSnackbar],
  );

  const showForm = !error && !loading && data;

  return (
    <Container maxWidth="xl">
      <PageHeader title="Edit Deal" backButton />

      {error && <Alert severity="error">Something went wrong, please try again</Alert>}

      {showForm && (
        <FlightDealsForm
          onSubmit={handleUpdate}
          onDelete={() => handleDelete(data.id)}
          onClone={() => handleClone(data)}
          loading={updating}
          error={updateError}
          initialValues={{
            adultCount: data.adultCount,
            bookingClassCode: data.bookingClassCode,
            carrierCode: data.carrierCode,
            categoryGroup: data.categoryGroup,
            dealDescription: data.dealDescription,
            dealName: data.dealName,
            departureDate1: toDayJs(data.offerDuration[0].departureDate),
            departureDate2: data.offerDuration[1].departureDate ? toDayJs(data.offerDuration[1]?.departureDate) : null,
            departureDate3: data.offerDuration[2].departureDate ? toDayJs(data.offerDuration[2]?.departureDate) : null,
            arrivalDate1: data.offerDuration[0]?.arrivalDate ? toDayJs(data.offerDuration[0].arrivalDate) : null,
            arrivalDate2: data.offerDuration[1]?.arrivalDate ? toDayJs(data.offerDuration[1]?.arrivalDate) : null,
            arrivalDate3: data.offerDuration[2]?.arrivalDate ? toDayJs(data.offerDuration[2]?.arrivalDate) : null,
            destinationAirportCode: data.destinationAirportCode,
            discountLabel: data.discountLabel,
            fareClass: data.fareClass,
            fareType: data.fareType,
            hierarchy: data.hierarchy?.toString() ?? '0',
            originAirportCode: data.originAirportCode,
            heroImage: data.heroImagePublicId,
            perAdultPrice: data.perAdultPrice?.toString(),
            region: data.region,
            termsAndConditions: data.termsAndConditions,
            travelDays:
              data.travelDays && Array.isArray(data.travelDays)
                ? data.travelDays.join(',')
                : (data.travelDays as string),
            salesStartDate: toDayJs(data.salesStartDate),
            salesEndDate: toDayJs(data.salesEndDate),
            departureAirportCampaign: data.departureAirportCampaign,
            airlineCampaign: data.airlineCampaign,
            destinationCampaign: data.destinationCampaign,
          }}
          deleting={deleting}
          cloning={cloning}
          showClone
          showDelete
        />
      )}
    </Container>
  );
}
