import React, { forwardRef, useCallback, useState } from 'react';

import { useSnackbar } from 'notistack';

import AddIcon from '@mui/icons-material/Add';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, IconButton, Paper, Stack, TextField } from '@mui/material';
import { GridColDef, GridEventListener, GridRenderCellParams, GridRowModel, GridRowsProp } from '@mui/x-data-grid-pro';

import useImageUrlGenerator from '~/hooks/useImageUrlGenerator';

import { uploadImage } from '~/services/ImageService';

import { DynamicSectionType } from '../../context/useDynamicSections';

import ImageUploadBox from './Inputs/ImageUploadBox';
import StyledDataGrid from './StyledDataGrid';

export enum SCHEDULE_FLIGHT_SECTION_FORM_NAMES {
  HEADING = 'flight_section_heading',
  FLIGHTS = 'flight_section_flights',
}

interface FlightRow {
  id: string;
  departingCity: string;
  arrivalCity: string;
  pricePerPerson: string;
  tripType: string;
  url: string;
  airlineLogo?: string;
  imageId?: string;
  imageFile?: File;
}

interface Props {
  existingHeading?: string;
  existingFlights?: Array<FlightRow>;
}

const defaultGridRowIdGetter = (row: FlightRow) => row.id;
const defaultRowHeightGetter = () => 100 as const; // Reduced height for mini mode
const defaultDetailPanelHeightGetter = () => 'auto' as const;

export function parseFlightSectionFormData(formData: FormData, position = -1) {
  const flightsData = formData.get(SCHEDULE_FLIGHT_SECTION_FORM_NAMES.FLIGHTS);
  const parsedFlights = flightsData ? JSON.parse(String(flightsData)) : [];
  const flights = [];
  for (const flight of parsedFlights) {
    flights.push({
      departingCity: flight.departingCity,
      arrivalCity: flight.arrivalCity,
      pricePerPerson: flight.pricePerPerson,
      tripType: flight.tripType,
      url: flight.url,
      imageId: flight.imageId,
    });
  }
  return {
    type: DynamicSectionType.FLIGHT,
    heading: String(formData.get(SCHEDULE_FLIGHT_SECTION_FORM_NAMES.HEADING)),
    flights: flights,
    position,
  };
}

const ScheduleFlightSectionForm = forwardRef<HTMLFormElement, Props>((props, ref) => {
  const { existingHeading = '', existingFlights = [] } = props;
  const [heading, setHeading] = useState(existingHeading);
  const [rows, setRows] = useState<GridRowsProp>(existingFlights);
  const { enqueueSnackbar } = useSnackbar();

  const handleAddClick = useCallback(() => {
    const id = Date.now().toString();
    setRows((oldRows) => [
      ...oldRows,
      {
        id,
        departingCity: '',
        arrivalCity: '',
        pricePerPerson: '',
        tripType: 'Return',
        url: '',
        airlineLogo: '',
        imageId: '',
      },
    ]);
  }, []);

  const handleOrderChange = useCallback<GridEventListener<'rowOrderChange'>>((params) => {
    setRows((currRows) => {
      const clonedRows = [...currRows];
      clonedRows.splice(params.oldIndex, 1);
      clonedRows.splice(params.targetIndex, 0, params.row);
      return clonedRows;
    });
  }, []);

  const deleteRow = useCallback<React.MouseEventHandler<HTMLButtonElement>>((event) => {
    event.preventDefault();
    const flightId = String(event.currentTarget.dataset['flightId']);

    setRows((currRows) => {
      const clonedRows = [...currRows];
      const foundIndex = currRows.findIndex((i) => i.id === flightId);
      clonedRows.splice(foundIndex, 1);
      return clonedRows;
    });
  }, []);

  const handleProcessRowUpdate = useCallback((newRow: GridRowModel) => {
    setRows((prevRows) => prevRows.map((row) => (row.id === newRow.id ? { ...row, ...newRow } : row)));
    return newRow;
  }, []);

  const handleUploadImage = useCallback(
    async (rowId: string) => {
      const row = rows.find((r) => r.id === rowId);
      if (!row?.imageFile) {
        enqueueSnackbar('No image file available to upload', { variant: 'warning' });
        return;
      }

      try {
        const response = await uploadImage(row.imageFile);
        setRows((prevRows) =>
          prevRows.map((r) =>
            r.id === rowId
              ? {
                  ...r,
                  imageId: response.body.public_id,
                  imageFile: undefined,
                }
              : r,
          ),
        );
        enqueueSnackbar('Image uploaded successfully', { variant: 'success' });
      } catch (error) {
        enqueueSnackbar('Failed to upload image', { variant: 'error' });
      }
    },
    [rows, enqueueSnackbar],
  );

  const columns = useCallback<() => Array<GridColDef>>(
    () => [
      {
        field: 'departingCity',
        sortable: false,
        headerName: 'Departing City',
        flex: 1,
        editable: true,
      },
      {
        field: 'arrivalCity',
        sortable: false,
        headerName: 'Arrival City',
        flex: 1,
        editable: true,
      },
      {
        field: 'pricePerPerson',
        sortable: false,
        headerName: 'Price Per Person',
        flex: 1,
        editable: true,
      },
      {
        field: 'tripType',
        sortable: false,
        headerName: 'Trip Type',
        flex: 1,
        editable: true,
      },
      {
        field: 'url',
        sortable: false,
        headerName: 'URL',
        flex: 1.5,
        editable: true,
      },
      {
        field: 'imageId',
        headerName: 'Image ID',
        flex: 1,
        sortable: false,
        editable: true,
      },
      {
        field: 'imageUpload',
        headerName: 'Image Upload',
        flex: 1,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const imageUrl = useImageUrlGenerator(params.row.imageId);

          return (
            <ImageUploadBox
              id={`flight-image-${params.id}`}
              label="Image"
              mini
              imageUrl={
                params.row.imageId
                  ? imageUrl
                  : params.row.imageFile
                  ? URL.createObjectURL(params.row.imageFile)
                  : undefined
              }
              name={`flight-image-${params.id}`}
              onUpload={(file) => {
                setRows((prevRows) =>
                  prevRows.map((row) =>
                    row.id === params.id
                      ? {
                          ...row,
                          imageFile: file,
                          imageId: '',
                        }
                      : row,
                  ),
                );
              }}
              onRemove={() => {
                setRows((prevRows) =>
                  prevRows.map((row) =>
                    row.id === params.id
                      ? {
                          ...row,
                          imageFile: undefined,
                          imageId: '',
                        }
                      : row,
                  ),
                );
              }}
            />
          );
        },
      },
      {
        field: 'Actions',
        headerName: '',
        flex: 0.1,
        sortable: false,
        renderCell: (params: GridRenderCellParams<FlightRow, FlightRow['id']>) => (
          <Stack direction="column">
            {params.row.imageFile && !params.row.imageId && (
              <IconButton size="small" onClick={() => handleUploadImage(params.row.id)} color="primary">
                <CloudUploadIcon />
              </IconButton>
            )}
            <IconButton size="small" data-flight-id={params.row.id} onClick={deleteRow}>
              <DeleteIcon />
            </IconButton>
          </Stack>
        ),
      },
    ],
    [deleteRow, handleUploadImage],
  );

  return (
    <Stack component="form" ref={ref} spacing={3}>
      <input type="hidden" name={SCHEDULE_FLIGHT_SECTION_FORM_NAMES.FLIGHTS} value={JSON.stringify(rows)} />

      <TextField
        fullWidth
        label="Section Heading"
        name={SCHEDULE_FLIGHT_SECTION_FORM_NAMES.HEADING}
        value={heading}
        onChange={(e) => setHeading(e.target.value)}
      />

      <Stack spacing={2}>
        <Paper variant="outlined">
          <StyledDataGrid
            rows={rows}
            columns={columns()}
            getRowId={defaultGridRowIdGetter}
            autoHeight
            disableRowSelectionOnClick
            rowReordering
            onRowOrderChange={handleOrderChange}
            getRowHeight={defaultRowHeightGetter}
            getDetailPanelHeight={defaultDetailPanelHeightGetter}
            processRowUpdate={handleProcessRowUpdate}
            density="compact"
            disableColumnMenu
            disableColumnFilter
            disableColumnSelector
            hideFooter
          />
        </Paper>

        <Button startIcon={<AddIcon />} onClick={handleAddClick} variant="outlined" sx={{ alignSelf: 'flex-start' }}>
          Add Flight
        </Button>
      </Stack>
    </Stack>
  );
});

ScheduleFlightSectionForm.displayName = 'ScheduleFlightSectionForm';
export default ScheduleFlightSectionForm;
