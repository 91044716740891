import React, { useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, IconButton, MenuItem, Paper, Select, Stack } from '@mui/material';

import { SectionType } from './DynamicSection';

type SectionMenuProps = {
  onAddSection: (sectionType: string) => void;
  getSectionCount: (sectionType: string) => number;
  allowedSections: Array<SectionType>;
};

function SectionMenu({ onAddSection, getSectionCount, allowedSections }: SectionMenuProps): JSX.Element {
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedType, setSelectedType] = useState<string>('');

  const handleAddClick = () => {
    setShowDropdown(true);
  };

  const handleTypeChange = (event) => {
    const type = event.target.value;
    setSelectedType(type);
    onAddSection(type);
    setShowDropdown(false);
    setSelectedType('');
  };

  const handleCancel = () => {
    setShowDropdown(false);
    setSelectedType('');
  };

  return (
    <Stack>
      {!showDropdown ? (
        <Button variant="contained" onClick={handleAddClick} startIcon={<AddIcon />} sx={{ alignSelf: 'flex-start' }}>
          Create New Section
        </Button>
      ) : (
        <Paper variant="outlined" sx={{ p: 2 }}>
          <Stack direction="row" spacing={2} alignItems="center">
            <Select
              value={selectedType}
              onChange={handleTypeChange}
              variant="outlined"
              fullWidth
              displayEmpty
              placeholder="Select section type"
            >
              <MenuItem value="" disabled>
                Select section type
              </MenuItem>
              {allowedSections.map((type) => {
                const count = getSectionCount(type.id);
                const disabled = count >= type.limit;
                return (
                  <MenuItem key={type.id} value={type.id} disabled={disabled}>
                    {type.label} {disabled && `(Limit ${type.limit} reached)`}
                  </MenuItem>
                );
              })}
            </Select>
            <IconButton onClick={handleCancel} color="error" size="small">
              <DeleteIcon />
            </IconButton>
          </Stack>
        </Paper>
      )}
    </Stack>
  );
}

export default SectionMenu;
