import React from 'react';

import { Discount, VerifiedOutlined } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SellIcon from '@mui/icons-material/Sell';
import { Accordion, AccordionDetails, AccordionSummary, Box, Stack, Typography } from '@mui/material';

import currencyFormatter from '~/utils/currencyFormatter';

import PriceSummaryField from '../OrderItem/PriceSummaryField';

interface Props {
  order: App.Order;
}

export default function SubscriptionOrderItem({ order }: Props) {
  return (
    <Accordion defaultExpanded id="subscription-items" sx={{ mt: 2 }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Stack direction="row" alignItems="center">
          <VerifiedOutlined sx={{ mr: 1 }} />
          <Typography variant="h6">Subscription items ({order.subscription_items.length})</Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        {order.subscription_items.map((item, index) => (
          <Box key={item.id} sx={{ maxWidth: 'sm', border: 1, borderColor: 'grey.200', m: 2 }}>
            <Accordion defaultExpanded>
              <AccordionSummary
                sx={{
                  backgroundColor: 'grey.200',
                }}
              >
                <Stack>
                  <div>
                    <Typography sx={{ display: 'inline', color: '#304DAB', fontWeight: 'bold' }}>Lux Plus+ </Typography>
                    {item.sub_type === 'recurring_fee' && (
                      <Typography sx={{ display: 'inline' }}>1 year subscription fee</Typography>
                    )}
                    {item.sub_type === 'joining_fee' && <Typography sx={{ display: 'inline' }}>Joining fee</Typography>}
                  </div>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <Typography variant="subtitle1" display="inline">
                    Membership status:{' '}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    display="inline"
                    fontWeight="bold"
                    sx={{
                      color: 'green',
                    }}
                  >
                    {item.status}
                  </Typography>
                </div>
                <Stack gap={1}>
                  <Typography variant="subtitle1" fontWeight="bold">
                    General information
                  </Typography>
                </Stack>
                <Stack gap={1} mt={1}>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Pricing
                  </Typography>
                  <Stack direction="row" spacing={1}>
                    <SellIcon />
                    <PriceSummaryField
                      boldPrice
                      label="Total sell price"
                      value={currencyFormatter(item.currency, item.total)}
                    />
                    {item.promo_code_discount_amount > 0 && (
                      <>
                        <Discount fontSize="small" color="secondary" />
                        <PriceSummaryField
                          boldPrice
                          label="Promo code discount amount"
                          value={currencyFormatter(item.currency, -item.promo_code_discount_amount)}
                        />
                      </>
                    )}
                  </Stack>
                </Stack>
              </AccordionDetails>
            </Accordion>
          </Box>
        ))}
      </AccordionDetails>
    </Accordion>
  );
}
