import React, { useEffect, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';

import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Link,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';

import { RoomComment, SourceRoom } from '~/components/Bedbank/Properties/Edit/RoomMapping/types';
import Spinner from '~/components/Common/Spinner';

import useToggleState from '~/hooks/useToggleState';

import BedbankService from '~/services/BedbankService';

interface Props {
  room: SourceRoom;
}

function RoomSourceCommentModal({ room }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [comments, setComments] = useState<Array<RoomComment>>(room.comments);
  const [buttonVariant, setButtonVariant] = useState<'outlined' | 'contained'>('outlined');
  const [buttonColor, setButtonColor] = useState<'primary' | 'warning'>('primary');
  const { isToggled, toggle, toggleOff } = useToggleState(false);

  useEffect(() => {
    setButtonVariant(comments.length > 0 ? 'contained' : 'outlined');
    setButtonColor(comments.length > 0 ? 'warning' : 'primary');
  }, [comments]);

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      comment: '',
    },
    mode: 'onBlur',
  });

  const fetchComments = async () => {
    try {
      const response = await BedbankService.getRoomComments(room.externalId, room.externalPropertyId, room.supplier);
      setComments(response.result);
    } catch (error) {
      enqueueSnackbar(`Failed to fetch room comments: ${error}`, { variant: 'error' });
    }
  };

  const handleSubmission = useMemo(
    () =>
      handleSubmit(async (data) => {
        const payload = {
          supplier: room.supplier,
          externalPropertyId: room.externalPropertyId,
          externalId: room.externalId,
          comment: data.comment,
        };

        setLoading(true);
        try {
          await BedbankService.addRoomComment(payload);
          await fetchComments();
          reset();
        } catch (error) {
          enqueueSnackbar(`Failed to store room comment: ${error}`, { variant: 'error' });
        } finally {
          setLoading(false);
        }
      }),
    [handleSubmit, room, reset],
  );

  const handleDeleteComment = async (commentId: string) => {
    setLoading(true);
    try {
      await BedbankService.deleteRoomComment(commentId);
      await fetchComments();
    } catch (error) {
      enqueueSnackbar(`Failed to delete room comment: ${error}`, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button variant={buttonVariant} color={buttonColor} onClick={toggle}>
        Comments
      </Button>
      <Dialog open={isToggled} onClose={toggleOff} maxWidth="sm" fullWidth>
        <form onSubmit={handleSubmission}>
          <DialogTitle>Room Comments</DialogTitle>
          <DialogContent>
            {loading && <Spinner />}
            {!loading && (
              <List>
                {comments.map((comment) => (
                  <ListItem
                    key={comment.id}
                    alignItems="flex-start"
                    secondaryAction={
                      <Button
                        color="error"
                        onClick={() => handleDeleteComment(comment.id)}
                        size="small"
                        disabled={loading}
                      >
                        Delete
                      </Button>
                    }
                  >
                    <ListItemText
                      primary={
                        <Typography variant="subtitle1" fontWeight="bold">
                          <Link href={`/users/${comment.authorId}`} target="_blank">
                            User
                          </Link>
                        </Typography>
                      }
                      secondary={
                        <>
                          <Typography variant="body2" color="text.primary">
                            {comment.comment}
                          </Typography>
                          <Typography variant="caption" color="text.secondary">
                            {comment.createdAt}
                          </Typography>
                        </>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            )}
            <Stack direction="column">
              <Controller
                name="comment"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    name={field.name}
                    ref={field.ref}
                    value={field.value}
                    error={fieldState.invalid}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    type="text"
                    fullWidth
                    multiline
                    rows="3"
                    disabled={loading}
                  />
                )}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <LoadingButton variant="contained" type="submit" loading={loading}>
              Save
            </LoadingButton>
            <Button onClick={toggleOff}>Close</Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

export default RoomSourceCommentModal;
