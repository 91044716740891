import React, { useState } from 'react';

import { FormControl, FormLabel, MenuItem, Select, Stack } from '@mui/material';

import LuxLoyaltyAccountOverview from '~/components/LuxLoyalty/LuxLoyaltyAccountOverview';
import LuxLoyaltyActivityLog from '~/components/LuxLoyalty/LuxLoyaltyActivityLog';
import LuxLoyaltyBenefitsOverview from '~/components/LuxLoyalty/LuxLoyaltyBenefitsOverview';

interface Props {
  account: App.LuxLoyaltyAccount;
}

const menuOptions = [
  { label: 'Account', value: 'account-overview' },
  { label: 'Benefits', value: 'benefits-overview' },
  { label: 'Activities', value: 'activities' },
];

export default function LuxLoyaltyUserProfile({ account }: Props) {
  const [currentMenuOption, setCurrentMenuOption] = useState(menuOptions[0].value);

  return (
    <Stack direction="column" gap={2}>
      <FormControl
        variant="outlined"
        sx={{
          width: 200,
        }}
      >
        <FormLabel sx={{ fontWeight: 'bold' }}>View</FormLabel>
        <Select value={currentMenuOption} onChange={(e) => setCurrentMenuOption(e.target.value)}>
          {menuOptions.map((menuOption) => (
            <MenuItem key={menuOption.value} value={menuOption.value}>
              {menuOption.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {currentMenuOption === 'account-overview' && <LuxLoyaltyAccountOverview account={account} />}
      {currentMenuOption === 'benefits-overview' && <LuxLoyaltyBenefitsOverview account={account} />}
      {currentMenuOption === 'activities' && <LuxLoyaltyActivityLog account={account} />}
    </Stack>
  );
}
