import React, { useCallback, useEffect } from 'react';

import { History } from 'history';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router';

import { Stack } from '@mui/material';
import { GridCellParams } from '@mui/x-data-grid';

import useQuery from '~/hooks/useQuery';

import { GetPromoCodeProps, expirePromo } from '~/services/PromoService';

import PageSubheader from '../Common/Elements/PageSubheader';
import PromoSearchForm from '../Common/Forms/PromoSearchForm';
import Spinner from '../Common/Spinner';
import { withTenant } from '../hoc';

import PromoPage from './PromoPage';
import { usePromoCodes } from './hooks/usePromoCodes';
import usePromoMeta from './hooks/usePromoMeta';

const SIZE_PER_PAGE = 10;

export interface PromoResult {
  status: number;
  message: string;
  count: number;
  total: number;
  result: Array<App.PromoData>;
}

interface Props {
  tenant: App.Tenant;
}

type Users = {
  [id: string]: App.User;
};

const onClick = (row: GridCellParams<App.PromoData>) => {
  // 'code_name' handles the click event internally via Link elements
  if (row.field === 'code_name' || row.field == 'active' || row.field == 'default_display') {
    return;
  }
  const url = `/promos/${row.row.id_promo_code}`;
  window.open(url, '_blank');
};

const getQueryFilters = (query: URLSearchParams): GetPromoCodeProps => {
  const page = query.get('page') ? parseInt(query.get('page') as string) : 1;
  const limit = query.get('limit');
  const codeName = query.get('name') || '';
  const emailAddress = query.get('email_address') || '';
  const caseId = query.get('caseId') || '';
  const expiresAfter = query.get('expiresAfter') || '';
  const noChildPromos = query.get('noChildPromos') === 'true';
  const excludeReferralPromos = query.get('noReferralPromos') === 'true';
  const userIdInteracted = query.get('userIdInteracted') || '';
  const deptTag = query.get('dept_tag') || '';
  const categoryTag = query.get('category_tag') || '';
  const subCategoryTag = query.get('sub_category_tag') || '';
  const isDev = query.get('isDev') || '';

  return {
    page,
    codeName,
    emailAddress,
    caseId,
    expiresAfter,
    noChildPromos,
    excludeReferralPromos,
    userIdInteracted,
    deptTag,
    categoryTag,
    subCategoryTag,
    brand: query.get('brand') ?? 'luxuryescapes',
    limit: limit ? parseInt(limit as string) : SIZE_PER_PAGE,
    isDev: isDev === 'true',
  };
};

const setQueryFilters = (history: History, filters: GetPromoCodeProps) => {
  const query = new URLSearchParams();

  query.set('page', filters.page.toString());
  query.set('limit', filters.limit.toString());
  query.set('name', filters.codeName);
  if (filters.emailAddress) {
    query.set('email_address', filters.emailAddress);
  }
  if (filters.caseId) {
    query.set('caseId', filters.caseId);
  }

  query.set('expiresAfter', filters.expiresAfter);
  if (filters.noChildPromos) {
    query.set('noChildPromos', filters.noChildPromos.toString());
  }

  if (filters.excludeReferralPromos) {
    query.set('noReferralPromos', filters.excludeReferralPromos.toString());
  }

  if (filters.userIdInteracted) {
    query.set('userIdInteracted', filters.userIdInteracted);
  }

  if (filters.deptTag) {
    query.set('deptTag', filters.deptTag);
  }

  if (filters.categoryTag) {
    query.set('categoryTag', filters.categoryTag);
  }

  if (filters.subCategoryTag) {
    query.set('subCategoryTag', filters.subCategoryTag);
  }

  if (filters.isDev) {
    query.set('isDev', filters.isDev.toString());
  }
  query.set('brand', filters.brand);
  history.push({
    pathname: '/marketing/search-promo',
    search: query.toString(),
  });
  return query;
};

function PromoSearchContainer({ tenant: { brand } }: Props) {
  const query = useQuery();
  const history = useHistory();
  const isDev = query.get('isDev') === 'true';

  const initialFilters = getQueryFilters(query);

  const { promoMeta } = usePromoMeta();

  const { promoCodes, isLoading, fetchData, filters, setFilters, total } = usePromoCodes({
    initialFilters,
  });

  useEffect(() => {
    if (filters.codeName || filters.caseId || filters.userIdInteracted) {
      fetchData(filters);
      setQueryFilters(history, {
        ...filters,
        isDev: filters.isDev,
      });
    }
  }, [filters, fetchData]);

  const expirePromoWithBrand = useCallback(
    async (id: string) => {
      await expirePromo(id, brand);
      await fetchData(filters);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [brand, filters],
  );

  const setCurrentPage = useCallback(
    (page: number, pageSize: number) => {
      const newFilters: GetPromoCodeProps = { ...filters, limit: pageSize, page: page + 1, isDev };
      setQueryFilters(history, newFilters);
      setFilters(newFilters);
      fetchData(newFilters);
    },
    [filters],
  );

  const handleSearch = useCallback(
    (filters: GetPromoCodeProps) => {
      const newFilters = { ...filters, page: 1, isDev };
      setQueryFilters(history, newFilters);
      setFilters(newFilters);
      fetchData(newFilters);
    },
    [fetchData],
  );

  return (
    <div className="promo-codes T-promo-page">
      <Helmet>
        <title>Search Promo Codes</title>
      </Helmet>

      <PageSubheader title={`Search Promo Codes`} />

      <Stack direction="column" gap={4}>
        <PromoSearchForm searchQuery={handleSearch} isLoading={isLoading} />

        {isLoading && <Spinner />}

        {promoCodes !== null && (
          <PromoPage
            promos={promoCodes}
            total={total}
            isLoading={isLoading}
            page={filters.page}
            sizePerPage={filters.limit}
            isDev={filters.isDev}
            onPageChange={setCurrentPage}
            onClick={onClick}
            expirePromo={expirePromoWithBrand}
            promoMeta={promoMeta}
          />
        )}
      </Stack>
    </div>
  );
}

export default withTenant(PromoSearchContainer);
