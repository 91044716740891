import React, { useCallback } from 'react';

import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';

import DeleteIcon from '@mui/icons-material/Delete';
import { Alert, Box, IconButton, Link } from '@mui/material';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';

import AccommodationPropertyStateIndicator from '~/components/Accommodation/Pages/Properties/components/AccommodationPropertyStateIndicator';
import { useAccommodationServiceFetch } from '~/components/Accommodation/hooks/useAccommodationServiceFetch';

import { PropertyResponse, deleteProperty, listProperties } from '~/services/AccommodationService';

import { AccommodationPropertiesSearchInput } from './AccommodationPropertiesSearchForm';

interface Props {
  searchInput: AccommodationPropertiesSearchInput;
  pageIndex?: number;
  onPageChange: (pageIndex: number) => void;
}

const PAGE_SIZE = 20;

function AccommodationPropertiesResultsTable({ searchInput, pageIndex, onPageChange }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const getProperties = useCallback(
    () =>
      listProperties({
        name: searchInput.propertyName,
        id: searchInput.id,
        city: searchInput.city,
        country: searchInput.country,
        rating: searchInput.rating,
        propertyState: searchInput.propertyState || undefined,
        page: String((pageIndex ?? 0) + 1),
        limit: String(PAGE_SIZE),
      }),
    [pageIndex, searchInput],
  );

  const { fetchReqState, refetch } = useAccommodationServiceFetch({
    fetchFn: getProperties,
  });

  const handleDeleteProperty = async (propertyId: string) => {
    if (window.confirm('Are you sure you want to delete this property?')) {
      try {
        await deleteProperty(propertyId);
        enqueueSnackbar('Successfully deleted property', { variant: 'success' });
        refetch(); // Refresh the table after deletion
      } catch (error) {
        enqueueSnackbar('Failed to delete property', { variant: 'error' });
      }
    }
  };

  const columns: Array<GridColDef<PropertyResponse>> = [
    {
      field: 'name',
      headerName: 'Property Name',
      display: 'flex',
      minWidth: 260,
      flex: 1,
      renderCell: (params) => (
        <Link
          href={`/accommodation/properties/${params.row.id}`}
          target="_blank"
          className="accommodation-properties-search-result-link"
        >
          {params.value}
        </Link>
      ),
      hideable: false,
    },
    {
      field: 'propertyState',
      headerName: 'Supplier State',
      maxWidth: 250,
      minWidth: 250,
      display: 'flex',
      hideable: true,
      renderCell: (params) => {
        return <AccommodationPropertyStateIndicator property={params.row} />;
      },
    },
    {
      field: 'location',
      headerName: 'Location',
      minWidth: 350,
      display: 'flex',
      hideable: true,
      renderCell: (params) => {
        return <Box>{[params.row.city, params.row.country].filter((field) => !isEmpty(field)).join(', ')}</Box>;
      },
    },
    {
      field: 'actions',
      headerName: '',
      width: 60,
      sortable: false,
      renderCell: (params) => (
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            handleDeleteProperty(params.row.id);
          }}
          color="error"
          size="small"
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <>
      {fetchReqState.status === 'failed' && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {JSON.stringify(fetchReqState.error)}
        </Alert>
      )}
      <DataGrid
        className="accommodation-properties-search-results-table"
        columns={columns}
        rows={fetchReqState.status === 'succeeded' ? fetchReqState.result.properties : []}
        rowCount={fetchReqState.status === 'succeeded' ? fetchReqState.result.total : undefined}
        getRowHeight={() => 70}
        pageSizeOptions={[PAGE_SIZE]}
        paginationMode="server"
        paginationModel={{ page: pageIndex, pageSize: PAGE_SIZE }}
        onPaginationModelChange={({ page }) => onPageChange(page)}
        loading={fetchReqState.status === 'pending'}
        autoHeight
        disableColumnFilter
        disableDensitySelector
        slots={{
          toolbar: GridToolbar,
        }}
      />
    </>
  );
}

export default AccommodationPropertiesResultsTable;
