import React, { useEffect, useMemo, useState } from 'react';

import Papa from 'papaparse';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { Box, CircularProgress, Modal, Stack } from '@mui/material';

import PriceParrotService from '~/services/PriceParrotService';

import { stringToHexColor } from '~/utils/stringUtils';

type ParsedPriceSummaryCSV = Array<Record<string, number | string>>;

type Props = {
  open: boolean;
  onClose: () => void;
  jobId?: string;
};

const BOX_STYLE = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50vw',
  height: '75vh',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function PriceSummaryPreviewModal({ open, onClose, jobId }: Props) {
  const [data, setData] = useState<ParsedPriceSummaryCSV>([]);
  const [otaColumns, setOtaColumns] = useState<Array<{ name: string; hide: boolean }>>([]);
  const dateColumn = useMemo<string | undefined>(
    () => (data.length ? Object.keys(data[0]).find((key) => key.toLowerCase().includes('date')) : undefined),
    [data],
  );

  useEffect(() => {
    if (!jobId || !open) {
      return;
    }

    (async () => {
      try {
        const response = await PriceParrotService.downloadPriceSummaryJob(jobId);
        const csvText = await response.text();
        const csvData = Papa.parse(csvText, { header: true, dynamicTyping: true }).data as ParsedPriceSummaryCSV;

        setData(csvData);
        setOtaColumns(
          Object.keys(csvData[0])
            .filter((key) => !key.toLowerCase().includes('date'))
            .map((key) => ({ name: key, hide: false })),
        );
      } catch (e) {
        console.error(e);
      }
    })();

    return () => setData([]);
  }, [jobId, open]);

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={BOX_STYLE}>
        {data.length ? (
          <ResponsiveContainer>
            <LineChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey={dateColumn} />
              <YAxis />
              {/* Sort items by value in descending order */}
              <Tooltip itemSorter={(item) => -item.value} />
              <Legend
                layout="vertical"
                align="right"
                verticalAlign="middle"
                wrapperStyle={{
                  paddingLeft: '15px',
                }}
                onClick={(data) =>
                  setOtaColumns((prev) =>
                    prev.map((ota) => (ota.name === data.dataKey ? { ...ota, hide: !ota.hide } : ota)),
                  )
                }
              />
              {otaColumns.map(({ name, hide }) => (
                <Line
                  key={name}
                  type="monotone"
                  dataKey={name}
                  stroke={stringToHexColor(name)}
                  isAnimationActive={false}
                  hide={hide}
                  dot={false}
                />
              ))}
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <Stack alignItems="center" justifyContent="center" height="100%">
            <CircularProgress />
          </Stack>
        )}
      </Box>
    </Modal>
  );
}
