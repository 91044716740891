import React from 'react';

import { List, ListItemButton, ListItemText } from '@mui/material';

import { HOTEL_OFFER_TYPES } from '~/consts/offerTypes';

export default class HoverNav extends React.Component {
  render() {
    return (
      <List sx={{ position: 'fixed', top: 70, width: '154px' }}>
        <ListItemButton component="a" href="#overview">
          <ListItemText primary="Overview" />
        </ListItemButton>
        <ListItemButton component="a" href="#timeline">
          <ListItemText primary="Timeline" />
        </ListItemButton>
        <ListItemButton component="a" href="#packages">
          <ListItemText primary="Packages" />
        </ListItemButton>
        {HOTEL_OFFER_TYPES.includes(this.props.offerType) && (
          <ListItemButton component="a" href="#roomPolicies">
            <ListItemText primary="Room Policy" />
          </ListItemButton>
        )}
        <ListItemButton component="a" href="#images">
          <ListItemText primary="Images &amp; Videos" />
        </ListItemButton>
        <ListItemButton component="a" href="#addons">
          <ListItemText primary="Add-ons" />
        </ListItemButton>
        {this.props.offerType === 'tour' && (
          <ListItemButton component="a" href="#travellerDetailRequirements">
            <ListItemText primary="Requirements" />
          </ListItemButton>
        )}
        {this.props.offerType !== 'tour' && (
          <ListItemButton component="a" href="#experiences">
            <ListItemText primary="Experiences" />
          </ListItemButton>
        )}
      </List>
    );
  }
}
