import React from 'react';

import currencyFormatter from 'currency-formatter';
import { useSelector } from 'react-redux';

import { Box } from '@mui/material';

import { formatDateLong } from '~/services/TimeService';

import { getDisputeBoxStyle, selectShowDisputeProcess } from '../utils/getDisputeProcess';

import Log from './Log';

interface Props {
  giftCardItem: App.GiftCardItem;
  order: App.Order;
}

export default function Item({ giftCardItem, order }: Props) {
  const showDisputeProcess = useSelector(selectShowDisputeProcess);
  const disputeStyle = getDisputeBoxStyle(showDisputeProcess);

  return (
    <Box display="grid" gap={1} gridTemplateColumns="repeat(6, 1fr)">
      <Box
        gridColumn="1 / 5"
        gridRow="span 2"
        sx={{ ...disputeStyle, display: 'grid', gridTemplateRows: '1fr 1fr', gap: 1 }}
      >
        <Box display="grid" gridTemplateColumns="1fr 3fr">
          <Box>
            <strong>Value</strong>
          </Box>
          <Box>
            {currencyFormatter.format(giftCardItem.total, {
              code: order.currency_code,
            })}
          </Box>
        </Box>

        <Box display="grid" gridTemplateColumns="1fr 3fr">
          <Box>
            <strong>Expiry Date</strong>
          </Box>
          <Box>{formatDateLong(giftCardItem.expiry)}</Box>
        </Box>
      </Box>

      <div>
        {currencyFormatter.format(giftCardItem.total, {
          code: order.currency_code,
        })}
      </div>

      <div> {order.status === 'completed' ? 'Paid' : ''}</div>

      <Box gridColumn="5 / 7" sx={{ visibility: 'hidden' }}></Box>

      <Box gridColumn="span 6">
        <Log item={giftCardItem} order={order} />
      </Box>
    </Box>
  );
}
