import React from 'react';

import { Typography } from '@mui/material';

import { LUX_LOYALTY_PROGRAM_CONFIG, LUX_LOYALTY_TIERS_CONFIG } from '@luxuryescapes/lib-lux-loyalty';

interface Props extends React.ComponentProps<typeof Typography> {
  tier: App.LuxLoyaltyTier;
}

interface TierConfig {
  gradient: string;
  contrast: string;
}

const TIER_COLOURS: Record<App.LuxLoyaltyTier, TierConfig> = {
  bronze: {
    gradient: 'linear-gradient(45.05deg, #D4B5A4 0.73%, #F8F3F2 45.77%, #D4B5A4 74.49%)',
    contrast: '#000000',
  },
  silver: {
    gradient: 'linear-gradient(45.05deg, #DBDBDB 0.73%, #F2F2F2 45.77%, #DBDBDB 74.49%)',
    contrast: '#000000',
  },
  gold: {
    gradient: 'linear-gradient(45.05deg, #C8BF97 0.73%, #F3EFD5 45.77%, #C8BF97 74.49%)',
    contrast: '#000000',
  },
  platinum: {
    gradient: 'linear-gradient(45.05deg, #3D3B3B 0.73%, #939393 45.77%, #3D3B3B 74.49%);',
    contrast: '#FFFFFF',
  },
};

function LuxLoyaltyAccountChip({ tier, ...rest }: Props) {
  return (
    <Typography
      {...rest}
      component="span"
      sx={{
        padding: '4px 8px',
        borderRadius: '4px',
        background: TIER_COLOURS[tier]?.gradient,
        color: TIER_COLOURS[tier]?.contrast,
        fontWeight: 500,
        fontSize: '1.25rem',
      }}
    >
      {LUX_LOYALTY_PROGRAM_CONFIG.programDisplayName} {LUX_LOYALTY_TIERS_CONFIG[tier].displayName} Member
    </Typography>
  );
}

export default LuxLoyaltyAccountChip;
