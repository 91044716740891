import React, { useCallback, useState } from 'react';

import { theme } from '~/theme';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Stack, Typography } from '@mui/material';

import { Experiences } from '@luxuryescapes/contract-svc-experience';

import AIGeneratorButton from '~/components/AIGenerator/AIGenerateButton';
import MarkdownEditor from '~/components/Common/Forms/widgets/MarkdownEditor';
import { useUpdateExperienceValues } from '~/components/Experiences/hooks';

import { ORIGINAL_EXPERIENCE_CONTENT_TAB } from '~/consts/experiences';

function ExperienceCurationAdditionalInformation() {
  const { updateValues, values, contentTab } = useUpdateExperienceValues();

  const areOriginalValues = contentTab === ORIGINAL_EXPERIENCE_CONTENT_TAB;

  // TODO: below code is just a workaround to force the markdown editor to update when the value is changed
  // We must implement a proper form handler and replace MarkdownEditor with OverridableRichText/RichTextEditor
  const [dealDescriptionKey, setDealDescriptionKey] = useState(`dealDescription-${new Date().getTime()}`);
  const [inclusionsKey, setInclusionsKey] = useState(`inclusions-${new Date().getTime()}`);
  const applyAIContent = useCallback(
    (key: string, value: string) => {
      updateValues({ [key]: value });
      if (key === 'dealDescription') {
        setDealDescriptionKey(`dealDescription-${new Date().getTime()}`);
      } else if (key === 'included') {
        setInclusionsKey(`inclusions-${new Date().getTime()}`);
      }
    },
    [updateValues],
  );

  return (
    <>
      {values && (
        <Stack spacing={2}>
          <Accordion id="additional-information" defaultExpanded disableGutters>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="offer-fields"
              id="offer-fields"
              sx={{
                backgroundColor: theme.palette.grey[200],
              }}
            >
              <Typography variant="h5">Additional Information</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack mt={2} spacing={4}>
                <Stack spacing={2}>
                  <Box>
                    <MarkdownEditor
                      key={dealDescriptionKey}
                      value={values.dealDescription}
                      onChange={(value) => updateValues({ dealDescription: value })}
                      disabled={areOriginalValues}
                    />
                  </Box>
                  <AIGeneratorButton
                    context={{
                      model: 'experience',
                      field: 'description',
                      content: values.dealDescription,
                    }}
                    buttonLabel={values.dealDescription ? 'Rewrite Deal Description' : 'Generate Deal Description'}
                    onChoose={(value) => applyAIContent('dealDescription', value)}
                  />
                </Stack>
                <Box>
                  <Typography variant="body1">Short Description</Typography>
                  <MarkdownEditor
                    value={values.shortDescription}
                    onChange={(value) => updateValues({ shortDescription: value })}
                    disabled={areOriginalValues}
                  />
                </Box>
                <Stack spacing={2}>
                  <Box>
                    <Typography variant="body1">Inclusions</Typography>
                    <MarkdownEditor
                      key={inclusionsKey}
                      value={values.included}
                      onChange={(value) => updateValues({ included: value })}
                      disabled={areOriginalValues}
                    />
                  </Box>
                  <AIGeneratorButton
                    context={{
                      model: 'experience',
                      field: 'inclusions',
                      content: values.included,
                    }}
                    buttonLabel={values.included ? 'Rewrite Inclusions' : 'Generate Inclusions'}
                    onChoose={(value) => applyAIContent('included', value)}
                  />
                </Stack>
              </Stack>
            </AccordionDetails>
          </Accordion>

          <Accordion id="thingsToKnow" defaultExpanded disableGutters>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="things-to-know-fields"
              id="things-to-know-fields"
              sx={{
                backgroundColor: theme.palette.grey[200],
              }}
            >
              <Typography variant="h5">Things To Know</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Stack mt={2} spacing={4}>
                {Object.entries(values.thingsToKnowNew).map(
                  ([key, item]: [keyof Experiences.ThingsToKnow, Experiences.ThingsToKnowItem], index) =>
                    (key !== 'cancellationPolicy' || !values.isNonRefundable) && (
                      <Stack spacing={1} key={key}>
                        <Box>
                          <Typography variant="body1">{item.label}</Typography>
                          <MarkdownEditor
                            key={contentTab}
                            value={item.value}
                            onChange={(value) => {
                              updateValues({
                                thingsToKnowNew: {
                                  ...values.thingsToKnowNew,
                                  [key]: {
                                    ...item,
                                    value,
                                  },
                                },
                              });
                            }}
                            disabled={areOriginalValues}
                          />
                        </Box>
                      </Stack>
                    ),
                )}
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Stack>
      )}
    </>
  );
}

export default ExperienceCurationAdditionalInformation;
