import React from 'react';

import { Done } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

function PromoLogResultsFormatter(row) {
  const logResults = row?.details?.logResults;

  if (logResults == undefined) {
    return null;
  }

  if (Math.abs(Number(logResults?.promoLogThresholdDifference ?? 0)) < 0.5) {
    return (
      <Box title={JSON.stringify(logResults, null, 4)}>
        <Done />
      </Box>
    );
  }

  return (
    <Box title={JSON.stringify(logResults, null, 4)}>
      <Typography>
        <Typography>{logResults?.clientSideAmount?.toFixed(2)}</Typography>
        {' != '}
        <Typography>{logResults?.serverSideAmount?.toFixed(2)}</Typography>
      </Typography>
      <Typography>{logResults?.promoLogThresholdDifference?.toFixed(2)}%</Typography>
      {row.details.bypass_log_validation_check && (
        <Typography variant="body2" color="error">
          (Bypassed Log Validation)
        </Typography>
      )}
    </Box>
  );
}
export default PromoLogResultsFormatter;
