import React from 'react';

import { Alert, Typography } from '@mui/material';

import { definitions } from '@luxuryescapes/contract-svc-promo';

import { productTypesExpectedFields } from '~/services/PromoService';

type DisplayField = 'productType';

type PromoItemFieldProps = {
  field: keyof definitions['Discount Request']['order']['items']['0'] | DisplayField;
  categoryBK: definitions['Discount Request']['order']['items']['0']['categoryBK'];
  title: string;
  value: string | number | null | object | Array<object>;
};

export const isExpectedField = (
  category: definitions['Discount Request']['order']['items']['0']['categoryBK'],
  field: keyof definitions['Discount Request']['order']['items']['0'] | DisplayField,
) => {
  const match = productTypesExpectedFields[category];

  if (!match) {
    return false;
  }

  return match[field];
};

function PromoItemField({ field, categoryBK, value, title }: PromoItemFieldProps) {
  if (!isExpectedField(categoryBK, field)) {
    return null;
  }

  if (value == null || value === '') {
    return <Alert severity="warning">{`${field} not available`}</Alert>;
  }

  return (
    <Typography>
      <Typography component="span" fontWeight="bold">
        {title}:
      </Typography>
      {value}
    </Typography>
  );
}

export default PromoItemField;
