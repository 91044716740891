import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useParams } from 'react-router';

import { Box } from '@mui/material';

import Spinner from '~/components/Common/Spinner';
import PageBody from '~/components/Page/PageBody';
import PageHeaderBar from '~/components/Page/PageHeaderBar';
import PageLayout from '~/components/Page/PageLayout';
import PageSidebar from '~/components/Page/PageSidebar';

import { getOrderDetail } from '~/services/OrdersService';
import UsersService from '~/services/UsersService';

import { UserType } from '~/types/responses';

// eslint-disable-next-line import/no-named-as-default
import OrderDetailPageContainer from './OrderDetailPageContainer';
import OrderDetailsHeaderActions from './OrderDetailsHeaderActions';
import OrderDetailsHeaderTitle from './OrderDetailsHeaderTitle';
import OrderDetailsSidebar from './OrderDetailsSidebar';

export default function OrderDetailContainerV2() {
  const [order, setOrder] = useState<any>(undefined);
  const [customer, setCustomer] = useState<undefined | UserType>(undefined);
  const [isReady, setIsReady] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { id_orders: orderId } = useParams<{ id_orders: string }>();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchData = async () => {
      await refreshData();
      setIsReady(true);
    };
    fetchData();
  }, []);

  const refreshData = async () => {
    setIsReady(false);
    setIsLoading(true);
    let order = undefined;
    try {
      order = await getOrderDetail(orderId);
      if (order?.fk_customer_id) {
        const customer = await UsersService.getUser(order.fk_customer_id);
        setCustomer(customer);
      }
      setOrder(order);
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <PageLayout>
        <PageHeaderBar title="Order Details" />
        <PageBody>
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
            <Spinner />
          </Box>
        </PageBody>
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <PageHeaderBar
        title={<OrderDetailsHeaderTitle order={order} customer={customer} />}
        actions={<OrderDetailsHeaderActions order={order} />}
      />
      <PageSidebar placement="start" width="medium">
        <OrderDetailsSidebar order={order} customer={customer} />
      </PageSidebar>
      <PageBody>
        {!isReady ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
            <Spinner />
          </Box>
        ) : (
          <OrderDetailPageContainer match={{ params: { id_orders: orderId } }} />
        )}
      </PageBody>
    </PageLayout>
  );
}
