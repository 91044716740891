import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';

import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';

import Spinner from '~/components/Common/Spinner';
import { CruiseInclusionItem } from '~/components/Cruises/pages/Inclusions/types';

import inclusionsService from '~/services/cruises/InclusionsService';

const TYPES: { [key: string]: CruiseInclusionItem['type'] } = {
  FREE_TEXT: 'FREE_TEXT',
  ONBOARD_CREDIT: 'ONBOARD_CREDIT',
};

const INITIAL_FORM_STATE: Partial<CruiseInclusionItem> = {
  description: '',
  type: TYPES.ONBOARD_CREDIT,
  creditCurrency: undefined,
  creditAmount: 0,
};

type Props = {
  inclusionId: string;
  inclusionItem?: CruiseInclusionItem;
  onSave: () => void;
};

function InclusionItemForm({ inclusionId, inclusionItem, onSave }: Props) {
  const history = useHistory();
  const [formValues, setFormValues] = useState<Partial<CruiseInclusionItem>>(INITIAL_FORM_STATE);
  const [status, setStatus] = useState<string>();
  const { enqueueSnackbar } = useSnackbar();

  const handleChangeField = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.persist();
      setFormValues({
        ...formValues,
        [event.target.name]: event.target.value ?? undefined,
      });
    },
    [formValues],
  );

  const handleChangeNumberField = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.persist();
      setFormValues({
        ...formValues,
        [event.target.name]: parseFloat(event.target.value),
      });
    },
    [formValues],
  );

  const handleSelectChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target) {
        setFormValues({
          ...formValues,
          [event.target.name]: event.target.value,
        });
      }
    },
    [formValues],
  );

  const handleChangeTab = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    setFormValues({
      ...INITIAL_FORM_STATE,
      [event.target.name]: event.target.value ?? undefined,
    });
  }, []);

  const handleSubmit = useCallback(async () => {
    const isEditing = !!inclusionItem;
    setStatus('loading');

    try {
      if (isEditing) {
        await inclusionsService.updateItem(inclusionId, inclusionItem.id, formValues);
      } else {
        await inclusionsService.createItem(inclusionId, formValues);
      }

      setStatus('success');
      enqueueSnackbar('Inclusion Item saved with success', { variant: 'success' });
      onSave();
      history.push(`/cruises/inclusions/edit/${inclusionId}/items`);
    } catch (error) {
      console.error(error);
      setStatus('error');
      enqueueSnackbar('Error saving Inclusion Item', { variant: 'error' });
    }
  }, [formValues, history, enqueueSnackbar, inclusionId, inclusionItem, onSave]);

  const handleCancel = useCallback(() => {
    history.push(`/cruises/inclusions/edit/${inclusionId}/items`);
  }, [history, inclusionId]);

  const isSaveEnabled = useMemo(() => {
    const isLoading = status === 'loading';
    if (formValues.type === TYPES.ONBOARD_CREDIT) {
      return !isLoading && formValues.creditCurrency && formValues.creditAmount;
    }

    return !isLoading && formValues.description;
  }, [formValues, status]);

  useEffect(() => {
    if (inclusionItem) {
      setFormValues({
        type: inclusionItem.type,
        description: inclusionItem.description,
        creditCurrency: inclusionItem.creditCurrency,
        creditAmount: inclusionItem.creditAmount,
      });
    }
  }, [inclusionItem]);

  return (
    <Box>
      <Box>
        <FormControl>
          <RadioGroup defaultValue={TYPES.FREE_TEXT} name="type" value={formValues.type} onChange={handleChangeTab} row>
            <FormControlLabel value={TYPES.ONBOARD_CREDIT} control={<Radio />} label="Onboard Credit" />
            <FormControlLabel value={TYPES.FREE_TEXT} control={<Radio />} label="Free Text" />
          </RadioGroup>
        </FormControl>
      </Box>

      {formValues.type === TYPES.ONBOARD_CREDIT && (
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Box mt={2}>
              <FormControl fullWidth>
                <TextField
                  select
                  name="creditCurrency"
                  label="Currency"
                  onChange={handleSelectChange}
                  value={formValues.creditCurrency}
                  key={formValues.creditCurrency}
                >
                  <MenuItem value="AUD">AUD</MenuItem>
                  <MenuItem value="USD">USD</MenuItem>
                </TextField>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={9}>
            <Box mt={2}>
              <TextField
                fullWidth
                type="number"
                name="creditAmount"
                label="Amount"
                onChange={handleChangeNumberField}
                value={formValues.creditAmount}
              />
            </Box>
          </Grid>
        </Grid>
      )}

      {formValues.type === TYPES.FREE_TEXT && (
        <Box mt={2}>
          <TextField
            required
            fullWidth
            name="description"
            label="Description"
            onChange={handleChangeField}
            inputProps={{ maxLength: 50 }}
            value={formValues.description}
            helperText="The maximum length is 50 characters."
          />
        </Box>
      )}

      <Box mt={3} gap={1} display="flex" justifyContent="end">
        <Button variant="outlined" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit} disabled={!isSaveEnabled}>
          {status === 'loading' && <Spinner size={15} />}
          Save Changes
        </Button>
      </Box>
    </Box>
  );
}

export default InclusionItemForm;
