import React, { useCallback } from 'react';

import { Check, Close } from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from '@mui/material';

import { useAccommodationServiceFetch } from '~/components/Accommodation/hooks/useAccommodationServiceFetch';
import DefinitionText from '~/components/Common/Elements/DefinitionText';
import DefinitionTitle from '~/components/Common/Elements/DefinitionTitle';

import { getPotentialMappingDetails } from '~/services/AccommodationService';

import AccommodationVerifyPropertyDetailsCard from './AccommodationVerifyPropertyDetailsCard';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  propertyId: string;
  mappedPropertyId: string;
  handleVerify: () => void;
  handleReject: () => void;
}

export default function AccommodationVerifyMappingModal({
  isOpen,
  closeModal,
  handleVerify,
  handleReject,
  propertyId,
  mappedPropertyId,
}: Props) {
  const fetchPotentialMappingDetails = useCallback(
    () => getPotentialMappingDetails({ propertyId, mappedPropertyId }),
    [propertyId, mappedPropertyId],
  );

  const { fetchReqState, refetch } = useAccommodationServiceFetch({
    fetchFn: fetchPotentialMappingDetails,
  });

  if (fetchReqState.status === 'failed') {
    return (
      <Alert
        severity="error"
        action={
          <Button color="inherit" variant="outlined" onClick={refetch}>
            Retry
          </Button>
        }
      >
        <AlertTitle>Failed to fetch the details.</AlertTitle>
        {JSON.stringify(fetchReqState.error)}
      </Alert>
    );
  }

  return (
    <Dialog
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            maxWidth: '100%',
          },
        },
      }}
      open={isOpen}
      onClose={() => closeModal()}
    >
      {isOpen && (
        <>
          {fetchReqState.status === 'pending' ||
            (fetchReqState.status === 'uninitiated' && (
              <Box p={2} display="flex" justifyContent="center">
                <CircularProgress variant="indeterminate" />
              </Box>
            ))}
          {fetchReqState.status === 'succeeded' && (
            <>
              <DialogTitle>Verify Mapping</DialogTitle>
              <DialogContent>
                <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2}>
                  <Stack gap={2}>
                    <DefinitionTitle>Existing</DefinitionTitle>
                    {fetchReqState.result.existing.map((existing) => (
                      <AccommodationVerifyPropertyDetailsCard key={existing.id} details={existing} />
                    ))}
                  </Stack>
                  <Stack gap={2}>
                    <DefinitionTitle>Potential</DefinitionTitle>
                    <AccommodationVerifyPropertyDetailsCard
                      key={fetchReqState.result.potential.id}
                      details={fetchReqState.result.potential}
                    />
                  </Stack>
                </Box>
                <Stack gap={2}>
                  <Box>
                    <DefinitionTitle>Name Score</DefinitionTitle>
                    <DefinitionText>{(fetchReqState.result.nameScore * 100).toFixed(2)}%</DefinitionText>
                  </Box>

                  <Box>
                    <DefinitionTitle>Distance</DefinitionTitle>
                    <DefinitionText>{fetchReqState.result.distance}m</DefinitionText>
                  </Box>
                </Stack>
              </DialogContent>
              <DialogActions>
                <Stack direction="row" spacing={1} justifyContent="center">
                  <IconButton onClick={handleReject} color="error" size="small" title="Reject mapping">
                    <Close />
                  </IconButton>
                  <IconButton onClick={handleVerify} color="success" size="small" title="Verify mapping">
                    <Check />
                  </IconButton>
                </Stack>
              </DialogActions>
            </>
          )}
        </>
      )}
    </Dialog>
  );
}
