import React from 'react';

import { Card, CardContent } from '@mui/material';

import DefinitionText from '~/components/Common/Elements/DefinitionText';
import DefinitionTitle from '~/components/Common/Elements/DefinitionTitle';

import { PotentialMappingPropertyDetail } from '~/services/AccommodationService';

interface Props {
  details: PotentialMappingPropertyDetail;
}

export default function AccommodationVerifyPropertyDetailsCard({ details }: Props) {
  return (
    <Card variant="outlined">
      <CardContent>
        <DefinitionTitle>Name</DefinitionTitle>
        <DefinitionText>{details.name}</DefinitionText>
        <DefinitionTitle>Address</DefinitionTitle>
        <DefinitionText>{details.address}</DefinitionText>
        <DefinitionTitle>State</DefinitionTitle>
        <DefinitionText>{details.state}</DefinitionText>
        <DefinitionTitle>Country</DefinitionTitle>
        <DefinitionText>{details.country}</DefinitionText>
      </CardContent>
    </Card>
  );
}
