import { useCallback, useEffect, useState } from 'react';

import { GetFlightDealsData, getFlightDeals } from '~/services/FlightsService';

import { FLIGHT_DEALS_LIST_ATTRIBUTES } from '../constants';

const PAGE_SIZE = 20;

const initialOptions = {
  page: 0,
  pageSize: PAGE_SIZE,
  attributes: FLIGHT_DEALS_LIST_ATTRIBUTES,
};

export function useFlightDeals(options: GetFlightDealsData = initialOptions) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [data, setData] = useState<{ rows: Array<App.FlightDeal>; count: number }>({ rows: [], count: 0 });

  const fetchDealsData = useCallback(async () => {
    setLoading(true);

    try {
      const { result } = await getFlightDeals(options);
      setData({ count: result.count, rows: result.rows });
    } catch (error) {
      setError(error);
    }

    setLoading(false);
  }, [setLoading, setData, setError, options]);

  useEffect(() => {
    fetchDealsData();
  }, [fetchDealsData]);

  return { loading, error, data, revalidate: fetchDealsData };
}
