import React, { ChangeEvent, Reducer, useEffect, useMemo, useReducer, useState } from 'react';

import sortBy from 'lodash/sortBy';
import { arrayMove } from 'react-sortable-hoc';
import { TagsAction, TagsState, fetchTagsReducer, initialTagsState } from '~/reducers/marketing';

import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import { getRegionNamesAndCode } from '@luxuryescapes/lib-regions';

import PageSubheader from '~/components/Common/Elements/PageSubheader';
import { Badge, Spinner } from '~/components/Experiences/components';
import { getPreviewURL } from '~/components/Experiences/helpers';
import { useUpdateExperienceValues } from '~/components/Experiences/hooks';

import {
  EXP_PROVIDER_DERBYSOFT,
  EXP_PROVIDER_LED,
  EXP_PROVIDER_REZDY,
  ORIGINAL_EXPERIENCE_CONTENT_TAB,
  getStatusByCurated,
} from '~/consts/experiences';
import { TAG_TYPE_CAMPAIGN } from '~/consts/tags';

import { CurateExperienceParams, CurationStatus, ExperienceOffer } from '~/services/ExperiencesService';
import offersService from '~/services/OffersService';

import ExperienceCurationImages from './ExperienceCurationImages';
import ExperienceCurationOverview from './ExperienceCurationOverview';

interface Props {
  experience: ExperienceOffer;
  loading: boolean;
  tenant: App.Tenant;
  createExperience: ({ curationStatus }: CurateExperienceParams) => Promise<void>;
}

type CurationStatusProps = {
  label: string;
  value: CurationStatus | undefined;
};

const SELECT_ALL_OPTION = 'All';

export default function ExperienceCurationOptions(props: Props) {
  const [images, setImages] = useState([]);
  const [imageHero, setImageHero] = useState(null);
  const [imagesToRemove, setImagesToRemove] = useState([]);
  const [selectedCampaigns, setSelectedCampaigns] = useState<string[]>([]);
  const [selectedExcludedRegions, setSelectedExcludedRegions] = useState<string[]>([]);
  const [editMultiSelect, setEditMultiSelect] = useState({
    campaigns: false,
    regions: false,
  });
  const [curationStatusValue, setCurationStatus] = useState<CurationStatusProps>({
    label: 'Not Curated',
    value: 'NOT_CURATED',
  });
  const [showCommissionMarginField, setShowCommissionMarginField] = useState<boolean>(false);

  const { updateValues, values, payload, contentTab } = useUpdateExperienceValues();

  const { experience, createExperience, tenant } = props;

  const areOriginalValues = contentTab === ORIGINAL_EXPERIENCE_CONTENT_TAB;

  const statusLabel = useMemo(
    () => ({
      APPROVED: 'Approved',
      REJECTED: 'Rejected',
      NOT_CURATED: 'Not Curated',
    }),
    [],
  );

  useEffect(() => {
    if (experience) {
      const { campaigns = [], excludedRegions = [], images = [] } = experience;

      if (campaigns.length) {
        setSelectedCampaigns(campaigns);
        setEditMultiSelect((prev) => ({ ...prev, campaigns: true }));
      }

      if (excludedRegions.length) {
        setSelectedExcludedRegions(excludedRegions.map((region) => region.regionCode));
        setEditMultiSelect((prev) => ({ ...prev, regions: true }));
      }

      if (experience?.curationStatus) {
        setCurationStatus({
          label: statusLabel[experience.curationStatus],
          value: experience.curationStatus,
        });
      }

      if (images.length) {
        setImages(images);
      }

      if (experience.commissionMargin) setShowCommissionMarginField(true);
    }
  }, [experience, payload?.categories, statusLabel]);

  const [campaigns, campaignsDispatch] = useReducer<Reducer<TagsState, TagsAction>>(fetchTagsReducer, initialTagsState);

  const fetchCampaigns = async (): Promise<void> => {
    campaignsDispatch({ type: 'LOAD' });
    try {
      const res = await offersService.getTags(TAG_TYPE_CAMPAIGN);
      campaignsDispatch({
        type: 'FETCH',
        payload: sortBy(res.result, ['name']),
      });
    } catch (error) {
      campaignsDispatch({ type: 'ERROR', message: error.message });
    }
  };

  useEffect(() => {
    if (!areOriginalValues) {
      fetchCampaigns();
    }
  }, [areOriginalValues]);

  const handleSelectedCampaigns = (campaigns) => {
    setSelectedCampaigns(campaigns);
    updateValues({ campaigns });
  };

  const handleSelectedExcludedRegions = (excludedRegions) => {
    setSelectedExcludedRegions(excludedRegions);
    updateValues({
      excludedRegions: excludedRegions.map((region) => ({
        regionCode: region,
      })),
    });
  };

  const campaignsOptions = useMemo(
    () =>
      campaigns.tags.map((campaign) => ({
        label: campaign.name,
        value: campaign.name,
      })),
    [campaigns.tags],
  );

  const excludedRegionOptions = useMemo(
    () =>
      getRegionNamesAndCode(tenant.brand).map((region) => ({
        label: region.name,
        value: region.code,
      })),
    [tenant.brand],
  );

  const curationStatus = [
    { label: 'Approved', value: 'APPROVED' },
    { label: 'Rejected', value: 'REJECTED' },
    { label: 'Not Curated', value: 'NOT_CURATED' },
  ];

  const status = getStatusByCurated(experience?.curationStatus);

  const handleSetImageHero = (imageId: number | string) => {
    setImageHero(imageId);
    updateValues({ heroImageId: imageId?.toString() });
  };

  const handleVendorBookingCutoffChange = (event) => {
    const value = parseInt(event.target.value || 0);
    updateValues({ vendorBookingCutoff: value });
  };

  const handleMarginChange = (event) => {
    const value = event.target.value ? parseInt(event.target.value) : null;
    updateValues({ margin: value });
  };

  const handleCommissionMarginChange = (event) => {
    const value = event.target.value ? parseInt(event.target.value) : null;
    updateValues({ commissionMargin: value });
  };

  const handleShowCommissionMarginField = (event) => {
    setShowCommissionMarginField(event.target.checked);
    if (!event.target.checked) updateValues({ commissionMargin: null });
  };

  const handleBookingCancellationCutoffChange = (event) => {
    const value = parseInt(event.target.value || 0);
    const cancellationInfo = payload?.cancellationInfo ?? experience?.cancellationInfo;
    cancellationInfo.daysCutoff = value;
    updateValues({ cancellationInfo });
  };

  const onFileChange = (event: ChangeEvent<HTMLInputElement>) =>
    updateValues({ imagesToUpload: Array.from(event.target.files) });

  const handleDeleteImage = (imageId) => {
    let updatedImagesToRemove = [];
    const includes = imagesToRemove.includes(imageId);

    if (includes) {
      updatedImagesToRemove = imagesToRemove.filter((imageToRemove) => imageToRemove !== imageId);
    } else {
      updatedImagesToRemove = [...imagesToRemove, imageId];
    }

    updateValues({ imagesToRemove: updatedImagesToRemove });
    setImagesToRemove(updatedImagesToRemove);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const movedImages = arrayMove(images, oldIndex, newIndex);
    setImages(movedImages);
    updateValues({ images: movedImages });
  };

  const isRezdy = experience?.provider === EXP_PROVIDER_REZDY;
  const isLED = experience?.provider === EXP_PROVIDER_LED;
  const isDerbysoft = experience?.provider === EXP_PROVIDER_DERBYSOFT;

  const enableImagesUpload = isRezdy || isDerbysoft;

  const hasImage = !!experience?.images.length || !!payload?.imagesToUpload?.length;

  const transferEnabled = !!values?.features?.transfer;

  const hasPriceOverridden = values?.tickets?.some((ticket) => ticket.margin);

  const handleOverriddenFields = (isOverwriting: boolean, key: string) => {
    const index = values.overriddenFields.indexOf(key);
    if (isOverwriting && index < 0) {
      values.overriddenFields.push(key);
    } else if (!isOverwriting && index >= 0) {
      values.overriddenFields.splice(index, 1);
    }
    updateValues({ overriddenFields: values.overriddenFields });
  };

  return (
    <Stack direction="column" spacing={2}>
      <PageSubheader title={experience?.title}>
        <Badge title={status?.title} background={status?.color} />
      </PageSubheader>

      <Box>
        <Button
          variant="contained"
          disabled={!hasImage || areOriginalValues}
          onClick={() => createExperience({ curationStatus: values?.curationStatus })}
        >
          Save
        </Button>
      </Box>

      <Box maxWidth="40%" display="grid" gap={2} alignItems="center" gridTemplateColumns="repeat(3, 1fr)">
        <Typography fontWeight="bold" fontSize={16}>
          Curation Status:
        </Typography>
        <Box gridColumn="span 2">
          <FormControl fullWidth>
            <InputLabel id="curation-status-label">Curation status</InputLabel>
            <Select
              labelId="curation-status-label"
              id="curation-status"
              label="Curation status"
              value={curationStatusValue.value}
              onChange={(e) => {
                const value = e.target.value;

                setCurationStatus({
                  label: curationStatus.find((status) => status.value === value).label,
                  value,
                });
                updateValues({ curationStatus: value });
              }}
            >
              {curationStatus.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Typography fontWeight="bold" fontSize={16}>
          Preview:
        </Typography>
        <Box gridColumn="span 2">
          <Button variant="outlined" href={getPreviewURL(tenant.value, experience?.id)} target="_blank">
            {tenant.title}
          </Button>
        </Box>
      </Box>

      <Box>
        <Button variant="outlined" onClick={() => window.close()}>
          Cancel
        </Button>
      </Box>

      <Box>
        <Typography fontWeight="bold" fontSize={16}>
          Images:
        </Typography>

        <Box>
          <ExperienceCurationImages
            handleSetImageHero={handleSetImageHero}
            handleDeleteImage={handleDeleteImage}
            imagesToRemove={imagesToRemove}
            onSortEnd={onSortEnd}
            imageHero={imageHero}
            items={images}
          />
          {enableImagesUpload && (
            <Box>
              <Typography fontWeight="bold" fontSize={16}>
                Upload new images:
              </Typography>

              <TextField
                inputProps={{ accept: 'image/*', multiple: true }}
                type="file"
                onChange={onFileChange}
                disabled={areOriginalValues}
              />
            </Box>
          )}
        </Box>
      </Box>

      <Stack direction="row" spacing={2} justifyContent="flex-start" alignItems="center">
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={editMultiSelect.campaigns}
                onChange={(e) =>
                  setEditMultiSelect({
                    ...editMultiSelect,
                    campaigns: e.target.checked,
                  })
                }
                disabled={areOriginalValues}
              />
            }
            label="Add campaign tag:"
          />
        </FormGroup>

        {campaigns.loading && <Spinner size={25} />}
        {campaigns.error && <Alert severity="error">{campaigns.message}</Alert>}

        {!campaigns.loading && !campaigns.error && (
          <Box flexGrow={1}>
            <Autocomplete
              multiple
              disableCloseOnSelect
              disabled={!editMultiSelect.campaigns}
              options={[SELECT_ALL_OPTION, ...campaignsOptions.map((option) => option.value)]}
              limitTags={7}
              onChange={(_, values) => {
                if (values.filter((item) => item === SELECT_ALL_OPTION).length === 1) {
                  handleSelectedCampaigns(campaignsOptions);
                } else {
                  handleSelectedCampaigns(values);
                }
              }}
              value={selectedCampaigns}
              renderInput={(params) => <TextField {...params} label="Select tags" />}
            />
          </Box>
        )}
      </Stack>

      <Stack direction="row" spacing={2} justifyContent="flex-start" alignItems="center ">
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={editMultiSelect.regions}
                onChange={(e) =>
                  setEditMultiSelect({
                    ...editMultiSelect,
                    regions: e.target.checked,
                  })
                }
                disabled={areOriginalValues}
              />
            }
            label="Add excluded region:"
          />
        </FormGroup>

        <Box flexGrow={1}>
          <Autocomplete
            multiple
            disableCloseOnSelect
            disabled={!editMultiSelect.regions}
            options={[SELECT_ALL_OPTION, ...excludedRegionOptions.map((option) => option.value)]}
            limitTags={7}
            onChange={(_, values) => {
              if (values.filter((item) => item === SELECT_ALL_OPTION).length === 1) {
                handleSelectedExcludedRegions(excludedRegionOptions.map((option) => option.value));
              } else {
                handleSelectedExcludedRegions(values);
              }
            }}
            value={selectedExcludedRegions}
            renderInput={(params) => <TextField {...params} label="Select regions" />}
            getOptionLabel={(option) =>
              excludedRegionOptions.find((region) => region.value === option)?.label || option
            }
          />
        </Box>
      </Stack>

      <Stack direction="row" spacing={2} justifyContent="flex-start" alignItems="center">
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={values?.overriddenFields?.includes('ticketUnitLabel') ?? false}
                onChange={(e) => handleOverriddenFields(e.target.checked, 'ticketUnitLabel')}
                disabled={areOriginalValues}
              />
            }
            label="Override ticket unit label:"
          />
        </FormGroup>

        <Box flexGrow={1}>
          <TextField
            onChange={(e) => updateValues({ ticketUnitLabel: e.target.value })}
            value={values?.ticketUnitLabel ?? ''}
            disabled={areOriginalValues || !values?.overriddenFields?.includes('ticketUnitLabel')}
          />
        </Box>
      </Stack>

      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={values?.hideTimeSlots ?? false}
              onChange={(e) => updateValues({ hideTimeSlots: e.target.checked })}
              disabled={areOriginalValues}
            />
          }
          label="Hide time slots"
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={values?.isNonRefundable ?? false}
              disabled={areOriginalValues}
              onChange={(e) => updateValues({ isNonRefundable: e.target.checked })}
            />
          }
          label="Non-refundable"
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={values?.allowBuyNowBookLater ?? false}
              onChange={(e) => updateValues({ allowBuyNowBookLater: e.target.checked })}
              disabled={areOriginalValues || transferEnabled || isRezdy || isDerbysoft}
            />
          }
          label={
            transferEnabled
              ? 'Allow Buy Now Book Later / Gifting (You cannot enable it if you have allowed Transfer)'
              : 'Allow Buy Now Book Later / Gifting'
          }
        />

        {transferEnabled && (
          <FormControlLabel
            control={
              <Checkbox
                checked={!!values?.features?.transfer?.preferredTransfer}
                onChange={(e) =>
                  updateValues({
                    features: {
                      ...values.features,
                      transfer: { ...values.features.transfer, preferredTransfer: e.target.checked },
                    },
                  })
                }
              />
            }
            label="Preferred transfer (one per region)"
          />
        )}

        {isLED && (
          <FormControlLabel
            control={
              <Checkbox
                checked={values?.ticketed ?? false}
                disabled={areOriginalValues}
                onChange={(e) => updateValues({ ticketed: e.target.checked })}
              />
            }
            label="Ticketed Experience"
          />
        )}

        <FormControlLabel control={<Checkbox checked={hasPriceOverridden} disabled />} label="Price Overridden" />
      </FormGroup>

      <Box>
        <TextField
          label="Booking Cancellation Cutoff (Amount in days)"
          type="number"
          onChange={handleBookingCancellationCutoffChange}
          value={payload?.cancellationInfo?.daysCutoff || experience?.cancellationInfo?.daysCutoff || 0}
          required
          fullWidth
        />
      </Box>

      <Box>
        <TextField
          label="Vendor Booking Cutoff (Amount in hours)"
          type="number"
          onChange={handleVendorBookingCutoffChange}
          value={payload?.vendorBookingCutoff || experience?.vendorBookingCutoff || 0}
          required
          fullWidth
        />
      </Box>

      <Box>
        <TextField
          label="Profit Margin %"
          name="margin"
          onChange={handleMarginChange}
          value={values?.margin ?? 0}
          disabled={areOriginalValues}
          type="number"
          inputProps={{ max: 100 }}
          helperText="Profit margin calculated based on the sales price. This margin affects the sales price of the deal."
          fullWidth
        />
      </Box>

      {!!isRezdy && (
        <>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showCommissionMarginField}
                  disabled={areOriginalValues}
                  onChange={handleShowCommissionMarginField}
                />
              }
              label="This supplier does not provide cost price via Rezdy"
            />
          </FormGroup>

          {showCommissionMarginField && (
            <TextField
              name="commission-margin"
              onChange={handleCommissionMarginChange}
              label="Commission Margin %"
              value={values?.commissionMargin ?? 0}
              disabled={areOriginalValues}
              type="number"
              inputProps={{ max: 100 }}
              helperText="Equivalent to the percentage that will be deducted from the vendor's price as a commission for Luxury Escapes. This margin does not modify the deal's selling price."
              fullWidth
            />
          )}
        </>
      )}

      <ExperienceCurationOverview />

      <Box>
        <Typography fontWeight="bold" fontSize={16} mb={1}>
          Video
        </Typography>
        <Typography>Insert the Vimeo video ID</Typography>

        <Typography color="secondary">
          The ID is located at:{' '}
          <i>
            http://vimeo.com/
            <strong>1234567890</strong>
          </i>
        </Typography>

        <TextField
          onChange={(e) => updateValues({ video: e.target.value })}
          placeholder="Ex.: 1234567890"
          value={values?.video || ''}
          disabled={areOriginalValues}
        />
      </Box>
    </Stack>
  );
}
