import React, { useMemo } from 'react';

import { useSnackbar } from 'notistack';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import { REFUND_V1, VENDOR_REFUND_AGREES_PERCENTAGE } from '~/consts/refund';

import { deleteAccountingMeta } from '~/services/PaymentsService';
import { formatDateSlashesWithClock } from '~/services/TimeService';

import type { RefundRecord, RefundsResponse } from '~/types/responses';

import { onlyHasSpecificKeys } from '~/utils/arrayUtils';

function deleteRow(row, refreshData) {
  return async function () {
    if (window.confirm('Are you sure you wish to delete this accounting metadata row?')) {
      try {
        await deleteAccountingMeta(row.fk_orders, row.id_refund_metadata);
        refreshData();
      } catch (e) {
        alert(e);
      }
    }
  };
}

const isValidData = (data) => {
  return typeof data === 'string' || typeof data === 'number';
};

const isValidKeys = (info) => {
  if (info && isValidData(info.refund_vendor_agrees_to)) {
    if (info.refund_vendor_agrees_to === VENDOR_REFUND_AGREES_PERCENTAGE) {
      return isValidData(info.percentage);
    } else {
      return isValidData(info.number_of_nights) && isValidData(info.total_nights);
    }
  } else {
    return false;
  }
};

const additionalInfoFormatter = (originalAdditionalInfoObj) => {
  const additionalInfoObj = {
    ...originalAdditionalInfoObj,
  };
  delete additionalInfoObj?.version;
  if (isValidKeys(additionalInfoObj)) {
    return additionalInfoObj.refund_vendor_agrees_to === VENDOR_REFUND_AGREES_PERCENTAGE
      ? additionalInfoObj.percentage + '%'
      : `${additionalInfoObj.number_of_nights} of ${additionalInfoObj.total_nights} nights`;
  } else {
    return JSON.stringify(additionalInfoObj) === '{}' ||
      additionalInfoObj === null ||
      onlyHasSpecificKeys(additionalInfoObj, ['refund_subtype']) // if it only has refund subtype, will be handled as own col
      ? ''
      : JSON.stringify(additionalInfoObj);
  }
};

interface AccountingMetaProps {
  refunds: RefundsResponse;
  users: Users;
  sale_currency_code: string;
  refreshData: () => void;
}

type Users = {
  [id: string]: App.UserSummary;
};

type RefundData = RefundRecord & { refundedBy: string };

export default function AccountingMetaTable({ refunds, users, sale_currency_code, refreshData }: AccountingMetaProps) {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const data = useMemo<Array<RefundData>>(
    () =>
      refunds.result.map((refund) => ({
        ...refund,
        refundedBy: users[refund.refunded_by]?.full_name || null,
      })),
    [refunds, users],
  );

  const columns: Array<GridColDef> = useMemo<Array<GridColDef<RefundData>>>(
    () => [
      {
        field: 'reason',
        headerName: 'Refund info',
        sortable: false,
        flex: 4,
        minWidth: 250,
        renderCell: (params: GridRenderCellParams<RefundData, string>) => {
          const refund = params.row;
          return (
            <Box width="100%">
              <Typography>
                <strong>Refunded by</strong>: {refund.refundedBy}
              </Typography>

              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography>
                  <strong>Refund reason</strong>: {refund.reason}
                </Typography>
              </Stack>

              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography>
                  <strong>Comment</strong>: {refund.comment}
                </Typography>
              </Stack>

              {refund.customer_refund_reason && refund.customer_refund_reason.length > 0 && (
                <Typography>
                  <strong>Customer refund reason</strong>: {refund.customer_refund_reason}
                </Typography>
              )}
            </Box>
          );
        },
        display: 'flex',
      },
      {
        field: 'created_at',
        headerName: 'Date',
        disableColumnMenu: true,
        minWidth: 100,
        flex: 1,
        valueFormatter: (value) => {
          return formatDateSlashesWithClock(value);
        },
        display: 'flex',
      },
      {
        field: 'accounting_amount',
        headerName: 'Accounting',
        sortable: false,
        display: 'flex',
        flex: 1,
        minWidth: 80,
        hideable: true,
      },
      {
        field: 'cash_amount',
        headerName: 'Cash',
        sortable: false,
        display: 'flex',
        flex: 1,
        minWidth: 80,
        hideable: true,
      },
      {
        field: 'currency',
        headerName: '$',
        sortable: false,
        renderCell: () => sale_currency_code,
        display: 'flex',
        flex: 0.5,
        minWidth: 40,
        hideable: true,
      },
      {
        field: 'source',
        headerName: 'Source',
        sortable: false,
        display: 'flex',
        flex: 1,
        minWidth: 80,
        hideable: true,
      },
      {
        field: 'additional_info',
        headerName: 'Extra Info',
        sortable: false,
        valueFormatter: (value) => additionalInfoFormatter(value),
        display: 'flex',
        flex: 1,
        minWidth: 100,
        hideable: true,
      },
      {
        field: 'refund_subtype',
        headerName: 'Subtype',
        sortable: false,
        display: 'flex',
        flex: 1,
        minWidth: 80,
        hideable: true,
        valueGetter: (_, row) => {
          if (row.additional_info.refund_subtype) {
            return row.additional_info.refund_subtype;
          }
          return '';
        },
      },
      {
        field: 'copy',
        headerName: 'IDs',
        sortable: false,
        flex: 2,
        minWidth: 170,
        renderCell: (params: GridRenderCellParams<RefundData, string>) => {
          const refund = params.row;
          return (
            <Box width="100%">
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography>Refund ID</Typography>
                <IconButton
                  onClick={() => {
                    navigator.clipboard.writeText(refund.id_refund_metadata);
                    enqueueSnackbar('Refund ID copied to clipboard', { variant: 'success' });
                  }}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Stack>

              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography>Case ID</Typography>
                <IconButton
                  onClick={() => {
                    navigator.clipboard.writeText(refund.ticket_id);
                    enqueueSnackbar('Case ID copied to clipboard', { variant: 'success' });
                  }}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Stack>

              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography>Item ID</Typography>
                <IconButton
                  onClick={() => {
                    navigator.clipboard.writeText(refund.charge_component_key);
                    enqueueSnackbar('Item ID copied to clipboard', { variant: 'success' });
                  }}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Stack>
              <Typography fontStyle="italic">
                <b>Version:</b> {refund?.additional_info?.version ? refund?.additional_info?.version : REFUND_V1}
              </Typography>
            </Box>
          );
        },
        display: 'flex',
      },
      {
        field: 'actions',
        headerName: '',
        sortable: false,
        width: 60,
        align: 'center',
        renderCell: (params: GridRenderCellParams<RefundData, string>) => {
          return (
            <IconButton onClick={deleteRow(params.row, refreshData)}>
              <DeleteIcon />
            </IconButton>
          );
        },
        display: 'flex',
      },
    ],
    [refreshData, sale_currency_code, enqueueSnackbar],
  );

  return (
    <DataGrid
      columns={columns}
      rows={data}
      getRowId={(row: RefundData) => row.id_refund_metadata}
      autoHeight
      pageSizeOptions={[]}
      disableColumnMenu
      disableRowSelectionOnClick
      hideFooter
      getRowHeight={() => 'auto'}
      initialState={{
        columns: {
          columnVisibilityModel: {
            accounting_amount: !isSmallScreen,
            cash_amount: !isSmallScreen,
            source: !isSmallScreen,
            refund_subtype: !isSmallScreen,
            additional_info: !isSmallScreen,
          },
        },
      }}
    />
  );
}
