import React from 'react';

import { useParams } from 'react-router';

import { Stack } from '@mui/material';

import ContentWithSidebar from '../ContentWithSidebar';

import AirportTransfers from './AirportTransfers';
import CreditExtensions from './CreditExtensions';
import CustomOffersSummaryForm from './CustomOffersSummaryForm';
import DateChangeOutsidePolicy from './DateChangeOutsidePolicy';
import DateChangeOutsidePolicyReportForm from './DateChangeOutsidePolicyReportForm';
import EmailChangeReportForm from './EmailChangeReportForm';
import RefundsOutsidePolicy from './RefundsOutsidePolicy';
import RefundsOutsidePolicyReportForm from './RefundsOutsidePolicyReportForm';
import SpecialRequests from './SpecialRequests';

const DEFAULT_ITEM = 'credit-extensions';

const items = [
  {
    title: 'Credit extensions',
    slug: 'credit-extensions',
    component: CreditExtensions,
  },
  {
    title: 'Custom offers summary',
    slug: 'custom-offers-summary',
    component: CustomOffersSummaryForm,
  },
  {
    title: 'Email change report',
    slug: 'email-change-report',
    component: EmailChangeReportForm,
  },
  {
    title: 'Refunds outside policy report',
    slug: 'refunds-outside-policy',
    component: RefundsOutsidePolicyReportForm,
  },
  {
    title: 'Date change outside policy report',
    slug: 'date-change-outside-policy',
    component: DateChangeOutsidePolicyReportForm,
  },
  {
    title: 'Airport transfers report',
    slug: 'airport-transfers-report',
    component: () => null,
  },
  {
    title: 'Special requests',
    slug: 'special-requests',
    component: () => null,
  },
];

export default function OtherPageContainer() {
  const { report } = useParams<{ report: string }>();

  return (
    <Stack direction="column" spacing={4}>
      <ContentWithSidebar items={items} defaultItem={DEFAULT_ITEM} />

      {report === 'refunds-outside-policy' && <RefundsOutsidePolicy />}
      {report === 'date-change-outside-policy' && <DateChangeOutsidePolicy />}
      {report === 'airport-transfers-report' && <AirportTransfers />}
      {report === 'special-requests' && <SpecialRequests />}
    </Stack>
  );
}
