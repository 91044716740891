import React, { useState } from 'react';

import { Box, Button, Collapse, Typography } from '@mui/material';

interface Props {
  user: App.User;
  alwaysShow?: boolean;
}

const ResolveAccountTakeoverInstructions: React.FC<Props> = ({ user, alwaysShow = false }) => {
  const [showShadowBanInfo, setShowShadowBanInfo] = useState(false);

  if (!alwaysShow && !user.shadow_ban_user) {
    return null;
  }

  return (
    <Box>
      <Button
        onClick={() => setShowShadowBanInfo((prev) => !prev)}
        sx={{ color: 'green', textTransform: 'none', p: 2 }}
      >
        <Typography color="green">
          How to resolve an account takeover or lift a shadow ban {showShadowBanInfo ? '▼' : '▶'}
        </Typography>
      </Button>
      <Collapse in={showShadowBanInfo}>
        <Box sx={{ mt: 1, ml: 2, mb: 5 }}>
          <Typography>
            You can <strong>resolve an account takeover immediately</strong>, while on the phone to the customer.
            <br />
            Follow these steps (or watch{' '}
            <a
              href="https://www.loom.com/share/340a5f4b3adf48c49905154ea99f6112?sid=9d4a1cb8-0dea-4d79-97df-f1000367870a"
              target="_blank"
              rel="noopener noreferrer"
            >
              this video
            </a>
            ):
            <ol>
              <li>
                <strong>Use the "force logout" button.</strong> Mash at the keyboard to create a password that no one
                knows. Don't save it, or share it with the customer. <br />
                <em>At this point, no one - customer or fraudster - can access the account via password.</em>
              </li>
              <li>
                <strong>Confirm name, email, and phone number are correct.</strong>
              </li>
              <li>
                <strong>Lift the shadow ban.</strong> If the user is shadow-banned, remove the ban now. No need to wait
                until the above 2 steps are completed. Un-check the "Shadow Ban User" box, then click "update".
              </li>
              <li>
                <strong>Escalate case to gain approval to refund credit to customer.</strong>
              </li>
              <li>
                <strong>Mark relevant orders as fraudulent.</strong> This means it will no longer show in the customer's
                account.
              </li>
              <li>
                Send the customer the following instructions:
                <Box sx={{ m: 3, border: '1px solid lightgrey', p: 2 }}>
                  Due to suspected unauthorised access, we've terminated all active logins to your Luxury Escapes
                  account. <br />
                  To securely regain access, follow these two steps:
                  <br />
                  <br />
                  1. <strong>Set a new password</strong> for your email account:
                  <ul>
                    <li>
                      <em>Be sure to choose a password that you don't use on any other website</em>
                    </li>
                    <li>It's OK to write this password down, somewhere others won't see it.</li>
                    <li>
                      No matter how long or secure your password is, re-using the same password on multiple websites is
                      the least secure thing you can do.
                    </li>
                  </ul>{' '}
                  <br />
                  2. <strong>Set a new password</strong> for your Luxury Escapes account:
                  <ul>
                    <li>Visit luxuryescapes.com</li>
                    <li>Click "login"</li>
                    <li>Enter your email</li>
                    <li>Click "forgot password"</li>
                    <li>
                      <em>Be sure to choose a password that you don't use on any other website</em>
                    </li>
                    <li>It's OK to write this password down, somewhere others won't see it.</li>
                  </ul>
                  <br />
                  <br />
                  <strong>END OF INSTRUCTIONS TO SEND CUSTOMER</strong>
                </Box>
              </li>
            </ol>
          </Typography>
        </Box>
      </Collapse>
    </Box>
  );
};

export default ResolveAccountTakeoverInstructions;
