import React from 'react';

import { Alert, Button, DialogActions, DialogTitle } from '@mui/material';

interface Props {
  onClose: () => void;
}

export default function OtpSuccessModal({ onClose }: Props) {
  return (
    <>
      <DialogTitle>Verification Successful</DialogTitle>
      <Alert severity="success" sx={{ mx: 3, my: 1 }}>
        The customer has been successfully verified.
      </Alert>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </>
  );
}
