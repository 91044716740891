import React from 'react';

import { useRouteMatch } from 'react-router-dom';

import {
  ContactSupportOutlined,
  EventNote,
  FilePresent,
  InsertInvitation,
  InventoryOutlined,
  ListOutlined,
  LocalPoliceOutlined,
  ReceiptLongOutlined,
} from '@mui/icons-material';
import ContactsIcon from '@mui/icons-material/Contacts';
import SellIcon from '@mui/icons-material/Sell';
import StarOutlineIcon from '@mui/icons-material/StarOutline';

import Layout from '~/components/layouts/Layout';

import OpportunityHeader from './OpportunityHeader';
import OpportunityPropertyInfo from './OpportunityPropertyInfo/OpportunityPropertyInfo';
import OpportunitySidebar from './OpportunitySidebar/OpportunitySidebar';
import { SidebarSectionProps } from './OpportunitySidebar/SidebarSection';
import OpportunityDatesAndPolicies from './sections/OpportunityDatesAndPolicies';
import OpportunityDealDetails from './sections/OpportunityDealDetails';
import OpportunityInformation from './sections/OpportunityInformation';

const SIDEBAR_SECTIONS: Array<SidebarSectionProps> = [
  {
    title: 'Key info',
    items: [
      {
        label: 'Opportunity Info',
        icon: <StarOutlineIcon />,
        url: '/opportunity/opportunity-information',
      },
      {
        label: 'Key contacts',
        icon: <ContactsIcon />,
        url: '/opportunity/opportunity-information#key-contacts',
      },
      {
        label: 'Key commercial',
        icon: <SellIcon />,
        url: '/opportunity/opportunity-information#key-commercial',
      },
    ],
  },
  {
    title: 'Deal details',
    items: [
      {
        label: 'Deal options',
        icon: <ListOutlined />,
        url: '/opportunity/deal-options',
      },
      {
        label: 'Additional questions',
        icon: <ContactSupportOutlined />,
        url: '/opportunity/deal-options#additional-questions',
      },
      {
        label: 'Payments',
        icon: <ReceiptLongOutlined />,
        url: '/opportunity/deal-options#payments',
      },
    ],
  },
  {
    title: 'Dates & Policies',
    items: [
      {
        label: 'Deal dates',
        icon: <InsertInvitation />,
        url: '/opportunity/deal-dates',
      },
      {
        label: 'Run dates',
        icon: <EventNote />,
        url: '/opportunity/deal-dates#run-dates',
      },
      {
        label: 'Policies',
        icon: <LocalPoliceOutlined />,
        url: '/opportunity/deal-dates#policies',
      },
    ],
  },
  {
    title: 'Documents & Agreements',
    items: [
      {
        label: 'Documents',
        icon: <FilePresent />,
        url: 'documents',
      },
      {
        label: 'Agreements',
        icon: <InventoryOutlined />,
        url: 'documents#agreements',
      },
    ],
  },
];

const SECTION_COMPONENT_MAP: Record<string, { component: React.ReactNode; isAnchor: boolean }> = {
  'opportunity-information': { component: <OpportunityInformation />, isAnchor: false },
  'key-contacts': { component: <OpportunityInformation />, isAnchor: true },
  'key-commercial': { component: <OpportunityInformation />, isAnchor: true },

  'deal-options': { component: <OpportunityDealDetails />, isAnchor: false },
  payments: { component: <OpportunityDealDetails />, isAnchor: true },
  'additional-questions': { component: <OpportunityDealDetails />, isAnchor: true },

  'deal-dates': { component: <OpportunityDatesAndPolicies />, isAnchor: true },
  'run-dates': { component: <OpportunityDatesAndPolicies />, isAnchor: true },
  policies: { component: <OpportunityDatesAndPolicies />, isAnchor: true },

  documents: { component: <OpportunityDatesAndPolicies />, isAnchor: false },
  agreements: { component: <div>agreements</div>, isAnchor: false },
};

export default function OpportunityContainer() {
  const { url } = useRouteMatch();
  const section = url.split('/').pop();

  return (
    <Layout
      header={<OpportunityHeader />}
      leftSidebar={<OpportunitySidebar sections={SIDEBAR_SECTIONS} />}
      rightSidebar={<OpportunityPropertyInfo />}
    >
      {section && SECTION_COMPONENT_MAP[section].component}
    </Layout>
  );
}
