import React, { ReactElement, ReactFragment, ReactNode } from 'react';

import { Stack, SxProps } from '@mui/material';

import PageHeader from './PageHeader';
import PageHeaderTitle from './PageHeaderTitle';

const rootStyle: SxProps = {
  paddingBlock: 1,
};

interface Props {
  title: string | ReactNode;
  actions?: ReactElement | ReactFragment;
}

function PageHeaderBar({ title, actions }: Props) {
  return (
    <PageHeader>
      <Stack direction="row" rowGap={1} columnGap={2} justifyContent="space-between" flexWrap="wrap" sx={rootStyle}>
        <PageHeaderTitle>{title}</PageHeaderTitle>
        <Stack direction="row" justifyContent="end" gap={1} flexWrap="wrap">
          {actions}
        </Stack>
      </Stack>
    </PageHeader>
  );
}

export default PageHeaderBar;
