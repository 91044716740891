import React, { Fragment } from 'react';

import currencyFormatter from 'currency-formatter';

import { Button, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import PermissionedComponent from '~/components/Common/PermissionedComponent';

import { ROLE_ADMIN_USER, ROLE_EMPLOYEE_USER, ROLE_ICS_STORE_TEAM } from '~/consts/roles';

import { formatDateShort } from '~/services/TimeService';

export default function FlightRefund({ flightDetails, onIssueRefund }) {
  const currency = flightDetails.currency;

  return (
    <>
      {flightDetails.booking_fees > 0 && (
        <Grid container>
          <Grid xs={1} className="type-heading">
            {flightDetails.travellers.length + 1}
          </Grid>
          <Grid xs={4}>Booking Fee</Grid>
          <Grid xs={2} className="type-heading">
            {currencyFormatter.format(flightDetails.booking_fees, {
              code: currency,
            })}
          </Grid>
          <Grid xs={3} className="type-heading">
            {flightDetails.fee_refunded_at ? (
              <Stack spacing={2}>
                <Typography>Refunded</Typography>
                <Typography>{formatDateShort(flightDetails.fee_refunded_at)}</Typography>
                <Typography>
                  {currencyFormatter.format(flightDetails.booking_fees, {
                    code: currency,
                  })}
                </Typography>
              </Stack>
            ) : (
              flightDetails.status
            )}
          </Grid>
          <PermissionedComponent requiredRoles={[ROLE_ADMIN_USER, ROLE_EMPLOYEE_USER, ROLE_ICS_STORE_TEAM]}>
            {!flightDetails.fee_refunded_at && (
              <Grid xs={2} className="type-heading">
                <Button onClick={() => onIssueRefund(flightDetails.booking_fees)}>Issue Refund</Button>
              </Grid>
            )}
          </PermissionedComponent>
        </Grid>
      )}
      {flightDetails.service_fees > 0 && (
        <Grid container>
          <Grid xs={1} className="type-heading">
            {flightDetails.travellers.length + 1}
          </Grid>
          <Grid xs={3}>
            <Typography>Service Fee</Typography>
          </Grid>
          <Grid xs={2} className="type-heading">
            {currencyFormatter.format(flightDetails.service_fees, {
              code: currency,
            })}
          </Grid>
        </Grid>
      )}
      {flightDetails.atol_fee > 0 && (
        <Grid container>
          <Grid xs={1} className="type-heading">
            {flightDetails.booking_fees > 0 ? flightDetails.travellers.length + 2 : flightDetails.travellers.length + 1}
          </Grid>
          <Grid xs={4}>
            <Typography>ATOL Fee</Typography>
          </Grid>
          <Grid xs={2} className="type-heading">
            {currencyFormatter.format(flightDetails.atol_fee, {
              code: currency,
            })}
          </Grid>
          <Grid xs={3} className="type-heading">
            {flightDetails.fee_refunded_at && (
              <Stack spacing={2}>
                <Typography>Refunded</Typography>
                <Typography>{formatDateShort(flightDetails.fee_refunded_at)}</Typography>
                <Typography>
                  {currencyFormatter.format(flightDetails.atol_fee, {
                    code: currency,
                  })}
                </Typography>
              </Stack>
            )}
            {flightDetails.fee_refunded_at && flightDetails.status}
          </Grid>
          <PermissionedComponent requiredRoles={[ROLE_ADMIN_USER, ROLE_EMPLOYEE_USER, ROLE_ICS_STORE_TEAM]}>
            {!flightDetails.fee_refunded_at && (
              <Grid xs={2} className="type-heading">
                <Button onClick={() => onIssueRefund(flightDetails.atol_fee)}>Issue Refund</Button>
              </Grid>
            )}
          </PermissionedComponent>
        </Grid>
      )}
    </>
  );
}
