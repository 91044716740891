import pluralize from 'pluralize';

import ApiError from '~/types/ApiError';

const KILO = 1024;
const PREFIXED_BYTES = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] as const;

/**
 * Format a number of bytes
 * @returns {string} Prefixed bytes presentaion
 * @example
 * formatByes(2048)
 * // returns "2 KB"
 */
export function formatBytes(bytes: number, decimalPoints = 2): `${number} ${(typeof PREFIXED_BYTES)[number]}` {
  if (bytes === 0) {
    return '0 Bytes';
  }

  const i = Math.floor(Math.log(bytes) / Math.log(KILO));

  return `${parseFloat((bytes / Math.pow(KILO, i)).toFixed(decimalPoints))} ${PREFIXED_BYTES[i]}`;
}

/**
 * Convert camel case to space-separated
 * @returns {string} Space-separated
 * @example
 * camelCaseToSpaceSeparated('camelCase')
 * // returns "camel Case"
 */
export function camelCaseToSpaceSeparated(input: string): string {
  return input?.replace(/([A-Z])/g, ' $1') ?? '';
}

/**
 * Convert string to Title Case
 * @returns {string} any text
 * @example
 * titleCase('just testing')
 * // returns "Just Testing"
 */
export function titleCase(value: string) {
  const str = value.toLowerCase().split(' ');
  for (let i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }

  return str.join(' ');
}

export function extractNumberFromString(id: string): number | null {
  const matches = id.match(/\d+/);
  return matches ? parseInt(matches[0], 10) : null;
}

/**
 * Convert snake case to Space Separated Start Case
 * @returns {string} Start Case
 * @example
 * snakeCaseToStartCase('snake_case')
 * // returns "Snake Case"
 */
export function snakeCaseToStartCase(input: string): string {
  const str = input.split('_').map((s) => s.charAt(0).toUpperCase() + s.slice(1));
  return str.join(' ');
}

export function capitalise(str = '') {
  if (str.length > 0) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return str;
}

export function pluralizeToString(singular: string, count: number) {
  let text = singular;
  if (count !== 1) {
    text = pluralize(singular, count);
  }
  return `${count} ${text}`;
}

export function isNumeric(str: string): boolean {
  return /^\d+$/.test(str); // Matches strings containing only digits (0-9)
}

export function getOrdinalText(number: number): string {
  const ordinals: Array<string> = [
    'first',
    'second',
    'third',
    'fourth',
    'fifth',
    'sixth',
    'seventh',
    'eighth',
    'ninth',
    'tenth',
  ];

  if (number <= ordinals.length && number > 0) {
    return ordinals[number - 1];
  }
  return `${number}th`;
}

/**
 * Converts a string into a deterministic hex color code by hashing the input string.
 * The same input string will always generate the same color.
 *
 * @param {string} str - The input string to convert to a color
 * @returns {string} A hex color code in the format '#RRGGBB'
 * @example
 * stringToColor('hello')
 * // returns '#5ab534' (or similar hex code)
 */
export function stringToHexColor(str: string): string {
  let hash = 0;

  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += value.toString(16).padStart(2, '0');
  }

  return color;
}

/**
 * Convert an error to a string
 * @param error - The error to convert
 * @returns A string representation of the error
 */
export function errorToString(error: Error): string {
  let errorMessage = error.message;
  if (error instanceof ApiError && Array.isArray(error.errors) && error.errors?.[0] === 'string') {
    errorMessage = error.errors[0];
  }

  return `${error.name}: ${errorMessage}`;
}

/**
 * Extracts the username from an email address
 * @param email - The email address to extract the username from
 * @returns The username
 */
export function extractUsernameFromEmail(email: string): string {
  return email.split('@')[0].toLowerCase();
}

export function getCharacterCount(str: string): number {
  return str.length;
}

export function getWordCount(str: string): number {
  return str.split(/\s+/).filter(Boolean).length;
}
