import React, { forwardRef, useCallback, useEffect, useMemo } from 'react';

import {
  FormControl,
  FormHelperText,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from '@mui/material';

import { EmptyArray } from '~/utils/arrayUtils';
import { isRequestPending, isRequestRejected, isRequestUnresolved } from '~/utils/requestUtils';

interface Props<T> {
  value: string;
  onChange: (value: string) => void;
  destinationsInputData: () => { dataReq: Utils.RequestState<T> };
}

export enum DESTINATION_FORM_NAMES {
  DESTINATION_ID = 'destination_id',
}

export function parseDestinationFormData(formData: FormData) {
  return {
    destinationId: String(formData.get(DESTINATION_FORM_NAMES.DESTINATION_ID)),
  };
}

const ScheduleDestinationInput = forwardRef<
  HTMLFormElement,
  Props<Array<CustomerCommunication.CustomScheduleDestination>>
>(({ value, onChange, destinationsInputData }, ref) => {
  const { dataReq } = destinationsInputData();
  const destinations = dataReq?.result ?? EmptyArray;

  const handleChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      onChange(event.target.value);
    },
    [onChange],
  );

  useEffect(() => {
    if (destinations.length && (!value || !destinations.find((i) => i.id === value))) {
      onChange(destinations[0].id);
    }
  }, [destinations, value, onChange]);

  const initialDestination = useMemo<CustomerCommunication.CustomScheduleDestination | undefined>(() => {
    if (value && !destinations.find((i) => i.id === value)) {
      return { id: value, name: value };
    }
  }, [destinations, value]);

  return (
    <Stack component="form" ref={ref}>
      <FormControl variant="standard" fullWidth>
        <InputLabel>Select destination</InputLabel>
        <Select
          name={DESTINATION_FORM_NAMES.DESTINATION_ID}
          value={value}
          onChange={handleChange}
          required
          disabled={isRequestUnresolved(dataReq) || isRequestRejected(dataReq)}
          error={isRequestRejected(dataReq)}
        >
          {!!initialDestination && (
            <MenuItem value={initialDestination.id}>
              <i>{initialDestination.name}</i>
            </MenuItem>
          )}
          {destinations.map((destination) => (
            <MenuItem key={destination.id} value={destination.id}>
              {destination.name}
            </MenuItem>
          ))}
        </Select>
        {isRequestRejected(dataReq) && <FormHelperText error>{dataReq.error}</FormHelperText>}
        {isRequestPending(dataReq) && <LinearProgress sx={{ position: 'absolute' }} />}
      </FormControl>
    </Stack>
  );
});

ScheduleDestinationInput.displayName = 'ScheduleDestinationInput';
export default ScheduleDestinationInput;
