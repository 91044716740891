import React, { useCallback, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SaveIcon from '@mui/icons-material/Save';
import { Alert, Box, Button, Chip, IconButton, Stack, Typography } from '@mui/material';

import { convertBytes } from '~/components/Common/Forms/helpers/sizeHelpers';

import { useFlightTrace } from '../hooks/useFlightTrace';

import { FlightsLogsViewerCode } from './FlightsLogsViewerCode';
import { useFlightsLogViewer } from './FlightsLogsViewerProvider';

interface Props {
  id: string;
  name: string;
  kind: App.FlightTraceKind;
  provider: string;
  lastModified: string;
  size: number;
}

export function FlightsLogViewerListItem({ id, name, kind, provider, lastModified, size = 0 }: Props) {
  const [showCode, setShowCode] = useState(false);
  const { data, loading, error } = useFlightTrace(id, { disabled: !showCode });
  const showCodeViewer = data && !loading && !error;

  const handleShowCode = useCallback(() => {
    setShowCode((show) => !show);
  }, [setShowCode]);

  return (
    <Stack gap={2}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Stack gap={0.5}>
          <Box display="flex" alignItems="center" gap={2}>
            <Typography fontWeight="bold" onClick={handleShowCode} style={{ cursor: 'pointer' }}>
              {name}
            </Typography>

            <Box display="flex" alignItems="center" gap={1}>
              <Chip label={provider.toUpperCase()} color="secondary" size="small" />
              <Chip label={kind.toUpperCase()} color="primary" size="small" />
            </Box>
          </Box>

          <Box display="flex" alignItems="center" gap={3}>
            <Typography color="gray" display="flex" alignItems="center" gap={1}>
              <AccessTimeIcon fontSize="small" /> {lastModified}
            </Typography>

            <Typography color="gray" display="flex" alignItems="center" gap={1}>
              <SaveIcon fontSize="small" /> {convertBytes(size)}
            </Typography>
          </Box>
        </Stack>

        <Button onClick={handleShowCode}>{!showCode ? 'Show logs' : 'Hide logs'}</Button>
      </Box>

      {showCode && (
        <>
          {error && <Alert severity="error">Something went wrong</Alert>}
          {loading && 'Loading...'}
          {showCodeViewer && <RenderCodeItems trace={data} />}
        </>
      )}
    </Stack>
  );
}

interface RenderCodeItemProps {
  trace: App.FlightTrace;
}

function RenderCodeItems({ trace }: RenderCodeItemProps) {
  const [collapsed, setCollapsed] = useState<Record<string, boolean>>({});
  const { theme } = useFlightsLogViewer();
  const { enqueueSnackbar } = useSnackbar();

  const handleCopy = useCallback((str: string) => {
    navigator.clipboard.writeText(str).then(() => {
      enqueueSnackbar('Copied!');
    });
  }, []);

  const isRequestResponse = useMemo(() => {
    return trace.data.length === 2;
  }, [trace.data]);

  return (
    <Stack gap={1}>
      {trace.data.map((code, index) => (
        <Stack key={index} gap={2}>
          {trace.data.length > 1 && (
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Box display="flex" alignItems="center" gap={1}>
                {!isRequestResponse && <Typography fontWeight="bold">Log {index + 1}</Typography>}

                {isRequestResponse && (
                  <Typography fontWeight="medium">{index === 0 ? 'Request' : 'Response'}</Typography>
                )}

                <IconButton onClick={() => setCollapsed((data) => ({ ...data, [index]: !data[index] }))}>
                  {!collapsed[index] ? (
                    <KeyboardArrowUpIcon fontSize="small" />
                  ) : (
                    <KeyboardArrowDownIcon fontSize="small" />
                  )}
                </IconButton>
              </Box>

              <IconButton size="small" onClick={() => handleCopy(code)}>
                <ContentCopyIcon />
              </IconButton>
            </Box>
          )}

          {!collapsed[index] && (
            <FlightsLogsViewerCode
              key={index}
              code={trace.kind === 'xml' ? code : code}
              kind={trace.kind}
              theme={theme}
            />
          )}
        </Stack>
      ))}
    </Stack>
  );
}
