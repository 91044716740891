import React from 'react';

import { useParams } from 'react-router-dom';

import { Dialog, DialogContent, DialogTitle } from '@mui/material';

import DealInclusionForm from './components/DealInclusionForm';

type Props = {
  onSave: () => void;
};

function DealInclusionCreate({ onSave }: Props) {
  const { id: dealPageId } = useParams<{ id: string }>();

  return (
    <Dialog open maxWidth="sm" fullWidth>
      <DialogTitle>Create Inclusion Item</DialogTitle>
      <DialogContent>
        <DealInclusionForm dealPageId={dealPageId} onSave={onSave} />
      </DialogContent>
    </Dialog>
  );
}

export default DealInclusionCreate;
