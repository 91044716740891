import React from 'react';

import currencyFormatter from 'currency-formatter';
import pluralize from 'pluralize';

import { Box, Typography } from '@mui/material';

import { diffDays } from '~/services/TimeService';

interface Props {
  bookings: number;
  totalBookingAmount: number;
  totalCostPrice: number;
  bookingsPax: number;
  paxLeft: number;
  lastBookingDate: string;
}

export function BookingDetailCell({
  bookings,
  totalBookingAmount,
  totalCostPrice,
  bookingsPax,
  paxLeft,
  lastBookingDate,
}: Props) {
  const lastDay = diffDays(new Date(), lastBookingDate, 'day');
  return (
    <Box>
      <Typography>
        <Box component="b">{bookings}</Box> bookings for a total:
      </Typography>
      <Typography>
        <Box component="b">{currencyFormatter.format(totalBookingAmount, { code: 'AUD', precision: 2 })}</Box>
      </Typography>
      <Typography>
        Cost price: <Box component="b">{currencyFormatter.format(totalCostPrice, { code: 'AUD', precision: 2 })}</Box>
      </Typography>
      <Typography>
        Pax booking:{' '}
        <Box component="b">
          {((bookingsPax / (paxLeft + bookingsPax)) * 100).toFixed(2)}% ({bookingsPax})
        </Box>
      </Typography>
      <Typography>
        {pluralize('day', lastDay)} since last booking: <Box component="b">{lastDay === 0 ? 'today' : lastDay}</Box>
      </Typography>
    </Box>
  );
}
