import { useCallback, useEffect, useMemo } from 'react';

import { useDispatch } from 'react-redux';
import { getRecordKeyFor } from '~/reducers/customerCommunicationReducer';

import fetchHeroPlannerCountryGroups from '~/actions/customerCommunication/fetchHeroPlannerCountryGroups';

import { useAppSelector } from '../store';

function useHeroPlannerCountryGroups(brand?: string) {
  const [key, params] = useMemo(() => {
    const params: Parameters<typeof fetchHeroPlannerCountryGroups> = [brand];
    return [getRecordKeyFor(...params), params];
  }, [brand]);
  const countryGroupsReq = useAppSelector((state) => state.customerCommunication.countryGroupRecords[key]);
  const appDispatch = useDispatch();
  const fetchCountryGroups = useCallback(() => {
    appDispatch(fetchHeroPlannerCountryGroups(...params));
  }, [appDispatch, params]);
  useEffect(() => {
    fetchCountryGroups();
  }, [fetchCountryGroups]);

  return { countryGroupsReq, fetchCountryGroups };
}

export default useHeroPlannerCountryGroups;
