import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Autocomplete, Box, Button, Chip, FormControl, Stack, TextField } from '@mui/material';

import { CruisesContract } from '@luxuryescapes/contract-svc-cruise';

import PageHeader from '~/components/Common/Elements/PageHeader';

import { formatDateISO } from '~/services/TimeService';
import cruiseLineService, { CruiseLine } from '~/services/cruises/CruiseLineService';
import promotionService from '~/services/cruises/PromotionService';
import rateCodeService, { GetRateCodes } from '~/services/cruises/RateCodeService';

import PromotionForm from './components/PromotionForm';
import { INITIAL_REQUEST_STATE, INITIAL_SELECT_FORM_STATE } from './constants';
import { hasRequiredFields } from './helpers';
import { Option, PromotionFormValues, Request, SelectFormValues } from './types';

const PROMO_BASE_PATH = `/cruises/promotion-management`;

const CreatePromotionPage: React.FC = (): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedValues, setSelectedValues] = useState<SelectFormValues>(INITIAL_SELECT_FORM_STATE);
  const [cruiseLines, setCruiseLines] = useState<Request<CruiseLine[]>>(INITIAL_REQUEST_STATE);

  const [cruiseRateCodes, setCruiseRateCodes] = useState<Request<Array<GetRateCodes>>>(INITIAL_REQUEST_STATE);

  const [rateList, setRateList] = useState<Array<GetRateCodes>>([]);

  const getCruiseLines = useCallback(async () => {
    if (cruiseLines.loading) return;

    setCruiseLines((prev) => ({ ...prev, loading: true }));
    const res = await cruiseLineService.listWithPagination({ skip: 0, take: 1000 });

    if (res.status === 200 && res.result) setCruiseLines({ loading: false, result: res.result });
  }, [cruiseLines.loading]);

  const getRateListByCruiseLine = useCallback(async () => {
    if (cruiseRateCodes.loading || !selectedValues.cruiseLine?.label) return;

    setCruiseRateCodes((prev) => ({ ...prev, loading: true }));
    const res = await rateCodeService.getRateCodeList({
      take: 500,
      vendorCode: selectedValues.cruiseLine.label,
    });

    if (res.status === 200 && res.result) {
      setCruiseRateCodes({ loading: false, result: res.result });

      setRateList(
        !cruiseRateCodes.result?.length
          ? res.result
          : [
              ...cruiseRateCodes.result,
              ...res.result.filter(
                (newItem) => !cruiseRateCodes.result?.find((prevItem) => prevItem.id === newItem.id),
              ),
            ],
      );
    }
  }, [cruiseRateCodes, selectedValues.cruiseLine?.label]);

  const handleCruiseLineChange = (_, selectedOption: Option | null) => {
    setSelectedValues((prev) => ({
      ...prev,
      cruiseLine: selectedOption,
    }));
  };

  const handleRatesChange = useCallback(
    (_, selectedOptions: Option[] | null) => {
      setSelectedValues((prev) => ({
        ...prev,
        rates: selectedOptions
          ?.map((selectedRate) => {
            const vendor = rateList.find((item) => item.id === selectedRate.value)?.vendor;

            if (!vendor) return;

            return {
              rate: { label: selectedRate.label, value: selectedRate.value },
              vendor: {
                value: vendor.id,
                name: vendor.name,
                label: vendor.code,
              },
            };
          })
          .filter((item) => !!item),
      }));
    },
    [rateList],
  );

  const handleSubmit = useCallback(
    async (formValues: PromotionFormValues) => {
      if (!hasRequiredFields(formValues)) {
        enqueueSnackbar(`Please fill all required fields`, { variant: 'error' });
      } else {
        enqueueSnackbar(`The information is being processed, please wait a moment.`, { variant: 'info' });

        const rates: CruisesContract.RatesWithVendor[] = selectedValues.rates.map((selectedRate) => {
          return {
            rateId: selectedRate.rate.value,
            rateCode: selectedRate.rate.label,
            vendorId: selectedRate.vendor.value,
            vendorCode: selectedRate.vendor.label,
          };
        });

        const res = await promotionService.createPromotionByRateCodes({
          rates: rates,
          name: formValues.promotionName,
          leExclusive: formValues.leExclusive,
          startDate: formatDateISO(formValues.promotionStartDate),
          endDate: formatDateISO(formValues.promotionEndDate),
          sellingPoints: [
            formValues.promotionSellingPoint1,
            formValues.promotionSellingPoint2,
            formValues.promotionSellingPoint3,
          ].filter((item: string | undefined) => !!item),
          ...(formValues.depositAmount && {
            deposit: {
              type: formValues.depositType,
              amount: formValues.depositAmount,
              currencyCode: formValues.depositCurrency,
            },
          }),
          ...(formValues.onBoardCreditAmount && {
            onBoardCredit: {
              amount: formValues.onBoardCreditAmount,
              currencyCode: formValues.onBoardCreditCurrency,
            },
          }),
        });

        if (res?.status === 201) {
          res.notices?.forEach((message: string) => {
            const hasSomeError = !message.toLowerCase().includes('success');
            enqueueSnackbar(message, { variant: hasSomeError ? 'error' : 'success' });
          });
        } else {
          res.errors?.forEach((message: string) => enqueueSnackbar(message, { variant: 'error' }));
        }
      }
    },
    [enqueueSnackbar, selectedValues.rates],
  );

  const cruiseLinesSelectOptions = useMemo(() => {
    return cruiseLines.result?.map((item) => ({
      value: item.id,
      name: item.name,
      label: item.code,
    }));
  }, [cruiseLines.result]);

  const ratesSelectOptions = useMemo(() => {
    return cruiseRateCodes.result?.map((item) => ({
      value: item.id,
      label: item.code,
    }));
  }, [cruiseRateCodes.result]);

  const ratesSelected = useMemo(() => {
    return selectedValues.rates?.map((item) => ({
      label: item.rate.label,
      value: item.rate.value,
    }));
  }, [selectedValues.rates]);

  useEffect(() => {
    getCruiseLines();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedValues.cruiseLine?.label) getRateListByCruiseLine();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValues.cruiseLine?.label]);

  return (
    <>
      <Helmet>
        <title>Create Cruise Promotion</title>
      </Helmet>

      <Box mx={3} px={5}>
        <Stack flexDirection="row" gap="2px" alignItems="center">
          <ArrowBackIcon color="primary" sx={{ width: '16px', height: '16px' }} />
          <Button variant="text" component={Link} to={PROMO_BASE_PATH}>
            Back to Promotion Management
          </Button>
        </Stack>

        <Box mb={4}>
          <PageHeader title="Create Promotion" />
        </Box>

        <Stack mt={3} gap={2} flexDirection="row">
          <FormControl fullWidth>
            <Autocomplete
              openOnFocus
              id="select-vendor"
              value={selectedValues.cruiseLine}
              options={cruiseLinesSelectOptions}
              onChange={handleCruiseLineChange}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  fullWidth
                  label="Cruise Lines"
                  placeholder={`Please Enter Cruise Line`}
                />
              )}
            />
          </FormControl>

          <FormControl fullWidth>
            <Autocomplete
              multiple
              id="select-rates"
              disableCloseOnSelect
              value={ratesSelected}
              options={ratesSelectOptions}
              onChange={handleRatesChange}
              disabled={!selectedValues.cruiseLine?.value}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    size="small"
                    variant="outlined"
                    key={option.value}
                    label={option.label}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField {...params} required fullWidth label="Rate Codes" placeholder={`Please Enter Rate Codes`} />
              )}
            />
          </FormControl>
        </Stack>

        <PromotionForm onSubmit={handleSubmit} />
      </Box>
    </>
  );
};

export default CreatePromotionPage;
