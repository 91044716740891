import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Link } from 'react-router-dom';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { TableCell, TableRow, Tooltip } from '@mui/material';

import { PublicOfferV2 } from '@luxuryescapes/contract-public-offer';

import RankingInfo from '~/components/Common/RankingInfo';
import { FlexTableCell } from '~/components/Content/SearchRanking/FlexTableCell';

import OffersService from '~/services/OffersService';
import PublicOfferService from '~/services/PublicOfferService';
import SearchService, { EvVariant, GlobalRanks } from '~/services/SearchService';

import currencyFormatter from '~/utils/currencyFormatter';
import isUUID from '~/utils/isUUID';

import { HEADER_COLUMN_SPAN } from './SearchHotelHead';

interface IExpectedValueData {
  views: string;
  salesCount: string;
  salesAmount: string;
  marginAmount: string;
  marginPercentage: string;
  expectedValue: string;
  expectedValueInfo?: string;
  adjustedExpectedValue: string;
  adjustedExpectedValueInfo?: string;
  expectedValueScore: string;
  overrideScore: string;
  ctr: string;
  calculatedScore: string;
  info?: string;
  globalRank?: number | 'n/a';
  globalRankOverride?: number;
}

interface SearchHotelRowProps {
  offerId: string;
  region: string;
  variant: EvVariant;
}

function SearchHotelRow({ offerId, region, variant }: SearchHotelRowProps) {
  const isMountedRef = useRef(true);
  const kind = useMemo(() => (isUUID(offerId) ? 'bedbank' : 'luxuryescapes'), [offerId]);
  const [globalRanks, setGlobalRanks] = useState<GlobalRanks | null>(null);
  const [fetchingState, setFetchingState] = useState<Utils.FetchingState>('idle');
  const [offerDetails, setOfferDetails] = useState<App.Offer | PublicOfferV2.BedbankOffer | undefined>();
  const [offerScore, setOfferScore] = useState<App.OfferScore | undefined>();

  useEffect(() => {
    SearchService.getHotelGlobalRanks(region, variant).then(setGlobalRanks);
  }, [region, variant]);

  useEffect(() => {
    (async () => {
      try {
        setFetchingState('loading');
        const [score, offerDetails] = await Promise.all([
          SearchService.getHotelScoreForVariant(offerId, region, variant),
          kind === 'bedbank'
            ? PublicOfferService.getBedbankOfferById(offerId, region, 'luxuryescapes')
            : OffersService.getOffer(offerId),
        ]);

        if (!isMountedRef.current) {
          return;
        }

        setOfferScore(score.result.score);
        setOfferDetails(offerDetails.result);
        setFetchingState('success');
      } catch (error) {
        setFetchingState('failed');
      }
    })();

    return () => {
      isMountedRef.current = false;
    };
  }, [kind, offerId, region, variant]);

  const schedule = useMemo(() => {
    if (!offerDetails || offerDetails.type === 'bedbank_hotel' || !offerDetails.brand_schedules) {
      return null;
    }
    const schedules = offerDetails.brand_schedules.find(
      (s: { type: string; start: string; end: string }) => s.type === 'list_visibility',
    ) as { start: string; end: string };
    const startDate = new Date(schedules?.start || '');
    const endDate = new Date(schedules?.end || '');
    return `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`;
  }, [offerDetails]);

  const expectedValueData: IExpectedValueData = useMemo(() => {
    if (offerScore) {
      const marginPercentage =
        offerScore.totalSales > 0 ? ((offerScore.totalMargin / offerScore.totalSales) * 100).toFixed(2) + '%' : 'N/A';
      return {
        views: offerScore.numberOfViews.toLocaleString(),
        salesCount: offerScore.salesCount.toLocaleString(),
        salesAmount: currencyFormatter('AUD', offerScore.totalSales),
        marginAmount: currencyFormatter('AUD', Math.round(offerScore.totalMargin)),
        marginPercentage,
        expectedValue: offerScore.expectedValue.toFixed(3),
        expectedValueInfo: offerScore.expectedValue && offerScore.expectedValueInfo,
        adjustedExpectedValue: offerScore.adjustedExpectedValue.toFixed(3),
        adjustedExpectedValueInfo: offerScore.adjustedExpectedValue && offerScore.adjustedExpectedValueInfo,
        expectedValueScore: offerScore.expectedValueScore.toFixed(3),
        overrideScore: (offerScore.overrideScore * offerScore.overrideWeight).toFixed(1),
        ctr: (offerScore.ctr * 100).toFixed(2) + '%',
        calculatedScore: offerScore.calculatedScore.toFixed(3),
        info: offerScore.info,
        globalRank: globalRanks ? globalRanks[offerId] || 'n/a' : undefined,
      };
    }
  }, [offerScore, globalRanks, offerId]);

  const offerType = useMemo(() => {
    if (!offerDetails) {
      return null;
    }
    if ('promotions' in offerDetails && offerDetails.promotions.length > 0) {
      return 'limited_time_special';
    }
    return offerDetails.type;
  }, [offerDetails]);

  const isBedbankType = useMemo(() => {
    return offerType === 'bedbank_hotel' || offerType === 'limited_time_special';
  }, [offerType]);

  const offerName = useMemo(() => {
    if (!offerDetails) {
      return '';
    }

    if (isBedbankType || offerType === 'rental') {
      return offerDetails.name;
    }

    if ('location' in offerDetails) {
      return offerDetails.location;
    }

    return '';
  }, [isBedbankType, offerDetails, offerType]);

  return (
    <>
      {fetchingState == 'loading' && (
        <TableRow>
          <TableCell component="th" colSpan={HEADER_COLUMN_SPAN}>
            Loading...
          </TableCell>
        </TableRow>
      )}
      {fetchingState == 'success' && offerDetails && (
        <TableRow>
          <TableCell align="left">{expectedValueData.globalRank}</TableCell>
          <TableCell align="left" component="th" scope="row">
            <Link to={isBedbankType ? `/bedbank/properties/${offerId}` : `/offers/${offerId}`}>{offerName}</Link>
          </TableCell>
          <TableCell align="right">{offerType}</TableCell>
          <TableCell align="right">{schedule}</TableCell>
          <TableCell align="right">{expectedValueData.views}</TableCell>
          <TableCell align="right">{expectedValueData.salesCount}</TableCell>
          <TableCell align="right">{expectedValueData.salesAmount}</TableCell>
          <TableCell align="right">{expectedValueData.marginAmount}</TableCell>
          <TableCell align="right">{expectedValueData.marginPercentage}</TableCell>
          <FlexTableCell align="right">
            {expectedValueData.expectedValue}
            {expectedValueData.expectedValueInfo && (
              <Tooltip title={<RankingInfo info={expectedValueData.expectedValueInfo} />} placement="top" arrow>
                <InfoOutlinedIcon fontSize="small" />
              </Tooltip>
            )}
          </FlexTableCell>
          <FlexTableCell align="right">
            {expectedValueData.adjustedExpectedValue}
            {expectedValueData.adjustedExpectedValueInfo && (
              <Tooltip title={<RankingInfo info={expectedValueData.adjustedExpectedValueInfo} />} placement="top" arrow>
                <InfoOutlinedIcon fontSize="small" />
              </Tooltip>
            )}
          </FlexTableCell>
          <TableCell align="right">{expectedValueData.overrideScore}</TableCell>
          <TableCell align="right">{expectedValueData.ctr}</TableCell>
          <FlexTableCell align="right">
            {expectedValueData.calculatedScore}
            {expectedValueData.info && (
              <Tooltip title={<RankingInfo info={expectedValueData.info} />}>
                <InfoOutlinedIcon fontSize="small" />
              </Tooltip>
            )}
          </FlexTableCell>
        </TableRow>
      )}
      {fetchingState == 'failed' && (
        <TableRow>
          <TableCell component="th" colSpan={HEADER_COLUMN_SPAN}>
            Failed to load offer: {offerId}
          </TableCell>
        </TableRow>
      )}
    </>
  );
}

export default SearchHotelRow;
