import React, { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';

import { CarRental, CreditCard } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';

import Spinner from '~/components/Common/Spinner';

import * as carHireService from '~/services/CarHireService';

import CarHireOrderItemDetails from './CarHireOrderItemDetails';
import CarHireOrderVccDetails from './CarHireOrderVccDetails';

type Props = {
  currencyCode: string;
  carHireItems: Array<App.CarHireItem>;
  hasAllowedRefund?: boolean;
  showRefundModal?: ({ itemId, itemType }) => void;
  refreshData: () => void;
};

export default function CarHireOrderListDetails({
  carHireItems: [item],
  currencyCode,
  hasAllowedRefund,
  showRefundModal,
  refreshData,
}: Props) {
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const getBookingDetails = useCallback(
    async (id: string): Promise<void> => {
      try {
        setLoading(true);
        const { result } = await carHireService.get(id);

        if (!result) {
          enqueueSnackbar('Missing reservation ID', {
            variant: 'warning',
          });
        }

        setResult(result);
      } catch (e) {
        enqueueSnackbar('Failed to load Car Hire data', {
          variant: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [enqueueSnackbar],
  );

  useEffect(() => {
    if (item.id_reservation) {
      getBookingDetails(item.id_reservation);
    }
  }, [item.id_reservation, getBookingDetails]);

  return (
    <>
      <Box mt={2}>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <CarRental sx={{ mr: 1 }} />
            <Typography variant="h6">Car Hire</Typography>
          </AccordionSummary>

          <AccordionDetails>
            {loading && <Spinner size={36} />}

            {!loading && (
              <CarHireOrderItemDetails
                count={1}
                carHireItem={item}
                bookingInfo={result}
                currencyCode={currencyCode}
                hasAllowedRefund={hasAllowedRefund}
                showRefundModal={showRefundModal}
                refreshData={refreshData}
              />
            )}
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box mt={2}>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <CreditCard sx={{ mr: 1 }} />
            <Typography variant="h6">Car Hire VCC Details</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CarHireOrderVccDetails carHireItem={item} />
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
}
