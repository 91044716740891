import React from 'react';

import { Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import ReadOnlyField from '~/components/v2/common/ReadOnlyField/ReadOnlyField';

interface Props {
  customer: App.User;
  businessInfo?: {
    businessName: string;
    businessCreditCustomerId?: string;
    employeePersonalAccountInfo: string;
    personalName: string;
    personalEmail: string;
  };
  order: App.Order;
}

export default function BusinessInfo({ customer, businessInfo, order }: Props) {
  return (
    <Stack id="overview" spacing={2}>
      <Stack direction="row" spacing={2} alignItems="center" sx={{ paddingLeft: '8px' }}>
        <Typography variant="body2" fontWeight="bold">
          Business info
        </Typography>
      </Stack>

      <Grid container spacing={2}>
        <Grid md={3}>
          <ReadOnlyField
            title="Business"
            value={businessInfo.businessName}
            type="link"
            href={`/lebusiness/${order.business_id}/business`}
          />
        </Grid>

        <Grid md={3}>
          <ReadOnlyField
            title="Name"
            value={businessInfo.businessCreditCustomerId ? businessInfo.personalName : customer.fullName}
            type="link"
            href={`/users/${customer.memberId}`}
          />
        </Grid>

        <Grid md={3}>
          <ReadOnlyField title="Email" value={customer.email} type="text" copyable />
        </Grid>
      </Grid>
    </Stack>
  );
}
