import React, { useCallback } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

import { LUX_LOYALTY_PROGRAM_CONFIG } from '@luxuryescapes/lib-lux-loyalty';

import OffersService from '~/services/OffersService';

interface Props {
  open: boolean;
  onClose: () => void;
  offerId: string;
  refreshData: () => Promise<void>;
}

function LuxLoyaltyMapPackageUpgradesModal({ open, onClose, offerId, refreshData }: Props) {
  const handleMapPackageUpgrades = useCallback(() => {
    OffersService.mapPackageUpgrades(offerId);
    refreshData();
    onClose();
  }, [refreshData, offerId, onClose]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title-map-package-upgrades"
      aria-describedby="alert-dialog-description-map-package-upgrades"
    >
      <DialogTitle id="alert-dialog-title-map-package-upgrades">Map Package Upgrades</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description-map-package-upgrades">
          This will map the default upgrades for each package (where applicable) on the offer.
          <br />
          <br />
          It is primarily used for the {LUX_LOYALTY_PROGRAM_CONFIG.programDisplayName} complimentary room upgrades
          benefit. Once upgrades have been auto-mapped, packages can be updated on an individual basis.
          <br />
          <br />
          Proceeding with this action will override any individual adjustments made to the default upgrades mapped.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose} autoFocus>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleMapPackageUpgrades}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default LuxLoyaltyMapPackageUpgradesModal;
