export const INTRINSIC_GREY = {
  50: '#f7f7ff',
  100: '#efeff9',
  200: '#e5e5ef',
  300: '#d4d3dd',
  400: '#afafb9',
  500: '#8f8f98',
  600: '#676770',
  700: '#54545c',
  800: '#36363e',
  900: '#16161d',
};

export const GREY = {
  '50': '#F7F7F8',
  '100': '#EFEFF0',
  '200': '#DFDFE1',
  '300': '#CFD0D2',
  '400': '#AFB0B5',
  '500': '#7E8188',
  '600': '#4A4E58',
  '700': '#363A45',
  '800': '#16181C',
  '900': '#060608',
  A100: '#d5d5d5',
  A200: '#aaaaaa',
  A400: '#616161',
  A700: '#303030',
};

export const TEAL = {
  50: '#e0f2f1',
  100: '#b2dfdb',
  200: '#80cbc4',
  300: '#4db6ac',
  400: '#26a69a',
  500: '#009688',
  600: '#00897b',
  700: '#00796b',
  800: '#00695c',
  900: '#004d40',
  A100: '#a7ffeb',
  A200: '#64ffda',
  A400: '#1de9b6',
  A700: '#00bfa5',
};

export const RED = {
  '50': '#F9B8C4',
  '100': '#F7A3B2',
  '200': '#F58EA0',
  '300': '#F3788F',
  '400': '#F1637D',
  '500': '#EF4E6B',
  '600': '#E54B67',
  '700': '#DB4862',
  '800': '#D1445E',
  '900': '#C74159',
  A100: '#ff8a80',
  A200: '#ff5252',
  A400: '#ff1744',
  A700: '#d50000',
};

export const GREEN = {
  '50': '#b7f7c5',
  '100': '#9ee8ae',
  '200': '#85d997',
  '300': '#6dc981',
  '400': '#54ba6a',
  '500': '#3bab53',
  '600': '#38a34f',
  '700': '#359b4b',
  '800': '#339247',
  '900': '#2d823f',
  A100: '#b9f6ca',
  A200: '#69f0ae',
  A400: '#00e676',
  A700: '#00c853',
};

export const LIGHT_BLUE = {
  '50': '#e1f5fe',
  '100': '#b3e5fc',
  '200': '#81d4fa',
  '300': '#4fc3f7',
  '400': '#29b6f6',
  '500': '#03a9f4',
  '600': '#039be5',
  '700': '#0288d1',
  '800': '#0277bd',
  '900': '#01579b',
  A100: '#80d8ff',
  A200: '#40c4ff',
  A400: '#00b0ff',
  A700: '#0091ea',
};

export const ORANGE = {
  '50': '#FDF1E3',
  '100': '#F8E1C5',
  '200': '#F3D0A7',
  '300': '#EEC08A',
  '400': '#E9AF6C',
  '500': '#E49F4E',
  '600': '#DA984B',
  '700': '#CF9147',
  '800': '#C58944',
  '900': '#BA8240',
  A100: '#ffd180',
  A200: '#ffab40',
  A400: '#ff9100',
  A700: '#ff6d00',
};
