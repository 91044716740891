import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { Dialog, DialogContent, DialogTitle } from '@mui/material';

import Spinner from '~/components/Common/Spinner';
import { CruiseInclusionItem } from '~/components/Cruises/pages/Inclusions/types';

import inclusionsService from '~/services/cruises/InclusionsService';

import InclusionItemForm from './components/InclusionItemForm';

type Props = {
  onSave: () => void;
};

function InclusionsItemEdit({ onSave }: Props) {
  const { inclusionId, actionId: inclusionItemId } = useParams<{ inclusionId: string; actionId: string }>();
  const [inclusionItem, setInclusionItem] = useState<CruiseInclusionItem | null>(null);
  const [fetching, setFetching] = useState<boolean>(false);

  useEffect(() => {
    const fetchInclusionItem = async () => {
      setFetching(true);

      const inclusionItem = await inclusionsService.getItemById(inclusionId, inclusionItemId);
      setInclusionItem(inclusionItem);

      setFetching(false);
    };

    fetchInclusionItem();
  }, [inclusionItemId, setInclusionItem, setFetching, inclusionId]);

  return (
    <Dialog open maxWidth="sm" fullWidth>
      <DialogTitle>Edit Inclusion Item</DialogTitle>
      <DialogContent>
        {fetching && <Spinner size={30} />}

        {!fetching && inclusionItem && (
          <InclusionItemForm inclusionId={inclusionId} inclusionItem={inclusionItem} onSave={onSave} />
        )}
      </DialogContent>
    </Dialog>
  );
}

export default InclusionsItemEdit;
