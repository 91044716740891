import React, { ReactNode } from 'react';

import MUIProvider from '~/contexts/MUIProvider';

import { Box, Stack, SxProps } from '@mui/material';

export const HEADER_HEIGHT = 64;
export const SIDEBAR_WIDTH = 280;

const LEFT_SIDEBAR_GRID_AREA = 'left-side';
const RIGHT_SIDEBAR_GRID_AREA = 'right-side';
const CONTENT_CONTAINER_GRID_AREA = 'main-content';

const getContentGridStyle = {
  display: 'grid',
  gridTemplate: {
    xs: `
      "left-side"
      "main-content"
      "right-side" / 1fr
    `,
    md: `
      "left-side main-content right-side" / auto minmax(0,1fr) auto
      `,
  },

  width: '100%',
  minHeight: 'calc(var(--1vh) * 100)',
  px: { xs: 2, md: 3 },
};

const CONTENT_CONTAINER_STYLE: SxProps = {
  gridArea: CONTENT_CONTAINER_GRID_AREA,
  flexBasis: '100%',
  flexGrow: '1',
  maxWidth: '100%',
  overflow: 'auto',
  gridTemplateColumns: '1fr',
  gridTemplateRows: '1fr',
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
};

export interface TwoColumnLayoutProps {
  header: ReactNode;
  leftSidebar?: ReactNode;
  rightSidebar?: ReactNode;
  children: ReactNode;
}

export default function Layout({ header, leftSidebar, rightSidebar, children }: TwoColumnLayoutProps) {
  return (
    <MUIProvider>
      <Stack direction="column">
        <Box>{header}</Box>
        <Box sx={getContentGridStyle}>
          {!!leftSidebar && <Box sx={{ gridArea: LEFT_SIDEBAR_GRID_AREA }}>{leftSidebar}</Box>}
          <Box sx={{ gridArea: CONTENT_CONTAINER_GRID_AREA, paddingInline: 2 }}>
            <Box sx={CONTENT_CONTAINER_STYLE}>
              <Box sx={{ display: { xs: 'block', md: 'none' } }}>{leftSidebar}</Box>
              {children}
            </Box>
          </Box>
          {!!rightSidebar && <Box sx={{ gridArea: RIGHT_SIDEBAR_GRID_AREA, ps: 1 }}>{rightSidebar}</Box>}
        </Box>
      </Stack>
    </MUIProvider>
  );
}
