import React from 'react';

import { useParams } from 'react-router';

import { Container } from '@mui/material';

import { FlightsLogsViewerHeader } from './FlightsLogsViewerHeader';
import { FlightsLogsViewerList } from './FlightsLogsViewerList';
import { FlightsLogsViewerProvider } from './FlightsLogsViewerProvider';

export function FlightsLogsViewer() {
  const { id } = useParams<{ id: string }>();

  return (
    <FlightsLogsViewerProvider>
      <Container maxWidth="xl">
        <FlightsLogsViewerHeader reservationId={id} />
        <FlightsLogsViewerList reservationId={id} />
      </Container>
    </FlightsLogsViewerProvider>
  );
}
