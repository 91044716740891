import React from 'react';

import { Grid } from '@mui/material';

function BorderedGridItem({ children, noBorder = false, ...props }) {
  return (
    <Grid
      item
      xs
      sx={{
        ...props.sx,
        borderBottom: noBorder ? 'none' : '1px solid',
        borderColor: 'grey.400',
        padding: '1px',
        flexGrow: 1,
      }}
      {...props}
    >
      {children}
    </Grid>
  );
}

export default BorderedGridItem;
