import React from 'react';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import {
  Backdrop,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Stack,
} from '@mui/material';

import { Dayjs } from '~/services/TimeService';

import {
  BannerPayload,
  CallToActionPayload,
  DreamPayload,
  DynamicSectionType,
  FlightPayload,
  OfferPayload,
  PushPayload,
  SectionPayload,
  TrendingDestinationPayload,
} from '../context/useDynamicSections';

import ScheduleBannerForm from './ScheduleForms/ScheduleBannerForm';
import ScheduleBannerSectionForm from './ScheduleForms/ScheduleBannerSectionForm';
import ScheduleCallToActionSectionForm from './ScheduleForms/ScheduleCallToActionSectionForm';
import ScheduleDreamSectionForm from './ScheduleForms/ScheduleDreamSectionForm';
import ScheduleFlightSectionForm from './ScheduleForms/ScheduleFlightSectionForm';
import ScheduleOfferSectionForm from './ScheduleForms/ScheduleOfferSectionForm';
import SchedulePushContentSectionForm from './ScheduleForms/SchedulePushContentSectionForm';
import ScheduleTrendingDestinationSectionForm from './ScheduleForms/ScheduleTrendingDestinationSectionForm';

export type SectionComponentType =
  | typeof ScheduleBannerForm
  | typeof SchedulePushContentSectionForm
  | typeof ScheduleOfferSectionForm
  | typeof ScheduleFlightSectionForm
  | typeof ScheduleBannerSectionForm
  | typeof ScheduleDreamSectionForm
  | typeof ScheduleTrendingDestinationSectionForm
  | typeof ScheduleCallToActionSectionForm;

export type SectionType = {
  id: DynamicSectionType;
  label: string;
  limit: number;
  component: SectionComponentType;
};

export const ALLOWED_SECTIONS: Array<SectionType> = [
  {
    id: DynamicSectionType.OFFER,
    label: 'Offer Section',
    limit: 20,
    component: ScheduleOfferSectionForm,
  },
  {
    id: DynamicSectionType.FLIGHT,
    label: 'Flight Section',
    limit: 20,
    component: ScheduleFlightSectionForm,
  },
  {
    id: DynamicSectionType.BANNER,
    label: 'Banner',
    limit: 20,
    component: ScheduleBannerSectionForm,
  },
  {
    id: DynamicSectionType.DREAM_ARTICLE,
    label: 'Dream Section',
    limit: 20,
    component: ScheduleDreamSectionForm,
  },
  {
    id: DynamicSectionType.TRENDING_DESTINATION,
    label: 'Trending Destinations',
    limit: 20,
    component: ScheduleTrendingDestinationSectionForm,
  },
  {
    id: DynamicSectionType.CALL_TO_ACTION,
    label: 'Call to Action',
    limit: 20,
    component: ScheduleCallToActionSectionForm,
  },
  {
    id: DynamicSectionType.DEFAULT_PUSH,
    label: 'Push Notification - Default',
    limit: 1,
    component: SchedulePushContentSectionForm,
  },
  {
    id: DynamicSectionType.EVENING_PUSH,
    label: 'Push Notification - Evening',
    limit: 1,
    component: SchedulePushContentSectionForm,
  },
];

export const getAvailableSections = (isDestination: boolean): Array<SectionType> => {
  return isDestination
    ? ALLOWED_SECTIONS
    : ALLOWED_SECTIONS.filter((section) => section.id === DynamicSectionType.EVENING_PUSH);
};

interface DynamicSectionProps {
  section: {
    id: string;
    type: DynamicSectionType;
    position: number;
    payload: SectionPayload;
  };
  sectionType: SectionType;
  onDeleteClick: (id: string) => void;
  onSectionTypeChange: (id: string, newType: DynamicSectionType) => void;
  onPositionChange: (id: string, newPosition: number) => void;
  busy?: boolean;
  isGlobalDragging?: boolean;
  isExpanded?: boolean;
  formRef?: (element: HTMLFormElement | null) => void;
  brand: string;
  countryGroupId: string;
  countryId: string;
  sendDate: Dayjs;
  allowedSections?: Array<SectionType>;
}

function DynamicSection({
  section,
  sectionType,
  onDeleteClick,
  onSectionTypeChange,
  onPositionChange,
  busy = false,
  isGlobalDragging = false,
  isExpanded = true,
  formRef,
  brand,
  countryGroupId,
  countryId,
  sendDate,
  allowedSections,
}: DynamicSectionProps): JSX.Element {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: section.id,
    data: {
      type: 'section',
      section,
    },
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
  };

  const handleTypeChange = (event) => {
    onSectionTypeChange(section.id, event.target.value as DynamicSectionType);
  };

  const handlePositionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPosition = parseInt(event.target.value, 10);
    if (!isNaN(newPosition) && newPosition > 0) {
      onPositionChange(section.id, newPosition);
    }
  };

  const renderComponent = () => {
    switch (sectionType.component) {
      case SchedulePushContentSectionForm: {
        const pushPayload = section.payload as PushPayload;
        return (
          <SchedulePushContentSectionForm
            ref={formRef}
            titleDefaultValue={pushPayload.pushTitle ?? ''}
            subtitleDefaultValue={pushPayload.pushSubtitle ?? ''}
            messageDefaultValue={pushPayload.pushMessage ?? ''}
            linkDefaultValue={pushPayload.pushLink ?? ''}
            imageIdDefaultValue={pushPayload.pushSvcImageId ?? ''}
            countryGroupId={countryGroupId}
            countryId={countryId}
            offerId={undefined}
            showPrefillButton={false}
          />
        );
      }

      case ScheduleBannerSectionForm: {
        const bannerPayload = section.payload as BannerPayload;
        return (
          <ScheduleBannerSectionForm
            ref={formRef}
            existingBannerName={bannerPayload.bannerName}
            existingBannerUrl={bannerPayload.bannerUrl}
            existingWebImageId={bannerPayload.desktopImageId}
            existingMobileImageId={bannerPayload.mobileImageId}
          />
        );
      }

      case ScheduleDreamSectionForm: {
        const dreamPayload = section.payload as DreamPayload;
        return (
          <ScheduleDreamSectionForm
            ref={formRef}
            existingTitle={dreamPayload.heading}
            existingSubheading={dreamPayload.subheading}
            existingArticleUrl={dreamPayload.articleUrl}
            existingWebImageId={dreamPayload.desktopImageId}
            existingMobileImageId={dreamPayload.mobileImageId}
            existingDestinationName={dreamPayload.destination}
          />
        );
      }

      case ScheduleOfferSectionForm: {
        const offerPayload = section.payload as OfferPayload;
        return (
          <ScheduleOfferSectionForm
            ref={formRef}
            sendDate={sendDate}
            brand={brand}
            countryGroupId={countryGroupId}
            countryId={countryId}
            existingOfferIds={offerPayload.offerIds}
            existingHeading={offerPayload.heading}
          />
        );
      }

      case ScheduleFlightSectionForm: {
        const flightPayload = section.payload as FlightPayload;
        const flights = flightPayload.flights.map((flight) => ({
          id: `${flight.departingCity}-${flight.arrivalCity}-${flight.tripType}`,
          departingCity: flight.departingCity,
          arrivalCity: flight.arrivalCity,
          pricePerPerson: flight.pricePerPerson,
          tripType: flight.tripType,
          url: flight.url,
          imageId: flight.imageId,
        }));

        return (
          <ScheduleFlightSectionForm ref={formRef} existingHeading={flightPayload.heading} existingFlights={flights} />
        );
      }

      case ScheduleTrendingDestinationSectionForm: {
        const trendingPayload = section.payload as TrendingDestinationPayload;
        const destinations = trendingPayload.destinations.map((destination) => ({
          id: `${destination.name}-${destination.redirectUrl}`,
          name: destination.name,
          redirectUrl: destination.redirectUrl,
          imageId: destination.imageId ?? '',
        }));

        return (
          <ScheduleTrendingDestinationSectionForm
            ref={formRef}
            existingHeading={trendingPayload.heading}
            existingDestinations={destinations}
          />
        );
      }

      case ScheduleCallToActionSectionForm: {
        const ctaPayload = section.payload as CallToActionPayload;
        return (
          <ScheduleCallToActionSectionForm ref={formRef} existingText={ctaPayload.text} existingUrl={ctaPayload.url} />
        );
      }

      default:
        return null;
    }
  };

  return (
    <Paper
      ref={setNodeRef}
      variant="outlined"
      sx={{
        p: 2,
        position: 'relative',
        mb: 2,
        ...style,
        backgroundColor: isDragging ? 'action.hover' : 'background.paper',
        boxShadow: isDragging ? 4 : 1,
      }}
    >
      <Stack direction="column" gap={2}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <IconButton
            {...attributes}
            {...listeners}
            size="small"
            sx={{
              cursor: isDragging ? 'grabbing' : 'grab',
              '&:active': { cursor: 'grabbing' },
              color: isDragging ? 'primary.main' : 'text.secondary',
            }}
          >
            <DragIndicatorIcon />
          </IconButton>
          <FormControl sx={{ flex: 1 }}>
            <InputLabel>Section</InputLabel>
            <Select
              value={section.type}
              onChange={handleTypeChange}
              input={<OutlinedInput label="Section" />}
              fullWidth
            >
              {allowedSections.map((type) => (
                <MenuItem key={type.id} value={type.id}>
                  {type.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <OutlinedInput
            type="number"
            value={section.position}
            onChange={handlePositionChange}
            size="small"
            sx={{
              width: '70px',
              '& input': {
                p: 0.5,
                textAlign: 'center',
              },
            }}
            inputProps={{
              min: 1,
              style: { textAlign: 'center' },
            }}
          />
          <IconButton onClick={() => onDeleteClick(section.id)} color="error" size="small">
            <DeleteIcon />
          </IconButton>
        </Stack>
        <Stack
          sx={{
            height: isGlobalDragging || !isExpanded ? 0 : 'auto',
            overflow: 'hidden',
          }}
        >
          {renderComponent()}
        </Stack>
      </Stack>
      <Backdrop open={busy} sx={{ position: 'absolute' }}>
        <CircularProgress color="primary" />
      </Backdrop>
    </Paper>
  );
}

export default DynamicSection;
