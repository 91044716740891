import React from 'react';

import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';

type BannerPreviewIconProps = {
  icon: 'check' | 'info' | 'warning' | 'error';
};

export function BannerPreviewIcon({ icon }: BannerPreviewIconProps) {
  switch (icon) {
    case 'check':
      return <CheckIcon fontSize="inherit" />;
    case 'info':
      return <InfoIcon fontSize="inherit" />;
    case 'warning':
      return <WarningIcon fontSize="inherit" />;
    case 'error':
      return <ErrorIcon fontSize="inherit" />;
    default:
      return null;
  }
}
