import React from 'react';

import { NavLink } from 'react-router-dom';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  Stack,
} from '@mui/material';

import { APICallProps, useProgressPolling } from '~/components/Accommodation/hooks/useProgressPolling';
import Spinner from '~/components/Common/Spinner';

import { ProgressStatusResponse } from '~/services/AccommodationService';
import { HttpResponse } from '~/services/types';

type Props = {
  open: boolean;
  apiCall: (props: APICallProps) => Promise<HttpResponse<ProgressStatusResponse>>;
  onClose: () => void;
};

function AccommodationCreatePropertyModal({ open, apiCall, onClose }: Props) {
  const { isLoading, isComplete, errorMessage, generatedPropertyId, runStartJob, resetAndClose } = useProgressPolling({
    apiCall,
    onClose,
  });

  return (
    <Dialog open={open} onClose={resetAndClose}>
      <DialogTitle>Create a new property</DialogTitle>
      <DialogContent sx={{ minWidth: '400px' }}>
        <Stack spacing={2}>
          {!isLoading && !isComplete && (
            <DialogContentText>This will create a new property. Are you sure you want to continue?</DialogContentText>
          )}
          {isComplete && <DialogContentText color="primary">Property created successfully</DialogContentText>}
          {errorMessage && <DialogContentText color="error">{errorMessage}</DialogContentText>}
        </Stack>
      </DialogContent>
      {!isComplete && (
        <DialogActions>
          <Button variant="text" onClick={resetAndClose} disabled={isLoading}>
            Cancel
          </Button>
          <Button variant="contained" onClick={() => runStartJob()} disabled={isLoading}>
            {isLoading ? <Spinner size={15} inline /> : 'Create'}
          </Button>
        </DialogActions>
      )}
      {isComplete && (
        <DialogActions>
          <Stack direction="row" spacing={2} alignItems="center">
            {generatedPropertyId && (
              <Link component={NavLink} to={`/accommodation/properties/${generatedPropertyId}`} target="_blank">
                Open
              </Link>
            )}
            <Button variant="contained" onClick={resetAndClose} disabled={isLoading}>
              Close
            </Button>
          </Stack>
        </DialogActions>
      )}
    </Dialog>
  );
}

export default AccommodationCreatePropertyModal;
