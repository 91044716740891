import React from 'react';

import { Stack } from '@mui/material';

import { AccommodationTabs } from '~/components/Accommodation/Pages/Home/AccommodationHomePage';
import AccommodationPropertiesResultsTable from '~/components/Accommodation/Pages/Properties/components/AccommodationPropertiesResultsTable';
import AccommodationPropertiesSearchForm from '~/components/Accommodation/Pages/Properties/components/AccommodationPropertiesSearchForm';
import usePagedSearch from '~/components/Accommodation/hooks/usePagedSearch';

function AccommodationPropertiesResultsContainer() {
  const { searchInput, pageNumber, handleSearchFormSubmission, handlePageChange } = usePagedSearch();

  return (
    <Stack direction="column" spacing={2}>
      <AccommodationPropertiesSearchForm
        initialInput={searchInput}
        onSubmit={handleSearchFormSubmission}
        selectedTab={AccommodationTabs.PROPERTIES}
      />
      <AccommodationPropertiesResultsTable
        searchInput={searchInput}
        pageIndex={pageNumber - 1}
        onPageChange={handlePageChange}
      />
    </Stack>
  );
}

export default AccommodationPropertiesResultsContainer;
