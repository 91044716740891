import React, { FormEventHandler, useCallback, useState } from 'react';

import { useSnackbar } from 'notistack';

import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormGroup,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { copyTourContent } from '~/services/ToursService';

import ErrorDisplay from '../../Common/ErrorDisplay';

interface Props {
  tourId: string;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

function CopyTourModal({ tourId, open, onClose, onSuccess }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState<{ message?: string } | undefined>();
  const [isCopying, setIsCopying] = useState(false);

  const copyContentTour = useCallback<FormEventHandler<HTMLFormElement>>(
    async (event) => {
      event.preventDefault();
      const copyTourId = event.currentTarget.copyTourId.value;
      try {
        setIsCopying(true);
        await copyTourContent(tourId, copyTourId);
        enqueueSnackbar('Successfully copied', { variant: 'success' });
        onSuccess();
        onClose();
      } catch (error) {
        setError(error);
        enqueueSnackbar(error.message, { variant: 'error' });
      } finally {
        setIsCopying(false);
      }
    },
    [tourId],
  );

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variant="h6">Copy tour content from another tour</Typography>
          <IconButton aria-label="Close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography variant="subtitle2" gutterBottom>
          Please note it will copy tour details, tour options, itineraries, locations, images, fine print and hotel
          recommandations. There are some restrictions: this only applies to Salesforce offers, both tours must have the
          same tour options (Base and extended), and the tour should not include itineraries or images to prevent
          overwriting existing content.
        </Typography>

        <form onSubmit={copyContentTour}>
          <FormGroup>
            {error && <ErrorDisplay message={error.message} />}
            <Grid container spacing={2} alignItems="center">
              <Grid sm>
                <TextField name="copyTourId" type="text" placeholder="tour ID here" fullWidth />
              </Grid>
              <Grid>
                <Button type="submit" color="primary" variant="contained" disabled={isCopying}>
                  Copy
                </Button>
              </Grid>
            </Grid>
          </FormGroup>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default CopyTourModal;
