import { useCallback, useEffect, useMemo } from 'react';

import { useDispatch } from 'react-redux';
import { getRecordKeyFor } from '~/reducers/customerCommunicationReducer';

import fetchCustomScheduleDestinations from '~/actions/customerCommunication/fetchCustomScheduleDestinations';

import { useAppSelector } from '../store';

function useCustomScheduleDestinations(brandId: string) {
  const [key, params] = useMemo(() => {
    const params: Parameters<typeof fetchCustomScheduleDestinations> = [brandId];
    return [getRecordKeyFor(...params), params];
  }, [brandId]);

  const dataReq = useAppSelector((state) => state.customerCommunication.customScheduleDestinationsRecords[key]);

  const appDispatch = useDispatch();
  const fetchDestinations = useCallback(() => {
    appDispatch(fetchCustomScheduleDestinations(...params));
  }, [appDispatch, params]);

  useEffect(() => {
    fetchDestinations();
  }, [fetchDestinations]);

  return { dataReq, fetchDestinations };
}

export default useCustomScheduleDestinations;
