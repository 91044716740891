import { useCallback, useState } from 'react';

import {
  ProgressStatusResponse,
  ValidationErrorCodes,
  ValidationErrorDetails,
  getProgressStatuses,
} from '~/services/AccommodationService';
import { HttpResponse } from '~/services/types';

import asyncPoll from '~/utils/asyncPoll';

export interface APICallProps {
  validationOverrides?: Array<ValidationErrorCodes>;
  internalPropertyId?: string;
}

interface UseProgressPollingProps {
  apiCall: (props: APICallProps) => Promise<HttpResponse<ProgressStatusResponse>>;
  onClose: () => void;
}

interface ProgressState {
  isLoading: boolean;
  isComplete: boolean;
  statusMessage: string | null;
  errorMessage: string | null;
  propertyImportSvcRes: ProgressStatusResponse | null;
  propertyImportSvcBedbank: ProgressStatusResponse | null;
  generatedPropertyId: string | null;
  createBaseProperty: ProgressStatusResponse | null;
  validationErrors: Array<ValidationErrorDetails>;
}

export function useProgressPolling({ apiCall, onClose }: UseProgressPollingProps) {
  const [state, setState] = useState<ProgressState>({
    isLoading: false,
    isComplete: false,
    statusMessage: null,
    errorMessage: null,
    propertyImportSvcRes: null,
    propertyImportSvcBedbank: null,
    generatedPropertyId: null,
    createBaseProperty: null,
    validationErrors: [],
  });

  const TWO_MINUTES_IN_MS = 60000 * 2;
  const POLL_RATE_IN_MS = 500;

  const handleError = useCallback((message: string) => {
    setState((prev) => ({
      ...prev,
      errorMessage: message,
      statusMessage: null,
      isLoading: false,
    }));
  }, []);

  const resetAndClose = useCallback(() => {
    setState({
      isLoading: false,
      isComplete: false,
      statusMessage: null,
      errorMessage: null,
      propertyImportSvcRes: null,
      propertyImportSvcBedbank: null,
      generatedPropertyId: null,
      createBaseProperty: null,
      validationErrors: [],
    });
    onClose();
  }, [onClose]);

  const setJobStatuses = useCallback(
    (result: Array<ProgressStatusResponse>) => {
      for (const status of result) {
        setState((prev) => {
          const newState = { ...prev };
          switch (status.type) {
            case 'propertyImportSvcRes':
              newState.propertyImportSvcRes = status;
              break;
            case 'propertyImportSvcBedbank':
              newState.propertyImportSvcBedbank = status;
              break;
            case 'createBaseProperty':
              newState.createBaseProperty = status;
              newState.generatedPropertyId = status.meta?.propertyId;
              break;
          }
          return newState;
        });

        if (status.state === 'failed') {
          handleError(status.message);
        }
      }
    },
    [handleError],
  );

  const pollImportStatus = useCallback(
    async (id: string) => {
      try {
        await asyncPoll({
          apiCall: () => getProgressStatuses(id),
          validateFunction: async ({ result }) => {
            const propertyImport = result.find((status) => status.type === 'propertyImport');
            if (!propertyImport) {
              return false;
            }
            setJobStatuses(result);
            if (propertyImport.state === 'completed') {
              setState((prev) => ({
                ...prev,
                isLoading: false,
                isComplete: true,
                statusMessage: propertyImport.message,
              }));
              return true;
            } else if (propertyImport.state === 'failed') {
              setState((prev) => ({
                ...prev,
                isLoading: false,
                isComplete: true,
              }));
              return true;
            } else {
              return false;
            }
          },
          maxTime: TWO_MINUTES_IN_MS,
          pollInterval: POLL_RATE_IN_MS,
        });
      } catch (e) {
        handleError(e.message);
        setState((prev) => ({ ...prev, isLoading: false }));
        return;
      }
    },
    [TWO_MINUTES_IN_MS, handleError, setJobStatuses],
  );

  const runStartJob = useCallback(
    async (validationOverrides?: Array<ValidationErrorCodes>) => {
      setState((prev) => ({
        ...prev,
        isLoading: true,
        isComplete: false,
        errorMessage: null,
        validationErrors: [],
      }));

      try {
        const { result } = await apiCall({ validationOverrides });
        if (!result?.id) {
          handleError('Failed to start job');
          return;
        }
        pollImportStatus(result.id);
      } catch (e) {
        if (e.status === 409) {
          setState((prev) => ({
            ...prev,
            validationErrors: e.errors,
            isLoading: false,
          }));
        } else {
          setState((prev) => ({
            ...prev,
            errorMessage: e.message,
            isLoading: false,
          }));
        }
      }
    },
    [pollImportStatus, handleError, apiCall],
  );

  return {
    ...state,
    runStartJob,
    resetAndClose,
  };
}
