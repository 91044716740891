import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { WidgetProps } from '@rjsf/utils';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

import { CircularProgress, FormControl, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { debounce } from '@mui/material/utils';

import UsersService from '~/services/UsersService';

interface UserOption {
  label: string;
  value: string;
}

interface userResult {
  email: string;
  full_name: string;
  id_member: string;
}

interface Props extends Omit<WidgetProps, 'onChange' | 'onBlur'> {
  formErrors: Array<string>;
  onChange: (id: string, value: string) => void;
  loading?: boolean;
  passedAutocompleteValue?: string;
  setPassedAutocompleteValue?: React.Dispatch<React.SetStateAction<string>>;
  clearOnBlur?: boolean;
  showOnlyEmail?: boolean;
}

function UserSearchWidget({
  id,
  required,
  readonly,
  disabled,
  label,
  onChange,
  formErrors,
  loading,
  passedAutocompleteValue = null,
  setPassedAutocompleteValue,
  clearOnBlur = false,
  showOnlyEmail = false,
}: Props) {
  const brand = useSelector((state: App.State) => state.tenant.brand);
  const { enqueueSnackbar } = useSnackbar();

  const [loadingState, setLoadingState] = useState<Utils.FetchingState>('idle');
  const [userSuggestions, setUserSuggestions] = useState<Array<userResult>>([]);

  const requestRef = useRef<AbortController>();
  const inputRef = useRef<HTMLInputElement>(null);

  const isLoading = loadingState === 'loading';

  useEffect(() => {
    if (!passedAutocompleteValue) {
      setUserSuggestions([]);
    }
  }, [passedAutocompleteValue]);

  const userOptions = useMemo<Array<UserOption>>(
    () =>
      userSuggestions.map((user) => ({
        label: showOnlyEmail ? user.email : `${user.full_name} - ${user.email}`,
        value: user.id_member,
      })),
    [userSuggestions, showOnlyEmail],
  );

  const fetchUsers = useCallback(
    async (query: string) => {
      if (!query || query.length < 2) {
        return;
      }

      try {
        setLoadingState('loading');

        if (requestRef.current) {
          requestRef.current.abort();
        }

        requestRef.current = new AbortController();
        const suggestions = await UsersService.getUsers(
          {
            filter: query,
            brand: brand,
          },
          { signal: requestRef.current.signal },
        );

        setUserSuggestions(suggestions);
        setLoadingState('success');
        requestRef.current = undefined;
      } catch (e) {
        if (e.name === 'AbortError') {
          return;
        } // ignore abort errors

        setLoadingState('idle');
        enqueueSnackbar(`There was an error while fetching users [${JSON.stringify(e, null, 2)}]`, {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar, brand],
  );

  const debouncedFetchUsers = useMemo(() => debounce(fetchUsers, 500), [fetchUsers]);

  const getUsers = useCallback((query: string) => debouncedFetchUsers(query), [debouncedFetchUsers]);

  const onInputChange = useCallback(
    async (_, value: string) => {
      if (value) {
        await getUsers(value);
      }
    },
    [getUsers],
  );

  const onEnterPress = useCallback(
    async (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key !== 'Enter') {
        return;
      }

      const currentInput = inputRef.current.value.trim();

      if (!currentInput.length) {
        return;
      }

      try {
        setLoadingState('loading');

        const suggestions = await UsersService.getUsers({
          filter: currentInput,
          brand,
        });
        setLoadingState('success');

        if (suggestions.length === 1) {
          onChange(id, suggestions[0].id_member);
        }

        if (suggestions.length > 1) {
          setUserSuggestions(suggestions);
        }
      } finally {
        setLoadingState('idle');
      }
    },
    [onChange, brand, id],
  );

  return (
    <FormControl error={formErrors?.includes(id)} sx={{ minWidth: 240 }} fullWidth>
      <Autocomplete
        id={id}
        clearOnBlur={clearOnBlur}
        multiple={false}
        freeSolo
        disabled={disabled}
        readOnly={readonly}
        options={userOptions}
        loading={isLoading}
        loadingText="Looking through our users..."
        getOptionLabel={(option: UserOption) => option?.label ?? option.toString()}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        filterOptions={(x) => x}
        noOptionsText="No users found"
        onKeyUp={onEnterPress}
        onChange={(e, newOption: UserOption) => {
          onChange(id, newOption?.value ?? '');
          if (setPassedAutocompleteValue) {
            setPassedAutocompleteValue(newOption?.label ?? '');
          }
        }}
        onInputChange={onInputChange}
        renderInput={(params) => (
          <TextField
            {...params}
            inputRef={inputRef}
            name={id}
            required={required}
            label={label}
            fullWidth
            data-testid="Search"
            error={formErrors?.includes(id)} // Added to highlight the errored field red.
            helperText={formErrors?.includes(id) ? 'Please select value from a dropdown' : undefined}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading || isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        slotProps={{ paper: { elevation: 1 } }}
      />
    </FormControl>
  );
}

export default UserSearchWidget;
