import { combineReducers } from 'redux';

import badges from './badges';
import bedbank from './bedbank';
import cart from './cart';
import currentUser from './currentUser';
import customerCommunicationReducer from './customerCommunicationReducer';
import dispute from './dispute';
import insurance from './insurance';
import order from './order';
import tenant from './tenant';
import user from './user';

const rootReducer = combineReducers({
  order,
  user,
  cart,
  insurance,
  tenant,
  badges,
  currentUser,
  customerCommunication: customerCommunicationReducer,
  bedbankReservation: bedbank,
  dispute,
});

export default rootReducer;
