import React from 'react';

import { Box, Tooltip } from '@mui/material';

import { Payment } from '~/types/services/payment';

import { StripePaymentLine } from './StripePaymentLine';

interface Props {
  payment: Payment;
}

function PaymentLine({ payment }: Props) {
  if (payment.type === 'stripe') {
    return <StripePaymentLine payment={payment} />;
  }

  return (
    <Box>
      {Math.abs(Number(payment.amount)).toLocaleString('en-US', { minimumFractionDigits: 0 })} {payment.type}{' '}
      {payment.type == 'credit' && parseFloat(payment.amount) > 0 && (
        <Tooltip title="Fraudsters want to spend credit, not their own money. The higher percentage of credit, the higher risk of fraud.">
          <span>🟠</span>
        </Tooltip>
      )}
      {payment.status !== 'completed' && payment.status}
    </Box>
  );
}

export { PaymentLine };
