import React, { useCallback } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';

import { useAccommodationServiceRequest } from '~/components/Accommodation/hooks/useAccommodationServiceRequest';
import Spinner from '~/components/Common/Spinner';

import { InternalProperty, patchInternalProperty } from '~/services/AccommodationService';

interface Props {
  propertyId: string;
  internalProperty: InternalProperty;
  onClose: () => void;
  onComplete: (internalProperty: InternalProperty) => void;
}

export default function AccommodationPropertySupplierRatesEnabledModal({
  propertyId,
  internalProperty,
  onClose,
  onComplete,
}: Props) {
  const newSppEnabledValue = !internalProperty.sppEnabled;

  const apiReq = useCallback(() => {
    return patchInternalProperty(
      { propertyId, internalPropertyId: internalProperty.id },
      { sppEnabled: newSppEnabledValue },
    );
  }, [newSppEnabledValue, propertyId, internalProperty.id]);

  const onSuccess = useCallback(
    (result: InternalProperty) => {
      onComplete(result);
    },
    [onComplete],
  );

  const { reqState, doRequest } = useAccommodationServiceRequest({ apiReq, onSuccess });

  const handleConfirm = useCallback(() => {
    doRequest();
  }, [doRequest]);

  return (
    <Dialog open>
      <DialogTitle>{newSppEnabledValue ? 'Enabling' : 'Disabling'} rates</DialogTitle>
      <DialogContent>
        {reqState.status === 'failed' && <DialogContentText color="error">{reqState.error}</DialogContentText>}
        <Box my={2}>
          <Typography>
            This action will {newSppEnabledValue ? 'enable' : 'disable'} rates for the {internalProperty.source}{' '}
            property ({internalProperty.name} - {internalProperty.sourceId}).{' '}
            {newSppEnabledValue
              ? 'Allowing them to be shown on the customer portal.'
              : 'Hiding them on the customer portal.'}
          </Typography>
        </Box>
        <DialogActions>
          <Button onClick={onClose} variant="text" disabled={reqState.status === 'pending'}>
            Cancel
          </Button>
          <Button onClick={handleConfirm} variant="contained" disabled={reqState.status === 'pending'}>
            {reqState.status === 'pending' ? <Spinner size={15} inline /> : 'Confirm'}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
