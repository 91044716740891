import React, { FormEventHandler, useCallback, useRef, useState } from 'react';

import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';

import { CollectionReason, RefundMetadata, VendorRefundTypes } from '../../types';
import CaseIDAndReasons from '../Common/CaseIDAndReasons';
import EditableShareOfRefund from '../Common/EditableShareOfRefund';
import MarkCancelled from '../Common/MarkCancelled';
import RefundSurcharge from '../Common/RefundSurcharge';
import ShareOfRefund from '../Common/ShareOfRefund';
import { getCostPrice } from '../Utils/RefundUtils';

import BNBLSelectNights from './CustomRefund/BNBLSelectNights';
import CustomAmount from './CustomRefund/CustomAmount';
import SelectNights from './CustomRefund/SelectNights';

export enum RefundTabs {
  FULL_REFUND_WITH_FEE,
  CUSTOM_REFUND,
}

interface Props {
  checkIn?: string;
  checkOut?: string;
  currencyCode: string;
  decrementStep: () => void;
  defaultCaseId: string;
  defaultComment: string;
  handleLateChangeOfMindFieldChange: (formData) => void;
  hasSurcharge?: boolean;
  LEContribution: number;
  onSubmit: FormEventHandler<HTMLFormElement>;
  reason: CollectionReason;
  refundMetadata: RefundMetadata;
  reservationId?: string;
  surchargeAmount?: number;
  vendorContribution: number;
}

export default function LateChangeOfMindBadExperienceOrUnfortunateEvents({
  checkIn,
  checkOut,
  currencyCode,
  decrementStep,
  defaultCaseId,
  defaultComment,
  handleLateChangeOfMindFieldChange,
  hasSurcharge = false,
  LEContribution,
  onSubmit,
  reason,
  refundMetadata,
  reservationId,
  surchargeAmount,
  vendorContribution,
}: Props) {
  const isBNBL = !checkIn && !checkOut;
  const [feeOrCustom, setFeeOrCustom] = useState<RefundTabs>(RefundTabs.FULL_REFUND_WITH_FEE);
  const [percentageOrNumber, setPercentageOrNumber] = useState<VendorRefundTypes>(VendorRefundTypes.percentage);
  const [customerRefundAmount, setCustomerRefundAmount] = useState('');
  const formRef = useRef(null);
  const maxRefundAmount = refundMetadata.item_metadata.cash_amount;
  const costPrice = getCostPrice(refundMetadata);
  const handleFeeOrCustomChange = useCallback((_, value: number) => {
    setFeeOrCustom(value);
  }, []);

  const handlePercentageOrNumberChange = useCallback(
    (_, value: VendorRefundTypes) => {
      const formData = new FormData(formRef.current);
      setPercentageOrNumber(value);
      formData.set('percentageOrNumber', value);
      if (value === VendorRefundTypes.percentage) {
        // values will default to 100%
        formData.set('percentageRefundToCustomer', maxRefundAmount.toString());
        formData.set('percentageRefund', '100');
      } else {
        // values will default to 0
        formData.set('numberOfNights', '0');
        formData.set('totalRefundedToCustomer', '0');
      }
      handleLateChangeOfMindFieldChange(formData);
    },
    [handleLateChangeOfMindFieldChange, maxRefundAmount],
  );

  const handleCustomVendorContribution = useCallback(
    (newValue: string) => {
      const formData = new FormData(formRef.current);
      formData.set('customVendorContribution', newValue);
      handleLateChangeOfMindFieldChange(formData);
    },
    [handleLateChangeOfMindFieldChange],
  );

  const handleCustomerRefundAmountChange = useCallback((newAmount: string) => {
    setCustomerRefundAmount(newAmount);
  }, []);

  return (
    <>
      <DialogContent dividers>
        <form onSubmit={onSubmit} ref={formRef} id="late-change-of-mind-form">
          <CaseIDAndReasons reason={reason} defaultComment={defaultComment} defaultCaseId={defaultCaseId} />
          {/* Full Refund With Fee, Or Custom Refund */}
          <Stack spacing={1}>
            <Box>
              <Tabs value={feeOrCustom} onChange={handleFeeOrCustomChange}>
                <Tab
                  label="Full Refund with Fee"
                  value={RefundTabs.FULL_REFUND_WITH_FEE}
                  data-cy="Full Refund With Fee"
                />
                <Tab label="Custom Refund" value={RefundTabs.CUSTOM_REFUND} data-cy="Custom Refund" />
              </Tabs>
              {/* Hidden input to hold the feeOrCustom tab value */}
              <input type="hidden" name="feeOrCustom" value={feeOrCustom} />
            </Box>
            {feeOrCustom === RefundTabs.FULL_REFUND_WITH_FEE && (
              <Stack>
                <TextField
                  type="number"
                  name="refundFee"
                  label="Fee Charged to Customer"
                  data-cy="FeeChargedToCustomer"
                  required
                  inputProps={{
                    min: 0,
                    max: maxRefundAmount,
                    step: '.01',
                  }}
                />
              </Stack>
            )}
            {feeOrCustom === RefundTabs.CUSTOM_REFUND && (
              <>
                <FormControl>
                  <RadioGroup
                    name="percentageOrNumber"
                    row
                    value={percentageOrNumber}
                    onChange={handlePercentageOrNumberChange}
                  >
                    <FormControlLabel
                      data-cy="CustomAmount"
                      value={VendorRefundTypes.percentage}
                      control={
                        <Radio
                          inputProps={{
                            required: true,
                          }}
                        />
                      }
                      label="Custom Amount"
                    />
                    <FormControlLabel
                      key="select-nights"
                      value={VendorRefundTypes.number}
                      data-cy="SelectNights"
                      control={
                        <Radio
                          inputProps={{
                            required: true,
                          }}
                        />
                      }
                      label="Select Nights"
                    />
                  </RadioGroup>
                </FormControl>
                {percentageOrNumber === VendorRefundTypes.percentage && (
                  <>
                    <CustomAmount
                      maxRefundAmount={maxRefundAmount}
                      formRef={formRef}
                      handleLateChangeOfMindFieldChange={handleLateChangeOfMindFieldChange}
                      onCustomerRefundAmountChange={handleCustomerRefundAmountChange}
                    />
                    <Divider variant="fullWidth" />
                    <Typography variant="h6" marginTop={2}>
                      Share of Refund
                    </Typography>
                    <EditableShareOfRefund
                      vendorContribution={vendorContribution}
                      LEContribution={LEContribution}
                      handleCustomVendorContribution={handleCustomVendorContribution}
                      costPrice={costPrice}
                      customerRefundAmount={customerRefundAmount}
                    />
                  </>
                )}

                {percentageOrNumber === VendorRefundTypes.number && (
                  <>
                    {isBNBL && (
                      <BNBLSelectNights
                        refundMetadata={refundMetadata}
                        numberOfNights={refundMetadata.number_of_nights}
                        currencyCode={currencyCode}
                        formRef={formRef}
                        handleLateChangeOfMindFieldChange={handleLateChangeOfMindFieldChange}
                      />
                    )}
                    {!isBNBL && (
                      <SelectNights
                        refundMetadata={refundMetadata}
                        checkInDate={checkIn}
                        checkOutDate={checkOut}
                        currencyCode={currencyCode}
                        formRef={formRef}
                        reservationId={reservationId}
                        maxRefundAmount={maxRefundAmount}
                        handleLateChangeOfMindFieldChange={handleLateChangeOfMindFieldChange}
                      />
                    )}
                    <Divider variant="fullWidth" />
                    <Typography variant="h6" marginTop={2}>
                      Share of Refund
                    </Typography>
                    <ShareOfRefund vendorContribution={vendorContribution} LEContribution={LEContribution} />
                  </>
                )}
              </>
            )}
            <Stack spacing={2} direction="column">
              {hasSurcharge && <RefundSurcharge surchargeAmount={surchargeAmount} currencyCode={currencyCode} />}
              <MarkCancelled />
            </Stack>
          </Stack>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={decrementStep}>Back</Button>
        <Button type="submit" variant="contained" form="late-change-of-mind-form">
          Continue
        </Button>
      </DialogActions>
    </>
  );
}
