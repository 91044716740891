import React from 'react';

import { Box, Stack, Typography } from '@mui/material';

interface Props {
  actualMargin: string;
  incrementalAverageMargin: { [key: string]: number };
}

export function MarginDetailCell({ actualMargin, incrementalAverageMargin }: Props) {
  return (
    <Stack direction="column" justifyContent="flex-start" alignItems="flex-start">
      {!!actualMargin && (
        <Typography>
          Actual margin: <Box component="b">{parseFloat(actualMargin)}%</Box>
        </Typography>
      )}
      {incrementalAverageMargin && (
        <>
          <Typography>Incremental margin:</Typography>
          {Object.entries(incrementalAverageMargin).map(([key, value]) => (
            <Typography>
              {key} pax: <Box component="b">{value}%</Box>
            </Typography>
          ))}
        </>
      )}
    </Stack>
  );
}
