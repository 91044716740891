import { SxProps } from '@mui/material';

export const PAGE_GUTTER_SIZE = 2;

export const unsupportedComponentStyle = (message: string): SxProps => ({
  background: 'rgba(255, 0, 0, .75)',
  outline: '2px solid red',
  outlineOffset: '2px',
  '&::before': {
    content: `"🚫${message}🚫"`,
  },
});

export const EMPTY_PAGE_COMPONENT_STYLE: SxProps = {
  '&:empty': { display: 'none' },
};

export const INVALID_PAGE_COMPONENT_DESCENDENCE: SxProps = {
  ':not(.page-layout > &)': unsupportedComponentStyle('MUST BE CHILD OF <PageLayout>'),
};
