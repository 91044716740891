import React from 'react';

import { Download } from '@mui/icons-material';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import { Box, Button, IconButton } from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';

import { FLIGHTS_TRACES_BASE_API } from '~/services/FlightsService';

import { useFlightsLogViewer } from './FlightsLogsViewerProvider';

interface Props {
  reservationId: string;
}

export function FlightsLogsViewerHeader({ reservationId }: Props) {
  const { theme, setTheme } = useFlightsLogViewer();

  return (
    <PageHeader title="Flights Logs Viewer">
      <Box display="flex" alignItems="center" gap={3}>
        <Button
          variant="contained"
          href={`${FLIGHTS_TRACES_BASE_API}/list?reservationId=${reservationId}&operation=download`}
          target="_blank"
        >
          <Download style={{ marginRight: 6 }} />
          Download Files
        </Button>

        {theme === 'dark' && (
          <IconButton onClick={() => setTheme('light')}>
            <LightModeIcon color="primary" />
          </IconButton>
        )}

        {theme === 'light' && (
          <IconButton onClick={() => setTheme('dark')}>
            <DarkModeIcon color="primary" />
          </IconButton>
        )}
      </Box>
    </PageHeader>
  );
}
