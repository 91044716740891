import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet';

import { Box, Button, Container, Typography } from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';
import PageSubheader from '~/components/Common/Elements/PageSubheader';

import VendorsService from '~/services/VendorsService';

import SalesFxHedgingLog from './SalesFxHedgingLog';

export default function SalesFxHedgingPage() {
  const [isSendingPaused, setIsSendingPaused] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);

      const data = {
        sending_paused: !isSendingPaused,
      };

      setIsSendingPaused((isSendingPaused) => !isSendingPaused);

      await VendorsService.updateSalesHedgeSettings(data);

      enqueueSnackbar('Settings saved', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(`Something went wrong processing this update: ${e.message}`, { variant: 'error' });
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const fetchSettingsData = async () => {
      try {
        const data = await VendorsService.getSalesHedgeSettings();
        const settings = data.result.settings;
        setIsSendingPaused(settings.sending_paused);
      } catch (e) {
        enqueueSnackbar(`Error fetching settings: ${e.message}`, { variant: 'error' });
      }
    };
    fetchSettingsData();
  }, [enqueueSnackbar]);

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>Sales FX Hedging</title>
      </Helmet>
      <PageHeader title="Sales FX Hedging" />
      <PageSubheader title="Toggle sending" />
      <Box sx={{ mb: 2 }}>
        <Typography>Use the following setting to enable or disable the sending of the sales file to Citi</Typography>
        <Typography sx={{ mt: 2 }} color={isSendingPaused ? 'orange' : 'green'}>
          Current setting: {isSendingPaused ? 'Disabled' : 'Enabled'}
        </Typography>
        <Button sx={{ mt: 2 }} type="submit" variant="contained" disabled={isSubmitting} onClick={handleSubmit}>
          {isSendingPaused ? 'Enable file sending' : 'Disable file sending'}
        </Button>
      </Box>
      <SalesFxHedgingLog />
    </Container>
  );
}
