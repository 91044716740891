import React from 'react';

import { Box, Typography } from '@mui/material';

import { Order } from '@luxuryescapes/contract-svc-order';

import { LUX_PLUS } from '~/consts/membership';

import { formatCurrency } from '~/utils/currencyFormatter';

interface Props {
  room: Order.BedbankItemRoom;
  currencyCode: string;
  order: Order.Order;
}

export default function BedbankPricingSummary({ room, currencyCode, order }: Props) {
  const totalBusinessCreditEarned = order.business_credit_items.reduce((accum, item) => accum + item.total, 0);

  return (
    <>
      <Typography variant="subtitle1" fontWeight="bold">
        Pricing
      </Typography>

      {!room.rebooked && (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography>
            Cost: {formatCurrency(room.cost_price, currencyCode, 'en-AU', { maximumFractionDigits: 2 })}
          </Typography>
          <Typography>
            Current Margin:{' '}
            {formatCurrency(room.price - room.cost_price, currencyCode, 'en-AU', { maximumFractionDigits: 2 })} (
            {(((room.price - room.cost_price) / room.price) * 100).toFixed(2)}%)
          </Typography>
          {order.business_id !== null && (
            <Typography>
              Business Credits: {formatCurrency(totalBusinessCreditEarned, currencyCode, 'en-AU')}
            </Typography>
          )}
          <Typography>
            Sell Price: {formatCurrency(room.price, currencyCode, 'en-AU', { maximumFractionDigits: 2 })}
          </Typography>
          {!!room.lux_plus_price && (
            <Typography>
              {LUX_PLUS.PROGRAM_NAME} Price:{' '}
              {formatCurrency(room.lux_plus_price, currencyCode, 'en-AU', { maximumFractionDigits: 2 })}
            </Typography>
          )}
          <Typography>
            {/* @dev For orders pre-LP+ for LPP feature rollout, public_price returns as zero. As there is no lux_plus_price in those cases sell price is public price */}
            Public Price:{' '}
            {formatCurrency(room.public_price || room.price, currencyCode, 'en-AU', { maximumFractionDigits: 2 })}
          </Typography>
        </Box>
      )}

      {room.rebooked && (
        <>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography pt={1} fontWeight={500}>
              Original
            </Typography>
            <Typography>
              Cost:{' '}
              {formatCurrency(room.rebooked.parent_cost_price, currencyCode, 'en-AU', { maximumFractionDigits: 2 })}
            </Typography>
            <Typography>
              Margin:{' '}
              {formatCurrency(room.rebooked.parent_marketing_fee, currencyCode, 'en-AU', { maximumFractionDigits: 2 })}{' '}
              ({((room.rebooked.parent_marketing_fee / room.rebooked.parent_price) * 100).toFixed(2)}%)
            </Typography>
            <Typography>
              Sell Price:{' '}
              {formatCurrency(room.rebooked.parent_price, currencyCode, 'en-AU', { maximumFractionDigits: 2 })}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography pt={1} fontWeight={500}>
              Rebooked
            </Typography>
            <Typography>
              Cost: {formatCurrency(room.rebooked.cost_price, currencyCode, 'en-AU', { maximumFractionDigits: 2 })}
            </Typography>
            <Typography>
              Margin:{' '}
              {formatCurrency(room.rebooked.parent_price - room.rebooked.cost_price, currencyCode, 'en-AU', {
                maximumFractionDigits: 2,
              })}{' '}
              (
              {(((room.rebooked.parent_price - room.rebooked.cost_price) / room.rebooked.parent_price) * 100).toFixed(
                2,
              )}
              %)
            </Typography>
            <Typography>
              Incremental Margin:{' '}
              {formatCurrency(
                room.rebooked.parent_price - room.rebooked.cost_price - room.rebooked.parent_marketing_fee,
                currencyCode,
                'en-AU',
                { maximumFractionDigits: 2 },
              )}{' '}
              (
              {(
                ((room.rebooked.parent_price - room.rebooked.cost_price - room.rebooked.parent_marketing_fee) /
                  room.rebooked.parent_price) *
                100
              ).toFixed(2)}
              %)
            </Typography>
          </Box>
        </>
      )}
    </>
  );
}
