import React, { MouseEventHandler, forwardRef, useCallback, useEffect, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import requestGetHeroPlannerSchedulePushContent from '~/queries/customerCommunication/requestGetHeroPlannerSchedulePushContent';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { LoadingButton } from '@mui/lab';
import { Alert, AlertTitle, Box, Button, Stack, TextField } from '@mui/material';

import { RequestStatus } from '~/consts/requestConstants';

import useImageUrlGenerator from '~/hooks/useImageUrlGenerator';

import { uploadImage } from '~/services/ImageService';

import { isRequestRejected } from '~/utils/requestUtils';
import { getCharacterCount, getWordCount } from '~/utils/stringUtils';

import { DynamicSectionType } from '../../context/useDynamicSections';

import ImageUploadBox from './Inputs/ImageUploadBox';

export enum SCHEDULE_PUSH_CONTENT_SECTION_FORM_NAMES {
  TITLE = 'schedule_push_content_title_input_name',
  SUBTITLE = 'schedule_push_content_subtitle_input_name',
  MESSAGE = 'schedule_push_content_message_input_name',
  IMAGE_ID = 'schedule_push_content_image_id_input_name',
  LINK = 'schedule_push_content_link_input_name',
}

export enum SCHEDULE_PUSH_TYPE {
  DEFAULT_PUSH = 'DEFAULT',
  EVENING_PUSH = 'EVENING',
}

interface Props {
  titleDefaultValue: string;
  subtitleDefaultValue: string;
  messageDefaultValue: string;
  linkDefaultValue: string;
  imageIdDefaultValue: string;
  countryGroupId?: string;
  countryId?: string;
  offerId?: string;
  showPrefillButton?: boolean;
}

export function parseSchedulePushContentSectionFormData(
  formData: FormData,
  scheduleType: DynamicSectionType | undefined = undefined,
) {
  const pushTitle = String(formData.get(SCHEDULE_PUSH_CONTENT_SECTION_FORM_NAMES.TITLE));
  const pushSubtitle = String(formData.get(SCHEDULE_PUSH_CONTENT_SECTION_FORM_NAMES.SUBTITLE));
  const pushMessage = String(formData.get(SCHEDULE_PUSH_CONTENT_SECTION_FORM_NAMES.MESSAGE));
  const pushLink = String(formData.get(SCHEDULE_PUSH_CONTENT_SECTION_FORM_NAMES.LINK));
  const pushSvcImageId = String(formData.get(SCHEDULE_PUSH_CONTENT_SECTION_FORM_NAMES.IMAGE_ID));

  return {
    title: pushTitle,
    subtitle: pushSubtitle,
    message: pushMessage,
    link: pushLink,
    imageId: pushSvcImageId,
    scheduleType,
  };
}

const SchedulePushContentSectionForm = forwardRef<HTMLFormElement, Props>((props, ref) => {
  const {
    titleDefaultValue,
    subtitleDefaultValue,
    messageDefaultValue,
    linkDefaultValue,
    imageIdDefaultValue,
    countryGroupId,
    countryId,
    offerId,
    showPrefillButton = true,
  } = props;

  const brand = useSelector((state: App.State) => state.tenant.brand);

  const [title, setTitle] = useState(titleDefaultValue);
  const [subtitle, setSubtitle] = useState(subtitleDefaultValue);
  const [message, setMessage] = useState(messageDefaultValue);
  const [link, setLink] = useState(linkDefaultValue);
  const [imageId, setImageId] = useState(imageIdDefaultValue);
  const [imageFile, setImageFile] = useState<File>();
  const [isUploading, setIsUploading] = useState(false);

  const imageUrl = useImageUrlGenerator(imageId);

  const [emailContentRequest, setEmailContentRequest] = useState<
    Utils.RequestState<CustomerCommunication.HeroPlannerSchedulePushContent, string>
  >({
    status: RequestStatus.INITIAL,
  });

  const { enqueueSnackbar } = useSnackbar();

  const params = useMemo(() => {
    if (brand && countryGroupId && countryId && offerId) {
      return [brand, countryGroupId, countryId, offerId] as const;
    }
  }, [brand, countryGroupId, countryId, offerId]);

  const handlePrefillClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    async (event) => {
      event.preventDefault();
      setEmailContentRequest({
        status: RequestStatus.PENDING,
        params,
      });

      try {
        const result = await requestGetHeroPlannerSchedulePushContent(...params);
        setEmailContentRequest({
          status: RequestStatus.FULFILLED,
          params,
          result,
        });
      } catch (error) {
        setEmailContentRequest({
          status: RequestStatus.REJECTED,
          params,
          error,
        });
      }
    },
    [params],
  );

  const handleUploadImage = useCallback(async () => {
    if (!imageFile) {
      enqueueSnackbar('No image file available to upload', { variant: 'warning' });
      return;
    }

    setIsUploading(true);
    try {
      const response = await uploadImage(imageFile);
      setImageId(response.body.public_id);
      setImageFile(undefined);
      enqueueSnackbar('Image uploaded successfully', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Failed to upload image', { variant: 'error' });
    } finally {
      setIsUploading(false);
    }
  }, [imageFile, enqueueSnackbar]);

  useEffect(() => {
    if (emailContentRequest.result?.title) {
      setTitle(emailContentRequest.result.title);
    }
    if (emailContentRequest.result?.subtitle) {
      setSubtitle(emailContentRequest.result.subtitle);
    }
    if (emailContentRequest.result?.message) {
      setMessage(emailContentRequest.result.message);
    }
    if (emailContentRequest.result?.imageId) {
      setImageId(emailContentRequest.result.imageId);
    }
    if (emailContentRequest.result?.link) {
      setLink(emailContentRequest.result.link);
    }
  }, [emailContentRequest.result]);

  return (
    <Stack component="form" ref={ref} direction="column" gap={2} mt={1}>
      {showPrefillButton && (
        <Box>
          <Button variant="outlined" color="secondary" disabled={!params} onClick={handlePrefillClick}>
            Prefill push content
          </Button>
          {isRequestRejected(emailContentRequest) && (
            <Alert severity="error" sx={{ mt: 1 }}>
              <AlertTitle>Problem with prefilling!</AlertTitle>
              <pre>{emailContentRequest.error}</pre>
            </Alert>
          )}
        </Box>
      )}

      <TextField
        name={SCHEDULE_PUSH_CONTENT_SECTION_FORM_NAMES.TITLE}
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        label="Title"
        multiline
        helperText={`Chars: ${getCharacterCount(title)} Words: ${getWordCount(title)}`}
      />

      <TextField
        name={SCHEDULE_PUSH_CONTENT_SECTION_FORM_NAMES.SUBTITLE}
        value={subtitle}
        onChange={(e) => setSubtitle(e.target.value)}
        label="Subtitle"
        multiline
        helperText={`Chars: ${getCharacterCount(subtitle)} Words: ${getWordCount(subtitle)}`}
      />

      <TextField
        name={SCHEDULE_PUSH_CONTENT_SECTION_FORM_NAMES.MESSAGE}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        label="Message"
        multiline
        helperText={`Chars: ${getCharacterCount(message)} Words: ${getWordCount(message)}`}
      />

      <TextField
        name={SCHEDULE_PUSH_CONTENT_SECTION_FORM_NAMES.LINK}
        value={link}
        onChange={(e) => setLink(e.target.value)}
        label="Link"
      />

      <Stack spacing={2}>
        <TextField
          name={SCHEDULE_PUSH_CONTENT_SECTION_FORM_NAMES.IMAGE_ID}
          value={imageId}
          onChange={(e) => {
            setImageId(e.target.value);
            setImageFile(undefined);
          }}
          label="Image ID"
          fullWidth
        />

        <Box sx={{ width: '50%', alignSelf: 'center' }}>
          <Stack spacing={2}>
            <ImageUploadBox
              id={`${SCHEDULE_PUSH_CONTENT_SECTION_FORM_NAMES.IMAGE_ID}-upload-box-${Math.random()}`}
              label="Push Image"
              imageUrl={imageId ? imageUrl : imageFile ? URL.createObjectURL(imageFile) : undefined}
              name={SCHEDULE_PUSH_CONTENT_SECTION_FORM_NAMES.IMAGE_ID}
              onUpload={(file) => {
                setImageFile(file);
                setImageId('');
              }}
              onRemove={() => {
                setImageFile(undefined);
                setImageId('');
              }}
            />
            {imageFile && !imageId && (
              <Box display="flex" justifyContent="flex-end">
                <LoadingButton
                  loading={isUploading}
                  onClick={handleUploadImage}
                  variant="contained"
                  startIcon={<CloudUploadIcon />}
                >
                  Upload Image
                </LoadingButton>
              </Box>
            )}
          </Stack>
        </Box>
      </Stack>
    </Stack>
  );
});

SchedulePushContentSectionForm.displayName = 'SchedulePushContentSectionForm';
export default SchedulePushContentSectionForm;
