import React, { useMemo, useState } from 'react';

import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Link, Stack } from '@mui/material';

import Spinner from '~/components/Common/Spinner';

import { getRebookStatus, updateOrderItem } from '~/services/OrdersService';

import RebookInfo from './RebookInfo';

interface Props {
  order: App.Order;
  itemId: string;
  onClose: () => void;
  refreshData: () => void;
  bedbankRoomsInfo: {
    [reservationRoomId: string]: {
      id: string;
      status: string;
      rebook?: App.Bedbank.ReservationRebookInfo;
    };
  };
}

function RebookModal({ order, itemId, onClose, refreshData, bedbankRoomsInfo }: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const item = useMemo(() => {
    return order.bedbank_items.find((i) => i.id === itemId);
  }, [itemId, order.bedbank_items]);

  const propertyId = useMemo(() => {
    const propertyId = order.bedbank_items.length ? order.bedbank_items[0].fk_property_id : null;
    return propertyId;
  }, [order.bedbank_items]);

  const [hasUnverifiedRooms, supplierRoomCode, supplierRoomName] = useMemo(() => {
    const rebookableRooms = Object.values(bedbankRoomsInfo).filter((r) => r.rebook);
    const hasUnverifiedRooms = rebookableRooms.some((r) => !r.rebook?.isVerifiedRoom);
    const rebookInfo = rebookableRooms.find((r) => !r.rebook?.isVerifiedRoom)?.rebook || {
      supplierRoomCode: 'N/A',
      supplierRoomName: 'N/A',
    };
    const { supplierRoomCode, supplierRoomName } = rebookInfo;
    return [hasUnverifiedRooms, supplierRoomCode, supplierRoomName];
  }, [bedbankRoomsInfo]);

  const checkStatus = () => {
    getRebookStatus(order.id_orders, itemId).then(({ jobState, failedReason }) => {
      if (typeof jobState !== 'undefined') {
        if (jobState === 'failed') {
          setError(failedReason);
          setLoading(false);
        } else {
          setTimeout(checkStatus, 1000);
        }
      } else {
        setLoading(false);
        refreshData();
      }
    });
  };

  const rebookItem = () => {
    setLoading(true);
    setError(null);

    updateOrderItem(order.id_orders, itemId, {
      op: 'rebook',
    })
      .then(() => {
        checkStatus();
      })
      .catch((e) => {
        setLoading(false);
        setError(e.message);
      });
  };

  if (loading) {
    return (
      <Dialog open>
        <DialogContent>
          <Spinner />
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>
        <Stack spacing={2}>Rebooking item {itemId}</Stack>
      </DialogTitle>
      {hasUnverifiedRooms && (
        <DialogContent>
          <Alert severity="warning">
            Room {supplierRoomName} - {supplierRoomCode} is not verified. Please verify room mapping before rebooking
            and then retry.
          </Alert>
          <Link href={`/bedbank/properties/${propertyId}#3`} target="_blank">
            Room Mapping
          </Link>
        </DialogContent>
      )}

      {!hasUnverifiedRooms && (
        <DialogContent>
          <Stack direction="column" spacing={2}>
            {item.rooms
              .filter((r) => bedbankRoomsInfo[r.id_reservation_room]?.rebook)
              .map((room) => (
                <RebookInfo
                  key={room.room_index}
                  roomIndex={room.room_index}
                  rebookableData={bedbankRoomsInfo[room.id_reservation_room].rebook}
                  currencyCode={order.currency_code}
                  sourceSupplierRoomName={room.name}
                />
              ))}
          </Stack>
        </DialogContent>
      )}

      {error && <Alert severity="error">{error}</Alert>}

      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={rebookItem} disabled={hasUnverifiedRooms}>
          Consent
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default RebookModal;
