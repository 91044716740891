import React, { ReactElement, useMemo } from 'react';

import { Apartment, CalendarToday, DateRange, Hotel, People, Person, Plagiarism, Public } from '@mui/icons-material';
import BadgeIcon from '@mui/icons-material/Badge';
import { Box, Button, Stack, Typography } from '@mui/material';

import { ROLE_ICS_STORE_TEAM } from '~/consts/roles';

import useCurrentUser from '~/hooks/useCurrentUser';
import useToggleState from '~/hooks/useToggleState';

import { newDate } from '~/services/TimeService';

import { isOfferTypeHotel } from '~/utils/offer';
import { formatOrderDate } from '~/utils/order';

import {
  formatChildrenAges,
  getPropertyLink,
  getRoomRate,
  getRoomTypeLink,
  getTourLink,
  getTourOptionLink,
} from '../utils';

import { FinePrintModal } from './FinePrintModal';
import { GuestDetails } from './GuestDetails';

export type Traveller = {
  traveller_submitted?: boolean;
  first_name: string;
  last_name: string;
};

export interface CheckInCheckOutTime {
  checkInTime: string;
  checkOutTime: string;
}

type Props = {
  traveller: Traveller;
  order: App.Order;
  item: App.OrderItem;
  offerPackage: App.AccommodationPackage;
};

export function GeneralInformationComponent({ traveller, order, item, offerPackage }: Props): ReactElement {
  const { isToggled: isModalOpen, toggleOn: openModal, toggleOff: closeModal } = useToggleState(false);

  const { reservation, fullReservation, fk_vendor_id, offer } = item;

  const { user } = useCurrentUser();

  const isVendorLinkRestricted = useMemo(() => {
    return user.roles.includes(ROLE_ICS_STORE_TEAM);
  }, [user]);
  const checkInCheckOutTimes = useMemo<CheckInCheckOutTime | undefined>(() => {
    if (isOfferTypeHotel(offer)) {
      const facilityLine = offer.facilities?.split('\n').find((line) => line.includes('Check-in:'));
      if (!facilityLine) {
        return undefined;
      }

      const times = facilityLine.match(/\d+(\.\d+)?\s*[aApP][mM]/g);
      if (!times || times.length !== 2) {
        return undefined;
      }
      const [checkInRaw, checkOutRaw] = times;

      // Normalize time format before parsing
      const normalizeTime = (timeStr: string) => {
        // Convert to uppercase AM/PM format and ensure there's a space before AM/PM
        const normalized = timeStr.replace(/([aApP][mM])$/, ' $1').toUpperCase();
        return normalized;
      };

      return {
        checkInTime: newDate(normalizeTime(checkInRaw), 'hA').format('h:mm A'),
        checkOutTime: newDate(normalizeTime(checkOutRaw), 'hA').format('h:mm A'),
      };
    }
    return undefined;
  }, [offer]);

  const hasGuest = reservation?.number_of_adults;

  const roomDisplayType = useMemo(() => {
    return [
      fullReservation?.room_type?.name,
      fullReservation?.rate_plan?.name,
      fullReservation?.rate_plan?.rate_plan_code,
    ]
      .filter((val) => val)
      .join(' | ');
  }, [fullReservation?.rate_plan?.name, fullReservation?.rate_plan?.rate_plan_code, fullReservation?.room_type?.name]);

  const roomTypeLink = useMemo(() => {
    if (fullReservation.tour) {
      return '';
    }

    const roomRate = getRoomRate(fullReservation, offerPackage);
    if (!roomRate) {
      return '';
    }
    return getRoomTypeLink(fk_vendor_id, offerPackage.room_type, roomRate);
  }, [fk_vendor_id, fullReservation, offerPackage]);

  return (
    <Box
      mt={1}
      display="grid"
      columnGap={1}
      gridTemplateColumns="auto 1fr"
      gridAutoRows="minmax(32px, auto)"
      alignItems="center"
      className="T-HasReservation"
    >
      <>
        <Person color="secondary" />
        <GuestDetails offer={offer} order={order} reservation={reservation} traveller={traveller} />
      </>

      {order && order.permanent_account_number && (
        <>
          <BadgeIcon color="secondary" />
          <Typography>PAN: {order.permanent_account_number}</Typography>
        </>
      )}

      {!!hasGuest && (
        <>
          <People color="secondary" />
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>{reservation.number_of_adults} adults</Typography>
            <Typography>{reservation.number_of_children} children</Typography>
            <Typography>{reservation.number_of_infants} infants</Typography>
            {reservation.children_ages?.length > 0 && (
              <Typography>{formatChildrenAges(reservation.children_ages)}</Typography>
            )}
          </Stack>
        </>
      )}

      <>
        <DateRange color="secondary" />
        <Stack direction="row" spacing={1}>
          <Typography data-cy="reservation-start-date" className="T-ReservationDate">
            {formatOrderDate(reservation.check_in || reservation.start_date)}
          </Typography>
          <Typography> - </Typography>
          <Typography data-cy="reservation-end-date">
            {formatOrderDate(reservation.check_out || reservation.end_date)}
          </Typography>
        </Stack>
      </>

      {checkInCheckOutTimes && (
        <>
          <DateRange color="secondary" />
          <Stack direction="row" spacing={1}>
            <Typography className="T-ReservationDate">Check-in: {checkInCheckOutTimes.checkInTime}</Typography>
            <Typography> - </Typography>
            <Typography className="T-ReservationDate"> Check-out: {checkInCheckOutTimes.checkOutTime}</Typography>
          </Stack>
        </>
      )}

      {item.number_of_nights && (
        <>
          <CalendarToday color="secondary" />
          <Typography>{item.number_of_nights} Night(s)</Typography>
        </>
      )}

      {item.fullReservation.property && offerPackage?.room_type && (
        <>
          <Apartment color="secondary" />
          <Box>
            <Button
              variant="text"
              size="small"
              href={getPropertyLink(fk_vendor_id, offerPackage.room_type)}
              target="_blank"
              rel="noreferrer"
              disabled={isVendorLinkRestricted}
            >
              {fullReservation.property.name}
            </Button>
          </Box>

          <Hotel color="secondary" />
          <Box>
            <Button
              variant="text"
              size="small"
              href={roomTypeLink}
              disabled={!roomTypeLink || isVendorLinkRestricted}
              target="_blank"
              rel="noreferrer"
              title={roomDisplayType}
            >
              {roomDisplayType}
            </Button>
          </Box>
        </>
      )}

      {fullReservation.tour && (
        <>
          <Public color="secondary" />
          <Box>
            <Button
              variant="text"
              size="small"
              href={getTourLink(fk_vendor_id, offerPackage)}
              target="_blank"
              rel="noreferrer"
              disabled={isVendorLinkRestricted}
            >
              {fullReservation.tour.name}
            </Button>
          </Box>

          <Hotel color="secondary" />
          <Box>
            <Button
              variant="text"
              size="small"
              href={getTourOptionLink(fk_vendor_id, offerPackage)}
              target="_blank"
              rel="noreferrer"
              disabled={isVendorLinkRestricted}
            >
              {fullReservation.tour_option.name}
            </Button>
          </Box>
        </>
      )}

      {offer.fine_print && (
        <>
          <Plagiarism color="secondary" />
          <Box>
            <Button variant="text" disabled={!offer.fine_print} onClick={openModal} size="small">
              Fine Print
            </Button>
            <FinePrintModal offer={offer} open={isModalOpen} onClose={closeModal} />
          </Box>
        </>
      )}
    </Box>
  );
}
