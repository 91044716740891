import React from 'react';

import { Box, List, Typography } from '@mui/material';

import SidebarItem, { SidebarItemProps } from './SidebarItem';

export interface SidebarSectionProps {
  title: string;
  items: Array<SidebarItemProps>;
}

export default function SidebarSection({ title, items }: SidebarSectionProps) {
  return (
    <Box
      sx={{
        py: 1,
        '&:first-of-type': {
          pt: 0,
        },
      }}
    >
      <Typography variant="overline">{title}</Typography>
      <List>
        {items.map((item, index) => (
          <SidebarItem key={`${title}-sidebar-item-${index}`} {...item} />
        ))}
      </List>
    </Box>
  );
}
