import React from 'react';

import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import { Alert, Box } from '@mui/material';

import Accordion from '~/components/v2/common/Accordion/Accordion';
import ReadOnlyField from '~/components/v2/common/ReadOnlyField/ReadOnlyField';

export default function OpportunityDatesAndPolicies() {
  return (
    <>
      <Box id="deal-dates">
        <Accordion title="Deal dates" icon={<CalendarMonthOutlinedIcon />}>
          <ReadOnlyField title="Travel from" value="1/1/2025" type="text" />
          <ReadOnlyField title="Travel to" value="20/12/2025" type="text" />
          <ReadOnlyField title="Book by" value="1/10/2025" type="text" />
          <ReadOnlyField title="Black Out Period" value="1/10/2025 - 20/12/2025" type="text" />
          <ReadOnlyField title="Latest Travel To" value="1/10/2025 - 20/12/2025" type="text" />
        </Accordion>
      </Box>
      <Box id="run-dates">
        <Accordion title="Run dates" icon={<EventNoteOutlinedIcon />}>
          <ReadOnlyField title="Online Run Dates" value="1/1/2025" type="text" />
          <ReadOnlyField title="Offline Run Dates" value="1/10/2025 - 20/12/2025" type="text" />
          <Alert severity="info">Scheduled - Starts in X days</Alert>
          <Alert severity="success">Available for Sale</Alert>
        </Accordion>
      </Box>
      <Box id="policies">
        <Accordion title="Policies" icon={<LocalPoliceIcon />}>
          <ReadOnlyField title="Date change allowed" value="21" type="text" />
          <ReadOnlyField title="Package upgrade allowed" value="Yes" type="text" />
          <ReadOnlyField title="Number of date changes allowed" value="Unlimited" type="text" />
          <ReadOnlyField title="Cancellation Policy" value="Unlimited" type="text" />
        </Accordion>
      </Box>
    </>
  );
}
