import { useCallback, useEffect, useState } from 'react';

import { getFlightTrace } from '~/services/FlightsService';

export function useFlightTrace(id: string, options: { disabled: boolean } = { disabled: false }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [data, setData] = useState<App.FlightTrace>();

  const fetchData = useCallback(async () => {
    setLoading(true);

    try {
      const { result } = await getFlightTrace(id);
      setData(result);
    } catch (error) {
      setError(error);
    }

    setLoading(false);
  }, [setLoading, setData, setError, id]);

  useEffect(() => {
    if (!options.disabled && !data) {
      fetchData();
    }
  }, [fetchData, options.disabled]);

  return { loading, error, data, revalidate: fetchData };
}
