import React, { useCallback } from 'react';

import { useSnackbar } from 'notistack';

import VerifiedIcon from '@mui/icons-material/Verified';
import { IconButton, Tooltip } from '@mui/material';

import { useConfirmDialog } from '~/components/Common/Providers/ConfirmDialogProvider';

import useToggleState from '~/hooks/useToggleState';

import { updateMapping } from '~/services/AccommodationService';

import AccommodationVerifyMappingModal from './AccommodationVerifyMappingModal';

interface Props {
  propertyId: string;
  mappedPropertyId: string;
  onComplete: () => void;
}

export default function AccommodationVerifyMapping({ propertyId, mappedPropertyId, onComplete }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const showConfirmDialog = useConfirmDialog();

  const {
    isToggled: isVerifyMappingModalOpen,
    toggleOn: setOpenVerifyMappingModal,
    toggleOff: setCloseVerifyMappingModal,
  } = useToggleState(false);

  const handleVerify = useCallback(async () => {
    const confirmed = await showConfirmDialog({
      title: 'Verify Mapping?',
      description: `This will verify the mapping, are you sure you want to proceed?`,
    });
    if (!confirmed) {
      return;
    }

    try {
      await updateMapping({
        propertyId,
        mappedPropertyId,
        verificationStatus: 'Approved',
      });
      enqueueSnackbar('Successfully verified mapping', { variant: 'success' });
      onComplete();
    } catch (error) {
      enqueueSnackbar('Failed to verify mapping', { variant: 'error' });
    }
  }, [showConfirmDialog, propertyId, mappedPropertyId, enqueueSnackbar, onComplete]);

  const handleReject = useCallback(async () => {
    const confirmed = await showConfirmDialog({
      title: 'Reject Mapping?',
      description: `This will reject the mapping, are you sure you want to proceed?`,
    });
    if (!confirmed) {
      return;
    }
    try {
      await updateMapping({
        propertyId,
        mappedPropertyId,
        verificationStatus: 'Rejected',
      });
      enqueueSnackbar('Successfully rejected mapping', { variant: 'success' });
      onComplete();
    } catch (error) {
      enqueueSnackbar('Failed to reject mapping', { variant: 'error' });
    }
  }, [showConfirmDialog, propertyId, mappedPropertyId, enqueueSnackbar, onComplete]);

  return (
    <>
      <IconButton onClick={setOpenVerifyMappingModal}>
        <Tooltip title="Verify mapping">
          <VerifiedIcon fontSize="medium" />
        </Tooltip>
      </IconButton>
      {isVerifyMappingModalOpen && (
        <AccommodationVerifyMappingModal
          isOpen={isVerifyMappingModalOpen}
          closeModal={setCloseVerifyMappingModal}
          propertyId={propertyId}
          mappedPropertyId={mappedPropertyId}
          handleVerify={handleVerify}
          handleReject={handleReject}
        />
      )}
    </>
  );
}
