import { ServerConnectionCancellationPolicy } from '@luxuryescapes/lib-refunds';

import {
  COLLECTION_REASONS,
  RefundSource,
  VARIANT_OF_REFUND_ALL,
  VARIANT_OF_REFUND_NONE,
  VARIANT_OF_REFUND_PARTIAL,
  VARIANT_OF_REFUND_PARTIAL_CALCULATED,
  VARIANT_OF_REFUND_SURCHARGE,
} from '~/consts/refund';

import { RefundMethods } from './RefundV2/Utils/RefundUtils';

export interface RefundAttributes {
  charge_component_key?: string;
  transaction_key?: string;
  cancel_refund?: any;
  promo_amount: number;
  promo_percentage: number;
  accounting_amount: number;
  initial_amount: number;
  cash_amount_original: number;
  cash_amount: number;
  refund_fee: number;
  fee: number;
  source?: string;
  include_promo: boolean;
  vendor_refund_fee?: number;
}

export interface AccountingMetaPayload {
  source: string;
  refund_fee: string;
  cash_amount: string;
  accounting_amount: string;
  charge_component_key: string;
}

export interface Refund {
  reason: (typeof COLLECTION_REASONS)[keyof typeof COLLECTION_REASONS]['key'];
  methods: Array<any>;
  refund_method: string;
  refund_policies: Array<string>;
  expired_cancellation_policy: ServerConnectionCancellationPolicy | undefined;
  automatic: boolean;
  is_accommodation: boolean;
  is_addon: boolean;
  is_experience: boolean;
  is_insurance: boolean;
  is_bedbank: boolean;
  is_tour: boolean;
  is_ttc_tour: boolean;
  is_expired_tour_refund: boolean;
  is_deposit: boolean;
  out_of_cooling_off: boolean;
  item_id: string;
  room_id: string;
  id_orders: string;
  has_reservation: boolean;
  has_surcharge: boolean;
  is_refunded_surcharge: boolean;
  currency_code: any;
  package_cost_price_data: any;
  package_sale_price: number;
  extra_nights_cost_price_data: any;
  extra_nights_sale_price: number;
  total_sale_price: number;
  surcharge_price: number;
  package_nights: number;
  extra_nights: number;
  total_nights: number;
  number_of_nights: number;
  comment: string;
  ticket_id: string;
  nib_reference_id: string;
  mark_cancelled: boolean;
  send_refund_notification: boolean;
  send_customer_refund_notification: boolean;
  override: boolean;
  update_vcc_balance: boolean;
  source: RefundSource;
  variant_of_refund:
    | typeof VARIANT_OF_REFUND_ALL
    | typeof VARIANT_OF_REFUND_PARTIAL
    | typeof VARIANT_OF_REFUND_PARTIAL_CALCULATED
    | typeof VARIANT_OF_REFUND_SURCHARGE
    | typeof VARIANT_OF_REFUND_NONE;
  refund_vendor_agrees_to: string;
  percentage: number;
  item_metadata: RefundAttributes;
  surcharge_metadata: RefundAttributes;
  charge_component_count: number;
  tmp: {
    amount: string | number;
    fee: string | number;
  };
  missing_cost_price_data: boolean;
  disable_credits_promo_refund: boolean;
  remaining_deposit_items: Array<App.OrderItem> & Array<App.OrderTourItem>;
  current_deposit_item: App.OrderItem & App.OrderTourItem;
  is_final_deposit_refund: boolean;
  refund_provider_fee: boolean;
  is_instalment: boolean;
  remaining_instalment_items: Array<App.OrderItem> & Array<App.OrderTourItem>;
  current_instalment_item: App.OrderItem & App.OrderTourItem;
  is_pending_deferred_payment: boolean;
  remaining_deferred_payment_items: Array<App.OrderItem> & Array<App.OrderTourItem>;
  current_deferred_payment_item: App.OrderItem & App.OrderTourItem;
  business_credit_refund_amount: number;
  is_tour_v2?: boolean;
  is_car_hire?: boolean;
  is_cruise?: boolean;
  is_tour_optional_experience?: boolean;
  is_subscription?: boolean;
  merchant_fee_amount: number;
  is_booking_protection: boolean;
  pro_rata_subscription_refund_amount: number;
  channel_manager: string | null;
}

export interface RefundPayloadAccountingMetadata {
  source: string;
  cash_amount: number;
  accounting_amount: number;
  charge_component_key: string;
  refund_fee: number;
}

export interface AdditionalInfo {
  refund_vendor_agrees_to?: VendorRefundTypes;
  number_of_nights?: number | string;
  percentage?: number | string;
  package_nights?: number;
  extra_nights?: number;
  total_nights?: number;
  refund_subtype?: string;
  version?: string;
  vendor_contribution?: number;
}

export interface RefundPayload {
  transaction_key: string;
  reason: string;
  refund_method: string;
  amount: number;
  comment: string;
  ticket_id: string;
  currency_code: string;
  mark_cancelled: string | boolean;
  update_vcc_balance: boolean;
  send_refund_notification: boolean;
  send_customer_refund_notification: boolean | string;
  accounting_metadata: Array<RefundPayloadAccountingMetadata>;
  additional_info: AdditionalInfo;
  disable_credits_promo_refund: boolean;
  remaining_deposit_items: Array<any>;
  current_deposit_item: any | null;
  is_deposit: boolean;
  is_final_deposit_refund: boolean;
  is_instalment: boolean;
  remaining_instalment_items: Array<any>;
  current_instalment_item: any | null;
  is_pending_deferred_payment: boolean;
  remaining_deferred_payment_items: Array<any>;
  current_deferred_payment_item: any | null;
  merchant_fee_amount: number;
  variant_of_refund: string;
  room_id?: string;
}

interface PriceData {
  sale_price: number;
  cost_price?: number;
  // e.g. THB
  cost_price_currency: string;
  cost_price_converted_to_sell_currency?: number;
}

export interface RefundMetadata extends Refund {
  has_connection: boolean;
  cost_price_data: PriceData;
  extra_nights_cost_price_data: PriceData;
  sales_price: number;
  nights: number;
}

export interface EnrichedRefundMetadata extends RefundMetadata {
  cash_amount_original: string;
  refund_method: string;
}

interface CollectionReasonDefaults {
  source: string;
  variant_of_refund: string;
  is_refunded_surcharge: boolean | null;
  mark_cancelled: boolean;
  send_refund_notification: boolean;
  send_customer_refund_notification: boolean;
  methods: Array<RefundMethods>;
  comment?: string;
}

export interface CollectionReason {
  key: string;
  label?: string;
  hidden: boolean;
  description: string;
  can_select_variant: boolean;
  defaults: CollectionReasonDefaults;
}

export interface CollectionReasons {
  [key: string]: CollectionReason;
}

export enum VendorRefundTypes {
  percentage = 'percentage',
  // this is for a full refund with fee
  // as well as select nights
  number = 'number',
}

export type OrderType =
  | 'hotel'
  | 'bundleAndSave'
  | 'villa'
  | 'tour'
  | 'cruise'
  | 'experience'
  | 'flight'
  | 'carHire'
  | 'insurance'
  | 'other';

export type ItemType =
  | 'hotel'
  | 'tacticalAOHotel'
  | 'lastMinuteHotel'
  | 'bundleAndSave'
  | 'bedbank'
  | 'flight'
  | 'tour'
  | 'tourV1'
  | 'cruise'
  | 'cruiseV1'
  | 'carHire'
  | 'experience'
  | 'villa'
  | 'insurance'
  | 'customOffer'
  | 'bookingProtection';
