import { RJSFSchema } from '@rjsf/utils';
import capitalize from 'lodash/capitalize';

import * as libRefunds from '@luxuryescapes/lib-refunds';
import * as libRegions from '@luxuryescapes/lib-regions';

import { OfferInclusionsSelect } from '~/components/Common/Forms/fields';
import MarkdownEditor from '~/components/Common/Forms/widgets/MarkdownEditor';
import ReactHookFormAdapter from '~/components/Common/Forms/widgets/ReactHookFormAdapter';

import { TAG_TYPE_CAMPAIGN } from '~/consts/tags';

import OffersService from '~/services/OffersService';

import { titleCase } from '~/utils/stringUtils';

import {
  BEDBANK_PROFILES_ENUM,
  BEDBANK_PROFILE_DEFAULT,
  BEDBANK_PROPERTY_DOMAINS_ENUM,
  BEDBANK_PROPERTY_DOMAIN_ALL,
  BEDBANK_PROPERTY_STATUSES_ENUM,
  BEDBANK_PROPERTY_STATUS_CONTENT_APPROVED,
  BEDBANK_PROPERTY_TYPES_ENUM,
  BEDBANK_PROPERTY_TYPE_HOTEL,
} from '../../../../consts/bedbank';
import AIGeneratorMarkdownWidget from '../../../Common/Forms/widgets/AIGeneratorMarkdownWidget';
import CustomRoomMappingsWidget from '../../../Common/Forms/widgets/CustomRoomMappingsWidget';

import { isArchivedPromotion } from './utils/isArchivedPromotion';

const regions = libRegions.getRegions();
const currencies = Array.from(new Set(regions.map((regions) => regions.currencyCode).sort()).values());
const promotionCancellationPolicies = libRefunds.getPromotionCancellationPolicies();

export function getSchema(
  suppliers: Array<{ supplier: string; supplierEnabled: boolean; supplierMarkup: number }>,
): RJSFSchema {
  return {
    title: 'Edit',
    type: 'object',
    required: [],
    properties: {
      type: {
        type: 'string',
        title: 'Type',
        default: BEDBANK_PROPERTY_TYPE_HOTEL,
        enum: BEDBANK_PROPERTY_TYPES_ENUM,
      },
      airport: {
        type: ['null', 'string'],
        title: 'Airport Code',
      },
      suppliers: {
        type: 'array',
        title: 'Supplier Management',
        items: suppliers.map((supplier) => ({
          type: 'object',
          title: titleCase(supplier.supplier),
          properties: {
            supplierEnabled: {
              type: 'boolean',
              title: `${titleCase(supplier.supplier)} Enabled`,
              default: supplier.supplierEnabled,
            },
          },
          allOf: [
            {
              if: {
                properties: {
                  supplierEnabled: {
                    const: true,
                  },
                },
              },
              then: {
                properties: {
                  supplierMarkup: {
                    type: 'number',
                    // TODO: this validation is not working
                    minimum: 10,
                    maximum: 200,
                    title: `${titleCase(supplier.supplier)} Markup %`,
                    default: supplier.supplierMarkup,
                  },
                },
              },
            },
          ],
        })),
      },
      isFlightEnabled: {
        type: 'boolean',
        title: 'Flight Enabled',
      },
      isPreferred: {
        type: 'boolean',
        title: 'Is Preferred',
      },
      promoteAsBundle: {
        type: 'boolean',
        title: 'Promote as bundle',
      },
      displayPricingAsPerPerson: {
        type: 'boolean',
        title: 'Display pricing as per person',
      },
      displayDecoupledRooms: {
        type: 'boolean',
        title: 'Display decoupled rooms',
      },
      profile: {
        type: 'string',
        enum: BEDBANK_PROFILES_ENUM,
        // enumNames should be replaced with `oneOf` switch with constant values and title
        // https://rjsf-team.github.io/react-jsonschema-form/docs/json-schema/single#enumerated-values
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        enumNames: BEDBANK_PROFILES_ENUM.map((x) => capitalize(x)),
        default: BEDBANK_PROFILE_DEFAULT,
        title: 'Profile',
      },
      status: {
        type: 'string',
        enum: BEDBANK_PROPERTY_STATUSES_ENUM,
        default: BEDBANK_PROPERTY_STATUS_CONTENT_APPROVED,
        title: 'Status',
      },
      domain: {
        type: 'string',
        enum: BEDBANK_PROPERTY_DOMAINS_ENUM,
        default: BEDBANK_PROPERTY_DOMAIN_ALL,
        title: 'Domain',
      },
      tagline: {
        title: 'Tagline ',
        type: 'string',
      },
      description: {
        title: 'Description',
        type: 'string',
      },
      additionalPromoContent: {
        title: 'Additional Promo Content',
        type: 'string',
      },
      highlights: {
        title: 'Highlights',
        type: 'string',
      },
      facilitiesOverride: {
        title: 'Facilities',
        type: 'string',
      },
      attractionsOverride: {
        title: "What's Nearby / Attractions",
        type: 'string',
      },
      finePrintOverride: {
        title: 'Fine Print',
        type: 'string',
      },
      locationTitleOverride: {
        title: 'Location Title',
        type: 'string',
      },
    },
  };
}

export function getSellPricesSchema() {
  return {
    type: 'object',
    properties: {
      sellIsEnabled: {
        title: 'Enable Sell Prices',
        type: 'boolean',
        default: false,
      },
    },
    dependencies: {
      sellIsEnabled: {
        oneOf: [
          {
            properties: {
              sellIsEnabled: { enum: [true] },
              sell: {
                title: 'Sell Price',
                type: 'object',
                required: ['price', 'value', 'currency', 'los'],
                properties: {
                  price: {
                    title: 'Price',
                    type: 'string',
                  },
                  value: {
                    title: 'Valued up to',
                    type: 'string',
                  },
                  valueAdd: {
                    title: 'Value add',
                    type: 'string',
                  },
                  taxesAndFees: {
                    title: 'Taxes And Fees',
                    type: 'string',
                  },
                  propertyFees: {
                    title: 'Property Fees',
                    type: 'string',
                  },
                  currency: {
                    title: 'Currency',
                    enum: currencies,
                  },
                  los: {
                    title: 'Length of Stay',
                    type: 'integer',
                    minimum: 1,
                  },
                },
              },
              rooms: {
                title: 'Rooms',
                type: 'array',
                items: {
                  type: 'object',
                  title: 'Room',
                  required: [],
                  properties: {
                    sell: {
                      type: 'object',
                      title: 'Sell Price',
                      required: ['price', 'value', 'currency', 'los'],
                      properties: {
                        price: {
                          title: 'Price',
                          type: 'string',
                        },
                        value: {
                          title: 'Valued up to',
                          type: 'string',
                        },
                        valueAdd: {
                          title: 'Value add',
                          type: 'string',
                        },
                        taxesAndFees: {
                          title: 'Taxes And Fees',
                          type: 'string',
                        },
                        propertyFees: {
                          title: 'Property Fees',
                          type: 'string',
                        },
                        currency: {
                          title: 'Currency',
                          enum: currencies,
                        },
                        los: {
                          title: 'Length of Stay',
                          type: 'integer',
                          minimum: 1,
                        },
                      },
                    },
                  },
                },
              },
            },
          },
          {
            properties: {
              sellIsEnabled: { enum: [false] },
            },
          },
        ],
      },
    },
  };
}

export function getRoomsSchema(property) {
  const activePromotions = property.promotions.filter((promotion) => !isArchivedPromotion(promotion));
  return {
    title: 'Rooms',
    type: 'array',
    items: {
      type: 'object',
      title: 'Room',
      required: [],
      properties: {
        nameOverride: {
          title: 'Name',
          type: 'string',
        },
        descriptionOverride: {
          type: 'string',
          title: 'Description',
        },
        promotionId: {
          title: 'Promotion',
          type: 'string',
          enum: activePromotions.map((promo) => promo.promoId),
          enumNames: activePromotions.map((promo) => promo.promoName),
        },
        roomMappings: {
          title: 'Room Mappings',
          type: 'string',
        },
      },
    },
  };
}

export function getPromotionsSchema(property) {
  const ratesEnum = property.rates.map((rate) => rate.extId);
  return {
    title: 'Promotions',
    type: 'array',
    items: {
      type: 'object',
      required: ['promoName', 'los', 'rateInclusionsLong', 'rateInclusionsShort', 'restrictToRates', 'inclusions'],
      properties: {
        promoId: {
          title: 'Promo Id',
          type: 'string',
        },
        promoName: {
          title: 'Promo name',
          type: 'string',
        },
        subjectLine: {
          title: 'Email subject line',
          type: 'string',
        },
        preheader: {
          title: 'Email preheader',
          type: 'string',
        },
        promoPeriodSchedule: {
          title: 'Promo period/schedule',
          type: 'object',
          required: ['promoPeriodFrom', 'promoPeriodTo'],
          properties: {
            promoPeriodFrom: {
              title: 'Select Start Date',
              type: 'string',
              format: 'date',
            },
            promoPeriodTo: {
              title: 'Select End Date',
              type: 'string',
              format: 'date',
            },
          },
        },
        travelPeriod: {
          title: 'Travel period',
          type: 'object',
          required: ['travelPeriodFrom', 'travelPeriodTo'],
          properties: {
            travelPeriodFrom: {
              title: 'Select Start Date',
              type: 'string',
              format: 'date',
            },
            travelPeriodTo: {
              title: 'Select End Date',
              type: 'string',
              format: 'date',
            },
          },
        },
        los: {
          title: 'Min Length of Stay',
          type: 'integer',
          minimum: 1,
        },
        restrictToRates: {
          title: 'Restrict to Rate IDs',
          type: 'array',
          uniqueItems: true,
          items: {
            type: 'string',
            enum: ratesEnum,
          },
          minItems: 1,
        },
        rateInclusionsLong: {
          title: 'Rate inclusions (long version)',
          type: 'string',
        },
        rateInclusionsShort: {
          title: 'Rate inclusions (short version)',
          type: 'string',
          maxLength: 70,
          description: 'Maximum 70 characters',
        },
        finePrint: {
          title: 'Fine print',
          type: 'string',
        },
        cancellationPolicy: {
          title: 'Cancellation policy',
          type: 'string',
          enum: promotionCancellationPolicies.map((x) => x.code),
          enumNames: promotionCancellationPolicies.map((x) => x.description),
        },
        inclusions: {
          title: 'Inclusions',
          type: 'array',
          items: {
            type: 'object',
            properties: {
              icon: 'string',
              category: 'string',
              content: 'string',
              isHighlighted: 'boolean',
            },
          },
        },
      },
    },
  };
}

export function getVideosSchema() {
  return {
    title: 'Video Upload',
    type: 'array',
    items: {
      type: 'object',
      required: ['id', 'type'],
      properties: {
        id: {
          title: 'Video Id',
          type: 'string',
        },
        type: {
          title: 'Platform',
          type: 'string',
          enum: ['vimeo'],
          enumNames: ['Vimeo'],
          default: 'vimeo',
        },
        url: {
          title: 'Video URL',
          type: 'string',
        },
      },
    },
    'ui:options': {
      submitButtonOptions: {
        norender: true,
      },
    },
  };
}

export async function getTagsSchema() {
  const allTags = (await OffersService.getTags(TAG_TYPE_CAMPAIGN)).result.map((item) => item.name);
  return {
    title: 'Campaign Tags',
    type: 'array',
    items: {
      type: 'string',
      enum: allTags,
    },
  };
}

export const uiSchemas = (props) => {
  return {
    main: {
      status: {
        'ui:disabled': !props.isAdmin,
      },
      tagline: {
        'ui:widget': AIGeneratorMarkdownWidget,
        'ui:generatorContext': {
          propertyName: props.property.name,
          model: 'offer',
          field: 'headline',
        },
        'ui:isMarkdown': true,
        'ui:disableUseButton': true,
        'ui:helperText': 'Linked to Promotions Schedule',
      },
      description: {
        'ui:widget': AIGeneratorMarkdownWidget,
        'ui:generatorContext': {
          propertyName: props.property.name,
          model: 'offer',
          field: 'description',
        },
        'ui:isMarkdown': true,
        'ui:disableUseButton': true,
      },
      additionalPromoContent: {
        'ui:widget': MarkdownEditor,
        'ui:helperText': 'Linked to Promotions Schedule',
      },
      facilitiesOverride: {
        'ui:widget': MarkdownEditor,
      },
      highlights: {
        'ui:widget': MarkdownEditor,
        'ui:helperText': 'Linked to Promotions Schedule',
      },
      attractionsOverride: {
        'ui:widget': AIGeneratorMarkdownWidget,
        'ui:generatorContext': {
          propertyName: props.property.name,
          model: 'offer',
          field: 'description',
        },
        'ui:isMarkdown': true,
        'ui:disableUseButton': true,
      },
      finePrintOverride: {
        'ui:widget': MarkdownEditor,
      },
      locationTitleOverride: {
        'ui:widget': 'text',
      },
      inclusions: {
        'ui:widget': ReactHookFormAdapter,
        'ui:options': { ReactHookFormComponent: OfferInclusionsSelect },
      },
      'ui:options': {
        submitButtonOptions: {
          norender: true,
        },
      },
    },
    sellPrices: {
      'ui:order': ['*', 'sell', 'rooms'],
      sell: {
        'ui:classNames': 'sell-prices-field',
      },
      rooms: {
        'ui:options': {
          removable: false,
          orderable: false,
          addable: false,
        },
        items: {
          sell: {
            'ui:classNames': 'sell-prices-field',
          },
        },
      },
    },
    promotions: {
      items: {
        promoId: {
          'ui:widget': 'hidden',
        },
        rateInclusionsLong: {
          'ui:widget': MarkdownEditor,
        },
        finePrint: {
          type: 'string',
          'ui:widget': MarkdownEditor,
        },
        inclusions: {
          'ui:widget': ReactHookFormAdapter,
          'ui:options': { ReactHookFormComponent: OfferInclusionsSelect },
        },
      },
      'ui:options': {
        submitButtonOptions: {
          norender: true,
        },
      },
    },
    rooms: {
      'ui:options': {
        removable: false,
        orderable: false,
        addable: false,
        submitButtonOptions: {
          norender: true,
        },
      },
      items: {
        descriptionOverride: {
          title: 'Description',
          'ui:widget': AIGeneratorMarkdownWidget,
          'ui:isMarkdown': true,
          'ui:disableUseButton': true,
        },
        roomMappings: {
          'ui:widget': CustomRoomMappingsWidget,
          'ui:options': {
            rooms: props.property.rooms,
          },
        },
      },
    },
    roomMapping: {
      'ui:options': {
        removable: false,
        orderable: false,
        addable: false,
      },
      items: {},
    },
    videos: {
      'ui:options': {
        removable: true,
        orderable: true,
        addable: true,
      },
      items: {},
    },
    campaigns: {
      'ui:options': {
        submitButtonOptions: {
          norender: true,
        },
      },
    },
  };
};
