import pluralize from 'pluralize';

import { diffDays, isAfter, isBefore } from '~/services/TimeService';

interface CruiseDealStatusProps {
  startDate: string;
  endDate: string;
}

export interface CruiseDealStatus {
  status: string;
  color: string;
}

export default function cruiseDealStatus({ startDate, endDate }: CruiseDealStatusProps): CruiseDealStatus | undefined {
  const now = new Date();
  const start = new Date(startDate);
  const end = new Date(endDate);

  if (isBefore(now, start)) {
    const daysUntilStart = diffDays(start, now, 'day');
    const daysUntilStartString = daysUntilStart === 1 ? 'day' : 'days';
    return {
      status: `live in ${daysUntilStart} ${daysUntilStartString}`,
      color: 'green',
    };
  }

  if (isAfter(now, end)) {
    const daysSinceEnd = diffDays(now, end, 'day');
    const daysSinceEndString = pluralize('day', daysSinceEnd);
    return {
      status: `ended ${daysSinceEnd} ${daysSinceEndString} ago`,
      color: 'red',
    };
  }

  if (isBefore(start, end)) {
    return {
      status: 'currently live',
      color: 'green',
    };
  }

  return;
}
