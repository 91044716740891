import React from 'react';

import sum from 'lodash/sum';

import { Box, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';

import { Order } from '@luxuryescapes/contract-svc-order';

import { OrderStatusChip } from '../AllOrders/components/OrderStatusChip';

import dateFormatter from './dateFormatter';

const orderInformationFormatter = (params: GridRenderCellParams<Order.Order>) => {
  const order = params.row;

  const bedbankMarginTotal = sum(order.bedbank_items.map((item) => item.marketing_fee));
  const bedbankOrderTotal = sum(order.bedbank_items.map((item) => item.total));

  // in the unlikely case a bedbank order has total === 0
  const bedbankMarginPercentage = bedbankOrderTotal > 0 ? (bedbankMarginTotal / bedbankOrderTotal) * 100 : 0;

  return (
    <Box sx={{ '& strong': { fontWeight: 500 } }}>
      <Typography>{dateFormatter(order.created_at)}</Typography>
      <OrderStatusChip orderStatus={order.status} />

      {order.bedbank_items.length > 0 && bedbankMarginTotal && (
        <>
          <Typography fontWeight="bold">Bedbank Margins</Typography>
          <Typography>
            {bedbankMarginTotal.toFixed(2)} ({bedbankMarginPercentage.toFixed(2)}% of {bedbankOrderTotal.toFixed(2)})
          </Typography>
        </>
      )}
    </Box>
  );
};

export default orderInformationFormatter;
