import React, { useCallback } from 'react';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';

import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Box, Button } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import { useConfirmDialog } from '~/components/Common/Providers/ConfirmDialogProvider';
import { CruiseDealInclusion } from '~/components/Cruises/pages/DealsPage/types';

import dealPageService from '~/services/cruises/DealPageService';

interface Props {
  inclusion: CruiseDealInclusion;
  onDelete: () => void;
}

function DealInclusionListItem({ inclusion, onDelete }: Props) {
  const { attributes, isDragging, listeners, setNodeRef, transform, transition } = useSortable({ id: inclusion.id });
  const { enqueueSnackbar } = useSnackbar();
  const { id: dealPageId } = useParams<{ id: string }>();

  const showConfirmDialog = useConfirmDialog();

  const handleDeleteRecord = useCallback(async () => {
    try {
      const confirmed = await showConfirmDialog({
        title: 'Delete Inclusion?',
        description: `This will delete the inclusion, are you sure you want to proceed?`,
      });

      if (!confirmed) {
        return;
      }

      await dealPageService.deleteDealInclusion(dealPageId, inclusion.id);
      enqueueSnackbar('Inclusion deleted', { variant: 'success' });
      onDelete();
    } catch (error) {
      enqueueSnackbar('Error deleting Inclusion', { variant: 'error' });
    }
  }, [enqueueSnackbar, dealPageId, inclusion.id, showConfirmDialog]);

  return (
    <ListItem
      key={inclusion.id}
      {...attributes}
      sx={{
        flexGrow: 1,
        transform: CSS.Transform.toString(transform),
        transition,
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box ref={setNodeRef} {...listeners} sx={{ cursor: isDragging ? 'grabbing' : 'grab' }} mr={2}>
        <DragIndicatorIcon />
      </Box>

      <ListItemText primary={inclusion.description} secondary={`Icon: ${inclusion.icon}`} />

      <Button variant="text" size="small" color="error" onClick={handleDeleteRecord}>
        <DeleteIcon />
      </Button>
    </ListItem>
  );
}

export default DealInclusionListItem;
