import React, { Component } from 'react';

import styled from 'styled-components';
import dayjs from '~/timeInit';

import { Dialog, DialogContent, DialogTitle, Pagination } from '@mui/material';

import { Code } from '~/components/Common/Code';

import isJSON from '~/utils/isJSON';

const ContentBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & > .left-block {
    display: flex;
    flex-direction: column;
  }

  & > .right-block {
    display: flex;
    flex-direction: column;
    align-items: end;
  }
`;

export default class AriLogs extends Component {
  constructor(props) {
    super(props);
    this.state = { modalContent: null, activePage: 1, logsPerPage: 5 };
  }

  render() {
    const { ariLogs, date, onHide } = this.props;

    return (
      <Dialog maxWidth="lg" fullWidth open={date !== null} onClose={onHide}>
        <DialogTitle id="contained-modal-title-vcenter">Ari Logs: {date}</DialogTitle>
        <DialogContent>
          {ariLogs === null && <h6 className="h6 text-uppercase text-muted">Loading</h6>}

          {ariLogs !== null && ariLogs?.length === 0 && <h6 className="h6 text-uppercase text-muted">No Logs Found</h6>}

          {ariLogs === 'NOT_SUPPORTED' && (
            <h6 className="h6 text-uppercase text-muted">This channel manager does not support ari logs.</h6>
          )}

          {Array.isArray(ariLogs) &&
            ariLogs.length > 0 &&
            ariLogs
              .slice(
                (this.state.activePage - 1) * this.state.logsPerPage,
                this.state.activePage * this.state.logsPerPage,
              )
              .map((ariLog, index) => (
                <div key={index} className="box-list-item">
                  <ContentBlock>
                    <div className="left-block">
                      <div>{dayjs(ariLog.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</div>
                      <div>
                        {dayjs(ariLog.startDate).format('DD MMM YYYY')} - {dayjs(ariLog.endDate).format('DD MMM YYYY')}
                      </div>
                    </div>
                    <div className="right-block">
                      <div>{ariLog.messageName}</div>
                      <div>Hotel Code: {ariLog.hotelCode}</div>
                    </div>
                  </ContentBlock>
                  <h6 className="h6 text-uppercase text-muted">Original Request</h6>
                  {isJSON(ariLog.originalRequest) && (
                    <Code value={ariLog.originalRequest} lang="json" style={{ maxHeight: '30vh' }} />
                  )}
                  {!isJSON(ariLog.originalRequest) && (
                    <Code value={ariLog.originalRequest} lang="xml" style={{ maxHeight: '30vh' }} />
                  )}
                </div>
              ))}

          {Array.isArray(ariLogs) && ariLogs.length > 0 && (
            <Pagination
              count={Math.ceil(ariLogs.length / this.state.logsPerPage)}
              page={this.state.activePage + 1}
              onChange={(_, page) => this.setState({ activePage: page - 1 })}
            />
          )}
        </DialogContent>
      </Dialog>
    );
  }
}
