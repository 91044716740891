import React, { useCallback } from 'react';

import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { v4 as uuid } from 'uuid';

import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

import { createNewVid } from '~/services/PublicOfferFeedService';

import LayerForm from './LayerForm';

const TRANSITION_TYPES = [
  'directional-left',
  'directional-right',
  'directional-up',
  'directional-down',
  'random',
  'dummy',
];

const AUDIO_CURVES = [
  'tri',
  'qsin',
  'hsin',
  'esin',
  'log',
  'ipar',
  'qua',
  'cub',
  'squ',
  'squ',
  'cbr',
  'par',
  'exp',
  'iqsin',
  'ihsin',
  'dese',
  'desi',
  'losi',
  'nofade',
];

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

function VideoEditorForm({ setOpen }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const handleClose = () => {
    setOpen(false);
  };
  const [videoName, setVideoName] = React.useState('');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [videoAttributes, setVideoAttributes] = React.useState({} as any);
  const [defaultTransition, setDefaultTransition] = React.useState({
    name: TRANSITION_TYPES[0],
    duration: 0.5,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as any);
  const [clipComponents, setClipComponents] = React.useState([]);
  const [openClipForm, setOpenClipForm] = React.useState(false);
  const [activeClipIndex, setActiveClipIndex] = React.useState(undefined);
  const [activeLayerIndex, setActiveLayerIndex] = React.useState(undefined);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleCreate = useCallback(async () => {
    const videoId = uuid();
    const payload = {
      ...videoAttributes,
      outPath: `./videos/${videoName + '_' + videoId}.mov`,
      thumbnailPath: `./thumbnails/${videoName + '_' + videoId}.png`,
      allowRemoteRequests: true,
      defaults: { transition: defaultTransition, layer: { fontPath: './examples/assets/suisse-intl-regular.ttf' } },
      clips: clipComponents,
    };
    try {
      await createNewVid(payload, videoName);
      enqueueSnackbar('Video created successfully', { variant: 'success' });
      setOpen(false);
    } catch (error) {
      enqueueSnackbar('Failed to create video', { variant: 'error' });
    }
  }, [clipComponents, defaultTransition, enqueueSnackbar, setOpen, videoAttributes, videoName]);

  // CLIP COMPONENTS HANDLERS START
  const addNewClipComponent = useCallback(() => {
    setClipComponents([
      ...clipComponents,
      {
        duration: 5,
        transition: { name: TRANSITION_TYPES[0], duration: 0.5 },
      },
    ]);
  }, [clipComponents]);

  const removeClipComponent = useCallback(
    (index) => {
      const newClipComponents = clipComponents.filter((_, i) => i !== index);
      setClipComponents(newClipComponents);
    },
    [clipComponents],
  );

  const updateClipComponent = useCallback(
    (index, key, value) => {
      const newClipComponents = clipComponents.map((clip, i) => {
        if (i === index) {
          return {
            ...clip,
            [key]: value,
          };
        }
        return clip;
      });
      setClipComponents(newClipComponents);
    },
    [clipComponents],
  );

  const addNewLayerToClip = useCallback(
    (index) => {
      setOpenClipForm(true);
      setActiveClipIndex(index);
    },
    [setOpenClipForm],
  );

  const saveNewLayerToClip = useCallback(
    (layer) => {
      const newClipComponents = clipComponents.map((clip, i) => {
        if (i === activeClipIndex) {
          const newLayers = clip.layers ? [...clip.layers, layer] : [layer];
          return {
            ...clip,
            layers: newLayers,
          };
        }
        return clip;
      });
      setClipComponents(newClipComponents);
      setOpenClipForm(false);
      setActiveClipIndex(undefined);
    },
    [clipComponents, activeClipIndex, setOpenClipForm],
  );

  const initiateLayerEdit = useCallback(
    (clipIndex, layerIndex) => {
      setActiveClipIndex(clipIndex);
      setActiveLayerIndex(layerIndex);
      setOpenClipForm(true);
    },
    [setOpenClipForm],
  );

  const updateLayerInClip = useCallback(
    (data) => {
      const newClipComponents = clipComponents.map((clip, i) => {
        if (i === activeClipIndex) {
          const newLayers = clip.layers.map((layer, j) => {
            if (j === activeLayerIndex) {
              return data;
            }
            return layer;
          });
          return {
            ...clip,
            layers: newLayers,
          };
        }
        return clip;
      });
      setOpenClipForm(false);
      setClipComponents(newClipComponents);
      setActiveClipIndex(undefined);
      setActiveLayerIndex(undefined);
    },
    [activeClipIndex, activeLayerIndex, clipComponents],
  );

  const removeLayerFromClip = useCallback(
    (clipIndex, layerIndex) => {
      const newClipComponents = clipComponents.map((clip, i) => {
        if (i === clipIndex) {
          const newLayers = clip.layers.filter((_, j) => j !== layerIndex);
          return {
            ...clip,
            layers: newLayers,
          };
        }
        return clip;
      });
      setClipComponents(newClipComponents);
    },
    [clipComponents],
  );
  // CLIP COMPONENTS HANDLERS END
  if (openClipForm) {
    return (
      <LayerForm
        open={openClipForm}
        setOpen={setOpenClipForm}
        activeClip={clipComponents[activeClipIndex]}
        activeLayerIndex={activeLayerIndex}
        saveNewLayerToClip={saveNewLayerToClip}
        updateLayerInClip={updateLayerInClip}
      />
    );
  }

  return (
    <Dialog fullWidth maxWidth="xl" open onClose={handleClose} component="form" onSubmit={handleSubmit(handleCreate)}>
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Create a Video
      </DialogTitle>
      <DialogContent dividers>
        <Stack spacing={2}>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <Typography variant="h6">Default Attributes</Typography>
            </Grid>
          </Grid>
        </Stack>
        <Stack spacing={2}>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <Stack spacing={2}>
                <TextField
                  id="video_name"
                  label="Video Name"
                  variant="outlined"
                  value={videoName}
                  error={!!errors.video_name}
                  helperText={errors.video_name?.message}
                  autoComplete="off"
                  onChange={(e) => setVideoName(e.target.value)}
                />
              </Stack>
            </Grid>
            <Grid item xs={3}>
              <Tooltip title="Width which all media will be converted to" placement="bottom">
                <TextField
                  fullWidth
                  id="width"
                  label="Video Width"
                  variant="outlined"
                  {...register('width', {
                    required: 'Width is required',
                  })}
                  onChange={(e) => setVideoAttributes({ ...videoAttributes, width: Number(e.target.value) })}
                  value={videoAttributes.width}
                  inputProps={{ min: 0 }}
                  type="number"
                  error={!!errors.width}
                  helperText={errors.width?.message}
                  autoComplete="off"
                />
              </Tooltip>
            </Grid>
            <Grid item xs={3}>
              <Tooltip title="Height which all media will be converted to" placement="bottom">
                <TextField
                  fullWidth
                  id="height"
                  label="Video Height"
                  variant="outlined"
                  {...register('height', {
                    required: 'Height is required',
                  })}
                  onChange={(e) => setVideoAttributes({ ...videoAttributes, height: Number(e.target.value) })}
                  value={videoAttributes.height}
                  inputProps={{ min: 0 }}
                  type="number"
                  error={!!errors.height}
                  helperText={errors.height?.message}
                  autoComplete="off"
                />
              </Tooltip>
            </Grid>
            <Grid item xs={3}>
              <Tooltip title="FPS which all media will be converted to" placement="bottom">
                <TextField
                  fullWidth
                  id="fps"
                  label="Video FPS"
                  variant="outlined"
                  {...register('fps', {
                    required: 'FPS is required',
                  })}
                  onChange={(e) => setVideoAttributes({ ...videoAttributes, fps: e.target.value })}
                  value={videoAttributes.fps}
                  inputProps={{ min: 0 }}
                  type="number"
                  error={!!errors.fps}
                  helperText={errors.fps?.message}
                  autoComplete="off"
                />
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <Stack spacing={2}>
                <TextField
                  id="audio_file_path"
                  label="Audio File Path"
                  variant="outlined"
                  error={!!errors.audio_file_path}
                  helperText={errors.audio_file_path?.message}
                  autoComplete="off"
                  onChange={(e) => setVideoAttributes({ ...videoAttributes, audio_file_path: e.target.value })}
                  value={videoAttributes.audio_file_path}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <Typography variant="h6">Transition</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <Stack spacing={2}>
                <TextField
                  id="transition_duration"
                  label="Transition Duration"
                  variant="outlined"
                  error={!!errors.transition_duration}
                  helperText={errors.transition_duration?.message}
                  autoComplete="off"
                  type="number"
                  value={defaultTransition.duration}
                  inputProps={{ min: 0 }}
                  onChange={(e) =>
                    setDefaultTransition({
                      ...defaultTransition,
                      duration: Number(e.target.value),
                    })
                  }
                />
              </Stack>
            </Grid>
            <Grid item xs={3}>
              <Stack spacing={2}>
                <Autocomplete
                  id="transition_type"
                  options={TRANSITION_TYPES}
                  value={defaultTransition.name}
                  onChange={(e) =>
                    setDefaultTransition({
                      ...defaultTransition,
                      name: (e.target as HTMLInputElement).value,
                    })
                  }
                  renderInput={(params) => <TextField {...params} label="Transition Types" />}
                />
              </Stack>
            </Grid>
            <Grid item xs={3}>
              <Stack spacing={2}>
                <Autocomplete
                  id="audio_out_curve"
                  options={AUDIO_CURVES}
                  value={defaultTransition.audio_out_curve}
                  onChange={(_, newValue) => {
                    setDefaultTransition({ ...defaultTransition, audio_out_curve: newValue });
                  }}
                  renderInput={(params) => <TextField {...params} label="Audio Out Curve" />}
                />
              </Stack>
            </Grid>
            <Grid item xs={3}>
              <Stack spacing={2}>
                <Autocomplete
                  id="audio_in_curve"
                  options={AUDIO_CURVES}
                  value={defaultTransition.audio_in_curve}
                  onChange={(_, newValue) => {
                    setDefaultTransition({ ...defaultTransition, audio_out_curve: newValue });
                  }}
                  renderInput={(params) => <TextField {...params} label="Audio In Curve" />}
                />
              </Stack>
            </Grid>
          </Grid>
        </Stack>
        <Stack spacing={2}>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <Typography variant="h6">Clip Components</Typography>
            </Grid>
            <Grid item xs={3}>
              <Button variant="contained" onClick={() => addNewClipComponent()}>
                Add Clip
              </Button>
            </Grid>
          </Grid>
          {/* LIST OF CLIPS */}
          {clipComponents.map((clip, index) => (
            <Grid container spacing={1} key={'clip' + index}>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  id={`clip_duration_${index}`}
                  label="Clip Duration"
                  variant="outlined"
                  {...register(`clip_duration_${index}`, {
                    required: 'Clip Duration is required',
                  })}
                  inputProps={{ min: 0.0, step: 0.01 }}
                  value={clip.duration}
                  type="number"
                  error={!!errors.clip_duration}
                  helperText={errors.clip_duration?.message}
                  autoComplete="off"
                  onChange={(e) => updateClipComponent(index, 'duration', e.target.value)}
                />
              </Grid>
              <Grid item xs={3} container>
                <Grid item xs={9}>
                  <Autocomplete
                    id={`clip_transition_type_${index}`}
                    options={TRANSITION_TYPES}
                    onChange={(_, newValue) => {
                      updateClipComponent(index, 'transition', { ...clip.transition, name: newValue });
                    }}
                    value={clip.transition.name}
                    renderInput={(params) => <TextField {...params} label="Transition Types" />}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    id={`clip_transition_duration_${index}`}
                    label="Duration"
                    variant="outlined"
                    {...register(`clip_transition_duration_${index}`, {
                      required: 'Transition Duration is required',
                    })}
                    inputProps={{ min: 0.0, step: 0.01 }}
                    value={clip.transition.duration}
                    type="number"
                    error={!!errors.clip_transition_duration}
                    helperText={errors.clip_transition_duration?.message}
                    autoComplete="off"
                    onChange={(e) =>
                      updateClipComponent(index, 'transition', { ...clip.transition, duration: e.target.value })
                    }
                  />
                </Grid>
              </Grid>
              <Grid item xs={3} container spacing={1}>
                {clip.layers?.map((layer, layerIndex) => (
                  <Grid xs={12} container item key={'layer' + layerIndex} spacing={1}>
                    <Grid item xs={2} alignContent="center">
                      <Typography>{layer.type}</Typography>
                    </Grid>
                    <Grid item>
                      <Button variant="contained" onClick={() => removeLayerFromClip(index, layerIndex)}>
                        Remove Layer
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button variant="contained" onClick={() => initiateLayerEdit(index, layerIndex)}>
                        Edit
                      </Button>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              <Grid item xs={3} spacing={0.5} container>
                <Grid item>
                  <Button variant="contained" onClick={() => addNewLayerToClip(index)}>
                    Add Layer
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="contained" onClick={() => removeClipComponent(index)}>
                    Remove
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant="contained" type="submit">
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default VideoEditorForm;
