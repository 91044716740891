import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation } from 'react-router';

import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Link } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import GridPagination from '~/components/Common/Elements/GridPagination';
import PageSubheader from '~/components/Common/Elements/PageSubheader';

import useCurrentUser from '~/hooks/useCurrentUser';
import useQuery from '~/hooks/useQuery';

import { resendEmail, searchSentEmails } from '~/services/SailthruService';
import { formatDateWithClock } from '~/services/TimeService';

import { addQuery } from '~/utils/url';

import { Code } from '../Common/Code';

import EmailFormModal from './EmailFormModal';

const SIZE_PER_PAGE = 20;
const DEFAULT_ROW_COUNT = 100;

function EmailFailedSendContainer() {
  const location = useLocation();
  const query = useQuery();
  const [dataState, setData] = useState([]);
  const { push: setQueryString } = useHistory();
  const [fetchingState, setFetchingState] = useState<Utils.FetchingState>('loading');
  const [openDebug, setOpenDebug] = useState(false);
  const [debugJson, setDebugJson] = useState<{ [key: string]: unknown }>({});
  const [_currentPage, setCurrentPage] = useState(1);
  const currentPage = query.get('page') ? parseInt(query.get('page') as string) : _currentPage;
  const [rowCountState, setRowCountState] = useState(DEFAULT_ROW_COUNT);
  const [openModalId, setOpenModalId] = useState(null);
  const { user } = useCurrentUser();

  const handleOpenModal = (id) => setOpenModalId(id);
  const handleCloseModal = () => setOpenModalId(null);

  const emailAddress = decodeURIComponent(query.get('email')) ?? '';

  const emailKey = query.get('key') ? decodeURIComponent(query.get('key')) : undefined;

  const { enqueueSnackbar } = useSnackbar();

  const resendEmailCallback = useCallback(async (params) => {
    try {
      await resendEmail({ id: params.row.id, email: params.row.email });
      enqueueSnackbar('Email successfully resent', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar('Failed to resend email', { variant: 'error' });
    }
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'notification_type',
      headerName: 'Notification Type',
      width: 250,
      editable: false,
      display: 'flex',
    },
    {
      field: 'updated_at',
      headerName: 'Sent',
      width: 120,
      editable: false,
      renderCell: (params) => formatDateWithClock(params.value),
      display: 'flex',
    },
    {
      field: 'delivered_at',
      headerName: 'Delivered',
      width: 120,
      editable: false,
      renderCell: (params) => (params.value ? formatDateWithClock(params.value) : '-'),
      display: 'flex',
    },
    {
      field: 'opened_at',
      headerName: 'Opened',
      width: 120,
      editable: false,
      renderCell: (params) => (params.value ? formatDateWithClock(params.value) : '-'),
      display: 'flex',
    },
    {
      field: 'clicked_at',
      headerName: 'Clicked',
      width: 120,
      editable: false,
      renderCell: (params) => (params.value ? formatDateWithClock(params.value) : '-'),
      display: 'flex',
    },
    {
      field: 'dropped_at',
      headerName: 'Dropped',
      width: 120,
      editable: false,
      renderCell: (params) => (params.value ? formatDateWithClock(params.value) : '-'),
      display: 'flex',
    },
    {
      field: 'bounced_at',
      headerName: 'Bounced',
      width: 120,
      editable: false,
      renderCell: (params) => (params.value ? formatDateWithClock(params.value) : '-'),
      display: 'flex',
    },
    {
      field: 'failed_reason',
      headerName: 'Reason for Failure',
      width: 40,
      editable: false,
      renderCell: (params) => (params.value ? params.value : '-'),
      display: 'flex',
    },
    {
      field: 'view_email',
      headerName: 'View Email',
      width: 150,
      editable: false,
      renderCell: (params) => {
        return (
          <Link
            color="primary"
            target="_blank"
            rel="noreferrer"
            href={`/email/render?id=${params.row.id}`}
            underline="hover"
          >
            View Email
          </Link>
        );
      },
      display: 'flex',
    },
    {
      field: 'forward_email',
      headerName: 'Forward Email',
      width: 150,
      editable: false,
      renderCell: (params) => {
        return (
          <div key={params.row.id}>
            <Button variant="text" size="small" onClick={() => handleOpenModal(params.row.id)}>
              Forward Email
            </Button>
            <EmailFormModal
              open={openModalId === params.row.id}
              handleClose={handleCloseModal}
              params={params}
              initialEmail={user.email}
            />
          </div>
        );
      },
      display: 'flex',
    },
    {
      field: 'resend_email',
      headerName: 'Resend Email',
      width: 150,
      editable: false,
      renderCell: (params) => {
        return (
          <Button
            variant="text"
            size="small"
            onClick={async (event) => {
              event.preventDefault();
              event.stopPropagation();
              await resendEmailCallback(params);
            }}
          >
            Resend Email
          </Button>
        );
      },
      display: 'flex',
    },
  ];

  const fetchData = useCallback(
    async ({ email, key, currentPage }: { email: string; key: string; currentPage: number }) => {
      setFetchingState('loading');
      let sentEmails;
      let rowCount;
      try {
        const res = await searchSentEmails({
          email,
          key,
          offset: (currentPage - 1) * SIZE_PER_PAGE,
          limit: SIZE_PER_PAGE,
        });
        sentEmails = res.result;
        rowCount = res.resultCount;
        setFetchingState('success');
      } catch (e) {
        setFetchingState('failed');
        sentEmails = [];
      }

      setData(sentEmails);
      if (rowCount && currentPage === 1) {
        setRowCountState(rowCount);
      }
    },
    [],
  );

  useEffect(() => {
    setQueryString(addQuery(location, { page: currentPage, email: emailAddress, key: emailKey }));
    fetchData({ email: emailAddress, key: emailKey, currentPage: Number(currentPage) });
  }, [currentPage, emailAddress, emailKey, fetchData]);

  const code = useMemo(() => {
    return JSON.stringify(debugJson, null, 2);
  }, [debugJson]);

  const dataRows = dataState.map((row, index) => ({
    id: index,
    ...row,
  }));

  const onPageChange = useCallback((page: number) => {
    setCurrentPage(page);
    setQueryString(addQuery(location, { page, email: emailAddress }));
  }, []);

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>Sent Emails</title>
      </Helmet>
      <PageSubheader title={`Emails Sent To: ${emailAddress}`} />
      <Grid container spacing={2} />
      <Box mt={2}>
        <DataGrid
          loading={fetchingState === 'loading'}
          rows={dataRows}
          columns={columns}
          disableRowSelectionOnClick={true}
          autoHeight
          pagination
          paginationMode="server"
          paginationModel={{
            page: currentPage - 1,
            pageSize: SIZE_PER_PAGE,
          }}
          pageSizeOptions={[SIZE_PER_PAGE]}
          classes={{
            root: 'T-packages-container',
            virtualScrollerContent: 'T-packages-table',
          }}
          slots={{ pagination: GridPagination }}
          onPaginationModelChange={({ page }) => onPageChange(page + 1)}
          getRowHeight={() => 'auto'}
          rowCount={rowCountState}
        />
      </Box>
      <Dialog open={openDebug} onClose={() => setOpenDebug(false)} maxWidth="md">
        <DialogTitle>Email input internal details</DialogTitle>

        <DialogContent>
          <Code value={code} lang="json" />
        </DialogContent>

        <DialogActions>
          <Button variant="text" onClick={() => setOpenDebug(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default EmailFailedSendContainer;
