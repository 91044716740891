import React, { useCallback, useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router';

import { Container, Stack, Tab, Tabs } from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';

import AccommodationProperties from '../Properties/AccommodationProperties';

export enum AccommodationTabs {
  PROPERTIES = 'properties',
  UNMAPPED_SUPPLIERS = 'unmapped_suppliers',
  POTENTIAL_MAPPINGS = 'potential_mappings',
}

export default function AccommodationHomePage() {
  const [selectedTab, setSelectedTab] = useState(
    (window.location.hash?.replace('#', '') as AccommodationTabs) ?? AccommodationTabs.PROPERTIES,
  );
  const history = useHistory();

  useEffect(() => {
    if (window.location.hash?.replace('#', '') === '') {
      window.location.hash = AccommodationTabs.PROPERTIES;
      setSelectedTab(AccommodationTabs.PROPERTIES);
    }
  }, []);

  const handleTabChange = useCallback(
    (event: React.SyntheticEvent, newValue: AccommodationTabs) => {
      setSelectedTab(newValue);
      history.push({
        search: '',
      });
      window.location.hash = newValue;
    },
    [history],
  );

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>Accommodation - Properties</title>
      </Helmet>
      <PageHeader title="Accommodation - Properties" />
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <Tabs sx={{ mb: 2 }} value={selectedTab} onChange={handleTabChange}>
          <Tab key={AccommodationTabs.PROPERTIES} value={AccommodationTabs.PROPERTIES} label="Properties" />
          <Tab
            key={AccommodationTabs.POTENTIAL_MAPPINGS}
            value={AccommodationTabs.POTENTIAL_MAPPINGS}
            label="Potential Mappings"
          />
          <Tab
            key={AccommodationTabs.UNMAPPED_SUPPLIERS}
            value={AccommodationTabs.UNMAPPED_SUPPLIERS}
            label="Unmapped Suppliers"
          />
        </Tabs>
      </Stack>
      <AccommodationProperties selectedTab={selectedTab} />
    </Container>
  );
}
