import React, { MouseEventHandler, useCallback } from 'react';

import { useSnackbar } from 'notistack';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Alert,
  Backdrop,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

import useImageUrlGenerator from '~/hooks/useImageUrlGenerator';

import { isRequestFulfilled, isRequestRejected, isRequestUnresolved } from '~/utils/requestUtils';

type FileUploadRequest = Utils.RequestState<string, string, { id: string }>;

interface Props {
  file: File;
  request: FileUploadRequest;
}

function ImageUploadRequestCard({ file, request }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const url = useImageUrlGenerator(isRequestFulfilled(request) ? request.result : '');
  const previewUrl = useImageUrlGenerator(isRequestFulfilled(request) ? request.result : '', { width: 200 });
  const previewBlurUrl = useImageUrlGenerator(isRequestFulfilled(request) ? request.result : '', { width: 10 });

  const handleCopyToClipboardClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      navigator.clipboard.writeText(event.currentTarget.dataset.imageId);
      enqueueSnackbar(`Copied "${request.result}" to clipboard.`, { variant: 'info' });
    },
    [enqueueSnackbar, request.result],
  );
  return (
    <Card raised sx={{ position: 'relative' }}>
      <CardActions sx={{ justifyContent: 'space-between' }}>
        <Typography typography="caption">{file.name}</Typography>
        <Tooltip placement="top" title="Open image in new tab">
          <IconButton size="small" href={url} target="_blank">
            <OpenInNewIcon />
          </IconButton>
        </Tooltip>
      </CardActions>
      <CardMedia
        component="img"
        src={previewUrl}
        sx={{
          backgroundImage: `url(${previewBlurUrl})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          position: 'relative',
          backgroundColor: '#eee',
          objectFit: 'contain',
          aspectRatio: '1.618',
        }}
      />
      <CardContent>
        <TextField
          variant="outlined"
          value={request.result}
          fullWidth
          margin="none"
          inputProps={{ readOnly: true }}
          disabled={!isRequestFulfilled(request)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip placement="top" title="Copy to clipboard">
                  <IconButton
                    edge="end"
                    type="button"
                    data-image-id={request.result}
                    onClick={handleCopyToClipboardClick}
                  >
                    <ContentCopyIcon />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
        {isRequestRejected(request) && <Alert severity="error">{request.error}</Alert>}
      </CardContent>
      <Backdrop open={isRequestUnresolved(request)} sx={{ position: 'absolute' }}>
        <CircularProgress color="info" size={80} />
      </Backdrop>
    </Card>
  );
}

export default ImageUploadRequestCard;
