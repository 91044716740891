import qs from 'qs';

import { actions, definitions } from '@luxuryescapes/contract-svc-flights';

import { AtolForm } from '~/components/Users/Orders/Atol/AtolPage';

import { request } from './common';

function basePath() {
  return window.configs.API_HOST + '/api/';
}

const validRates = ['daily', 'twice daily'];
const validTravelDayLongNames = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
const validTravelDayNames = [
  ...validTravelDayLongNames,
  ...validTravelDayLongNames.map((name) => name.substring(0, 3)),
];

export const structureTravelDaysForApi = (travelDays: string) => {
  const days = travelDays
    .split(/\s*,\s*/)
    .map((value) => value.toLowerCase().trim())
    .filter(Boolean);

  if (days.length === 0) {
    return null;
  }

  if (days.length === 1 && validRates.includes(days[0])) {
    return days[0];
  }

  return days;
};

export const validateTravelDays = (travelDays: string | string[] | null) => {
  if (!travelDays) {
    return true;
  }
  if (typeof travelDays === 'string') {
    return validRates.includes(travelDays);
  }
  if (!Array.isArray(travelDays)) {
    return false;
  }
  return travelDays.every((day) => validTravelDayNames.includes(day));
};

export const transformTravelDaysFromApi = (travelDays: string | string[] | null) => {
  if (!travelDays) {
    return '';
  }
  if (typeof travelDays === 'string') {
    return travelDays;
  }
  return travelDays.join(', ');
};

function postRequest(path, data) {
  return request(path, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: { 'Content-Type': 'application/json' },
  });
}

function putRequest(path, data) {
  return request(path, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: { 'Content-Type': 'application/json' },
  });
}

function getRequest(path) {
  return request(path, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
}

function patchRequest(path, data) {
  return request(path, {
    method: 'PATCH',
    body: JSON.stringify(data),
    headers: { 'Content-Type': 'application/json' },
  });
}

function deleteRequest(path) {
  return request(path, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  });
}

export function retrievePNRBookingDetails(data) {
  return postRequest(basePath() + 'flights/retrievePNRBookingDetails', data);
}

export interface CreateFlightDealData {
  carrierCode: string;
  region: string;
  originAirportCode: string;
  destinationAirportCode: string;
  fareType: string;
  offerDurations: Array<{
    departureDate: string;
    arrivalDate: string;
  }>;
  perAdultPrice?: number;
  searchText: string;
  salesStartDate: string;
  salesEndDate: string;
  dealName?: string;
  dealDescription?: string;
  heroImagePublicId?: string;
  categoryGroup: string;
  fareClass?: string;
  baggageIncluded?: boolean;
  fareName?: string;
  adultCount?: number;
  fareBasis?: string;
  discountLabel?: string;
  bookingClassCode: string;
  termsAndConditions: string;
  travelDays?: string | Array<string>;
  hierarchy?: number;
  airlineCampaign?: string;
  destinationCampaign?: string;
  departureAirportCampaign?: string;
}

export function createFlightDeal(data: CreateFlightDealData) {
  return postRequest(basePath() + 'flights/flight-deals/create', data);
}

export function updateFlightDeal(dealId: string, data: Partial<CreateFlightDealData>) {
  return patchRequest(basePath() + `flights/flight-deals/${dealId}/edit`, data);
}

export function deleteFlightDeal(dealId: string) {
  return deleteRequest(basePath() + `flights/flight-deals/${dealId}`);
}

export function retrieveAtolDetails(data) {
  return postRequest(basePath() + 'flights/atol/getAll', data);
}

export async function getCreditReservations(data) {
  return postRequest(basePath() + 'flights/credit-reservation/getAll', data);
}

export async function getCreditReservationDetails(id) {
  return getRequest(basePath() + `flights/credit-reservation/${id}/details`);
}

export async function getCreditReservationByPnrId(pnrId) {
  return getRequest(basePath() + `flights/credit-reservation/${pnrId}/by-previous-id`);
}

export async function updateCreditReservation(id, data) {
  return putRequest(basePath() + `flights/credit-reservation/${id}/update`, data);
}

export async function getRecordsDataByUrl(url, data) {
  return postRequest(basePath() + `flights/reports/${url}`, data);
}

export interface GetFlightDealsData {
  pageSize?: number;
  page?: number;
  attributes?: Array<string>;
  searchString?: string;
  filter?: {
    column: string;
    value: string;
  };
  orderBy?: {
    column: string;
    sort: string;
  };
}

export async function getFlightDeals(
  data: GetFlightDealsData,
): Promise<{ result: { count: number; rows: Array<App.FlightDeal> } }> {
  return postRequest(basePath() + 'flights/flight-deals/getAll', data);
}

export async function getFlightDeal(dealId: string): Promise<{ result: App.FlightDeal }> {
  return getRequest(basePath() + `flights/flight-deals/${dealId}`);
}

export async function getAllAirlineCarriers(brand = 'luxuryescapes') {
  return getRequest(basePath() + `flights/airlines?brand=${brand}`);
}

type GetAllForceBundlesResponse = Result<App.ForceBundle[]>;
export async function getAllForceBundles(): Promise<GetAllForceBundlesResponse> {
  return getRequest(basePath() + 'flights/force-bundles');
}

type GetForceBundleResponse = Result<App.ForceBundle>;
export async function getForceBundle(id: string): Promise<GetForceBundleResponse> {
  return getRequest(basePath() + 'flights/force-bundles/' + id);
}

type ForceBundleAvailabilityRequest = {
  brand: string;
  forceBundleDealName: string;
  forceBundleId: string;
  origin: string;
  destination: string;
  region: string;
  startDate: string;
  endDate: string;
};
type ForceBundleAvailabilityResponse = Result<App.ForceBundle & { amountAvailable: number }>;
export async function getForceBundleAvailability(
  data: Partial<ForceBundleAvailabilityRequest>,
): Promise<ForceBundleAvailabilityResponse> {
  return postRequest(basePath() + 'flights/force-bundles/availability', {
    brand: 'luxuryescapes',
    ...data,
  });
}

export async function sendAtolCertificate(data: AtolForm): Promise<Result<AtolForm>> {
  return postRequest(basePath() + `le-notify/atol-certificate/create`, {
    brand: 'luxuryescapes',
    ...data,
  });
}

export interface FDATable {
  name: string;
  displayName: string;
}

export async function getFDATables(): Promise<{ result: FDATable[] }> {
  return getRequest(basePath() + 'flights/fda/table-names');
}

export async function getFDATableData(
  tableName: string,
  options?: {
    limit: number;
    offset: number;
    sortField?: string;
    sortDirection?: string;
    searchField?: string;
    searchValue?: string;
  },
): Promise<{ result: { count: number; rows: Record<string, FDATableRecord>[] } }> {
  let query = {};

  if (options) {
    query = {
      limit: options.limit,
      offset: options.limit * options.offset,
    };
  }

  if (options?.sortField && options?.sortDirection) {
    query = {
      ...query,
      orderBy: `${options.sortField}:${options.sortDirection.toUpperCase()}`,
    };
  }

  if (options?.searchField && options?.searchValue) {
    query = {
      ...query,
      filterBy: `{"${options.searchField}":"${options.searchValue}"}`,
    };
  }

  return getRequest(basePath() + `flights/fda/${tableName}?${qs.stringify(query)}`);
}

export type FDATableDataType = 'number' | 'string' | 'boolean' | 'date';

export interface FDATableSchema {
  name: string;
  displayName: string;
  dataType: FDATableDataType;
  allowNull: boolean;
  maxLength?: number;
}

interface FDATableSchemaResponse {
  attributes: FDATableSchema[];
  searchSupported: boolean;
  sortableFields: string[];
  searchableFields: string[];
}

export async function getFDATableSchema(tableName: string): Promise<{ result: FDATableSchemaResponse }> {
  return getRequest(basePath() + `flights/fda/${tableName}/schema`);
}

type FDATableRecord = string | number | boolean;

export async function createFDATableRecord(
  tableName: string,
  data: Record<string, FDATableRecord>,
): Promise<{ result: Record<string, FDATableRecord> }> {
  return postRequest(basePath() + `flights/fda/${tableName}`, data);
}

export async function updateFDATableRecord(tableName: string, recordId: string, data: Record<string, FDATableRecord>) {
  return patchRequest(basePath() + `flights/fda/${tableName}/${recordId}`, data);
}

export async function deleteFDATableRecord(tableName: string, recordId: string) {
  return deleteRequest(basePath() + `flights/fda/${tableName}/${recordId}`);
}

type getCalendarSearchAdminPayload =
  actions['/api/flights/calendar-search/admin/post']['parameters']['body']['CheapestPriceParams'];
export type getCalendarSearchAdminResponse =
  actions['/api/flights/calendar-search/admin/post']['responses']['200']['content']['application/json'];
export type CheapestPrice = definitions['CheapestPrice'];

export async function getCalendarSearchAdmin(
  payload: getCalendarSearchAdminPayload,
): Promise<getCalendarSearchAdminResponse> {
  return postRequest(basePath() + 'flights/calendar-search/admin', payload);
}

const FLIGHTS_CAMPAIGNS_BASE_API = basePath() + 'flights/campaigns';

export async function createFlightCampaign(
  data: Omit<App.FlightCampaign, 'updatedAt' | 'createdAt' | 'deals' | 'deleted' | 'id'>,
): Promise<{ status: number; result: App.FlightCampaign }> {
  return postRequest(FLIGHTS_CAMPAIGNS_BASE_API, data);
}

export type FlightCampaignType = 'airline' | 'destination' | 'departure_airport' | 'all';

export async function getFlightCampaigns(filters: { type?: FlightCampaignType } = {}): Promise<{
  status: number;
  result: { rows: Array<App.FlightCampaign>; count: number };
}> {
  const query = qs.stringify({ type: filters.type });
  return getRequest(`${FLIGHTS_CAMPAIGNS_BASE_API}?${query}`);
}

export async function deleteFlightCampaign(id: string): Promise<{
  status: number;
  result: null;
}> {
  return deleteRequest(`${FLIGHTS_CAMPAIGNS_BASE_API}/${id}`);
}

export async function getFlightCampaign(id: string): Promise<{
  status: number;
  result: App.FlightCampaign;
}> {
  return getRequest(`${FLIGHTS_CAMPAIGNS_BASE_API}/${id}`);
}

export async function updateFlightCampaign(
  id: string,
  data: Partial<Omit<App.FlightCampaign, 'updatedAt' | 'createdAt' | 'id'>>,
): Promise<{ status: number; result: App.FlightCampaign }> {
  return patchRequest(`${FLIGHTS_CAMPAIGNS_BASE_API}/${id}`, data);
}

export async function getFlightCampaignDeals(id: string): Promise<{
  status: number;
  result: { rows: Array<App.FlightDeal>; count: number };
}> {
  return getRequest(`${FLIGHTS_CAMPAIGNS_BASE_API}/${id}/deals`);
}

export const FLIGHTS_TRACES_BASE_API = basePath() + 'flights/traces';

export async function getFlightTraces(id: string): Promise<{ status: number; result: Array<App.FlightTraces> }> {
  return getRequest(`${FLIGHTS_TRACES_BASE_API}/list?reservationId=${id}`);
}

export async function getFlightTrace(id: string): Promise<{ status: number; result: App.FlightTrace }> {
  return getRequest(`${FLIGHTS_TRACES_BASE_API}/fetch-trace?name=${id}`);
}

export async function getAllAirportCodes(brand: string) {
  return getRequest(basePath() + `flights/airports?brand=${brand}`);
}
