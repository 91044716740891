import React, { forwardRef, useState } from 'react';

import { Stack, TextField } from '@mui/material';

import { DynamicSectionType } from '../../context/useDynamicSections';

export enum SCHEDULE_CALL_TO_ACTION_FORM_NAMES {
  TEXT = 'call_to_action_text',
  URL = 'call_to_action_url',
}

interface Props {
  existingText?: string;
  existingUrl?: string;
}

export function parseCallToActionFormData(formData: FormData, position = -1) {
  return {
    type: DynamicSectionType.CALL_TO_ACTION,
    text: String(formData.get(SCHEDULE_CALL_TO_ACTION_FORM_NAMES.TEXT)),
    url: String(formData.get(SCHEDULE_CALL_TO_ACTION_FORM_NAMES.URL)),
    position,
  };
}

const ScheduleCallToActionSectionForm = forwardRef<HTMLFormElement, Props>((props, ref) => {
  const { existingText = '', existingUrl = '' } = props;

  const [text, setText] = useState(existingText);
  const [url, setUrl] = useState(existingUrl);

  return (
    <Stack component="form" ref={ref} spacing={2} mt={1}>
      <TextField
        fullWidth
        label="CTA"
        name={SCHEDULE_CALL_TO_ACTION_FORM_NAMES.TEXT}
        value={text}
        onChange={(e) => setText(e.target.value)}
        required
        placeholder="Enter call to action"
      />

      <TextField
        fullWidth
        label="CTA URL"
        name={SCHEDULE_CALL_TO_ACTION_FORM_NAMES.URL}
        value={url}
        onChange={(e) => setUrl(e.target.value)}
        required
        placeholder="Enter call to action URL"
      />
    </Stack>
  );
});

ScheduleCallToActionSectionForm.displayName = 'ScheduleCallToActionSectionForm';
export default ScheduleCallToActionSectionForm;
