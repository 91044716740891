import React, { useCallback, useEffect, useState } from 'react';

import { useHistory } from 'react-router';
import requestGetHeroPlannerSchedule from '~/queries/customerCommunication/requestGetHeroPlannerSchedule';
import requestPostCustomSchedule from '~/queries/customerCommunication/requestPostCustomSchedule';
import requestPostHeroPlannerSchedule from '~/queries/customerCommunication/requestPostHeroPlannerSchedule';

import { Alert, AlertTitle, Button, CircularProgress } from '@mui/material';

import { RequestStatus } from '~/consts/requestConstants';

import { useAppSelector } from '~/hooks/store';

import { getDayJs } from '~/services/TimeService';

import { isRequestInitial, isRequestPending, isRequestRejected } from '~/utils/requestUtils';

import ScheduleEntityForm from './ScheduleEntityForm';

interface Props {
  scheduleId?: string;
  segment?: string;
  editorType: 'new' | 'duplicate';
}

const EMPTY_SCHEDULE: CustomerCommunication.UnsavedHeroPlannerSchedule = {
  sendDate: getDayJs().add(1, 'day').tz(window.configs.DEFAULT_TIMEZONE),
  brandId: '',
  countryGroupId: '',
  countryId: '',
  stateId: '',
  cityId: '',
  segmentId: '',
  cadenceId: '',
  membershipId: '',

  isApprovedForTpfm: false,
  emailSubjectLine: '',
  emailPreHeader: '',
  emailBannerId: undefined,
  emailBannerPosition2Id: undefined,
  emailHeroOfferIds: [],
  pushTitle: '',
  pushSubtitle: '',
  pushMessage: '',
  pushLink: '',
  pushSvcImageId: '',
};

type CombinedUnSavedSchedule = CustomerCommunication.UnsavedHeroPlannerSchedule | CustomerCommunication.CustomSchedule;

function NewScheduleForm({ scheduleId, segment, editorType }: Props) {
  const history = useHistory();
  const brandId = useAppSelector((state) => state.tenant.brand) || '';

  const [existingScheduleReq, setExistingScheduleReq] = useState<Utils.RequestState<CombinedUnSavedSchedule, string>>({
    status: RequestStatus.INITIAL,
  });
  const requestExistingSchedule = useCallback(async () => {
    setExistingScheduleReq({
      status: RequestStatus.PENDING,
      params: scheduleId,
    });

    try {
      const result = await requestGetHeroPlannerSchedule(scheduleId, segment);
      const { id, ...unsaved } = result;
      setExistingScheduleReq({
        status: RequestStatus.FULFILLED,
        params: scheduleId,
        result: unsaved as CombinedUnSavedSchedule,
      });
    } catch (error) {
      setExistingScheduleReq({
        status: RequestStatus.REJECTED,
        params: scheduleId,
        error,
      });
    }
  }, [scheduleId, segment]);

  useEffect(() => {
    if (scheduleId) {
      requestExistingSchedule();
    }
  }, [requestExistingSchedule, scheduleId]);

  const [submissionReq, setSubmissionReq] = useState<
    Utils.RequestState<
      CustomerCommunication.HeroPlannerSchedule | CustomerCommunication.CustomSchedulePostPayload,
      string,
      CustomerCommunication.UnsavedHeroPlannerSchedule | CustomerCommunication.CustomSchedule
    >
  >({
    status: RequestStatus.INITIAL,
  });
  const handleSubmission = useCallback(
    async (unsaved: CustomerCommunication.UnsavedHeroPlannerSchedule | CustomerCommunication.CustomSchedule) => {
      setSubmissionReq({
        status: RequestStatus.PENDING,
        params: unsaved,
      });

      try {
        if ('type' in unsaved && unsaved.type === 'DESTINATION') {
          const createdCustomSchedule = await requestPostCustomSchedule(unsaved);
          setSubmissionReq({
            status: RequestStatus.FULFILLED,
            params: unsaved,
            result: createdCustomSchedule,
          });
          history.push(
            `/customer-communication/hero-planner/schedules/${createdCustomSchedule.id}?segment=DESTINATION`,
          );
        } else {
          const createdHeroPlannerSchedule = await requestPostHeroPlannerSchedule(
            unsaved as CustomerCommunication.UnsavedHeroPlannerSchedule,
          );
          setSubmissionReq({
            status: RequestStatus.FULFILLED,
            params: unsaved,
            result: createdHeroPlannerSchedule,
          });
          history.push(`/customer-communication/hero-planner/schedules/${createdHeroPlannerSchedule.id}`);
        }
      } catch (error) {
        setSubmissionReq({
          status: RequestStatus.REJECTED,
          params: unsaved,
          error,
        });
      }
    },
    [history],
  );

  if ((!!scheduleId && isRequestInitial(existingScheduleReq)) || isRequestPending(existingScheduleReq)) {
    return <CircularProgress />;
  }

  if (isRequestRejected(existingScheduleReq)) {
    return (
      <Alert
        severity="error"
        action={
          <Button size="small" color="inherit" onClick={requestExistingSchedule}>
            Retry
          </Button>
        }
      >
        <AlertTitle>Could not fetch the schedule.</AlertTitle>
        <pre>{existingScheduleReq.error}</pre>
      </Alert>
    );
  }

  return (
    <>
      <ScheduleEntityForm
        editorType={editorType}
        schedule={existingScheduleReq.result ?? { ...EMPTY_SCHEDULE, brandId }}
        busy={isRequestPending(submissionReq)}
        onSubmit={handleSubmission}
      />
      {isRequestRejected(submissionReq) && (
        <Alert severity="error">
          <AlertTitle>Please resolve the following:</AlertTitle>
          <pre>{submissionReq.error}</pre>
        </Alert>
      )}
    </>
  );
}

export default NewScheduleForm;
