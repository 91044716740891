import React from 'react';

import { Controller } from 'react-hook-form';

import ModeIcon from '@mui/icons-material/Mode';
import { Checkbox, FormControl, FormControlLabel, Grid, IconButton, Stack, Typography } from '@mui/material';

import Input from './Input';

export default function PackageInclusionItem({
  onInputChange,
  onLuxPlusExclusiveInputChange,
  getValues,
  control,
  name,
  index,
}) {
  return (
    <Grid
      container
      columns={24}
      direction="row"
      spacing={2}
      mt={0}
      alignItems="center"
      wrap="nowrap"
      sx={{ '& > .MuiGrid-item': { paddingTop: 0 } }}
    >
      <Grid item>
        <IconButton
          target="_blank"
          href={`/vendors/${getValues(`${name}.${index}.vendor_salesforce_id`)}/inclusions?inclusionId=${getValues(
            `${name}.${index}.inclusion_id`,
          )}`}
          size="large"
          color="primary"
          aria-label="Edit Asset"
        >
          <ModeIcon />
        </IconButton>
      </Grid>
      <Grid item xs={6} lg={4}>
        <Input
          control={control}
          name={`${name}.${index}.inclusion.category.name`}
          muiLabel="Category"
          disabled={getValues(`${name}.${index}.inclusion.is_hidden`)}
          fullWidth
          inputProps={{
            readOnly: true,
          }}
        />
      </Grid>
      <Grid item xs>
        <Input
          control={control}
          name={`${name}.${index}.inclusion.name`}
          muiLabel="Inclusion"
          disabled={getValues(`${name}.${index}.inclusion.is_hidden`)}
          fullWidth
          inputProps={{
            readOnly: true,
          }}
        />
      </Grid>
      <Grid item xs={5} lg={5}>
        <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
          <Typography variant="body1">Style:</Typography>
          <Controller
            render={({ field, fieldState }) => (
              <FormControl
                disabled={getValues(`${name}.${index}.inclusion.is_hidden`)}
                error={!!fieldState.error}
                fullWidth
                sx={{ position: 'relative' }}
              >
                <FormControlLabel
                  label="Highlighted"
                  disabled={!!getValues(`${name}.${index}.lux_plus_tier`)}
                  control={
                    <Checkbox
                      {...field}
                      checked={field.value === 'highlighted'}
                      onChange={(event) => onInputChange(event, 'style', index)}
                      disabled={!!getValues(`${name}.${index}.lux_plus_tier`)}
                    />
                  }
                />
              </FormControl>
            )}
            name={`${name}.${index}.style`}
            control={control}
          />
        </Stack>
      </Grid>
      <Grid item xs={4} lg={4}>
        <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
          <Typography variant="body1"></Typography>
          <Controller
            render={({ field }) => (
              <FormControlLabel
                label="LuxPlus Exclusive"
                disabled={getValues(`${name}.${index}.inclusion.is_hidden`)}
                sx={{ position: 'relative' }}
                control={
                  <Checkbox
                    {...field}
                    checked={field.value === 'base'}
                    onChange={(event) => onLuxPlusExclusiveInputChange(event, 'lux_plus_tier', index, name)}
                  />
                }
              />
            )}
            name={`${name}.${index}.lux_plus_tier`}
            control={control}
          />
        </Stack>
      </Grid>
    </Grid>
  );
}
