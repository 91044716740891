import React, { ReactNode } from 'react';

import MUIProvider from '~/contexts/MUIProvider';

import { Box, SxProps } from '@mui/material';

import PageAreas from './PageAreas';
import { unsupportedComponentStyle } from './pageSupportingStyles';

const supportedAreas = Object.values(PageAreas);

const rootStyle: SxProps = {
  display: 'grid',
  gridTemplate: {
    xs: `
      "${PageAreas.header}"
      "${PageAreas.startSide}"
      "${PageAreas.body}"
      "${PageAreas.endSide}" / minmax(0, 1fr)
      `,
    md: `
      "${PageAreas.header} ${PageAreas.header} ${PageAreas.header}"
      "${PageAreas.startSide} ${PageAreas.body} ${PageAreas.endSide}" 1fr / auto minmax(0, 1fr) auto
    `,
  },
  [`> :not(${supportedAreas.map((a) => `.${a}`).join(', ')})`]: unsupportedComponentStyle(
    'UNSUPPORTED PAGE LAYOUT COMPONENT',
  ),
};

interface Props {
  children: ReactNode;
}

function PageLayout({ children }: Props) {
  return (
    <MUIProvider>
      <Box className="page-layout" sx={rootStyle}>
        {children}
      </Box>
    </MUIProvider>
  );
}

export default PageLayout;
