import React from 'react';

import { useSelector } from 'react-redux';

import { Tour } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, ButtonGroup, Typography } from '@mui/material';

import PermissionedComponent from '~/components/Common/PermissionedComponent';

import { OFFER_TYPE_TOUR_V2 } from '~/consts/offerTypes';
import { ROLE_ADMIN_USER, ROLE_EMPLOYEE_USER, ROLE_EXPERIENCES_COORDINATOR, ROLE_ICS_STORE_TEAM } from '~/consts/roles';

import { getDisputeBoxStyle, selectShowDisputeProcess } from '../utils/getDisputeProcess';

import Log from './OrderItem/Log';
import ResendEmail from './ResendEmail';
import TourFinePrint from './TourList/TourFinePrint';
import TourInclusions from './TourList/TourInclusions';
import TourOrderList from './TourList/TourOrderList';
import TourOverview from './TourList/TourOverview';
import TourTravellersContainer from './TourList/TourTravellersContainer';
import { buildOfferTypes } from './orderMetaUtils';

type Props = {
  item: App.OrderTourItem;
  order: App.Order;
  tourRoomInfo?: Record<string, unknown> & {
    sourceTourId: string;
    finePrint?: App.TourV2FinePrint;
    inclusions?: Array<App.TourV2Inclusion>;
  };
  customerEmailToAgentState: Record<string, unknown> & { isSending: boolean };
  emailToCustomerState: Record<string, unknown> & { isSending: boolean };
  resendCustomerEmail: (offerId: string, offerType?: string) => void;
  resendCustomerEmailToAgent: (offerId: string, offerType?: string) => void;
  vendorEmailToAgentState: Record<string, unknown> & { isSending: boolean };
  emailToVendorState: Record<string, unknown> & { isSending: boolean };
  resendVendorEmail: (offerId: string, offerType?: string) => void;
  resendVendorEmailToAgent: (offerId: string, offerType?: string) => void;
  showRefundModal: (value: unknown) => void;
};

export default function OrderDetailTour({
  item,
  order,
  tourRoomInfo,
  customerEmailToAgentState,
  emailToCustomerState,
  resendCustomerEmail,
  resendCustomerEmailToAgent,
  vendorEmailToAgentState,
  emailToVendorState,
  resendVendorEmail,
  resendVendorEmailToAgent,
  showRefundModal,
}: Props) {
  const tenant = useSelector((state: App.State) => state.tenant);
  const offerTypes = buildOfferTypes(order).join(', ');
  const showDisputeProcess = useSelector(selectShowDisputeProcess);

  return (
    <Accordion defaultExpanded sx={{ mt: 2 }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box display="flex" alignItems="center">
          <Tour sx={{ mr: 1 }} />
          <Typography variant="h6">Tours ({order.tour_items.length})</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box
          key={item.id}
          sx={{
            border: '1px solid',
            borderColor: 'grey.100',
            borderRadius: '8px',
            marginBottom: '20px',
          }}
        >
          <Box bgcolor="grey.50" p={2}>
            <Box display="grid" columnGap={2} gridTemplateColumns="auto 1fr" alignItems="center">
              <Typography color="black">ID:</Typography>
              <Typography>{item.id}</Typography>

              <Typography color="black">Deal ID:</Typography>
              <ButtonGroup variant="outlined" size="small">
                <PermissionedComponent
                  requiredRoles={[ROLE_ADMIN_USER, ROLE_EMPLOYEE_USER, ROLE_EXPERIENCES_COORDINATOR]}
                >
                  <Button
                    target="_blank"
                    rel="noreferrer"
                    href={`${window.configs.SALESFORCE_HOST}/${tourRoomInfo?.sourceTourId}`}
                  >
                    {tourRoomInfo?.sourceTourId}
                  </Button>

                  <Button
                    target="_blank"
                    rel="noreferrer"
                    href={`https://luxgroupau.sharepoint.com/_layouts/15/sharepoint.aspx?q=${tourRoomInfo?.sourceTourId}&v=/search/files`}
                  >
                    Sharepoint
                  </Button>
                </PermissionedComponent>
              </ButtonGroup>

              <Box sx={getDisputeBoxStyle(showDisputeProcess)}>
                <Typography color="black" display="inline-block">
                  Booking Number:
                </Typography>
                <Typography display="inline-block">{item.booking_number}</Typography>
              </Box>

              <Typography color="black">Offer Types</Typography>
              <Typography>{offerTypes}</Typography>
            </Box>

            <PermissionedComponent requiredRoles={[ROLE_ADMIN_USER, ROLE_EMPLOYEE_USER, ROLE_ICS_STORE_TEAM]}>
              {item.vendor_id && (
                <Box maxWidth="600px">
                  <ResendEmail
                    emailType="customer"
                    resendEmail={resendCustomerEmail}
                    isCustomerEmailDisabled={emailToCustomerState.isSending}
                    resendEmailToAgent={resendCustomerEmailToAgent}
                    isAgentEmailDisabled={customerEmailToAgentState.isSending}
                    offerType={OFFER_TYPE_TOUR_V2}
                  />

                  <ResendEmail
                    emailType="vendor"
                    resendEmail={resendVendorEmail}
                    isCustomerEmailDisabled={emailToVendorState.isSending}
                    resendEmailToAgent={resendVendorEmailToAgent}
                    isAgentEmailDisabled={vendorEmailToAgentState.isSending}
                    offerType={OFFER_TYPE_TOUR_V2}
                  />
                </Box>
              )}
            </PermissionedComponent>
          </Box>

          <Box p={2}>
            <TourOrderList
              order={order}
              item={item}
              showRefundModal={showRefundModal}
              tourInfo={tourRoomInfo}
              tenant={tenant}
            />
          </Box>

          <Box p={3}>
            <TourOverview item={item} />

            <TourFinePrint finePrint={tourRoomInfo?.finePrint} />

            <TourInclusions inclusions={tourRoomInfo?.inclusions} />

            <Log orderId={item.fk_order_id} itemId={item.id} />

            {item.product_type && <TourTravellersContainer orderId={order.id} productType={item.product_type} />}
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
