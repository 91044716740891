import { ThunkAction } from 'redux-thunk';
import { ValueOf } from 'type-fest';
import requestGetCustomScheduleDestinations from '~/queries/customerCommunication/requestGetCustomScheduleDestinations';
import { getRecordKeyFor } from '~/reducers/customerCommunicationReducer';

import { isRequestInitial } from '~/utils/requestUtils';

import CustomerCommunicationAction, { CustomerCommunicationActionTypes } from './customerCommunicationAction';

type CustomScheduleDestinationsParams = NonNullable<
  ValueOf<CustomerCommunication.CustomerCommunicationReduxState['customScheduleDestinationsRecords']>['params']
>;

const fetchCustomScheduleDestinations =
  (brandId: string): ThunkAction<void, App.State, unknown, CustomerCommunicationAction> =>
  async (dispatch, getState) => {
    const appState = getState();

    const params = [brandId] as CustomScheduleDestinationsParams;
    const key = getRecordKeyFor(...params);
    const existing = appState.customerCommunication.customScheduleDestinationsRecords[key];

    if (!existing || isRequestInitial(existing)) {
      dispatch({
        type: CustomerCommunicationActionTypes.SET_CUSTOM_SCHEDULE_DESTINATIONS_FETCHING,
        params,
      });
      try {
        const destinations = await requestGetCustomScheduleDestinations(...params);
        dispatch({
          type: CustomerCommunicationActionTypes.SET_CUSTOM_SCHEDULE_DESTINATIONS_FULFILLED,
          params,
          destinations,
        });
      } catch (error) {
        dispatch({
          type: CustomerCommunicationActionTypes.SET_CUSTOM_SCHEDULE_DESTINATIONS_REJECTED,
          params,
          error,
        });
      }
    }
  };

export default fetchCustomScheduleDestinations;
