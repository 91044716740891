import React, { useCallback, useContext } from 'react';
import { createContext, useState } from 'react';

import { noop } from 'lodash';

import useLocalStorage from '~/hooks/useLocalStorage';

type Theme = 'light' | 'dark';

export const FlightsLogsViewerContext = createContext<{ theme: Theme; setTheme: (theme: Theme) => void }>({
  theme: 'light',
  setTheme: noop,
});

const THEME_KEY = 'flight-log-viewer-theme';

interface Props {
  children: React.ReactNode;
}

export function FlightsLogsViewerProvider({ children }: Props) {
  const [value, setValue] = useLocalStorage(THEME_KEY, 'light');
  const [theme, setTheme] = useState<Theme>(value);

  const handleThemeChange = useCallback(
    (theme: 'light' | 'dark') => {
      setTheme(theme);
      setValue(theme);
    },
    [setTheme, setValue],
  );

  return (
    <FlightsLogsViewerContext.Provider value={{ theme, setTheme: handleThemeChange }}>
      {children}
    </FlightsLogsViewerContext.Provider>
  );
}

export function useFlightsLogViewer() {
  const context = useContext(FlightsLogsViewerContext);

  if (!context) {
    throw new Error('Your component need to be wrapped with FlightsLogsViewerProvider');
  }

  return context;
}
