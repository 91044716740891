import React, { forwardRef } from 'react';

import { Stack, TextField } from '@mui/material';

import { getCharacterCount, getWordCount } from '~/utils/stringUtils';

interface Props {
  introHeading: string;
  introCopy: string;
  introCta: string;
  introCtaUrl: string;
  onIntroHeadingChange: (value: string) => void;
  onIntroCopyChange: (value: string) => void;
  onIntroCtaChange: (value: string) => void;
  onIntroCtaUrlChange: (value: string) => void;
}

enum SCHEDULE_INTRO_SECTION_FORM_NAMES {
  INTRO_HEADING = 'intro_heading',
  INTRO_COPY = 'intro_copy',
  INTRO_CTA = 'intro_cta',
  INTRO_CTA_URL = 'intro_cta_url',
}

export function parseIntroSectionFormData(formData: FormData) {
  return {
    introHeading: String(formData.get(SCHEDULE_INTRO_SECTION_FORM_NAMES.INTRO_HEADING)),
    introCopy: String(formData.get(SCHEDULE_INTRO_SECTION_FORM_NAMES.INTRO_COPY)),
    introCta: String(formData.get(SCHEDULE_INTRO_SECTION_FORM_NAMES.INTRO_CTA)),
    introCtaUrl: String(formData.get(SCHEDULE_INTRO_SECTION_FORM_NAMES.INTRO_CTA_URL)),
  };
}

const ScheduleIntroSectionForm = forwardRef<HTMLFormElement, Props>(
  (
    {
      introHeading,
      introCopy,
      introCta,
      introCtaUrl,
      onIntroHeadingChange,
      onIntroCopyChange,
      onIntroCtaChange,
      onIntroCtaUrlChange,
    },
    ref,
  ) => {
    return (
      <Stack component="form" ref={ref} spacing={2}>
        <TextField
          fullWidth
          label="Intro Heading"
          name={SCHEDULE_INTRO_SECTION_FORM_NAMES.INTRO_HEADING}
          value={introHeading}
          onChange={(e) => onIntroHeadingChange(e.target.value)}
          required
          helperText={`Chars: ${getCharacterCount(introHeading)} Words: ${getWordCount(introHeading)}`}
        />
        <TextField
          fullWidth
          label="Intro Copy"
          name={SCHEDULE_INTRO_SECTION_FORM_NAMES.INTRO_COPY}
          value={introCopy}
          onChange={(e) => onIntroCopyChange(e.target.value)}
          required
          multiline
          rows={4}
          helperText={`Chars: ${getCharacterCount(introCopy)} Words: ${getWordCount(introCopy)}`}
        />
        <TextField
          fullWidth
          label="Intro CTA"
          name={SCHEDULE_INTRO_SECTION_FORM_NAMES.INTRO_CTA}
          value={introCta}
          onChange={(e) => onIntroCtaChange(e.target.value)}
          required
          helperText={`Chars: ${getCharacterCount(introCta)} Words: ${getWordCount(introCta)}`}
        />
        <TextField
          fullWidth
          label="Intro CTA URL"
          name={SCHEDULE_INTRO_SECTION_FORM_NAMES.INTRO_CTA_URL}
          value={introCtaUrl}
          onChange={(e) => onIntroCtaUrlChange(e.target.value)}
          required
        />
      </Stack>
    );
  },
);

ScheduleIntroSectionForm.displayName = 'ScheduleIntroSectionForm';
export default ScheduleIntroSectionForm;
