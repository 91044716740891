import React, { useCallback, useEffect, useMemo, useState } from 'react';

import cn from 'clsx';
import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';

import { Button, Container, Grid, Link, Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import { ROLE_ADMIN_USER, ROLE_EMPLOYEE_USER, ROLE_ICS_STORE_TEAM } from '~/consts/roles';
import { TripPlannerTripItemTypes } from '~/consts/tripPlanner';

import useCurrentTenant from '~/hooks/useCurrentTenant';

import { deleteTripItem, getTripDetails, unHideTrip } from '~/services/TripPlannerService';
import UsersService from '~/services/UsersService';
import { customerPortalHost } from '~/services/common';

import { toTitleCase } from '~/utils/toTitleCase';

import PageHeader from '../Common/Elements/PageHeader';
import PermissionedComponent from '../Common/PermissionedComponent';

import ConciergeNote from './components/ConciergeNote';
import ConfirmDialog from './components/ConfirmDialog';
import ExperiencesRecommendation from './components/Experiences/ExperiencesRecommendation';
import ExploreRecommendations from './components/Explore/ExploreRecommendations';
import { TripPlannerDataGrid } from './components/TripPlannerDataGrid';
import TripSpoofDialog from './components/TripSpoofDialog';

function TripDetailPage() {
  const [loading, setLoading] = useState<boolean>(true);
  const [tripDetails, setTripDetails] = useState<Partial<App.TripDetails>>({ items: [] });

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogConfig, setDialogConfig] = useState<{
    title: string;
    message: string;
    onConfirm: () => void;
  } | null>(null);

  const openDialog = (title: string, message: string, onConfirm: () => void) => {
    setDialogConfig({ title, message, onConfirm });
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
    setDialogConfig(null);
  };

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
  const { tripId } = useParams() as { tripId: string };

  // Spoofing information
  const [spoofUrl, setSpoofUrl] = useState<string>('');
  const [trip, setTrip] = useState<string>('');
  const { tenant: currentTenant } = useCurrentTenant();

  const { enqueueSnackbar } = useSnackbar();

  const fetchTripDetails = useCallback(async () => {
    setLoading(true);
    try {
      const tripDetailsResponse = await getTripDetails(tripId);

      if (tripDetailsResponse.status === 200) {
        setTripDetails(tripDetailsResponse.result);
      }
    } catch {
      enqueueSnackbar('Invalid Trip ID.', {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, [enqueueSnackbar, tripId]);

  useEffect(() => {
    fetchTripDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [openSpoofingModal, setOpenSpoofingModal] = useState(false);

  const handleSpoofTrip = async (createdBy: string, id: string, tripName: string) => {
    flushSpoofingState();
    try {
      const spoofedUrl = await UsersService.spoofUserTrip(createdBy, currentTenant, `/trip-planner/trip/${id}/summary`);
      navigator.clipboard.writeText(spoofedUrl);
      setSpoofUrl(spoofedUrl);
      setTrip(tripName);
      setOpenSpoofingModal(true); // Open the modal after setting the spoof URL
    } catch (error) {
      console.error('Error spoofing trip:', error);
    }
  };

  const flushSpoofingState = () => {
    setSpoofUrl('');
    setTrip('');
    setOpenSpoofingModal(false); // Close the modal when clearing the spoofing state
  };

  const offerLink = (params: GridRenderCellParams) => {
    if (!params || !params.row || !params.row.type) {
      return null;
    }

    let link: string;

    if (params.row.type === TripPlannerTripItemTypes.ACCOMMODATION) {
      link = `${customerPortalHost({ value: 'LUX' })}/offer/${params.row.code}`;
    } else if (params.row.type === TripPlannerTripItemTypes.EXPERIENCE) {
      link = `${customerPortalHost({ value: 'LUX' })}/experience/${params.row.code}`;
    }

    if (link) {
      return (
        <Link href={link} color="primary" rel="noreferrer" target="_blank">
          Offer link
        </Link>
      );
    }

    return null;
  };

  const columns: Array<GridColDef> = [
    { field: 'sortableName', headerName: 'Name', width: 200 },
    {
      field: 'type',
      headerName: 'Type',
      width: 150,
      renderCell: (params: GridRenderCellParams) => toTitleCase(params.row.type),
    },
    {
      field: 'date',
      headerName: 'Date',
      width: 250,
      renderCell: (params: GridRenderCellParams) => {
        if (!params.row.startDate) {
          return null;
        }
        const startDate = new Date(params.row.startDate).toLocaleDateString();
        const endDate = new Date(params.row.endDate).toLocaleDateString();
        return (
          <div>
            {startDate} &mdash; {endDate}
          </div>
        );
      },
    },
    {
      field: 'isSuggested',
      headerName: 'Suggested',
      width: 150,
      renderCell: (params: GridRenderCellParams) => (params.row.isSuggested ? 'Yes' : 'No'),
    },
    {
      field: 'url',
      headerName: 'Offer',
      width: 150,
      renderCell: (params: GridRenderCellParams) => offerLink(params),
    },
    {
      field: 'orderId',
      headerName: 'Order Id',
      width: 150,
      renderCell: (params: GridRenderCellParams) => (
        <Link color="primary" rel="noreferrer" href={`/purchases/${tripDetails.sourceOrderId}`} underline="hover">
          {params.row.orderId}
        </Link>
      ),
      display: 'flex',
    },
    {
      headerName: '',
      field: 'remove',
      width: 150,
      renderCell: (params: GridRenderCellParams) => (
        <Button variant="contained" color="error" onClick={() => handleDelete(params.row.id)}>
          Remove
        </Button>
      ),
      display: 'flex',
    },
  ];

  const handleUnhide = () => {
    openDialog('Confirm Unhide', 'Are you sure you want to unhide this trip?', async () => {
      await unHideTrip(tripId);
      await fetchTripDetails();
      closeDialog();
      enqueueSnackbar('Successfully unhidden trip', {
        variant: 'success',
      });
    });
  };

  const handleDelete = (tripItemId: string) => {
    openDialog(
      'Confirm Delete',
      'Are you sure you want to delete this trip item? This action cannot be undone.',
      async () => {
        await deleteTripItem(tripId, tripItemId);
        await fetchTripDetails();
        closeDialog();
        enqueueSnackbar('Successfully deleted trip', {
          variant: 'success',
        });
      },
    );
  };

  const conciergeNoteItem = useMemo(
    () => tripDetails.items?.find((i) => i.isSuggested && i.type === TripPlannerTripItemTypes.NOTE),
    [tripDetails.items],
  );

  const conciergeAccommodationItem = useMemo(
    () => tripDetails.items?.find((i) => i.type === TripPlannerTripItemTypes.ACCOMMODATION),
    [tripDetails.items],
  );

  return (
    <Container maxWidth="lg">
      <Helmet>
        <title>Trip details</title>
      </Helmet>
      <PageHeader title={`Trip details - ${tripDetails.name}`} />
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="body1">
            Trip ID: <b>{tripDetails.id}</b>
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="body1">
            Created At: <b>{tripDetails.createdAt}</b>
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="body1">
            Updated At: <b>{tripDetails.updatedAt}</b>
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="body1">
            Hidden: <b>{tripDetails.isHidden ? 'Yes' : 'No'}</b>
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="body1">
            Transfer included: <b>{tripDetails.transferInclusion}</b>
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="body1">
            User ID:{' '}
            <Link
              color="primary"
              rel="noreferrer"
              href={`/users/${tripDetails.createdBy}`}
              underline="hover"
              fontWeight="bold"
            >
              {tripDetails.createdBy}
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          {`Order ID: `}
          <Link
            color="primary"
            rel="noreferrer"
            href={`/purchases/${tripDetails.sourceOrderId}`}
            underline="hover"
            fontWeight="bold"
          >
            {tripDetails.sourceOrderId}
          </Link>
        </Grid>
        <Grid item xs={12} md={6}>
          {`User purchases: `}
          <Link
            color="primary"
            rel="noreferrer"
            href={`/purchases?customer_id=${tripDetails.createdBy}`}
            underline="hover"
            fontWeight="bold"
          >
            Purchases
          </Link>
        </Grid>
      </Grid>

      {tripDetails.isHidden && (
        <Button variant="contained" color="primary" onClick={handleUnhide}>
          Unhide trip
        </Button>
      )}

      <Typography variant="h5" mt={6}>
        Trip items
      </Typography>
      <TripPlannerDataGrid
        rows={tripDetails.items}
        columns={columns}
        rowCount={tripDetails.items.length}
        loading={loading}
        localeText={{
          noRowsLabel: 'No items',
        }}
        hideFooterPagination
        getRowHeight={() => 'auto'}
        className={cn({ 'no-trip-items': tripDetails.items.length === 0 })}
      />
      <PermissionedComponent requiredRoles={[ROLE_ADMIN_USER, ROLE_EMPLOYEE_USER, ROLE_ICS_STORE_TEAM]}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSpoofTrip(tripDetails.createdBy, tripDetails.id, tripDetails.name)}
        >
          Spoof
        </Button>
      </PermissionedComponent>

      {dialogConfig && (
        <ConfirmDialog
          open={dialogOpen}
          title={dialogConfig.title}
          message={dialogConfig.message}
          onConfirm={dialogConfig.onConfirm}
          onClose={closeDialog}
        />
      )}

      <PermissionedComponent requiredRoles={[ROLE_ADMIN_USER, ROLE_EMPLOYEE_USER, ROLE_ICS_STORE_TEAM]}>
        <TripSpoofDialog
          openModal={openSpoofingModal}
          flushSpoofingState={flushSpoofingState}
          spoofUrl={spoofUrl}
          trip={trip}
        />
      </PermissionedComponent>

      {tripDetails.isConciergeTrip && (
        <>
          <Typography variant="h4" mt={6} gutterBottom>
            Concierge management
          </Typography>
          {conciergeNoteItem && (
            <ConciergeNote tripItem={conciergeNoteItem} tripId={tripDetails.id} onSuccess={fetchTripDetails} />
          )}
          {conciergeAccommodationItem && (
            <>
              <Typography variant="h5" mt={6}>
                Experience recommendations
              </Typography>
              <ExperiencesRecommendation
                tripId={tripId}
                onAddSuccess={fetchTripDetails}
                tripItems={tripDetails.items}
              />
              <Typography variant="h5" mt={2}>
                Explore recommendations
              </Typography>
              <ExploreRecommendations
                tripId={tripId}
                accommodation={conciergeAccommodationItem}
                tripItems={tripDetails.items}
                onAddSuccess={fetchTripDetails}
              />
            </>
          )}
        </>
      )}
    </Container>
  );
}

export default TripDetailPage;
