import React, { ReactNode } from 'react';

import { Box, SxProps } from '@mui/material';

interface Props {
  children: ReactNode;
  sx?: SxProps;
}

function AppSidebar({ children, sx }: Props) {
  return <Box sx={{ width: { md: 280 }, py: { xs: 2, md: 1 }, ...sx }}>{children}</Box>;
}

export default AppSidebar;
