import React from 'react';

import { Tooltip } from '@mui/material';

import { SingleOrderPayments } from './SingleOrderPayments';

interface Props {
  order: App.Order;
  checkIn?: string;
  bookingEmail?: string;
  bookingPhone?: string;
  locationInfo?: string;
  guestFirstName?: string;
  guestLastName?: string;
  countryName?: string;
  pendingBNBL?: boolean;
}

function SingleHotelDetails({
  order,
  checkIn,
  bookingEmail = '',
  bookingPhone = '',
  locationInfo = '',
  guestFirstName = '',
  guestLastName = '',
  countryName = '',
  pendingBNBL = false,
}: Props) {
  const formattedTotal = order.total.toLocaleString(undefined, { maximumFractionDigits: 0 });

  let leadDays = 999;
  if (checkIn) {
    leadDays = Math.floor(
      (new Date(checkIn).setHours(0, 0, 0, 0) - new Date(order.created_at).setHours(0, 0, 0, 0)) /
        (1000 * 60 * 60 * 24),
    );
  }
  if (pendingBNBL) {
    return (
      <div>
        <a target="_blank" href={`/purchases/${order.id}`} rel="noreferrer">
          BNBL order
        </a>{' '}
        for {order.currency_code}
        {formattedTotal}{' '}
        <Tooltip title="Fraudsters very rarely buy a BNBL.">
          <span>🟢</span>
        </Tooltip>
        <br />
        <em>{locationInfo}</em>
        <SingleOrderPayments order={order} />
      </div>
    );
  }

  const guestName = guestFirstName + ' ' + guestLastName;
  return (
    <div>
      {order.status}{' '}
      <a target="_blank" href={`/purchases/${order.id}`} rel="noreferrer">
        hotel order
      </a>{' '}
      for {order.currency_code}
      {formattedTotal}
      <br />
      <strong>Account:</strong> {order.customer_full_name} | {order.customer_email} | {order.customer_phone_prefix}
      {order.customer_phone}
      <br />
      <strong>Booking:</strong> {guestName} | {bookingEmail} | {bookingPhone}{' '}
      {order.customer_surname !== guestLastName && (
        <Tooltip title="The room is booked under a different surname. Maybe it's a friend? Or maybe it's fraud.">
          <span>🔴</span>
        </Tooltip>
      )}
      {order.customer_surname == guestLastName && (
        <Tooltip title="The room is booked under the same surname as the account. This is less likely to be fraud. (unless the fraudster recently changed the account name)">
          <span>🟢</span>
        </Tooltip>
      )}
      {order.customer_email !== bookingEmail && (
        <Tooltip title="The booking email is different from the account email.  Maybe it's a friend's email? Or maybe it's fraud.">
          <span>🟠</span>
        </Tooltip>
      )}
      {order.customer_email == bookingEmail && (
        <Tooltip title="The room is booked under the same email as the account. This is less likely to be fraud. (unless the fraudster recently changed the account email)">
          <span>🟢</span>
        </Tooltip>
      )}
      <br />
      booked {leadDays}d in advance{' '}
      {leadDays <= 10 && (
        <Tooltip title="Fraudsters check in quickly, before we can catch them! Booking under 10 days in advance is a risk. Under 3 days is a high risk. But, genuine customers can book last minute, too!">
          <span>{leadDays <= 3 ? '🔴' : '🟠'}</span>
        </Tooltip>
      )}
      {leadDays > 10 && (
        <Tooltip title="Fraudsters check in quickly, before we can catch them! Usually they book less than 10 days in advance. Longer lead times are more likely to be genuine customers.">
          <span>🟢</span>
        </Tooltip>
      )}
      <br />
      <em>{locationInfo}</em>
      {countryName === 'Australia' && (
        <Tooltip title="It's rare we see fraudsters booking hotels in Australia.">
          <span>🟢</span>
        </Tooltip>
      )}
      <SingleOrderPayments order={order} />
    </div>
  );
}

export { SingleHotelDetails };
