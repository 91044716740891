import React, { useMemo, useState } from 'react';

import currencyFormatter from 'currency-formatter';
import { useSelector } from 'react-redux';

import { Discount } from '@mui/icons-material';
import { Alert, Box, Button, Chip, Link, Stack, Typography } from '@mui/material';

import PermissionedComponent from '~/components/Common/PermissionedComponent';
import { getDisputeBoxStyle, selectShowDisputeProcess } from '~/components/Purchases/utils/getDisputeProcess';

import { ROLE_ADMIN_USER, ROLE_EMPLOYEE_USER, ROLE_EXPERIENCES_COORDINATOR, ROLE_ICS_STORE_TEAM } from '~/consts/roles';
import TENANTS from '~/consts/tenant';

import useCurrentUser from '~/hooks/useCurrentUser';

import { formatDateWithClock } from '~/services/TimeService';
import { customerPortalHost } from '~/services/common';

import { isAirportTransfer } from '~/utils/experienceUtils';

import * as CE from '../../../../consts/experiences';
import * as ES from '../../../../services/ExperiencesService';
import * as LE from '../../../Experiences/components';
import Log from '../OrderItem/Log';
import PriceSummaryField from '../OrderItem/PriceSummaryField';

import ExperienceAirportTransferInformation from './ExperienceTransferInformation';

interface ExperienceItemProps {
  count?: number;
  hasAllowedRefund: boolean;
  experienceItem: App.ExperienceItem;
  experienceOffer: ES.ExperienceOffer | null;
  showRefundModal: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, experienceItem: App.ExperienceItem) => void;
  orderBrand: string;
  experienceBookingDetail?: ES.BookingDetails;
  parentAccommodationBookingNumber?: string;
}

const isProduction = process.env.NODE_ENV === 'production';

function ExperienceItem({
  count = 1,
  experienceItem,
  experienceOffer,
  showRefundModal,
  hasAllowedRefund,
  orderBrand,
  experienceBookingDetail,
  parentAccommodationBookingNumber,
}: ExperienceItemProps) {
  const [fetchingBookingDetails, setFetchingBookingDetails] = useState(false);
  const [error, setError] = useState(null);

  const { user } = useCurrentUser();

  const isVendorLinkRestricted = useMemo(() => {
    // If no user, assume restricted
    if (!user) {
      return true;
    }

    return user?.roles.includes(ROLE_ICS_STORE_TEAM);
  }, [user]);

  const providerLabels = CE.PROVIDER_OPTIONS.reduce((acc, { name, value }) => {
    acc[value] = name;
    return acc;
  }, {});

  const disabled = experienceItem.status?.toLocaleUpperCase() === 'CANCELLED';

  const status = CE.getStatusByPurchase(experienceItem.status);

  const experiencePage = () =>
    `${customerPortalHost(tenant)}/experience/${experienceOffer?.slug}/${experienceOffer?.id}`;

  const salesforceLink = (salesforceOfferId) =>
    `${window.configs.SALESFORCE_HOST}/lightning/r/Opportunity/${salesforceOfferId}/view`;

  const tenant = Object.values(TENANTS).find((tenant) => tenant.brand === orderBrand);

  const viewVoucher = async () => {
    if (experienceOffer?.provider) {
      return window.open(
        `${customerPortalHost(tenant)}/account/my-escapes/experiences/${experienceItem.id}/voucher`,
        '_blank',
      );
    }

    // Musement vouchers are only visible in production
    if (!isProduction) {
      return;
    }

    try {
      if (error) {
        setError(null);
      }
      setFetchingBookingDetails(true);
      const { result } = await ES.getBookingDetails(experienceItem.id);
      return window.open(result[0]?.voucherUrl, '_blank');
    } catch (error) {
      setError(error.message);
    } finally {
      setFetchingBookingDetails(false);
    }
  };

  const offerSalesforceId =
    experienceOffer?.idSalesforceExternal || experienceBookingDetail?.redemptionLocation?.dealIdSalesforceExternal;

  const isAirportTransferItem = isAirportTransfer(experienceItem);

  const showDisputeProcess = useSelector(selectShowDisputeProcess);

  return (
    <Stack direction="column" gap={1}>
      <Stack direction="row" spacing={2}>
        <Box>
          <Typography variant="h4" color="grey.800" fontWeight={500}>
            {count}
          </Typography>
        </Box>

        <Box flexGrow={1} sx={getDisputeBoxStyle(showDisputeProcess)}>
          <Stack>
            {experienceOffer && (
              <Stack direction="row" gap={1} alignItems="center">
                <Link
                  variant="h5"
                  href={experiencePage()}
                  fontWeight={500}
                  target="_blank"
                  rel="noreferrer"
                  underline="hover"
                >
                  {experienceOffer.title}
                </Link>
                {parentAccommodationBookingNumber && (
                  <Chip label={`Complimentary - ${parentAccommodationBookingNumber}`} color="primary" size="small" />
                )}
              </Stack>
            )}
            {!experienceOffer && <Typography>Loading...</Typography>}

            <Stack direction="row" gap={1}>
              <Typography fontWeight="bold">Fare Type:</Typography>
              <Typography>{experienceItem.ticket?.fareType}</Typography>
            </Stack>

            <Stack direction="row" gap={1}>
              <Typography fontWeight="bold">Date:</Typography>
              <Typography>
                {experienceItem.ticket?.date ? formatDateWithClock(experienceItem.ticket.date) : '-'}
              </Typography>
            </Stack>

            <Stack direction="row" gap={1}>
              <Typography fontWeight="bold">Free Cancellation:</Typography>
              <Typography>{experienceOffer?.cancellationInfo.isFree ? 'YES' : 'NO'}</Typography>
            </Stack>

            <Stack direction="row" gap={1}>
              <Typography fontWeight="bold">Booking Number:</Typography>
              <Typography>{experienceItem.booking_number}</Typography>
            </Stack>

            {experienceItem.provider && (
              <Stack direction="row" gap={1}>
                <Typography fontWeight="bold">Vendor Id:</Typography>
                <Typography>{providerLabels[experienceItem.provider] ?? experienceItem.provider}</Typography>
              </Stack>
            )}

            {experienceBookingDetail?.providerBookingNumber && (
              <Stack direction="row" gap={1}>
                <Typography fontWeight="bold">Provider Booking Id:</Typography>
                <Typography> {experienceBookingDetail?.providerBookingNumber}</Typography>
              </Stack>
            )}

            {experienceItem.provider_order_id && (
              <Stack direction="row" gap={1}>
                <Typography fontWeight="bold">Provider Order Id:</Typography>
                <Typography> {experienceItem.provider_order_id}</Typography>
              </Stack>
            )}

            {offerSalesforceId && (
              <Stack direction="row" gap={1}>
                <Typography fontWeight="bold">Salesforce Offer Id:</Typography>
                {isVendorLinkRestricted && <Typography>{offerSalesforceId}</Typography>}
                {!isVendorLinkRestricted && <Link href={salesforceLink(offerSalesforceId)}>{offerSalesforceId}</Link>}
              </Stack>
            )}
          </Stack>
        </Box>

        <Box>
          <LE.FlexWrapper direction="column">
            <LE.Badge mb="5px" py="8px" px="16px" lineHeight="10px" title={status.title} background={status.color} />
            {hasAllowedRefund && (
              <Button
                disabled={disabled}
                className="T-issue-refund"
                onClick={(e) => showRefundModal(e, experienceItem)}
              >
                Issue refund
              </Button>
            )}
            {experienceItem.promo_code_discount_amount > 0 && (
              <Stack direction="row" gap={1}>
                <Discount fontSize="small" color="secondary" />
                <PriceSummaryField
                  label="Promo Code Discount Amount"
                  value={currencyFormatter.format(experienceItem.promo_code_discount_amount, {
                    code: experienceItem.currency,
                  })}
                  priceType="discount"
                />
              </Stack>
            )}
            <Stack direction="row" gap={1}>
              <Typography fontWeight="bold">Total Sell Price:</Typography>
              {experienceItem.total && (
                <Typography>
                  {`${experienceItem.currency} ${currencyFormatter.format(experienceItem.total, {
                    code: experienceItem.currency,
                  })}`}
                </Typography>
              )}
              {experienceItem.total === undefined && <Typography>N/A</Typography>}
            </Stack>
            {experienceBookingDetail?.costPriceAmount && (
              <Stack direction="row" gap={1}>
                <Typography fontWeight="bold">Total Cost Price:</Typography>
                <Typography>
                  {`${experienceBookingDetail?.costPriceCurrency} ${currencyFormatter.format(
                    experienceBookingDetail?.costPriceAmount,
                    { code: experienceBookingDetail?.costPriceCurrency },
                  )}`}
                </Typography>
              </Stack>
            )}
          </LE.FlexWrapper>
        </Box>

        <Stack direction="column" spacing={1} alignItems="end">
          <PermissionedComponent requiredRoles={[ROLE_ADMIN_USER, ROLE_EMPLOYEE_USER, ROLE_EXPERIENCES_COORDINATOR]}>
            {offerSalesforceId && (
              <Box>
                <Button variant="text" size="small" target="_blank" href={`/finance/offers/${offerSalesforceId}`}>
                  Vendor payments
                </Button>
              </Box>
            )}
          </PermissionedComponent>

          <Box>
            <Button variant="text" size="small" onClick={viewVoucher} disabled={fetchingBookingDetails}>
              View Voucher
              {fetchingBookingDetails && <LE.Spinner size={26} />}
            </Button>
          </Box>

          {error && <Alert severity="error">{error}</Alert>}
        </Stack>
      </Stack>

      <Stack direction="column" gap={1}>
        {isAirportTransferItem && <ExperienceAirportTransferInformation experienceItem={experienceItem} />}
        {experienceItem.fk_order_id && <Log itemId={experienceItem.id} orderId={experienceItem.fk_order_id} />}
      </Stack>
    </Stack>
  );
}

export default ExperienceItem;
