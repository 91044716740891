import React, { ComponentProps, FormEventHandler, useCallback, useEffect, useRef, useState } from 'react';

import { useSnackbar } from 'notistack';
import requestDeleteHeroPlannerSchedule from '~/queries/customerCommunication/requestDeleteHeroPlannerSchedule';

import {
  Alert,
  AlertTitle,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
} from '@mui/material';

import { RequestStatus } from '~/consts/requestConstants';

import { isRequestFulfilled, isRequestRejected } from '~/utils/requestUtils';

interface Props {
  scheduleId: string;
  isCustomSchedule?: boolean;
  open: ComponentProps<typeof Dialog>['open'];
  onClose: () => void;
  onDelete?: (scheduleId: string) => void;
}

function ScheduleDeletionDialog({ scheduleId, isCustomSchedule = false, open, onClose, onDelete }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const formRef = useRef<HTMLFormElement>(null);

  const [deletionReq, setDeletionReq] = useState<Utils.RequestState<undefined, string, string>>({
    status: RequestStatus.INITIAL,
  });
  const request = useCallback(
    async (scheduleIdToDelete: string) => {
      setDeletionReq({
        status: RequestStatus.PENDING,
        params: scheduleIdToDelete,
      });

      try {
        await requestDeleteHeroPlannerSchedule(scheduleIdToDelete, isCustomSchedule);
        setDeletionReq({
          status: RequestStatus.FULFILLED,
          params: scheduleIdToDelete,
          result: undefined,
        });
      } catch (error) {
        setDeletionReq({
          status: RequestStatus.REJECTED,
          params: scheduleIdToDelete,
          error,
        });
      }
    },
    [isCustomSchedule],
  );

  const handleSubmission = useCallback<FormEventHandler<HTMLFormElement>>(
    (event) => {
      event.preventDefault();
      const formData = new FormData(event.currentTarget);

      request(String(formData.get('schedule-id')));
    },
    [request],
  );

  useEffect(() => {
    if (isRequestFulfilled(deletionReq)) {
      enqueueSnackbar(`Schedule ${deletionReq.params} was deleted`, { variant: 'info' });
      onDelete?.(scheduleId);
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletionReq.status]);

  useEffect(() => {
    if (open) {
      formRef.current?.reset();
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      PaperProps={{
        ref: formRef,
        component: 'form',
        onSubmit: handleSubmission,
      }}
    >
      <DialogContent>
        <input type="hidden" name="schedule-id" value={scheduleId} key={scheduleId} />
        <FormControlLabel
          control={<Checkbox required />}
          label="I acknowledge that this is an irreversible action and I want to delete this send schedule."
        />
        {isRequestRejected(deletionReq) && (
          <Alert severity="error">
            <AlertTitle>Could not delete schedule.</AlertTitle>
            {deletionReq.error}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="secondary" onClick={onClose}>
          Dismiss
        </Button>
        <Button variant="contained" color="error" type="submit">
          DELETE SCHEDULE
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ScheduleDeletionDialog;
