import React, { KeyboardEvent, useCallback, useEffect, useMemo, useState } from 'react';

import { useLocation } from 'react-router';

import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import { Alert, Button, Stack, TextField, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { DataGrid, GridColDef, GridPaginationModel } from '@mui/x-data-grid';

import { Order } from '@luxuryescapes/contract-svc-order';

import ReportingExportDialog from '~/components/LeBusinessTraveller/components/ReportingExportDialog';
import {
  customerDataFormatter,
  internalInformationFormatter,
  orderInformationFormatter,
  orderLinkFormatter,
  packageInformationFormatter,
} from '~/components/Purchases/Home/formatters';

import useToggleState from '~/hooks/useToggleState';

import { getPurchases } from '~/services/OrdersService';

('../../../components/Purchase/Home/formatters');

export const columns: GridColDef[] = [
  {
    field: 'fk_customer_id',
    headerName: 'Customer',
    sortable: false,
    width: 250,
    renderCell: customerDataFormatter,
    display: 'flex',
  },
  {
    field: 'order_information',
    headerName: 'Order information',
    sortable: false,
    flex: 3,
    renderCell: orderInformationFormatter,
    display: 'flex',
  },
  {
    field: 'package_information',
    headerName: 'Package Information',
    sortable: false,
    flex: 2,
    renderCell: packageInformationFormatter,
    display: 'flex',
  },
  {
    field: 'internal_information',
    headerName: 'Internal Information',
    sortable: false,
    flex: 3,
    renderCell: internalInformationFormatter,
    display: 'flex',
  },
  { field: 'actions', headerName: 'Details', sortable: false, renderCell: orderLinkFormatter, display: 'flex' },
];

const emptyFilter = {
  customer_details: '',
  booking_numbers: '',
};

interface FilterResponse {
  orders: Order.Order[];
  count: number;
  error: boolean;
}

export default function OrdersTab() {
  const [response, setResponse] = useState<FilterResponse>({ orders: [], count: 0, error: false });
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: 10,
    page: 0,
  });
  const [filters, setFilters] = useState<Record<string, string>>(emptyFilter);
  const { isToggled: isExportDialogOpen, toggleOn: openExportDialog, toggleOff: closeExportDialog } = useToggleState();

  const location = useLocation();
  // URL path is of form: /lebusiness/:businessId/orders
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const businessId = useMemo(() => location.pathname.split('/')[2], []);

  const getNonEmptyFilterValue = useCallback((values: string[]) => {
    return values.find((value) => value);
  }, []);

  const getKeyForNonEmptyFilterValue = useCallback(
    (keys: string[]) => {
      return keys.find((key) => filters[key]);
    },
    [filters],
  );

  const fetchOrders = useCallback(async () => {
    try {
      // there can only be one value set in state at any time
      const filterValue = getNonEmptyFilterValue(Object.values(filters));
      let filter = null;
      if (filterValue) {
        filter = getKeyForNonEmptyFilterValue(Object.keys(filters));
      }
      const result = await getPurchases({
        page: paginationModel.page + 1, // datagrid pagination 0-indexed but svc-order is 1-indexed
        per_page: paginationModel.pageSize,
        brand: 'lebusinesstraveller',
        filterBy: filter,
        filterValue: filterValue,
        business_id: businessId,
      });
      setResponse({ orders: result.result, count: result.total, error: false });
    } catch (e) {
      setResponse({ orders: [], count: 0, error: true });
      console.error(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel.page, paginationModel.pageSize, filters]);

  // this fires on first render as well
  useEffect(() => {
    fetchOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationModel]);

  const clearAllFilters = useCallback(async () => {
    setFilters(emptyFilter);
  }, []);

  const handleEnterPress = useCallback(
    async (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter') {
        await fetchOrders();
      }
    },
    [fetchOrders],
  );

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
      const field = event.currentTarget.name;
      let value = event.currentTarget.value;

      if (field === 'booking_numbers') {
        value = value.toUpperCase();
      }

      setFilters({
        ...emptyFilter,
        [field]: value,
      });
    },
    [],
  );

  return (
    <Stack gap={2}>
      {response.error && <Alert severity="error">Error fetching bookings</Alert>}
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h5">Filter Bookings</Typography>
        <Button color="primary" onClick={openExportDialog} startIcon={<DownloadOutlinedIcon />} variant="contained">
          Export
        </Button>
        <ReportingExportDialog isOpen={isExportDialogOpen} onClose={closeExportDialog} />
      </Stack>
      <Stack>
        <Grid2 container spacing={3}>
          <Grid2 xs={3}>
            <TextField
              name="customer_details"
              fullWidth
              label="Customer email / name"
              onChange={handleInputChange}
              onKeyDown={handleEnterPress}
              placeholder="Enter customer email or name"
              type="text"
              value={filters.customer_details}
              variant="outlined"
            />
          </Grid2>
          <Grid2 xs={3}>
            <TextField
              fullWidth
              label="Booking Number"
              name="booking_numbers"
              onChange={handleInputChange}
              onKeyDown={handleEnterPress}
              type="text"
              value={filters.booking_numbers}
              variant="outlined"
            />
          </Grid2>

          <Grid2 xs={6} />

          <Grid2 xs="auto">
            <Button onClick={clearAllFilters} variant="text">
              Clear all filters
            </Button>
          </Grid2>
          <Grid2 xs="auto">
            <Button onClick={fetchOrders} variant="contained">
              FILTER
            </Button>
          </Grid2>
        </Grid2>
      </Stack>

      <DataGrid
        autoHeight
        classes={{
          root: 'T-packages-container',
          virtualScrollerContent: 'T-packages-table',
        }}
        columns={columns}
        disableColumnFilter
        disableColumnMenu
        disableRowSelectionOnClick
        getRowHeight={() => 'auto'}
        onPaginationModelChange={setPaginationModel}
        pagination
        paginationMode="server"
        paginationModel={paginationModel}
        pageSizeOptions={[10, 25, 50, 100]}
        rows={response.orders}
        rowCount={response.count}
      />
    </Stack>
  );
}
