import React, { ComponentProps, useCallback, useEffect, useState } from 'react';

import { Dayjs } from 'dayjs';
import { Link, useHistory } from 'react-router-dom';

import { Button, Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { DateRange, DateRangePicker } from '@mui/x-date-pickers-pro';

import { useAppSelector } from '~/hooks/store';
import useQuery from '~/hooks/useQuery';

import { getDayJs } from '~/services/TimeService';

import SchedulesCountryGroupsTabs from './ScheduleFilters/SchedulesCountryGroupsTabs';
import SchedulesCountryStateTabs from './ScheduleFilters/SchedulesCountryStateTabs';

export enum SCHEDULE_SEARCH_PARAMS {
  FROM_DATE = 'from-date',
  TO_DATE = 'to-date',
  VIEW = 'view',
  COUNTRY_GROUP = 'country-group',
  COUNTRY = 'country',
  COUNTRY_STATE = 'country-state',
}

const SCHEDULE_SEARCH_PARAMS_STORAGE_KEY = 'customer-communications-schedules-filters';

function isCountryGroupsFilterSupportedForBrand(brand: string) {
  return brand === 'luxuryescapes' || brand === 'treatmetravel';
}

const VIEWS = [
  { id: 'email', name: 'Email' },
  { id: 'push', name: 'Push' },
  { id: 'deals', name: 'Deals' },
];

const restoredSearchParams = new URLSearchParams(localStorage.getItem(SCHEDULE_SEARCH_PARAMS_STORAGE_KEY));

function restoreSearchParamValueFor(key: SCHEDULE_SEARCH_PARAMS, searchParams: URLSearchParams) {
  return restoredSearchParams.get(key) || searchParams.get(key);
}

function SchedulesFilters() {
  const brand = useAppSelector((state) => state.tenant.brand);
  const history = useHistory();
  const searchParams = useQuery();

  // COUNTRY GROUP
  const [countryGroupId, setCountryGroupId] = useState<string | undefined>(
    restoreSearchParamValueFor(SCHEDULE_SEARCH_PARAMS.COUNTRY_GROUP, searchParams),
  );
  // COUNTRY
  const [countryId, setCountryId] = useState<string | undefined>(
    restoreSearchParamValueFor(SCHEDULE_SEARCH_PARAMS.COUNTRY, searchParams),
  );
  // COUNTRY STATE
  const [countryStateId, setCountryStateId] = useState<string | undefined>(
    restoreSearchParamValueFor(SCHEDULE_SEARCH_PARAMS.COUNTRY_STATE, searchParams),
  );
  // VIEW
  const [view, setView] = useState<string>(
    restoreSearchParamValueFor(SCHEDULE_SEARCH_PARAMS.VIEW, searchParams) ?? 'email',
  );
  const handleViewChange = useCallback<ComponentProps<typeof ToggleButtonGroup>['onChange']>((event, value) => {
    if (value) setView(value);
  }, []);
  // DATE RANGE
  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>(() => {
    const fromDate = restoreSearchParamValueFor(SCHEDULE_SEARCH_PARAMS.FROM_DATE, searchParams);
    const toDate = restoreSearchParamValueFor(SCHEDULE_SEARCH_PARAMS.TO_DATE, searchParams);

    const start = getDayJs(fromDate).tz(window.configs.DEFAULT_TIMEZONE);
    const end = (toDate ? getDayJs(toDate) : getDayJs().add(1, 'week')).tz(window.configs.DEFAULT_TIMEZONE);
    return [start, end];
  });
  const handleDateRangeChange = useCallback(([start, end]: DateRange<Dayjs>) => {
    if (start?.isValid() && end?.isValid()) setDateRange([start, end]);
  }, []);
  const [fromDate, toDate] = dateRange;

  // SET BRAND PARAMS
  useEffect(() => {
    switch (brand) {
      case 'cudotravel': {
        setCountryGroupId('australia');
        setCountryId('AU');
        break;
      }
      case 'dealstravel': {
        setCountryGroupId('australia');
        setCountryId('AU');
        break;
      }
      case 'scoopontravel': {
        setCountryGroupId('australia');
        setCountryId('AU');
        break;
      }
      default: {
        setCountryGroupId(undefined);
        setCountryId(undefined);
        setCountryStateId(undefined);
      }
    }
  }, [brand]);

  // SYNC URL SEARCH PARAMS
  useEffect(() => {
    if (fromDate && history && countryGroupId && toDate && view) {
      const newSearchParams = new URLSearchParams();

      if (countryGroupId) newSearchParams.set(SCHEDULE_SEARCH_PARAMS.COUNTRY_GROUP, countryGroupId);
      if (countryId) newSearchParams.set(SCHEDULE_SEARCH_PARAMS.COUNTRY, countryId);
      if (countryStateId) newSearchParams.set(SCHEDULE_SEARCH_PARAMS.COUNTRY_STATE, countryStateId);
      if (view) newSearchParams.set(SCHEDULE_SEARCH_PARAMS.VIEW, view);
      if (fromDate.isValid()) newSearchParams.set(SCHEDULE_SEARCH_PARAMS.FROM_DATE, fromDate.format('YYYY-MM-DD'));
      if (toDate.isValid()) newSearchParams.set(SCHEDULE_SEARCH_PARAMS.TO_DATE, toDate.format('YYYY-MM-DD'));

      // PERSIST IN STORAGE TO RECOVER UPON REFRESH
      localStorage.setItem(SCHEDULE_SEARCH_PARAMS_STORAGE_KEY, newSearchParams.toString());

      history.replace(`/customer-communication/hero-planner/schedules?${newSearchParams.toString()}`);
    }
  }, [fromDate, history, countryGroupId, toDate, view, countryStateId, countryId]);

  return (
    <Stack direction="column" gap={2}>
      <Stack direction="row" gap={4} justifyContent="space-between" alignItems="center" flexWrap="wrap-reverse">
        <Stack direction="row" columnGap={4} rowGap={3} flexWrap="wrap">
          <DateRangePicker
            value={dateRange}
            localeText={{ start: 'From', end: 'To' }}
            formatDensity="spacious"
            format="DD/MM/YYYY"
            onChange={handleDateRangeChange}
          />

          <ToggleButtonGroup value={view} exclusive size="small" onChange={handleViewChange}>
            {VIEWS.map((viewOption) => (
              <ToggleButton key={viewOption.id} value={viewOption.id}>
                {viewOption.name}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Stack>

        <Button variant="contained" component={Link} to="/customer-communication/hero-planner/schedules/new">
          Create Send
        </Button>
      </Stack>

      {isCountryGroupsFilterSupportedForBrand(brand) && (
        <SchedulesCountryGroupsTabs brand={brand} value={countryGroupId} onChange={setCountryGroupId} />
      )}
      {!isCountryGroupsFilterSupportedForBrand(brand) && (
        <SchedulesCountryStateTabs
          countryGroupId={countryGroupId}
          countryId={countryId}
          brand={brand}
          value={countryStateId}
          onChange={setCountryStateId}
        />
      )}
    </Stack>
  );
}

export default SchedulesFilters;
