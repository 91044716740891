import React from 'react';

import { Button, Checkbox, FormControlLabel, Grid, TextField } from '@mui/material';

interface Props {
  propertyIds: string;
  isMobile?: boolean;
  checkIn: string;
  nights: number;
  combinations: number;
  isKnownItinerary: boolean;
  occupancy: number;
  setNights: (value: number) => void;
  setCombinations: (value: number) => void;
  setCheckIn: (value: string) => void;
  setIsMobile: (value: boolean) => void;
  setPropertyIds: (value: string) => void;
  setOccupancy: (value: number) => void;
  onSubmit: () => void;
}
export function GHAPriceCheckSearch({
  propertyIds,
  isMobile = false,
  setIsMobile,
  setPropertyIds,
  checkIn,
  setCheckIn,
  nights,
  setNights,
  setCombinations,
  combinations,
  onSubmit,
  isKnownItinerary,
  occupancy,
  setOccupancy,
}: Props) {
  return (
    <Grid container spacing={3} mb={4} mt={4}>
      <Grid item sm={12}>
        <TextField
          fullWidth
          autoFocus
          type="text"
          value={propertyIds}
          onChange={(e) => setPropertyIds(e.target.value)}
          label="Property IDs (comma separated)"
          placeholder="Property IDs"
        />
      </Grid>

      <Grid item sm={3}>
        <TextField
          placeholder="Check In Date"
          fullWidth
          type="date"
          value={checkIn}
          onChange={(e) => setCheckIn(e.target.value)}
        />
      </Grid>
      <Grid item sm={3}>
        <TextField
          label="Number of Nights"
          fullWidth
          type="number"
          value={nights}
          onChange={(e) => setNights(+e.target.value)}
          inputProps={{ step: '1', min: '0' }}
          placeholder="Number of Nights"
        />
      </Grid>

      <Grid item sm={3}>
        <TextField
          label="Number of Occupants"
          fullWidth
          type="number"
          value={occupancy}
          onChange={(e) => setOccupancy(+e.target.value)}
          inputProps={{ step: '1', min: '1' }}
        />
      </Grid>

      <Grid item sm={3}>
        <TextField
          label="Number of Combinations (Max 50)"
          fullWidth
          type="number"
          value={combinations}
          disabled={isKnownItinerary}
          onChange={(e) => setCombinations(+e.target.value)}
          inputProps={{ step: '1', min: '1', max: '50' }}
          placeholder="Number of Combinations"
        />
      </Grid>
      <Grid item sm={2}>
        <FormControlLabel
          control={<Checkbox checked={isMobile} onChange={(e) => setIsMobile(e.target.checked)} />}
          label={'Is Mobile'}
        />
      </Grid>
      <Grid item sm={1}>
        <Button type="submit" variant="contained" onClick={onSubmit}>
          Search
        </Button>
      </Grid>
    </Grid>
  );
}
