export function isTripAdvisorURL(url: string): boolean {
  // regex match tripadvisor .com .co.uk .com.au etc
  const tripAdvisorHotelURLRegexp =
    /^https:\/\/www\.tripadvisor\.(com|co)(\.[a-z]{2})?\/Hotel_Review-g\d{1,10}-d\d{1,10}-Reviews-[\w-]{1,255}\.html$/;
  return tripAdvisorHotelURLRegexp.test(url);
}

export function parsePropertyNameFromTAURL(url: string): string | undefined {
  return isTripAdvisorURL(url) ? url.split('-')[4].replace(/_/g, ' ') : undefined;
}
