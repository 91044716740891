import React from 'react';

import { GREY } from '~/configs/colours';

import { Box, Chip, InputLabel } from '@mui/material';

export interface TagsReadOnlyFieldProps {
  title: string;
  value: Array<string>;
}

export default function TagsReadOnlyField({ title, value }: TagsReadOnlyFieldProps) {
  return (
    <Box>
      <InputLabel
        variant="standard"
        shrink
        sx={{
          // Try to mimic MUI input label on TextField
          '&.MuiInputLabel-shrink': {
            transform: 'translate(0, -1.5px) scale(0.75)',
            transformOrigin: 'top left',
            color: GREY[500],
          },
        }}
      >
        {title}
      </InputLabel>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 0.5,
          typography: 'body2',
        }}
      >
        {Array.isArray(value) && value.map((value, index) => <Chip key={index} label={value} size="small" />)}
      </Box>
    </Box>
  );
}
