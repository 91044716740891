import React, { useMemo } from 'react';

import { Link } from 'react-router-dom';

import EditIcon from '@mui/icons-material/Edit';
import { Alert } from '@mui/material';
import { Button, Stack } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';

import { formatDateShort } from '~/services/TimeService';

interface ActionButtonsProps {
  row: GridRenderCellParams;
}

export function ActionButtons({ row }: ActionButtonsProps) {
  return (
    <Stack direction="row" alignItems="center" gap={1} height="100%">
      <Button
        variant="outlined"
        component={Link}
        to={`/flights/flight-deal/${row.id}/edit`}
        style={{ minWidth: 40 }}
        size="small"
        target="_blank"
      >
        <EditIcon />
      </Button>
    </Stack>
  );
}

interface Props {
  data: Array<App.FlightDeal>;
  loading?: boolean;
  error?: string;
}

export function FlightsCampaignsDealsTable({ data, loading, error }: Props) {
  const columns: Array<GridColDef> = useMemo(() => {
    const commonColumnProps = {
      minWidth: 120,
      flex: 1,
    };

    return [
      {
        ...commonColumnProps,
        field: 'dealDame',
        headerName: 'Deal Name',
        renderCell: ({ row }) => getName(row.dealName),
      },
      {
        ...commonColumnProps,
        field: 'categoryGroup',
        headerName: 'Category',
      },
      {
        ...commonColumnProps,
        field: 'region',
        headerName: 'Region',
      },
      {
        ...commonColumnProps,
        field: 'fareType',
        headerName: 'Type',
      },
      {
        ...commonColumnProps,
        field: 'carrierCode',
        headerName: 'Airline Code',
      },
      {
        ...commonColumnProps,
        field: 'route',
        headerName: 'Route',
        renderCell: ({ row }) => `${row.originAirportCode} - ${row.destinationAirportCode}`,
      },
      {
        ...commonColumnProps,
        field: 'salesPeriod',
        headerName: 'Sales Period',
        renderCell: ({ row }) => formatSalesPeriod(row),
      },
      {
        ...commonColumnProps,
        field: 'travelPeriod',
        headerName: 'Travel Period',
        renderCell: ({ row }) => formatTravelPeriod(row),
      },
      {
        ...commonColumnProps,
        field: 'fareClass',
        headerName: 'Fare Class',
      },
      {
        field: 'actions',
        headerName: 'Actions',
        renderCell: ({ row }) => <ActionButtons row={row} />,
        sortable: false,
      },
    ];
  }, []);

  if (error) {
    return <Alert severity="error">Something went wrong</Alert>;
  }

  return (
    <DataGridPro
      columns={columns}
      loading={loading}
      rows={data}
      disableRowSelectionOnClick
      disableColumnMenu
      density="standard"
      initialState={{ pinnedColumns: { right: ['actions'] } }}
    />
  );
}

function getDate(val: string) {
  return formatDateShort(val);
}

function getName(val: string) {
  return val?.length > 20 ? val.substring(0, 20) + '...' : val;
}

function formatSalesPeriod(row): string {
  return `${getDate(row.salesStartDate)} - ${getDate(row.salesEndDate)}`;
}

export function formatTravelPeriod(row) {
  const offerDurations = row.offerDuration;

  const travelPeriods = offerDurations
    .map((duration) => ({
      start: duration.departureDate,
      end: duration.arrivalDate,
    }))
    .filter((period) => period.start && period.end);

  return (
    <>
      {travelPeriods.map((period, index) => (
        <React.Fragment key={index}>
          {index > 0 && ', '}
          {getDate(period.start)} - {getDate(period.end)}
        </React.Fragment>
      ))}
    </>
  );
}
