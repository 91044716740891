import React, { ReactNode } from 'react';

import { Box, SxProps } from '@mui/material';

import PageAreas from './PageAreas';
import {
  EMPTY_PAGE_COMPONENT_STYLE,
  INVALID_PAGE_COMPONENT_DESCENDENCE,
  PAGE_GUTTER_SIZE,
} from './pageSupportingStyles';

const rootStyle: SxProps = {
  ...EMPTY_PAGE_COMPONENT_STYLE,
  ...INVALID_PAGE_COMPONENT_DESCENDENCE,
  gridArea: PageAreas.header,
  borderBottomWidth: { md: 1 },
  borderBottomStyle: { md: 'solid' },
  borderBottomColor: { md: 'neutral.dark' },
};

interface Props {
  children: ReactNode;
}

function PageHeader({ children }: Props) {
  return (
    <Box px={PAGE_GUTTER_SIZE} className={PageAreas.header} sx={rootStyle}>
      {children}
    </Box>
  );
}

export default PageHeader;
