import React, { useCallback, useEffect, useState } from 'react';

import requestGetHeroPlannerSchedule from '~/queries/customerCommunication/requestGetHeroPlannerSchedule';
import requestPatchCustomSchedule from '~/queries/customerCommunication/requestPatchCustomSchedule';
import requestPatchHeroPlannerSchedule from '~/queries/customerCommunication/requestPatchHeroPlannerSchedule';

import { Alert, AlertTitle, Button, CircularProgress, Paper, Stack, Typography } from '@mui/material';

import { RequestStatus } from '~/consts/requestConstants';

import { isRequestFulfilled, isRequestRejected, isRequestUnresolved } from '~/utils/requestUtils';

import ScheduleEmailStatusEditor from './ScheduleEmailStatusEditor';
import ScheduleEntityForm from './ScheduleEntityForm';
import SchedulePushStatusEditor from './SchedulePushStatusEditor';

interface Props {
  scheduleId: string;
  segment?: string;
}

type CombinedSchedule = CustomerCommunication.HeroPlannerSchedule | CustomerCommunication.CustomSchedule;

function ExistingScheduleForm({ scheduleId, segment }: Props) {
  const [existingScheduleReq, setExistingScheduleReq] = useState<Utils.RequestState<CombinedSchedule, string>>({
    status: RequestStatus.INITIAL,
  });
  const requestSchedule = useCallback(async () => {
    setExistingScheduleReq({
      status: RequestStatus.PENDING,
      params: scheduleId,
    });

    try {
      const result = await requestGetHeroPlannerSchedule(scheduleId, segment);
      setExistingScheduleReq({
        status: RequestStatus.FULFILLED,
        params: scheduleId,
        result,
      });
    } catch (error) {
      setExistingScheduleReq({
        status: RequestStatus.REJECTED,
        params: scheduleId,
        error,
      });
    }
  }, [scheduleId, segment]);

  useEffect(() => {
    requestSchedule();
  }, [requestSchedule]);

  const [submissionReq, setSubmissionReq] = useState<Utils.RequestState<undefined, string>>({
    status: RequestStatus.INITIAL,
  });
  const handleSubmission = useCallback(
    async (unsaved: CustomerCommunication.UnsavedHeroPlannerSchedule | CustomerCommunication.UnsavedCustomSchedule) => {
      const params = [scheduleId, unsaved] as const;
      setSubmissionReq({
        status: RequestStatus.PENDING,
        params,
      });

      try {
        if ('type' in unsaved && unsaved.type === 'DESTINATION') {
          await requestPatchCustomSchedule(scheduleId, unsaved);
        } else {
          await requestPatchHeroPlannerSchedule(
            scheduleId,
            unsaved as CustomerCommunication.UnsavedHeroPlannerSchedule,
          );
        }
        setSubmissionReq({
          status: RequestStatus.FULFILLED,
          params,
          result: undefined,
        });
      } catch (error) {
        setSubmissionReq({
          status: RequestStatus.REJECTED,
          params,
          error,
        });
      }
    },
    [scheduleId],
  );

  useEffect(() => {
    if (isRequestFulfilled(submissionReq)) {
      requestSchedule();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submissionReq.status]);

  if (isRequestUnresolved(existingScheduleReq)) {
    return <CircularProgress />;
  }

  if (isRequestRejected(existingScheduleReq)) {
    return (
      <Alert
        severity="error"
        action={
          <Button size="small" color="inherit" onClick={requestSchedule}>
            Retry
          </Button>
        }
      >
        <AlertTitle>Could not fetch the schedule.</AlertTitle>
        <pre>{existingScheduleReq.error}</pre>
      </Alert>
    );
  }

  const schedule = existingScheduleReq.result;

  return (
    <>
      <Paper elevation={2} sx={{ p: 2 }}>
        <Stack direction="column" gap={2}>
          <Stack direction="row" justifyContent="end" alignItems="center" flexWrap="wrap" gap={3}>
            <Stack direction="row" alignItems="center" gap={1} flexGrow="1">
              <Typography variant="caption">Email status:</Typography>
              <ScheduleEmailStatusEditor
                scheduleId={schedule.id}
                segment={schedule.segmentId}
                emailStatus={schedule.emailStatus}
                onStatusUpdate={requestSchedule}
              />
            </Stack>
            {schedule.brandId === 'luxuryescapes' && (
              <Stack direction="row" alignItems="center" gap={1} flexGrow="1">
                <Typography variant="caption">Push status:</Typography>
                <SchedulePushStatusEditor
                  scheduleId={schedule.id}
                  segment={schedule.segmentId}
                  pushStatus={schedule.pushStatus}
                  onStatusUpdate={requestSchedule}
                />
              </Stack>
            )}
          </Stack>
          <Alert variant="outlined" severity="warning">
            Clicking each status would commit the change instantly and reload the schedule.
          </Alert>
        </Stack>
      </Paper>
      <ScheduleEntityForm editorType="existing" schedule={schedule} onSubmit={handleSubmission} />
      {isRequestRejected(submissionReq) && (
        <Alert severity="error">
          <AlertTitle>Please resolve the following:</AlertTitle>
          <pre>{submissionReq.error}</pre>
        </Alert>
      )}
      {isRequestFulfilled(submissionReq) && (
        <Alert severity="success">
          <AlertTitle>Successfully updated.</AlertTitle>
        </Alert>
      )}
    </>
  );
}

export default ExistingScheduleForm;
