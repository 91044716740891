import React from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import { Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import ReadOnlyField from '~/components/v2/common/ReadOnlyField/ReadOnlyField';

import { formatDateLongMonthWithMeridiemShort } from '~/services/TimeService';

import { buildOfferTypes } from './orderMetaUtils';

interface Props {
  order: App.Order;
  purchaser: string;
  businessInfo?: {
    businessName: string;
    businessCreditCustomerId?: string;
    employeePersonalAccountInfo: string;
    personalName: string;
    personalEmail: string;
  };
}

function toProperCase(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function actionToHuman(action: string) {
  return toProperCase(action.replace(/_/g, ' '));
}

function mapFraudStatusToColourAndIcon(fraudStatus: string) {
  let fraudStatusColor = 'primary';
  let icon = undefined;
  switch (fraudStatus) {
    case 'reviewed_fraud':
      fraudStatusColor = 'red';
      icon = <CancelIcon color="error" />;
      break;
    case 'pending_review':
      fraudStatusColor = 'orange';
      icon = <PauseCircleFilledIcon color="warning" />;
      break;
    default:
      fraudStatusColor = 'green';
      icon = <CheckCircleIcon color="success" />;
  }
  return {
    color: fraudStatusColor,
    icon,
  };
}
const mapOrderStatusToColourAndIcon = (status: string) => {
  let orderStatusColor = 'black';
  let icon = undefined;
  switch (status) {
    case 'completed':
    case 'refunded':
      orderStatusColor = 'green';
      icon = <CheckCircleIcon color="success" />;
      break;
    case 'cancelled':
    case 'abandoned':
    case 'payment_failed':
      orderStatusColor = 'red';
      icon = <CancelIcon color="error" />;
      break;
    case 'pending':
    case 'awaiting_payment':
    case 'awaiting_purchase':
    case 'needs_attention':
      orderStatusColor = 'orange';
      icon = <PauseCircleFilledIcon color="warning" />;
      break;
  }

  return {
    color: orderStatusColor,
    icon,
  };
};
const getGiftStatusTexAndColor = (status: string) => {
  let giftStatusText = 'primary';
  let giftStatusColor = 'black';
  switch (status) {
    case 'redeemed':
      giftStatusText = 'Redeemed';
      giftStatusColor = 'green';
      break;
    case 'awaiting_delivery':
      giftStatusText = 'Awaiting delivery';
      giftStatusColor = 'orange';
      break;
    case 'pending_redemption':
      giftStatusText = 'Pending redemption';
      giftStatusColor = 'orange';
      break;
    default:
      giftStatusText = status;
      break;
  }
  return { giftStatusText, giftStatusColor };
};

export default function OrderInfo({ order, purchaser }: Props) {
  const offerTypes = buildOfferTypes(order).join(', ');

  const { giftStatusText, giftStatusColor } = getGiftStatusTexAndColor(order.gift_status);

  return (
    <Stack spacing={2}>
      <Typography variant="body2" fontWeight="bold" sx={{ paddingLeft: '8px' }}>
        Order info
      </Typography>
      <Grid container spacing={2} padding={0}>
        <Grid md={3}>
          <ReadOnlyField title="Number" value={order.number} type="text" copyable />
        </Grid>
        <Grid md={3}>
          <ReadOnlyField
            title="Status"
            value={actionToHuman(order.status).toUpperCase()}
            type="text"
            colour={mapOrderStatusToColourAndIcon(order.status).color}
            icon={mapOrderStatusToColourAndIcon(order.status).icon}
          />
        </Grid>
        <Grid md={3}>
          <ReadOnlyField
            title="Ordered At"
            value={formatDateLongMonthWithMeridiemShort(order.created_at)}
            type="text"
          />
        </Grid>
        <Grid md={3}>
          <ReadOnlyField title="Sold By" value={purchaser} type="text" />
        </Grid>
        <Grid md={3}>
          <ReadOnlyField title="Source" value={order.utm_source} type="text" />
        </Grid>
        <Grid md={3}>
          <ReadOnlyField title="Campaign" value={order.utm_campaign} type="text" />
        </Grid>
        <Grid md={3}>
          <ReadOnlyField title="Medium" value={order.utm_medium} type="text" />
        </Grid>
        <Grid md={3}>
          <ReadOnlyField title="Attribution" value={order.le_attribution} type="text" />
        </Grid>
        <Grid md={3}>
          <ReadOnlyField title="Platform" value={order.platform} type="text" />
        </Grid>
        <Grid md={3}>
          <ReadOnlyField title="Offer Types" value={offerTypes} type="text" />
        </Grid>
        <Grid md={3}>
          <ReadOnlyField
            title="Fraud Status"
            value={order.fraud_status ? actionToHuman(order.fraud_status).toUpperCase() : 'N/A'}
            type="text"
            icon={mapFraudStatusToColourAndIcon(order.fraud_status).icon}
            colour={mapFraudStatusToColourAndIcon(order.fraud_status).color}
          />
        </Grid>
        <Grid md={3}>
          <ReadOnlyField title="OTP Required" value={order.elevated_credentials ? 'OTP verified' : 'N/A'} type="text" />
        </Grid>
        {order.gift_status && (
          <Grid md={3}>
            <ReadOnlyField title="Gift" value={giftStatusText} type="text" colour={giftStatusColor} />
          </Grid>
        )}
      </Grid>
    </Stack>
  );
}
