import React from 'react';

import { Stack, Typography } from '@mui/material';

import { definitions } from '@luxuryescapes/contract-svc-promo';

import PromoItemField, { isExpectedField } from '~/components/PromoV3/PromoItemField';

import { getPromoProductDisplayName } from '~/services/PromoService';

import currencyFormatter from '~/utils/currencyFormatter';

import TravellersList from '../PromoPage/TravellersList';

type PromoResponseItemProps = {
  item: definitions['Discount Response']['promo']['items'][0];
  currencyCode: string;
  meta: definitions['PromoMeta'];
  extendedItem: boolean;
};

function PromoResponseItem({ item, currencyCode, meta, extendedItem }: PromoResponseItemProps) {
  const hasDiscount = item.discountAmount > 0;
  const isLuxPlusPrice = item.luxPlusPrice > 0 && item.luxPlusPrice < item.discountableTotal;
  return (
    <Stack direction="column" gap={1}>
      <Typography>
        {hasDiscount && (
          <Typography component="span" color={isLuxPlusPrice ? 'blue' : 'text.primary'}>
            {currencyFormatter(currencyCode, item.discountableTotal - item.discountAmount)}
          </Typography>
        )}
        <Typography
          sx={{
            textDecoration: hasDiscount ? 'line-through' : '',
          }}
          component="span"
          color={isLuxPlusPrice ? 'blue' : 'text.primary'}
        >
          {currencyFormatter(currencyCode, item.discountableTotal)}
        </Typography>
        {hasDiscount && (
          <Typography color="green" component="span">
            ({currencyFormatter(currencyCode, item.discountAmount)})
          </Typography>
        )}
      </Typography>
      <PromoItemField
        title="Product Type"
        field="productType"
        categoryBK={item.categoryBK}
        value={meta && getPromoProductDisplayName(meta.promo_product_types, item.categoryBK, item.subCategoryBK)}
      />
      {isExpectedField(item.categoryBK, 'travellers') && <TravellersList travellers={item.travellers} />}
      <PromoItemField
        title="Reservation Type"
        field="reservationType"
        categoryBK={item.categoryBK}
        value={item.reservationType}
      />
      {extendedItem && (
        <Stack direction="column" title={JSON.stringify(item, null, 4)}>
          <PromoItemField
            title="Item Country Code"
            field="itemCountryCode"
            categoryBK={item.categoryBK}
            value={item.itemCountryCode}
          />
          <PromoItemField
            title="Number of Nights"
            field="numberOfNights"
            categoryBK={item.categoryBK}
            value={item.numberOfNights}
          />
          <PromoItemField
            title="Number of Adults"
            field="numberOfAdults"
            categoryBK={item.categoryBK}
            value={item.numberOfAdults}
          />
          <PromoItemField
            title="Number of Children"
            field="numberOfChildren"
            categoryBK={item.categoryBK}
            value={item.numberOfChildren}
          />
          <PromoItemField
            title="Origin Airport Code"
            field="originAirportCode"
            categoryBK={item.categoryBK}
            value={item.originAirportCode}
          />
          <PromoItemField
            title="Item Info String"
            field="itemInfoString"
            categoryBK={item.categoryBK}
            value={item.itemInfoString}
          />
        </Stack>
      )}
    </Stack>
  );
}

export { PromoResponseItem };
