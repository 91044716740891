import React from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionDetails, AccordionSummary, Box, Accordion as MuiAccordion, Stack, Typography } from '@mui/material';

export interface AccordionProps {
  children: React.ReactNode;
  title: string;
  icon?: React.ReactNode;
  columns?: number;
}

const getGridTemplateColumns = (columns: number) => {
  // On mobile, there will only ever be 1 column
  return { xs: '1fr', md: `repeat(${columns}, 1fr)` };
};

const getGridStyle = (columns: number) => {
  return {
    display: 'grid',
    gridTemplateColumns: getGridTemplateColumns(columns),
    gap: 2,
    overflowX: 'auto',
    minWidth: 0,
  };
};

export default function Accordion({ title, icon, children, columns = 2 }: AccordionProps) {
  return (
    <Box>
      <MuiAccordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Stack direction="row" spacing={1} alignItems="center">
            {icon}
            <Typography variant="h6">{title}</Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails sx={getGridStyle(columns)}>{children}</AccordionDetails>
      </MuiAccordion>
    </Box>
  );
}
