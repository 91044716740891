import React, { useEffect, useState } from 'react';

import { SubmitHandler, useFormContext } from 'react-hook-form';
import { MarkdownPreview } from 'react-marked-markdown';
import { Link } from 'react-router-dom';

import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import VisibilityIcon from '@mui/icons-material/Visibility';
import WarningIcon from '@mui/icons-material/Warning';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';

import { getCountries } from '@luxuryescapes/lib-countries';

import DateWidget from '~/components/Common/Forms/fields/DateWidget';
import Input from '~/components/Common/Forms/fields/Input';
import RichTextEditor from '~/components/Common/Forms/fields/RichTextEditor';
import Select from '~/components/Common/Forms/fields/Select';

import { getAllAirportCodes } from '../../../services/FlightsService';

import { BannerPreviewIcon } from './BannerPreviewIcon';
import { FormInput } from './common';

const TargetDictionary: Record<string, string> = {
  hotelDestinationCountry: 'the accommodations country is',
  hotelDestinationRegion: 'the accommodations region is',
  flightDepartureAirportCode: 'a flight in the order departs from airport',
  flightArrivalAirportCode: 'a flight in the order arrives at airport',
  travelBetweenDates: 'traveling between the dates',
};

interface Props {
  onSubmit: SubmitHandler<FormInput>;
  onSubmitDraft?: SubmitHandler<FormInput>;
  criteria: Array<any>;
  appendCriterion: (criterion: any) => void;
  removeCriterion: (index: number) => void;
  mode: 'create' | 'update';
}

function BannerEditForm({ onSubmit, onSubmitDraft, criteria, appendCriterion, removeCriterion, mode }: Props) {
  const { control, handleSubmit, reset, watch } = useFormContext<FormInput>();
  const [airportCodes, setAirportCodes] = useState([]);

  useEffect(() => {
    const fetchLocations = async () => {
      const airportCodes = await getAllAirportCodes('luxuryescapes');
      setAirportCodes(airportCodes.result.allAirportCodes);
    };
    fetchLocations();
  }, []);

  return (
    <Grid container spacing={2}>
      {/* left side - input elements */}
      <Grid
        item
        xs={5}
        sx={{
          overflowY: 'scroll',
          height: 'calc(100vh - 210px)',
        }}
      >
        <Stack direction="column" spacing={2}>
          <Stack direction="column" spacing={1}>
            <Typography variant="h6">Banner Details</Typography>
            <Input
              control={control}
              name="campaignTitle"
              label="Banner Name"
              required
              fullWidth
              rules={{
                required: 'Banner name is required',
              }}
            />
            {/* The start and end date row */}

            <Stack direction="row" spacing={2}>
              <DateWidget name="startDate" label="Start Date" control={control} fullWidth />
              <DateWidget name="endDate" label="End Date" control={control} fullWidth />
            </Stack>
          </Stack>

          <Stack direction="column" spacing={1}>
            <Box
              sx={{
                marginTop: 2,
              }}
            >
              <Typography variant="h6">Banner Content</Typography>
            </Box>
            <Input
              control={control}
              name="bannerTitle"
              label="Banner Text Bold"
              inputProps={{
                placeholder: 'Warning: ',
              }}
              rules={{
                required: 'Banner text is required',
              }}
              required
              fullWidth
            />
            <Input
              control={control}
              name="bannerOverview"
              label="Banner Text"
              rows={3}
              inputProps={{
                placeholder: 'Alfredo is coming.',
              }}
              fullWidth
            />
            <Stack direction="row" spacing={2}>
              <Select control={control} name="bannerAppearance.icon" label="Banner Icon">
                <MenuItem value="check">
                  <Stack direction="row" spacing={1}>
                    <CheckIcon fontSize="small" />
                    <span>Check</span>
                  </Stack>
                </MenuItem>
                <MenuItem value="info">
                  <Stack direction="row" spacing={2}>
                    <InfoIcon fontSize="small" />
                    <span>Info</span>
                  </Stack>
                </MenuItem>
                <MenuItem value="warning">
                  <Stack direction="row" spacing={2}>
                    <WarningIcon fontSize="small" />
                    <span>Warning</span>
                  </Stack>
                </MenuItem>
                <MenuItem value="error">
                  <Stack direction="row" spacing={2}>
                    <ErrorIcon fontSize="small" />
                    <span>Error</span>
                  </Stack>
                </MenuItem>
              </Select>
              <Select control={control} name="bannerAppearance.color" label="Banner Color">
                <MenuItem value="info">
                  <span style={{ color: '#0288d1' }}>Info</span>
                </MenuItem>
                <MenuItem value="warning">
                  <span style={{ color: '#ed6c02' }}>Warning</span>
                </MenuItem>
                <MenuItem value="error">
                  <span style={{ color: '#d32f2f' }}>Error</span>
                </MenuItem>
                <MenuItem value="success">
                  <span style={{ color: '#2e7d32' }}>Success</span>
                </MenuItem>
              </Select>
            </Stack>

            <Input control={control} name="modalTitle" label="Modal Title" fullWidth />
            <RichTextEditor control={control} name="modalDescription" label="Modal Description" />
          </Stack>
        </Stack>
        <Stack direction="column" spacing={1} mt={1}>
          <Box
            mt={1}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6" display="flex" alignItems="center">
              Targeting Rules
              <Tooltip title="The number of people being targeted by this banner. The exact number will be calculated based on your criteria.">
                <InfoIcon fontSize="small" sx={{ marginLeft: 1 }} />
              </Tooltip>
            </Typography>
          </Box>
          {criteria.map((field, index) => (
            <Box key={field.id}>
              <Select key={field.id} control={control} name={`targets.${index}.criterion`} label={`Rule ${index + 1}`}>
                <MenuItem value="hotelDestinationCountry">Accommodation Country</MenuItem>
                <MenuItem value="hotelDestinationRegion">Accommodation Region</MenuItem>
                <MenuItem value="flightDepartureAirportCode">Flight Departure Airport</MenuItem>
                <MenuItem value="flightArrivalAirportCode">Flight Arrival Airport</MenuItem>
                <MenuItem value="travelBetweenDates">Travel Between Dates</MenuItem>
              </Select>
              {watch(`targets.${index}.criterion`) === 'hotelDestinationCountry' && (
                <Select control={control} name={`targets.${index}.value`} label="Value">
                  {getCountries().map((country) => (
                    <MenuItem key={country} value={country}>
                      {country}
                    </MenuItem>
                  ))}
                </Select>
              )}
              {watch(`targets.${index}.criterion`) === 'hotelDestinationRegion' && (
                <Input
                  control={control}
                  name={`targets.${index}.value`}
                  label="Value"
                  type="text"
                  fullWidth
                  helperText="Please enter regions keywords separated by commas"
                />
              )}
              {(watch(`targets.${index}.criterion`) === 'flightDepartureAirportCode' ||
                watch(`targets.${index}.criterion`) === 'flightArrivalAirportCode') && (
                <Select control={control} name={`targets.${index}.value`} label="Value">
                  {airportCodes.map((airport) => (
                    <MenuItem key={airport.code} value={airport.code}>
                      {airport.code} ({airport.name})
                    </MenuItem>
                  ))}
                </Select>
              )}

              {watch(`targets.${index}.criterion`) === 'travelBetweenDates' && (
                <Stack direction="row" spacing={2}>
                  <DateWidget control={control} name={`targets.${index}.startDate`} label="Start Date" fullWidth />
                  <DateWidget control={control} name={`targets.${index}.endDate`} label="End Date" fullWidth />
                </Stack>
              )}

              <Stack justifyContent="flex-end" mt={1} direction="row">
                <Button
                  variant="outlined"
                  onClick={() => removeCriterion(index)}
                  size="small"
                  color="error"
                  startIcon={<DeleteIcon />}
                >
                  Remove
                </Button>
              </Stack>
            </Box>
          ))}
        </Stack>
        <Button variant="outlined" onClick={() => appendCriterion({ criterion: '' })} sx={{ marginTop: 1 }}>
          Add Rule
        </Button>

        <Box sx={{ display: 'flex', gap: 2, marginTop: 2 }}>
          <Button variant="contained" type="submit" startIcon="" onClick={handleSubmit(onSubmit)}>
            {mode === 'update' ? 'Update' : 'Create'}
          </Button>
          {onSubmitDraft && (
            <Button variant="contained" type="submit" startIcon="" onClick={handleSubmit(onSubmitDraft)}>
              Save As Draft
            </Button>
          )}
          <Link to="/site-banner-campaigns">
            <Button
              variant="contained"
              type="submit"
              startIcon=""
              onClick={() => {
                reset();
              }}
              color="error"
            >
              Cancel
            </Button>
          </Link>
        </Box>
      </Grid>
      {/* right side - preview */}
      <Grid
        item
        xs={7}
        sx={{
          overflowY: 'scroll',
          height: 'calc(100vh - 210px)',
        }}
      >
        <Typography variant="h6" display="flex" alignItems="center">
          <IconButton>
            <VisibilityIcon />
          </IconButton>
          Preview
        </Typography>
        <Alert
          icon={<BannerPreviewIcon icon={watch('bannerAppearance.icon')} />}
          severity={watch('bannerAppearance.color') ?? 'info'}
        >
          <Typography fontWeight="bold" component="span">
            {watch('bannerTitle') || 'Banner title preview: '}
          </Typography>
          <Typography component="span">{watch('bannerOverview') || 'Banner overview preview'}</Typography>
        </Alert>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            minHeight: 300,
            paddingTop: '10%',
            paddingBottom: '10%',
          }}
        >
          <Card sx={{ width: '80%', maxWidth: 600 }}>
            <CardContent>
              <Typography variant="h5" component="div">
                {watch('modalTitle') || 'Modal title'}
              </Typography>
              <MarkdownPreview value={watch('modalDescription') || 'Modal description will appear here.'} />
            </CardContent>
          </Card>
        </Box>
        <Box>
          <Typography variant="h6">Targeting Rules Summary:</Typography>
          {watch('targets').length > 0 &&
            watch('targets').some((target) => target.criterion) &&
            watch('targets').some((target) => target.value) && (
              <Typography variant="body1">
                When{' '}
                {watch('targets')
                  .map((target) => {
                    const targetCriterion = TargetDictionary[target.criterion];
                    const value = target.value;

                    if (targetCriterion && value) {
                      return `${targetCriterion} ${value}`;
                    }

                    return '';
                  })
                  .join(' and ')}
              </Typography>
            )}
          {watch('targets').length === 0 && (
            <Typography variant="body1">No targeting rules have been added.</Typography>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}

export default BannerEditForm;
