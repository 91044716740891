import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { debounce } from 'lodash';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

import {
  Alert,
  Box,
  FormControl,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TablePagination,
} from '@mui/material';

import { regions } from '@luxuryescapes/lib-regions/lib/regions';

import PlaceSearchForm from '~/components/Common/Forms/PlaceSearchForm';

import SearchService, {
  ANYWHERE_PLACE_ID,
  EvVariant,
  HotelSearchList,
  OrderOffer,
  ScoreSettings,
  TourSearchList,
  TypeaheadQueryParams,
  TypeaheadResult,
  TypeaheadType,
  UnifiedSearchList,
} from '~/services/SearchService';

import Spinner from '../../Common/Spinner';

import SearchRankingTable from './SearchRankingTable';
import SearchRankingTooltip from './SearchRankingTooltip';

const TYPEAHEAD_TYPE: Record<TypeaheadType, boolean> = {
  airport: false,
  province_state: true,
  neighborhood: true,
  city: true,
  high_level_region: true,
  country: true,
  multi_city_vicinity: true,
  metro_station: false,
  continent: true,
  train_station: false,
  point_of_interest: true,
  colloquial_area: true,
  le_property: true,
  le_property_unique_stays: true,
  bedbank_property: true,
  bedbank_property_unique_stays: true,
  le_experience: false,
  channel_experience: false,
  car_hire_location: false,
};

const PAGE_LIMITS = [100, 500, 1000, 1500, 2000];

export type Vertical = 'hotel' | 'tour' | 'cruise' | 'experience' | 'unified' | 'ultra lux';
const Verticals: Array<Vertical> = ['hotel', 'tour', 'cruise', 'experience', 'unified', 'ultra lux'];

const VariantSupportedVerticals: Array<Vertical> = ['hotel', 'tour', 'cruise', 'experience'];

export interface AlgorithmOptions {
  [key: string]: string;
}

export const DEFAULT_ALGORITHM_OPTION = {
  [EvVariant.Current]: 'Current Live',
};

const SortOptions: Record<string, Array<string>> = {
  tour: ['default', 'ev'],
  cruise: ['default', 'ev'],
  experience: ['default', 'ev'],
  all: ['default', 'ev', 'daily sales'],
};

const ListNames = {
  tour: 'vertical-tour',
  cruise: 'vertical-cruise',
  experience: 'vertical-experience',
  unified: 'home',
};

const productTypeMapping = {
  rental: 'Rentals',
  tactical_ao_hotel: 'LPC',
  bedbank: 'LPP',
};

function SearchRankingPage() {
  const { enqueueSnackbar } = useSnackbar();

  const [listOffers, setListOffers] = useState<Array<OrderOffer>>([]);
  const [hotelOffers, setHotelOffers] = useState<HotelSearchList>([]);
  const [tourOffers, setTourOffers] = useState<TourSearchList>([]);
  const [cruiseOffers, setCruiseOffers] = useState<UnifiedSearchList>([]);
  const [experienceOffers, setExperienceOffers] = useState<UnifiedSearchList>([]);
  const [unifiedOffers, setUnifiedOffers] = useState<UnifiedSearchList>([]);
  const [ultraLuxOffers, setUltraLuxOffers] = useState<UnifiedSearchList>([]);
  const [fetchingState, setFetchingState] = useState<Utils.FetchingState>('idle');
  const [region, setRegion] = useState('AU');
  const [vertical, setVertical] = useState<Vertical>('hotel');
  const [page, setPage] = useState(1);
  const [offersPerPage, setOffersPerPage] = useState(PAGE_LIMITS[0]);
  const [evVariant, setEvVariant] = useState<EvVariant>(EvVariant.Current);
  const [algorithmOptions, setAlgorithmOptions] = useState<AlgorithmOptions>(DEFAULT_ALGORITHM_OPTION);
  const [isPlaceSearchOpen, setIsPlaceSearchOpen] = useState<boolean>(false);
  const [placeValue, setPlaceValue] = useState('Anywhere');
  const [destinationId, setDestinationId] = useState<string | undefined>(ANYWHERE_PLACE_ID);
  const [placeSearchResults, setPlaceSearchResults] = useState<Array<TypeaheadResult>>([]);
  const [scoreSettings, setScoreSettings] = useState<ScoreSettings | undefined>(undefined);
  const [sorting, setSorting] = useState<string>('default');

  const brand = useSelector((state: App.State) => state.tenant.brand);

  const onVerticalChange = useCallback(
    (event: SelectChangeEvent) => {
      const vertical = event.target.value as Vertical;
      setVertical(vertical);
      setEvVariant(EvVariant.Current);
      if (!SortOptions[vertical]?.includes(sorting)) {
        setSorting('default');
      }
    },
    [sorting],
  );

  const onVariantChange = useCallback(
    (event: SelectChangeEvent) => {
      setEvVariant(event.target.value as EvVariant);
    },
    [setEvVariant],
  );

  const onRegionChange = useCallback(
    (event: SelectChangeEvent) => {
      setRegion(event.target.value);
    },
    [setRegion],
  );

  const sortOptions = useMemo(() => SortOptions[vertical], [vertical]);

  const loadListOffers = useCallback(async () => {
    const listName = ListNames[vertical];
    if (listName && sorting == 'default') {
      const { result } = await SearchService.getListOrder(listName, brand, region);
      setListOffers(result.offers);
    } else {
      setListOffers([]);
    }
  }, [vertical, sorting, brand, region]);

  const loadVariants = useCallback(async () => {
    if (VariantSupportedVerticals.includes(vertical)) {
      const { result } = await SearchService.getOfferScoreSettings(vertical, region);
      setAlgorithmOptions(result?.variants?.variantInfos);
    } else {
      setAlgorithmOptions(DEFAULT_ALGORITHM_OPTION);
    }
  }, [vertical, region]);

  const loadOffers = useCallback(async () => {
    switch (vertical) {
      case 'hotel':
        {
          const { result } = await SearchService.getHotelOffers({
            region,
            destinationId,
            includeAllBedbankWithSales: true,
            evVariant: evVariant,
            noCache: true,
          });
          setHotelOffers(result);
        }
        break;
      case 'tour':
        {
          const { result } = await SearchService.getAllTourOffers(region, destinationId, sorting);
          setTourOffers(result);
        }
        break;
      case 'cruise':
        {
          const { result } = await SearchService.getAllCruiseOffers(region, destinationId, sorting, evVariant);
          setCruiseOffers(result);
        }
        break;
      case 'experience':
        {
          const { result } = await SearchService.getAllExperienceOffers(brand, region, destinationId, sorting);
          setExperienceOffers(result);
        }
        break;
      case 'unified':
        {
          const { results } = await SearchService.getAllUnifiedOffers(region, destinationId, sorting);
          setUnifiedOffers(results);
        }
        break;
      case 'ultra lux':
        {
          const { results } = await SearchService.getAllUltraLuxOffers(region, destinationId);
          setUltraLuxOffers(results);
        }
        break;
    }
  }, [vertical, region, destinationId, evVariant, sorting, brand]);

  useEffect(() => {
    setFetchingState('loading');
    Promise.all([loadOffers(), loadListOffers(), loadVariants()])
      .then(() => {
        setFetchingState('success');
      })
      .catch((error) => {
        setFetchingState('failed');
        enqueueSnackbar(`Error: ${error.message}`, { variant: 'error' });
      });
  }, [enqueueSnackbar, loadListOffers, loadOffers, loadVariants]);

  useEffect(() => {
    if (vertical !== 'ultra lux') {
      const offerScoreVertical = vertical !== 'unified' ? vertical : 'hotel';
      SearchService.getOfferScoreSettings(offerScoreVertical, region)
        .then((res) => {
          setScoreSettings(res.result);
        })
        .catch((error) => {
          enqueueSnackbar(`Error: ${error.message}`, { variant: 'error' });
        });
    } else {
      // we cannot show score setting if all verticals are selected
      setScoreSettings(undefined);
    }
  }, [enqueueSnackbar, vertical, region]);

  const offers = {
    hotel: hotelOffers,
    tour: tourOffers,
    cruise: cruiseOffers,
    experience: experienceOffers,
    unified: unifiedOffers,
    'ultra lux': ultraLuxOffers,
  }[vertical];

  const curPageOffers: any = useMemo(
    () => offers.slice((page - 1) * offersPerPage, page * offersPerPage),
    [offers, page, offersPerPage],
  );

  const debouncedPlaceSearch = debounce(async (value: string) => {
    const res = await SearchService.typeahead(value, {
      type: Object.keys(TYPEAHEAD_TYPE).filter((type) => TYPEAHEAD_TYPE[type]) as Array<keyof typeof TYPEAHEAD_TYPE>,
      brand: brand as TypeaheadQueryParams['brand'],
      region: 'AU',
      limit: 20,
    });
    setPlaceSearchResults(res.result.results);
  }, 500);

  const onPlaceSearchTyping = useCallback(
    (value: string) => {
      setPlaceValue(value);

      if (value == '') {
        setDestinationId(ANYWHERE_PLACE_ID);
      }

      if (value.length > 2) {
        debouncedPlaceSearch(value);
      }
    },
    [debouncedPlaceSearch],
  );

  const onPlaceSelect = (selection: TypeaheadResult) => {
    setDestinationId(selection.fk);
    setPlaceValue(selection.primary_text);
  };

  // the unified index updates between 7 am and 10 am Sydney time
  const hours = new Date().getUTCHours();
  const isUpdatingWindow = useMemo(() => {
    return hours >= 20 && hours < 23;
  }, [hours]);

  const isCruiseSearch = useMemo(() => {
    return vertical === 'cruise' && sorting === 'default';
  }, [sorting, vertical]);

  const dayRangeInfo = useMemo(() => {
    if (scoreSettings) {
      let info = `${scoreSettings.daySpan} Default`;
      const extendedTimelineSettings = scoreSettings.extendedTimeline?.[evVariant];
      if (extendedTimelineSettings) {
        info += `, ${extendedTimelineSettings.days} ${extendedTimelineSettings.products
          .map((product) => productTypeMapping[product] || product)
          .join('/')}`;
      }
      return info;
    }
  }, [evVariant, scoreSettings]);

  return (
    <Box>
      <Stack spacing={2}>
        {isUpdatingWindow && (
          <Alert severity="warning">
            Please be aware that between 7:00 AEST and 10:00 AEST, the index will undergo updates, resulting in certain
            rankings not being promptly updated during this period.
          </Alert>
        )}
        {isCruiseSearch && (
          <Alert severity="info">
            The cruise search doesn't support tourV1 offers, so the results may differ from the offer list order.
          </Alert>
        )}
        <FormGroup>
          <Box display="flex" gap={8} justifyContent="space-between" alignItems="flex-end">
            <Box display="flex" gap={4} alignItems="center">
              <FormControl sx={{ minWidth: 300 }}>
                <PlaceSearchForm
                  setValue={onPlaceSearchTyping}
                  value={placeValue}
                  setOpen={setIsPlaceSearchOpen}
                  open={isPlaceSearchOpen}
                  onSelect={onPlaceSelect}
                  results={placeSearchResults}
                />
              </FormControl>
              <FormControl sx={{ minWidth: 120 }}>
                <InputLabel id="vertical-select-label">Product</InputLabel>
                <Select
                  labelId="vertical-select-label"
                  label="Vertical"
                  value={vertical}
                  onChange={onVerticalChange}
                  sx={{ textTransform: 'capitalize' }}
                >
                  {Verticals.map((vertical) => (
                    <MenuItem key={vertical} value={vertical} sx={{ textTransform: 'capitalize' }}>
                      {vertical}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ minWidth: 120 }}>
                <InputLabel id="region-select-label">Region</InputLabel>
                <Select labelId="region-select-label" label="Region" value={region} onChange={onRegionChange}>
                  {regions['luxuryescapes'].map((region) => (
                    <MenuItem key={region.code} value={region.code}>
                      {region.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {sortOptions && (
                <FormControl sx={{ minWidth: 120 }}>
                  <InputLabel id="sort-select-label">Sort by</InputLabel>
                  <Select
                    labelId="sort-select-label"
                    label="Sort by"
                    value={sorting}
                    onChange={(event) => setSorting(event.target.value)}
                    sx={{ textTransform: 'capitalize' }}
                  >
                    {sortOptions.map((sorting) => (
                      <MenuItem key={sorting} value={sorting} sx={{ textTransform: 'capitalize' }}>
                        {sorting}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              {scoreSettings && (
                <SearchRankingTooltip scoreSettings={scoreSettings} vertical={vertical} dayRangeInfo={dayRangeInfo} />
              )}
            </Box>
            <FormControl sx={{ minWidth: 120 }}>
              <InputLabel id="sort-select-label">Variant</InputLabel>
              <Select labelId="variant-select-label" label="Variant" value={evVariant} onChange={onVariantChange}>
                {Object.keys(algorithmOptions).map((variant: string) => (
                  <MenuItem key={variant} value={variant}>
                    {algorithmOptions[variant]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </FormGroup>
      </Stack>
      {fetchingState == 'loading' && <Spinner />}
      {fetchingState == 'success' && offers.length === 0 && <Box>No offers found</Box>}
      {fetchingState == 'success' && offers.length > 0 && (
        <SearchRankingTable
          vertical={vertical}
          offers={curPageOffers}
          listOffers={listOffers}
          region={region}
          variant={evVariant}
        />
      )}
      {fetchingState == 'success' && (
        <TablePagination
          component="div"
          count={offers.length}
          page={page - 1}
          onPageChange={(_, page) => setPage(page + 1)}
          rowsPerPage={offersPerPage}
          onRowsPerPageChange={(event) => {
            setOffersPerPage(parseInt(event.target.value));
            setPage(1);
          }}
          rowsPerPageOptions={PAGE_LIMITS}
        />
      )}
    </Box>
  );
}
export default SearchRankingPage;
