import { isBoolean, isString } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { ITEM_TYPE_CAR_HIRE, ITEM_TYPE_SERVICE_FEE, ITEM_TYPE_SUBSCRIPTION } from '~/consts/order';
import {
  CAR_HIRE_REASONS,
  COLLECTION_REASONS,
  EXPIRED_ONLY_TOUR_REFUND_ALLOWED_TYPES,
  EXPIRED_TOUR_REFUND_ALLOWED_TYPES,
  REASONS_WITH_ADDITIONAL_INFO,
  REFUND_V2,
  SERVICE_FEE_ALLOWED_TYPES,
  SUBSCRIPTION_REASONS,
  SUBSCRIPTION_REASONS_V2,
  TNFH_REASON_SOURCE,
  VARIANT_OF_REFUND_ALL,
} from '~/consts/refund';

import currencyFormatter from '~/utils/currencyFormatter';

import {
  AdditionalInfo,
  CollectionReasons,
  EnrichedRefundMetadata,
  RefundMetadata,
  RefundPayload,
  VendorRefundTypes,
} from '../../types';
import { RefundSteps } from '../LPPRefundV2';

export function getItemType(item: App.OrderItem): string {
  if (!item.type) {
    throw new Error(`Item ${item.id} does not have an item type`);
  }
  return item.type;
}

interface ReasonByTypeDict {
  refundTypes: Array<string>;
  reasons: CollectionReasons;
}

export function getReasonsByItemType(
  itemType: string,
  order: any,
  isRefundable: boolean,
  isExpiredTourRefund = false,
): ReasonByTypeDict {
  let possibleCollectionReasons = {
    refundTypes: Object.keys(COLLECTION_REASONS),
    reasons: COLLECTION_REASONS,
  };

  if (isExpiredTourRefund) {
    possibleCollectionReasons.refundTypes = Object.keys(COLLECTION_REASONS).filter((key) =>
      EXPIRED_TOUR_REFUND_ALLOWED_TYPES.includes(key),
    );
    return possibleCollectionReasons;
  }
  switch (itemType) {
    case ITEM_TYPE_CAR_HIRE:
      possibleCollectionReasons = {
        refundTypes: Object.keys(CAR_HIRE_REASONS),
        reasons: CAR_HIRE_REASONS,
      };
      break;
    case ITEM_TYPE_SERVICE_FEE:
      possibleCollectionReasons.refundTypes = Object.keys(COLLECTION_REASONS).filter((key) =>
        SERVICE_FEE_ALLOWED_TYPES.includes(key),
      );
      break;
    case ITEM_TYPE_SUBSCRIPTION:
      possibleCollectionReasons = {
        refundTypes: Object.keys(SUBSCRIPTION_REASONS),
        reasons: SUBSCRIPTION_REASONS_V2,
      };
      break;
    default:
      possibleCollectionReasons.refundTypes = Object.keys(COLLECTION_REASONS).filter(
        (key) => !EXPIRED_ONLY_TOUR_REFUND_ALLOWED_TYPES.includes(key),
      );
  }

  const hasAccommodation = !!order.accommodation_items.length || !!order.bedbank_items.length;
  const hasCruise = !!order.cruise_items.length;
  const hasTour = !!order.tour_items.length;

  // possibleCollectionReasons.refundTypes
  if (hasAccommodation || hasCruise || hasTour) {
    //   Change refund reason type when outside cancellation period
    if (!isRefundable) {
      possibleCollectionReasons.reasons['INSURANCE_BREAKAGE'].defaults.source = TNFH_REASON_SOURCE;
    }
  } else {
    possibleCollectionReasons.refundTypes = Object.keys(COLLECTION_REASONS).filter(
      (key) => key === 'INSURANCE_BREAKAGE',
    );
  }

  return possibleCollectionReasons;
}

export interface CostBreakdown {
  packageCost: string;
  numberOfNights: number;
  surcharges: string;
  avgPricePerNight: string;
  total: string;
}

function buildComment(isTestPurchase, userFullName, refundApprover, commentFallback) {
  let comment = '';

  if (refundApprover) {
    comment += 'Approver: ' + refundApprover + '\n';
  }
  comment += isTestPurchase ? userFullName : commentFallback;

  return comment;
}

export function getDefaultValues(
  refundMetadata: EnrichedRefundMetadata,
  selectedReasonKey: string,
  userFullName: string,
  refundApprover: string,
): RefundPayload {
  let additionalInfo: AdditionalInfo = {};
  if (REASONS_WITH_ADDITIONAL_INFO.includes(selectedReasonKey)) {
    additionalInfo = {
      number_of_nights: 0,
      percentage: 100,
      package_nights: refundMetadata.package_nights,
      total_nights: refundMetadata.total_nights,
      extra_nights: refundMetadata.extra_nights,
    };
  }

  const merchantFeeAmount = refundMetadata.merchant_fee_amount ?? 0;
  const cashAmount = refundMetadata.item_metadata.cash_amount ?? 0;
  const surchargeAmount = refundMetadata.surcharge_metadata?.cash_amount ?? 0;
  let amountToRefund = cashAmount + merchantFeeAmount;
  const accountingMetadatum = [];

  const itemAccountingMetadata = {
    source: refundMetadata.item_metadata.source,
    cash_amount: refundMetadata.item_metadata.cash_amount ?? 0,
    accounting_amount: refundMetadata.item_metadata.accounting_amount ?? 0,
    charge_component_key: refundMetadata.item_metadata.charge_component_key,
    refund_fee: refundMetadata.item_metadata.refund_fee,
  };

  accountingMetadatum.push(itemAccountingMetadata);

  const includeSurchargeMetadata = refundMetadata.has_surcharge;

  if (includeSurchargeMetadata) {
    const surchargeAccountingMetadata = {
      source: refundMetadata.surcharge_metadata.source,
      cash_amount: refundMetadata.surcharge_metadata.cash_amount ?? 0,
      accounting_amount: refundMetadata.surcharge_metadata.accounting_amount ?? 0,
      charge_component_key: refundMetadata.surcharge_metadata.charge_component_key,
      refund_fee: refundMetadata.surcharge_metadata.refund_fee,
    };
    accountingMetadatum.push(surchargeAccountingMetadata);
    amountToRefund += surchargeAmount;
  }

  return {
    transaction_key: uuidv4(),
    reason: refundMetadata?.reason ?? '',
    refund_method: refundMetadata?.refund_method ?? '',
    amount: amountToRefund,
    comment: buildComment(
      selectedReasonKey === 'REFUND_TEST_PURCHASE',
      userFullName,
      refundApprover,
      refundMetadata.comment,
    ),
    ticket_id: refundMetadata.ticket_id,
    room_id: refundMetadata?.room_id ?? undefined,
    currency_code: refundMetadata.currency_code,
    mark_cancelled: refundMetadata.mark_cancelled,
    update_vcc_balance: refundMetadata.update_vcc_balance,
    send_refund_notification: convertStrOrBoolToBool(refundMetadata.send_refund_notification),
    send_customer_refund_notification: convertStrOrBoolToBool(refundMetadata.send_customer_refund_notification),
    accounting_metadata: accountingMetadatum,
    additional_info: additionalInfo,
    disable_credits_promo_refund: refundMetadata.disable_credits_promo_refund,
    remaining_deposit_items: refundMetadata.remaining_deposit_items,
    current_deposit_item: refundMetadata.current_deposit_item,
    is_deposit: refundMetadata.is_deposit,
    is_final_deposit_refund: refundMetadata.is_final_deposit_refund,
    is_instalment: refundMetadata.is_instalment,
    remaining_instalment_items: refundMetadata.remaining_instalment_items,
    current_instalment_item: refundMetadata.current_instalment_item,
    is_pending_deferred_payment: refundMetadata.is_pending_deferred_payment,
    remaining_deferred_payment_items: refundMetadata.remaining_deferred_payment_items,
    current_deferred_payment_item: refundMetadata.current_deferred_payment_item,
    merchant_fee_amount: refundMetadata.merchant_fee_amount,
    variant_of_refund: refundMetadata.variant_of_refund,
  };
}

export type RefundMethods = 'Back To Original' | 'LE Credits' | 'Manual';
export function mapRefundMethodToLabel(refundMethod: RefundMethods) {
  switch (refundMethod) {
    case 'Back To Original': {
      return 'Default';
    }
    case 'LE Credits': {
      return 'Credits';
    }
    case 'Manual': {
      return 'Offline/Manual';
    }
  }
}

export function convertStrOrBoolToBool(value: boolean | string) {
  if (isBoolean(value)) {
    return value;
  }
  return value === 'true';
}

function convertStrOrNumToNum(value: string | number, toFixed = 2) {
  let numberValue: number;
  if (isString(value)) {
    numberValue = parseFloat(value);
  } else {
    numberValue = value;
  }

  return parseFloat(numberValue.toFixed(toFixed));
}

export function buildRefundPayload(refund: RefundPayload): RefundPayload {
  const formattedAccountingMetadata = refund.accounting_metadata.map((metadata) => {
    // there's an edge case where, if you are doing a refund with fee refund
    // and no NOT mark as cancelled
    // we set the refund fee to 0
    const shouldZeroRefundFee =
      convertStrOrNumToNum(metadata.refund_fee) > 0 &&
      !convertStrOrBoolToBool(refund.mark_cancelled) &&
      !metadata.charge_component_key.includes('surcharge');
    return {
      ...metadata,
      cash_amount: convertStrOrNumToNum(metadata.cash_amount),
      refund_fee: shouldZeroRefundFee ? 0 : convertStrOrNumToNum(metadata.refund_fee),
    };
  });

  refund.additional_info.version = REFUND_V2;

  const formattedAdditionalInfo = {
    ...refund.additional_info,
    number_of_nights: refund?.additional_info?.number_of_nights?.toString(),
  };

  if (refund.variant_of_refund === 'partial') {
    /**
     * These `percentage` and `number_of_nights` fields are how much is payable to vendor.
     * In the new UI, we will base it around payable to customer, so we have to inverse the fields.
     */

    // the calculation for vendor_holdback = cost price - (cost price * (nights / total nights))
    // e.g.
    // if refund the customer 3/7 nights
    // we have 4 nights payable to vendor
    // so we set this field to be 4 nights.
    if (formattedAdditionalInfo.number_of_nights) {
      formattedAdditionalInfo.number_of_nights = (
        formattedAdditionalInfo.extra_nights +
        formattedAdditionalInfo.package_nights -
        parseFloat(formattedAdditionalInfo.number_of_nights)
      ).toFixed(0);
    }

    // the calculation for vendor_holdback = cost price - (cost price * percentage)
    // e.g.
    // if refund the customer 90%
    // we have 10% payable to vendor
    // so we want this field to be 10%
    if (formattedAdditionalInfo.percentage) {
      formattedAdditionalInfo.percentage = (100 - convertStrOrNumToNum(formattedAdditionalInfo.percentage, 5)).toFixed(
        5,
      );
    }
  }

  return {
    ...refund,
    send_customer_refund_notification: convertStrOrBoolToBool(refund.send_customer_refund_notification),
    mark_cancelled: convertStrOrBoolToBool(refund.mark_cancelled),
    amount: convertStrOrNumToNum(refund.amount),
    update_vcc_balance: convertStrOrBoolToBool(refund.update_vcc_balance),
    accounting_metadata: formattedAccountingMetadata,
    additional_info: refund.additional_info ? formattedAdditionalInfo : refund.additional_info,
  };
}

/**
 * @param currency AUD
 * @returns $
 */
export function getCurrencySymbol(currency) {
  return (
    new Intl.NumberFormat('en-AU', {
      style: 'currency',
      currency: currency,
    })
      .formatToParts(0)
      .find((part) => part.type === 'currency')?.value || currency
  );
}

export function getVendorContribution(
  refundMetadata: RefundMetadata,
  refund: RefundPayload,
  item?: App.OrderItem,
  customerRefundAmount?: string,
) {
  let vendorContribution = 0;
  const packageCostPrice = getCostPrice(refundMetadata);
  refund.accounting_metadata.forEach((accountingMetadata) => {
    const source = accountingMetadata.source;
    if (
      accountingMetadata.charge_component_key.includes('merchant_fee') ||
      accountingMetadata.charge_component_key.includes('surcharge')
    ) {
      return;
    }

    switch (source) {
      case 'Default': {
        vendorContribution +=
          (accountingMetadata.accounting_amount / refundMetadata.total_sale_price) * packageCostPrice;
        break;
      }
      case 'Custom': {
        let ratio = 0;
        if (refund.additional_info.refund_vendor_agrees_to === 'number') {
          ratio =
            Number(refund.additional_info.number_of_nights) /
            (refund.additional_info.package_nights + refund.additional_info.extra_nights);
        }
        if (refund.additional_info.refund_vendor_agrees_to === 'percentage') {
          ratio = convertStrOrNumToNum(refund.additional_info.percentage, 5) / 100;
        }
        const vendorHoldbackAmount = packageCostPrice - packageCostPrice * ratio;
        vendorContribution += packageCostPrice - vendorHoldbackAmount;
        break;
      }
      case 'TNFH': {
        // no addition to vendor contribution here
        break;
      }
    }
  });

  if (customerRefundAmount) {
    /**
     * We want it to be the smallest of
     * - what the agent typed in
     * - what the cost price is
     * - what the customer refund amount is
     */
    return Math.min(packageCostPrice, parseFloat(customerRefundAmount), vendorContribution);
  }
  return vendorContribution;
}

export function formatModalHeader(step: RefundSteps, refundMetadata: RefundMetadata): string {
  let typeOfItem,
    typeOfStep = '';
  if (refundMetadata?.is_addon) {
    typeOfItem = 'Addon price';
  } else if (refundMetadata?.is_bedbank) {
    typeOfItem = 'Room price';
  } else if (refundMetadata?.is_tour_v2) {
    typeOfItem = 'TourV2 price';
  } else if (refundMetadata?.is_deposit) {
    typeOfItem = 'Refund amount';
  } else if (refundMetadata?.is_instalment) {
    typeOfItem = 'Refund amount';
  } else if (refundMetadata?.is_pending_deferred_payment) {
    typeOfItem = 'Refund amount';
  } else {
    typeOfItem = 'Package price';
  }

  switch (step) {
    case RefundSteps.Reason: {
      typeOfStep = 'Select Type Of Refund';
      break;
    }
    case RefundSteps.Detail: {
      typeOfStep = 'Issue Refund';
      break;
    }
    case RefundSteps.Confirm: {
      typeOfStep = 'Confirm Refund';
      break;
    }
  }

  if (!refundMetadata) {
    return `${typeOfStep}`;
  }
  return `${typeOfStep} - ${typeOfItem} - ${currencyFormatter(
    refundMetadata.currency_code,
    refundMetadata.total_sale_price,
    2,
  )} ${refundMetadata.currency_code}`;
}

export function filterAccountingMetadataByChargeComponentKey(refundPayload, chargeComponentKey: string) {
  const refundPayloadCopy = { ...refundPayload };
  const refundMetaToRemove = refundPayloadCopy.accounting_metadata.find((data) =>
    data.charge_component_key.includes(chargeComponentKey),
  );
  refundPayloadCopy.amount -= refundMetaToRemove.cash_amount;
  refundPayloadCopy.accounting_metadata = refundPayloadCopy.accounting_metadata.filter(
    (data) => !data.charge_component_key.includes(chargeComponentKey),
  );
  return refundPayloadCopy;
}

interface HandleCustomRefundFeeProps {
  inputPayload: RefundPayload;
  refundMetadata: RefundMetadata;
  refundFee: string;
  markCancelled: string;
  shouldSurchargeBeRefunded: string;
}

export function handleCustomFee({
  inputPayload,
  refundFee,
  refundMetadata,
  markCancelled,
  shouldSurchargeBeRefunded,
}: HandleCustomRefundFeeProps) {
  // full refund with fee
  // Guide:
  // [0] = Item Metadata
  // [1] = Surcharge Metadata

  const updatedPayload = { ...inputPayload };
  updatedPayload.accounting_metadata[0].refund_fee = parseFloat(refundFee);

  const updatedCashValue = refundMetadata.item_metadata.cash_amount - parseFloat(refundFee);
  /**
   * If we mark it cancelled, we want the entire amount to be taken from the order, such that vendor pays the full cost price
   * If we don't mark it cancelled, we want to leave some amount in the order and for the vendor to pay a proportional amount
   */
  if (convertStrOrBoolToBool(markCancelled)) {
    updatedPayload.accounting_metadata[0].accounting_amount = refundMetadata.item_metadata.cash_amount;
  } else {
    updatedPayload.accounting_metadata[0].accounting_amount = updatedCashValue;
  }

  updatedPayload.accounting_metadata[0].cash_amount = updatedCashValue;
  let calculatedRefundAmount =
    refundMetadata.item_metadata.cash_amount + (refundMetadata.merchant_fee_amount ?? 0) - parseFloat(refundFee);

  if (refundMetadata.has_surcharge) {
    if (convertStrOrBoolToBool(shouldSurchargeBeRefunded)) {
      calculatedRefundAmount += refundMetadata.surcharge_metadata.cash_amount;
    } else {
      // we keep the surcharge accounting metadata, but charge the full surcharge
      // amount as the refund fee for the mark cancelled case

      updatedPayload.accounting_metadata[1].refund_fee = refundMetadata.surcharge_metadata.cash_amount;
      updatedPayload.accounting_metadata[1].cash_amount = 0;
    }
  }

  updatedPayload.amount = parseFloat(calculatedRefundAmount.toFixed(2));

  updatedPayload.variant_of_refund = VARIANT_OF_REFUND_ALL;

  // unlikely these fields are used, keeping for posterity to match previous implementation
  updatedPayload.additional_info.refund_vendor_agrees_to = VendorRefundTypes.number;
  updatedPayload.additional_info.number_of_nights = refundMetadata.total_nights;

  return updatedPayload;
}

interface HandleVendorPercentageProps {
  inputPayload: RefundPayload;
  percentageRefundToCustomer: string;
  customVendorContribution: string;
  refundMetadata: RefundMetadata;
}
export function handleVendorPercentage({
  inputPayload,
  percentageRefundToCustomer,
  customVendorContribution,
  refundMetadata,
}: HandleVendorPercentageProps) {
  const updatedPayload = { ...inputPayload };
  // select percentage
  const costPrice = getCostPrice(refundMetadata);
  updatedPayload.additional_info.refund_vendor_agrees_to = VendorRefundTypes.percentage;
  updatedPayload.amount = parseFloat(percentageRefundToCustomer);
  updatedPayload.accounting_metadata[0].accounting_amount = parseFloat(percentageRefundToCustomer);
  updatedPayload.accounting_metadata[0].cash_amount = parseFloat(percentageRefundToCustomer);
  if (customVendorContribution) {
    updatedPayload.additional_info.percentage = ((parseFloat(customVendorContribution) / costPrice) * 100).toFixed(5);
    updatedPayload.additional_info.vendor_contribution = parseFloat(customVendorContribution);
  } else {
    // default to 100% to prevent it being NaN when switching between nights/percentage
    updatedPayload.additional_info.percentage = '100';
  }
  // unused, keeping to match existing payload
  updatedPayload.additional_info.number_of_nights = refundMetadata.total_nights;
  return updatedPayload;
}

interface HandleSelectNightsProps {
  inputPayload: RefundPayload;
  totalRefundedToCustomer: string;
  numberOfNights: string;
}

export function handleSelectNights({ inputPayload, totalRefundedToCustomer, numberOfNights }: HandleSelectNightsProps) {
  const updatedPayload = { ...inputPayload };
  updatedPayload.additional_info.refund_vendor_agrees_to = VendorRefundTypes.number;
  updatedPayload.amount = parseFloat(totalRefundedToCustomer);
  updatedPayload.accounting_metadata[0].accounting_amount = parseFloat(totalRefundedToCustomer);
  updatedPayload.accounting_metadata[0].cash_amount = parseFloat(totalRefundedToCustomer);
  updatedPayload.additional_info.number_of_nights = numberOfNights;
  return updatedPayload;
}

export function getCostPrice(refundMetadata: RefundMetadata) {
  const convertedPackageCostPrice =
    refundMetadata.package_cost_price_data?.cost_price_converted_to_sell_currency &&
    convertStrOrNumToNum(refundMetadata.package_cost_price_data.cost_price_converted_to_sell_currency);

  const packageCostPrice =
    refundMetadata.package_cost_price_data?.cost_price &&
    convertStrOrNumToNum(refundMetadata.package_cost_price_data.cost_price);

  let convertedExtraNightsCostPrice = 0;
  let extraNightsCostPrice = 0;

  if (refundMetadata.extra_nights > 0) {
    // Extra nights pricing is per night unlike packages which is the total
    convertedExtraNightsCostPrice =
      refundMetadata.extra_nights_cost_price_data?.cost_price_converted_to_sell_currency &&
      convertStrOrNumToNum(refundMetadata.extra_nights_cost_price_data.cost_price_converted_to_sell_currency);

    extraNightsCostPrice =
      refundMetadata.extra_nights_cost_price_data?.cost_price &&
      convertStrOrNumToNum(refundMetadata.extra_nights_cost_price_data.cost_price);
  }

  const fullCostPrice =
    (convertedPackageCostPrice ?? packageCostPrice) +
    (convertedExtraNightsCostPrice ?? extraNightsCostPrice) * convertStrOrNumToNum(refundMetadata.extra_nights);
  return parseFloat(fullCostPrice.toFixed(2));
}
