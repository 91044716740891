import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import { Container } from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';
import { Port } from '~/components/Cruises/pages/Ports/types';

import portService from '~/services/cruises/PortService';

import PortForm from './components/PortForm';

export default function PortsEditPage() {
  const { portId } = useParams<{ portId: string }>();
  const [port, setPort] = useState<Port | null>(null);

  useEffect(() => {
    portService.getPortById(portId).then((port) => {
      setPort(port);
    });
  }, [portId]);

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>Edit Port</title>
      </Helmet>

      <PageHeader title="Edit Port" backButton="/cruises/ports" />

      {port && <PortForm port={port} />}
    </Container>
  );
}
