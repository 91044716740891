import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Button, FormLabel, Link, Stack, TextField } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams, GridRowsProp } from '@mui/x-data-grid';

import * as LuxLoyaltyService from '~/services/LuxLoyaltyService';
import { formatDateShortDD, isAfter, isBetween } from '~/services/TimeService';

import { EmptyArray, sortBy } from '~/utils/arrayUtils';
import formatDecimal from '~/utils/formatDecimal';

import ErrorDisplay from '../Common/ErrorDisplay';
import { Spinner } from '../Experiences/components/Spinner';

interface Props {
  account: App.LuxLoyaltyAccount;
}

export default function LuxLoyaltyActivityLog({ account }: Props) {
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const [activityLog, setActivityLog] = useState<App.LuxLoyaltyActivityLog>([]);
  const [pendingPoints, setPendingPoints] = useState<Array<App.LuxLoyaltyPendingPoint>>([]);

  const validateDateRange = useCallback<() => boolean>(() => {
    if (!fromDate || !toDate || isAfter(fromDate, toDate)) {
      return false;
    }
    return true;
  }, [fromDate, toDate]);

  const handleSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      try {
        e.preventDefault();
        if (!validateDateRange()) {
          return setError('Please select valid date range');
        }
        setError(null);
        setIsLoading(true);
        const activityLog = await LuxLoyaltyService.getLuxLoyaltyActivityLog(account.customerId, fromDate, toDate);
        setActivityLog(activityLog);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    },
    [account.customerId, fromDate, toDate, validateDateRange],
  );

  const fetchPendingPoints = useCallback(async () => {
    try {
      const pendingPoints = await LuxLoyaltyService.getLuxLoyaltyCustomerPendingPoints(account.customerId);
      setPendingPoints(pendingPoints);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, [account.customerId]);

  useEffect(() => {
    fetchPendingPoints();
  }, [fetchPendingPoints]);

  const rows = useMemo<GridRowsProp<App.LuxLoyaltyActivity>>(() => {
    if (!activityLog.length || !pendingPoints.length || !fromDate || !toDate) {
      return EmptyArray;
    }

    const mergedRows = [
      ...activityLog,
      ...pendingPoints.map((point) => ({
        ...point,
        activityDate: point.createdAt,
        activityType: 'Purchase',
        activityDescription: 'Pending Points',
      })),
    ];

    const rowsInDateRange = mergedRows.filter((row) => {
      return isBetween(fromDate, toDate, row.activityDate);
    });

    return sortBy(rowsInDateRange, (row) => row.activityDate, 'asc');
  }, [activityLog, fromDate, pendingPoints, toDate]);

  const columns = useMemo<Array<GridColDef>>(
    () => [
      {
        field: 'activityDate',
        flex: 1,
        headerName: 'Activity Date',
        sortable: true,
        display: 'flex',
        valueGetter: (value) => formatDateShortDD(value),
      },
      {
        field: 'activityType',
        flex: 1,
        headerName: 'Activity Type',
        sortable: true,
        display: 'flex',
      },
      {
        field: 'activityDescription',
        flex: 1,
        headerName: 'Activity Description',
        sortable: false,
        display: 'flex',
      },
      {
        field: 'statusCredits',
        flex: 1,
        headerName: 'Status Credits',
        sortable: false,
        display: 'flex',
        valueFormatter: (value) => (value ? formatDecimal(value) : undefined),
      },
      {
        field: 'points',
        flex: 1,
        headerName: 'Points',
        sortable: false,
        display: 'flex',
        valueFormatter: (value) => (value ? formatDecimal(value) : undefined),
      },
      {
        field: 'numberOfNights',
        flex: 1,
        headerName: 'Number of Nights',
        sortable: false,
        display: 'flex',
        valueFormatter: (value) => (value ? formatDecimal(value) : undefined),
      },
      {
        field: 'currencyAmount',
        flex: 1,
        headerName: 'Currency Amount',
        sortable: false,
        display: 'flex',
        valueFormatter: (value) => (value ? formatDecimal(value) : undefined),
      },
      {
        field: 'currency',
        flex: 1,
        headerName: 'Currency',
        sortable: false,
        display: 'flex',
        valueGetter: (value, row) => (row.currencyAmount ? value : undefined),
      },
      {
        field: 'orderId',
        flex: 1,
        headerName: 'Order ID',
        sortable: false,
        display: 'flex',
        renderCell: (params: GridRenderCellParams) => (
          <Link target="_blank" href={`/purchases/${params.row.orderId}`}>
            {params.row.orderId}
          </Link>
        ),
      },
      {
        field: 'itemId',
        flex: 1,
        headerName: 'Item ID',
        sortable: false,
        display: 'flex',
      },
    ],
    [],
  );

  return (
    <Stack direction="column" gap={4} flex={1}>
      <Stack direction="row" gap={2}>
        <form onSubmit={handleSubmit}>
          <Stack direction="row" gap={2} sx={{ alignItems: 'flex-end' }}>
            <Stack direction="column" gap={0.5}>
              <FormLabel sx={{ fontWeight: 'bold' }}>From</FormLabel>
              <TextField id="fromDate" type="date" value={fromDate} onChange={(e) => setFromDate(e.target.value)} />
            </Stack>
            <Stack direction="column" gap={0.5}>
              <FormLabel sx={{ fontWeight: 'bold' }}>To</FormLabel>
              <TextField id="toDate" type="date" value={toDate} onChange={(e) => setToDate(e.target.value)} />
            </Stack>
            <Button type="submit" variant="contained">
              Submit
            </Button>
          </Stack>
        </form>
      </Stack>
      {isLoading && <Spinner />}
      {error && <ErrorDisplay message={error} />}
      {!isLoading && !error && <DataGrid rows={rows} columns={columns} autoHeight disableRowSelectionOnClick />}
    </Stack>
  );
}
