import React from 'react';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Button } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';

import { Order } from '@luxuryescapes/contract-svc-order';

const orderLinkFormatter = (params: GridRenderCellParams<Order.Order>) => {
  return (
    <Button
      variant="text"
      size="small"
      href={`/purchases/${params.row.id}`}
      target="_blank"
      rel="noreferrer"
      startIcon={<OpenInNewIcon />}
      title="View order details in new window"
    >
      Details
    </Button>
  );
};

export default orderLinkFormatter;
