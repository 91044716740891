type HistoryItem = {
  created_at: string;
  type: 'login' | 'order' | 'phone_change' | 'user_change';
  object: any;
};

export const combineHistory = (logins: Array<any>, orders: Array<any>, userChanges: Array<any>): Array<HistoryItem> => {
  const loginItems = logins.map((login) => ({
    created_at: login.login_date,
    type: 'login' as const,
    object: login,
  }));

  const orderItems = orders.map((order) => ({
    created_at: order.created_at,
    type: 'order' as const,
    object: order,
  }));

  const userItems = userChanges
    .filter((r) => !r.changed_fields.includes('toggle_name')) // toggles aren't working properly yet so ignore for now
    .map((change) => ({
      created_at: change.change_time,
      type: 'user_change' as const,
      object: change,
    }));

  return [...loginItems, ...orderItems, ...userItems].sort(
    (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
  );
};
