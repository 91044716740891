import React, { useCallback, useState } from 'react';

import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';

import ExpirePromoModal from '~/components/Common/Modals/ExpirePromoModal';

import { isBefore } from '~/services/TimeService';

type ExtraAction = {
  field: string;
  label: string;
  active: boolean;
  level?: 'warning' | 'error';
};

function ActionsCell({
  extraActions,
  promo,
}: {
  extraActions: Array<ExtraAction>;
  promo: App.PromoData & {
    actions?: { expirePromo: (id: string) => Promise<void> };
  };
}) {
  const [showExpireModal, setShowExpireModal] = useState(false);
  const [showExtra, setShowExtra] = useState(false);
  const [selectedExtra, setSelectedExtra] = useState<ExtraAction | null>(null);

  const isExpired = isBefore(promo.expires_at);

  const extraValue: null | string | Array<string> = selectedExtra ? promo[selectedExtra.field] : null;

  const openExtraModal = (e: React.MouseEvent<HTMLButtonElement>, extraAction: ExtraAction) => {
    e.preventDefault();
    e.stopPropagation();

    setSelectedExtra(extraAction);
    setShowExtra(true);
  };

  const closeExtraModal = useCallback(() => {
    setShowExtra(false);
    setSelectedExtra(null);
  }, []);

  const openExpireModal = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setShowExpireModal(true);
  }, []);

  const closeExpireModal = useCallback(() => {
    setShowExpireModal(false);
  }, []);

  const handleExpirePromo = useCallback(
    async (promoId: string) => {
      if (promo.actions?.expirePromo) {
        await promo.actions.expirePromo(promoId);
      }

      setShowExpireModal(false);
    },
    [promo],
  );

  return (
    <>
      <Stack direction="column" spacing={0.5}>
        {isExpired && <Chip label="Expired" size="small" color="secondary" />}
        {!isExpired && (
          <Chip
            component="button"
            label="Expire"
            size="small"
            color="primary"
            variant="outlined"
            onClick={openExpireModal}
          />
        )}

        {extraActions
          .filter((action) => action.active)
          .map((action) => (
            <Chip
              component="button"
              key={action.field}
              label={action.label}
              size="small"
              color={action?.level ?? 'primary'}
              variant="outlined"
              onClick={(e) => openExtraModal(e, action)}
            />
          ))}
      </Stack>

      {showExtra && selectedExtra && (
        <Dialog onClose={closeExtraModal} open>
          <DialogTitle>
            {promo.code_name} - {selectedExtra.label}
          </DialogTitle>

          <DialogContent>
            <DialogContentText>
              {Array.isArray(extraValue) ? (
                <ul>
                  {extraValue.map((value, index) => (
                    <li key={index}>{value}</li>
                  ))}
                </ul>
              ) : (
                extraValue
              )}
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button onClick={closeExtraModal}>Close</Button>
          </DialogActions>
        </Dialog>
      )}

      {showExpireModal && (
        <ExpirePromoModal
          promoId={promo.id_promo_code}
          promoName={promo.code_name}
          closeModal={closeExpireModal}
          expirePromo={handleExpirePromo}
          openModal
        />
      )}
    </>
  );
}

export default function actionsRenderer(params: GridRenderCellParams<App.PromoData, boolean>) {
  const extraActions = [
    {
      field: 'bin_numbers',
      label: 'BIN numbers',
      active: params.row.has_bin_numbers,
    },
    {
      field: 'email_addresses',
      label: 'Email addresses',
      active: params.row.has_email_restriction,
    },
    {
      field: 'email_domains',
      label: 'Email domains',
      active: params.row.has_email_domains_restriction,
    },
    {
      field: 'warning',
      label: 'Warning message',
      active: !!params.row.warning,
    },
  ];

  if (params.row?.promo_warnings && params.row.promo_warnings.length > 0) {
    extraActions.push(
      ...params.row.promo_warnings.map((pw) => ({
        field: pw.code,
        label: pw.code,
        active: true,
        // patch the (now removed) 'none' warning type
        level: pw.level == 'none' ? 'info' : pw.level,
      })),
    );
  }

  return <ActionsCell promo={params.row} extraActions={extraActions} />;
}
