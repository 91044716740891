import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { debounce } from 'lodash';
import capitalize from 'lodash/capitalize';

import {
  Alert,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import { useFlightTraces } from '../hooks/useFlightTraces';

import { FlightsLogViewerListItem } from './FlightsLogsViewerListItem';

const PROVIDERS = ['all', 'amadeus', 'travel fusion', 'sabre', 'unkown'];
const initialFilters = { name: '', provider: 'all' } as const;

interface Props {
  reservationId: string;
}

export function FlightsLogsViewerList({ reservationId }: Props) {
  const [name, setName] = useState('');
  const [provider, setProvider] = useState('all');
  const [filters, setFilters] = useState<{ name: string; provider: string }>(initialFilters);
  const { data, loading, error } = useFlightTraces(reservationId);
  const showList = data && !loading && !error;
  const showEmptyMessage = !loading && !error && data?.length === 0;

  const traces = useMemo(() => {
    return data.filter((data) => {
      let matchingName = true;
      let matchingProvider = true;

      if (filters.name) {
        matchingName = data.name.toLowerCase().includes(filters.name.toLowerCase());
      }

      if (filters.provider && filters.provider !== 'all') {
        matchingProvider = data.provider.toLowerCase() === filters.provider.toLowerCase();
      }

      return matchingName && matchingProvider;
    });
  }, [data, filters]);

  const updateFiltersDebounced = useCallback(
    debounce((name: string, provider: string) => {
      setFilters({ name, provider });
    }, 300),
    [setFilters],
  );

  useEffect(() => {
    updateFiltersDebounced(name, provider);
  }, [name, provider]);

  const clearFilters = useCallback(() => {
    setFilters(initialFilters);
    setName('');
    setProvider('all');
  }, [setFilters, setName, setProvider]);

  return (
    <Stack gap={2}>
      {error && <Alert severity="error">Something went wrong</Alert>}
      {loading && 'Loading...'}
      {showEmptyMessage && 'No data found'}

      {showList && (
        <Stack gap={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography fontWeight="medium" color="gray">
                Filters
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <FormControl fullWidth>
                <TextField
                  id="name"
                  label="Name"
                  variant="outlined"
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  fullWidth
                />
              </FormControl>
            </Grid>

            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel htmlFor="provider">Provider</InputLabel>

                <Select
                  labelId="provider"
                  id="provider"
                  label="Provider"
                  value={provider}
                  onChange={(event) => setProvider(event.target.value)}
                >
                  {PROVIDERS.map((provider) => (
                    <MenuItem key={provider} value={provider}>
                      {capitalize(provider)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={4}>
              <Button onClick={clearFilters}>Clear filters</Button>
            </Grid>
          </Grid>

          <Stack gap={2}>
            {traces.length === 0 && 'No data found. Try clearing the filters'}

            {traces.map((item, index) => (
              <React.Fragment key={item.key}>
                <FlightsLogViewerListItem
                  id={item.key}
                  kind={item.kind}
                  name={item.name}
                  provider={item.provider}
                  lastModified={item.lastModified}
                  size={item.size}
                />
                {index < data.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}
