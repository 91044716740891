type BedbankRoomStatus = 'altered' | 'verified' | 'unverified';

export const getBedbankRoomStatus = (
  itemRooms: Array<App.BedbankItemRoom>,
  roomsInfo: Array<App.Bedbank.ReservationRoomInfo> | null,
): BedbankRoomStatus | null => {
  if (roomsInfo) {
    const rooms = itemRooms.filter((x) => roomsInfo.find((n) => n.id === x.id_reservation_room));

    if (roomsInfo.some((x) => x.isAlteredMappedRoom)) {
      return 'altered';
    }

    const isBeforeVerified = rooms.every((room) => room.is_verified);
    const isAfterVerified = roomsInfo.every((room) => room.isVerifiedRoom);

    if (!isBeforeVerified && isAfterVerified) {
      return 'verified';
    }

    if ((isBeforeVerified && !isAfterVerified) || !isBeforeVerified) {
      return 'unverified';
    }
  }

  return null;
};
