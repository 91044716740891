import React from 'react';

import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';

import { DataGrid } from '@mui/x-data-grid';

import PageHeader from '~/components/Common/Elements/PageHeader';

import { retrievePNRBookingDetails } from '~/services/FlightsService';

import { reportError } from '~/utils/reportError';

import { withTenant } from '../../hoc';

import OrderFlightLeg from './BookingDetails/OrderFlightLeg';
import OrderTravellerDetails from './BookingDetails/OrderTravellerDetails';
import PnrJsonViewer from './BookingDetails/PnrJsonViewer';

class OrderBookingPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      provider: '',
      region: null,
      pnrId: null,
      travellers: [],
      flights: [],
      payments: [],
      bookingRef: '',
      sfpd: '',
      routeType: '',
    };
  }

  async componentDidMount() {
    const { order } = this.props;
    const { region_code: region } = order;
    const flightItem = order?.flight_items?.[0] || {};
    const { provider = '', pnr_id: pnrId = null } = flightItem;
    this.setState({
      provider,
      region,
      pnrId,
    });

    try {
      const pnrResponse = await retrievePNRBookingDetails({
        pnrId,
        region,
        provider,
      });
      const { travellerInfo, originDestinationDetails, tstData } = pnrResponse.result;

      const travellers = this.constructTravellerDetails(travellerInfo);
      const flights = this.constructFlightDetails(originDestinationDetails);
      const payments = this.constructPaymentDetails(tstData);

      this.setState({
        travellers,
        flights,
        payments,
        bookingRef: 'TBD',
        sfpd: 'TBD',
        routeType: 'TBD',
      });
    } catch (error) {
      reportError(error);
    }
  }

  constructTravellerDetails = (travellerInfo) => {
    return travellerInfo.map((traveller) => {
      const enhancedInfo = traveller?.enhancedPassengerData?.[0]?.enhancedTravellerInformation?.[0] || {};
      const { otherPaxNamesDetails = [], travellerNameInfo = [] } = enhancedInfo;

      const title = otherPaxNamesDetails?.[0]?.title?.[0];
      const givenName = otherPaxNamesDetails?.[0]?.givenName?.[0];
      const surname = otherPaxNamesDetails?.[0]?.surname?.[0];
      const type = travellerNameInfo?.[0]?.type?.[0];

      const miscFiels = {
        ticketNo: 'TBD',
        gross: 0,
        taxes: 0,
        net: 0,
        fees: 0,
        baggage: 'TBD',
      };
      return { ...miscFiels, title, givenName, surname, type };
    });
  };

  constructFlightDetails = (originDestinationDetails) => {
    const flights = [];
    originDestinationDetails?.[0]?.itineraryInfo.forEach((itinerary, _itineraryIdx) => {
      const { travelProduct, legInfo } = itinerary;
      const { companyDetail, productDetails } = travelProduct[0];

      legInfo.forEach((leg) => {
        const { flightDate, boardPointDetails, offpointDetails } = leg.legTravelProduct[0];
        const { departureDate, departureTime, arrivalDate, arrivalTime } = flightDate[0];

        const flightDetails = {
          departureLocation: boardPointDetails[0].trueLocationId[0],
          departureDate: departureDate[0],
          departureTime: departureTime[0],
          arrivalLocation: offpointDetails[0].trueLocationId[0],
          arrivalDate: arrivalDate[0],
          arrivalTime: arrivalTime[0],
          aircraftNumber: `${companyDetail[0].identification}${productDetails[0].identification[0]}`,
        };
        flights.push(flightDetails);
      });
    });
    return flights;
  };

  constructPaymentDetails = (tstData) => {
    const payments = [];
    tstData.forEach((tst) => {
      const { fareData } = tst;
      const { monetaryInfo } = fareData[0];

      monetaryInfo.forEach((monetary) => {
        if (monetary.qualifier[0] === 'T') {
          const paymentDetails = {
            currency: monetary.currencyCode[0],
            amount: monetary.amount[0],
            transactionDate: 'TBD',
            method: 'TBD',
            payment_id: 'TBD',
          };
          payments.push(paymentDetails);
        }
      });
    });
    return payments;
  };

  render() {
    const { customer } = this.props;
    const { pnrId, region, provider, travellers, flights, bookingRef, sfpd, routeType } = this.state;

    const { id_orders: orderId } = this.props.match.params;

    return (
      <div className="container order-detail">
        <Helmet>
          <title>Orders | {customer.fullName} | Order Booking Details</title>
        </Helmet>

        <div id="timeline">
          <PageHeader title="Booking details" backButton={`/purchases/${orderId}`} />

          <div className="row">
            <dl className="col-sm-3">
              <dt className="h6 text-uppercase text-muted">LE Booking Reference</dt>
              <dd>{bookingRef}</dd>
            </dl>
            <dl className="col-sm-3">
              <dt className="h6 text-uppercase text-muted">SFPD</dt>
              <dd>{sfpd}</dd>
            </dl>
            <dl className="col-sm-3">
              <dt className="h6 text-uppercase text-muted">Route Type</dt>
              <dd>{routeType}</dd>
            </dl>
          </div>

          <div className="detail-container items-panel-container">
            <div className="offer-details">
              <div>
                <div>
                  <div className="order-header">Departing / Returning Flights</div>
                </div>
              </div>
              <div>
                <OrderFlightLeg flights={flights} />
              </div>
            </div>
          </div>

          <div>
            <div>Traveller Details</div>
            <div>
              <OrderTravellerDetails travellers={travellers} />
            </div>
          </div>
        </div>

        <div>
          <div>Payment Details</div>
          <div>
            <DataGrid
              columns={[
                { field: 'method', headerName: 'Method', width: 150, display: 'flex' },
                { field: 'payment_id', headerName: 'Reference', width: 150, display: 'flex' },
                { field: 'currency', headerName: 'Currency', width: 150, display: 'flex' },
                { field: 'transactionDate', headerName: 'Transaction Date', width: 150, display: 'flex' },
                { field: 'amount', headerName: 'Amount', width: 150, display: 'flex' },
              ]}
              rows={this.state.payments}
              getRowId={(row) => row.payment_id}
              autoHeight
              disableColumnFilter
              disableColumnMenu
              disableRowSelectionOnClick
            />
          </div>
        </div>

        <div>
          <div>Passenger Name Record (PNR) Record</div>
          <div>
            <div className="row">
              <dl className="col-sm-3">
                <dt className="h6 text-uppercase text-muted">Region</dt>
                <dd>{region}</dd>
              </dl>
              <dl className="col-sm-3">
                <dt className="h6 text-uppercase text-muted">Provider</dt>
                <dd>{provider.toUpperCase()}</dd>
              </dl>
              <dl className="col-sm-3">
                <dt className="h6 text-uppercase text-muted">PNR ID</dt>
                <dd>{pnrId}</dd>
              </dl>
            </div>
            <PnrJsonViewer pnrId={pnrId} region={region} provider={provider} />
          </div>
        </div>
      </div>
    );
  }
}

OrderBookingPage.defaultProps = {};

// Exported without router & redux HOC for testing
export const OrderDetailPageComponent = OrderBookingPage;

export default withRouter(withTenant(OrderBookingPage));
