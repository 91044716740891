import React from 'react';

import { Link, useHistory } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Divider, IconButton, MenuItem, Stack, TextField, Typography } from '@mui/material';

import { EmployeeUser } from '~/components/Users/UserPage';

type Props = React.PropsWithChildren<{
  user?: App.User;
  title: React.ReactChild;
  subtitle?: React.ReactChild;
  backButton?: string | boolean;
  color?: string;
  isAdmin?: boolean;
  employeeUserList?: Array<EmployeeUser>;
  updatePreferredAgent?: (agent: string) => void;
}>;

export default function PageHeader({
  user,
  title,
  children,
  subtitle,
  backButton,
  isAdmin,
  employeeUserList,
  updatePreferredAgent,
}: Props) {
  const history = useHistory();

  const backButtonProps = () => {
    if (typeof backButton === 'string') {
      return { to: backButton, component: Link };
    }

    return { onClick: goBack };
  };

  const goBack = () => {
    history.goBack();
  };

  return (
    <Box pt={1} pb={3}>
      <Stack direction="row" alignItems="center" spacing={2} mb={1}>
        {backButton && (
          <IconButton sx={{ border: 1 }} size="small" color="secondary" title="Back" {...backButtonProps()}>
            <ArrowBackIcon />
          </IconButton>
        )}
        {!subtitle && !user && (
          <Typography flex={1} component="h1" fontSize="2.5rem" fontWeight={500}>
            {title}
          </Typography>
        )}
        {!subtitle && user && (
          <Stack direction="column" gap={0.5}>
            <Typography flex={1} component="h1" fontSize="2.5rem" fontWeight={500}>
              {title}
            </Typography>
            <Typography component="h2" fontSize="1.5rem">
              {subtitle}
            </Typography>
            {/* This part is only for the user page, will display the preferred sales agent under the subtitle */}
            {user &&
              (isAdmin ? (
                <Typography component="h2" fontSize="1.5rem">
                  Preferred Sales Agent:{' '}
                  <TextField
                    variant="outlined"
                    value={user.preferred_agent_id}
                    onChange={(e) => updatePreferredAgent(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ displayEmpty: true }}
                    select
                    size="small"
                  >
                    {employeeUserList?.map((item) => (
                      <MenuItem key={item.id_account} value={item.id_account}>
                        {item.full_name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Typography>
              ) : (
                <Typography component="h2" fontSize="1.5rem">
                  Preferred Sales Agent:{' '}
                  {employeeUserList?.find((emp) => emp.id_account === user.preferred_agent_id)?.full_name ?? ''}
                </Typography>
              ))}
          </Stack>
        )}

        {!!subtitle && (
          <Stack direction="column" gap={0.5}>
            <Typography flex={1} component="h1" fontSize="2.5rem" fontWeight={500}>
              {title}
            </Typography>
            <Typography component="h2" fontSize="1.5rem">
              {subtitle}
            </Typography>
            {/* This part is only for the user page, will display the preferred sales agent under the subtitle */}
            {user &&
              (isAdmin ? (
                <Typography component="h2" fontSize="1.5rem">
                  Preferred Sales Agent:{' '}
                  <TextField
                    variant="outlined"
                    value={user.preferred_agent_id}
                    onChange={(e) => updatePreferredAgent(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ displayEmpty: true }}
                    select
                    size="small"
                  >
                    {employeeUserList?.map((item) => (
                      <MenuItem key={item.id_account} value={item.id_account}>
                        {item.full_name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Typography>
              ) : (
                <Typography component="h2" fontSize="1.5rem">
                  Preferred Sales Agent:{' '}
                  {employeeUserList?.find((emp) => emp.id_account === user.preferred_agent_id)?.full_name ?? ''}
                </Typography>
              ))}
          </Stack>
        )}

        {children && <Box>{children}</Box>}
      </Stack>

      <Divider />
    </Box>
  );
}
