import React from 'react';

import DescriptionIcon from '@mui/icons-material/Description';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';

import PageBody from '../PageBody';
import PageHeaderBar from '../PageHeaderBar';
import PageLayout from '../PageLayout';
import PageSidebar from '../PageSidebar';

function ExamplePage() {
  return (
    <PageLayout>
      <PageHeaderBar
        title="Example page"
        actions={
          <>
            <Button>Action A</Button>
            <Button>Action B</Button>
            <Button>Action C</Button>
          </>
        }
      />
      <PageSidebar placement="start">
        <List>
          <ListItemButton>
            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            <ListItemText primary="Item A" />
          </ListItemButton>
          <ListItemButton>
            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            <ListItemText primary="Item B" />
          </ListItemButton>
          <ListItemButton>
            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            <ListItemText primary="Item C" />
          </ListItemButton>
        </List>
        <List subheader="Sub-list">
          <ListItemButton>
            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            <ListItemText primary="Sub-Item A" />
          </ListItemButton>
          <ListItemButton>
            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            <ListItemText primary="Sub-Item B" />
          </ListItemButton>
          <ListItemButton>
            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            <ListItemText primary="Sub-Item C" />
          </ListItemButton>
        </List>
      </PageSidebar>
      <PageSidebar placement="end">
        <Card variant="elevation">
          <CardContent>
            <Typography gutterBottom>Tagline</Typography>
            <Typography variant="h5" component="div">
              Complementary content
            </Typography>
            <Typography variant="body2">Some important information on the side</Typography>
          </CardContent>
          <CardActions>
            <Button size="small">More action</Button>
          </CardActions>
        </Card>
      </PageSidebar>
      <PageBody>
        <Container>Content stuff</Container>
      </PageBody>
    </PageLayout>
  );
}

export default ExamplePage;
