import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { theme } from '~/theme';

import { AutoGraph } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, MenuItem, Select } from '@mui/material';
import { DataGrid, GridColDef, GridExpandMoreIcon } from '@mui/x-data-grid';

import { definitions } from '@luxuryescapes/contract-svc-promo';
import * as libRegions from '@luxuryescapes/lib-regions';

import CopyableField from '~/components/Common/CopyableField';
import PageSubheader from '~/components/Common/Elements/PageSubheader';
import DebugModal from '~/components/DebugModal/DebugModal';
import UserAndOrderId from '~/components/PromoV3/UserAndOrderId';

import { DEFAULT_PAGE_SIZES } from '~/consts/filters';

import { PromoFailureFilters, PromoMeta, getFilterTitle } from '~/services/PromoService';
import { formatDateLongMonthWithMeridiem, formatDateShort } from '~/services/TimeService';

import GridPagination from '../../Common/Elements/GridPagination';
import Spinner from '../../Common/Spinner';
import PromoTesterLink from '../PromoTester/PromoTesterLink';
import { PromoCodeName } from '../formatters/PromoCodeNameFormatter';
import usePromoFailures from '../hooks/usePromoFailures';

const sharedAttrs: Pick<GridColDef, 'sortable' | 'display' | 'flex'> = {
  sortable: false,
  display: 'flex',
  flex: 1,
};

const getColumns = (): Array<GridColDef> => [
  {
    field: 'created_at',
    valueFormatter: (value) => formatDateShort(value),
    renderCell: (params) => (
      <Box title={formatDateLongMonthWithMeridiem(params.row.created_at)}>{formatDateShort(params.row.created_at)}</Box>
    ),
    headerName: 'Logged at',
    ...sharedAttrs,
  },
  {
    field: 'region',
    headerName: 'Region',
    ...sharedAttrs,
  },
  {
    field: 'code_name',
    renderCell: (params) => PromoCodeName({ codeName: params.row.code_name }),
    headerName: 'Code',
    ...sharedAttrs,
  },
  {
    field: 'User/Order',
    renderCell: (params) => UserAndOrderId({ orderId: params.row.id_order, userId: params.row.user_id }),
    ...sharedAttrs,
  },
  {
    field: 'error_level',
    headerName: 'Error Level',
    ...sharedAttrs,
  },
  {
    field: 'promo_failure_error_code',
    headerName: 'Error Code',
    ...sharedAttrs,
  },
  {
    field: 'error_message',
    headerName: 'Error Msg',
    ...sharedAttrs,
    flex: 4,
  },
  {
    field: 'debug',
    headerName: 'Raw',
    renderCell: (params) => (
      <CopyableField
        value={JSON.stringify(params.row, null, 4)}
        label={<DebugModal type="generic" data={params.row ?? { data: 'None' }} />}
      />
    ),
    ...sharedAttrs,
  },
  {
    field: 'reSimulate',
    headerName: 'Re-Sim',
    renderCell: (params) =>
      params.row.order_id
        ? PromoTesterLink({
            codeName: params.row.code_name,
            orderId: params.row.order_id,
          })
        : 'N/A',
    ...sharedAttrs,
    flex: 0,
  },
];

interface Props {
  initCodeName?: string;
  initUserId?: string;
  initPage?: number;
  initLimit?: number;
  brand: App.Brands | string;
  handleUserIdFilterSet?: (userId: string) => void;
  promoMeta: PromoMeta;
  defaultExpanded?: boolean;
}

const DEFAULT_PAGE_SIZE = 10;

function PromoFailures({ initCodeName, initUserId, initLimit, initPage, brand, defaultExpanded = false }: Props) {
  const { promoFailures, totalRows, isLoading, promoFailuresFilter, promoFailureMetrics, setPromoFailuresFilter } =
    usePromoFailures({
      initPage,
      initLimit: initLimit ?? DEFAULT_PAGE_SIZE,
      initCodeName,
      brand: brand.toString(),
      initUserId,
    });
  const [loadMetrics, setLoadMetrics] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const regions = libRegions.getRegions();

  useEffect(() => {
    setPromoFailuresFilter({
      ...promoFailuresFilter,
      code_name: initCodeName,
      brand: brand as definitions['Promo Brands'],
      user_id: initUserId,
    });
  }, [initCodeName, initUserId, brand, initPage, setPromoFailuresFilter]);

  const onPageChange = async (page, pageSize) => {
    setPromoFailuresFilter({
      ...promoFailuresFilter,
      page,
      limit: pageSize,
    });
  };

  const handleLoadMetrics = () => {
    setLoadMetrics(!promoFailuresFilter.load_metrics);
    setPromoFailuresFilter({
      ...promoFailuresFilter,
      load_metrics: !promoFailuresFilter.load_metrics,
    });
  };

  const handleSetErrorType = (errorType: string) => {
    if (!['all', 'error', 'warning'].some((t) => t == errorType)) {
      enqueueSnackbar('Something went wrong (handleSetErrorType wrong type)', { variant: 'error' });
      return;
    }
    setLoadMetrics(!promoFailuresFilter.load_metrics);
    setPromoFailuresFilter({
      ...promoFailuresFilter,
      page: 0,
      error_level: errorType as PromoFailureFilters['error_level'],
    });
  };

  const handleSetRegion = (regionCode: string) => {
    setLoadMetrics(!promoFailuresFilter.load_metrics);
    setPromoFailuresFilter({
      ...promoFailuresFilter,
      page: 0,
      region: regionCode as definitions['Promo Regions'],
    });
  };

  const filterTitle = getFilterTitle({
    prefix: `${totalRows} Promo Failures`,
    codeName: promoFailuresFilter.code_name,
    userId: initUserId,
  });

  if (isLoading) {
    return <Spinner size={36} />;
  }

  return (
    <Accordion disableGutters defaultExpanded={defaultExpanded}>
      <AccordionSummary
        expandIcon={<GridExpandMoreIcon />}
        aria-controls="offer-fields"
        id="offer-fields"
        sx={{
          backgroundColor: theme.palette.grey[200],
        }}
      >
        <PageSubheader title={filterTitle} />
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          <Select
            value={promoFailuresFilter.error_level}
            onChange={(e) => handleSetErrorType(e.target.value)}
            label="Error Level"
            title="Filter by error level"
          >
            <MenuItem value="all">Errors & Warnings</MenuItem>
            <MenuItem value="error">Errors</MenuItem>
            <MenuItem value="warning">Warnings</MenuItem>
          </Select>
          <Select
            value={promoFailuresFilter.region}
            onChange={(e) => handleSetRegion(e.target.value)}
            label="Region"
            title="Region"
            placeholder="Region"
          >
            <MenuItem value="">All</MenuItem>
            {regions.length > 0 &&
              regions.map((region) => {
                return (
                  <MenuItem key={region.code} value={region.code}>
                    {`${region.name} (${region.currencyCode})`}
                  </MenuItem>
                );
              })}
          </Select>
          {!promoFailuresFilter.load_metrics && (
            <Button
              variant="contained"
              onClick={handleLoadMetrics}
              sx={{ padding: '0 16px' }}
              title={loadMetrics ? 'Hide extended info' : 'Show extended info'}
            >
              <AutoGraph />
            </Button>
          )}
          {promoFailureMetrics && <DebugModal type="generic" title={filterTitle} data={promoFailureMetrics} />}
        </Box>
        {!isLoading ? (
          <DataGrid
            columns={getColumns()}
            autoHeight
            getRowId={(row) => row.uniqueKey}
            rowCount={totalRows}
            rows={promoFailures || []}
            pagination
            paginationMode="server"
            paginationModel={{ page: promoFailuresFilter.page, pageSize: promoFailuresFilter.limit }}
            onPaginationModelChange={({ page, pageSize }) => onPageChange(page, pageSize)}
            slots={{ pagination: GridPagination }}
            pageSizeOptions={DEFAULT_PAGE_SIZES}
            getRowHeight={() => 'auto'}
          />
        ) : (
          <Spinner size={36} />
        )}
      </AccordionDetails>
    </Accordion>
  );
}

export default PromoFailures;
