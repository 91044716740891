import React from 'react';

import { ExpandMore } from '@mui/icons-material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from '@mui/material';
import { common, green, grey, red } from '@mui/material/colors';

import { humanize } from '~/utils/humanize';

interface Props {
  keyName: string;
  value: unknown;
  depth?: number;
}

function formatValue(value: unknown) {
  if (typeof value === 'boolean') {
    return value ? <CheckBoxIcon sx={{ color: green[300] }} /> : <DisabledByDefaultIcon sx={{ color: red[300] }} />;
  }

  const valueString: string = Array.isArray(value)
    ? value.map((v) => JSON.stringify(v)).join(', ')
    : JSON.stringify(value);

  return <Typography fontFamily="monospace"> {valueString} </Typography>;
}

// Recursively generate the accordion based on the type of value
// If value is a primitive/array -> directly display the value
// Else display the object as a nested accordion
export default function NestedAccordion({ keyName, value, depth = 0 }: Props) {
  if (typeof value !== 'object' || value === null || Array.isArray(value)) {
    return (
      <Stack sx={{ py: 1, background: depth % 2 == 1 ? grey[200] : common['white'] }} paddingLeft={2}>
        <Typography fontWeight="bold" alignItems="center" display="flex" gap={1}>
          {humanize(keyName)}: {formatValue(value)}
        </Typography>
      </Stack>
    );
  }

  return (
    <Accordion
      sx={{
        '&:before': { height: '0px' } /* remove the line before the accordion*/,
        background: depth % 2 == 1 ? grey[200] : common['white'],
        paddingLeft: 2,
      }}
      disableGutters
      defaultExpanded
    >
      <AccordionSummary expandIcon={<ExpandMore />} sx={{ margin: 0, padding: 0 }}>
        <Typography fontWeight="bold">{humanize(keyName)}</Typography>
      </AccordionSummary>

      <AccordionDetails sx={{ margin: 0, padding: 0 }}>
        {Object.entries(value).map(([childKey, childValue]) => (
          <Stack sx={{ paddingLeft: 3 }} key={childKey}>
            <NestedAccordion key={childKey} keyName={childKey} value={childValue} depth={depth + 1} />
          </Stack>
        ))}
      </AccordionDetails>
    </Accordion>
  );
}
