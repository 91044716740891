import React, { useMemo } from 'react';

import { Alert } from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';

import { FlightsCampaignsTableActionButtons } from './FlightsCampaignsTableActionButtons';

const common: Partial<GridColDef> = {
  flex: 1,
};

interface Props {
  data: Array<App.FlightCampaign>;
  loading?: boolean;
  error?: string;
  onDelete: (id: string) => void;
}

export function FlightsCampaignsTable({ data, loading, error, onDelete }: Props) {
  const columns: Array<GridColDef> = useMemo(() => {
    return [
      {
        ...common,
        field: 'id',
        headerName: 'Id',
      },
      {
        ...common,
        field: 'title',
        headerName: 'Title',
      },
      {
        ...common,
        field: 'type',
        headerName: 'Type',
      },
      {
        ...common,
        field: 'header',
        headerName: 'Header',
      },
      {
        ...common,
        field: 'region',
        headerName: 'Region',
      },
      {
        ...common,
        field: 'start_date',
        headerName: 'Start Date',
      },
      {
        ...common,
        field: 'end_date',
        headerName: 'End Date',
      },
      {
        ...common,
        field: 'brand',
        headerName: 'Brand',
        renderCell: (row) => {
          return (row.value as string)?.split(',').join(', ');
        },
      },
      {
        field: 'actions',
        headerName: 'Actions',
        renderCell: ({ row }) => <FlightsCampaignsTableActionButtons row={row} onDelete={onDelete} />,
        sortable: false,
      },
    ];
  }, [onDelete]);

  if (error) {
    return <Alert severity="error">Something went wrong</Alert>;
  }

  return (
    <DataGridPro
      columns={columns}
      loading={loading}
      rows={data}
      disableRowSelectionOnClick
      disableColumnMenu
      density="standard"
      initialState={{ pinnedColumns: { right: ['actions'] } }}
    />
  );
}
