import { request } from './common';

function basePath() {
  return window.configs.API_HOST + '/api/';
}

export async function getDetails(orderId, withoutPassportPhoto = false, submittedOnly = true) {
  const response = await request(
    `${basePath()}traveller/tour-details/${orderId}?submitted_only=${submittedOnly}&without_passport_photo=${withoutPassportPhoto}`,
    { method: 'GET' },
  );

  if (response.result && response.result.travellers) {
    return response.result.travellers;
  }

  return [];
}

export async function updateDetails(object) {
  return request(basePath() + 'traveller/tour-details', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(object),
  });
}

export async function getTravellerDetailRequirements(offerId) {
  return request(basePath() + `traveller/traveller-requirements/${offerId}`, {
    method: 'GET',
  });
}

export async function getSvcExperienceSyncData(packageId) {
  return request(basePath() + `experiences/offers?bundledPackageId=${packageId}&showUnlisted=true`, {
    method: 'GET',
  });
}

export async function getTravellerDetailRequirementsSchema(offerId) {
  return request(basePath() + `traveller/traveller-requirements/${offerId}`, {
    method: 'OPTIONS',
  });
}

export async function createOrUpdateTravellerDetailRequirements(offerId, data) {
  return request(basePath() + `traveller/traveller-requirements/${offerId}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  });
}
