import React, { forwardRef, useCallback, useState } from 'react';

import { useSnackbar } from 'notistack';

import AddIcon from '@mui/icons-material/Add';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, IconButton, Paper, Stack, TextField } from '@mui/material';
import { GridColDef, GridEventListener, GridRenderCellParams, GridRowModel, GridRowsProp } from '@mui/x-data-grid-pro';

import useImageUrlGenerator from '~/hooks/useImageUrlGenerator';

import { uploadImage } from '~/services/ImageService';

import { DynamicSectionType } from '../../context/useDynamicSections';

import ImageUploadBox from './Inputs/ImageUploadBox';
import StyledDataGrid from './StyledDataGrid';

export enum SCHEDULE_TRENDING_DESTINATION_FORM_NAMES {
  HEADING = 'trending_destination_section_heading',
  DESTINATIONS = 'trending_destination_section_destinations',
}

interface DestinationRow {
  id: string;
  name: string;
  imageId: string;
  imageFile?: File;
  redirectUrl: string;
}

interface Props {
  existingHeading?: string;
  existingDestinations?: Array<DestinationRow>;
}

const defaultGridRowIdGetter = (row: DestinationRow) => row.id;
const defaultRowHeightGetter = () => 100 as const;
const defaultDetailPanelHeightGetter = () => 'auto' as const;

export function parseTrendingDestinationFormData(formData: FormData, position = -1) {
  const destinationsData = formData.get(SCHEDULE_TRENDING_DESTINATION_FORM_NAMES.DESTINATIONS);
  const parsedDestinations: Array<DestinationRow> = destinationsData ? JSON.parse(String(destinationsData)) : [];
  const destinations = [];
  for (const destination of parsedDestinations) {
    destinations.push({
      name: destination.name,
      imageId: destination.imageId,
      redirectUrl: destination.redirectUrl,
    });
  }
  return {
    type: DynamicSectionType.TRENDING_DESTINATION,
    heading: String(formData.get(SCHEDULE_TRENDING_DESTINATION_FORM_NAMES.HEADING)),
    destinations,
    position,
  };
}

const ScheduleTrendingDestinationSectionForm = forwardRef<HTMLFormElement, Props>((props, ref) => {
  const { existingHeading = '', existingDestinations = [] } = props;
  const [heading, setHeading] = useState(existingHeading);
  const [rows, setRows] = useState<GridRowsProp>(existingDestinations);
  const { enqueueSnackbar } = useSnackbar();

  const handleAddClick = useCallback(() => {
    const id = Date.now().toString();
    setRows((oldRows) => [
      ...oldRows,
      {
        id,
        name: '',
        imageId: '',
        redirectUrl: '',
      },
    ]);
  }, []);

  const handleOrderChange = useCallback<GridEventListener<'rowOrderChange'>>((params) => {
    setRows((currRows) => {
      const clonedRows = [...currRows];
      clonedRows.splice(params.oldIndex, 1);
      clonedRows.splice(params.targetIndex, 0, params.row);
      return clonedRows;
    });
  }, []);

  const deleteRow = useCallback<React.MouseEventHandler<HTMLButtonElement>>((event) => {
    event.preventDefault();
    const destinationId = String(event.currentTarget.dataset['destinationId']);

    setRows((currRows) => {
      const clonedRows = [...currRows];
      const foundIndex = currRows.findIndex((i) => i.id === destinationId);
      clonedRows.splice(foundIndex, 1);
      return clonedRows;
    });
  }, []);

  const handleProcessRowUpdate = useCallback((newRow: GridRowModel) => {
    setRows((prevRows) => prevRows.map((row) => (row.id === newRow.id ? { ...row, ...newRow } : row)));
    return newRow;
  }, []);

  const handleUploadImage = useCallback(
    async (rowId: string) => {
      const row = rows.find((r) => r.id === rowId);
      if (!row?.imageFile) {
        enqueueSnackbar('No image file available to upload', { variant: 'warning' });
        return;
      }

      try {
        const response = await uploadImage(row.imageFile);
        setRows((prevRows) =>
          prevRows.map((r) =>
            r.id === rowId
              ? {
                  ...r,
                  imageId: response.body.public_id,
                  imageFile: undefined,
                }
              : r,
          ),
        );
        enqueueSnackbar('Image uploaded successfully', { variant: 'success' });
      } catch (error) {
        enqueueSnackbar('Failed to upload image', { variant: 'error' });
      }
    },
    [rows, enqueueSnackbar],
  );

  const columns = useCallback<() => Array<GridColDef>>(
    () => [
      {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        editable: true,
      },
      {
        field: 'imageId',
        headerName: 'Image ID',
        flex: 1,
        sortable: false,
        editable: true,
      },
      {
        field: 'imageUpload',
        headerName: 'Image Upload',
        flex: 1,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
          const imageUrl = useImageUrlGenerator(params.row.imageId);

          return (
            <ImageUploadBox
              id={`destination-image-${params.id}`}
              label="Image"
              mini
              imageUrl={
                params.row.imageId
                  ? imageUrl
                  : params.row.imageFile
                  ? URL.createObjectURL(params.row.imageFile)
                  : undefined
              }
              name={`destination-image-${params.id}`}
              onUpload={(file) => {
                setRows((prevRows) =>
                  prevRows.map((row) =>
                    row.id === params.id
                      ? {
                          ...row,
                          imageFile: file,
                          imageId: '',
                        }
                      : row,
                  ),
                );
              }}
              onRemove={() => {
                setRows((prevRows) =>
                  prevRows.map((row) =>
                    row.id === params.id
                      ? {
                          ...row,
                          imageFile: undefined,
                          imageId: '',
                        }
                      : row,
                  ),
                );
              }}
            />
          );
        },
      },
      {
        field: 'redirectUrl',
        headerName: 'Redirect URL',
        flex: 1,
        editable: true,
      },
      {
        field: 'Actions',
        headerName: '',
        flex: 0.1,
        sortable: false,
        renderCell: (params: GridRenderCellParams<DestinationRow, DestinationRow['id']>) => (
          <Stack direction="column">
            {params.row.imageFile && !params.row.imageId && (
              <IconButton size="small" onClick={() => handleUploadImage(params.row.id)} color="primary">
                <CloudUploadIcon />
              </IconButton>
            )}
            <IconButton size="small" data-destination-id={params.row.id} onClick={deleteRow}>
              <DeleteIcon />
            </IconButton>
          </Stack>
        ),
      },
    ],
    [deleteRow, handleUploadImage],
  );

  return (
    <Stack component="form" ref={ref} spacing={3}>
      <input type="hidden" name={SCHEDULE_TRENDING_DESTINATION_FORM_NAMES.DESTINATIONS} value={JSON.stringify(rows)} />

      <TextField
        fullWidth
        label="Section Heading"
        name={SCHEDULE_TRENDING_DESTINATION_FORM_NAMES.HEADING}
        value={heading}
        onChange={(e) => setHeading(e.target.value)}
      />

      <Stack spacing={2}>
        <Paper variant="outlined">
          <StyledDataGrid
            rows={rows}
            columns={columns()}
            getRowId={defaultGridRowIdGetter}
            autoHeight
            disableRowSelectionOnClick
            rowReordering
            onRowOrderChange={handleOrderChange}
            getRowHeight={defaultRowHeightGetter}
            getDetailPanelHeight={defaultDetailPanelHeightGetter}
            processRowUpdate={handleProcessRowUpdate}
            density="compact"
            disableColumnMenu
            disableColumnFilter
            disableColumnSelector
            hideFooter
          />
        </Paper>
        <Button startIcon={<AddIcon />} onClick={handleAddClick} variant="outlined" sx={{ alignSelf: 'flex-start' }}>
          Add Destination
        </Button>
      </Stack>
    </Stack>
  );
});

ScheduleTrendingDestinationSectionForm.displayName = 'ScheduleTrendingDestinationSectionForm';
export default ScheduleTrendingDestinationSectionForm;
