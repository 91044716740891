import React from 'react';

import { Box, Typography } from '@mui/material';

import useCurrentUser from '~/hooks/useCurrentUser';

import { formatDateWeekDayShortMonth } from '~/services/TimeService';

const isNewDay = (idx: number, date: string, recentUsers: Array<any>) => {
  if (idx === 0) {
    return true;
  }
  const previousDate = new Date(recentUsers[idx - 1].date);
  const currentDate = new Date(date);
  return previousDate.toDateString() !== currentDate.toDateString();
};

export default function RecentUsers() {
  const savedUsers = localStorage.getItem('recentlyViewedUsers');
  const recentUsers = savedUsers ? JSON.parse(savedUsers) : [];
  const { user } = useCurrentUser();

  return (
    <Box>
      <Typography variant="h4">Recently viewed users</Typography>
      <a href={`/users/${user?.id_member}`}>{user?.email}</a> (you)
      {recentUsers.map((user, idx) => (
        <Box key={user.id}>
          {isNewDay(idx, user.date, recentUsers) && (
            <Typography component="pre" variant="body2" sx={{ marginTop: 2, fontFamily: 'monospace' }}>
              {formatDateWeekDayShortMonth(user.date)}
            </Typography>
          )}
          <a href={`/users/${user.id}`}>user</a> &nbsp;{' '}
          <a href={`/purchases/section/allOrders?customer_id=${user.id}`}>orders</a> &nbsp;{' '}
          <a href={`/users/${user.id}/history`}>history</a> &nbsp; {user.label}
        </Box>
      ))}
    </Box>
  );
}
