import { paths as CustomerCommunicationPaths } from '@luxuryescapes/contract-customer-communication';

import { request } from '~/services/common';

import { makeCustomerCommunicationV1URL } from './customerCommunicationRequestUtils';

type HeroPlannerScheduleEmailStatusPutContract =
  CustomerCommunicationPaths['/api/customer-communication/v1/todays-escapes-schedule/hero-planner/schedules/{scheduleId}/email/status/{statusId}']['put'];

async function requestPutHeroPlannerScheduleEmailStatus(
  scheduleId: HeroPlannerScheduleEmailStatusPutContract['parameters']['path']['scheduleId'],
  statusId: HeroPlannerScheduleEmailStatusPutContract['parameters']['path']['statusId'],
  segment?: string,
) {
  if (!scheduleId) {
    throw 'missing scheduleId';
  }
  if (!statusId) {
    throw 'missing statusId';
  }

  const url = makeCustomerCommunicationV1URL(
    `/todays-escapes-schedule/hero-planner/schedules/${scheduleId}/email/status/${statusId}`,
  );
  if (segment === 'DESTINATION') {
    url.searchParams.set('type', segment);
  }

  return request(url.toString(), {
    method: 'PUT',
    credentials: 'include',
  })
    .then((response: HeroPlannerScheduleEmailStatusPutContract['responses']['200']['content']['application/json']) => {
      return response.result.emailStatus;
    })
    .catch((error) => {
      throw JSON.stringify(error, null, 2);
    });
}

export default requestPutHeroPlannerScheduleEmailStatus;
