import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

import { LoadingButton } from '@mui/lab';
import { Alert, Button, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography } from '@mui/material';

import UsersService from '~/services/UsersService';

import { Step } from './VerifyCustomerButtons';

const STEP_SUCCESS = 'SUCCESS';

interface Props {
  onClose: () => void;
  setCurrentStep: (step: Step) => void;
  phonePrefix: string;
  phone: string;
}

export default function EnterCodeModal({ onClose, setCurrentStep, phonePrefix, phone }: Props) {
  const brand = useSelector((state: App.State) => state.tenant.brand);
  const { enqueueSnackbar } = useSnackbar();

  const [code, setCode] = useState('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [countdown, setCountdown] = useState(30);

  const isValidCode = /^\d{6}$/.test(code);

  useEffect(() => {
    if (countdown > 0) {
      const timer = setInterval(() => {
        setCountdown((curr) => Math.max(curr - 1, 0));
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [countdown]);

  const handleVerify = async () => {
    setErrorMessage(null);

    if (!isValidCode) {
      setErrorMessage('Please enter 6 digits only');
      return;
    }

    setIsProcessing(true);

    try {
      await UsersService.validateOtpSmsCode(phonePrefix, phone, code, brand);

      setCurrentStep(STEP_SUCCESS);
    } catch (error) {
      setErrorMessage(error.message ?? 'Unknown Error');
    } finally {
      setIsProcessing(false);
    }
  };

  const handleResendCode = async () => {
    setErrorMessage(null);
    setCountdown(30);

    setIsProcessing(true);

    try {
      await UsersService.sendOtpSmsCode(phonePrefix, phone, brand);

      enqueueSnackbar('Code sent to customer', { variant: 'success' });
    } catch (error) {
      setErrorMessage(error.message ?? 'Unknown Error');
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <>
      <DialogTitle>Enter Verification Code</DialogTitle>

      {errorMessage && (
        <Alert severity="error" sx={{ mx: 3, my: 1 }}>
          {errorMessage}
        </Alert>
      )}

      <DialogContent>
        <Typography variant="body1" gutterBottom>
          The customer has received a text on their phone. Please ask them to read it to you out loud. If they haven't
          received the SMS, try resending it below.
        </Typography>
        <TextField
          fullWidth
          label="Verification Code"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          margin="dense"
        />
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={onClose} disabled={isProcessing}>
          Cancel
        </Button>
        <Stack direction="row" gap={1}>
          <LoadingButton
            onClick={handleResendCode}
            variant="outlined"
            loading={isProcessing}
            disabled={countdown > 0 || isProcessing}
          >
            {countdown > 0 ? `Resend Code (${countdown}s)` : 'Resend Code'}
          </LoadingButton>
          <LoadingButton
            onClick={handleVerify}
            variant="outlined"
            loading={isProcessing}
            disabled={!code || isProcessing}
          >
            Verify
          </LoadingButton>
        </Stack>
      </DialogActions>
    </>
  );
}
