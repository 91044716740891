// component that shows the VCC history for a car hire item
import React, { useCallback, useEffect, useState } from 'react';

import { Box, Typography } from '@mui/material';

import * as carHireService from '~/services/CarHireService';

import Spinner from '../../../Common/Spinner';

export default function CarHireOrderVccDetails({ carHireItem }) {
  const [vccDetails, setVccDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  const loadVCCHistory = useCallback(async () => {
    try {
      const response = await carHireService.getVCCHistory(carHireItem.id);
      setVccDetails(response.result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error loading VCC history:', error);
    }
  }, [carHireItem.id]);

  useEffect(() => {
    loadVCCHistory();
  }, [carHireItem.id_reservation, loadVCCHistory]);

  return (
    <Box mt={2}>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Box component="ul" sx={{ listStyle: 'none', p: 0, m: 0 }}>
            <Typography component="li" sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography fontWeight="bold">ID: </Typography> {vccDetails?.id}
            </Typography>
            <Typography component="li" sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography fontWeight="bold">PAN: </Typography> {vccDetails?.pan}
            </Typography>
            <Typography component="li" sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography fontWeight="bold">Expiry: </Typography> {vccDetails?.expiry}
            </Typography>
            <Typography component="li" sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography fontWeight="bold">Limit: </Typography> {vccDetails?.limit}
            </Typography>
            <Typography component="li" sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography fontWeight="bold">Balance: </Typography> {vccDetails?.balance}
            </Typography>
            <Typography component="li" sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography fontWeight="bold">Valid from: </Typography> {vccDetails?.validityFrom}
            </Typography>
            <Typography component="li" sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography fontWeight="bold">Valid to: </Typography> {vccDetails?.validityTo}
            </Typography>
            <Typography component="li">
              <Typography fontWeight="bold">Custom fields:</Typography>
              <ul>
                {vccDetails?.customFields && Object.entries(vccDetails.customFields).map(([key, value]) => (
                    <li key={key}>
                      <strong>{key}:</strong> {value}
                    </li>
                ))}
              </ul>
            </Typography>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography variant="h6">History</Typography>
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
              <thead>
                <tr>
                  <th style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'left' }}>ID</th>
                  <th style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'left' }}>Card ID</th>
                  <th style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'left' }}>Old State</th>
                  <th style={{ padding: '8px', borderBottom: '1px solid #ddd', textAlign: 'left' }}>New State</th>
                </tr>
              </thead>
              <tbody>
                {vccDetails?.history.map((history) => (
                  <tr key={history.id}>
                    <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{history.id}</td>
                    <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{history.cardId}</td>
                    <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{history.oldState}</td>
                    <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{history.newState}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Box>
        </>
      )}
    </Box>
  );
}
