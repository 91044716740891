import qs from 'qs';

import { actions, definitions } from '@luxuryescapes/contract-svc-accommodation';

import { json_headers, request } from './common';
import { HttpResponse } from './types';

export type PropertyResponse = definitions['AdminProperty'];
export type Room = definitions['AdminRoomDetail'];
export type PropertyDetailResponse = definitions['AdminPropertyDetail'];
export type ProgressStatusResponse = definitions['StatusResponse'];
export type MappedInternalProperty = definitions['AdminMappedInternalProperty'];
export type MappedInternalPropertyWithOffers = definitions['AdminMappedInternalPropertyWithOffers'];
export type InternalProperty = definitions['AdminInternalProperty'];
export type InternalService = definitions['InternalService'];
export type RoomResponse = definitions['AdminRoom'];
export type InternalRoom = definitions['AdminInternalRoom'];
export type SupplierRoom = definitions['AdminSupplierRoom'];
export type MappedInternalRoom = definitions['AdminMappedInternalRoom'];
export type RoomMappingResponse = definitions['AdminPropertyRoomMappings'];
export type RoomMapping = definitions['AdminRoomMapping'];
export type ExtendedMappedInternalRoom = MappedInternalRoom & { roomId: string };
export type RoomMappingPayload = actions['postAdminPropertyRoomMappings']['parameters']['body']['payload'];
export type ValidationErrorCodes = definitions['ValidationErrorCodes'];
export type ValidationErrorDetails = definitions['ValidationErrorDetails'];
export type VerifyMappedRoomPayload = actions['postAdminVerifyRoomMappings']['parameters']['body']['payload'];
export type SourceModel = definitions['SourceModel'];
export type TargetModel = definitions['TargetModel'];
export type MappingLog = definitions['AdminMappingLog'];
export type VerificationStatusEnum = definitions['VerificationStatusEnum'];
export type PotentialMappingPropertyDetail = definitions['AdminPotentialMappingPropertyDetail'];
export type PotentialMappingDetails = definitions['AdminPotentialMappingDetails'];
export type PropertyStateEnum = definitions['PropertyStateEnum'];

function basePath() {
  return window.configs.LUX_API_DOMAIN + '/api/accommodation';
}

function baseAdminPath() {
  return window.configs.LUX_API_DOMAIN + '/api/accommodation/admin';
}

export const listProperties = ({
  name,
  id,
  city,
  country,
  rating,
  limit,
  page,
  propertyState,
}: actions['listAdminProperties']['parameters']['query']): Promise<
  HttpResponse<{ total: number; properties: Array<PropertyResponse> }>
> => {
  const url = `${baseAdminPath()}/properties?${qs.stringify({
    name,
    id,
    city,
    country,
    rating,
    limit,
    page,
    propertyState,
  })}`;
  return request(url, {
    method: `GET`,
  });
};

export const getProperty = ({
  propertyId,
}: actions['getAdminProperty']['parameters']['path']): Promise<HttpResponse<PropertyDetailResponse>> => {
  const url = `${baseAdminPath()}/properties/${propertyId}`;
  return request(url, {
    method: `GET`,
  });
};

export const createProperty = ({
  internalPropertyId,
}: actions['createAdminProperty']['parameters']['body']['payload']): Promise<HttpResponse<ProgressStatusResponse>> => {
  const url = `${baseAdminPath()}/properties`;
  return request(url, {
    method: 'POST',
    headers: json_headers,
    body: JSON.stringify({ internalPropertyId }),
  });
};

export const listMappedInternalProperties = ({
  propertyId,
  verificationStatuses,
}: actions['getAdminInternalProperties']['parameters']['path'] & {
  verificationStatuses: Array<VerificationStatusEnum>;
}): Promise<HttpResponse<Array<MappedInternalPropertyWithOffers>>> => {
  const url = `${baseAdminPath()}/properties/${propertyId}/internal-properties?${qs.stringify({
    verificationStatuses,
  })}`;
  return request(url, {
    method: 'GET',
  });
};

export const getUnmappedSuppliers = ({
  name,
  id,
  internalService,
  limit,
  page,
  verificationStatuses,
}: actions['getAdminUnmappedInternalProperties']['parameters']['query']): Promise<
  HttpResponse<{ total: number; internalProperties: Array<InternalProperty> }>
> => {
  const url = `${baseAdminPath()}/properties/unmapped-internal-properties?${qs.stringify({
    name,
    id,
    internalService,
    limit,
    page,
    verificationStatuses,
  })}`;
  return request(url, {
    method: 'GET',
  });
};

export const patchInternalProperty = (
  { propertyId, internalPropertyId }: actions['patchAdminInternalProperty']['parameters']['path'],
  data: actions['patchAdminInternalProperty']['parameters']['body']['AdminPatchSupplierProperty'],
): Promise<HttpResponse<InternalProperty>> => {
  const url = `${baseAdminPath()}/properties/${propertyId}/internal-properties/${internalPropertyId}`;
  return request(url, {
    method: 'PATCH',
    headers: json_headers,
    body: JSON.stringify(data),
  });
};

export const listRooms = ({
  propertyId,
}: actions['getAdminRooms']['parameters']['path']): Promise<HttpResponse<Array<RoomResponse>>> => {
  const url = `${baseAdminPath()}/properties/${propertyId}/rooms`;
  return request(url, {
    method: 'GET',
  });
};

export const getProgressStatuses = (id: string): Promise<HttpResponse<Array<ProgressStatusResponse>>> => {
  const url = `${basePath()}/progress/statuses?${qs.stringify({ id })}`;
  return request(url, {
    method: `GET`,
  });
};

export const getRoomMappings = (propertyId: string): Promise<HttpResponse<RoomMappingResponse>> => {
  const url = `${baseAdminPath()}/properties/${propertyId}/rooms/mappings`;
  return request(url, {
    method: `GET`,
  });
};

export const postRoomMappings = (
  propertyId: string,
  mappings: RoomMappingPayload,
): Promise<HttpResponse<RoomMappingResponse>> => {
  const url = `${baseAdminPath()}/properties/${propertyId}/rooms/mappings`;
  return request(url, {
    method: `POST`,
    headers: json_headers,
    body: JSON.stringify(mappings),
  });
};

export const deleteRoomMappings = (
  propertyId: string,
  mappings: RoomMappingPayload,
): Promise<HttpResponse<RoomMappingResponse>> => {
  const url = `${baseAdminPath()}/properties/${propertyId}/rooms/mappings`;
  return request(url, {
    method: `DELETE`,
    headers: json_headers,
    body: JSON.stringify(mappings),
  });
};

export const createAndMapRoom = (
  propertyId: string,
  internalRoomIds: Array<string>,
): Promise<HttpResponse<RoomMappingResponse>> => {
  const url = `${baseAdminPath()}/properties/${propertyId}/rooms/create-and-map`;
  return request(url, {
    method: `POST`,
    headers: json_headers,
    body: JSON.stringify({ internalRoomIds }),
  });
};

export const verifyMappedRooms = (
  propertyId: string,
  payload: VerifyMappedRoomPayload,
): Promise<HttpResponse<RoomMappingResponse>> => {
  const url = `${baseAdminPath()}/properties/${propertyId}/rooms/verify-mappings`;
  return request(url, {
    method: `POST`,
    headers: json_headers,
    body: JSON.stringify(payload),
  });
};

export const getMappingLogs = ({
  sourceModel,
  sourceId,
  targetModel,
  targetId,
}: actions['getMappingLogs']['parameters']['query']): Promise<HttpResponse<Array<definitions['AdminMappingLog']>>> => {
  const url = `${baseAdminPath()}/mapping-logs?${qs.stringify({ sourceModel, sourceId, targetModel, targetId })}`;
  return request(url, {
    method: `GET`,
  });
};

export const mapInternalProperty = ({
  propertyId,
  internalPropertyId,
  validationOverrides,
}: actions['mapInternalProperty']['parameters']['path'] &
  actions['mapInternalProperty']['parameters']['body']['payload']): Promise<HttpResponse<object>> => {
  const url = `${baseAdminPath()}/properties/${propertyId}/internal-properties/${internalPropertyId}`;
  return request(url, {
    method: `POST`,
    headers: json_headers,
    body: JSON.stringify({ validationOverrides }),
  });
};

export const unmapInternalProperty = ({
  propertyId,
  internalPropertyId,
}: actions['unmapInternalProperty']['parameters']['path']): Promise<HttpResponse<object>> => {
  const url = `${baseAdminPath()}/properties/${propertyId}/internal-properties/${internalPropertyId}`;
  return request(url, {
    method: `DELETE`,
  });
};

export const deleteProperty = (propertyId: string): Promise<HttpResponse<void>> => {
  const url = `${baseAdminPath()}/properties/${propertyId}`;
  return request(url, {
    method: 'DELETE',
  });
};

export const getPotentialMappings = ({
  name,
  id,
  internalService,
  page,
  limit,
}: actions['getPotentialMappings']['parameters']['query']): Promise<
  HttpResponse<{ total: number; mappedProperties: Array<MappedInternalProperty> }>
> => {
  const url = `${baseAdminPath()}/properties/potential-mappings?${qs.stringify({
    name,
    id,
    internalService,
    page,
    limit,
  })}`;
  return request(url, {
    method: `GET`,
  });
};

export const getPotentialMappingDetails = ({
  propertyId,
  mappedPropertyId,
}: actions['getMappedPropertyDetails']['parameters']['path']): Promise<HttpResponse<PotentialMappingDetails>> => {
  const url = `${baseAdminPath()}/properties/${propertyId}/mapped-properties/${mappedPropertyId}/details`;
  return request(url, {
    method: `GET`,
  });
};

export const updateMapping = ({
  propertyId,
  mappedPropertyId,
  verificationStatus,
}: actions['updateMappingVerificationStatus']['parameters']['path'] & {
  verificationStatus: VerificationStatusEnum;
}): Promise<HttpResponse<definitions['AdminMappedInternalProperty']>> => {
  const url = `${baseAdminPath()}/properties/${propertyId}/mapped-properties/${mappedPropertyId}`;
  return request(url, {
    method: `PATCH`,
    headers: json_headers,
    body: JSON.stringify({ verificationStatus }),
  });
};

export const reimportSuppliers = (propertyId: string): Promise<HttpResponse<ProgressStatusResponse>> => {
  const url = `${baseAdminPath()}/properties/${propertyId}/suppliers/reimport`;
  return request(url, {
    method: `POST`,
  });
};

export async function deleteRoom({
  propertyId,
  roomId,
}: actions['deleteAdminRoom']['parameters']['path']): Promise<HttpResponse<void>> {
  const url = `${baseAdminPath()}/properties/${propertyId}/rooms/${roomId}`;
  return request(url, {
    method: 'DELETE',
  });
}
