import React from 'react';

import { Stack } from '@mui/material';

import { CruiseShipProvider } from '~/components/Cruises/context/useCruiseShips';

import ShipList from './components/ShipList/ShipList';
import ShipSearch from './components/ShipSearch/ShipSearch';

export default function ShipsPage() {
  return (
    <CruiseShipProvider>
      <Stack direction="column" spacing={2}>
        <ShipSearch />
        <ShipList />
      </Stack>
    </CruiseShipProvider>
  );
}
