import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useHistory, useLocation } from 'react-router';

import { Box, Container, Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { Toggle } from '~/components/Experiences/components';
import { BookingDetailCell } from '~/components/Tours/DepatureInsight/BookingDetailCell';
import { MarginDetailCell } from '~/components/Tours/DepatureInsight/MarginDetailCell';
import { PricingDetailCell } from '~/components/Tours/DepatureInsight/PricingDetailCell';
import { TourPricingLink } from '~/components/Tours/DepatureInsight/TourPricingLink';

import { diffDays, formatDateISOUTC } from '~/services/TimeService';
import { getDeparturesInsights } from '~/services/ToursService';

const columns: Array<GridColDef<DepartureInsight>> = [
  {
    field: 'tourName',
    headerName: 'Tour Name',
    display: 'flex',
    minWidth: 150,
    renderCell: (params) => (
      <TourPricingLink tourId={params.row.tourId} startDate={params.row.startDate} tourName={params.value} />
    ),
  },
  {
    field: 'package',
    headerName: 'Package',
    display: 'flex',
  },
  {
    field: 'startDate',
    headerName: 'Start date',
    display: 'flex',
    width: 120,
    renderCell: (params) => (
      <Box>
        <Typography>{formatDateISOUTC(params.row.startDate)}</Typography>
        <Typography>{diffDays(params.value, new Date(), 'day')} days left</Typography>
      </Box>
    ),
  },
  {
    field: 'handbackDate',
    headerName: 'Handback date',
    display: 'flex',
    width: 120,
    renderCell: (params) => (
      <Box>
        <Typography>{formatDateISOUTC(params.value)}</Typography>
        <Typography>{diffDays(params.value, new Date(), 'day')} days left</Typography>
      </Box>
    ),
  },
  {
    field: 'paxLeft',
    headerName: 'Pax left',
    display: 'flex',
  },
  {
    field: 'bookings',
    headerName: 'Booking',
    display: 'flex',
    width: 200,
    renderCell: (params) =>
      params.row.bookings &&
      params.row.totalBookingAmount &&
      params.row.lastBookingDate && (
        <BookingDetailCell
          bookings={params.row.bookings}
          totalBookingAmount={params.row.totalBookingAmount}
          totalCostPrice={params.row.totalCostPrice}
          bookingsPax={params.row.bookingsPax}
          paxLeft={params.row.paxLeft}
          lastBookingDate={params.row.lastBookingDate}
        />
      ),
  },
  {
    field: 'pricing',
    headerName: 'Pricing details',
    display: 'flex',
    width: 300,
    renderCell: (params) => <PricingDetailCell pricing={params.value} />,
  },
  {
    field: 'actualMargin',
    headerName: 'Margin',
    display: 'flex',
    width: 350,
    renderCell: (params) => (
      <MarginDetailCell actualMargin={params.value} incrementalAverageMargin={params.row.incrementalAverageMargin} />
    ),
  },
];

export interface PriceDetail {
  id: string;
  adminDiscountPercentage: number;
  discountedPrice: number;
  roomType: string;
  inventoryLeft: number;
  paxLeft: number;
}

interface DepartureInsight {
  id: string;
  startDate: string;
  tourId: string;
  tourName: string;
  package: string;
  paxLeft: number;
  handbackDate: string;
  bookings?: number;
  totalBookingAmount?: number;
  lastBookingDate?: string;
  bookingsPax?: number;
  pricing?: Array<PriceDetail>;
  actualMargin?: string;
  totalCostPrice?: number;
  incrementalAverageMargin?: { [key: string]: number };
}

export default function ToursDepartureInsightsPage() {
  const history = useHistory();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [departuresInsight, setDeparturesInsight] = useState<Array<DepartureInsight>>([]);
  const [departureWithNoBookings, setDepartureWithNoBookings] = useState<Array<DepartureInsight>>([]);
  const [toggleDepartureShow, setToggleDepartureShow] = useState({ bookings: true, noBookings: false });
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const urlSearchParams = useMemo<URLSearchParams>(() => new URLSearchParams(location.search), [location.search]);
  const pageIndex = useMemo<number>(() => parseInt(urlSearchParams.get('page') || '1'), [urlSearchParams]);

  const handlePageChange = useCallback(
    (pageIndex: number) => {
      const urlSearchParamsToPush = new URLSearchParams(urlSearchParams);
      if (pageIndex) {
        urlSearchParamsToPush.set('page', String(pageIndex + 1));
      } else {
        urlSearchParamsToPush.delete('page');
      }
      history.push({
        search: urlSearchParamsToPush.toString(),
      });
    },
    [urlSearchParams, history],
  );

  const departuresToShow = useMemo(() => {
    if (toggleDepartureShow.bookings) {
      return departuresInsight;
    } else if (toggleDepartureShow.noBookings) {
      return departureWithNoBookings;
    }
    return departuresInsight;
  }, [toggleDepartureShow, departuresInsight, departureWithNoBookings]);

  const toggleDepartureStatusBooking = useCallback((key) => {
    setToggleDepartureShow({ bookings: false, noBookings: false, [key]: true });
  }, []);

  useEffect(() => {
    setLoading(true);
    getDeparturesInsights(pageIndex)
      .then((response) => {
        setDeparturesInsight(response.result.filter((departure) => departure.bookings));
        setDepartureWithNoBookings(response.result.filter((departure) => departure.bookings === 0));
        setTotal(response.total);
      })
      .catch((error) => {
        enqueueSnackbar('Failed to fetch departures', { variant: 'error' });
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex]);

  return (
    <Container maxWidth="xl">
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h4">
          Departures {toggleDepartureShow.bookings ? 'with bookings' : 'with no bookings'}
        </Typography>
        <Toggle
          options={['bookings', 'noBookings']}
          onClick={toggleDepartureStatusBooking}
          selected={toggleDepartureShow}
        />
      </Stack>

      <DataGrid
        columns={columns}
        rows={departuresToShow}
        rowCount={total}
        getRowHeight={() => 'auto'}
        paginationMode="server"
        paginationModel={{ page: pageIndex - 1, pageSize: 20 }}
        onPaginationModelChange={({ page }) => handlePageChange(page)}
        loading={loading}
        autoHeight
        disableColumnFilter
        disableColumnMenu
      />
    </Container>
  );
}
