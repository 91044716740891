import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import { Box, Container } from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';

import rateCodeService from '~/services/cruises/RateCodeService';

import { RateCode } from '../../types';

import RateCodeForm from './components/RateCodeForm';
import { GENERIC_INITIAL_REQUEST_STATE, RATE_CODE_BASE_PATH } from './constants';
import { hasRequiredFields } from './helpers';
import { RateCodeFormValues, Request } from './types';

const EditRateCodePage: React.FC = (): JSX.Element => {
  const { rateCodeId } = useParams<{ rateCodeId: string }>();

  const { enqueueSnackbar } = useSnackbar();
  const [rateCode, setRateCode] = useState<Request<RateCode>>(GENERIC_INITIAL_REQUEST_STATE);

  const rateCodeFormValues: RateCodeFormValues | null = useMemo(() => {
    if (!rateCode.result) return null;

    return {
      id: rateCode.result.id,
      code: rateCode.result.code,
      vendorId: rateCode.result.vendorId,
      isFlash: rateCode.result.isFlash,
    };
  }, [rateCode]);

  const fetchRateCodeById = useCallback(
    async (rateCodeId: string) => {
      setRateCode({ loading: true, result: null });

      const res = await rateCodeService.getRateCodeById(rateCodeId);

      if (!res || !res?.result || res?.status !== 200) {
        setRateCode({ loading: false, result: null });
        enqueueSnackbar('Something went wrong, please contact cruises team', { variant: 'error' });
      } else {
        setRateCode({ loading: false, result: res.result });
      }
    },
    [enqueueSnackbar],
  );

  const handleSubmit = useCallback(
    async (formValues: RateCodeFormValues) => {
      if (!hasRequiredFields(formValues)) {
        enqueueSnackbar(`Please fill all required fields`, { variant: 'error' });
      } else {
        enqueueSnackbar(`The information is being processed, please wait a moment.`, { variant: 'info' });

        const res = await rateCodeService.updateRateCodeById(rateCodeId, {
          isFlash: formValues.isFlash,
        });

        if (res?.status === 201 || res?.status === 200) {
          enqueueSnackbar(`Rate Code updated successfully`, { variant: 'success' });
        } else {
          enqueueSnackbar('Something went wrong, please contact cruises team', { variant: 'error' });
        }
      }
    },
    [enqueueSnackbar, rateCodeId],
  );

  useEffect(() => {
    fetchRateCodeById(rateCodeId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rateCodeId]);

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>Edit Cruise Rate Code</title>
      </Helmet>

      <PageHeader title="Edit Rate Code" backButton={RATE_CODE_BASE_PATH} />

      <Box>
        <RateCodeForm rateCodeFormValues={rateCodeFormValues} onSubmit={handleSubmit} />
      </Box>
    </Container>
  );
};

export default EditRateCodePage;
