import React, { useState } from 'react';

import { Button, Dialog } from '@mui/material';

import PermissionedComponent from '~/components/Common/PermissionedComponent';

import { ROLE_ADMIN_USER, ROLE_EMPLOYEE_USER } from '~/consts/roles';

import EnterCodeModal from './EnterCodeModal';
import OtpSuccessModal from './OtpSuccessModal';
import SendSmsModal from './SendSmsModal';

const STEP_SEND_SMS = 'SEND_SMS';
const STEP_ENTER_CODE = 'ENTER_CODE';
const STEP_SUCCESS = 'SUCCESS';

export type Step = 'SEND_SMS' | 'ENTER_CODE' | 'SUCCESS';

interface Props {
  user: App.User;
}

export default function VerifyCustomerButtons({ user }: Props) {
  const [currentStep, setCurrentStep] = useState<Step | null>(null);

  const isModalOpen = Boolean(currentStep);
  const hasPhoneNumber = user.phone_prefix && user.phone;

  const handleOpen = () => {
    setCurrentStep(STEP_SEND_SMS);
  };

  const handleClose = () => {
    setCurrentStep(null);
  };

  return (
    <>
      <PermissionedComponent requiredRoles={[ROLE_ADMIN_USER, ROLE_EMPLOYEE_USER]}>
        <Button
          variant="contained"
          onClick={handleOpen}
          disabled={isModalOpen || !hasPhoneNumber}
          sx={{
            backgroundColor: 'info.dark',
          }}
        >
          Verify Customer - SMS
        </Button>
      </PermissionedComponent>

      <Dialog open={isModalOpen} onClose={handleClose} fullWidth maxWidth="sm">
        {currentStep === STEP_SEND_SMS && (
          <SendSmsModal
            onClose={handleClose}
            setCurrentStep={setCurrentStep}
            phonePrefix={user.phone_prefix}
            phone={user.phone}
          />
        )}
        {currentStep === STEP_ENTER_CODE && (
          <EnterCodeModal
            onClose={handleClose}
            setCurrentStep={setCurrentStep}
            phonePrefix={user.phone_prefix}
            phone={user.phone}
          />
        )}
        {currentStep === STEP_SUCCESS && <OtpSuccessModal onClose={handleClose} />}
      </Dialog>
    </>
  );
}
