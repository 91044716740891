import React, { useEffect, useState } from 'react';

import { Typography } from '@mui/material';

import { diffSeconds, formatDateLongISO } from '~/services/TimeService';

type Props = {
  startTime: string;
};
export default function SecondsSince({ startTime }: Props) {
  const [secondsSince, setSecondsSince] = useState<number>(diffSeconds(startTime, formatDateLongISO()));

  useEffect(() => {
    const interval = setInterval(() => {
      setSecondsSince(diffSeconds(startTime, formatDateLongISO()));
    }, 250);

    return () => clearInterval(interval);
  }, [startTime]);

  return (
    <Typography component="span" variant="inherit">
      {secondsSince}
    </Typography>
  );
}
