import qs from 'qs';

import * as EC from '@luxuryescapes/contract-svc-experience';
import { API } from '@luxuryescapes/lib-types';

import { uploadImage } from '~/services/ImageService';

import { json_headers, request } from './common';

const STATIC_PARAMS = `currency=AUD`;
const BASE_PATH = `${window.configs.API_HOST}/api/experiences`;

export type ExperienceItem = API.Order.ExperienceItem;
export type ExperienceItems = API.Order.ExperienceItem[];

export type CategoryItem = EC.Experiences.Category;
export type ExperienceOffer = EC.Experiences.Offer;
export type ExperienceOffers = EC.Experiences.Offer[];
export type ExperienceOfferImage = EC.Experiences.Image;
export type CurationStatus = EC.Experiences.CurationStatus | string;
export type DateAvailability = EC.Experiences.DateAvailability;
export type TimeGroups = EC.Experiences.TimeGroups;
export type DealTicket = EC.Experiences.DealTicket;
export type ExperienceActionTypes = 'LOAD' | 'FETCH' | 'ERROR' | 'RESET';
export type BookingOptions = EC.Experiences.BookingOptions;
export type Brand = EC.Experiences.Brand;
export type BookingDetails = EC.Experiences.BookingDetails;

export interface RangeFilter {
  min: number | null;
  max: number | null;
}

export interface Filters {
  limit?: number | null;
  offset?: number | null;
  distance?: string | null;
  price?: RangeFilter | null;
  rating?: RangeFilter | null;
  categories?: string[] | null;
  status?: CurationStatus[] | null;
  coordinates?: google.maps.LatLngLiteral | null;
  idIn?: string | null;
  title?: string | null;
  vendor?: string | null;
  providerIn?: string[] | null;
}

export type Hero = {
  experiencesOffersIds: string[];
  region: string;
};

export const initialFiltersValues: Filters = {
  limit: null,
  offset: null,
  status: null,
  distance: null,
  categories: null,
  coordinates: null,
  price: { min: null, max: null },
  rating: { min: null, max: null },
  idIn: null,
  title: null,
  vendor: null,
  providerIn: null,
};

type GetOffersParams = {
  limit?: number;
  brand: string;
  offset?: number;
  filters?: Filters;
  curationData?: boolean;
  minified?: boolean;
  getController?: (controller: { cancel: () => void }) => void;
};

type OffersResponse = EC.Experiences.Response<EC.Experiences.Offer[]>;

const getExperienceOffers = (params: GetOffersParams): Promise<OffersResponse> => {
  const { filters, limit, offset, brand, curationData, minified, getController } = params;
  const queryParams: EC.Experiences.FilterQuery = {
    limit,
    offset,
    brand,
    curationData,
    minified,
    showUnlisted: true,
    showFutureOffers: true,
    disableCache: true,
  };

  if (filters?.distance) queryParams.distance = filters.distance;

  if (filters?.categories?.length > 0) queryParams.categoryIn = filters.categories?.join();

  if (filters?.status?.length > 0) queryParams.curationStatus = filters.status.join();
  else queryParams.curationStatus = 'APPROVED,REJECTED,NOT_CURATED';

  if (filters?.coordinates) queryParams.coordinates = `${filters.coordinates.lat},${filters.coordinates.lng}`;

  if (filters?.rating?.min || filters?.rating?.max)
    queryParams.ratingRange = `${filters.rating.min || 0},${filters.rating.max || 5}`;

  if (filters?.price?.min || filters?.price?.max)
    queryParams.basePriceRange = `${filters.price.min || 0},${filters.price.max || 99999}`;

  if (filters?.idIn) queryParams.idIn = filters.idIn;

  if (filters?.title) queryParams.title = filters.title;

  if (filters?.vendor) queryParams.vendor = filters.vendor;

  if (filters?.providerIn?.length > 0) {
    queryParams.providerIn = filters.providerIn.join();
  }

  const requestController = new AbortController();
  const { signal } = requestController;
  if (getController) getController({ cancel: () => requestController.abort() });

  const FILTER_PARAMS = qs.stringify(queryParams);
  const uri = `${BASE_PATH}/offers/new?${STATIC_PARAMS}&${FILTER_PARAMS}`;

  return request(uri, { signal, method: 'GET' });
};

type OfferByIdParams = { id: string; brand: string; curationData?: boolean };
type OfferByIdResponse = EC.Experiences.Response<EC.Experiences.Offer>;
type HeroesResponse = EC.Experiences.Response<Hero>;
type BookingDetailsResponse = EC.Experiences.Response<EC.Experiences.BookingDetails[]>;

const getExperienceOfferById = (params: OfferByIdParams): Promise<OfferByIdResponse> => {
  const { id, brand, curationData = false } = params;
  const uri = `${BASE_PATH}/offers/new/${id}?${STATIC_PARAMS}&brand=${brand}&curationData=${curationData}&disableCache=true`;

  return request(uri, { method: 'GET' });
};

type CategoriesResponse = EC.Experiences.Response<EC.Experiences.Category[]>;

const getCategories = (brand: string): Promise<CategoriesResponse> => {
  const uri = `${BASE_PATH}/categories/new?currency=AUD&brand=${brand}`;
  return request(uri, { method: 'GET' });
};
const getHeroesByRegion = (region: string, brand: string): Promise<HeroesResponse> => {
  const uri = `${BASE_PATH}/region-heroes/${region}?brand=${brand}&disableCache=true`;
  return request(uri, { method: 'GET' });
};

const updateHeroes = (region: string, payload): Promise<HeroesResponse> => {
  const uri = `${BASE_PATH}/region-heroes/${region}`;
  return request(uri, {
    method: 'PATCH',
    headers: json_headers,
    body: JSON.stringify(payload),
  });
};

export type UploadedImage = {
  id: string;
  url: string;
};

export type TicketsPayload = DealTicket & {
  image?: File;
};

export interface CurationParams {
  curationStatus?: CurationStatus;
  isFlash?: boolean;
  title?: string;
  location?: {
    latitude: number | null;
    longitude: number | null;
    description: string | null;
  };
  categories?: EC.Experiences.Category[];
  heroImageId?: string;
  campaigns?: string[];
  showPostPurchase?: boolean;
  searchEngineMarketingEnabled?: boolean;
  unlisted?: boolean;
  dealDescription?: string;
  shortDescription?: string;
  included?: string;
  thingsToKnowNew?: EC.Experiences.ThingsToKnow;
  tickets?: EC.Experiences.DealTicket[];
  images?: EC.Experiences.Image[];
  hideTimeSlots?: boolean;
  isNonRefundable?: boolean;
  video?: string;
  excludedRegions?: EC.Experiences.ExcludedRegion;
  bookingOptions?: EC.Experiences.BookingOptions;
  vendorBookingCutoff?: number;
  features?: EC.Experiences.OfferFeatures;
  ticketUnitLabel?: string;
  baseSalesPrice?: {
    amount: number;
    currencyCode: string;
  };
  allowBuyNowBookLater?: boolean;
  idSalesforceExternal?: string | null;
  brands?: string[];
  overriddenFields?: string[];
  cancellationInfo?: EC.Experiences.CancellationInfo;
  showInJourneys?: boolean;
  bookingFields?: EC.Experiences.BookingField[];
  margin?: number;
  commissionMargin?: number;
  ticketed?: boolean;
}

type CurationResponse = EC.Experiences.Response<EC.Experiences.Offer>;

export enum TransferType {
  AIRPORT_TO_HOTEL = 'AIRPORT-TO-HOTEL',
  HOTEL_TO_AIRPORT = 'HOTEL-TO-AIRPORT',
}

export enum ChildSeatType {
  INFANT_SEAT = 'INFANT-SEAT',
  BOOSTER_SEAT = 'BOOSTER-SEAT',
}

export interface CurateExperienceParams {
  curationStatus: CurationStatus;
}

const uploadImageFile = async (image: File): Promise<{ id: string; url: string }> => {
  const response = await uploadImage(image);
  return {
    id: response.body.public_id,
    url: `${window.configs.IMAGE_HOST}/${response.body.public_id}.${image.type.split('/').pop()}`,
  };
};

const mapOfferTickets = async (
  tickets: TicketsPayload[] | undefined,
): Promise<EC.Experiences.DealTicket[]> | undefined => {
  if (!tickets) return;

  const mappedTickets: EC.Experiences.DealTicket[] = await Promise.all(
    tickets.map(async (ticket) => {
      let imageUrl = ticket.imageUrl;

      if (ticket.image) {
        const { url } = await uploadImageFile(ticket.image);
        imageUrl = url;
      }

      delete ticket.image;

      return { ...ticket, imageUrl };
    }),
  );

  return mappedTickets;
};

const uploadOfferImage = async (images: File[]): Promise<UploadedImage[]> => {
  return Promise.all(images.map((image) => uploadImageFile(image)));
};

const curationExperience = async (
  experienceId: string,
  brand: string,
  payload: CurationParams,
): Promise<CurationResponse> => {
  const uri = `${BASE_PATH}/offers/${experienceId}?${STATIC_PARAMS}&brand=${brand}`;

  return request(uri, {
    method: 'PATCH',
    headers: json_headers,
    body: JSON.stringify(payload),
  });
};

type AvailableDatesResponse = EC.Experiences.Response<EC.Experiences.DateAvailability[]>;
type AvailableTimesResponse = EC.Experiences.Response<EC.Experiences.TimeGroups[]>;
type OriginalOfferResponse = EC.Experiences.Response<ExperienceOffer>;

type CurationFeatureManagerResponse = EC.Experiences.Response<EC.Experiences.CurationFeatureManager>;

type BrandsResponse = EC.Experiences.Response<EC.Experiences.Brand[]>;

export type CurationFeatureManager = EC.Experiences.CurationFeatureManager;

const getAvailableDates = (experienceId: string, brand: string): Promise<AvailableDatesResponse> => {
  const uri = `${BASE_PATH}/offers/${experienceId}/availability?brand=${brand}`;
  return request(uri, { method: 'GET' });
};

const getAvailableTimes = (
  experienceId: string,
  brand: string,
  payload: { day: string },
): Promise<AvailableTimesResponse> => {
  const uri = `${BASE_PATH}/offers/${experienceId}/availability/${payload.day}?brand=${brand}&curationData=true`;
  return request(uri, { method: 'GET' });
};

/**
 * Returns the original experience from provider
 */
const getOriginalOffer = (experienceId: string, brand: string): Promise<OriginalOfferResponse> => {
  const uri = `${BASE_PATH}/offers/${experienceId}/original?brand=${brand}`;
  return request(uri, { method: 'GET' });
};

const getHandpickedExperiencesForOffer = (offerId: string) => {
  return request(`${BASE_PATH}/offer-handpicked-experiences?offerId=${offerId}`, { method: 'GET' });
};

const postHandpickedExperiencesForOffer = (payload) => {
  const jsonData = JSON.stringify(payload);
  return request(`${BASE_PATH}/offer-handpicked-experiences`, {
    method: 'POST',
    headers: json_headers,
    body: jsonData,
  });
};

const getHandpickedExperiencesForPackage = (packageId: string) => {
  return request(`${BASE_PATH}/package-handpicked-experiences?packageId=${packageId}`, { method: 'GET' });
};

const postHandpickedExperiencesForPackage = (payload) => {
  const jsonData = JSON.stringify(payload);
  return request(`${BASE_PATH}/package-handpicked-experiences`, {
    method: 'POST',
    headers: json_headers,
    body: jsonData,
  });
};

const getBookingDetails = (experienceItemId: string): Promise<BookingDetailsResponse> => {
  return request(`${BASE_PATH}/booking-details/${experienceItemId}`, {
    method: 'GET',
  });
};

const getCurationFeatureManager = (experienceId: string): Promise<CurationFeatureManagerResponse> => {
  const uri = `${BASE_PATH}/offers/${experienceId}/curation-feature-manager`;
  return request(uri, { method: 'GET' });
};

const getBrands = (): Promise<BrandsResponse> => {
  const uri = `${BASE_PATH}/brands`;
  return request(uri, { method: 'GET' });
};

const bulkUpdateOffers = (): Promise<BrandsResponse> => {
  const uri = `${BASE_PATH}/offers/bulk`;
  return request(uri, { method: 'POST' });
};

export async function getSvcExperienceSyncData(packageId) {
  return request(`${BASE_PATH}/offers?bundledPackageId=${packageId}&showUnlisted=true`, {
    method: 'GET',
  });
}

export {
  BASE_PATH,
  getCategories,
  curationExperience,
  getExperienceOffers,
  getExperienceOfferById,
  getHeroesByRegion,
  updateHeroes,
  getAvailableTimes,
  getAvailableDates,
  getHandpickedExperiencesForOffer,
  postHandpickedExperiencesForOffer,
  getHandpickedExperiencesForPackage,
  postHandpickedExperiencesForPackage,
  uploadOfferImage,
  getOriginalOffer,
  getBookingDetails,
  getCurationFeatureManager,
  mapOfferTickets,
  getBrands,
  bulkUpdateOffers,
};
