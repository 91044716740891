import { CruisesContract as API } from '@luxuryescapes/contract-svc-cruise';

import cruisesAPI from './CruisesApiService';

const resource = 'ships';

const mapImage = (image: API.ShipImage): API.ShipImage => ({
  id: image.id,
  order: image.order,
  idImage: image.imageId,
  filename: image.filename,
  idCloudinaryExternal: image.imageId,
});

export type Ship = API.Ship;

const listWithPagination = async (paginationParams: API.ShipParams): Promise<API.Response<API.Ship[]>> => {
  return await cruisesAPI.http.get<API.Ship[]>({ resource, paginationParams });
};

const getShipById = async (shipId: string): Promise<API.Response<API.Ship>> => {
  return await cruisesAPI.http.get<API.Ship>({
    resource: `${resource}/${shipId}`,
  });
};

const updateShip = async (shipId: string, cruiseCriticId: number): Promise<API.Response<API.Ship>> => {
  return await cruisesAPI.http.put<API.Ship>({
    resource: `${resource}/${shipId}`,
    body: { cruiseCriticId },
  });
};

const getShipImages = async (shipId: string): Promise<API.Response<API.ShipImage[]>> => {
  const response = await cruisesAPI.http.get<API.ShipImage[]>({
    resource: `${resource}/${shipId}/images`,
  });

  return {
    ...response,
    result: response.result.map(mapImage),
  };
};

const createShipImage = async (
  shipId: string,
  image: {
    idCloudinaryExternal: string;
    filename?: string;
  },
): Promise<API.ShipImage> => {
  const response = await cruisesAPI.http.post<API.ShipImage>({
    resource: `${resource}/${shipId}/images`,
    body: {
      imageId: image.idCloudinaryExternal,
      filename: image.filename,
    },
  });
  return mapImage(response);
};

const updateShipImages = async (
  shipId: string,
  images: {
    idImage: string;
    idCloudinaryExternal: string;
    order: string;
  }[],
) => {
  await cruisesAPI.http.put({
    resource: `${resource}/${shipId}/images`,
    body: {
      images: images.map((image) => ({
        imageId: image.idImage,
      })),
    },
  });
};

const deleteShipImage = async (shipId: string, imageId: string) => {
  await cruisesAPI.http.delete({
    resource: `${resource}/${shipId}/images/${imageId}`,
  });
};

export default {
  getShipById,
  updateShip,
  getShipImages,
  createShipImage,
  deleteShipImage,
  updateShipImages,
  listWithPagination,
};
