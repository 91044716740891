import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Radio,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';

import * as libRegions from '@luxuryescapes/lib-regions';

import DateTimeWidget from '~/components/Common/Elements/DateTimeWidget';
import PageHeader from '~/components/Common/Elements/PageHeader';

import SearchService, { EvVariant } from '~/services/SearchService';
import { formatDateISO } from '~/services/TimeService';

const ERROR_MESSAGE_NO_REGION_SELECTED = 'Please select at least one region';
const ERROR_MESSAGE_NO_SCORE = 'Please input an override score';
const ERROR_MESSAGE_NO_WEIGHT = 'Please input a weight';
const ERROR_MESSAGE_NO_EXPIRE_DATE = 'Please pickup an expire date';
const ERROR_MESSAGE_INVALID_EXPIRE_DATE = 'Please input a valid expire date';

interface Props {
  offerScores: Array<App.OfferScore>;
  match: any;
}

const regionCodes = libRegions.getRegions().map((r) => r.code);

function SearchOrderingEditContainer({ match }: Props) {
  const offerId = match.params.id_offer;
  const vertical = match.params.vertical;
  const history = useHistory();

  const [checkedRegions, setCheckedRegions] = React.useState<Array<string>>([]);
  const [expireDate, setExpireDate] = useState<string | null>(null);
  const [allVariants, setAllVariants] = useState<Array<EvVariant>>([EvVariant.Current]);
  const [variant, setVariant] = useState<string | null>(EvVariant.Current);
  const [score, setScore] = React.useState<number | undefined>(10);
  const [weight, setWeight] = React.useState<number | undefined>(100);
  const [errors, setErrors] = React.useState<Array<string>>([
    ERROR_MESSAGE_NO_REGION_SELECTED,
    ERROR_MESSAGE_NO_WEIGHT,
    ERROR_MESSAGE_NO_EXPIRE_DATE,
  ]);
  const [showErrorMessage, setShowErrorMessage] = React.useState<boolean>(false);

  const isAllChecked = regionCodes.length > 0 && checkedRegions.length === regionCodes.length;
  const isBedBankOffer = vertical === 'hotel' && offerId.length === 36;
  const getBackPath = (): string => {
    switch (vertical) {
      case 'experience':
        return `/experience/${offerId}`;
      case 'cruise':
        return `/cruises/offer/${offerId}`;
      default:
        return isBedBankOffer ? `/bedbank/properties/${offerId}` : `/offers/${offerId}`;
    }
  };
  const backPath = getBackPath();

  const handleRegionSelect = (region: string) => () => {
    if (region === 'all') {
      setCheckedRegions(checkedRegions.length === regionCodes.length ? [] : regionCodes);
      return;
    }

    const currentIndex = checkedRegions.indexOf(region);
    const newChecked = [...checkedRegions];

    if (currentIndex === -1) {
      newChecked.push(region);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedRegions(newChecked);
  };

  const handleVariantChange = (event: SelectChangeEvent<Array<string>>) => {
    setVariant(event.target.value as string);
  };

  useEffect(() => {
    const loadVariants = async () => {
      const { result } = await SearchService.getOfferScoreSettings('hotel', 'AU');
      setAllVariants(result?.variants?.variants);
    };

    loadVariants();
  }, []);

  const validateWeight = (event) => {
    const newWeight = event.target.value;

    if (newWeight > 100) {
      setWeight(100);
      return;
    } else if (newWeight < 0) {
      setWeight(0);
      return;
    }

    // round it to two decimals
    const weightInteger = newWeight * 100;
    setWeight(Math.floor(weightInteger) / 100);
  };

  const validateScore = (event) => {
    const newScore = event.target.value;

    if (newScore > 10) {
      setScore(10);
      return;
    } else if (newScore < 0) {
      setScore(0);
      return;
    }

    setScore(newScore);
  };

  const validateForm = (): Array<string> => {
    const newErrors = [];
    if (score === undefined) {
      newErrors.push(ERROR_MESSAGE_NO_SCORE);
    }

    if (weight === undefined) {
      newErrors.push(ERROR_MESSAGE_NO_WEIGHT);
    }

    if (!checkedRegions.length) {
      newErrors.push(ERROR_MESSAGE_NO_REGION_SELECTED);
    }

    if (expireDate === null) {
      newErrors.push(ERROR_MESSAGE_NO_EXPIRE_DATE);
    } else if (expireDate === 'Invalid Date') {
      newErrors.push(ERROR_MESSAGE_INVALID_EXPIRE_DATE);
    }

    setErrors(newErrors);
    return newErrors;
  };

  const handleSubmit = async () => {
    const newErrors = validateForm();
    if (newErrors.length > 0) {
      setShowErrorMessage(true);
      return;
    }
    const overrideExpireDate = formatDateISO(expireDate);
    const payload = checkedRegions.map((region) => ({
      region,
      variant,
      overrideScore: score,
      overrideWeight: weight / 100,
      overrideExpireDate,
    }));

    vertical === 'hotel'
      ? await SearchService.patchHotelScore(offerId, payload)
      : await SearchService.patchScore(offerId, payload);

    history.push(backPath);
  };

  return (
    <>
      {showErrorMessage && errors.length > 0 && <Alert severity="error">{errors.join('; ')}</Alert>}
      <Container maxWidth="xl">
        <Button
          component={Link}
          href={backPath}
          variant="text"
          startIcon={<ArrowBackIosNewIcon />}
          color="secondary"
          size="large"
        >
          Return to offer
        </Button>
        <PageHeader title="Edit Search Ordering" />
        <Alert severity="info">
          Percentile scores is a temporary solution while we re-work offer scores into our search engine.
          <br />
          9.9 = 99th percentile = towards the end of the top 20 offers
          <br />
          9.99 = 99.9th percentile = towards the end of the top 5 offers
        </Alert>
        <br />
        <Stack direction="row">
          <Container>
            {/* select all button */}
            <ListItemButton role={undefined} onClick={handleRegionSelect('all')} dense>
              <ListItemIcon>
                <Checkbox
                  checked={isAllChecked}
                  indeterminate={checkedRegions.length > 0 && checkedRegions.length < regionCodes.length}
                  edge="start"
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': 'all' }}
                />
              </ListItemIcon>
              <ListItemText id="all" primary="Select All Regions" />
            </ListItemButton>

            <Divider />

            <List>
              {regionCodes.map((regionCode) => {
                const labelId = `checkbox-list-label-${regionCode}`;
                return (
                  <ListItem
                    key={regionCode}
                    secondaryAction={<IconButton edge="end" aria-label="comments" />}
                    disablePadding
                  >
                    <ListItemButton role={undefined} onClick={handleRegionSelect(regionCode)} dense>
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={checkedRegions.includes(regionCode)}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText id={labelId} primary={regionCode} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Container>
          <Container>
            <FormControl sx={{ minWidth: 200 }}>
              <InputLabel>Select an Variant</InputLabel>
              <Select
                required
                value={[variant]}
                label="Select an Variant"
                onChange={handleVariantChange}
                renderValue={(selected) => selected}
              >
                {allVariants.map((option) => (
                  <MenuItem key={option} value={option}>
                    <Radio checked={variant === option} />
                    <ListItemText primary={option} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Container>
          <Container>
            <TextField
              id="override-score-input"
              label="Override percentile"
              type="number"
              value={score}
              onChange={validateScore}
            />
          </Container>
          <Container>
            <TextField
              id="weight-input"
              label="Weight Applied"
              type="number"
              value={weight}
              variant="outlined"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              onChange={validateWeight}
            />
          </Container>
          <Container>
            <DateTimeWidget
              value={expireDate}
              label="Override Expire Date"
              showDateOnly
              disablePast
              onChange={setExpireDate}
            />
          </Container>
          <Container>
            <Box>
              <Button type="submit" value="submit" onClick={handleSubmit}>
                Submit
              </Button>
            </Box>
          </Container>
        </Stack>
      </Container>
    </>
  );
}

export default SearchOrderingEditContainer;
