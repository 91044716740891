import { CruisesContract as API } from '@luxuryescapes/contract-svc-cruise';

import cruisesAPI from './CruisesApiService';

const resource = 'promo-banners';
type PromoBannerSortData = Pick<API.PromoBanner, 'id' | 'order'>;
type PromoBannerResponse = API.Response<API.PromoBanner>;

export type PromoBannerFormData = {
  description: string;
  startDate: string | null;
  endDate: string | null;
  redirectType?: string;
  redirectData?: string;
  order?: number;
  isFixed: boolean;
  imageId: string;
  mobileImageId: string;
  availableInBrands?: API.Brand[];
  availableInRegions?: API.Region[];
  isVisibleOnVendorPage: boolean;
  vendorId?: string;
};

const listWithPagination = async (paginationParams: API.PaginationParams): Promise<API.Response<API.PromoBanner[]>> => {
  return await cruisesAPI.httpV1.get({ resource, paginationParams });
};

const createPromoBanner = async (promoBanner: PromoBannerFormData): Promise<API.PromoBanner> => {
  return await cruisesAPI.httpV1.post({
    resource,
    body: promoBanner,
  });
};

const updatePromoBanner = async (promoBannerId: string, promoBanner: PromoBannerFormData): Promise<API.PromoBanner> => {
  const response = await cruisesAPI.httpV1.patch<API.PromoBanner>({
    resource: `${resource}/${promoBannerId}`,
    body: promoBanner,
  });

  return response.result;
};

const getPromoBannerById = async (promoBannerId: string): Promise<API.PromoBanner> => {
  const response = await cruisesAPI.httpV1.get<API.PromoBanner>({
    resource: `${resource}/${promoBannerId}`,
  });

  return response.result;
};

const deletePromoBanner = async (promoBannerId: string) => {
  await cruisesAPI.httpV1.delete({
    resource: `${resource}/${promoBannerId}`,
  });
};

const sortPromoBanners = async (promoBanners: PromoBannerSortData[]): Promise<PromoBannerResponse> => {
  return cruisesAPI.httpV1.patch({
    resource: `${resource}/sort`,
    body: { promoBanners },
  });
};

export default {
  createPromoBanner,
  updatePromoBanner,
  listWithPagination,
  getPromoBannerById,
  deletePromoBanner,
  sortPromoBanners,
};
