import React, { Suspense, lazy, useMemo } from 'react';

import { json } from '@codemirror/lang-json';
import { xml } from '@codemirror/lang-xml';
import { githubDark, githubLight } from '@uiw/codemirror-theme-github';
import type { ReactCodeMirrorProps } from '@uiw/react-codemirror';

const LazyCodeMirror = lazy(() => import('@uiw/react-codemirror'));

interface Props extends ReactCodeMirrorProps {
  lang: 'json' | 'xml';
  value?: string;
  darkMode?: boolean;
}

export function Code({ lang, darkMode, value, readOnly = true, ...props }: Props) {
  const extensions = useMemo(() => {
    if (lang === 'json') return [json()];
    return [xml()];
  }, [lang]);

  const codeMirrorTheme = useMemo(() => {
    if (darkMode) return githubDark;
    return githubLight;
  }, [darkMode]);

  return (
    <Suspense fallback={<div>Loading editor...</div>}>
      <LazyCodeMirror
        value={value}
        height="600px"
        lang={lang}
        extensions={extensions}
        theme={codeMirrorTheme}
        readOnly={readOnly}
        {...props}
      />
    </Suspense>
  );
}
