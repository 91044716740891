import React from 'react';

import dayjs from '~/timeInit';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

interface IOrderItem {
  reservation?: {
    id: string;
    has_vcc?: boolean;
  };
}

interface IReservations {
  [key: string]: {
    vcc_reservation: IVCCReservation;
  };
}

interface IVCCReservation {
  card_id: string;
  currency: string;
  deposit_amount: number;
  deposit_percent: number;
  deposit_state: string;
  total: number;
  type_of_pay: string;
}

const dateFormatter = (cell) => {
  const date = new Date(cell);
  return dayjs(date).format('Do MMM YYYY HH:mm:ss');
};

const debugFormatter = (cell) => {
  return JSON.stringify(cell);
};

export default function Row({ card }: { card }) {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{card.pan}</TableCell>
        <TableCell align="right">{card.expiry}</TableCell>
        <TableCell align="right">{card.validityFrom}</TableCell>
        <TableCell align="right">{card.validityTo}</TableCell>
        <TableCell size="small" align="right">
          {card.limit}
        </TableCell>
        <TableCell size="small" align="right">
          {card.balance}
        </TableCell>
        <TableCell size="small" align="right">
          {card.state}
        </TableCell>
        <TableCell size="small">{debugFormatter(card.customFields)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <TableContainer component={Paper}>
                <Table size="small" aria-label="purchases">
                  <TableHead
                    sx={{
                      backgroundColor: 'grey.200',
                    }}
                  >
                    <TableRow>
                      <TableCell>
                        <Typography variant="subtitle1" fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>
                          Created At
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="subtitle1" fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>
                          Old state
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="subtitle1" fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>
                          New state
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography variant="subtitle1" fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>
                          Attributes
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {card.history.map((historyRow) => (
                      <TableRow key={historyRow.date}>
                        <TableCell component="th" scope="row">
                          {dateFormatter(historyRow.createdAt)}
                        </TableCell>
                        <TableCell>{historyRow.oldState}</TableCell>
                        <TableCell align="left">{historyRow.newState}</TableCell>
                        <TableCell align="left">{debugFormatter(historyRow.customFields)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
