import React, { useCallback, useEffect, useState } from 'react';

import { ReceiptLongOutlined } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Chip, Typography, capitalize } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import Spinner from '~/components/Common/Spinner';

import * as OrdersService from '~/services/OrdersService';
import { getPayments } from '~/services/PaymentsService';
import { formatDateLongMonthWithMeridiemShort, formatDateShortDD } from '~/services/TimeService';
import UsersService from '~/services/UsersService';

import type { OrderItemLogRecord, OrderItemLogReservation } from '~/types/responses';

import currencyFormatter from '~/utils/currencyFormatter';

interface SingleItemLog {
  itemId: string;
  itemIndex: number;
  singleItemDescription: string;
}

type OrderItemLog = OrderItemLogRecord & {
  formattedDescription: string;
  user_type: string;
  user_name: string;
  items: Array<SingleItemLog>;
};

// @TODO make this mapping robust / actually test it
function itemTypeToDataType(itemType: string) {
  switch (itemType) {
    case 'accommodation':
      return 'accommodation_items';
    case 'flight':
      return 'flight_items';
    case 'bedbank':
      return 'bedbank_items';
    case 'transfer':
    case 'experience':
      return 'experience_items';
    case 'addon':
      return 'addon_items';
    case 'insurance':
      return 'insurance_items';
    case 'subscription':
      return 'subscription_items';
    case 'tour':
      return 'tour_items';
    case 'service_fee':
      return 'service_fee_items';
    default:
      console.error('Could not map', itemType);
      return null;
  }
}

function roleToType(role) {
  return role.replace(/-.*/, '');
}

function roleToHuman(role) {
  return toProperCase(roleToType(role));
}

function toProperCase(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function actionToHuman(action: string) {
  return toProperCase(action.replace(/_/g, ' '));
}

function fromDate(reservation: OrderItemLogReservation | null) {
  if (!reservation) {
    return null;
  }

  if (reservation.check_in) {
    return reservation.check_in;
  }

  if (reservation.start_date) {
    return reservation.start_date;
  }

  return null;
}

function toDate(reservation: OrderItemLogReservation | null) {
  if (!reservation) {
    return null;
  }

  if (reservation.check_out) {
    return reservation.check_out;
  }

  if (reservation.end_date) {
    return reservation.end_date;
  }

  return null;
}

function humanDates(from, to) {
  return `${formatDateShortDD(from)} - ${formatDateShortDD(to)}`;
}

function humanFromToDates(from, to, previousFrom, previousTo) {
  return `${humanDates(previousFrom, previousTo)} to ${humanDates(from, to)}`;
}

function rolesToHuman(roles) {
  if (roles.length === 0) {
    return 'Customer';
  }

  if (roles.includes('admin')) {
    return 'Admin';
  }

  if (roles.includes('employee')) {
    return 'Employee';
  }

  return roles.map(roleToHuman).join(', ');
}

function formatSource(source: string) {
  switch (source) {
    case 'admin-portal':
      return 'Admin Portal';
    case 'customer-portal':
      return 'Customer Portal';
    case 'vendor-portal':
      return 'Vendor Portal';
    case 'svc-order-script':
      return 'System';
    default:
      return 'Unknown';
  }
}

function itemDescription(item: App.AnyItem, itemType: string): string {
  switch (itemType) {
    case 'accommodation':
    case 'items':
      return item.offer_package.name;
    case 'addon':
    case 'custom_offer':
      return item.name;
    case 'car_hire':
      return `${item.reservation?.vehicle.model}: ${item.reservation?.pickUp.locationName} - ${item.reservation?.return.locationName}`;
    case 'cruise':
      return item.offer_name;
    case 'experience':
      return item.title;
    case 'bedbank':
      return item.offer.room.name;
    case 'insurance':
      return item.product_name;
    case 'subscription':
      return item.sub_type === 'joining_fee' ? 'Lux Plus Joining Fee' : 'Lux Plus Subscription';
    case 'tour':
      return item.tour_name;
    case 'service_fee':
      return 'Service Fee Item';
    default:
      return itemType;
  }
}

export function humanDescription(log: OrderItemLogRecord, item: App.AnyItem, order: App.Order): string {
  const reservation = log.reservation;
  const previousReservation = log.previous_reservation;
  if (log.action === 'create' && reservation) {
    return `Reservation created with dates ${humanDates(
      fromDate(reservation as OrderItemLogReservation),
      toDate(reservation as OrderItemLogReservation),
    )}`;
  }

  if (log.action === 'create') {
    return 'Created';
  }

  if (log.action === 'complete') {
    return 'Transaction completed';
  }

  if (log.action === 'mark_reminder_sent') {
    return 'Reminder email sent';
  }

  if (log.action === 'change_dates' || log.action === 'change_reservation') {
    const mes = [
      `Changed dates from ${humanFromToDates(
        fromDate(reservation as OrderItemLogReservation),
        toDate(reservation as OrderItemLogReservation),
        fromDate(previousReservation),
        toDate(previousReservation),
      )}`,
    ];

    if (log.action === 'change_reservation') {
      const guestFirstName = (reservation as OrderItemLogReservation).guest_first_name;
      const guestLastName = (reservation as OrderItemLogReservation).guest_last_name;

      mes.push(
        `Changed guest name from ${previousReservation.guest_first_name} ${previousReservation.guest_last_name} to ${guestFirstName} ${guestLastName}`,
      );
    }

    return mes.join(' and ');
  }

  if (log.action === 'change_guest_details') {
    const guestFirstName = (reservation as OrderItemLogReservation).guest_first_name;
    const guestLastName = (reservation as OrderItemLogReservation).guest_last_name;

    return `Changed guest details from ${previousReservation.guest_first_name} ${previousReservation.guest_last_name} to ${guestFirstName} ${guestLastName}`;
  }

  if (log.action === 'await_dates') {
    return 'Purchased and awaiting date selection';
  }

  if (log.action === 'set_reservation') {
    return `Selected dates ${humanDates(
      fromDate(reservation as OrderItemLogReservation),
      toDate(reservation as OrderItemLogReservation),
    )}`;
  }

  if (log.action === 'set_booking_date') {
    return `Selected date ${formatDateShortDD(reservation as string)}`;
  }

  if (log.action === 'change_package' && item.downgrade_type) {
    return `${actionToHuman(item.downgrade_type)}`;
  }

  if (log.action === 'change_package') {
    const relatedItems = order[itemTypeToDataType(item.type)].filter(
      (orderItem) =>
        (orderItem.fk_downgraded_from_id == item.id || orderItem.fk_downgraded_from_id == item.id_items) &&
        new Date(orderItem.created_at).getTime() < new Date(log.time).getTime(),
    );
    const mostRecentItem = relatedItems.sort(
      (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
    )[0];

    const items = [...order[itemTypeToDataType(item.type)]].sort(
      (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
    );
    const itemIndex =
      items.findIndex((item) => item.id_items === mostRecentItem.id || item.id === mostRecentItem.id) + 1;
    return `${actionToHuman(log.action)} to ${itemDescription(mostRecentItem, item.type)}${
      itemIndex > 0 ? ' #' + itemIndex : ''
    }`;
  }

  if (log?.description) {
    return log.description;
  }

  return actionToHuman(log.action);
}

const loadedUsers = new Map<string, App.User>();

async function addUserInfo(log: OrderItemLog): Promise<OrderItemLog> {
  if (log.system_user) {
    log.user_type = 'Admin';
    log.user_name = 'System';
    return log;
  }

  let user: App.User;

  if (loadedUsers.has(log._links.user.href)) {
    user = loadedUsers.get(log._links.user.href);
  } else {
    user = await UsersService.getUserByUrl(log._links.user.href);
    loadedUsers.set(log._links.user.href, user);
  }

  log.user_type = rolesToHuman(user.roles);
  log.user_name = user.fullName;

  return log;
}

async function getLogs(
  order: App.Order,
  itemId: string,
  allItems: Array<{ id: string; type: string }>,
): Promise<Array<OrderItemLog>> {
  const response = await OrdersService.getOrderItemLogs(order.id_orders, itemId);

  const logs: Array<OrderItemLog> = [];

  for (const logItem of response.result) {
    const itemType = allItems.find((item) => item.id === itemId)?.type;
    const items = [...order[itemTypeToDataType(itemType)]].sort(
      (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
    );
    const itemIndex = items.findIndex((item) => item.id_items === itemId || item.id === itemId);
    const item = items[itemIndex];
    const formattedDescription = humanDescription(logItem, item, order);
    const singleItemDescription = itemDescription(item, itemType);

    const log: OrderItemLog = Object.assign(
      {
        formattedDescription,
        user_type: '',
        user_name: '',
        items: [{ itemId, itemIndex: itemIndex + 1, singleItemDescription }],
      },
      logItem,
    );
    logs.push(await addUserInfo(log));
  }

  return logs;
}

async function getPaymentLogs(
  order: App.Order,
  allItems: Array<{ id: string; type: string }>,
): Promise<Array<OrderItemLog>> {
  const response = await getPayments(order.id_orders);

  const newLogs: Array<OrderItemLog> = [];

  response.result.forEach((payment) => {
    if (payment?.items_breakdown?.length > 0) {
      payment.items_breakdown.forEach((itemPayment) => {
        const itemType = allItems.find((item) => item.id === itemPayment.id_items)?.type;
        const items = [...order[itemTypeToDataType(itemType)]].sort(
          (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
        );
        const itemIndex = items.findIndex(
          (item) => item.id_items === itemPayment.id_items || item.id === itemPayment.id_items,
        );
        if (itemIndex === -1) {
          console.error("couldn't find item", itemPayment.id_items);
        }
        const item = items[itemIndex];
        const formattedAmount = currencyFormatter(payment.currency, itemPayment.amount, 2, 'code');

        newLogs.push({
          time: payment.created_at,
          user_type: 'System',
          user_name: 'Payment System',
          items: [
            {
              itemId: itemPayment.id_items,
              itemIndex: itemIndex + 1,
              singleItemDescription: `${itemDescription(item, itemType)}`,
            },
          ],
          formattedDescription: `${capitalize(payment.status)} ${capitalize(payment.type)} ${capitalize(
            payment.intent,
          )} of amount ${formattedAmount}`,
        } as OrderItemLog);
      });
    } else {
      const itemsArray: Array<SingleItemLog> = [];
      payment.item_ids.forEach((itemId) => {
        const itemType = allItems.find((item) => item.id === itemId)?.type;
        const orderItems = [...order[itemTypeToDataType(itemType)]].sort(
          (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
        );
        const itemIndex = orderItems.findIndex((item) => item.id_items === itemId || item.id === itemId);
        const item = orderItems[itemIndex];
        if (itemIndex === -1) {
          console.error("couldn't find item", itemId);
        }

        itemsArray.push({
          itemId,
          itemIndex: itemIndex + 1,
          singleItemDescription: `${itemDescription(item, itemType)}`,
        });
      });

      const formattedAmount = currencyFormatter(payment.currency, parseFloat(payment.amount), 2, 'code');

      newLogs.push({
        time: payment.created_at,
        user_type: 'System',
        user_name: 'Payment System',
        items: itemsArray,
        formattedDescription: `${capitalize(payment.status)} ${capitalize(payment.type)} ${capitalize(
          payment.intent,
        )} of amount ${formattedAmount}`,
      } as OrderItemLog);
    }
  });

  return newLogs;
}

// Function to generate a consistent pastel color from a string (item ID)
function generatePastelColor(str: string): string {
  // Create a hash from the string
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Generate HSL color with high lightness (pastel) for readability with black text
  // Using HSL to ensure colors are light enough (80-95% lightness)
  const h = Math.abs(hash) % 360; // Hue: 0-359
  const s = 50 + (Math.abs(hash) % 30); // Saturation: 50-79%
  const l = 85 + (Math.abs(hash) % 10); // Lightness: 85-94%

  return `hsl(${h}, ${s}%, ${l}%)`;
}

const columns: Array<GridColDef<OrderItemLog>> = [
  {
    field: 'time',
    headerName: 'Date',
    sortable: true,
    disableColumnMenu: true,
    flex: 0.75,
    valueFormatter: (value) => formatDateLongMonthWithMeridiemShort(value),
    display: 'flex',
  },
  {
    field: 'user_type',
    headerName: 'User Type',
    sortable: false,
    disableColumnMenu: true,
    flex: 0.5,
    display: 'flex',
  },
  {
    field: 'user_name',
    headerName: 'User',
    sortable: false,
    disableColumnMenu: true,
    flex: 0.5,
    display: 'flex',
  },
  {
    field: 'singleItemDescription',
    headerName: 'Item',
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      return (
        // @TODO: onclick, jump to the item
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'flex-start', width: '100%' }}>
          {params.row.items.map((item) => {
            return (
              <Chip
                key={item.itemId}
                label={`${item.singleItemDescription}${item.itemIndex > 0 ? ' #' + item.itemIndex : ''}`}
                sx={{
                  backgroundColor: generatePastelColor(item.itemId),
                  color: 'black',
                  // multi line wrapped text
                  height: 'auto',
                  '& .MuiChip-label': {
                    whiteSpace: 'normal',
                    display: 'block',
                    textAlign: 'left',
                    padding: '8px 12px',
                  },
                }}
              />
            );
          })}
        </Box>
      );
    },
    flex: 1,
    display: 'flex',
  },
  {
    field: 'formattedDescription',
    headerName: 'Action',
    sortable: false,
    disableColumnMenu: true,
    flex: 2,
    display: 'flex',
    renderCell: (params) => (
      <Typography sx={{ whiteSpace: 'pre-wrap', lineHeight: '2' }} fontWeight="bold">
        {params.value}
      </Typography>
    ),
  },
  {
    field: 'source',
    headerName: 'Source',
    sortable: false,
    disableColumnMenu: true,
    flex: 0.7,
    display: 'flex',
    valueFormatter: (value) => formatSource(value),
  },
];

interface Props {
  order: App.Order;
}

export default function OrderHistoricalLogs({ order }: Props) {
  const [loadingState, setLoadingState] = useState<Utils.FetchingState>('idle');
  const [logs, setLogs] = useState<Array<OrderItemLog>>([]);
  const [open, setOpen] = useState(false);

  const handleChange = useCallback(async (_: React.SyntheticEvent | null, isExpanded: boolean) => {
    try {
      setOpen(isExpanded);

      if (!isExpanded) {
        setLoadingState('idle');
        return;
      }

      setLoadingState('loading');
      setLogs([]);
      // @TODO can we optimise this?
      const itemKeys = Object.keys(order).filter((key) => key.includes('items'));
      const allItems = new Set(
        itemKeys.flatMap((key) => {
          if (key === 'items' || key.includes('has')) {
            // duplicate with accommodation items
            return [];
          }

          const items = order[key];
          return Array.isArray(items)
            ? items.map((item) => ({
                id: item.id,
                type: key.replace('_items', '').toLowerCase(),
              }))
            : [];
        }),
      );

      const allItemsArray = Array.from(allItems);

      const allItemIdArray = allItemsArray.map((item) => item.id);

      const itemLogs = await Promise.all(allItemIdArray.map((itemId) => getLogs(order, itemId, allItemsArray)));

      const paymentLogs = await getPaymentLogs(order, allItemsArray);

      setLogs([...itemLogs.flat(), ...paymentLogs]);
      setLoadingState('success');
    } catch (err) {
      setLoadingState('failed');
    }
  }, []);

  useEffect(() => {
    // Open logs by default
    handleChange(null, true);
  }, [handleChange]);

  return (
    <Accordion
      onChange={handleChange}
      defaultExpanded={false}
      variant="outlined"
      id="historical-order-logs"
      slotProps={{ transition: { unmountOnExit: true } }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box display="flex" alignItems="center">
          <ReceiptLongOutlined sx={{ mr: 1 }} />
          <Typography variant="h6">Historical Logs</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        {loadingState === 'loading' && <Spinner size={32} />}
        {loadingState === 'success' && (
          <DataGrid
            rows={logs}
            columns={columns}
            getRowId={(row) => `${row.time}-${row.items.map((item) => item.itemId).join('-')}`}
            pageSizeOptions={[]}
            disableColumnFilter
            disableColumnMenu
            disableRowSelectionOnClick
            autoHeight
            hideFooter
            getRowHeight={() => 'auto'}
            sx={{
              '& .MuiDataGrid-row': {
                minHeight: '50px !important', // Add some minimum height to rows
                paddingTop: '8px',
                paddingBottom: '8px',
              },
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: 'time', sort: 'desc' }],
              },
            }}
          />
        )}
      </AccordionDetails>
    </Accordion>
  );
}
