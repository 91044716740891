import { request } from '~/services/common';

import { makeCustomerCommunicationV1URL } from './customerCommunicationRequestUtils';

async function requestDeleteCustomSchedule(scheduleId: string) {
  if (!scheduleId) {
    throw 'missing scheduleId';
  }

  const url = makeCustomerCommunicationV1URL(`/custom-schedules/${scheduleId}`);

  return request(url.toString(), {
    method: 'DELETE',
    credentials: 'include',
  }).catch((error) => {
    throw JSON.stringify(error, null, 2);
  });
}

export default requestDeleteCustomSchedule;
