import qs from 'qs';

import { CruisesContract } from '@luxuryescapes/contract-svc-cruise';

import { json_headers, request } from '../common';

type IGet = {
  resource: string;
  queryString?: string;
  paginationParams?: CruisesContract.PaginationParams;
};

type IPost = {
  resource: string;
  body?: Record<string, unknown>;
};

type IPut = {
  resource: string;
  body?: Record<string, unknown>;
};

type IDelete = {
  resource: string;
};

const BASE_PATH = (version: number) => window.configs.API_HOST + '/api/cruise/' + `v${version}`;

function buildHttp(version: number) {
  return {
    get: async <T>(params: IGet, headers?: Record<string, string>): Promise<CruisesContract.Response<T>> => {
      const queryParams = {};
      const { resource, queryString, paginationParams } = params;

      if (paginationParams) Object.assign(queryParams, paginationParams);

      if (!!queryString && queryString !== '') {
        Object.assign(queryParams, JSON.parse(queryString));
      }

      const stringifiedQueryParams = Object.keys(queryParams).length !== 0 ? `?${qs.stringify(queryParams)}` : '';

      const url = `${BASE_PATH(version)}/${resource}${stringifiedQueryParams}`;

      return request(url, {
        method: 'GET',
        headers,
      });
    },

    post: async <T>({ resource, body }: IPost): Promise<T> => {
      const url = `${BASE_PATH(version)}/${resource}`;

      return request(url, {
        method: 'POST',
        headers: json_headers,
        body: JSON.stringify(body),
      });
    },

    patch: async <T>({ resource, body }: IPut): Promise<CruisesContract.Response<T>> => {
      const url = `${BASE_PATH(version)}/${resource}`;

      return request(url, {
        method: 'PATCH',
        headers: json_headers,
        body: JSON.stringify(body),
      });
    },

    put: async <T>({ resource, body }: IPut): Promise<CruisesContract.Response<T>> => {
      const url = `${BASE_PATH(version)}/${resource}`;

      return request(url, {
        method: 'PUT',
        headers: json_headers,
        body: JSON.stringify(body),
      });
    },

    delete: async <T>({ resource }: IDelete): Promise<CruisesContract.Response<T>> => {
      const url = `${BASE_PATH(version)}/${resource}`;
      return request(url, { method: 'DELETE' });
    },
  };
}

const httpV1 = buildHttp(1);
const http = buildHttp(2);

export default { httpV1, http };
