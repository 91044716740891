import React from 'react';

import { useSelector } from 'react-redux';

import { Box } from '@mui/material';

import DefinitionText from '~/components/Common/Elements/DefinitionText';
import DefinitionTitle from '~/components/Common/Elements/DefinitionTitle';
import UserName from '~/components/Common/UserName';

import { formatDateOrdinalWithClock } from '~/services/TimeService';

import { getDisputeBoxStyle, selectShowDisputeProcess } from '../utils/getDisputeProcess';

function formatDate(value) {
  return formatDateOrdinalWithClock(value);
}

type Props = {
  order: App.Order;
};

export default function Meta({ order }: Props) {
  const showDisputeProcess = useSelector(selectShowDisputeProcess);
  return (
    <Box display="grid" gap={2} gridTemplateColumns="repeat(4, 1fr)">
      <div style={getDisputeBoxStyle(showDisputeProcess)}>
        <DefinitionTitle>Customer name</DefinitionTitle>
        <DefinitionText>
          <UserName asLink userId={order.fk_customer_id} />
        </DefinitionText>
      </div>

      <div style={getDisputeBoxStyle(showDisputeProcess)}>
        <DefinitionTitle>Order Placed</DefinitionTitle>
        <DefinitionText>{formatDate(order.created_at)}</DefinitionText>
      </div>

      <div>
        <DefinitionTitle>Order Status</DefinitionTitle>
        <DefinitionText>{order.status}</DefinitionText>
      </div>

      <div>
        <DefinitionTitle>Source</DefinitionTitle>
        <DefinitionText>{order.utm_source}</DefinitionText>
      </div>

      <div>
        <DefinitionTitle>Sold By</DefinitionTitle>
        <DefinitionText>
          <UserName userId={order.fk_purchaser_id} />
        </DefinitionText>
      </div>

      <div>
        <DefinitionTitle>Campaign</DefinitionTitle>
        <DefinitionText>{order.utm_campaign}</DefinitionText>
      </div>

      <div>
        <DefinitionTitle>Device</DefinitionTitle>
        <DefinitionText>{order.utm_medium}</DefinitionText>
      </div>

      <div>
        <DefinitionTitle>Attribution</DefinitionTitle>
        <DefinitionText>{order.le_attribution}</DefinitionText>
      </div>
    </Box>
  );
}
