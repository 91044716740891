import React, { useState } from 'react';

import currencyFormatter from 'currency-formatter';
import { useSnackbar } from 'notistack';

import { InfoRounded } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SavingsIcon from '@mui/icons-material/SavingsOutlined';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Link,
  Modal,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import { linkManualStripePayment } from '~/services/PaymentsService';
import { formatDateShort } from '~/services/TimeService';

function OrderDepositDetails({ depositDetails }) {
  const [stripePI, setStripePI] = useState('');
  const [linkInfoModalIsOpen, setLinkInfoModalIsOpen] = useState(false);
  const [linkButtonIsDisabled, setLinkButtonIsDisabled] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const formatDate = (cell) => {
    const date = new Date(cell);
    return formatDateShort(date);
  };

  const getStripeSubscriptionLink = () => {
    const subscription = depositDetails?.deposit_metadata?.subscription;
    if (subscription?.id) {
      const isTest = subscription.livemode ? '' : '/test';
      return `https://dashboard.stripe.com${isTest}/subscription_schedules/${subscription.id}`;
    } else {
      return 'N/A';
    }
  };

  const getBalanceAmount = () => {
    return depositDetails?.balance_amount
      ? currencyFormatter.format(depositDetails.balance_amount, {
          code: depositDetails.currency,
          precision: 2,
        })
      : 'N/A';
  };

  const getBalanceDueDate = () => {
    return depositDetails?.balance_due_date ? formatDate(depositDetails.balance_due_date) : 'N/A';
  };

  const getBalancePaidDate = () => {
    return depositDetails?.balance_paid_date ? formatDate(depositDetails.balance_paid_date) : 'N/A';
  };

  const handleStripeLinkageOnClick = async () => {
    try {
      setLinkButtonIsDisabled(true);
      if (!stripePI) {
        setLinkButtonIsDisabled(false);
        enqueueSnackbar('Please add Stripe PI', { variant: 'error' });
        return;
      }
      await linkManualStripePayment(stripePI, { orderId: depositDetails?.fk_orders });
      setLinkButtonIsDisabled(false);
      window.location.reload();
    } catch (err) {
      setLinkButtonIsDisabled(false);
      if (err.errors) {
        enqueueSnackbar(err.errors.join(', '), { variant: 'error' });
        return;
      }
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  const isBalancePaid = !!depositDetails?.balance_paid_date;

  return (
    <>
      <Modal
        open={linkInfoModalIsOpen}
        onClose={() => setLinkInfoModalIsOpen(false)}
        aria-labelledby="Link Manual Stripe Payment"
      >
        <Box
          height={400}
          width={400}
          bgcolor="background.paper"
          boxShadow={24}
          p={4}
          gap={2}
          display="flex"
          flexDirection="column"
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Typography variant="h6" fontWeight="bold">
            Link manual stripe payment guide
          </Typography>
          <Typography textAlign="left">
            Manual payment and deposit must belong to the same stripe customer account.
          </Typography>
          <Typography textAlign="left">
            One or more manual payments (one at a time) may be attached but only when tne sum of these payments is
            greater than the balance will the deposit status be updated with a shortfall allowance of $1.00.
          </Typography>
          <Typography textAlign="left">
            All successfully linked manual payments will appear in the transactions table. If a valid subscription
            exists then it will auto cancel once the deposit status is updated.
          </Typography>
        </Box>
      </Modal>
      <Accordion defaultExpanded sx={{ mt: 2 }} id="deposit-details">
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box display="flex" alignItems="center">
            <SavingsIcon sx={{ mr: 1 }} />
            <Typography variant="h6">Deposit Details</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Stack direction="row" justifyContent="space-between">
            <Stack>
              <Typography variant="h6" fontWeight="bold">
                Deposit Status
              </Typography>
              <Typography>{depositDetails.deposit_status}</Typography>
            </Stack>
            <Stack>
              <Typography variant="h6" fontWeight="bold">
                Deposit Amount
              </Typography>
              <Typography>
                {currencyFormatter.format(depositDetails.paid_amount, {
                  code: depositDetails.currency,
                  precision: 2,
                })}
              </Typography>
            </Stack>
            <Stack>
              <Typography variant="h6" fontWeight="bold">
                Due balance amount
              </Typography>
              <Typography>{getBalanceAmount()}</Typography>
            </Stack>
            <Stack>
              <Typography variant="h6" fontWeight="bold">
                Balance due date
              </Typography>
              <Typography>{getBalanceDueDate()}</Typography>
            </Stack>
            <Stack>
              <Typography variant="h6" fontWeight="bold">
                Balance Paid Date
              </Typography>
              <Typography>{getBalancePaidDate()}</Typography>
            </Stack>
            <Stack>
              <Typography variant="h6" fontWeight="bold">
                Balance Paid Status
              </Typography>
              <Typography color={isBalancePaid ? 'green' : 'dark'}>{isBalancePaid ? 'True' : 'False'}</Typography>
            </Stack>
          </Stack>
          <Stack direction="row" justifyContent="space-between" mt={2}>
            <Stack>
              <Typography variant="h6" fontWeight="bold">
                Stripe subscription link
              </Typography>
              <Typography>
                <Link href={getStripeSubscriptionLink()} target="_blank" rel="noreferrer">
                  {getStripeSubscriptionLink()}
                </Link>
              </Typography>
            </Stack>
            <Stack>
              <Typography variant="h6" fontWeight="bold">
                Auto debit failure count*
              </Typography>
              <Typography>{depositDetails.due_balance_auto_debit_failed_count}</Typography>
            </Stack>
          </Stack>
          <Typography textAlign="right" variant="subtitle1">
            *indicates how many times the automatic payment for the due balance amount has failed. The booking is
            automatically cancelled after 3 failures.
          </Typography>
          <Stack>
            <Typography variant="h6" fontWeight="bold">
              Link manual stripe payment
            </Typography>
            <Stack direction="row" justifyContent="flex-start" mt={2}>
              <TextField
                label="Stripe PI"
                name="Stripe PI"
                placeholder="pi_3Qum2TFUtwYrZPVC0uik29u8"
                onChange={(event) => setStripePI(event.target.value)}
                value={stripePI}
                inputProps={{ maxLength: 100 }}
                style={{ marginRight: '5px' }}
              />
              <Button
                disabled={linkButtonIsDisabled}
                variant="outlined"
                size="small"
                onClick={handleStripeLinkageOnClick}
              >
                Link Stripe Payment PI
              </Button>
              <Stack justifyContent="center">
                <InfoRounded fontSize="small" sx={{ ml: 0.5 }} onClick={() => setLinkInfoModalIsOpen(true)} />
              </Stack>
            </Stack>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default OrderDepositDetails;
