import { createSelector } from 'reselect';

export const selectBedbankReservation = (state: App.State, reservationId: string) => {
  return state.bedbankReservation.adminView[reservationId];
};

export const selectBedbankReservationEmployeeView = (state: App.State, reservationId: string) => {
  return state.bedbankReservation.employeeView[reservationId];
};

export const selectBedbankRefundPoliciesEmployeeView = createSelector(
  selectBedbankReservationEmployeeView,
  (reservation?: App.Bedbank.Reservation) => {
    if (reservation?.rooms?.length) {
      const policies = {};
      for (const room of reservation.rooms) {
        const customerPolicy: App.Bedbank.RefundPolicy = {
          refundable: room.refundable,
          partiallyRefundable: room.partiallyRefundable,
          nonRefundableDateRanges: room.nonRefundableDateRanges,
          cancellationPolicies: room.cancellationPolicies,
        };
        const supplierPolicy: App.Bedbank.RefundPolicy = {
          refundable: room.supplierRefundable,
          partiallyRefundable: room.supplierPartiallyRefundable,
          nonRefundableDateRanges: room.supplierNonRefundableDateRanges,
          cancellationPolicies: room.supplierCancellationPolicies,
        };

        policies[room.id] = {
          customerPolicy,
          supplierPolicy,
        };
      }
      return policies;
    }
  },
);
