import React, { useCallback, useMemo, useState } from 'react';

import { useParams } from 'react-router';

import ListAltIcon from '@mui/icons-material/ListAlt';
import { Alert, Box, Chip, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import InternalPropertyLink from '~/components/Accommodation/Pages/Properties/components/InternalPropertyLink';
import MappingLogModal from '~/components/Accommodation/Pages/Properties/components/MappingLogModal';
import { AccommodationPropertySupplierResultsTableRef } from '~/components/Accommodation/Pages/PropertyDetails/sections/AccommodationPropertySuppliers/AccommodationPropertySupplierResultsTable';
import AccommodationVerifyMapping from '~/components/Accommodation/components/AccommodationVerifyMapping/AccommodationVerifyMapping';
import { useAccommodationServiceFetch } from '~/components/Accommodation/hooks/useAccommodationServiceFetch';

import useToggleState from '~/hooks/useToggleState';

import {
  InternalProperty,
  MappedInternalProperty,
  listMappedInternalProperties,
} from '~/services/AccommodationService';

import AccommodationPropertySupplierRatesEnabledModal from './AccommodationPropertySupplierRatesEnabledModal';

const PAGE_SIZE = 20;

interface Props {
  externalRef: React.RefObject<AccommodationPropertySupplierResultsTableRef>;
}

function AccommodationPropertyPotentialSuppliersResultsTable({ externalRef }: Props) {
  const { propertyId } = useParams<{ propertyId: string }>();
  const [selectedInternalProperty, setSelectedInternalProperty] = useState<InternalProperty | null>(null);
  const [logsId, setLogsId] = useState<string | null>(null);

  const {
    isToggled: isSupplierRatesEnabledModalOpen,
    toggleOn: setOpenSupplierRatesEnabledModal,
    toggleOff: setCloseSupplierRatesEnabledModal,
  } = useToggleState(false);

  const {
    isToggled: isMappingLogModalOpen,
    toggleOn: setOpenMappingLogModal,
    toggleOff: setCloseMappingLogModal,
  } = useToggleState(false);

  const getSuppliers = useCallback(
    () => listMappedInternalProperties({ propertyId, verificationStatuses: ['Pending'] }),
    [propertyId],
  );

  const { fetchReqState, refetch } = useAccommodationServiceFetch({
    fetchFn: getSuppliers,
  });

  const handleRatesEnabledClick = useCallback(
    (internalProperty: InternalProperty) => () => {
      setSelectedInternalProperty(internalProperty);
      setOpenSupplierRatesEnabledModal();
    },
    [setOpenSupplierRatesEnabledModal],
  );

  const closeRatesEnabledModal = useCallback(() => {
    setCloseSupplierRatesEnabledModal();
    setSelectedInternalProperty(null);
  }, [setCloseSupplierRatesEnabledModal]);

  const handleSupplierToggleRatesComplete = useCallback(() => {
    refetch();
    closeRatesEnabledModal();
  }, [refetch, closeRatesEnabledModal]);

  const showMappingLogs = useCallback(
    async (logsId: string) => {
      setLogsId(logsId);
      setOpenMappingLogModal();
    },
    [setOpenMappingLogModal],
  );

  const doRefetches = useCallback(() => {
    refetch();
    externalRef.current?.refresh();
  }, [refetch, externalRef]);

  const columns: Array<GridColDef<MappedInternalProperty>> = useMemo(
    () => [
      {
        field: 'name',
        headerName: 'Supplier Property Name',
        minWidth: 200,
        display: 'flex',
        flex: 1,
        hideable: true,
        valueGetter: (_value, row) => row.internalProperty.name,
      },
      {
        field: 'source',
        headerName: 'Source',
        maxWidth: 240,
        minWidth: 150,
        display: 'flex',
        hideable: true,
        valueGetter: (_value, row) => row.internalProperty.source,
      },
      {
        field: 'sourceId',
        headerName: 'ID',
        maxWidth: 240,
        minWidth: 200,
        display: 'flex',
        hideable: true,
        renderCell: (params) => {
          return (
            <InternalPropertyLink
              source={params.row.internalProperty.source}
              sourceId={params.row.internalProperty.sourceId}
            />
          );
        },
      },
      {
        field: 'mappedSupplierProperties',
        headerName: 'Supplier - ID',
        minWidth: 200,
        flex: 1,
        renderCell: (params) => {
          const supplierProperties = params.row.internalProperty.supplierProperties || [];
          return (
            <Box>
              {supplierProperties.map((supplier, index) => (
                <Typography key={index} variant="body2">
                  {supplier.supplier} - {supplier.supplierId}
                </Typography>
              ))}
            </Box>
          );
        },
      },
      {
        field: 'sppEnabled',
        headerName: 'Status',
        maxWidth: 240,
        minWidth: 150,
        display: 'flex',
        hideable: true,
        renderCell: (params) => {
          if (params.row.internalProperty.sppEnabled) {
            return (
              <Chip
                label="Rates Enabled"
                variant="outlined"
                color="success"
                onClick={handleRatesEnabledClick(params.row.internalProperty)}
              />
            );
          }
          return (
            <Chip
              label="Rates Disabled"
              variant="outlined"
              color="warning"
              onClick={handleRatesEnabledClick(params.row.internalProperty)}
            />
          );
        },
      },
      {
        field: ' ',
        headerName: 'Actions',
        headerAlign: 'center',
        width: 150,
        sortable: false,
        align: 'center',
        display: 'flex',
        renderCell: (params) => {
          return (
            <Stack direction="row" gap={1}>
              <IconButton onClick={() => showMappingLogs(params.row.internalProperty.id)} color="info">
                <Tooltip title="Show mapping logs">
                  <ListAltIcon fontSize="medium" />
                </Tooltip>
              </IconButton>
              <AccommodationVerifyMapping
                propertyId={propertyId}
                mappedPropertyId={params.row.id}
                onComplete={doRefetches}
              />
            </Stack>
          );
        },
      },
    ],
    [handleRatesEnabledClick, propertyId, refetch, showMappingLogs],
  );

  return (
    <>
      {fetchReqState.status === 'failed' && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {JSON.stringify(fetchReqState.error)}
        </Alert>
      )}
      <Typography variant="h6">Potential Suppliers</Typography>
      <DataGrid
        className="accommodation-property-suppliers-results-table"
        columns={columns}
        rows={fetchReqState.status === 'succeeded' ? fetchReqState.result : []}
        rowCount={fetchReqState.status === 'succeeded' ? fetchReqState.result.length : undefined}
        getRowId={(row) => row.id}
        getRowHeight={() => 'auto'}
        pageSizeOptions={[PAGE_SIZE]}
        pagination
        loading={fetchReqState.status === 'pending'}
        autoHeight
        disableColumnFilter
        disableDensitySelector
      />
      {selectedInternalProperty && isSupplierRatesEnabledModalOpen && (
        <AccommodationPropertySupplierRatesEnabledModal
          propertyId={propertyId}
          internalProperty={selectedInternalProperty}
          onClose={closeRatesEnabledModal}
          onComplete={handleSupplierToggleRatesComplete}
        />
      )}
      {isMappingLogModalOpen && (
        <MappingLogModal
          isMappingLogModalOpen={isMappingLogModalOpen}
          setCloseMappingLogModal={setCloseMappingLogModal}
          logsSourceId={logsId}
          type="property"
        />
      )}
    </>
  );
}

export default AccommodationPropertyPotentialSuppliersResultsTable;
