import React from 'react';

import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { Box, Container, Tab, Tabs } from '@mui/material';

import useCurrentUser from '~/hooks/useCurrentUser';

import PageHeader from '../Common/Elements/PageHeader';

import PriceParrotScheduler from './PriceParrotScheduler';
import PriceParrotShopper from './PriceParrotShopper';

const TabsMap = {
  shopper: {
    label: 'Shopper',
    slug: 'shopper',
    component: PriceParrotShopper,
  },
  scheduler: {
    label: 'Scheduler',
    slug: 'scheduler',
    component: PriceParrotScheduler,
  },
};
type TabKey = keyof typeof TabsMap;
const allTabs = Object.keys(TabsMap);

export default function PriceParrotPageContainer() {
  const { section = 'shopper' } = useParams<{ section?: TabKey }>();
  const { isAuthenticated, isLoading } = useCurrentUser();
  const currentTab = TabsMap[section];
  const title = `Price Parrot / ${currentTab.label}`;

  if (!isAuthenticated || isLoading) {
    return null;
  }

  return (
    <Container maxWidth="xl" sx={{ backgroundColor: 'white' }}>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <PageHeader title={title} />

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={section} variant="scrollable" scrollButtons="auto">
          {allTabs.map((tab) => (
            <Tab
              key={tab}
              value={tab}
              label={TabsMap[tab].label}
              to={`/price-parrot/${tab}`}
              component={Link}
              sx={{
                minWidth: 30,
                padding: '12px 10px',
              }}
            />
          ))}
        </Tabs>
      </Box>

      <Box mt={4}>
        <Box>
          <currentTab.component />
        </Box>
      </Box>
    </Container>
  );
}
