import React, { ReactNode } from 'react';

import { Typography } from '@mui/material';

interface Props {
  children: ReactNode;
}

function PageHeaderTitle({ children }: Props) {
  return <Typography variant="h5">{children}</Typography>;
}

export default PageHeaderTitle;
