import React, { useCallback, useEffect, useState } from 'react';

import debounce from 'lodash/debounce';
import { useSnackbar } from 'notistack';

import LinkIcon from '@mui/icons-material/Link';
import {
  Alert,
  Box,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';

import AccommodationConfirmWithOverrideDialog from '~/components/Accommodation/Pages/Properties/components/AccommodationConfirmWithOverrideDialog';
import { AccommodationPropertiesSearchInput } from '~/components/Accommodation/Pages/Properties/components/AccommodationPropertiesSearchForm';
import InternalPropertyLink from '~/components/Accommodation/Pages/Properties/components/InternalPropertyLink';
import { useAccommodationServiceFetch } from '~/components/Accommodation/hooks/useAccommodationServiceFetch';

import useToggleState from '~/hooks/useToggleState';

import {
  InternalProperty,
  ValidationErrorDetails,
  getUnmappedSuppliers,
  mapInternalProperty,
} from '~/services/AccommodationService';

interface Props {
  open: boolean;
  propertyId: string;
  onClose: () => void;
}

const PAGE_SIZE = 20;

export default function AccommodationAddPropertyFinder({ open, propertyId, onClose }: Props) {
  const [supplierPropertyName, setSupplierPropertyName] = useState('');
  const [searchInput, setSearchInput] = useState<AccommodationPropertiesSearchInput>({ propertyName: '', id: '' });
  const [pageIndex, setPageIndex] = useState(0);
  const [selectedInternalProperty, setSelectedInternalProperty] = useState<InternalProperty | null>(null);
  const {
    isToggled: isConfirmDialogOpen,
    toggleOn: setOpenConfirmDialog,
    toggleOff: setCloseConfirmDialog,
  } = useToggleState(false);
  const { enqueueSnackbar } = useSnackbar();

  const clearSearches = useCallback(() => {
    setSupplierPropertyName('');
  }, []);

  const setSearch = useCallback((name: string) => {
    setSupplierPropertyName(name);
    debounceSetSearchInput(name);
  }, []);

  const debounceSetSearchInput = debounce((name: string) => {
    if (name.length === 0 || name.length >= 3) {
      setSearchInput((prev) => ({ ...prev, propertyName: name }));
    }
  }, 300);

  const getProperties = useCallback(
    () =>
      getUnmappedSuppliers({
        name: searchInput.propertyName,
        page: String((pageIndex ?? 0) + 1),
        limit: String(PAGE_SIZE),
        verificationStatuses: ['Rejected', 'Pending'],
      }),
    [pageIndex, searchInput],
  );

  const { fetchReqState, refetch } = useAccommodationServiceFetch({
    fetchFn: getProperties,
  });

  const mappingAction = useCallback(
    async (validationErrors?: Array<ValidationErrorDetails>) => {
      await mapInternalProperty({
        propertyId: propertyId,
        internalPropertyId: selectedInternalProperty?.id,
        validationOverrides: validationErrors?.map((e) => e.code) ?? [],
      });
    },
    [propertyId, selectedInternalProperty],
  );

  const onComplete = useCallback(() => {
    enqueueSnackbar(`Successfully mapped "${selectedInternalProperty?.name}"`, { variant: 'success' });
    refetch();
  }, [selectedInternalProperty, refetch, enqueueSnackbar]);

  const doMap = useCallback(
    async (internalProperty: InternalProperty) => {
      setSelectedInternalProperty(internalProperty);
      setOpenConfirmDialog();
    },
    [setOpenConfirmDialog],
  );

  useEffect(() => {
    refetch();
  }, [refetch]);

  const onPageChange = useCallback(
    (page: number) => {
      setPageIndex(page);
      refetch();
    },
    [refetch],
  );

  const columns: Array<GridColDef<InternalProperty>> = [
    {
      field: 'name',
      headerName: 'Supplier Property Name',
      display: 'flex',
      flex: 1,
      renderCell: (params) => params.value,
      hideable: false,
    },
    {
      field: 'source',
      headerName: 'Source',
      maxWidth: 240,
      minWidth: 150,
      display: 'flex',
      hideable: true,
      valueGetter: (_value, row) => row.source,
    },
    {
      field: 'sourceId',
      headerName: 'ID',
      maxWidth: 240,
      minWidth: 200,
      display: 'flex',
      hideable: true,
      renderCell: (params) => {
        return <InternalPropertyLink source={params.row.source} sourceId={params.row.sourceId} />;
      },
    },
    {
      field: 'mappedSupplierProperties',
      headerName: 'Supplier - ID',
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        const supplierProperties = params.row.supplierProperties || [];
        return (
          <Box>
            {supplierProperties.map((supplier, index) => (
              <Typography key={index} variant="body2">
                {supplier.supplier} - {supplier.supplierId}
              </Typography>
            ))}
          </Box>
        );
      },
    },
    {
      field: ' ',
      headerName: 'Map',
      headerAlign: 'center',
      width: 50,
      sortable: false,
      align: 'center',
      display: 'flex',
      renderCell: (params) => {
        return (
          <IconButton onClick={() => doMap(params.row)} color="info">
            <LinkIcon fontSize="medium" />
          </IconButton>
        );
      },
    },
  ];

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
        <DialogTitle>Add Supplier Property</DialogTitle>
        <DialogContent>
          <Card>
            <CardContent>
              <Stack direction="row" spacing={2}>
                <TextField
                  label="Supplier Property Name (min 3 characters)"
                  value={supplierPropertyName}
                  type="text"
                  onChange={(e) => setSearch(e.target.value)}
                  onFocus={clearSearches}
                  fullWidth
                />
              </Stack>
            </CardContent>
          </Card>
          {fetchReqState.status === 'failed' && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {JSON.stringify(fetchReqState.error)}
            </Alert>
          )}
          <DataGrid
            className="accommodation-properties-search-results-table"
            columns={columns}
            rows={fetchReqState.status === 'succeeded' ? fetchReqState.result.internalProperties : []}
            rowCount={fetchReqState.status === 'succeeded' ? fetchReqState.result.total : undefined}
            getRowHeight={() => 'auto'}
            pageSizeOptions={[PAGE_SIZE]}
            paginationMode="server"
            paginationModel={{ page: pageIndex, pageSize: PAGE_SIZE }}
            onPaginationModelChange={({ page }) => onPageChange(page)}
            loading={fetchReqState.status === 'pending'}
            autoHeight
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            slots={{
              toolbar: GridToolbar,
            }}
          />
        </DialogContent>
      </Dialog>
      {isConfirmDialogOpen && (
        <AccommodationConfirmWithOverrideDialog
          open={isConfirmDialogOpen}
          onClose={() => setCloseConfirmDialog()}
          title="Map Supplier Property?"
          description="This will map this supplier. Are you sure you want to proceed?"
          overrideDescription="This will override the existing mapping for this property. Are you sure you want to proceed?"
          onConfirm={mappingAction}
          onComplete={onComplete}
        />
      )}
    </>
  );
}
