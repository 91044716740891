import React, { useEffect, useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import { Box } from '@mui/material';

import UserSearchWidget from '../Common/UserSearchWidget';

import RecentUsers from './RecentUsers';

export default function UsersPage() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialSearchString = queryParams.get('q') || '';

  const [searchQuerySubmit, setSearchQuerySubmit] = useState(initialSearchString ?? '');
  const [formErrors] = useState<Array<string> | null>(null);

  const history = useHistory();

  const handleSubmit = (id, searchQuery: string) => {
    setSearchQuerySubmit(searchQuery);
  };

  useEffect(() => {
    if (!searchQuerySubmit.length) {
      return;
    }

    history.push({
      pathname: '/users/' + searchQuerySubmit,
    });
  }, [searchQuerySubmit, history]);

  return (
    <>
      <UserSearchWidget
        id="fk_customer_id"
        label="Name / Email / Phone / Customer Support Code / User ID"
        formErrors={formErrors}
        loading={false}
        onChange={handleSubmit}
      />
      <Box paddingTop={4}>
        <RecentUsers />
      </Box>
    </>
  );
}
