import { paths as CustomerCommunicationPaths } from '@luxuryescapes/contract-customer-communication';

import { request } from '~/services/common';

import { makeCustomerCommunicationV1URL } from './customerCommunicationRequestUtils';

type CustomSchedulesDestinationGetContract =
  CustomerCommunicationPaths['/api/customer-communication/v1/custom-schedules/destinations']['get'];

function mapCustomScheduleDestination(
  external: Utils.ArrayElement<
    CustomSchedulesDestinationGetContract['responses']['200']['content']['application/json']['result']
  >,
): CustomerCommunication.CustomScheduleDestination {
  return {
    id: external.id,
    name: external.name,
  };
}

async function requestGetCustomScheduleDestinations(brand: string) {
  if (!brand) {
    throw 'missing brand';
  }

  const url = makeCustomerCommunicationV1URL('/custom-schedules/destinations');
  url.searchParams.set('brand', brand);

  return request(url.toString(), {
    method: 'GET',
    credentials: 'include',
  })
    .then((response: CustomSchedulesDestinationGetContract['responses']['200']['content']['application/json']) => {
      return response.result.map(mapCustomScheduleDestination);
    })
    .catch((error) => {
      throw JSON.stringify(error, null, 2);
    });
}

export default requestGetCustomScheduleDestinations;
