import React, { useCallback, useEffect, useState } from 'react';

import currencyFormatter from 'currency-formatter';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import { Link, useParams } from 'react-router-dom';

import { Box, Button, Stack } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { TYPES } from '~/components/Cruises/pages/Inclusions/components/InclusionForm';
import { CruiseInclusion, CruiseInclusionItem } from '~/components/Cruises/pages/Inclusions/types';

import inclusionsService from '~/services/cruises/InclusionsService';

import InclusionsItemCreate from './InclusionsItemCreate';
import InclusionsItemEdit from './InclusionsItemEdit';

const INCLUSION_ITEM_TYPE = {
  ONBOARD_CREDIT: 'Onboard Credit',
  FREE_TEXT: 'Free Text',
};

type Props = {
  inclusionType: CruiseInclusion['type'];
};

function InclusionsItemList({ inclusionType }: Props) {
  const [inclusionItems, setInclusionItems] = useState<Array<CruiseInclusionItem>>([]);
  const [fetching, setFetching] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [deleteButton, setDeleteButton] = useState(null);
  const { inclusionId, action } = useParams<{ inclusionId: string; action?: string }>();
  const history = useHistory();

  const inclusionsLimit = inclusionType === TYPES.LUX_PLUS ? 1 : 3;

  const handleDeleteRecord = useCallback(
    async (id: string) => {
      try {
        await inclusionsService.deleteItem(inclusionId, id);
        enqueueSnackbar('Inclusion Item deleted', { variant: 'success' });
      } catch (error) {
        enqueueSnackbar('Error deleting Inclusion Item', { variant: 'error' });
      }
    },
    [enqueueSnackbar, inclusionId],
  );

  const fetchInclusionItems = useCallback(async () => {
    setFetching(true);

    const response = await inclusionsService.getItems(inclusionId);
    setInclusionItems(response.result);

    setFetching(false);
  }, [inclusionId, setInclusionItems, setFetching]);

  useEffect(() => {
    fetchInclusionItems();
  }, [fetchInclusionItems]);

  const actionColumn = useCallback(
    (cell, row) => (
      <Box>
        {deleteButton !== row.id && (
          <Box>
            <Button
              variant="text"
              size="small"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                history.push(`/cruises/inclusions/edit/${inclusionId}/items/edit/${row.id}`);
              }}
            >
              Edit
            </Button>

            <Button
              variant="text"
              size="small"
              color="error"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setDeleteButton(row.id);
              }}
            >
              Delete
            </Button>
          </Box>
        )}

        {deleteButton === row.id && (
          <Box>
            <Button
              variant="text"
              size="small"
              color="error"
              onClick={async (event) => {
                event.preventDefault();
                event.stopPropagation();
                await handleDeleteRecord(row.id);

                // Update table
                fetchInclusionItems();
              }}
            >
              Confirm
            </Button>

            <Button
              variant="text"
              size="small"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setDeleteButton(null);
              }}
            >
              Cancel
            </Button>
          </Box>
        )}
      </Box>
    ),
    [deleteButton, fetchInclusionItems, handleDeleteRecord, history, inclusionId],
  );

  const columns: Array<GridColDef> = [
    {
      field: 'description',
      headerName: 'Description',
      sortable: false,
      disableColumnMenu: true,
      flex: 2,
      display: 'flex',
      renderCell: (params) => params.value || '-',
    },
    {
      field: 'type',
      headerName: 'Type',
      sortable: false,
      disableColumnMenu: true,
      flex: 2,
      display: 'flex',
      renderCell: (params) => {
        return INCLUSION_ITEM_TYPE[params.value];
      },
    },
    {
      field: 'creditAmount',
      headerName: 'Value',
      sortable: false,
      disableColumnMenu: true,
      flex: 2,
      renderCell: (params) => {
        if (params.value) {
          return currencyFormatter.format(params.value, {
            code: params.row.currency,
          });
        }

        return '-';
      },
      display: 'flex',
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 180,
      sortable: false,
      renderCell: (params) => actionColumn(params.value, params.row),
      display: 'flex',
    },
  ];

  return (
    <Box mt={1}>
      <Box>
        <DataGrid
          loading={fetching}
          rows={inclusionItems || []}
          columns={columns}
          getRowId={(row: CruiseInclusionItem) => row.id}
          autoHeight
        />
      </Box>

      <Stack
        mt={2}
        width="100%"
        direction="row"
        justifyContent="flex-end"
        paddingLeft={2}
        paddingRight={2}
        paddingBottom={1}
      >
        <Button
          component={Link}
          to={`/cruises/inclusions/edit/${inclusionId}/items/create`}
          variant="outlined"
          size="small"
          disabled={inclusionItems.length >= inclusionsLimit}
          fullWidth
        >
          Add Inclusion Item ({inclusionItems.length}/{inclusionsLimit})
        </Button>
      </Stack>

      {action === 'create' && <InclusionsItemCreate onSave={fetchInclusionItems} />}
      {action === 'edit' && <InclusionsItemEdit onSave={fetchInclusionItems} />}
    </Box>
  );
}

export default InclusionsItemList;
