import { AccommodationPropertiesSearchInput } from '~/components/Accommodation/Pages/Properties/components/AccommodationPropertiesSearchForm';

import { InternalService, PropertyStateEnum } from '~/services/AccommodationService';

export function getSearchInputFromSearchParams(searchParams: URLSearchParams): AccommodationPropertiesSearchInput {
  const input: AccommodationPropertiesSearchInput = {};
  const propertyName = searchParams.get('propertyName');
  if (propertyName) {
    input.propertyName = propertyName;
  }
  const id = searchParams.get('id');
  if (id) {
    input.id = id;
  }
  const city = searchParams.get('city');
  if (city) {
    input.city = city;
  }
  const country = searchParams.get('country');
  if (country) {
    input.country = country;
  }
  const rating = searchParams.get('rating');
  if (rating) {
    input.rating = rating;
  }
  const internalService = searchParams.get('internalService');
  if (internalService) {
    input.internalService = internalService as InternalService;
  }
  const propertyState = searchParams.get('propertyState');
  if (propertyState) {
    input.propertyState = propertyState as PropertyStateEnum;
  }
  return input;
}
