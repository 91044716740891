import { PublicOfferV2 } from '@luxuryescapes/contract-public-offer';
import { LUXURY_ESCAPES } from '@luxuryescapes/lib-regions/lib/regions';

import BedbankService from '~/services/BedbankService';
import PublicOffersService from '~/services/PublicOfferService';

import { arrayToObject } from '~/utils/arrayUtils';

export const getBedbankRoomsById = async (bedbankOfferId: string) => {
  let availableRooms = [];
  try {
    const { result } = await BedbankService.getPropertyEdit(bedbankOfferId);
    availableRooms = result?.rooms.map((room) => {
      return { id: room.id, name: room.name };
    });
  } catch (e) {
    reportError(e);
  }
  return availableRooms;
};

export const getAccommodationOffersMap = async (uniqueOfferIds: string[]) => {
  let savedOffersResponse;
  try {
    savedOffersResponse = await PublicOffersService.getMultiplePublicOffersById({
      offerIds: uniqueOfferIds.toString(),
      region: 'AU',
      brand: LUXURY_ESCAPES,
    });
  } catch (error) {
    savedOffersResponse = { result: [] };
  }
  return arrayToObject(
    savedOffersResponse.result,
    (offer: PublicOfferV2.LeHotelOffer | PublicOfferV2.BedbankOffer) => offer.id,
  );
};
