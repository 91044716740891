import React, { useCallback, useEffect, useMemo, useState } from 'react';

import requestGetHeroPlannerBanners from '~/queries/customerCommunication/requestGetHeroPlannerBanners';

import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import {
  Alert,
  AlertTitle,
  Button,
  Card,
  CardActionArea,
  CardMedia,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Tooltip,
} from '@mui/material';

import { RequestStatus } from '~/consts/requestConstants';

import { isRequestPending, isRequestRejected } from '~/utils/requestUtils';

const emptyValue = {
  id: 'none',
  name: 'None',
  url: '',
};

export function getBannerId(id: string): undefined | string {
  return id === emptyValue.id ? null : id;
}

interface Props {
  name: string;
  label: string;
  initialBannerId?: string;
  brand: string;
}

function ScheduleContentBannerImageInput({ initialBannerId = emptyValue.id, name, label, brand }: Props) {
  const [bannerId, setBannerId] = useState<string | undefined>(initialBannerId);
  const [bannersReq, setBannersReq] = useState<
    Utils.RequestState<Array<CustomerCommunication.HeroPlannerBanner>, string>
  >({
    status: RequestStatus.INITIAL,
  });

  // Make request depend on brand
  const request = useCallback(async () => {
    setBannersReq((currReq) => ({
      status: RequestStatus.PENDING,
      params: undefined,
      result: currReq.result,
    }));

    try {
      const result = await requestGetHeroPlannerBanners(brand);
      setBannersReq({
        status: RequestStatus.FULFILLED,
        params: undefined,
        result,
      });
      // Reset bannerId if the current selection isn’t valid for the new brand
      if (result && !result.some((banner) => banner.id === bannerId)) {
        setBannerId(emptyValue.id);
      }
    } catch (error) {
      setBannersReq({
        status: RequestStatus.REJECTED,
        params: undefined,
        error,
      });
    }
  }, [brand, bannerId]);

  useEffect(() => {
    request();
  }, [request]);

  const selectedBanner = useMemo<CustomerCommunication.HeroPlannerBanner>(() => {
    return bannersReq.result?.find((b) => b.id === bannerId);
  }, [bannersReq.result, bannerId]);

  const handleChange = useCallback((event: SelectChangeEvent<string>) => {
    setBannerId(event.target.value);
  }, []);

  return (
    <Stack direction="column" gap={2}>
      <FormControl variant="standard" sx={{ position: 'relative' }} fullWidth>
        <InputLabel>{label}</InputLabel>
        <Select
          name={name}
          value={bannerId}
          onChange={handleChange}
          label="Banner Image ID"
          required={false}
          variant="standard"
          disabled={isRequestPending(bannersReq)}
          error={isRequestRejected(bannersReq)}
        >
          <MenuItem key={emptyValue.id} value={emptyValue.id}>
            <em>{emptyValue.name}</em>
          </MenuItem>
          {bannersReq.result?.map((banner) => (
            <MenuItem key={banner.id} value={banner.id}>
              {banner.name}
            </MenuItem>
          ))}
        </Select>
        {isRequestPending(bannersReq) && <LinearProgress />}
        {isRequestRejected(bannersReq) && (
          <Alert
            severity="error"
            action={
              <Button size="small" color="inherit" onClick={request}>
                Retry
              </Button>
            }
          >
            <AlertTitle>Could not fetch banners.</AlertTitle>
            {bannersReq.error}
          </Alert>
        )}
      </FormControl>
      <Card elevation={2} sx={{ height: 320 }}>
        {!selectedBanner && <ImageNotSupportedIcon sx={{ m: 1 }} />}
        {!!selectedBanner && (
          <Tooltip placement="top" title="Click to open the image in a new tab" followCursor>
            <CardActionArea sx={{ height: '100%' }} href={selectedBanner.url} target="_blank">
              <CardMedia
                key={selectedBanner.url}
                component="img"
                src={selectedBanner.url}
                alt="banner image preview"
                sx={{ height: '100%', objectFit: 'contain' }}
              />
            </CardActionArea>
          </Tooltip>
        )}
      </Card>
    </Stack>
  );
}
export default ScheduleContentBannerImageInput;
