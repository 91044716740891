import { paths as CustomerCommunicationPaths } from '@luxuryescapes/contract-customer-communication';

import { json_headers, request } from '~/services/common';

import { makeCustomerCommunicationV1URL } from './customerCommunicationRequestUtils';

type HeroPlannerSchedulePatchContract =
  CustomerCommunicationPaths['/api/customer-communication/v1/todays-escapes-schedule/hero-planner/schedules/{scheduleId}']['patch'];

function mapEditedExternalHeroPlannerSchedule(
  internal: CustomerCommunication.UnsavedHeroPlannerSchedule,
): HeroPlannerSchedulePatchContract['parameters']['body']['payload']['schedule'] {
  const pushSchedules = internal.pushSchedules?.map((pushSchedule) => {
    return {
      title: pushSchedule.title,
      subtitle: pushSchedule.subtitle,
      message: pushSchedule.message,
      link: pushSchedule.link,
      imageId: pushSchedule.imageId,
      scheduleType: pushSchedule.scheduleType,
      status: 'PENDING' as const,
    };
  });
  return {
    identifiers: {
      // @ts-expect-error string is fine
      brand: internal.brandId,
      // @ts-expect-error string is fine
      country: internal.countryId,
      // @ts-expect-error string is fine
      countryGroup: internal.countryGroupId,
      // @ts-expect-error string is fine
      state: internal.stateId,
      // @ts-expect-error string is fine
      segment: internal.segmentId,
      // @ts-expect-error string is fine
      city: internal.cityId,
      // @ts-expect-error string is fine
      cadence: internal.cadenceId,
      // @ts-expect-error string is fine
      loyalty: internal.membershipId,

      sendDate: internal.sendDate.format('YYYY-MM-DD'),
    },
    data: {
      emailSubjectLine: internal.emailSubjectLine,
      emailPreheader: internal.emailPreHeader,
      emailBannerId: internal.emailBannerId,
      emailBannerPosition2Id: internal.emailBannerPosition2Id,
      emailHeroDeals: internal.emailHeroOfferIds,
      emailStatus: 'PENDING',
      isApprovedForTpfm: internal.isApprovedForTpfm,
      pushOfferId: internal.emailHeroOfferIds[0],
      pushTitle: internal.pushTitle,
      pushSubtitle: internal.pushSubtitle,
      pushMessage: internal.pushMessage,
      pushLink: internal.pushLink,
      pushImageId: internal.pushSvcImageId,
      pushStatus: 'PENDING',
      pushSchedules,
    },
  };
}

async function requestPatchHeroPlannerSchedule(
  scheduleId: string,
  unsavedSchedule: CustomerCommunication.UnsavedHeroPlannerSchedule,
) {
  if (!scheduleId) throw 'missing scheduleId';
  if (!unsavedSchedule) throw 'missing unsavedSchedule';

  const url = makeCustomerCommunicationV1URL(`/todays-escapes-schedule/hero-planner/schedules/${scheduleId}`);

  return request(url.toString(), {
    method: 'PATCH',
    credentials: 'include',
    headers: json_headers,
    body: JSON.stringify({ schedule: mapEditedExternalHeroPlannerSchedule(unsavedSchedule) }),
  }).catch((error) => {
    throw JSON.stringify(error, null, 2);
  });
}

export default requestPatchHeroPlannerSchedule;
