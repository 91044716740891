import React from 'react';

import { Box, Stack, Typography } from '@mui/material';

import { sortBy } from '~/utils/arrayUtils';
import currencyFormatter from '~/utils/currencyFormatter';
import { luxLoyaltyBenefitDescriptionMap } from '~/utils/luxLoyalty';

interface Props {
  account: App.LuxLoyaltyAccount;
}

export default function LuxLoyaltyAccountDetails({ account }: Props) {
  const benefits = sortBy(account.benefits, (benefit) => benefit.details.limit !== Infinity, 'desc');

  return (
    <Stack direction="column" gap={4} flex={1}>
      {benefits.map((benefit) => {
        const benefitValue = benefit.details.value;

        return (
          <Box key={benefit.code}>
            <Typography variant="h6">{luxLoyaltyBenefitDescriptionMap.get(benefit.code)}</Typography>
            <Typography variant="subtitle1">
              {benefit.details.limit === Infinity && 'Unlimited'}
              {benefit.details.limit !== Infinity && `Limit: ${benefit.details.limit}`}
            </Typography>
            {benefit.details.limit !== Infinity && (
              <Typography variant="subtitle1">Remaining: {benefit.details.remaining}</Typography>
            )}
            <Typography variant="subtitle1">
              {!!benefitValue && (
                <>
                  Value:{' '}
                  {benefitValue.unit === 'CURRENCY' &&
                    `${currencyFormatter(benefitValue.currency, benefitValue.amount, 2, 'code')} each`}
                  {benefitValue.unit === 'PERCENTAGE' && `${benefitValue.amount}%`}
                </>
              )}
            </Typography>
          </Box>
        );
      })}
    </Stack>
  );
}
