import React, { useCallback } from 'react';

import clsx from 'clsx';
import { parseInt } from 'lodash';
import { Controller, useForm } from 'react-hook-form';

import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';

import ConfirmButton from '~/components/Common/Elements/ConfirmButton';
import ImageUploadField from '~/components/Common/Forms/ImageUploadField';
import MarkdownEditor from '~/components/Common/Forms/widgets/MarkdownEditor';

import { CreateFlightDealData, structureTravelDaysForApi, validateTravelDays } from '~/services/FlightsService';
import { Dayjs, isAfter } from '~/services/TimeService';

import { useFlightCampaigns } from '../hooks/useFlightCampaigns';

export interface FlightDealFormData {
  success: boolean;
  refreshKey: number;
  errors: Array<string>;
  carrierCode: string;
  region: string;
  originAirportCode: string;
  destinationAirportCode: string;
  fareType: string;
  perAdultPrice: string;
  salesStartDate: Dayjs | null;
  salesEndDate: Dayjs | null;
  dealName: string;
  dealDescription: string;
  categoryGroup: string;
  fareClass: string;
  bookingClassCode: string;
  adultCount: number;
  discountLabel: string;
  departureDate1: Dayjs | null;
  arrivalDate1: Dayjs | null;
  departureDate2: Dayjs | null;
  arrivalDate2: Dayjs | null;
  departureDate3: Dayjs | null;
  arrivalDate3: Dayjs | null;
  termsAndConditions: string;
  travelDays?: string;
  heroImage?: string;
  selectingImage: boolean;
  hierarchy: string | null;
  airlineCampaign?: string;
  destinationCampaign?: string;
  departureAirportCampaign?: string;
}

const defaultValues: FlightDealFormData = {
  success: false,
  refreshKey: 0,
  errors: [],
  carrierCode: '',
  region: '',
  originAirportCode: '',
  destinationAirportCode: '',
  fareType: '',
  perAdultPrice: '',
  salesStartDate: null,
  salesEndDate: null,
  dealName: '',
  dealDescription: '',
  categoryGroup: '',
  fareClass: '',
  bookingClassCode: '',
  adultCount: 1,
  discountLabel: 'Sale',
  departureDate1: null,
  arrivalDate1: null,
  departureDate2: null,
  arrivalDate2: null,
  departureDate3: null,
  arrivalDate3: null,
  termsAndConditions: '',
  travelDays: '',
  heroImage: null,
  selectingImage: false,
  hierarchy: null,
  airlineCampaign: null,
  departureAirportCampaign: null,
  destinationCampaign: null,
};

interface FlightDealsFormProps {
  error?: string;
  loading?: boolean;
  initialValues?: Partial<FlightDealFormData>;
  onSubmit: (data: CreateFlightDealData) => void;
  onDelete?: () => void;
  onClone?: () => void;
  showDelete?: boolean;
  showClone?: boolean;
  deleting?: boolean;
  cloning?: boolean;
}

export function FlightDealsForm({
  error,
  loading,
  onSubmit,
  initialValues,
  showDelete = false,
  showClone = false,
  deleting = false,
  cloning = false,
  onDelete,
  onClone,
}: FlightDealsFormProps) {
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors: formErrors },
  } = useForm<FlightDealFormData>({
    defaultValues: { ...defaultValues, ...initialValues },
    resolver: formResolver,
  });

  const { data: airlineCampaigns } = useFlightCampaigns({ type: 'airline' });
  const { data: destinationCampaigns } = useFlightCampaigns({ type: 'destination' });
  const { data: departureCampaigns } = useFlightCampaigns({ type: 'departure_airport' });

  const heroImage = watch('heroImage');

  const formatAndSubmit = useCallback(
    async (formData: FlightDealFormData) => {
      const deal: CreateFlightDealData = {
        carrierCode: formData.carrierCode,
        region: formData.region,
        originAirportCode: formData.originAirportCode,
        destinationAirportCode: formData.destinationAirportCode,
        fareType: formData.fareType,
        perAdultPrice: parseFloat(formData.perAdultPrice),
        salesStartDate: formData.salesStartDate ? formData.salesStartDate.toISOString() : '',
        salesEndDate: formData.salesEndDate ? formData.salesEndDate.toISOString() : '',
        dealName: formData.dealName,
        dealDescription: formData.dealDescription,
        heroImagePublicId: formData.heroImage ?? null,
        categoryGroup: formData.categoryGroup,
        fareClass: formData.fareClass,
        bookingClassCode: formData.bookingClassCode,
        adultCount: formData.adultCount,
        offerDurations: getDurations(formData),
        discountLabel: formData.discountLabel,
        termsAndConditions: formData.termsAndConditions,
        travelDays: formData.travelDays ? structureTravelDaysForApi(formData.travelDays) : null,
        hierarchy: formData.hierarchy ? parseInt(formData.hierarchy) : 0,
        searchText: 'View flights',
        airlineCampaign: formData.airlineCampaign,
        destinationCampaign: formData.destinationCampaign,
        departureAirportCampaign: formData.departureAirportCampaign,
      };

      onSubmit(deal);
    },
    [onSubmit],
  );

  return (
    <Stack direction="column" spacing={4}>
      <form onSubmit={handleSubmit(formatAndSubmit)}>
        <Grid container spacing={4}>
          <Grid item md={4}>
            <Controller
              name="categoryGroup"
              control={control}
              render={({ field }) => <TextField {...field} fullWidth label="Category Name" required />}
            />
          </Grid>

          <Grid item md={4}>
            <Controller
              name="dealName"
              control={control}
              render={({ field }) => <TextField {...field} fullWidth label="Deal Name" required />}
            />
          </Grid>

          <Grid item md={4}>
            <Controller
              name="region"
              control={control}
              render={({ field }) => <TextField {...field} fullWidth label="Region" required />}
            />
          </Grid>

          <Grid item md={4}>
            <Controller
              name="perAdultPrice"
              control={control}
              render={({ field }) => <TextField {...field} fullWidth label="Per Adult Price" required type="number" />}
            />
          </Grid>

          <Grid item md={4}>
            <Controller
              name="discountLabel"
              control={control}
              render={({ field }) => (
                <TextField {...field} fullWidth label="Product Tag" required placeholder="Ex: Sale" />
              )}
            />
          </Grid>

          <Grid item md={4}>
            <Controller
              name="hierarchy"
              control={control}
              render={({ field }) => <TextField {...field} fullWidth label="Hierarchy" type="number" required />}
            />
          </Grid>

          <Grid item sm={4}>
            <Controller
              name="salesStartDate"
              control={control}
              render={({ field }) => (
                <DateTimePicker
                  {...field}
                  label="Start date *"
                  slotProps={{ textField: { fullWidth: true }, field: { clearable: true } }}
                />
              )}
            />
          </Grid>

          <Grid item sm={4}>
            <Controller
              name="salesEndDate"
              control={control}
              render={({ field }) => (
                <DateTimePicker
                  {...field}
                  slotProps={{ textField: { fullWidth: true }, field: { clearable: true } }}
                  label="End date *"
                />
              )}
            />
          </Grid>

          <Grid item md={12}>
            <Stack spacing={2}>
              <Typography fontWeight={500}>Deal Description</Typography>

              <Controller
                name="dealDescription"
                control={control}
                render={({ field }) => <MarkdownEditor {...field} />}
              />
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Stack spacing={2}>
              <Typography fontWeight={500}>Hero Image</Typography>

              <Card
                variant="outlined"
                className={clsx({
                  error: heroImage,
                })}
              >
                <CardContent>
                  <ImageUploadField
                    label="Hero Image"
                    value={heroImage}
                    multiple={false}
                    onUpload={(_, newValue) => setValue('heroImage', newValue)}
                  />
                </CardContent>
              </Card>
            </Stack>
          </Grid>

          <Grid item md={12}>
            <Stack spacing={2}>
              <Typography fontWeight={500}>Terms & Conditions</Typography>

              <Controller
                name="termsAndConditions"
                control={control}
                render={({ field }) => <MarkdownEditor {...field} />}
              />
            </Stack>
          </Grid>

          <Grid item sm={12}>
            <Typography variant="h5" fontWeight={600}>
              Campaigns
            </Typography>
          </Grid>

          <Grid item sm={4}>
            <Controller
              name="airlineCampaign"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel>Airline Campaign</InputLabel>
                  <Select {...field} label="Airline Campaign">
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {airlineCampaigns?.map((campaign) => (
                      <MenuItem value={campaign.id}>{campaign.title}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>

          <Grid item sm={4}>
            <Controller
              name="destinationCampaign"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel>Destination Campaign</InputLabel>
                  <Select {...field} label="Destination Campaign">
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {destinationCampaigns?.map((campaign) => (
                      <MenuItem value={campaign.id}>{campaign.title}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>

          <Grid item sm={4}>
            <Controller
              name="departureAirportCampaign"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel>Departure Airport Campaign</InputLabel>
                  <Select {...field} label="Departure Airport Campaign">
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {departureCampaigns?.map((campaign) => (
                      <MenuItem value={campaign.id}>{campaign.title}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>

          <Grid item sm={12}>
            <Typography variant="h5" fontWeight={600}>
              Flight Details
            </Typography>
          </Grid>

          <Grid item md={4}>
            <Controller
              name="originAirportCode"
              control={control}
              render={({ field }) => <TextField {...field} fullWidth label="Departure Airport Code" required />}
            />
          </Grid>

          <Grid item md={4}>
            <Controller
              name="destinationAirportCode"
              control={control}
              render={({ field }) => <TextField {...field} fullWidth label="Arrival Airport Code" required />}
            />
          </Grid>

          <Grid item md={4}>
            <Controller
              name="carrierCode"
              control={control}
              render={({ field }) => <TextField {...field} fullWidth label="Airline Code" required />}
            />
          </Grid>

          <Grid item md={4}>
            <Controller
              name="fareType"
              control={control}
              render={({ field }) => (
                <FormControl required fullWidth>
                  <InputLabel>Fare Type</InputLabel>
                  <Select {...field} label="Fare Type">
                    <MenuItem value="oneWay">One Way</MenuItem>
                    <MenuItem value="return">Return</MenuItem>
                    <MenuItem value="multiCity">Multi City</MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>

          <Grid item md={4}>
            <Controller
              name="fareClass"
              control={control}
              render={({ field }) => (
                <FormControl required fullWidth>
                  <InputLabel>Fare Class</InputLabel>
                  <Select {...field} label="Fare Class">
                    <MenuItem value="Economy">Economy</MenuItem>
                    <MenuItem value="Business">Business</MenuItem>
                    <MenuItem value="Premium">Premium</MenuItem>
                    <MenuItem value="First">First</MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>

          <Grid item md={4}>
            <Controller
              name="bookingClassCode"
              control={control}
              render={({ field }) => <TextField {...field} fullWidth label="Booking Class Code" required />}
            />
          </Grid>

          <Grid item md={4}>
            <Controller
              name="travelDays"
              control={control}
              render={({ field }) => <TextField {...field} fullWidth label="Travel Days" />}
            />
          </Grid>

          <Grid item sm={12}>
            <Typography variant="h5" fontWeight={600}>
              Travel Dates
            </Typography>
          </Grid>

          <Grid item md={3}>
            <Controller
              name="departureDate1"
              control={control}
              render={({ field }) => (
                <DateTimePicker
                  {...field}
                  slotProps={{ textField: { fullWidth: true }, field: { clearable: true } }}
                  label="First Departure Date *"
                />
              )}
            />
          </Grid>

          <Grid item md={3}>
            <Controller
              name="arrivalDate1"
              control={control}
              render={({ field }) => (
                <DateTimePicker
                  {...field}
                  slotProps={{ textField: { fullWidth: true }, field: { clearable: true } }}
                  label="First Arrival Date *"
                />
              )}
            />
          </Grid>

          <Grid item md={6} />

          <Grid item md={3}>
            <Controller
              name="departureDate2"
              control={control}
              render={({ field }) => (
                <DateTimePicker
                  {...field}
                  slotProps={{ textField: { fullWidth: true }, field: { clearable: true } }}
                  label="Second Departure Date"
                />
              )}
            />
          </Grid>

          <Grid item md={3}>
            <Controller
              name="arrivalDate2"
              control={control}
              render={({ field }) => (
                <DateTimePicker
                  {...field}
                  slotProps={{ textField: { fullWidth: true }, field: { clearable: true } }}
                  label="Second Arrival Date"
                />
              )}
            />
          </Grid>

          <Grid item md={6} />

          <Grid item md={3}>
            <Controller
              name="departureDate3"
              control={control}
              render={({ field }) => (
                <DateTimePicker
                  {...field}
                  slotProps={{ textField: { fullWidth: true }, field: { clearable: true } }}
                  label="Third Departure Date"
                />
              )}
            />
          </Grid>

          <Grid item md={3}>
            <Controller
              name="arrivalDate3"
              control={control}
              render={({ field }) => (
                <DateTimePicker
                  {...field}
                  slotProps={{ textField: { fullWidth: true }, field: { clearable: true } }}
                  label="Third Arrival Date"
                />
              )}
            />
          </Grid>

          <Grid item md={6} />

          <Grid item md={12}>
            <Stack direction="row" alignItems="center" gap={2}>
              <Button type="submit" variant="contained" disabled={loading}>
                {loading ? 'Loading...' : 'Submit'}
              </Button>

              {showDelete && (
                <ConfirmButton
                  confirmTitle="Delete deal"
                  confirmQuestion="Are you sure you want to delete this deal?"
                  confirmAnswer="Yes, delete"
                  onConfirm={onDelete}
                  color="error"
                  variant="contained"
                  disabled={deleting}
                >
                  {deleting ? 'Deleting...' : 'Delete'}
                </ConfirmButton>
              )}

              {showClone && (
                <Button type="button" variant="text" disabled={cloning} onClick={onClone}>
                  {cloning ? 'Clonning...' : 'Clone'}
                </Button>
              )}
            </Stack>
          </Grid>
        </Grid>
      </form>

      {error && <Alert severity="error">{error}</Alert>}

      {Object.entries(formErrors).length > 0 && (
        <Alert severity="error">
          {Object.entries(formErrors).map(([key, error]) => (
            <Box key={key}>- {error.message}</Box>
          ))}
        </Alert>
      )}
    </Stack>
  );
}

function getDurations(formData: FlightDealFormData) {
  const dateRanges = [
    { departure: formData.departureDate1, arrival: formData.arrivalDate1 },
    { departure: formData.departureDate2, arrival: formData.arrivalDate2 },
    { departure: formData.departureDate3, arrival: formData.arrivalDate3 },
  ];

  return dateRanges
    .filter((range) => range.departure && range.arrival)
    .map((range) => ({
      departureDate: range.departure.toISOString(),
      arrivalDate: range.arrival.toISOString(),
    }));
}

async function formResolver(values: FlightDealFormData) {
  const errors: Record<string, { type: string; message: string }> = {};

  const requiredFields = [
    'region',
    'carrierCode',
    'originAirportCode',
    'destinationAirportCode',
    'fareType',
    'perAdultPrice',
    'salesStartDate',
    'salesEndDate',
    'categoryGroup',
    'bookingClassCode',
    'termsAndConditions',
    'hierarchy',
    'departureDate1',
    'arrivalDate1',
  ];

  const missingFields = requiredFields.filter((field) => !values[field]);
  if (missingFields.length > 0) {
    errors.form = {
      type: 'required',
      message: 'Please fill all mandatory fields',
    };
  }

  const isArray = values.travelDays && values.travelDays.split(',')?.length > 1;

  if (values.travelDays && !validateTravelDays(isArray ? values.travelDays?.split(',') : values.travelDays)) {
    errors.travelDays = {
      type: 'invalid',
      message: 'Travel days should contain either a comma separated list of days, "daily" or "twice daily"',
    };
  }

  const dateRanges = [
    { start: values.salesStartDate, end: values.salesEndDate, errorKey: 'salesEndDate', label: 'Sale' },
    { start: values.departureDate1, end: values.arrivalDate1, errorKey: 'arrivalDate1', label: 'First' },
    { start: values.departureDate2, end: values.arrivalDate2, errorKey: 'arrivalDate2', label: 'Second' },
    { start: values.departureDate3, end: values.arrivalDate3, errorKey: 'arrivalDate3', label: 'Third' },
  ];

  dateRanges.forEach(({ start, end, errorKey, label }) => {
    if (start && end && isAfter(start.toISOString(), end.toISOString())) {
      errors[errorKey] = {
        type: 'invalid',
        message: `${label} end date should not be before start date`,
      };
    }
  });

  if (values.departureDate3 && !values.departureDate2) {
    errors.dateRange3 = {
      type: 'invalid',
      message: 'Should add second departure and arrival date before adding third date range',
    };
  }

  return {
    values,
    errors: Object.keys(errors).length > 0 ? errors : {},
  };
}
