import React, { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import qs from 'qs';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';

import { Button, Divider, Grid, Stack, Typography } from '@mui/material';

import PageSubheader from '~/components/Common/Elements/PageSubheader';

import useQuery from '~/hooks/useQuery';

import { getVidByOfferId } from '~/services/PublicOfferFeedService';
import { formatDateNumeralMonths } from '~/services/TimeService';

import VideoEditorForm from './VideoEditorForm';

function VideoEditorContainer() {
  const query = useQuery();
  const { enqueueSnackbar } = useSnackbar();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [data, setData] = useState({} as any);
  const offerId = query.get('offer_id') || undefined;
  const history = useHistory();

  const setQueryParams = useCallback(() => {
    const searchParams = qs.stringify({
      offer_id: offerId,
    });
    history.push({
      search: `?${searchParams}`,
    });
  }, [history, offerId]);

  const fetchData = useCallback(async () => {
    try {
      const { result } = await getVidByOfferId(offerId);
      setData({ ...result[0] });
      setQueryParams();
    } catch (error) {
      enqueueSnackbar('Failed to fetch videos by offerId', { variant: 'error' });
      return;
    }
  }, [offerId, setQueryParams, enqueueSnackbar]);

  useEffect(() => {
    const timer = setTimeout(() => fetchData(), 500);
    return () => clearTimeout(timer);
  }, [fetchData]);

  // START - New Markup Modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <Helmet>
        <title>VideoEditor</title>
      </Helmet>
      <PageSubheader title="View offer video list" />
      <Grid container spacing={2} justifyContent="flex-end" alignItems="end">
        <Grid item xs={3}>
          <Button variant="contained" color="primary" onClick={handleOpen}>
            New Video
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid container item xs={12} justifyContent="center">
          {data?.mainVideo && (
            <video width={720} height={480} controls>
              <source src={data.mainVideo} type="video/mp4" />
            </video>
          )}
        </Grid>
        <Grid container item xs={12} justifyContent="center" spacing={2}>
          {data.shorts?.map((video) => (
            <Grid container item xs={3} key={video.video_url} spacing={4}>
              <Grid item xs={4}>
                <video width={240} height={480} controls>
                  <source src={video.video_url} type="video/mp4" />
                </video>
                <Typography>
                  <b>Facebook</b>
                </Typography>

                <Typography>{formatDateNumeralMonths(video.date_posted_facebook) || 'N/A'}</Typography>

                <Stack
                  direction="row"
                  divider={<Divider orientation="vertical" flexItem />}
                  spacing={{ xs: 1, sm: 2, md: 2 }}
                >
                  <Typography>{video.facebook_views || 0} views</Typography>
                  <Typography>{video.facebook_reactions || 0} likes</Typography>
                  <Typography>{video.facebook_comments || 0} comments</Typography>

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      /* Handle Instagram post */
                    }}
                  >
                    Post
                  </Button>
                </Stack>

                <hr />
                <Typography>
                  <b>Instagram</b>
                </Typography>

                <Typography>{formatDateNumeralMonths(video.date_posted_instagram) || 'N/A'}</Typography>

                <Stack
                  direction="row"
                  divider={<Divider orientation="vertical" flexItem />}
                  spacing={{ xs: 1, sm: 2, md: 2 }}
                >
                  <Typography>{video.instagram_views || 0} views</Typography>
                  <Typography>{video.instagram_reactions || 0} likes</Typography>
                  <Typography>{video.instagram_comments || 0} comments</Typography>

                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => {
                      /* Handle Instagram post */
                    }}
                  >
                    Post
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
      {open && <VideoEditorForm open={open} setOpen={setOpen} />}
    </>
  );
}

export default VideoEditorContainer;
