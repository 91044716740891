import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';

import { Stack, TextField } from '@mui/material';

import { Dayjs } from '~/services/TimeService';

import { DynamicSectionType } from '../../context/useDynamicSections';

import ScheduleHeroOffersForm, { parseScheduleHeroOffersFormData } from './ScheduleHeroOffersForm';

interface Props {
  sendDate: Dayjs;
  brand: string;
  countryGroupId: string;
  countryId: string;
  existingOfferIds: Array<string>;
  existingHeading?: string;
}

export enum SCHEDULE_OFFER_SECTION_FORM_NAMES {
  HEADING = 'offer_section_heading',
  OFFER_IDS = 'offer_section_offer_ids',
}

export function parseOfferSectionFormData(formData: FormData, position = -1) {
  const offerIdsString = parseScheduleHeroOffersFormData(formData);
  return {
    type: DynamicSectionType.OFFER,
    heading: String(formData.get(SCHEDULE_OFFER_SECTION_FORM_NAMES.HEADING)),
    offerIds: offerIdsString.emailHeroOfferIds,
    position,
  };
}

const ScheduleOfferSectionForm = forwardRef<HTMLFormElement, Props>((props, ref) => {
  const { sendDate, brand, countryGroupId, countryId, existingOfferIds, existingHeading = '' } = props;
  const [heading, setHeading] = useState(existingHeading);
  const [offerIds, setOfferIds] = useState<Array<string>>(existingOfferIds);

  const child1Ref = useRef<HTMLFormElement>(null);
  const child2Ref = useRef<HTMLFormElement>(null);

  useImperativeHandle(ref, () => ({
    ...child1Ref.current,
    makeFormData: () => {
      const combinedFormData = new FormData();
      for (const ref of [child1Ref, child2Ref]) {
        if (ref.current) {
          for (const [key, value] of new FormData(ref.current)) {
            combinedFormData.append(key, value);
          }
        }
      }
      return combinedFormData;
    },
  }));
  const handleOfferIdsChange = useCallback((newOfferIds: Array<string>) => {
    setOfferIds(newOfferIds);
  }, []);

  return (
    <Stack spacing={3} mt={1}>
      <form ref={child1Ref}>
        <TextField
          fullWidth
          label="Section Heading"
          name={SCHEDULE_OFFER_SECTION_FORM_NAMES.HEADING}
          value={heading}
          onChange={(e) => setHeading(e.target.value)}
        />
      </form>

      <ScheduleHeroOffersForm
        ref={child2Ref}
        sendDate={sendDate}
        brand={brand}
        countryGroupId={countryGroupId}
        countryId={countryId}
        offerIds={offerIds}
        onOfferIdsChange={handleOfferIdsChange}
      />
    </Stack>
  );
});

ScheduleOfferSectionForm.displayName = 'ScheduleOfferSectionForm';
export default ScheduleOfferSectionForm;
