import React, { ComponentProps, useCallback, useEffect } from 'react';

import { Alert, AlertTitle, Button, Tab, Tabs } from '@mui/material';

import useHeroPlannerCountryStates from '~/hooks/customerCommunication/useHeroPlannerCountryStates';

import { isRequestRejected } from '~/utils/requestUtils';

interface Props {
  countryGroupId: string;
  countryId: string;
  brand: string;
  /**
   * countryStateId
   */
  value: string;
  onChange: (countryState: string) => void;
}

function SchedulesCountryStateTabs(props: Props) {
  const { countryGroupId, countryId, brand, value, onChange } = props;
  const handleChange = useCallback<NonNullable<ComponentProps<typeof Tabs>['onChange']>>(
    (event, value) => {
      onChange(value);
    },
    [onChange],
  );
  const { countryStatesReq, fetchCountryStates } = useHeroPlannerCountryStates(countryGroupId, countryId, brand);

  useEffect(() => {
    if (countryStatesReq?.result?.length) {
      if (!value || !countryStatesReq.result.find((i) => i.id === value)) {
        onChange(countryStatesReq.result[0].id);
      }
    }
  }, [countryStatesReq?.result, onChange, value]);

  if (isRequestRejected(countryStatesReq))
    return (
      <Alert
        severity="error"
        action={
          <Button size="small" color="inherit" onClick={fetchCountryStates}>
            Retry
          </Button>
        }
      >
        <AlertTitle>Couldn't fetch country groups for filtering.</AlertTitle>
        <pre>{countryStatesReq.error}</pre>
      </Alert>
    );

  return (
    <Tabs value={value ?? false} variant="scrollable" onChange={handleChange}>
      {countryStatesReq?.result?.map((country) => (
        <Tab key={country.id} value={country.id} label={country.name} />
      ))}
    </Tabs>
  );
}

export default SchedulesCountryStateTabs;
