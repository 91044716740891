import React from 'react';

import { Alert, Box, List, ListItem, Stack, Typography } from '@mui/material';

import { definitions } from '@luxuryescapes/contract-svc-promo';

type Travellers = definitions['Discount Response']['promo']['items'][0]['travellers'][0];

type TravellersListProps = {
  travellers?: Array<Travellers> | Travellers;
};
function TravellersList({ travellers }: TravellersListProps) {
  if (!travellers) {
    return <Alert severity="warning">Travellers not available</Alert>;
  }

  const title = <Typography fontWeight="bold">Travellers: </Typography>;
  if (Array.isArray(travellers)) {
    return (
      <Stack direction="column">
        {title}
        {travellers.map((t, i) => (
          <Box key={i}>
            {t.firstName} {t.lastName}
          </Box>
        ))}
      </Stack>
    );
  }

  return (
    <List>
      {title}
      <ListItem>
        {travellers.firstName} {travellers.lastName}
      </ListItem>
    </List>
  );
}

export default TravellersList;
