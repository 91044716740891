import { CruiseDealPage } from './types';

export const INITIAL_FORM_STATE: CruiseDealPage = {
  id: '',
  name: '',
  slug: '',
  heading: '',
  subHeading: '',
  termsAndConditions: '',
  cruiseLine: {
    id: '',
    name: '',
    code: '',
    whiteImageId: null,
  },
  imageId: '',
  startDate: '',
  endDate: '',
  rates: [],
};
