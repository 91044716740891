import React from 'react';

import { Chip } from '@mui/material';

interface Props {
  status: 'ACTIVE' | 'DRAFT' | 'ARCHIVED';
}

function StatusChip({ status }: Props) {
  if (status === 'ACTIVE') {
    return <Chip label="Active" color="success" variant="outlined" />;
  }
  if (status === 'DRAFT') {
    return <Chip label="Draft" color="info" variant="outlined" />;
  }
  if (status === 'ARCHIVED') {
    return <Chip label="Archived" color="default" variant="outlined" />;
  }
  return null;
}

export default StatusChip;
