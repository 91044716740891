import React from 'react';

import { Box } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import FoldableSection from '~/components/Common/Blocks/FoldableSection';

interface Props {
  data: any;
}

const columns: Array<GridColDef> = [
  {
    field: 'join_key_fk',
    flex: 2,
    headerName: 'Transaction ID',
    sortable: false,
    disableColumnMenu: true,
    display: 'flex',
  },
  {
    field: 'summary',
    flex: 2,
    headerName: 'Message',
    sortable: false,
    disableColumnMenu: true,
    display: 'flex',
  },
  {
    field: 'created_at',
    flex: 2,
    headerName: 'Created',
    sortable: false,
    disableColumnMenu: true,
    display: 'flex',
  },
  {
    field: 'created_at',
    flex: 2,
    headerName: 'Created',
    sortable: false,
    disableColumnMenu: true,
    display: 'flex',
  },
  {
    field: 'error',
    flex: 2,
    headerName: 'Full error',
    sortable: false,
    disableColumnMenu: true,
    valueFormatter: (value) => JSON.stringify(value),
    display: 'flex',
  },
];

export default function OrderDetailPaymentErrorLogs({ data }: Props) {
  return (
    <Box mt={2}>
      <FoldableSection title="Payment errors" initiallyExpanded>
        <DataGrid
          columns={columns}
          rows={data}
          getRowId={(row) => row.join_key_fk}
          autoHeight
          pageSizeOptions={[]}
          disableColumnMenu
          disableRowSelectionOnClick
          hideFooter
          getRowHeight={() => 'auto'}
        />
      </FoldableSection>
    </Box>
  );
}
