import React from 'react';

import { DataGrid, GridCellParams } from '@mui/x-data-grid';

import GridPagination from '~/components/Common/Elements/GridPagination';

import { DEFAULT_PAGE_SIZES } from '~/consts/filters';

import { PromoMeta } from '~/services/PromoService';

import getColumns from './columns';

interface Props {
  expirePromo: (id: string) => Promise<void>;
  onPageChange: (page: number, pageSize: number) => void;
  onClick: (rowPromo: GridCellParams<App.PromoData>) => void;
  page: number;
  promos: Array<App.PromoData>;
  sizePerPage: number;
  total: number;
  isLoading: boolean;
  promoMeta: PromoMeta;
  isDev: boolean;
}

function PromoPage({
  promos,
  total,
  page,
  onPageChange,
  onClick,
  expirePromo,
  sizePerPage,
  isLoading,
  promoMeta,
  isDev,
}: Props) {
  const data = promos.map((promo) => ({
    ...promo,
    actions: { expirePromo },
  }));

  return (
    <DataGrid
      columns={getColumns(promoMeta)}
      rows={data}
      rowCount={total}
      pagination
      paginationMode="server"
      paginationModel={{ page: page - 1, pageSize: sizePerPage }}
      slots={{ pagination: GridPagination }}
      pageSizeOptions={DEFAULT_PAGE_SIZES}
      onPaginationModelChange={({ page, pageSize }) => onPageChange(page, pageSize)}
      loading={isLoading}
      getRowId={(row: App.PromoData) => row.id_promo_code}
      getRowHeight={() => 'auto'}
      onCellClick={(params: GridCellParams<App.PromoData>) => onClick(params)}
      sx={{ '.MuiDataGrid-cell': { alignItems: 'start' } }}
      columnVisibilityModel={{
        popup_message_presented_example: isDev,
      }}
      disableRowSelectionOnClick
      disableColumnMenu
      autoHeight
    />
  );
}

export default PromoPage;
