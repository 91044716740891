import { paths as CustomerCommunicationPaths } from '@luxuryescapes/contract-customer-communication';

import { request } from '~/services/common';

import { makeCustomerCommunicationV1URL } from './customerCommunicationRequestUtils';

type HeroPlannerBannersGetContract =
  CustomerCommunicationPaths['/api/customer-communication/v1/todays-escapes-schedule/hero-planner/banners']['get'];

function mapHeroPlannerBanner(
  external: Utils.ArrayElement<
    HeroPlannerBannersGetContract['responses']['200']['content']['application/json']['result']
  >,
): CustomerCommunication.HeroPlannerBanner {
  return {
    id: external.bannerId,
    name: external.bannerName,
    url: external.bannerImageLink,
  };
}

async function requestGetHeroPlannerBanners(brand?: string) {
  const url = makeCustomerCommunicationV1URL('/todays-escapes-schedule/hero-planner/banners');

  if (brand && brand.trim() !== '') {
    url.searchParams.set('brand', brand);
  }

  return request(url.toString(), {
    method: 'GET',
    credentials: 'include',
  })
    .then((response: HeroPlannerBannersGetContract['responses']['200']['content']['application/json']) => {
      return response.result.map(mapHeroPlannerBanner);
    })
    .catch((error) => {
      throw JSON.stringify(error, null, 2);
    });
}

export default requestGetHeroPlannerBanners;
