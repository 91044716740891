import React from 'react';

import { Stack } from '@mui/material';

import { AccommodationTabs } from '~/components/Accommodation/Pages/Home/AccommodationHomePage';
import AccommodationPropertiesSearchForm from '~/components/Accommodation/Pages/Properties/components/AccommodationPropertiesSearchForm';
import AccommodationUnmappedSuppliersResultsTable from '~/components/Accommodation/Pages/Properties/components/AccommodationUnmappedSuppliersResultsTable';
import usePagedSearch from '~/components/Accommodation/hooks/usePagedSearch';

export default function AccommodationUnmappedSuppliersResultsContainer() {
  const { searchInput, pageNumber, handleSearchFormSubmission, handlePageChange } = usePagedSearch();
  return (
    <Stack direction="column" spacing={2}>
      <AccommodationPropertiesSearchForm
        initialInput={searchInput}
        onSubmit={handleSearchFormSubmission}
        selectedTab={AccommodationTabs.UNMAPPED_SUPPLIERS}
      />
      <AccommodationUnmappedSuppliersResultsTable
        searchInput={searchInput}
        pageIndex={pageNumber - 1}
        onPageChange={handlePageChange}
      />
    </Stack>
  );
}
