import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import BedbankService from '~/services/BedbankService';

export const FETCH_BEDBANK_RESERVATION = 'FETCH_BEDBANK_RESERVATION';

export const FETCH_BEDBANK_RESERVATION_EMPLOYEE_VIEW = 'FETCH_BEDBANK_RESERVATION_EMPLOYEE_VIEW';

export const fetchBedbankReservation = (reservationId: string): ThunkAction<void, never, never, AnyAction> => {
  return async (dispatch) => {
    const response = await BedbankService.getReservationInfo(reservationId);

    if (response?.status === 200 && response?.result) {
      dispatch({
        type: FETCH_BEDBANK_RESERVATION,
        reservation: response.result,
      });
    }
  };
};

export const fetchBedbankReservationEmployeeView = (
  reservationId: string,
): ThunkAction<void, never, never, AnyAction> => {
  return async (dispatch) => {
    const response = await BedbankService.getReservationInfoEmployeeView(reservationId);

    if (response?.status === 200 && response?.result) {
      dispatch({
        type: FETCH_BEDBANK_RESERVATION_EMPLOYEE_VIEW,
        reservationEmployeeView: response.result,
      });
    }
  };
};
