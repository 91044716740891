import React from 'react';

import {
  AddBusiness,
  AddCircleOutlineOutlined,
  Attractions,
  BedOutlined,
  Calculate,
  CalendarTodayRounded,
  CarRental,
  CreditCard,
  CurrencyExchange,
  FlightOutlined,
  HealthAndSafetyOutlined,
  ReceiptLongOutlined,
  Sailing,
  SavingsOutlined,
  StickyNote2Outlined,
  Tour,
  VerifiedOutlined,
  VisibilityOutlined,
} from '@mui/icons-material';
import { Box, SxProps } from '@mui/material';

import SidebarSection, { SidebarSectionProps } from '~/components/Opportunity/OpportunitySidebar/SidebarSection';
import AppSidebar from '~/components/layouts/AppSidebar';
import { HEADER_HEIGHT } from '~/components/layouts/Layout';

import { UserType } from '~/types/responses';

interface OrderDetailsSidebarProps {
  order?: App.Order;
  customer?: UserType;
}

const SIDEBAR_STYLE: SxProps = {
  borderColor: 'divider',
  // Updated sticky positioning to align closer to top when scrolled
  position: { xs: 'static', md: 'sticky' },
  top: { md: 0 }, // Changed from HEADER_HEIGHT to 0 to stick to the top
  alignSelf: { md: 'flex-start' },
  maxHeight: { md: `calc(100vh - ${HEADER_HEIGHT}px)` },
  overflowY: { md: 'auto' },
};

export default function OrderDetailsSidebar({ order, customer }: OrderDetailsSidebarProps) {
  if (!order || !customer) {
    return <Box sx={{ width: 280, p: 2 }}>Loading...</Box>;
  }

  const items = [];
  if (order?.items.length > 0) {
    items.push({
      label: 'Accommodations',
      icon: <BedOutlined />,
      url: `#accommodations`,
    });
  }

  if (order.experience_items.length > 0) {
    items.push({
      label: 'Experiences',
      icon: <Attractions />,
      url: `#experiences`,
    });
  }

  if (order.bedbank_items.length > 0) {
    items.push({
      label: 'Bedbank',
      icon: <BedOutlined />,
      url: `#bedbank`,
    });
  }

  if (order.subscription_items.length > 0) {
    items.push({
      label: 'Subscriptions',
      icon: <VerifiedOutlined />,
      url: `#subscription-items`,
    });
  }

  if (order.tour_items.length > 0) {
    items.push({
      label: 'Tour',
      icon: <Tour />,
      url: `#tour`,
    });
  }

  if (order.car_hire_items.length > 0) {
    items.push({
      label: 'Car Hire',
      icon: <CarRental />,
      url: `#car-hire`,
    });
  }

  if (order.cruise_items.length > 0) {
    items.push({
      label: 'Cruises',
      icon: <Sailing />,
      url: `#cruises`,
    });
  }

  if (order.flight_items.length > 0) {
    items.push({
      label: 'Flights',
      icon: <FlightOutlined />,
      url: `#flights`,
    });
  }

  if (order.insurance_items.length > 0) {
    items.push({
      label: 'Insurance',
      icon: <HealthAndSafetyOutlined />,
      url: `#insurance`,
    });
  }

  if (order.addon_items.length > 0) {
    items.push({
      label: 'Addons',
      icon: <AddCircleOutlineOutlined />,
      url: `#addons`,
    });
  }

  if (order.business_id !== null) {
    items.push({
      label: 'Business Credits',
      icon: <AddBusiness />,
      url: `#business-credits`,
    });
  }

  const paymentItems = [];

  if (order.service_fee_items.length > 0) {
    paymentItems.push({
      label: 'Service Fee',
      icon: <CreditCard />,
      url: `#service-fee`,
    });
  }

  if (order.deposit_details?.id_deposit) {
    paymentItems.push({
      label: 'Deposit Details',
      icon: <SavingsOutlined />,
      url: `#deposit-details`,
    });
  }

  if (order.merchant_fees?.length) {
    paymentItems.push({
      label: 'Card Fee',
      icon: <CreditCard />,
      url: `#card-fee`,
    });
  }

  if (order.payments.length) {
    paymentItems.push({
      label: 'Transactions History',
      icon: <CurrencyExchange />,
      url: `#transactions`,
    });
  }

  if (order.refunds.length) {
    paymentItems.push({
      label: 'Accounting Data',
      icon: <Calculate />,
      url: `#accounting-data`,
    });
  }

  if (order.cards.length > 0) {
    paymentItems.push({
      label: 'Virtual Credit Cards',
      icon: <CreditCard />,
      url: `#vcc-items`,
    });
  }

  if (order.payment_plans?.plans?.length) {
    paymentItems.push({
      label: 'Payment Plan Log',
      icon: <CalendarTodayRounded />,
      url: `#payment-plan-log`,
    });
  }

  paymentItems.push({
    label: 'Historical Logs',
    icon: <ReceiptLongOutlined />,
    url: `#historical-order-logs`,
  });

  const sections: Array<SidebarSectionProps> = [
    {
      title: 'SUMMARY',
      items: [
        {
          label: 'Overview',
          icon: <VisibilityOutlined />,
          url: `#overview`,
        },
        {
          label: 'Notes',
          icon: <StickyNote2Outlined />,
          url: `#order-notes`,
        },
      ],
    },
    {
      title: 'DETAILS',
      items,
    },
    {
      title: 'PAYMENTS',
      items: paymentItems,
    },
  ];

  return (
    <AppSidebar sx={SIDEBAR_STYLE}>
      {sections.map((section, index) => (
        <SidebarSection key={`${section.title}-${index}`} {...section} />
      ))}
    </AppSidebar>
  );
}
