import React, { Component } from 'react';

import { WithSnackbarProps, withSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import { Box, Button, MenuItem, TextField } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { withTenant } from '~/components/hoc';

import {
  CREATE_TYPE_EXPIRED_BNBL_PACKAGE,
  CREATE_TYPE_OTHER,
  CREATE_TYPE_PEOPLE_AND_CULTURE,
  CREATE_TYPE_REACTIVATED_CREDIT,
  CREATE_TYPE_TRANSFERRING_CREDIT,
  CREDIT_TYPE_COVID_NO_SHOW,
  CREDIT_TYPE_PAY_LATER_REFUND,
  CREDIT_TYPE_STORE_CREDIT,
  CREDIT_TYPE_STRIPE_INVOICE,
  DEFAULT_EXPIRY_IN_MONTHS,
  PAY_LATER_DEFAULT_EXPIRY_IN_MONTHS,
  STRIPE_PAYMENT_ZONES,
} from '~/consts/credits';
import { ROLE_TOUR_COORDINATOR } from '~/consts/roles';

import { addCredit } from '~/services/PaymentsService';
import { addMonths, endOfDay, formatDateLongISO } from '~/services/TimeService';

import { isAdmin } from '~/utils/adminPermission';

import DateTimeWidget from '../Elements/DateTimeWidget';

type Props = {
  currency: string;
  creditTypes: Array<{ creditType: string; label?: string }>;
  id_user: string;
  tenant: App.Tenant;
  updateAllExpiryDate: (date: string) => string;
  rerender: () => void;
};

type State = {
  isAdmin: boolean;
  creditType: string;
  stripe_payment_zones: Array<string>;
  amount: string;
  comments: string;
  bookingId: string;
  cardNumber: string;
  stripePaymentZone: string;
  stripePaymentReference: string;
  showBookingId: boolean;
  showCardNumber: boolean;
  showPaymentZone: boolean;
  showPaymentReference: boolean;
  expires_at: string;
  addCreditsRequestState: Utils.FetchingState;
};

type Context = {
  user: App.User;
};

class CreditsForm extends Component<Props & WithSnackbarProps, State> {
  static contextTypes: { user: PropTypes.Requireable<object> };

  currency: string;

  constructor(props: Props & WithSnackbarProps, context: Context) {
    super(props);

    // dayjs.tz.setDefault(window.configs.DEFAULT_TIMEZONE);

    const creditType = this.props.creditTypes[0].creditType;

    this.state = {
      isAdmin: isAdmin(context.user) || context.user?.roles?.includes(ROLE_TOUR_COORDINATOR),
      creditType: creditType,
      stripe_payment_zones: STRIPE_PAYMENT_ZONES,
      amount: '',
      comments: '',
      bookingId: '',
      cardNumber: '',
      stripePaymentZone: STRIPE_PAYMENT_ZONES[0],
      stripePaymentReference: '',
      showBookingId: false,
      showCardNumber: false,
      showPaymentZone: false,
      showPaymentReference: false,
      expires_at: this.getExpireDate(),
      addCreditsRequestState: 'idle',
    };
  }

  getExpireDate = (value?: string) => {
    let expiry = DEFAULT_EXPIRY_IN_MONTHS;

    switch (value) {
      case CREDIT_TYPE_STRIPE_INVOICE:
      case CREDIT_TYPE_PAY_LATER_REFUND:
      case CREATE_TYPE_EXPIRED_BNBL_PACKAGE:
      case CREATE_TYPE_OTHER:
        // Defaults to 12 months
        expiry = PAY_LATER_DEFAULT_EXPIRY_IN_MONTHS;
        break;

      case CREATE_TYPE_REACTIVATED_CREDIT:
      case CREATE_TYPE_PEOPLE_AND_CULTURE:
        // Defaults to 1 month
        expiry = DEFAULT_EXPIRY_IN_MONTHS;
        break;
    }

    return formatDateLongISO(endOfDay(addMonths(expiry)));
  };

  handleBookingIdChange = (event) => {
    this.setState({ bookingId: event.target.value });
  };

  handleCardNumberChange = (event) => {
    this.setState({ cardNumber: event.target.value });
  };

  handleAddCreditChange = (event) => {
    this.setState({ amount: event.target.value });
  };

  handlePaymentZoneChange = (event) => {
    this.setState({ stripePaymentZone: event.target.value });
  };

  handlePaymentReferenceChange = (event) => {
    this.setState({ stripePaymentReference: event.target.value });
  };

  handleCreditTypeChange = (event) => {
    const value = event.target.value;
    let showBookingId = false;
    let showCardNumber = false;
    let showPaymentZone = false;
    let showPaymentReference = false;

    if (value === CREDIT_TYPE_COVID_NO_SHOW) {
      showBookingId = true;
    }

    if (value === CREATE_TYPE_TRANSFERRING_CREDIT) {
      showCardNumber = true;
    }

    const paymentZoneTypes = [CREDIT_TYPE_STRIPE_INVOICE, CREDIT_TYPE_STORE_CREDIT];
    if (paymentZoneTypes.includes(value)) {
      showPaymentReference = true;
    }

    if (value === CREDIT_TYPE_STRIPE_INVOICE) {
      showPaymentZone = true;
    }

    this.setState({
      creditType: value,
      showBookingId,
      showCardNumber,
      showPaymentZone,
      showPaymentReference,
      expires_at: this.getExpireDate(value),
    });
  };

  handleCommentsChange = (event) => {
    this.setState({ comments: event.target.value });
  };

  handleAddCreditSubmit = async (event) => {
    event.preventDefault();

    this.setState({ addCreditsRequestState: 'loading' });

    const object = {
      amount: this.state.amount,
      currency: this.props.currency,
      fk_member: this.props.id_user,
      credit_type: this.state.creditType,
      comments: this.state.comments,
      expires_at: this.state.expires_at,
      brand: this.props.tenant.brand,
    };

    if (this.state.creditType === CREDIT_TYPE_COVID_NO_SHOW && this.state.bookingId) {
      object.comments += (object.comments.length ? ' - ' : '') + this.state.bookingId;
    }

    if (this.state.creditType === CREATE_TYPE_TRANSFERRING_CREDIT && this.state.cardNumber) {
      object.comments += (object.comments.length ? ' - ' : '') + this.state.cardNumber;
    }

    if (
      this.state.creditType === CREDIT_TYPE_STRIPE_INVOICE &&
      this.state.stripePaymentZone &&
      this.state.stripePaymentReference
    ) {
      object.comments +=
        (object.comments.length ? ' - ' : '') +
        'Payment Zone: ' +
        this.state.stripePaymentZone +
        ', Payment Reference: ' +
        this.state.stripePaymentReference;
    }

    await addCredit(object);

    this.setState({
      amount: '',
      comments: '',
      creditType: this.props.creditTypes[0].creditType,
      expires_at: this.getExpireDate(),
      addCreditsRequestState: 'idle',
    });

    this.props.updateAllExpiryDate(object.expires_at);

    this.props.enqueueSnackbar(`Credits added successfully`, { variant: 'success' });

    this.props.rerender();
  };

  setExpiryDate = (expiryDate: string) => {
    this.setState({ expires_at: expiryDate });
  };

  render() {
    return (
      <form onSubmit={this.handleAddCreditSubmit} className="add-credit-form">
        <Grid spacing={2} container>
          <Grid xs={12} md={4}>
            <TextField
              id="AddCredits"
              label={`Amount in ${this.props.currency}`}
              variant="outlined"
              type="number"
              value={this.state.amount}
              onChange={this.handleAddCreditChange}
              fullWidth
              required
            />
          </Grid>

          <Grid xs={12} md={4}>
            <TextField
              label="Credit type"
              variant="outlined"
              value={this.state.creditType}
              onChange={this.handleCreditTypeChange}
              select
              fullWidth
            >
              {this.props.creditTypes.map((item, idx) => (
                <MenuItem key={idx} value={item.creditType}>
                  {item.label || item.creditType}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid xs={12} md={4}>
            <DateTimeWidget
              label="Expiry date"
              value={this.state.expires_at}
              onChange={this.setExpiryDate}
              disabled={!this.state.isAdmin}
            />
          </Grid>

          <Grid xs={12}>
            <TextField
              label="Comment"
              placeholder="Additional comment, why this credits were added"
              variant="outlined"
              value={this.state.comments}
              onChange={this.handleCommentsChange}
              fullWidth
              multiline
            />
          </Grid>

          {this.state.showBookingId && (
            <Grid xs={12} md={4}>
              <TextField
                label="Related Booking ID(s)"
                placeholder="Mandatory for type COVID-19 No Show"
                variant="outlined"
                value={this.state.bookingId}
                onChange={this.handleBookingIdChange}
                inputProps={{
                  minLength: 6,
                }}
                fullWidth
                required
              />
            </Grid>
          )}

          {this.state.showCardNumber && (
            <Grid xs={12} md={4}>
              <TextField
                id="cardNumber"
                label="Case number with approval"
                placeholder="Case number with approval"
                variant="outlined"
                value={this.state.cardNumber}
                onChange={this.handleCardNumberChange}
                inputProps={{
                  minLength: 2,
                }}
                fullWidth
                required
              />
            </Grid>
          )}

          {this.state.showPaymentZone && (
            <Grid xs={12} md={4}>
              <TextField
                label="Stripe Payment Zone"
                variant="outlined"
                value={this.state.stripePaymentZone}
                onChange={this.handlePaymentZoneChange}
                select
                fullWidth
                required
              >
                {this.state.stripe_payment_zones.map((item, idx) => (
                  <MenuItem key={idx} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}

          {this.state.showPaymentReference && (
            <Grid xs={12} md={4}>
              <TextField
                label="Payment Reference"
                variant="outlined"
                value={this.state.stripePaymentReference}
                onChange={this.handlePaymentReferenceChange}
                inputProps={{
                  minLength: 2,
                }}
                fullWidth
                required
              />
            </Grid>
          )}
        </Grid>

        <Box textAlign="right" mt={2}>
          <Button variant="contained" type="submit" disabled={this.state.addCreditsRequestState === 'loading'}>
            Add credits
          </Button>
        </Box>
      </form>
    );
  }
}

CreditsForm.contextTypes = {
  user: PropTypes.object,
};

export default withTenant(withSnackbar(CreditsForm));

export { CreditsForm as CreditsFormComponent };
