import React from 'react';

import { CssBaseline, ThemeProvider } from '@mui/material';

import { lightTheme } from '../configs/leTheme';

export default function MUIProvider({ children }) {
  return (
    <ThemeProvider theme={lightTheme}>
      <CssBaseline>{children}</CssBaseline>
    </ThemeProvider>
  );
}
