import React, { useCallback, useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import { blue } from '@mui/material/colors';

import Spinner from '~/components/Common/Spinner';

import BedbankService from '~/services/BedbankService';

import asyncPoll from '~/utils/asyncPoll';

type Props = {
  open: boolean;
  modalTitle: string;
  confirmationText: string;
  startJob: () => Promise<{ result: App.Bedbank.StatusResponse }>;
  onClose: () => void;
};

function BedbankProgressModal({ open, modalTitle, confirmationText, startJob, onClose }: Props) {
  const [isLoading, setLoading] = useState(false);
  const [isComplete, setComplete] = useState(false);
  const [status, setStatus] = useState<string | null>(null);
  const [statusMessage, setStatusMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const TWO_MINUTES_IN_MS = 60000 * 2;
  const POLL_RATE_IN_MS = 1000;

  const handleError = useCallback((message) => {
    setErrorMessage(message);
    setStatusMessage(null);
    setLoading(false);
  }, []);

  const pollImportStatus = useCallback(
    async (id, processingType) => {
      try {
        await asyncPoll({
          apiCall: () => BedbankService.getProgressStatus(id, processingType),
          validateFunction: async ({ result }) => {
            setStatus(result?.state);
            setStatusMessage(result?.message);
            if (result?.state === 'completed') {
              setLoading(false);
              setComplete(true);
              return true;
            } else if (result?.state === 'failed') {
              setLoading(false);
              setComplete(true);
              handleError(result?.message);
              return true;
            } else {
              return false;
            }
          },
          maxTime: TWO_MINUTES_IN_MS,
          pollInterval: POLL_RATE_IN_MS,
        });
      } catch (e) {
        setStatus('error');
        handleError(e.message);
        setLoading(false);
        return;
      }
    },
    [TWO_MINUTES_IN_MS, handleError],
  );

  const runStartJob = useCallback(async () => {
    setLoading(true);
    setComplete(false);
    setErrorMessage(null);

    const { result } = await startJob();
    if (!result?.id) {
      handleError('Failed to start job');
      return;
    }
    pollImportStatus(result.id, result.type);
  }, [startJob, pollImportStatus, handleError]);

  const resetAndClose = useCallback(() => {
    setLoading(false);
    setComplete(false);
    setStatus(null);
    setStatusMessage(null);
    setErrorMessage(null);
    onClose();
  }, [onClose]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{modalTitle}</DialogTitle>
      <DialogContent sx={{ minWidth: '400px' }}>
        <DialogContentText>
          Clicking 'I understand' below {confirmationText}.
          <br />
          Running progress will be displayed below:
          <DialogContent>
            {!isComplete && status && <Typography sx={{ color: blue[500] }}>{statusMessage}</Typography>}
            {isComplete && <Typography sx={{ color: 'success.main' }}>{statusMessage}</Typography>}
            {errorMessage && <Typography sx={{ color: 'warning.main' }}>{errorMessage}</Typography>}
          </DialogContent>{' '}
        </DialogContentText>
      </DialogContent>
      {!isComplete && (
        <DialogActions>
          <Button variant="text" onClick={onClose} disabled={isLoading}>
            Cancel
          </Button>
          <Button variant="contained" onClick={runStartJob} disabled={isLoading}>
            {isLoading ? <Spinner size={15} inline /> : 'I understand'}
          </Button>
        </DialogActions>
      )}
      {isComplete && (
        <DialogActions>
          <Button variant="contained" onClick={resetAndClose} disabled={isLoading}>
            Close
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}

export default BedbankProgressModal;
