import { useCallback, useState } from 'react';

import { useSnackbar } from 'notistack';

import { operations } from '@luxuryescapes/contract-svc-promo';

import { processReferralLogs } from '~/services/PromoService';

type ReferralLog = operations['processReferralLogs']['responses']['200']['content']['application/json']['result']['0'];

type PageProcessLogProps = {
  limit: number;
  offset: number;
};

type LookupProcessLogProps = {
  referralLogIds: string[];
  ignoreOrderStatus?: boolean;
};

type ProcessLogProps = {
  referralLogId: string;
  ignoreOrderStatus?: boolean;
};

type ProcessLogsProps = PageProcessLogProps | LookupProcessLogProps;

interface UseReferralLogsReturn {
  logs: ReferralLog[] | null;
  errors: any[] | null;
  isLoading: boolean;
  processLog: (params: ProcessLogProps) => Promise<void>;
  processLogs: (params: ProcessLogsProps) => Promise<void>;
  onSuccess: () => void;
}

const useReferralProcessor = (): UseReferralLogsReturn => {
  const [logs, setLogs] = useState<ReferralLog[] | null>(null);
  const [errors, setErrors] = useState<any[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  const processLogs = useCallback(async (props: ProcessLogsProps) => {
    setIsLoading(true);
    setErrors(null);

    try {
      const apiCallProps =
        'referralLogIds' in props
          ? {
              referralLogIds: props.referralLogIds,
            }
          : {
              limit: props.limit,
              offset: props.offset,
            };
      const response = await processReferralLogs(apiCallProps);
      enqueueSnackbar('Successfully processed logs ' + response.message, { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Error processing logs ' + error.response?.data || error.message, { variant: 'error' });
      setErrors([error.response?.data || error.message]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const processLog = useCallback(async (props: ProcessLogProps) => {
    setIsLoading(true);
    setErrors(null);

    try {
      const apiCallProps = {
        referralLogIds: [props.referralLogId],
        ignoreOrderStatus: props.ignoreOrderStatus ?? false,
      };
      const response = await processReferralLogs(apiCallProps);

      setLogs(response.result.logs || []);
      onSuccess();
    } catch (error) {
      setErrors([error.response?.data || error.message]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const onSuccess = useCallback(() => {
    setLogs(null);
    setErrors(null);
  }, []);

  return {
    logs,
    errors,
    isLoading,
    processLogs,
    processLog,
    onSuccess,
  };
};

export default useReferralProcessor;
