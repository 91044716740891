import { CruisesContract, definitions } from '@luxuryescapes/contract-svc-cruise';

import { SearchFilters } from '~/components/Cruises/pages/Home/components/Sailings/constants';

import cruisesAPI from './CruisesApiService';

type GetRateCodesQueryParams = definitions['GET_RATE_CODES_QUERY'];
export type GetRateCodes = definitions['RATE_CODE_WITH_VENDOR_AND_PROMOTION'];
type GetRateCodeById = definitions['GET_RATE_CODE_BY_ID'];
type UpdateRateCodeById = definitions['UPDATE_RATE_CODE_BY_ID'];

export type GetRateCodesResponse = CruisesContract.Response<GetRateCodes[]>;
export type GetRateCodeByIdResponse = CruisesContract.Response<GetRateCodeById>;
export type UpdateRateCodeByIdResponse = CruisesContract.Response<UpdateRateCodeById>;

const RESOURCE = 'rate-codes';

const getRateCodeById = (id: string): Promise<GetRateCodeByIdResponse> => {
  return cruisesAPI.http.get({
    resource: `${RESOURCE}/${id}`,
  });
};

const getRateCodeList = async (params?: GetRateCodesQueryParams): Promise<GetRateCodesResponse> => {
  const queryParams = {
    ...(params?.skip && { skip: params.skip }),
    ...(params?.take && { take: params.take }),
    ...(params?.rateCodes && { rateCodes: params.rateCodes }),
    ...(params?.vendorCode && { vendorCode: params.vendorCode }),
    ...(params?.promotionName && { promotionName: params.promotionName }),
  };

  return await cruisesAPI.http.get({
    resource: `${RESOURCE}`,
    paginationParams: queryParams,
  });
};

const createBulkRateCodeByDepartureId = async (
  params: SearchFilters & {
    rateCodes: string[];
    departureIds: string[];
    isFlash: boolean;
    isSelectAll: boolean;
  },
): Promise<CruisesContract.Response<CruisesContract.GenericMessageResponse>> => {
  const {
    rateCodes,
    departureIds,
    isFlash,
    isSelectAll,
    name,
    offerId,
    vendorId,
    sailingExternalId,
    sailingPlan,
    sailingDurationMin,
    sailingDurationMax,
    departureDateStart,
    departureDateEnd,
    departurePortExternalIds,
    status: departureStatus,
    hasCoreChanges,
    hasItineraryChanges,
    shipName,
    shipExternalId,
  } = params;

  return await cruisesAPI.http.post({
    resource: `${RESOURCE}`,
    body: {
      rateCodes,
      departureIds,
      isFlash,
      isSelectAll,
      name,
      offerId,
      vendorId,
      sailingExternalId,
      sailingPlan,
      sailingDurationMin,
      sailingDurationMax,
      departureDateStart,
      departureDateEnd,
      departurePortExternalIds,
      departureStatus,
      hasCoreChanges: hasCoreChanges === 'ALL' ? undefined : hasCoreChanges === 'TRUE',
      hasItineraryChanges: hasItineraryChanges === 'ALL' ? undefined : hasItineraryChanges === 'TRUE',
      shipName,
      shipExternalId,
    },
  });
};

const updateRateCodeById = (
  id: string,
  params: { isFlash: boolean },
): Promise<CruisesContract.Response<UpdateRateCodeByIdResponse>> => {
  const { isFlash } = params;

  return cruisesAPI.http.put({
    resource: `${RESOURCE}/${id}`,
    body: { isFlash },
  });
};

export default {
  getRateCodeById,
  getRateCodeList,
  updateRateCodeById,
  createBulkRateCodeByDepartureId,
};
