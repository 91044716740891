import React from 'react';

import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { currencyFormatter } from '~/components/Experiences/helpers';

interface Props {
  businessCreditItems: Array<App.BusinessCreditItem>;
}

const columns: Array<GridColDef> = [
  {
    field: 'id',
    headerName: 'Id',
    disableColumnMenu: true,
    flex: 1,
    display: 'flex',
  },
  {
    field: 'total',
    headerName: 'Total',
    disableColumnMenu: true,
    flex: 1,
    renderCell: (params) => currencyFormatter(params.row.currency_code, params.row.total, 2),
    display: 'flex',
  },
  {
    field: 'type',
    headerName: 'Type',
    disableColumnMenu: true,
    flex: 1,
    display: 'flex',
  },
  {
    field: 'currency_code',
    headerName: 'Currency Code',
    disableColumnMenu: true,
    flex: 1,
    display: 'flex',
  },
];

export default function OrderDetailBusinessCredits({ businessCreditItems }: Props) {
  return (
    <Accordion defaultExpanded id="business-credits" sx={{ mt: 2 }}>
      <AccordionSummary>
        <AddBusinessIcon sx={{ mr: 1 }} />
        <Typography variant="h6">Business Credits ({businessCreditItems.length})</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <DataGrid
          columns={columns}
          rows={businessCreditItems}
          autoHeight
          pageSizeOptions={[]}
          disableColumnMenu
          disableRowSelectionOnClick
          hideFooter
          getRowHeight={() => 'auto'}
        />
      </AccordionDetails>
    </Accordion>
  );
}
