import React, { useMemo } from 'react';

import { useSnackbar } from 'notistack';

import { Checkbox, FormControlLabel, Stack } from '@mui/material';
import { DataGrid, GridColumnVisibilityModel } from '@mui/x-data-grid';

import { API } from '@luxuryescapes/lib-types';

import GridPagination from '~/components/Common/Elements/GridPagination';
import { ColumnControlForm } from '~/components/Purchases/Home/AllOrders/ColumnControlForm';

import { useSessionStorage } from '~/hooks/useSessionStorage';

import { columns } from './consts';

const LOCAL_STORE_KEY = 'OrdersPage.all-orders-column-control';

interface Props {
  purchases: Array<API.Order.Order>;
  total: number;
  page: number;
  sizePerPage: number;
  onPageChange: (page: number) => void;
}

const gridVisibilityInit = columns.reduce((returnVal, column) => {
  returnVal[column.field] = true;
  return returnVal;
}, {});

export default function OrdersPage({ page, onPageChange, sizePerPage, purchases, total }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [visibleColumns, setVisibleColumns] = useSessionStorage<GridColumnVisibilityModel>(
    LOCAL_STORE_KEY,
    gridVisibilityInit,
    (errMsg: string) =>
      enqueueSnackbar(`Error loading existing column visibility settings ${errMsg}`, { variant: 'warning' }),
  );

  const columnControl = useMemo(() => {
    return columns.map((column) => {
      const checked = visibleColumns[column.field];
      return (
        <FormControlLabel
          key={column.field}
          control={<Checkbox checked={checked} />}
          onChange={() => setVisibleColumns({ ...visibleColumns, [column.field]: !checked })}
          label={column.headerName}
        />
      );
    });
  }, [visibleColumns, setVisibleColumns]);

  return (
    <>
      <ColumnControlForm>
        <Stack sx={{ padding: '1em' }}>{columnControl}</Stack>
      </ColumnControlForm>
      <DataGrid
        columnVisibilityModel={visibleColumns}
        rows={purchases}
        rowCount={total}
        columns={columns}
        pagination
        paginationMode="server"
        paginationModel={{
          page: page - 1,
          pageSize: sizePerPage,
        }}
        pageSizeOptions={[sizePerPage]}
        classes={{
          root: 'T-packages-container',
          virtualScrollerContent: 'T-packages-table',
        }}
        slots={{ pagination: GridPagination }}
        onPaginationModelChange={({ page }) => onPageChange(page + 1)}
        getRowHeight={() => 'auto'}
        disableColumnFilter
        disableColumnMenu
        disableRowSelectionOnClick
        autoHeight
      />
    </>
  );
}
