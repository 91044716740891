import React, { useEffect, useMemo } from 'react';

import { capitalize, sum } from 'lodash';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { selectBedbankRefundPoliciesEmployeeView } from '~/selectors/bedbankSelector';

import { ContentCopy } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Link,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { Order } from '@luxuryescapes/contract-svc-order';

import { fetchBedbankReservationEmployeeView } from '~/actions/bedbank';

import CustomOfferRebookingOrderBanner from '~/components/Common/CustomOfferRebookingOrderBanner';
import PermissionedComponent from '~/components/Common/PermissionedComponent';
import Spinner from '~/components/Common/Spinner';

import { BEDBANK_SUPPLIER_INFO } from '~/consts/bedbank';
import { ROLE_ADMIN_USER, ROLE_EMPLOYEE_USER, ROLE_ICS_STORE_TEAM } from '~/consts/roles';

import { useAppSelector } from '~/hooks/store';

import { formatDateLongMonthWithMeridiemShort } from '~/services/TimeService';

import { titleCase } from '~/utils/stringUtils';

import dateFormatter from '../Home/formatters/dateFormatter';

import OrderItemFacilities from './BedbankList/Facilities';
import OrderItemFinePrint from './BedbankList/FinePrint';
import OrderItem from './BedbankList/List';
import Log from './OrderItem/Log';
import Supplier from './OrderItem/Supplier';
import ResendEmail from './ResendEmail';

const getItemStatusColor = (status: string) => {
  let itemStatusColour = 'white';
  switch (status) {
    case 'completed':
    case 'refunded':
      itemStatusColour = 'rgba(59, 171, 83, 0.04)';
      break;
    case 'cancelled':
    case 'abandoned':
    case 'payment_failed':
      itemStatusColour = 'rgba(239, 78, 107, 0.04)';
      break;
    case 'pending':
    case 'awaiting_payment':
    case 'awaiting_purchase':
    case 'needs_attention':
      itemStatusColour = 'rgba(237, 108, 2, 0.04)';
      break;
  }
  return itemStatusColour;
};

const getItemStatusBorder = (status: string) => {
  let itemStatusColour = 'white';
  switch (status) {
    case 'completed':
    case 'refunded':
      itemStatusColour = 'rgba(59, 171, 83, 0.3)';
      break;
    case 'cancelled':
    case 'abandoned':
    case 'payment_failed':
      itemStatusColour = 'rgba(239, 78, 107, 0.5)';
      break;
    case 'pending':
    case 'awaiting_payment':
    case 'awaiting_purchase':
    case 'needs_attention':
      itemStatusColour = 'rgba(228, 159, 78, 0.3)';
      break;
  }
  return itemStatusColour;
};

const getOrderStatusColor = (status: string) => {
  let orderStatusColor = 'black';
  switch (status) {
    case 'completed':
    case 'refunded':
      orderStatusColor = 'green';
      break;
    case 'cancelled':
    case 'abandoned':
    case 'payment_failed':
      orderStatusColor = 'red';
      break;
    case 'pending':
    case 'awaiting_payment':
    case 'awaiting_purchase':
    case 'needs_attention':
      orderStatusColor = 'orange';
      break;
  }
  return orderStatusColor;
};

type Props = {
  order: App.Order;
  item: Order.BedbankItem & { updated_at: string };
  hasAllowedRefund: boolean;
  bedbankRoomsInfo: {
    [reservationRoomId: string]: {
      id: string;
      status: string;
      reservationStatus: string;
      reservationRoomStatus: string;
      rebook?: App.Bedbank.ReservationRebookInfo;
      info?: App.Bedbank.ReservationRoomInfo;
    };
  };
  count: number;
  customerEmailToAgentState: Record<string, unknown> & { isSending: boolean };
  emailToCustomerState: Record<string, unknown> & { isSending: boolean };
  rebookableLoading: boolean;
  resendCustomerEmail: (offerId: string, offerType?: string) => void;
  resendCustomerEmailToAgent: (offerId: string, offerType?: string) => void;
  showRefundModal: (value: unknown) => void;
  showReconfirmModal: (value: unknown) => void;
  shouldShowCancellationWarning: boolean;
  checkAndShowRebookingModal: (itemId: string) => Promise<void>;
};

export default function OrderDetailBedbank({
  order,
  item,
  hasAllowedRefund,
  bedbankRoomsInfo,
  customerEmailToAgentState,
  emailToCustomerState,
  rebookableLoading,
  resendCustomerEmail,
  resendCustomerEmailToAgent,
  showRefundModal,
  showReconfirmModal,
  shouldShowCancellationWarning,
  checkAndShowRebookingModal,
  count,
}: Props) {
  const tenant = useSelector((state: App.State) => state.tenant);

  const { enqueueSnackbar } = useSnackbar();

  const handleCopyToClipboard = (info: string) => {
    navigator.clipboard.writeText(info);
    enqueueSnackbar('Copied to clipboard', { variant: 'success', autoHideDuration: 2000 });
  };

  const [isRebookable, marginIncAud] = useMemo(() => {
    const rebookable = (item.rooms ?? []).every((room) => bedbankRoomsInfo[room.id_reservation_room]?.rebook);
    const marginInc = sum(
      (item.rooms ?? []).map((r) => bedbankRoomsInfo[r.id_reservation_room]?.rebook?.marginIncreaseInAUD ?? 0),
    );
    return [rebookable, marginInc];
  }, [item, bedbankRoomsInfo]);

  const isNotFinished = useMemo(() => {
    return (
      item.status === 'cancelled' &&
      order.status === 'pending' &&
      (item.rooms ?? []).every(
        (room) =>
          room.status === 'pending' &&
          bedbankRoomsInfo[room.id_reservation_room] &&
          bedbankRoomsInfo[room.id_reservation_room]?.reservationStatus === 'failed',
      )
    );
  }, [item, order, bedbankRoomsInfo]);

  const reservationId = item.id_reservation;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchBedbankReservationEmployeeView(reservationId));
  }, [reservationId, dispatch]);

  const refundPolicies = useAppSelector((state) => selectBedbankRefundPoliciesEmployeeView(state, reservationId));

  const supplierKey = (item.supplier ?? 'expedia').toLowerCase();
  const supplierInfo = BEDBANK_SUPPLIER_INFO[supplierKey];

  if (rebookableLoading) {
    return (
      <Dialog open>
        <DialogContent>
          <Spinner />
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Accordion key={item.id} defaultExpanded={item.status !== 'cancelled'} sx={{ mt: 2 }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          backgroundColor: getItemStatusColor(item.status),
          border: `1px solid ${getItemStatusBorder(item.status)}`,
          padding: 2,
        }}
      >
        <Grid>
          <Box mb={1}>
            <Typography component="span" fontWeight="bold" fontSize="16px">
              {count}. {item.offer.room.name}
            </Typography>
          </Box>
          <Grid direction="row" spacing={2} container>
            <Grid md={3}>
              <Box>
                <Typography component="span">Status: </Typography>
                <Typography component="span" fontWeight="bold" color={getOrderStatusColor(item.status)}>
                  {capitalize(item.status)}
                </Typography>
              </Box>
            </Grid>
            <Grid md={3}>
              <Box>
                <Typography component="span">Last updated at: </Typography>
                <Typography component="span" fontWeight="bold">
                  {formatDateLongMonthWithMeridiemShort(item.updated_at)}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Box
          key={item.id}
          sx={{
            border: '1px solid',
            borderColor: 'grey.100',
            borderRadius: '8px',
            marginTop: 2,
            marginBottom: '20px',
          }}
        >
          <Box bgcolor="grey.50" p={2}>
            <Box display="grid" columnGap={2} gridTemplateColumns="auto 1fr" alignItems="center">
              <Typography color="black">Order Item ID:</Typography>
              <Typography>{item.id}</Typography>

              <Typography color="black">LE Booking Number:</Typography>
              <Typography>
                {item.booking_number}{' '}
                <IconButton
                  title="Copy booking number"
                  onClick={() => {
                    handleCopyToClipboard(item.booking_number);
                  }}
                  size="small"
                >
                  <ContentCopy fontSize="small" />
                </IconButton>
              </Typography>

              <Typography color="black">Bedbank Reservation ID:</Typography>
              <Typography>
                {reservationId}{' '}
                <IconButton
                  title="Copy reservation id"
                  onClick={() => {
                    handleCopyToClipboard(reservationId);
                  }}
                  size="small"
                >
                  <ContentCopy fontSize="small" />
                </IconButton>
              </Typography>

              <Typography color="black">Supplier:</Typography>
              <Typography>{titleCase(item.supplier ?? 'expedia')}</Typography>

              <Typography color="black">Supplier Booking Number:</Typography>
              <Typography>{item.supplier_booking_number ?? item.booking_number}</Typography>

              <Typography color="black">Reservation Email:</Typography>
              <Typography>{supplierInfo?.email ?? 'N/A'}</Typography>

              <Typography color="black">Reservation Phone:</Typography>
              <Typography>{supplierInfo?.phone ?? 'N/A'}</Typography>

              {supplierInfo?.portal && (
                <>
                  <Typography color="black">Portal:</Typography>
                  <Link href={supplierInfo.portal} target="_blank" rel="noopener noreferrer">
                    {supplierInfo.portal}
                  </Link>
                </>
              )}

              {item.parent_booking_number && (
                <>
                  <Typography color="black">Parent Booking Number:</Typography>
                  <Typography>{item.parent_booking_number}</Typography>
                </>
              )}
            </Box>

            {isNotFinished && (
              <PermissionedComponent requiredRoles={[ROLE_ADMIN_USER]}>
                <Box display="grid" columnGap={2} gridTemplateColumns="repeat(2, auto) 1fr" alignItems="center">
                  <Typography color="black">Supplier reservation is not confirmed:</Typography>
                  <Button variant="text" size="small" onClick={() => showReconfirmModal({ itemId: item.id })}>
                    Reconfirm{' '}
                  </Button>
                </Box>
              </PermissionedComponent>
            )}

            <Box display="grid" columnGap={2} gridTemplateColumns="repeat(3, auto) 1fr" alignItems="center">
              <Typography color="black">Rebooking:</Typography>
              <Button variant="text" size="small" onClick={() => checkAndShowRebookingModal(item.id)}>
                {isRebookable ? `Check & Rebook (+${marginIncAud})` : 'Check'}
              </Button>
            </Box>

            {item.parent_reservation_id && (
              <Box display="grid" columnGap={2} gridTemplateColumns="repeat(2, auto) 1fr" alignItems="center">
                <Typography color="black">Rebooked:</Typography>
                <Typography>{dateFormatter(item.rebooked_at)}</Typography>
              </Box>
            )}
            <PermissionedComponent requiredRoles={[ROLE_ADMIN_USER, ROLE_EMPLOYEE_USER, ROLE_ICS_STORE_TEAM]}>
              <Box maxWidth="600px">
                <ResendEmail
                  emailType="customer"
                  resendEmail={resendCustomerEmail}
                  isCustomerEmailDisabled={emailToCustomerState.isSending}
                  resendEmailToAgent={resendCustomerEmailToAgent}
                  isAgentEmailDisabled={customerEmailToAgentState.isSending}
                />
              </Box>
            </PermissionedComponent>
          </Box>

          <CustomOfferRebookingOrderBanner order={order} />

          <Box p={2}>
            <OrderItem
              order={order}
              tenant={tenant}
              showRefundModal={showRefundModal}
              hasAllowedRefund={hasAllowedRefund}
              item={item}
              bedbankRoomsInfo={bedbankRoomsInfo}
              disableInteraction={false}
              shouldShowCancellationWarning={shouldShowCancellationWarning}
              refundPolicies={refundPolicies}
            />
          </Box>

          <Box p={3}>
            <Supplier reservationId={item.id_reservation} />
            {item.offer?.room?.facility_groups && (
              <OrderItemFacilities name="Room Facilities" facilityGroups={item.offer.room.facility_groups} />
            )}
            {item.offer?.facility_groups && (
              <OrderItemFacilities name="Hotel Facilities" facilityGroups={item.offer.facility_groups} />
            )}
            {item.offer?.fine_print && <OrderItemFinePrint finePrint={item.offer.fine_print} />}
            <Log orderId={item.fk_order_id} itemId={item.id} />
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
