import sum from 'lodash/sum';

import { Order } from '@luxuryescapes/contract-svc-order';

import { ITEM_STATUS_CANCELLED, ITEM_STATUS_COMPLETED } from '~/consts/order';

import { formatDateOrdinalShortMonth } from '~/services/TimeService';

interface ItemWithStatus {
  status?: string;
}

export function isAllCancelled(items: Array<ItemWithStatus>): boolean {
  return items ? items.every((item) => item.status == ITEM_STATUS_CANCELLED) : true;
}

//credits do not exist at the line item level, they cannot be broken down
export function calculateCredit(order: Order.Order) {
  return order?.business_credit_items?.length > 0 ? sum(order.business_credit_items.map((c) => c.total)) : 0;
}

export function calculateDepositTotal(order: Order.Order) {
  const customOfferItems = order.custom_offer_items;
  if (customOfferItems?.length > 0) {
    let depositTotal = 0;

    for (const item of customOfferItems) {
      const paymentMetadata = item?.custom_offer?.payment_metadata;
      if (paymentMetadata?.customer_deposit_1_amount) {
        depositTotal += Math.round(paymentMetadata.customer_deposit_1_amount / 100);
      }
    }
    return depositTotal;
  }
  return 0;
}

export function getSuccessfulItemsTotal(order: Order.Order): number {
  const sumOfSuccessfulItems = (items) => {
    return items
      ? items.reduce((acc, { status, total }) => {
          return status === ITEM_STATUS_COMPLETED ? acc + parseFloat(total) : acc;
        }, 0)
      : 0;
  };

  return [
    order.accommodation_items,
    order.addon_items,
    order.bedbank_items,
    order.flight_items,
    order.offline_flight_items,
    order.gift_card_items,
    order.insurance_items,
    order.tour_items,
    order.experience_items,
    order.service_fee_items,
    order.cruise_items,
    order.subscription_items,
    order.custom_offer_items,
    order.car_hire_items,
  ].reduce((acc, items) => acc + sumOfSuccessfulItems(items), 0);
}

export function formatOrderDate(cell) {
  const date = new Date(cell);

  return formatDateOrdinalShortMonth(date);
}

export function getCustomerPhoneNumberFromOrder(order: App.Order) {
  if (!order.customer_phone_prefix || !order.customer_phone) {
    return '';
  }

  return '+' + order.customer_phone_prefix + order.customer_phone;
}

export function getRebookedCustomOrderIdFromOrder(order: Order.Order) {
  const filteredItems = order.bedbank_items.filter((row) => {
    return row.fk_rebooked_custom_order_id !== null;
  });

  if (filteredItems.length > 0) {
    return filteredItems[0].fk_rebooked_custom_order_id;
  }
  return null;
}
