import { CruisesContract as API } from '@luxuryescapes/contract-svc-cruise';

import {
  CruiseInclusion,
  CruiseInclusionFilter,
  CruiseInclusionItem,
} from '~/components/Cruises/pages/Inclusions/types';

import cruisesAPI from './CruisesApiService';

const RESOURCE = 'inclusions';

export type InclusionFormData = {
  description: string;
  startDate: string | null;
  endDate: string | null;
  redirectType?: string;
  redirectData?: string;
  order?: number;
  isFixed: boolean;
  imageId: string;
  mobileImageId: string;
  availableInBrands?: Array<API.Brand>;
  availableInRegions?: Array<API.Region>;
  isVisibleOnVendorPage: boolean;
  vendorId?: string;
};

// TODO: Use type from contract after BE merge
export type InclusionSearchFormValues = API.PaginationParams & {
  rateCodes?: Array<string>;
  vendorCode?: Array<string>;
  name?: string;
  departureIds?: Array<string>;
};

const listWithPagination = async (
  paginationParams: InclusionSearchFormValues,
): Promise<API.Response<Array<CruiseInclusion>>> => {
  return await cruisesAPI.http.get({ resource: RESOURCE, paginationParams });
};

const create = async (formData: Partial<CruiseInclusion>): Promise<CruiseInclusion> => {
  const response = await cruisesAPI.http.post<API.Response<CruiseInclusion>>({
    resource: RESOURCE,
    body: formData,
  });
  return response.result;
};

const update = async (id: string, formData: Partial<CruiseInclusion>): Promise<CruiseInclusion> => {
  const response = await cruisesAPI.http.put<CruiseInclusion>({
    resource: `${RESOURCE}/${id}`,
    body: formData,
  });

  return response.result;
};

const getById = async (id: string): Promise<CruiseInclusion> => {
  const response = await cruisesAPI.http.get<CruiseInclusion>({
    resource: `${RESOURCE}/${id}`,
  });

  return response.result;
};

const deleteById = async (id: string) => {
  await cruisesAPI.http.delete({
    resource: `${RESOURCE}/${id}`,
  });
};

// Inclusion items
const getItems = async (inclusionId: string): Promise<API.Response<Array<CruiseInclusionItem>>> => {
  return cruisesAPI.http.get({
    resource: `${RESOURCE}/${inclusionId}/items`,
  });
};

const getItemById = async (inclusionId: string, id: string): Promise<CruiseInclusionItem> => {
  const response = await cruisesAPI.http.get<CruiseInclusionItem>({
    resource: `${RESOURCE}/${inclusionId}/items/${id}`,
  });

  return response.result;
};

const createItem = async (
  inclusionId: string,
  formData: Partial<CruiseInclusionItem>,
): Promise<CruiseInclusionItem> => {
  return await cruisesAPI.http.post({
    resource: `${RESOURCE}/${inclusionId}/items`,
    body: formData,
  });
};

const updateItem = async (
  inclusionId: string,
  id: string,
  formData: Partial<CruiseInclusionItem>,
): Promise<CruiseInclusionItem> => {
  const response = await cruisesAPI.http.put<CruiseInclusionItem>({
    resource: `${RESOURCE}/${inclusionId}/items/${id}`,
    body: formData,
  });

  return response.result;
};

const deleteItem = async (inclusionId: string, id: string): Promise<API.Response<Array<CruiseInclusionItem>>> => {
  return cruisesAPI.http.delete({
    resource: `${RESOURCE}/${inclusionId}/items/${id}`,
  });
};

// Inclusion filters
const getFilters = async (inclusionId: string): Promise<API.Response<Array<CruiseInclusionFilter>>> => {
  return cruisesAPI.http.get({
    resource: `${RESOURCE}/${inclusionId}/filters`,
  });
};

const getFilterById = async (inclusionId: string, id: string): Promise<CruiseInclusionFilter> => {
  const response = await cruisesAPI.http.get<CruiseInclusionFilter>({
    resource: `${RESOURCE}/${inclusionId}/filters/${id}`,
  });

  return response.result;
};

const createFilter = async (
  inclusionId: string,
  formData: Partial<CruiseInclusionFilter>,
): Promise<CruiseInclusionFilter> => {
  return await cruisesAPI.http.post({
    resource: `${RESOURCE}/${inclusionId}/filters`,
    body: formData,
  });
};

const updateFilter = async (
  inclusionId: string,
  id: string,
  formData: Partial<CruiseInclusionFilter>,
): Promise<CruiseInclusionFilter> => {
  const response = await cruisesAPI.http.put<CruiseInclusionFilter>({
    resource: `${RESOURCE}/${inclusionId}/filters/${id}`,
    body: formData,
  });

  return response.result;
};

const deleteFilter = async (inclusionId: string, id: string): Promise<API.Response<Array<CruiseInclusionFilter>>> => {
  return cruisesAPI.http.delete({
    resource: `${RESOURCE}/${inclusionId}/filters/${id}`,
  });
};

export default {
  listWithPagination,
  create,
  update,
  getById,
  deleteById,
  // Inclusion items
  getItems,
  getItemById,
  createItem,
  updateItem,
  deleteItem,
  // Inclusion filters
  getFilters,
  getFilterById,
  createFilter,
  updateFilter,
  deleteFilter,
};
