import React from 'react';

import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Stack, Typography } from '@mui/material';

import { formatDateLongMonthWithMeridiem } from '~/services/TimeService';

import { Code } from '../Common/Code';

class CreditReservationDetailPage extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { creditReservation } = this.props;
    const departingJourney = creditReservation?.journey?.departing;
    const returningJourney = creditReservation?.journey?.returning;

    const dataFields = [
      {
        label: 'Total',
        value: `${creditReservation.total} ${creditReservation.currency}`,
      },
      {
        label: 'Total with fees',
        value: `${creditReservation.total_with_fees} ${creditReservation.currency}`,
      },
      { label: 'State', value: creditReservation.state },
      { label: 'Origin', value: creditReservation.origin },
      { label: 'Destination', value: creditReservation.destination },
      { label: 'Provider', value: creditReservation.provider },
      { label: 'Country Code', value: creditReservation.country_code },
      { label: 'Region', value: creditReservation.region },
      { label: 'Contact Phone', value: creditReservation.contact_phone_number },
      { label: 'Email', value: creditReservation.contact_email_address },
      { label: 'Previous PNR ID', value: creditReservation.previous_pnr_id },
      { label: 'Fare Type', value: creditReservation.fare_type },
      { label: 'New PNR IDs', value: creditReservation.new_pnr_ids || 'N/A' },
      { label: 'Adults', value: creditReservation.passengers?.adults || 0 },
      { label: 'Children', value: creditReservation.passengers?.children || 0 },
      { label: 'Infants', value: creditReservation.passengers?.infants || 0 },
      {
        label: 'Available Credits',
        value: creditReservation.available_credits || 'N/A',
      },
      { label: 'Used Credits', value: creditReservation.used_credits || 'N/A' },
      {
        label: 'Created',
        value: formatDateLongMonthWithMeridiem(creditReservation.created_at),
      },
      {
        label: 'Updated',
        value: formatDateLongMonthWithMeridiem(creditReservation.updated_at),
      },
    ];

    const beautifyJsonObject = (json) => {
      let str = '';
      if (!json) {
        return str;
      }

      const entries = Object.entries(json);

      entries.map(([key, value], index) => {
        str += `${key}: ${value}`;
        if (index < entries.length - 1) {
          str += ', ';
        }
      });

      return str;
    };

    const JourneyDetails = (journey, label) => {
      const flights = journey?.flights.map((flight) => {
        return {
          departingAirport: `${flight?.departingAirport} (${flight?.departingCountry})`,
          departingTime: `${flight?.departingDate} ${flight?.departingTime}`,
          arrivalAirport: `${flight?.arrivalAirport} (${flight?.arrivalCountry})`,
          arrivalTime: `${flight?.arrivalDate} ${flight?.arrivalTime}`,
          layoverTime: flight.layoverTime && beautifyJsonObject(flight.layoverTime),
        };
      });

      return (
        <Stack sx={{ overflow: 'hidden', borderRadius: 2, border: '1px solid grey', mb: 2 }}>
          <Box
            sx={{
              minHeight: 60,
              p: 2,
              backgroundColor: 'secondary.main',
              color: 'white',
            }}
          >
            <Typography variant="h5" sx={{ textTransform: 'uppercase' }}>
              {label}
            </Typography>
          </Box>
          <Box sx={{ p: 2 }}>
            <div className="row">
              <dl className="col-sm-12">
                <dt className="h6 text-uppercase text-muted">Journey Key</dt>
                <dd>{journey?.journeyKey}</dd>
              </dl>
              <dl className="col-sm-12">
                <dt className="h6 text-uppercase text-muted">Flight Time</dt>
                <dd>{beautifyJsonObject(journey?.flightTime)}</dd>
              </dl>
            </div>
            {flights.map((flight, idx) => (
              <div className="row" key={idx}>
                <dl className="col-sm-12">
                  <Typography variant="body1" sx={{ fontWeight: 700 }}>
                    Flight #{idx + 1}
                  </Typography>
                </dl>
                <dl className="col-sm-6">
                  <dt className="h6 text-uppercase text-muted">Departing</dt>
                  <dd>
                    {flight.departingAirport} at {flight.departingTime}
                  </dd>
                </dl>
                <dl className="col-sm-6">
                  <dt className="h6 text-uppercase text-muted">Arrival</dt>
                  <dd>
                    {flight.arrivalAirport} at {flight.arrivalTime}
                  </dd>
                </dl>
                <dl className="col-sm-6">
                  <dt className="h6 text-uppercase text-muted">Layover</dt>
                  <dd>{flight.layoverTime}</dd>
                </dl>
              </div>
            ))}
            <div className="row">
              <dl className="col-sm-12">
                <Typography variant="body1" sx={{ fontWeight: 700 }}>
                  Price Breakdown
                </Typography>
              </dl>
              <dl className="col-sm-6">
                <dt className="h6 text-uppercase text-muted">Adults</dt>
                <dd>{beautifyJsonObject(journey?.priceBreakdown?.adults)}</dd>
              </dl>
              <dl className="col-sm-6">
                <dt className="h6 text-uppercase text-muted">Children</dt>
                <dd>{beautifyJsonObject(journey?.priceBreakdown?.children)}</dd>
              </dl>
              <dl className="col-sm-6">
                <dt className="h6 text-uppercase text-muted">Infants</dt>
                <dd>{beautifyJsonObject(journey?.priceBreakdown?.infants)}</dd>
              </dl>
            </div>
          </Box>
        </Stack>
      );
    };

    return (
      <div className="container order-detail">
        <Helmet>
          <title>{`Flight Credit Reservation for ${creditReservation.previous_pnr_id}`}</title>
        </Helmet>
        <Box sx={{ mb: 2, p: 1 }} id="timeline" className="branded-panel">
          <Stack sx={{ mb: 1 }} direction="row" justifyContent="space-between" alignItems="center">
            <Button
              component={Link}
              startIcon={<ChevronLeftIcon />}
              variant="contained"
              to={`/flights/credit-reservation`}
            >
              Credit Reservations
            </Button>

            <Button
              component={Link}
              variant="contained"
              startIcon={<EditIcon />}
              to={`/flights/credit-reservation/${creditReservation.id}/edit`}
            >
              Edit Request
            </Button>
          </Stack>

          <h1 className="page-header">{`Flight Credit Reservation for ${creditReservation.previous_pnr_id}`}</h1>

          <div className="row">
            {dataFields.map((data, idx) => (
              <dl className="col-sm-3" key={idx}>
                <dt className="h6 text-uppercase text-muted">{data.label}</dt>
                <dd>{data.value}</dd>
              </dl>
            ))}
          </div>
          <div className="row">
            <dl className="col-sm-12">
              <dt className="h6 text-uppercase text-muted">Comments</dt>
              <dd>{creditReservation.comments || 'N/A'}</dd>
            </dl>
          </div>
        </Box>
        {departingJourney?.journeyKey && JourneyDetails(departingJourney, 'Departing')}
        {returningJourney?.journeyKey && JourneyDetails(returningJourney, 'Returning')}
        <Box sx={{ mb: 2 }}>
          <Box
            sx={{
              borderRadius: '8px 8px 0 0',
              minHeight: 60,
              p: 2,
              backgroundColor: 'secondary.main',
              color: 'white',
            }}
          >
            <Typography variant="h5" sx={{ textTransform: 'uppercase' }}>
              Journey JSON
            </Typography>
          </Box>
          <Box sx={{ p: 2 }}>
            <Code lang="json" value={creditReservation.journey} />
          </Box>
        </Box>
        <Box sx={{ mb: 2 }}>
          <Box
            sx={{
              borderRadius: '8px 8px 0 0',
              minHeight: 60,
              p: 2,
              backgroundColor: 'secondary.main',
              color: 'white',
            }}
          >
            <Typography variant="h5" sx={{ textTransform: 'uppercase' }}>
              Previous Reservation
            </Typography>
          </Box>
          <Box sx={{ p: 2 }}>
            <Code lang="json" value={creditReservation.previous_reservation} />
          </Box>
        </Box>
      </div>
    );
  }
}

export default CreditReservationDetailPage;
