import React, { useMemo } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { capitalize } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';

import CopyableField from '~/components/Common/CopyableField';

import { formatDateLongMonthWithMeridiemShort } from '~/services/TimeService';

import OrderItem from './OrderItem';

const getItemStatusColor = (status: string) => {
  let itemStatusColour = 'white';
  switch (status) {
    case 'completed':
    case 'refunded':
      itemStatusColour = 'rgba(59, 171, 83, 0.04)';
      break;
    case 'cancelled':
    case 'abandoned':
    case 'payment_failed':
      itemStatusColour = 'rgba(239, 78, 107, 0.04)';
      break;
    case 'pending':
    case 'created':
    case 'awaiting_payment':
    case 'awaiting_purchase':
    case 'needs_attention':
      itemStatusColour = 'rgba(237, 108, 2, 0.04)';
      break;
  }
  return itemStatusColour;
};

const getItemStatusBorder = (status: string) => {
  let itemStatusColour = 'black';
  switch (status) {
    case 'completed':
    case 'refunded':
      itemStatusColour = 'rgba(59, 171, 83, 0.3)';
      break;
    case 'cancelled':
    case 'abandoned':
    case 'payment_failed':
      itemStatusColour = 'rgba(239, 78, 107, 0.5)';
      break;
    case 'pending':
    case 'created':
    case 'awaiting_payment':
    case 'awaiting_purchase':
    case 'needs_attention':
      itemStatusColour = 'rgba(228, 159, 78, 0.3)';
      break;
  }
  return itemStatusColour;
};

const getOrderStatusColor = (status: string) => {
  let orderStatusColor = 'black';
  switch (status) {
    case 'completed':
    case 'refunded':
      orderStatusColor = 'green';
      break;
    case 'cancelled':
    case 'abandoned':
    case 'payment_failed':
      orderStatusColor = 'red';
      break;
    case 'pending':
    case 'awaiting_payment':
    case 'awaiting_purchase':
    case 'needs_attention':
      orderStatusColor = 'orange';
      break;
  }
  return orderStatusColor;
};

function OrderItemList({
  order,
  offer,
  items,
  allItems,
  customer,
  travellers,
  getTravellers,
  onShowRefundModal,
  hasAllowedRefund,
  refreshData,
  setIsBookingDates,
  setIsChangingDates,
  setIsConvertingToBNBL,
  setIsPuttingOnHold,
  isBookingDates,
  isChangingDates,
  isConvertingToBNBL,
  history,
  showWarningModal,
}) {
  const sortedItems = useMemo(
    () => [...items].sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()),
    [items],
  );

  const itemMap = allItems.reduce((acc, item) => {
    acc[item.id_items] = item;
    return acc;
  }, {});

  return (
    <div className="T-Order-Item-List">
      {sortedItems.map((item, index) => {
        const count = index + 1;
        const disableInteraction =
          isBookingDates ||
          isChangingDates ||
          isConvertingToBNBL ||
          (order.gift_status && order.gift_status !== 'redeemed');

        let traveller;
        if (travellers) {
          traveller = travellers.find((i) => i.booking_number === item.booking_number) || {};
        }

        const offerPackage = offer.packages.find(
          (p) =>
            p.id_salesforce_external === item.offer_package.id_salesforce_external ||
            p.le_package_id === item.offer_package.le_package_id,
        );

        return (
          <Accordion key={item.id_items} defaultExpanded={item.status !== 'cancelled'}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                backgroundColor: getItemStatusColor(item.status),
                border: `1px solid ${getItemStatusBorder(item.status)}`,
                padding: 2,
              }}
            >
              <Grid>
                <Box mb={1}>
                  <Typography component="span" fontWeight="bold" fontSize="16px">
                    {count}. {item.offer_package.name}
                  </Typography>
                </Box>
                <Grid direction="row" spacing={2} container alignItems="center">
                  <Grid md={3}>
                    <Box>
                      <Typography component="span">Status: </Typography>
                      <Typography component="span" fontWeight="bold" color={getOrderStatusColor(item.status)}>
                        {capitalize(item.status)}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid md={3}>
                    <Box>
                      <Typography component="span">Booking number: </Typography>
                      <CopyableField value={item.booking_number} component="span" fontWeight="bold" />
                    </Box>
                  </Grid>
                  {item.fk_downgraded_from_id && (
                    <Grid md={3}>
                      <Box>
                        <Typography component="span">Upgraded from: </Typography>
                        <CopyableField
                          value={itemMap[item.fk_downgraded_from_id].booking_number}
                          component="span"
                          fontWeight="bold"
                        />
                      </Box>
                    </Grid>
                  )}
                  <Grid md={3}>
                    <Box>
                      <Typography component="span">Last updated at: </Typography>
                      <Typography component="span" fontWeight="bold">
                        {formatDateLongMonthWithMeridiemShort(item.updated_at)}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <OrderItem
                order={order}
                offer={offer}
                offerPackage={offerPackage}
                key={item.id_items}
                item={{ ...item, offer: offer }}
                currencyCode={order.currency_code}
                count={count}
                showRefundModal={onShowRefundModal}
                hasAllowedRefund={hasAllowedRefund}
                customer={customer}
                traveller={traveller}
                getTravellers={getTravellers}
                refreshData={refreshData}
                setIsBookingDates={setIsBookingDates}
                setIsChangingDates={setIsChangingDates}
                setIsConvertingToBNBL={setIsConvertingToBNBL}
                setIsPuttingOnHold={setIsPuttingOnHold}
                disableInteraction={disableInteraction}
                history={history}
                checkInStatus={item.checkInStatus}
                showWarningModal={showWarningModal}
              />
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
}

export default OrderItemList;
