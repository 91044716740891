import React from 'react';

import { Link } from '@mui/material';

import { formatDateISOUTC } from '~/services/TimeService';

interface Props {
  tourId: string;
  startDate: string;
  tourName: string;
}

export function TourPricingLink({ tourId, startDate, tourName }: Props) {
  return (
    <Link
      href={`/tours/details/tour-${tourId}/tour-dynamic-pricing?departure-date=${formatDateISOUTC(startDate)}`}
      target="_blank"
    >
      {tourName}
    </Link>
  );
}
