import React, { Component } from 'react';

import cn from 'clsx';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';
import { Editor } from 'react-draft-wysiwyg';
import styled from 'styled-components';

import { FormHelperText } from '@mui/material';

const urlReg = new RegExp(
  '([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?',
);

const localization = {
  locale: 'en',
  translations: {
    'components.controls.blocktype.h3': (
      <span title="Large Header">
        <img src="/assets/icons/large-header.svg" width="13" alt="Large Header" />
      </span>
    ),
    'components.controls.blocktype.h4': (
      <span title="Small Header">
        <img src="/assets/icons/small-header.svg" width="13" alt="Small Header" />
      </span>
    ),
  },
};

const toolbar = {
  options: ['inline', 'blockType', 'list', 'link', 'history'],
  inline: {
    options: ['bold'],
  },
  blockType: {
    inDropdown: false,
    options: ['H3', 'H4'],
  },
  list: {
    options: ['ordered', 'unordered'],
  },
  history: {
    options: ['undo', 'redo'],
    title: 'History',
  },
};

const EDITOR_STYLE = {
  padding: '0 12px',
};

// There's nothing we can do about the markdown editor component
// re-rendering when it recieves many different props
// But we can prevent the editor itself from re-rendering
// unnecessarily by memo'ing it specifically with the props only it cares about
const EditorWrapper = React.memo((props) => {
  const { onBlur, readOnly, wrapperId, onChange, defaultEditorState } = props;

  return (
    <Editor
      onEditorStateChange={onChange}
      onBlur={onBlur}
      readOnly={readOnly}
      defaultEditorState={defaultEditorState}
      toolbar={toolbar}
      toolbarHidden={readOnly}
      wrapperId={wrapperId}
      localization={localization}
      editorStyle={EDITOR_STYLE}
    />
  );
});
EditorWrapper.displayName = 'EditorWrapper';

const EditorContainer = styled.div`
  border: 1px solid #ccc;
  background-color: #fff;

  &.error {
    border-color: red;
  }

  &.is-readonly {
    background-color: #eeeeee;
  }
`;

class MarkdownEditor extends Component {
  constructor(props) {
    super(props);
    const defaultValue = props.value || '';
    let contentState, newEditorState;
    if (defaultValue !== '') {
      contentState = markdownToDraft(defaultValue);
      newEditorState = EditorState.createWithContent(convertFromRaw(contentState));
    } else {
      newEditorState = EditorState.createEmpty();
    }

    this.state = {
      editorState: newEditorState,
      hideLabel: this.props?.formContext?.hideLabel || false,
    };
  }

  onEditorStateChange = (editorState) => {
    // this is due to a bug in react-draft-wysiwyg.
    // onBlur is not fire when only hyperlink is added
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    let markdown = draftToMarkdown(rawContentState);
    const defaultVal = this.props.value || '';

    if (this.isHyperlinkAdded(defaultVal, markdown)) {
      if (this.props.onBlur) this.props.onBlur(markdown);
    }

    if (this.props.onChange) {
      // this handles a current bug in stateToMarkdown
      // where an empty editor is saved with an empty 2 char return value
      // side effect user will not be able to save field with only one character
      // https://github.com/sstur/draft-js-utils/issues/131
      if (markdown.length === 2) {
        markdown = '';
      }
      this.props.onChange(markdown);
    }
  };

  onBlur = (e, editorState) => {
    if (this.props.onBlur) {
      const rawContentState = convertToRaw(editorState.getCurrentContent());
      const markdown = draftToMarkdown(rawContentState);
      this.props.onBlur(markdown);
    }
  };

  // compare two strings check if new hyperlink is added.
  isHyperlinkAdded = (prevVal, curVal) => {
    // if length stays the same suppose no hyperlink is added
    if (prevVal.length == curVal.length) {
      return false;
    }
    const curAry = curVal.split(' ');
    const regCurAr = curAry.filter((word) => urlReg.test(word));

    // if no hyperlink is found then no need to check
    if (regCurAr.length >= 1) {
      const preAry = prevVal.split(' ');
      const regPrAr = preAry.filter((word) => urlReg.test(word));
      return regPrAr.length < regCurAr.length;
    }
    return false;
  };

  render() {
    const { options, helperText: helper } = this.props;
    const helperText = helper ?? (options && options.helperText ? options.helperText : '');
    return (
      <div>
        {!!this.props.title && <label>{this.props.title}</label>}
        {!this.props.hideLabel && !this.state.hideLabel && !!this.props.label && <label>{this.props.label}</label>}
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
        <EditorContainer
          className={cn({
            'is-readonly': this.props.readonly || this.props.disabled,
            error: this.props.error,
          })}
        >
          <EditorWrapper
            onBlur={this.onBlur}
            onChange={this.onEditorStateChange}
            defaultEditorState={this.state.editorState}
            readOnly={this.props.readonly || this.props.disabled}
            wrapperId={this.props.wrapperId}
          />
        </EditorContainer>
      </div>
    );
  }
}

export default MarkdownEditor;
