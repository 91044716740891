import React from 'react';

import { Chip, Tooltip } from '@mui/material';

type ChipStatus = 'default' | 'error' | 'warning' | 'success' | 'info';

interface StateChipProps {
  label: string;
  status: ChipStatus;
  tooltip?: string;
}

export function StateChip({ label, status, tooltip }: StateChipProps) {
  const chip = <Chip size="small" label={label} color={status === 'default' ? undefined : status} />;
  return tooltip ? <Tooltip title={tooltip}>{chip}</Tooltip> : chip;
}
