import React, { useState } from 'react';

import { useSnackbar } from 'notistack';
import fileDownload from 'react-file-download';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Button } from '@mui/material';

import { getTaxInvoiceDetails } from '~/services/OrdersService';
import { getTaxInvoicePDF } from '~/services/PDFService';
import { searchSentEmails } from '~/services/SailthruService';
import { addDuration, isBetween } from '~/services/TimeService';

interface OrderDetailsHeaderActionsProps {
  order: App.Order;
}

export default function OrderDetailsHeaderActions({ order }: OrderDetailsHeaderActionsProps) {
  const [invoiceDownloading, setInvoiceDownloading] = useState(false);
  const [downloadFailed, setDownloadFailed] = useState(false);
  const [fetchingConfirmationEmail, setFetchingConfirmationEmail] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const fetchConfirmationEmail = async () => {
    setFetchingConfirmationEmail(true);

    const orderCreatedAt = order.created_at;
    const customerEmail = order.customer_email;

    try {
      const sentEmails = await searchSentEmails({ limit: 1000, offset: 0, email: customerEmail });
      const confirmationEmailForOrder = sentEmails.result.find(
        (email) =>
          email.notification_type.toLowerCase().includes('confirmation') &&
          isBetween(
            orderCreatedAt,
            addDuration({ days: 0, hours: 0, minutes: 30 }, orderCreatedAt),
            email.delivered_at,
          ),
      );

      if (!confirmationEmailForOrder) {
        enqueueSnackbar(`Unable to find sent email within 30 minutes of order creation`);
        return;
      }

      window.open(`/email/render?id=${confirmationEmailForOrder.id}`);
    } catch (err) {
      setFetchingConfirmationEmail(false);
    } finally {
      setFetchingConfirmationEmail(false);
    }
  };

  const downloadTax = async () => {
    setInvoiceDownloading(true);
    setDownloadFailed(false);

    try {
      const invoiceList = await getTaxInvoiceDetails(order.id_orders);
      const pdf = await getTaxInvoicePDF(invoiceList);
      const fileName = `LuxuryEscapes-Tax-Invoice-${order.id}.pdf`;
      await fileDownload(pdf, fileName);
    } catch (error) {
      console.warn(error);
      setDownloadFailed(true);
    } finally {
      setInvoiceDownloading(false);
    }
  };

  const showDownloadInvoice =
    order &&
    (!order.offline_flight_items || order.offline_flight_items.length === 0) &&
    (order.payments[0]?.instalmentDetails
      ? ['payment_due_balance_manual_debit_taken', 'payment_due_balance_auto_debit_taken'].includes(
          order.payments[0]?.instalmentDetails?.instalment_status,
        )
      : true) &&
    order.status !== 'cancelled' &&
    order.status !== 'abandoned';

  if (!order) {
    return null;
  }
  const flightReservationId = order.flight_items[0]?.fk_reservation_id;

  return (
    <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: '16px', py: { xs: 1, md: 0 } }}>
      {flightReservationId && (
        <Button
          href={`/flights/xml-logs-viewer/${flightReservationId}`}
          variant="outlined"
          target="_blank"
          rel="noreferrer"
        >
          View flight logs
        </Button>
      )}
      {showDownloadInvoice && (
        <Button variant="outlined" color="primary" disabled={invoiceDownloading} onClick={downloadTax}>
          {!invoiceDownloading && !downloadFailed && 'Download Invoice'}
          {invoiceDownloading && 'Downloading...'}
          {!invoiceDownloading && downloadFailed && 'Download Failed'}
        </Button>
      )}
      <Button variant="outlined" color="primary" disabled={fetchingConfirmationEmail} onClick={fetchConfirmationEmail}>
        Confirmation Email
      </Button>
      <Button variant="outlined" color="primary" component={RouterLink} to={`/edit-purchases/${order.id_orders}`}>
        Edit
      </Button>
    </Box>
  );
}
