import React, { useState } from 'react';

import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

import { LoadingButton } from '@mui/lab';
import { Alert, Button, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';

import UsersService from '~/services/UsersService';

import { Step } from './VerifyCustomerButtons';

interface Props {
  onClose: () => void;
  setCurrentStep: (step: Step) => void;
  phonePrefix: string;
  phone: string;
}

const STEP_ENTER_CODE = 'ENTER_CODE';

export default function SendSmsModal({ onClose, setCurrentStep, phonePrefix, phone }: Props) {
  const brand = useSelector((state: App.State) => state.tenant.brand);
  const { enqueueSnackbar } = useSnackbar();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const fullPhoneNumber = phonePrefix + phone;

  const handleSendSms = async () => {
    setIsProcessing(true);

    try {
      await UsersService.sendOtpSmsCode(phonePrefix, phone, brand);

      enqueueSnackbar('Code sent to customer', { variant: 'success' });

      setCurrentStep(STEP_ENTER_CODE);
    } catch (error) {
      setErrorMessage(error.message ?? 'Unknown Error');
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <>
      <DialogTitle>Send Verification SMS</DialogTitle>
      {errorMessage && (
        <Alert severity="error" sx={{ mx: 3, my: 1 }}>
          {errorMessage}
        </Alert>
      )}
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          This will send an SMS code to the customer to verify their identity at{' '}
          <Typography component="span" variant="subtitle1" fontWeight="bold">
            {fullPhoneNumber}
          </Typography>
          .
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button onClick={onClose} disabled={isProcessing}>
          Cancel
        </Button>
        <LoadingButton onClick={handleSendSms} variant="outlined" loading={isProcessing}>
          Send SMS
        </LoadingButton>
      </DialogActions>
    </>
  );
}
