import React from 'react';

import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import { InternalService } from '~/services/AccommodationService';

const ACCOMODATION_INTERNAL_SERVICE_FILTERS: Array<{ value: InternalService; label: string }> = [
  { value: 'reservation', label: 'Reservation' },
  { value: 'bedbank', label: 'Bedbank' },
];

interface Props {
  value: InternalService | '';
  onChange: (newValue?: InternalService | '') => void;
}

export default function AccommodationInternalServiceFilterInput({ value, onChange }: Props) {
  return (
    <FormControl fullWidth>
      <InputLabel>Source</InputLabel>
      <Select<InternalService | ''>
        value={value}
        label="Status"
        onChange={(e) => onChange(e.target.value as InternalService | '')}
      >
        {ACCOMODATION_INTERNAL_SERVICE_FILTERS.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
