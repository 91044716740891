import React, { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import qs from 'qs';
import fileDownload from 'react-file-download';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';

import { Box, Button } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import GridPagination from '~/components/Common/Elements/GridPagination';
import PageSubheader from '~/components/Common/Elements/PageSubheader';

import useQuery from '~/hooks/useQuery';

import {
  downloadGHAPricingReport,
  downloadRecentPricingReport,
  getGHAPricingOverview,
  ghaPricingOverview,
} from '~/services/PublicOfferFeedService';

const sharedOpts: Partial<GridColDef> = {
  editable: false,
  sortable: false,
  filterable: false,
  hideable: false,
  disableColumnMenu: true,
  flex: 1,
};

const getColumns = ({
  enqueueSnackbar,
}: {
  enqueueSnackbar: (message: string, options: { variant: 'success' | 'error' }) => void;
}): Array<GridColDef> => [
  {
    field: 'propertyId',
    headerName: 'Property ID',
    ...sharedOpts,
  },
  {
    field: 'propertyName',
    headerName: 'Property Name',
    ...sharedOpts,
  },
  {
    field: 'searchCount',
    headerName: 'Search Count',
    ...sharedOpts,
  },
  {
    field: 'operation',
    headerName: 'Operation',
    renderCell: (params) => {
      const handleDownload = async () => {
        try {
          const csv = await downloadGHAPricingReport(params.row.propertyId);
          const fileName = `${params.row.propertyName}.csv`;
          await fileDownload(csv, fileName);
          enqueueSnackbar('Download succeed', { variant: 'success' });
        } catch (error) {
          console.error('Download failed:', error);
          enqueueSnackbar('Download failed', { variant: 'error' });
        }
      };

      return (
        <Button variant="contained" color="secondary" size="small" onClick={handleDownload}>
          Download
        </Button>
      );
    },
    ...sharedOpts,
  },
];

function GHAPriceCompetitivenessContainer() {
  const query = useQuery();
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState<ghaPricingOverview>([]);
  const [fetchingState, setFetchingState] = useState<Utils.FetchingState>('idle');
  const [pageNum, setPageNum] = useState<number>(query.get('page_num') ? Number(query.get('page_num')) : 0);
  const [pageSize, setPageSize] = useState<number>(query.get('page_size') ? Number(query.get('page_size')) : 10);
  const [totalCount, setTotalCount] = useState(0);
  const history = useHistory();

  const setQueryParams = useCallback(() => {
    const searchParams = qs.stringify({
      page_num: pageNum,
      page_size: pageSize,
    });
    history.push({
      search: `?${searchParams}`,
    });
  }, [history, pageNum, pageSize]);

  const fetchData = useCallback(async () => {
    setFetchingState('loading');
    try {
      const result = await getGHAPricingOverview({
        page: pageNum,
        limit: pageSize,
      });

      setData(result?.result || []);
      setFetchingState('success');
      setTotalCount(result?.count || 0);
      setQueryParams();
    } catch (error) {
      setFetchingState('failed');
      enqueueSnackbar('GHA pricing record fetch failed', { variant: 'error' });
      return;
    }
  }, [pageNum, pageSize, setQueryParams, enqueueSnackbar]);

  useEffect(() => {
    fetchData();
  }, [fetchData, pageSize, pageNum]);

  const onPageChange = useCallback(
    (pageNum: number, pageSize: number) => {
      setPageNum(pageNum);
      setPageSize(pageSize);
      query.set('page_num', pageNum.toString());
      query.set('page_size', pageSize.toString());
      const searchParams = qs.stringify({
        page_num: pageNum,
        page_size: pageSize,
      });

      history.push({
        search: `?${searchParams}`,
      });
      fetchData();
    },
    [fetchData, history, query],
  );

  const downloadLatestReport = async () => {
    try {
      const csv = await downloadRecentPricingReport();
      const todayDate = new Date().toLocaleDateString().split('/').join('-');
      const fileName = `priceComparisonReport-${todayDate}.csv`;
      await fileDownload(csv, fileName);
      enqueueSnackbar('Download succeed', { variant: 'success' });
    } catch (error) {
      console.error('Download failed:', error);
      enqueueSnackbar('Download failed', { variant: 'error' });
    }
  };

  return (
    <>
      <Helmet>
        <title>GHA Pricing Data</title>
      </Helmet>

      <PageSubheader title={`GHA Price Competitiveness Reports`} />

      <Box mt={2}>
        <Box mt={2}>
          <Button variant="contained" color="secondary" size="small" onClick={downloadLatestReport}>
            Download Latest Report
          </Button>
        </Box>
        <DataGrid
          slots={{ pagination: GridPagination }}
          onPaginationModelChange={({ page, pageSize }) => onPageChange(page, pageSize)}
          loading={fetchingState === 'loading'}
          rows={data}
          columns={getColumns({ enqueueSnackbar })}
          autoHeight
          rowCount={totalCount}
          paginationMode="server"
          pageSizeOptions={[10, 20, 50]}
          paginationModel={{ page: pageNum, pageSize: pageSize }}
          getRowId={(row) => row.propertyId}
        />
      </Box>
    </>
  );
}

export default GHAPriceCompetitivenessContainer;
