import { useCallback } from 'react';

import { type SetCurrentUserPayload, setCurrentUser } from '~/actions/currentUser';

import { UserType } from '~/types/responses';

import { useAppDispatch, useAppSelector } from './store';

export default function useCurrentUser(): {
  isLoading: boolean;
  isAuthenticated: boolean;
  user: UserType | null;
  setCurrentUserState: (payload: SetCurrentUserPayload) => void;
} {
  const dispatch = useAppDispatch();

  const { isLoading, isAuthenticated, user } = useAppSelector((state) => state.currentUser);

  const setCurrentUserState = useCallback(
    (payload: SetCurrentUserPayload) => {
      dispatch(setCurrentUser(payload));
    },
    [dispatch],
  );

  return { isLoading, isAuthenticated, user, setCurrentUserState };
}
