import React from 'react';

import { Stack, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';

import { Order } from '@luxuryescapes/contract-svc-order';

import { ORDER_STATUS_COMPLETED } from '~/consts/order';

import { calculateCredit, calculateDepositTotal, getSuccessfulItemsTotal } from '~/utils/order';

const orderTotalFormatter = (params: GridRenderCellParams<Order.Order>) => {
  const order = params.row;

  const credit = calculateCredit(order);
  const depositTotal = calculateDepositTotal(order);

  const total = order.status !== ORDER_STATUS_COMPLETED ? order.total : getSuccessfulItemsTotal(order);

  return (
    <Stack direction="column">
      <Typography>{(total + depositTotal + credit).toFixed(2)}</Typography>
    </Stack>
  );
};

export default orderTotalFormatter;
