import React from 'react';

import { Box } from '@mui/material';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function TabPanel({ children, value, index, ...rest }: TabPanelProps) {
  if (value !== index) {
    return null;
  }

  return (
    <Box py={2} role="tabpanel" id={`bedbank-tabpanel-${index}`} aria-labelledby={`bedbank-tab-${index}`} {...rest}>
      {children}
    </Box>
  );
}
