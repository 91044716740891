import React from 'react';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { BedOutlined } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
  capitalize,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import PermissionedComponent from '~/components/Common/PermissionedComponent';
import ReadOnlyField from '~/components/v2/common/ReadOnlyField/ReadOnlyField';

import { ROLE_ADMIN_USER, ROLE_EMPLOYEE_USER, ROLE_EXPERIENCES_COORDINATOR, ROLE_ICS_STORE_TEAM } from '~/consts/roles';

import { formatDateLong, formatDateLongMonthWithMeridiemShort } from '~/services/TimeService';
import UsersService from '~/services/UsersService';
import { customerPortalHost } from '~/services/common';

import { isOfferTypeTour } from '~/utils/offer';

import OfferPackageCreditsForm from '../../Common/Forms/OfferPackageCreditsForm';

import OrderItemList from './OrderItemList';
import ResendEmail from './ResendEmail';

interface Props {
  addOfferToCart: any;
  allowToggleReservationType: any;
  customer: App.User;
  customerEmailToAgentState: any;
  flightsCreditReservationId: string;
  getTravellers: any;
  hasAllowedRefund: boolean;
  history: any;
  isBookingDates: boolean;
  isChangingDates: boolean;
  isConvertingToBNBL: boolean;
  offers: Array<{
    items: Array<App.OrderItem>;
    offer: App.AccommodationOffer;
  }>;
  onEmptyCart: any;
  order: App.Order;
  refreshData: any;
  refunds: any;
  emailToCustomerState: any;
  resendCustomerEmail: any;
  resendCustomerEmailToAgent: any;
  emailToVendorState: any;
  resendVendorEmail: any;
  resendVendorEmailToAgent: any;
  selectOffer: any;
  setIsBookingDates: any;
  setIsChangingDates: any;
  setIsConvertingToBNBL: any;
  setIsPuttingOnHold: any;
  setOrderId: any;
  setReservationType: any;
  showRefundModal: any;
  showWarningModal: any;
  tenant: App.Tenant;
  travellers: any;
  vendorEmailToAgentState: any;
}

interface State {
  spoof_url: string;
  spoof_error: boolean;
  isCruiseCreditsModalVisible: boolean;
  agentId: string;
}

class OrderDetailOffer extends React.Component<Props, State> {
  static contextTypes = {
    user: PropTypes.object,
  };

  constructor(props, context) {
    super(props);
    this.state = {
      spoof_url: '',
      spoof_error: false,
      agentId: context.user.id_member,
      isCruiseCreditsModalVisible: false,
    };
  }

  toggleCruiseCreditsModal = () => {
    this.setState((prevState) => ({
      isCruiseCreditsModalVisible: !prevState.isCruiseCreditsModalVisible,
    }));
  };

  gotoVendorPaymentsPage = (offer) => {
    const { history } = this.props;
    const vendor_payment_url = `/finance/offers/` + offer.id_salesforce_external;

    history.push(vendor_payment_url);
  };

  handleSpoofUser = async () => {
    const order = this.props.order;

    if (this.props.tenant.brand !== order.brand) {
      this.setState({ spoof_error: true });
      return;
    }

    const spoofUrl = await UsersService.spoofUser(
      this.props.customer.id_member,
      this.props.tenant,
      `/account/my-escapes/order/${order.id}/`,
    );
    navigator.clipboard.writeText(spoofUrl);
    this.setState({ spoof_url: spoofUrl, spoof_error: false });

    return spoofUrl;
  };

  flushSpoofingState = async () => {
    this.setState({ spoof_url: '', spoof_error: false });
  };

  customerPortalLink(offer) {
    const { tenant } = this.props;
    const offer_url = customerPortalHost(tenant) + `/offer/${offer.slug}/${offer.id_salesforce_external}?preview=true`;

    return offer_url;
  }

  adminOfferPageLink = (offer: App.Offer) => {
    return `/offers/${offer.id_salesforce_external}`;
  };

  adminBundleOfferPageLink = (bundleOfferId) => {
    return `/offers/${bundleOfferId}`;
  };

  vendorPortalLink = (offer: App.Offer) => {
    return `${window.configs.VENDOR_PORTAL}/${offer.vendor_account_id}/offers`;
  };

  sharepointLink = (offer: App.Offer) => {
    return `https://luxgroupau.sharepoint.com/_layouts/15/sharepoint.aspx?q=${offer.id_salesforce_external}&v=/search/files`;
  };

  salesforceLink = (offer: App.Offer) => {
    return `${window.configs.SALESFORCE_HOST}/${offer.id_salesforce_external}`;
  };

  vendorPaymentsLink = (offer: App.Offer) => {
    return `/finance/offers/${offer.id_salesforce_external}`;
  };

  getDateColor = (date: string) => (new Date(date) > new Date() ? 'grey.800' : 'red');

  showVendorEmailResend = (items) => {
    const dontHaveBNBL = !items.some(
      (item) => item.reservation_type === 'buy_now_book_later' && item.status !== 'cancelled' && !item.reservation,
    );

    const haveReservation = items.some((item) => item.reservation_made);
    return dontHaveBNBL || haveReservation;
  };

  showMerchantAccountManagerName = (offer: App.Offer) => {
    if (offer.merchant_account_manager_email) {
      const name = offer.merchant_account_manager_email.split('@')[0];
      const [firstName, lastName] = name.split('.');
      return `${firstName} ${lastName}`;
    }
    return '';
  };

  formatOfferType = (offerType: string) => {
    switch (offerType) {
      case 'hotel':
        return 'Limited Time Lux Exclusive';
      case 'tactical_ao_hotel':
        return 'Lux Premium Collection';
      case 'last_minute_hotel':
        return 'Last Minute Hotel';
      case 'tour':
        return 'Tour';
      default:
        return offerType;
    }
  };

  render() {
    const {
      order,
      customer,
      offers,
      refreshData,
      setIsBookingDates,
      setIsChangingDates,
      setIsConvertingToBNBL,
      setIsPuttingOnHold,
      isBookingDates,
      isChangingDates,
      isConvertingToBNBL,
      resendCustomerEmail,
      emailToCustomerState,
      resendVendorEmail,
      emailToVendorState,
      resendCustomerEmailToAgent,
      customerEmailToAgentState,
      resendVendorEmailToAgent,
      vendorEmailToAgentState,
      travellers,
      getTravellers,
      history,
      hasAllowedRefund,
      showRefundModal,
      showWarningModal,
    } = this.props;

    /*
      Hide ResendEmail when the gift has been redeemed but no dates have been selected.
      At this stage the order owner has changed to the gift receiver but no confirmation email has been sent.
      We do not want to send the original gift giver confirmation email to the gift receiver.
      We need to wait for the gift receiver to book their dates before resurfacing this button.
    */
    const showResendCustomerBookingConfirmation = !(order?.gift_status === 'redeemed' && order.status === 'pending');

    return offers.map(({ offer, items }) => (
      <Accordion defaultExpanded key={offer.id_salesforce_external} id="accommodations">
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box display="flex" alignItems="center">
            <BedOutlined sx={{ mr: 1 }} />
            <Typography variant="h6">Accommodations ({items.length})</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            key={offer.id_salesforce_external}
            sx={{
              border: '1px solid',
              borderColor: 'grey.100',
              borderRadius: '8px',
              marginBottom: '20px',
            }}
          >
            <Box bgcolor="grey.50" p={2}>
              <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="start" mb={2}>
                <Box>
                  <ButtonGroup variant="outlined" size="small">
                    <Button onClick={this.handleSpoofUser}>My Escapes</Button>

                    <Button href={this.customerPortalLink(offer)} target="_blank">
                      Customer Portal
                    </Button>

                    <PermissionedComponent
                      requiredRoles={[ROLE_ADMIN_USER, ROLE_EMPLOYEE_USER, ROLE_EXPERIENCES_COORDINATOR]}
                    >
                      <Button href={this.salesforceLink(offer)} target="_blank" rel="noreferrer">
                        SalesForce
                      </Button>

                      <Button href={this.adminOfferPageLink(offer)} target="_blank" rel="noreferrer">
                        Admin Offer Page {offer.bundle_offer_id ? '(Single)' : ''}
                      </Button>

                      {!!offer.bundle_offer_id && (
                        <Button
                          href={this.adminBundleOfferPageLink(offer.bundle_offer_id)}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Admin offer page (Bundle)
                        </Button>
                      )}

                      <Button href={this.sharepointLink(offer)} target="_blank" rel="noreferrer">
                        Sharepoint
                      </Button>
                      <Button href={this.vendorPortalLink(offer)} target="_blank">
                        Vendor Portal
                      </Button>
                      <Button href={this.vendorPaymentsLink(offer)} target="_blank" rel="noreferrer">
                        Vendor Payments
                      </Button>
                    </PermissionedComponent>
                  </ButtonGroup>
                </Box>
              </Stack>

              {this.state.spoof_error && (
                <Box my={2}>
                  <Alert severity="warning" onClose={this.flushSpoofingState}>
                    This order was created using <strong> {this.props.order.brand} </strong> brand and can't be spoofed
                    using <strong> {this.props.tenant.brand} </strong> admin portal tenant.
                  </Alert>
                </Box>
              )}

              {this.state.spoof_url && (
                <Box my={2}>
                  <Stack direction="column" spacing={1}>
                    <Alert severity="warning" onClose={this.flushSpoofingState}>
                      Spoofing link for {customer.email} has been copied to the clipboard! (EXTREME CAUTION, PASTE INTO
                      INCOGNITO ONLY)
                    </Alert>

                    <TextField
                      defaultValue={this.state.spoof_url}
                      InputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                    />
                  </Stack>
                </Box>
              )}

              <Stack spacing={1}>
                <Box pl={1}>
                  <ReadOnlyField title="Offer name" value={capitalize(offer.name)} type="text" />
                </Box>
                <Grid container spacing={2} alignItems="flex-end">
                  <Grid md={3}>
                    <Box>
                      <Link
                        target="_blank"
                        to={`/users/${customer.id_member}/new-order/offer-selection?q=${offer.id_salesforce_external}`}
                      >
                        <Typography fontSize="12px" color="primary.main" sx={{ textDecoration: 'underline' }}>
                          Re-book same offer for this user
                        </Typography>
                      </Link>
                    </Box>
                    <ReadOnlyField title="Offer ID" value={offer.id_salesforce_external} type="text" copyable />
                  </Grid>

                  {offer.bundle_offer_id && (
                    <Grid md={3}>
                      <ReadOnlyField title="Bundle Offer ID" value={capitalize(offer.bundle_offer_id)} type="text" />
                    </Grid>
                  )}

                  <Grid md={3}>
                    <ReadOnlyField title="Offer type" value={this.formatOfferType(offer.type)} type="text" />
                  </Grid>
                  {offer.type === 'hotel' && (
                    <Grid md={3}>
                      <ReadOnlyField
                        title="Travel to"
                        value={formatDateLong(offer.travel_to_date)}
                        type="text"
                        colour={this.getDateColor(offer.travel_to_date)}
                      />
                    </Grid>
                  )}
                  {offer.type === 'hotel' && (
                    <Grid md={3}>
                      <ReadOnlyField
                        title="Book by"
                        value={formatDateLong(offer.book_by_date)}
                        type="text"
                        colour={this.getDateColor(offer.book_by_date)}
                      />
                    </Grid>
                  )}
                  {offer.merchant_account_manager_email && (
                    <Grid md={3}>
                      <ReadOnlyField
                        title="Campaign Manager"
                        value={this.showMerchantAccountManagerName(offer)}
                        type="text"
                      />
                    </Grid>
                  )}
                  <Grid md={3}>
                    <ReadOnlyField title="Reservation email" value={offer.vendor_booking_email} copyable type="text" />
                  </Grid>
                  <Grid md={3}>
                    <ReadOnlyField title="Reservation phone" value={offer.vendor_contact_phone} copyable type="text" />
                  </Grid>
                </Grid>
              </Stack>

              {offer.disable_outside_policy_requests && (
                <Box mt={1}>
                  <Alert severity="warning">
                    <AlertTitle>Important property notes:</AlertTitle>
                    This property is blacklisted from cancellation or date change requests outside of policy. Please do
                    not contact the vendor, as such requests will be automatically rejected.
                  </Alert>
                </Box>
              )}

              {offer.customer_experience_notes && (
                <Box mt={1}>
                  <Alert severity="warning">
                    <strong>Important property notes</strong> {offer.customer_experience_notes}
                  </Alert>
                </Box>
              )}

              <PermissionedComponent requiredRoles={[ROLE_ADMIN_USER, ROLE_EMPLOYEE_USER, ROLE_ICS_STORE_TEAM]}>
                <Stack direction="row" spacing={2}>
                  {showResendCustomerBookingConfirmation && (
                    <ResendEmail
                      emailType="customer"
                      resendEmail={resendCustomerEmail}
                      isCustomerEmailDisabled={emailToCustomerState.isSending}
                      resendEmailToAgent={resendCustomerEmailToAgent}
                      isAgentEmailDisabled={customerEmailToAgentState.isSending}
                      offerId={offer.id_salesforce_external}
                    />
                  )}

                  {this.showVendorEmailResend(items) && (
                    <ResendEmail
                      emailType="vendor"
                      resendEmail={resendVendorEmail}
                      isCustomerEmailDisabled={emailToVendorState.isSending}
                      resendEmailToAgent={resendVendorEmailToAgent}
                      isAgentEmailDisabled={vendorEmailToAgentState.isSending}
                      offerId={offer.id_salesforce_external}
                    />
                  )}
                </Stack>
              </PermissionedComponent>

              {isOfferTypeTour(offer) && (
                <Box>
                  <Button variant="outlined" size="small" onClick={this.toggleCruiseCreditsModal}>
                    Add offer credits
                  </Button>

                  <Dialog open={this.state.isCruiseCreditsModalVisible} onClose={this.toggleCruiseCreditsModal}>
                    <DialogTitle>Offer Credits</DialogTitle>
                    <DialogContent>
                      <OfferPackageCreditsForm
                        offerId={offer.id_salesforce_external}
                        agentId={this.state.agentId}
                        currency={order.currency_code}
                        userId={order.fk_customer_id}
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={this.toggleCruiseCreditsModal} color="primary">
                        Close
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Box>
              )}
            </Box>

            <OrderItemList
              order={order}
              offer={offer}
              items={items.filter((item) => item.status !== 'cancelled')}
              allItems={items}
              customer={customer}
              travellers={travellers}
              getTravellers={getTravellers}
              onShowRefundModal={showRefundModal}
              hasAllowedRefund={hasAllowedRefund}
              refreshData={refreshData}
              setIsBookingDates={setIsBookingDates}
              setIsChangingDates={setIsChangingDates}
              setIsConvertingToBNBL={setIsConvertingToBNBL}
              setIsPuttingOnHold={setIsPuttingOnHold}
              isConvertingToBNBL={isConvertingToBNBL}
              isBookingDates={isBookingDates}
              isChangingDates={isChangingDates}
              history={history}
              showWarningModal={showWarningModal}
            />

            {items.some((item) => item.status === 'cancelled') && (
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={{
                    backgroundColor: 'grey.100',
                    border: `1px solid black`,
                    padding: 2,
                  }}
                >
                  <Grid>
                    <Box mb={1}>
                      <Typography component="span" fontWeight="bold" variant="h6">
                        Inactive Items ({items.filter((item) => item.status === 'cancelled').length})
                      </Typography>
                    </Box>
                    <Grid direction="row" spacing={2} container alignItems="center">
                      <Grid md={3}>
                        <Box>
                          <Typography component="span">Last updated at: </Typography>
                          <Typography component="span" fontWeight="bold">
                            {formatDateLongMonthWithMeridiemShort(
                              items
                                .filter((item) => item.status === 'cancelled')
                                .sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime())[0]
                                .updated_at,
                            )}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <OrderItemList
                    order={order}
                    offer={offer}
                    items={items.filter((item) => item.status === 'cancelled')}
                    allItems={items}
                    customer={customer}
                    travellers={travellers}
                    getTravellers={getTravellers}
                    onShowRefundModal={showRefundModal}
                    hasAllowedRefund={hasAllowedRefund}
                    refreshData={refreshData}
                    setIsBookingDates={setIsBookingDates}
                    setIsChangingDates={setIsChangingDates}
                    setIsConvertingToBNBL={setIsConvertingToBNBL}
                    setIsPuttingOnHold={setIsPuttingOnHold}
                    isConvertingToBNBL={isConvertingToBNBL}
                    isBookingDates={isBookingDates}
                    isChangingDates={isChangingDates}
                    history={history}
                    showWarningModal={showWarningModal}
                  />
                </AccordionDetails>
              </Accordion>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>
    ));
  }
}

export default OrderDetailOffer;
