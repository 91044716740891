import React from 'react';

import { Link } from 'react-router-dom';

import SearchIcon from '@mui/icons-material/Search';
import { Button, Stack } from '@mui/material';
import { GridDeleteIcon } from '@mui/x-data-grid';

import ConfirmButton from '~/components/Common/Elements/ConfirmButton';

interface Props {
  row: App.FlightDeal;
  onDelete: (id: string) => void;
}

export function FlightDealsTableActionButtons({ row, onDelete }: Props) {
  return (
    <Stack direction="row" alignItems="center" gap={1} height="100%">
      <Button
        component={Link}
        variant="outlined"
        to={`/flights/flight-deal/${row.id}/edit`}
        size="small"
        style={{ minWidth: '40px' }}
      >
        <SearchIcon />
      </Button>

      <ConfirmButton
        confirmTitle="Delete deal"
        confirmQuestion="Are you sure you want to delete this deal?"
        confirmAnswer="Yes, delete"
        onConfirm={() => onDelete(row.id)}
        color="error"
        variant="outlined"
        size="small"
        style={{ minWidth: '40px' }}
      >
        <GridDeleteIcon />
      </ConfirmButton>
    </Stack>
  );
}
