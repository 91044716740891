import React, { useCallback } from 'react';

import { useSnackbar } from 'notistack';

import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { IconButton, List, ListItem, ListItemIcon, ListItemText, SxProps, Tooltip } from '@mui/material';

interface Item {
  value: string;
  icon?: React.ReactNode;
  tooltipText?: string;
  copyable?: boolean;
}

const LIST_ITEM_ICON_STYLE: SxProps = {
  '&.MuiListItemIcon-root': {
    minWidth: '40px',
  },
};

export default function IconList({ items }: { items: Array<Item> }) {
  const { enqueueSnackbar } = useSnackbar();

  const copyValue = useCallback(
    (value: string) => {
      navigator.clipboard.writeText(value);
      enqueueSnackbar('Copied to clipboard', { variant: 'success', autoHideDuration: 3500 });
    },
    [enqueueSnackbar],
  );

  return (
    <List>
      {items.map((item, index) => (
        <ListItem
          key={`${item.value}-${index}`}
          sx={{
            px: 0,
            py: 0.5,
          }}
          secondaryAction={
            item.copyable && (
              <IconButton edge="end" onClick={() => copyValue(item.value)}>
                <ContentCopyOutlinedIcon />
              </IconButton>
            )
          }
        >
          {item.tooltipText && (
            <Tooltip title={item.tooltipText}>
              <ListItemIcon sx={LIST_ITEM_ICON_STYLE}>{item.icon}</ListItemIcon>
            </Tooltip>
          )}
          {!item.tooltipText && <ListItemIcon sx={LIST_ITEM_ICON_STYLE}>{item.icon}</ListItemIcon>}
          <ListItemText primary={item.value} />
        </ListItem>
      ))}
    </List>
  );
}
