import React, { useCallback } from 'react';

import { Box, Typography } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridFilterModel,
  GridPaginationModel,
  GridRowParams,
  GridSortModel,
} from '@mui/x-data-grid';

interface BaseTableProps<T> {
  columns: Array<GridColDef<T>>;
  rows: Array<T>;

  isLoading?: boolean;
  isDisabled?: boolean;
  autoHeight?: boolean;

  sortModel?: GridSortModel;
  onSortModelChange?: (sortModel: GridSortModel) => void;

  filterModel?: GridFilterModel;
  onFilterModelChange?: (filterModel: GridFilterModel) => void;

  onRowClick?: (params: GridRowParams<T>) => void;
  getRowId: (row: T) => string | number;

  containerSx?: React.CSSProperties;
  tableSx?: React.CSSProperties;

  // Expose this as a prop to allow for further customization where needed
  dataGridProps?: Partial<Parameters<typeof DataGrid>[0]>;
}

/**
 * Props for when server-side pagination is used. This is the type of pagination where an API call
 * is issued to fetch more data when the user navigates to the next page.
 */
interface ServerPaginationProps {
  paginationMode: 'server';
  paginationModel: GridPaginationModel;
  onPaginationModelChange: (paginationModel: GridPaginationModel) => void;
  totalRowCount: number;
}

/**
 * Props for when client-side pagination is used. This is the type of pagination where the table
 * is rendered with all the data at once, and the user navigates through the data using the table's
 * pagination controls. MUI handles the pagination logic automatically.
 */
interface ClientPaginationProps {
  paginationMode: 'client';
  paginationModel?: never;
  onPaginationModelChange?: never;
  totalRowCount?: never;
}

export type TableProps<T> = BaseTableProps<T> & (ClientPaginationProps | ServerPaginationProps);

export default function Table<T>({
  columns,
  rows,
  paginationModel,
  autoHeight,
  paginationMode,
  onPaginationModelChange,
  totalRowCount,
  isDisabled = false,
  onRowClick,
  getRowId,
  sortModel,
  onSortModelChange,
  filterModel,
  onFilterModelChange,
  isLoading,
  containerSx,
  tableSx,
  dataGridProps,
}: TableProps<T>) {
  const mapColumns = useCallback((columns: Array<GridColDef<T>>) => {
    return columns.map((column) => ({
      // By default, all columns should occupy the same amount of space, and extend the full width
      // of the table
      flex: 1,
      renderCell: (params) => {
        return <Typography variant="body2">{params.row[column.field]}</Typography>;
      },
      ...column,
    }));
  }, []);

  const mappedColumns = mapColumns(columns);
  return (
    <Box sx={{ width: '100%', ...containerSx }}>
      <DataGrid
        sx={{ width: '100%', ...tableSx }}
        rows={rows}
        columns={mappedColumns}
        rowCount={totalRowCount}
        paginationMode={paginationMode}
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
        disableRowSelectionOnClick={isDisabled}
        onRowClick={onRowClick}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        filterModel={filterModel}
        onFilterModelChange={onFilterModelChange}
        loading={isLoading}
        density="compact"
        pageSizeOptions={[10, 25, 50, 100]}
        getRowId={getRowId}
        autoHeight={autoHeight}
        {...dataGridProps}
      />
    </Box>
  );
}
