import React from 'react';

import {
  Cancel as CancelIcon,
  CloudDownload as CloudDownloadIcon,
  Replay as ReplayIcon,
  Visibility as VisibilityIcon,
} from '@mui/icons-material';
import { IconButton, Stack, Tooltip } from '@mui/material';

import { PriceSummaryJob, PriceSummaryJobStatus } from '~/types/services/priceParrot';

type ActionHandler = () => Promise<void>;
type Props = {
  job: PriceSummaryJob;
  onCancel: ActionHandler;
  onDownload: ActionHandler;
  onPreview: ActionHandler;
  onResubmit: ActionHandler;
};

const ICON_BUTTON_STYLE = { padding: '4px' };

export default function GridColActions({ job: { status }, onCancel, onDownload, onPreview, onResubmit }: Props) {
  return (
    <Stack direction="row">
      {[
        PriceSummaryJobStatus.PENDING,
        PriceSummaryJobStatus.PROCESSING,
        PriceSummaryJobStatus.RETRYING,
        PriceSummaryJobStatus.INITIALISING,
      ].includes(status) && (
        <Tooltip title="Cancel Job">
          <IconButton onClick={onCancel} sx={ICON_BUTTON_STYLE}>
            <CancelIcon />
          </IconButton>
        </Tooltip>
      )}
      {[PriceSummaryJobStatus.COMPLETED].includes(status) && (
        <>
          <Tooltip title="Download Job">
            <IconButton onClick={onDownload} sx={ICON_BUTTON_STYLE}>
              <CloudDownloadIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Preview Job">
            <IconButton onClick={onPreview} sx={ICON_BUTTON_STYLE}>
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
        </>
      )}
      {[PriceSummaryJobStatus.FAILED, PriceSummaryJobStatus.CANCELLED].includes(status) && (
        <Tooltip title="Resubmit Job">
          <IconButton onClick={onResubmit} sx={ICON_BUTTON_STYLE}>
            <ReplayIcon />
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  );
}
