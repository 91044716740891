import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CurrencyExchangeOutlined from '@mui/icons-material/CurrencyExchangeOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WarningIcon from '@mui/icons-material/Warning';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { Code } from '~/components/Common/Code';
import Spinner from '~/components/Common/Spinner';

import { BRAINTREE_PAYMENT_TYPE, PROMO_PAYMENT_TYPE } from '~/consts/payment';

import { getPromoById } from '~/services/PromoService';
import { formatDateOrdinalWithClock } from '~/services/TimeService';

import currencyFormatter from '~/utils/currencyFormatter';

interface Props {
  payments: Array<any>;
}

const initiallyExpanded = true;

function OrderDetailPayments({ payments }: Props) {
  const [isExpanded, setExpanded] = useState(initiallyExpanded);
  const [loading, setLoading] = useState(false);
  const [promoCodes, setPromoCodes] = useState<Array<any>>([]);
  const [openDebug, setOpenDebug] = useState(false);
  const [debugJson, setDebugJson] = useState<string>('');
  const [showMore, setShowMore] = useState(!initiallyExpanded);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const loadPromoCodes = async () => {
      try {
        setLoading(true);
        const promoPromises = payments
          .filter((payment) => payment.type === PROMO_PAYMENT_TYPE)
          .map((payment) =>
            getPromoById({
              idPromo: payment.response_json.id_promo,
              brand: payment.brand,
            }),
          );

        const promoCodes = await Promise.all(promoPromises);
        setPromoCodes(promoCodes.map((response) => response.result));
        setLoading(false);
      } catch (err) {
        // just ignore errors for now
      }
    };
    loadPromoCodes();
  }, [payments]);

  const toggleMore = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setShowMore((prevState) => !prevState);
  };

  const toggleExpanded = (isExpanded: boolean) => {
    setExpanded(isExpanded);
  };

  const additionalInfo = (row: any) => {
    if (row.stripe_account_id) {
      return `Stripe Account Id: ${row.stripe_account_id}`;
    }

    if (row.type === BRAINTREE_PAYMENT_TYPE) {
      if (row.response_json && row.response_json.transaction && row.response_json.transaction.paypal) {
        return `PayPal Capture Id: ${row.response_json.transaction.paypal.captureId}`;
      }
    }

    if (row.type === PROMO_PAYMENT_TYPE) {
      const promoCode = promoCodes.find((promoCode) => promoCode.id_promo_code === row.response_json.id_promo);
      if (promoCode) {
        return (
          <Link href={`/promos/${promoCode.id_promo_code}`} target="_blank">
            {promoCode.code_name}
          </Link>
        );
      }
    }

    return null;
  };
  const columns: Array<GridColDef> = [
    {
      field: 'created_at',
      headerName: 'Created At',
      sortable: false,
      disableColumnMenu: true,
      flex: 1.5,
      valueFormatter: (value) => formatDateOrdinalWithClock(value),
      display: 'flex',
    },
    {
      field: 'type',
      headerName: 'Type',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      display: 'flex',
    },
    {
      field: 'psp_name',
      headerName: 'PSP Name',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      display: 'flex',
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      display: 'flex',
      renderCell: (params) => {
        let statusIcon = undefined;
        switch (params.value) {
          case 'completed':
            statusIcon = <CheckCircleIcon color="success" />;
            break;
          case 'pending':
            statusIcon = <WarningIcon color="warning" />;
            break;
          case 'failed':
            statusIcon = <CancelIcon color="error" />;
            break;
          default:
            statusIcon = <Typography>{params.value}</Typography>;
            break;
        }
        return <Tooltip title={params.value}>{statusIcon}</Tooltip>;
      },
    },
    {
      field: 'intent',
      headerName: 'Intent',
      sortable: false,
      disableColumnMenu: true,
      flex: 0.5,
      display: 'flex',
    },
    {
      field: 'amount',
      headerName: 'Value',
      sortable: false,
      disableColumnMenu: true,
      flex: 1.2,
      renderCell: (params) => {
        const amount = params.row.amount;
        const formattedAmount = currencyFormatter(params.row.currency, amount, 2);
        let color = 'text.primary';
        if (amount < 0) {
          color = 'error.main';
        } else if (amount > 0) {
          color = 'info.main';
        }
        return (
          <Typography fontWeight="bold" color={color}>
            {formattedAmount}
          </Typography>
        );
      },
      display: 'flex',
    },
    {
      field: 'currency',
      headerName: '$',
      sortable: false,
      disableColumnMenu: true,
      flex: 0.5,
      display: 'flex',
    },
    {
      field: 'ext_transaction_id',
      headerName: 'External Payment ID',
      sortable: false,
      disableColumnMenu: true,
      flex: 1.5,
      display: 'flex',
      renderCell: (params) => {
        return (
          <IconButton
            onClick={() => {
              navigator.clipboard.writeText(params.value);
              enqueueSnackbar('Payment ID copied to clipboard', { variant: 'success' });
            }}
          >
            <ContentCopyIcon />
          </IconButton>
        );
      },
    } as GridColDef,
    {
      field: 'failure_reason',
      headerName: 'Failure Reason',
      sortable: false,
      disableColumnMenu: true,
      flex: 2,
      display: 'flex',
    },

    ...(showMore
      ? [
          {
            field: 'id_payment',
            headerName: 'Payment ID',
            sortable: false,
            disableColumnMenu: true,
            flex: 1,
            display: 'flex',
            renderCell: (params) => {
              return (
                <IconButton
                  onClick={() => {
                    navigator.clipboard.writeText(params.value);
                    enqueueSnackbar('Payment ID copied to clipboard', { variant: 'success' });
                  }}
                >
                  <ContentCopyIcon />
                </IconButton>
              );
            },
          } as GridColDef,
          {
            field: 'response_json',
            headerName: 'Additional Info',
            sortable: false,
            disableColumnMenu: true,
            flex: 2.5,
            renderCell: (params) => additionalInfo(params.row),
            display: 'flex',
          } as GridColDef,
          {
            field: 'button',
            headerName: 'Debug',
            sortable: false,
            disableColumnMenu: true,
            flex: 1,
            display: 'flex',
            renderCell: (params) => {
              return (
                <Button
                  variant="text"
                  size="small"
                  onClick={() => {
                    setOpenDebug(true);
                    setDebugJson(JSON.stringify(params.row.response_json, null, 2));
                  }}
                >
                  Details
                </Button>
              );
            },
          } as GridColDef,
        ]
      : []),
  ];

  return (
    <Box id="transactions">
      <Accordion defaultExpanded={initiallyExpanded} id="flights" expanded={isExpanded}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={() => setExpanded(!isExpanded)}>
          <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
            <Box display="flex" alignItems="center">
              <CurrencyExchangeOutlined sx={{ mr: 1 }} />
              <Typography variant="h6">Transactions History</Typography>
            </Box>
            {isExpanded && (
              <Button variant="text" size="small" onClick={toggleMore}>
                More details
              </Button>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          {loading && <Spinner size={36} />}
          {!loading && (
            <DataGrid
              columns={columns}
              rows={payments}
              getRowId={(row) => row.id_payment}
              autoHeight
              pageSizeOptions={[]}
              disableColumnMenu
              disableRowSelectionOnClick
              hideFooter
              getRowHeight={() => 'auto'}
            />
          )}
        </AccordionDetails>
      </Accordion>

      <Dialog open={openDebug} onClose={() => setOpenDebug(false)} maxWidth="md">
        <DialogTitle>Transaction internal details</DialogTitle>

        <DialogContent>
          <Code value={debugJson} lang="json" />
        </DialogContent>

        <DialogActions>
          <Button variant="text" onClick={() => setOpenDebug(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default OrderDetailPayments;
