import React, { useCallback, useRef, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Box, IconButton, Paper, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

interface Props {
  id: string;
  label: string;
  name: string;
  imageUrl?: string;
  onUpload: (file: File) => void;
  onRemove: () => void;
  width?: string;
  required?: boolean;
  isValid?: boolean;
  mini?: boolean;
}

const UploadBox = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  position: 'relative',
  minHeight: 200,
  border: `2px dashed ${theme.palette.divider}`,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  '&.dragActive': {
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.action.hover,
  },
  '&.required': {
    borderColor: theme.palette.error.main,
  },
  '&.mini': {
    minHeight: 80,
    '& .MuiSvgIcon-root': {
      fontSize: '1.2rem',
    },
    '& .MuiTypography-root': {
      fontSize: '0.75rem',
    },
  },
}));

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

function ImageUploadBox({
  id,
  label,
  name,
  imageUrl,
  onUpload,
  onRemove,
  width,
  required,
  isValid,
  mini = false,
}: Props) {
  const [isDragActive, setIsDragActive] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleDragEnter = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragActive(true);
  }, []);

  const handleDragLeave = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragActive(false);
  }, []);

  const handleDragOver = useCallback((e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDrop = useCallback(
    (e: React.DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragActive(false);

      const file = e.dataTransfer.files[0];
      if (file?.type.startsWith('image/')) {
        onUpload(file);
      }
    },
    [onUpload],
  );

  const handleFileChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      if (file) {
        onUpload(file);
      }
    },
    [onUpload],
  );

  const handleRemove = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      if (inputRef.current) {
        inputRef.current.value = '';
      }
      onRemove();
    },
    [onRemove],
  );

  return (
    <Box sx={{ width: width || '100%', position: 'relative' }}>
      <VisuallyHiddenInput
        ref={inputRef}
        type="file"
        id={id}
        accept="image/*"
        onChange={handleFileChange}
        name={name}
        required={required && !isValid}
      />
      <label htmlFor={id}>
        <UploadBox
          className={`${isDragActive ? 'dragActive' : ''} ${required && !isValid ? 'required' : ''} ${
            mini ? 'mini' : ''
          }`}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          variant="outlined"
        >
          {imageUrl ? (
            <>
              <IconButton
                size="small"
                onClick={handleRemove}
                sx={{ position: 'absolute', top: 0, right: 0, backgroundColor: 'red' }}
              >
                <CloseIcon />
              </IconButton>
              <Box
                component="img"
                src={imageUrl}
                alt={label}
                sx={{
                  width: '100%',
                  height: mini ? '80px' : '100%',
                  objectFit: 'contain',
                }}
              />
            </>
          ) : (
            <Stack spacing={mini ? 0.5 : 1} alignItems="center">
              <CloudUploadIcon color={required && !isValid ? 'error' : 'action'} />
              <Typography variant="body2" color={required && !isValid ? 'error' : 'text.secondary'}>
                {(() => {
                  const isRequired = required && !isValid;
                  const uploadText = `Click or drag to upload ${label}${isRequired ? ' *' : ''}`;

                  if (isDragActive) {
                    return 'Drop here';
                  }
                  if (mini) {
                    return 'Upload';
                  }
                  return uploadText;
                })()}
              </Typography>
            </Stack>
          )}
        </UploadBox>
      </label>
    </Box>
  );
}

export default ImageUploadBox;
