import React from 'react';

import { Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';

import { Order } from '@luxuryescapes/contract-svc-order';

const orderCurrencyFormatter = (params: GridRenderCellParams<Order.Order>) => {
  const order = params.row;
  return <Typography>{order.currency_code}</Typography>;
};

export default orderCurrencyFormatter;
