import React, { useCallback, useState } from 'react';

import { useSnackbar } from 'notistack';

import LoadingButton from '@mui/lab/LoadingButton';
import { Stack, TextField } from '@mui/material';

import useImageUrlGenerator from '~/hooks/useImageUrlGenerator';

import { uploadImage } from '~/services/ImageService';

import ImageUploadBox from './ImageUploadBox';

interface Props {
  webImageId?: string;
  mobileImageId?: string;
  headerUrl: string;
  onWebImageIdChange: (id: string) => void;
  onMobileImageIdChange: (id: string) => void;
  onHeaderUrlChange: (url: string) => void;
  showHeaderUrl?: boolean;
}

enum SCHEDULE_HEADER_IMAGE_FORM_NAMES {
  WEB_IMAGE_ID = 'web_image_id',
  MOBILE_IMAGE_ID = 'mobile_image_id',
  HEADER_URL = 'header_url',
}

export interface HeaderImageFormData {
  webImageId: string;
  mobileImageId: string;
  headerUrl: string;
}

export function parseHeaderImageFormData(formData: FormData): HeaderImageFormData {
  return {
    webImageId: String(formData.get(SCHEDULE_HEADER_IMAGE_FORM_NAMES.WEB_IMAGE_ID)),
    mobileImageId: String(formData.get(SCHEDULE_HEADER_IMAGE_FORM_NAMES.MOBILE_IMAGE_ID)),
    headerUrl: String(formData.get(SCHEDULE_HEADER_IMAGE_FORM_NAMES.HEADER_URL)),
  };
}

function ScheduleHeaderImageInput({
  webImageId = '',
  mobileImageId = '',
  headerUrl,
  onWebImageIdChange,
  onMobileImageIdChange,
  onHeaderUrlChange,
  showHeaderUrl = true,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [uploading, setUploading] = useState(false);
  const [webFile, setWebFile] = useState<File | null>(null);
  const [mobileFile, setMobileFile] = useState<File | null>(null);
  const webImageUrl = useImageUrlGenerator(webImageId);
  const mobileImageUrl = useImageUrlGenerator(mobileImageId);

  const handleWebFileSelect = useCallback(
    (file: File) => {
      onWebImageIdChange('');
      setWebFile(file);
    },
    [onWebImageIdChange],
  );

  const handleMobileFileSelect = useCallback(
    (file: File) => {
      onMobileImageIdChange('');
      setMobileFile(file);
    },
    [onMobileImageIdChange],
  );

  const handleWebRemove = useCallback(() => {
    setWebFile(null);
    onWebImageIdChange('');
  }, [onWebImageIdChange]);

  const handleMobileRemove = useCallback(() => {
    setMobileFile(null);
    onMobileImageIdChange('');
  }, [onMobileImageIdChange]);

  const handleUploadImages = useCallback(async () => {
    setUploading(true);
    try {
      if (webFile) {
        const webResponse = await uploadImage(webFile);
        onWebImageIdChange(webResponse.body.public_id);
        setWebFile(null);
        enqueueSnackbar('Web image uploaded successfully', { variant: 'success' });
      }
      if (mobileFile) {
        const mobileResponse = await uploadImage(mobileFile);
        onMobileImageIdChange(mobileResponse.body.public_id);
        setMobileFile(null);
        enqueueSnackbar('Mobile image uploaded successfully', { variant: 'success' });
      }
    } catch (error) {
      enqueueSnackbar('Failed to upload images', { variant: 'error' });
    } finally {
      setUploading(false);
    }
  }, [webFile, mobileFile, onWebImageIdChange, onMobileImageIdChange, enqueueSnackbar]);

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={2}>
        <Stack width="70%">
          <TextField
            fullWidth
            label="Web Image ID"
            value={webImageId}
            onChange={(e) => onWebImageIdChange(e.target.value)}
            name={SCHEDULE_HEADER_IMAGE_FORM_NAMES.WEB_IMAGE_ID}
            required
            sx={{ mb: 2 }}
          />
          <ImageUploadBox
            id={`${SCHEDULE_HEADER_IMAGE_FORM_NAMES.WEB_IMAGE_ID}-upload-box-${Math.random()}`}
            label="Web Image"
            imageUrl={webImageId ? webImageUrl : webFile ? URL.createObjectURL(webFile) : undefined}
            onUpload={handleWebFileSelect}
            onRemove={handleWebRemove}
            name={SCHEDULE_HEADER_IMAGE_FORM_NAMES.WEB_IMAGE_ID}
            required={!webImageId}
            isValid={!!webImageId}
          />
        </Stack>
        <Stack width="30%">
          <TextField
            fullWidth
            label="Mobile Image ID"
            value={mobileImageId}
            onChange={(e) => onMobileImageIdChange(e.target.value)}
            name={SCHEDULE_HEADER_IMAGE_FORM_NAMES.MOBILE_IMAGE_ID}
            sx={{ mb: 2 }}
          />
          <ImageUploadBox
            id={`${SCHEDULE_HEADER_IMAGE_FORM_NAMES.MOBILE_IMAGE_ID}-upload-box-${Math.random()}`}
            label="Mobile Image"
            imageUrl={mobileImageId ? mobileImageUrl : mobileFile ? URL.createObjectURL(mobileFile) : undefined}
            onUpload={handleMobileFileSelect}
            onRemove={handleMobileRemove}
            name={SCHEDULE_HEADER_IMAGE_FORM_NAMES.MOBILE_IMAGE_ID}
          />
        </Stack>
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={2}>
        <LoadingButton
          variant="contained"
          color="primary"
          loading={uploading}
          disabled={!webFile && !mobileFile}
          onClick={handleUploadImages}
        >
          Upload Images
        </LoadingButton>
      </Stack>
      {showHeaderUrl && (
        <TextField
          fullWidth
          label="Header URL"
          name={SCHEDULE_HEADER_IMAGE_FORM_NAMES.HEADER_URL}
          value={headerUrl}
          onChange={(e) => onHeaderUrlChange(e.target.value)}
          required
        />
      )}
    </Stack>
  );
}

ScheduleHeaderImageInput.displayName = 'ScheduleHeaderImageInput';
export default ScheduleHeaderImageInput;
