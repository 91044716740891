import React from 'react';

import { Box, Typography } from '@mui/material';

import ReadOnlyField, { ReadOnlyFieldProps } from '../../v2/common/ReadOnlyField/ReadOnlyField';

interface Props {
  title: string;
  fields: Array<ReadOnlyFieldProps>;
}

export default function OpportunityPropertyInfoSection({ title, fields }: Props) {
  return (
    <Box
      sx={{
        mt: 3,
      }}
    >
      <Typography variant="h6">{title}</Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        {fields?.map((field, index) => {
          return <ReadOnlyField key={`${field.title}-${index}`} {...field} />;
        })}
      </Box>
    </Box>
  );
}
