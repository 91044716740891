import React from 'react';

import TagsReadOnlyField from './TagsReadOnlyField';
import TextReadOnlyField from './TextReadOnlyField';
import { ReadOnlyFieldType } from './types';

export interface ReadOnlyFieldProps {
  title: string;
  value: string | Array<string>;
  type: ReadOnlyFieldType;
  icon?: React.ReactNode;
  href?: string;
  copyable?: boolean;
  colour?: string;
}

export default function ReadOnlyField({ title, value, type, icon, href, copyable, colour }: ReadOnlyFieldProps) {
  if (type === 'tags' && Array.isArray(value)) {
    return <TagsReadOnlyField title={title} value={value} />;
  }

  const valueString = Array.isArray(value) ? value.join(', ') : value;
  return (
    <TextReadOnlyField
      title={title}
      value={valueString}
      type={type}
      icon={icon}
      href={href}
      copyable={copyable}
      colour={colour}
    />
  );
}
