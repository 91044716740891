import React, { useCallback, useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';

import { Sailing } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';

import { CruisesContract } from '@luxuryescapes/contract-svc-cruise';

import Spinner from '~/components/Common/Spinner';

import cruiseBookingService from '~/services/cruises/BookingInfoService';
import cruiseDepartureService from '~/services/cruises/DepartureService';

import CruiseOrderDetails from './CruiseOrderDetails';
import CruiseOrderFinePrint from './CruiseOrderFinePrint';
import CruiseOrderItemDetails from './CruiseOrderItemDetails';
import { CruiseDepositDetails, CruiseOrderItem } from './constants';

type Props = {
  order: App.Order;
  currencyCode: string;
  hasAllowedRefund: boolean;
  cruiseItems: Array<CruiseOrderItem>;
  showRefundModal: ({ itemId }) => void;
  depositDetails?: CruiseDepositDetails;
};

export default function CruiseOrderListDetails(props: Props) {
  const { cruiseItems, showRefundModal, hasAllowedRefund, order } = props;
  const [loadingDeparture, setLoadingDeparture] = useState(false);
  const [loadingBookingInfo, setLoadingBookingInfo] = useState<Record<string, boolean>>({});

  const { enqueueSnackbar } = useSnackbar();

  const firstCruiseItem = cruiseItems[0];

  const [departure, setDeparture] = useState<CruisesContract.DepartureByIdResponse>(null);

  const [bookingsInfoMap, setBookingsInfoMap] = useState<Record<string, CruisesContract.BookingOrderResponse>>({});

  const bookingsInfo = cruiseItems.map((cruiseItem) => {
    return {
      cruiseItem,
      bookingInfo: bookingsInfoMap[cruiseItem.booking_id],
      isLoading: loadingBookingInfo[cruiseItem.booking_id],
    };
  });

  const firstBookingInfo = bookingsInfo[0]?.bookingInfo;

  const getCruiseDepartureById = useCallback(
    async (id: string): Promise<void> => {
      try {
        setLoadingDeparture(true);
        const res = await cruiseDepartureService.getById(id);
        if (!res.result) {
          enqueueSnackbar('No cruise departure found', { variant: 'error' });
          return;
        }
        setDeparture(res.result);
      } catch (error) {
        enqueueSnackbar('Something wrong to find cruise infos', { variant: 'error' });
      } finally {
        setLoadingDeparture(false);
      }
    },
    [enqueueSnackbar],
  );

  const getCruiseBookingById = useCallback(
    async (id: string): Promise<void> => {
      try {
        setLoadingBookingInfo((prev) => ({ ...prev, [id]: true }));
        const res = await cruiseBookingService.getById(id);
        if (!res.result) {
          enqueueSnackbar('No cruise booking found', { variant: 'error' });
          return;
        }
        setBookingsInfoMap((prev) => ({ ...prev, [id]: res.result }));
      } catch (error) {
        enqueueSnackbar('Something wrong to find cruise infos', { variant: 'error' });
      } finally {
        setLoadingBookingInfo((prev) => ({ ...prev, [id]: false }));
      }
    },
    [enqueueSnackbar],
  );

  useEffect(() => {
    cruiseItems.forEach((cruiseItem) => {
      if (
        cruiseItem.booking_id &&
        !loadingBookingInfo[cruiseItem.booking_id] &&
        !bookingsInfoMap[cruiseItem.booking_id]
      ) {
        getCruiseBookingById(cruiseItem.booking_id);
      }
    });
  }, [cruiseItems, loadingBookingInfo, bookingsInfoMap, getCruiseBookingById]);

  useEffect(() => {
    if (firstCruiseItem) {
      getCruiseDepartureById(firstCruiseItem.departure_id);
    }
  }, [firstCruiseItem, getCruiseDepartureById]);

  const hasResult = !!departure && Object.values(bookingsInfoMap).length > 0;
  const isLoading = loadingDeparture || Object.values(loadingBookingInfo).some(Boolean);

  return (
    <Box id="cruises">
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Sailing sx={{ mr: 1 }} />
          <Typography variant="h6">Cruises ({cruiseItems.length})</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {isLoading && <Spinner size={36} />}
          {firstBookingInfo && (
            <CruiseOrderDetails
              cruiseItem={firstCruiseItem}
              bookingInfo={firstBookingInfo}
              orderTotal={order.total || 0}
              departure={departure}
              depositDetails={props.depositDetails}
            />
          )}
          {hasResult &&
            bookingsInfo.map(({ bookingInfo, isLoading, cruiseItem }, index) => (
              <>
                {isLoading && <Spinner size={36} />}
                {!!bookingInfo && (
                  <>
                    <CruiseOrderItemDetails
                      count={index + 1}
                      cruiseItem={cruiseItem}
                      departure={departure}
                      bookingInfo={bookingInfo}
                      currencyCode={props.currencyCode}
                      hasAllowedRefund={hasAllowedRefund}
                      showRefundModal={showRefundModal}
                      order={order}
                    />
                    {!!bookingInfo.rateCodeDetails && (
                      <Box p={3}>
                        <Accordion variant="outlined" defaultExpanded>
                          <AccordionSummary>Fine Print</AccordionSummary>
                          <AccordionDetails>
                            <CruiseOrderFinePrint bookingInfo={bookingInfo} />
                          </AccordionDetails>
                        </Accordion>
                      </Box>
                    )}
                  </>
                )}
              </>
            ))}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
