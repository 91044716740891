import React, { useEffect, useState } from 'react';

import CreditCardIcon from '@mui/icons-material/CreditCard';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import ReservationService from '../../../services/ReservationService';
import Spinner from '../../Common/Spinner';

import Row from './Row';

interface IOrderItem {
  reservation?: {
    id: string;
    has_vcc?: boolean;
  };
}

interface IReservations {
  [key: string]: {
    vcc_reservation: IVCCReservation;
  };
}

interface IVCCReservation {
  card_id: string;
  currency: string;
  deposit_amount: number;
  deposit_percent: number;
  deposit_state: string;
  total: number;
  type_of_pay: string;
}

interface OrderDetailProps {
  items: Array<IOrderItem>;
  reservationsFetched: boolean;
  reservations: IReservations;
}

function isFulfilled<T>(val: PromiseSettledResult<T>): val is PromiseFulfilledResult<T> {
  return val.status === 'fulfilled';
}

function fetchCardsHistory<T>(reservationId: string) {
  return ReservationService.getVirtualCreditCardsHistory(reservationId) as Promise<T>;
}

const columns: Array<GridColDef> = [
  {
    field: 'card_id',
    headerName: 'Card ID',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    display: 'flex',
  },
  {
    field: 'total',
    headerName: 'Total',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    display: 'flex',
  },
  {
    field: 'currency',
    headerName: 'Currency',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    display: 'flex',
  },
  {
    field: 'type_of_pay',
    headerName: 'Type of pay',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    display: 'flex',
  },
  {
    field: 'deposit_amount',
    headerName: 'Deposit Amount',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
  },
  {
    field: 'deposit_percent',
    headerName: 'Deposit Percent',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    display: 'flex',
  },
  {
    field: 'deposit_state',
    headerName: 'Deposit State',
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
    display: 'flex',
  },
];

export interface ICardResponse {
  status: number;
  message: string;
  result: ICard;
}

export interface ICard {
  id: string;
  pan: string;
  expiry: string;
  limit: number;
  validityFrom: string;
  validityTo: string;
  state: string;
  customFields: CustomFields;
}

interface CustomFields {
  bookingNumber: string;
  checkInDate: string;
  reference: number;
  guestName: string;
  supplierEmail: string;
  orderCurrency?: string;
  paymentMethod?: string;
}

function OrderDetailPage({ items, reservationsFetched, reservations }: OrderDetailProps) {
  const [cards, setCards] = useState<Array<ICard>>();
  const [error, setError] = useState<string>();

  useEffect(() => {
    const getVCCPromises = items
      .filter((item) => item.reservation && item.reservation?.has_vcc)
      .map((item) => fetchCardsHistory<ICardResponse>(item.reservation.id).then((response) => response.result));
    Promise.allSettled(getVCCPromises)
      .then((result) => result.filter(isFulfilled).map((i) => i.value))
      .then((data) => setCards(data))
      .catch(() => setError('Could not load VCC history'));
  }, [items]);

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }
  if (!cards || !reservationsFetched) {
    return <Spinner />;
  }
  if (cards.length === 0) {
    return null;
  }

  const vccReservations = [];
  Object.values(reservations)
    .filter((reservation) => reservation && Object.keys(reservation).length > 0)
    .map((reservation) => {
      if (reservation?.vcc_reservation && Object.keys(reservation.vcc_reservation).length > 0) {
        vccReservations.push(reservation.vcc_reservation);
      }
    });

  return (
    <Accordion defaultExpanded sx={{ mt: 2 }} id="vcc-items">
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <CreditCardIcon sx={{ mr: 1 }} />
        <Typography variant="h6">Virtual Credit Cards</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {vccReservations.length > 0 && (
          <DataGrid
            columns={columns}
            rows={vccReservations}
            getRowId={(row) => row.card_id}
            autoHeight
            pageSizeOptions={[]}
            disableColumnMenu
            disableRowSelectionOnClick
            hideFooter
            getRowHeight={() => 'auto'}
          />
        )}
        <TableContainer component={Paper}>
          <Table
            sx={{
              width: '100%',
            }}
            aria-label="collapsible table"
          >
            <TableHead
              sx={{
                backgroundColor: 'grey.200',
              }}
            >
              <TableRow>
                <TableCell size="small" />
                <TableCell size="small">
                  <Typography variant="subtitle1" fontWeight="bold">
                    Pan
                  </Typography>
                </TableCell>
                <TableCell align="left" size="small">
                  <Typography variant="subtitle1" fontWeight="bold">
                    Expiry
                  </Typography>
                </TableCell>
                <TableCell size="small" align="left">
                  <Typography variant="subtitle1" fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>
                    Validity From
                  </Typography>
                </TableCell>
                <TableCell size="small" align="left">
                  <Typography variant="subtitle1" fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>
                    Validity To
                  </Typography>
                </TableCell>
                <TableCell size="small" align="left">
                  <Typography variant="subtitle1" fontWeight="bold">
                    Limit
                  </Typography>
                </TableCell>
                <TableCell size="small" align="left">
                  <Typography variant="subtitle1" fontWeight="bold">
                    Balance
                  </Typography>
                </TableCell>
                <TableCell size="small" align="left">
                  <Typography variant="subtitle1" fontWeight="bold">
                    State
                  </Typography>
                </TableCell>
                <TableCell align="left" size="small">
                  <Typography variant="subtitle1" fontWeight="bold">
                    Attributes
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cards.map((card) => (
                <Row key={card.id} card={card} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
}

export default OrderDetailPage;
