/* src/components/Purchases/OrderDetail/OrderAddonItem.js */
import React from 'react';

import currencyFormatter from 'currency-formatter';
import groupBy from 'lodash/groupBy';
import { Link } from 'react-router-dom';

import { Button, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import PermissionedComponent from '~/components/Common/PermissionedComponent';

import * as CE from '~/consts/experiences';
import { ROLE_ADMIN_USER, ROLE_EMPLOYEE_USER, ROLE_ICS_STORE_TEAM } from '~/consts/roles';

import * as LE from '../../../Experiences/components';
import CheckInStatus from '../CheckInStatus';
import Log from '../OrderItem/Log';

const ucfirst = (str) => str.charAt(0).toUpperCase() + str.slice(1);

interface Props {
  addons: Array<any>;
  disableInteraction: boolean;
  showRefundModal: (params: any) => void;
  count: number;
  checkInStatuses: Array<any>;
}

function AddonItem({ addons, disableInteraction, showRefundModal, count, checkInStatuses }: Props) {
  const addon = addons[0];
  const checkInStatus = checkInStatuses?.find((s) => s?.id === addon.id);
  const disabled = disableInteraction || addon.is_refunded;

  const isOnlineBooking = addon.channel_provider && !addon.channel_offline_booking;

  let bookingNumberText = addon.booking_number;
  let fareTypeText = addon.fare_type;
  let totalCostText = addon.total;
  const luxPlusExclusive = addon.lux_plus_exclusive;

  if (addons.length > 1) {
    const fareTypes = groupBy(addons, (addon) => addon.fare_type);
    fareTypeText = Object.keys(fareTypes)
      .map((k) => fareTypes[k])
      .reduce((text, current) => text + `${current.length} x ${current[0].fare_type}, `, '')
      .replace(/,\s*$/, '');

    bookingNumberText = addons.reduce((text, current) => text + `${current.booking_number}, `, '').replace(/,\s*$/, '');

    totalCostText = addons.reduce((total, current) => total + current.total, 0);
  }

  const status = CE.getStatusByPurchase(addon.status);

  return (
    <Grid container>
      <Grid xs={1} display="flex" justifyContent="center">
        <Typography variant="h4">{count}</Typography>
      </Grid>

      <Grid xs={9}>
        <Stack>
          <Typography variant="h5">{addon.name}</Typography>
          {luxPlusExclusive && (
            <Stack direction="row" gap={1}>
              <Typography fontWeight="bold" color="royalblue">
                LuxPlus Exclusive Addon
              </Typography>
            </Stack>
          )}
          <Stack direction="row" gap={1}>
            <Typography fontWeight="bold">Booking Number:</Typography>
            <Typography>{bookingNumberText}</Typography>
          </Stack>
          <Stack direction="row" gap={1}>
            <Typography fontWeight="bold">External Booking ID:</Typography>
            <Typography>{addon.channel_booking_id}</Typography>
          </Stack>
          <Stack direction="row" gap={1}>
            <Typography fontWeight="bold">Fare type:</Typography>
            <Typography>{fareTypeText}</Typography>
          </Stack>
          <Stack direction="row" gap={1}>
            <Typography fontWeight="bold">Booking details: </Typography>
            <Typography>{addon.channel_booking_start_time_local}</Typography>
          </Stack>
          <Stack direction="row" gap={1}>
            <Typography fontWeight="bold">Instant booking:</Typography>
            <Typography>{isOnlineBooking ? ' Yes' : ' No'}</Typography>
          </Stack>
          <Stack direction="row" gap={1}>
            <Typography fontWeight="bold"> Vendor Id: </Typography>
            <Typography>
              {' '}
              <Link to={`/vendors/${addon.vendor_salesforce_id}`}>{addon.vendor_salesforce_id}</Link>
            </Typography>
          </Stack>
          {addon.channel_provider && (
            <Stack direction="row" gap={1}>
              <Typography fontWeight="bold"> Channel provider:</Typography>
              <Typography>{addon.channel_provider && ucfirst(addon.channel_provider)}</Typography>
            </Stack>
          )}
          <Stack direction="row" gap={1}>
            <Typography fontWeight="bold">Deal Id:</Typography>
            <Typography>{addon.fk_opportunity_salesforce_id}</Typography>
          </Stack>
          <Stack direction="row" gap={1}>
            <Typography fontWeight="bold">Reservation email: </Typography>
            <Typography> {addon.vendor_booking_email}</Typography>
          </Stack>
        </Stack>
      </Grid>

      <Grid xs={2}>
        <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
          <Typography>
            {currencyFormatter.format(totalCostText, {
              code: addon.currency,
            })}
          </Typography>
          <LE.Badge mb="5px" py="8px" px="16px" lineHeight="10px" title={status.title} background={status.color} />
          <PermissionedComponent requiredRoles={[ROLE_ADMIN_USER, ROLE_EMPLOYEE_USER, ROLE_ICS_STORE_TEAM]}>
            {status.title !== 'CANCELLED' && (
              <Button
                onClick={() => {
                  showRefundModal({ itemId: addon.id });
                }}
                variant="contained"
                disabled={disabled}
                color="error"
              >
                Issue refund
              </Button>
            )}
          </PermissionedComponent>

          <CheckInStatus checkInStatus={checkInStatus} />
        </Stack>
      </Grid>

      <Grid mt={2} xs={12}>
        <Log orderId={addon.fk_order_id} itemId={addon.id} />
      </Grid>
    </Grid>
  );
}

export default AddonItem;
