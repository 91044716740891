/* Purchases/GiftCardDetail/Page.js */
import React from 'react';

import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

import { Button, Container, Stack } from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';
import PageSubheader from '~/components/Common/Elements/PageSubheader';
import { selectShowDisputeProcess, toggleDisputeProcess } from '~/components/Purchases/utils/getDisputeProcess';

import { brands } from '../../../consts/brands';
import OrderDetailPayments from '../OrderDetail/OrderDetailPayments';

import ResendConfirmationEmail from './Buttons/ResendConfirmationEmail';
import ItemList from './ItemList';
import OrderMeta from './Meta';

const LE_BRAND = 'luxuryescapes';

interface Props {
  order: App.Order;
  showDisputeProcess: boolean;
}

class GiftCardDetailPage extends React.Component<Props> {
  static contextTypes = {
    user: PropTypes.object,
  };

  render() {
    const { order, showDisputeProcess } = this.props;
    const brand = brands.find((brand) => brand.value === (order.brand ?? LE_BRAND));

    return (
      <Container maxWidth="xl">
        <Helmet>
          <title>Orders | {order.customer_full_name} | Gift Card</title>
        </Helmet>

        <PageHeader title="Order Summary">
          <Stack direction="row" spacing={2} alignItems="center">
            <Button
              variant="text"
              color={showDisputeProcess ? 'primary' : 'inherit'}
              onClick={() => toggleDisputeProcess(order.id)}
            >
              Dispute Highlights
            </Button>
          </Stack>
        </PageHeader>

        <Stack direction="column" spacing={4}>
          <OrderMeta order={order} />

          <div>
            <PageSubheader title={`${brand.title} gift card`}>
              <ResendConfirmationEmail orderId={order.id} />
            </PageSubheader>

            <ItemList order={order} />
          </div>

          <OrderDetailPayments payments={order.payments} />
        </Stack>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  showDisputeProcess: selectShowDisputeProcess(state),
});

export default connect(mapStateToProps)(GiftCardDetailPage);
