import React from 'react';

import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';

import { PropertyStateEnum } from '~/services/AccommodationService';

const ACCOMODATION_PROPERTY_STATE_FILTERS: Array<{ value: PropertyStateEnum; label: string; color: string }> = [
  { value: 'green', label: 'Good to go', color: 'success.main' },
  { value: 'orange', label: 'Almost there', color: 'warning.main' },
  { value: 'grey', label: 'Needs attention', color: 'gray' },
];

interface Props {
  value: PropertyStateEnum | '';
  onChange: (newValue?: PropertyStateEnum | '') => void;
}

export default function AccommodationPropertyStateFilterInput({ value, onChange }: Props) {
  return (
    <FormControl fullWidth>
      <InputLabel id="property-state-label">Property state</InputLabel>
      <Select
        labelId="property-state-label"
        value={value}
        onChange={(e: SelectChangeEvent) => onChange(e.target.value as PropertyStateEnum | '')}
        label="Property state"
      >
        {ACCOMODATION_PROPERTY_STATE_FILTERS.map((option) => (
          <MenuItem key={option.value} value={option.value} selected={value === option.value}>
            <Box sx={{ color: option.color }}>{option.label}</Box>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
