import React, { useEffect, useState } from 'react';

import { Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import ReadOnlyField from '~/components/v2/common/ReadOnlyField/ReadOnlyField';

import { getTermsAndConditions } from '~/services/CartService';
import { formatDateOrdinalWithClock } from '~/services/TimeService';

interface Props {
  orderId: string;
}

export default function TermsAndConditionsInfo({ orderId }: Props) {
  const [termsAndConditions, setTermsAndConditions] = useState<App.TermsAndConditions | undefined>();

  useEffect(() => {
    getTermsAndConditions(orderId)
      .then((results) => {
        if (results && results.length > 0) {
          setTermsAndConditions(results[0]);
        }
      })
      .catch((e) => {
        console.error('Error fetching terms and conditions: ', e);
        setTermsAndConditions(undefined);
      });
  }, [orderId]);

  if (!termsAndConditions?.id) {
    return null;
  }

  return (
    <Stack id="overview" spacing={2}>
      <Stack direction="row" spacing={2} alignItems="center" sx={{ paddingLeft: '8px' }}>
        <Typography variant="body2" fontWeight="bold">
          Terms And Conditions info
        </Typography>
      </Stack>

      <Grid container spacing={2}>
        <Grid md={3}>
          <ReadOnlyField
            title="Status"
            value={termsAndConditions.accepted ? 'Acknowledged' : 'Pending acknowledgement'}
            type="text"
          />
        </Grid>

        <Grid md={3}>
          <ReadOnlyField
            title="Sent At"
            value={formatDateOrdinalWithClock(termsAndConditions.created_at)}
            type="text"
          />
        </Grid>

        {termsAndConditions.accepted && (
          <Grid md={3}>
            <ReadOnlyField
              title="Accepted At"
              value={formatDateOrdinalWithClock(termsAndConditions.accepted_at)}
              type="text"
            />
          </Grid>
        )}

        {termsAndConditions.email_id && (
          <Grid md={3}>
            <ReadOnlyField
              title="View Email"
              href={`/email/render?id=${termsAndConditions.email_id}`}
              type="link"
              value="View Email"
            />
          </Grid>
        )}
      </Grid>
    </Stack>
  );
}
