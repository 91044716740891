import React from 'react';

import { useHistory } from 'react-router';

import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { useCruiseShips } from '~/components/Cruises/context/useCruiseShips';

const columns: Array<GridColDef> = [
  {
    field: 'id',
    headerName: 'Id',
    width: 350,
    display: 'flex',
  },
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
    display: 'flex',
  },
];

export default function ShipList() {
  const { ships, page, total, shipsPerPage, paginate, isLoading } = useCruiseShips();
  const history = useHistory();

  return (
    <DataGrid
      loading={isLoading}
      columns={columns}
      rows={ships ?? []}
      rowCount={total}
      pageSizeOptions={[shipsPerPage]}
      paginationMode="server"
      paginationModel={{ page: page - 1, pageSize: shipsPerPage }}
      onPaginationModelChange={({ page }) => paginate(page + 1)}
      onRowClick={(params) => history.push(`/cruises/ships/${params.row.id}`)}
      autoHeight
      disableColumnFilter
      disableColumnMenu
    />
  );
}
