import React from 'react';

import { Stack, Typography } from '@mui/material';

interface Props {
  label: string;
  value: string;
  boldPrice?: boolean;
  priceType?: 'standard' | 'discount';
}

function PriceSummaryField({ label, value, boldPrice, priceType = 'standard' }: Props) {
  const color = priceType === 'standard' ? 'black' : 'green';
  return (
    <Stack flexGrow={1} direction="row" spacing={1} justifyContent="space-between">
      <Typography>{label}:</Typography>
      <Typography fontWeight={boldPrice ? 'bold' : 'normal'} color={color}>
        {value}
      </Typography>
    </Stack>
  );
}

export default PriceSummaryField;
