import { useCallback, useState } from 'react';

import { useSnackbar } from 'notistack';

import { GetPromoCodeProps, getPromoCodes } from '~/services/PromoService';

type UsePromoCodeProps = {
  initialFilters: GetPromoCodeProps;
};

function usePromoCodes({ initialFilters }: UsePromoCodeProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [promoCodes, setPromoCodes] = useState(null);
  const [total, setTotal] = useState(0);
  const [promoWarnings, setPromoWarnings] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [filters, setFilters] = useState<GetPromoCodeProps>(initialFilters);

  const fetchData = useCallback(
    async (filters) => {
      try {
        setLoading(true);
        const promos = await getPromoCodes(filters);

        setPromoCodes(promos.result);
        setTotal(promos.total);
      } catch (error) {
        enqueueSnackbar('Failed to fetch promo codes: ' + error.message, { variant: 'error' });
      } finally {
        setLoading(false);
      }
    },
    [enqueueSnackbar],
  );

  return { promoCodes, promoWarnings, isLoading, fetchData, filters, setFilters, total };
}

export { usePromoCodes };
