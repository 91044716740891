import React, { useState } from 'react';

import { Helmet } from 'react-helmet';
import Styled from 'styled-components';

import { Button, MenuItem, Select } from '@mui/material';

import UserSearchWidget from '~/components/Common/UserSearchWidget';

import { PATCH_REPLACE_ORDER, PATCH_UPDATE_FRAUD_STATUS } from '~/consts/order';

import ErrorDisplay from '../ErrorDisplay';
import PermissionedComponent from '../PermissionedComponent';

interface OrderFormProps {
  readonly isLoading: boolean;
}

const OrderFormContainer = Styled.div<OrderFormProps>`
    visibility: {(props: OrderFormProps) => (props.isLoading ? 'hidden' : 'visible')}
`;

const SectionTitleContainer = Styled.div`
    margin-top: 5px;
    font-size: 18px;
    font-weight: 500;
`;

const SectionContainer = Styled.div`
    margin-top: 10px;
`;

const ButtonContainer = Styled.div`
    margin-top: 20px;
`;

interface Props {
  order: App.Order;
  isDirty: boolean;
  isUpdating: boolean;
  handleChange: (e, op) => void;
  handleChangePurchaser: (op, purchaserId) => void;
  purchaser: App.User;
  isLoading: boolean;
  tenant: App.Tenant;
  onSave: () => void;
  error;
}

function OrderForm({
  isUpdating,
  purchaser,
  isDirty,
  error,
  isLoading,
  order,
  handleChange,
  handleChangePurchaser,
  onSave,
}: Props) {
  const [utmMedium, setUtmMedium] = useState(order.utm_medium);
  const [fraudStatus, setFraudStatus] = useState(order.fraud_status);
  const [formErrors] = useState<Array<string> | null>([]);
  const haveCustomUtmMedium = order.utm_medium !== 'admin' && order.utm_medium !== 'popup_store';

  const onHandleChangePurchaser = (id, purchaserId) => {
    handleChangePurchaser(PATCH_REPLACE_ORDER, purchaserId);
  };

  const onHandleChangeUtmMedium = (e) => {
    setUtmMedium(e.target.value);
    handleChange(e, PATCH_REPLACE_ORDER);
  };

  const onHandleFraudStatusChange = (e) => {
    setFraudStatus(e.target.value);
    handleChange(e, PATCH_UPDATE_FRAUD_STATUS);
  };

  return (
    <OrderFormContainer isLoading={isLoading}>
      <Helmet>
        <title>Orders | {purchaser.fullName} | Edit Order</title>
      </Helmet>
      <SectionTitleContainer>Sold By: {purchaser && <strong>{purchaser.fullName}</strong>}</SectionTitleContainer>
      <PermissionedComponent>
        <SectionContainer>
          <UserSearchWidget
            id="fk_customer_id"
            label="Name / Email / Customer Support Code / User ID"
            formErrors={formErrors ?? []}
            loading={false}
            onChange={onHandleChangePurchaser}
          />
        </SectionContainer>
        <SectionTitleContainer>UTM Medium</SectionTitleContainer>
        <SectionContainer>
          <Select name="utm_medium" value={utmMedium} onChange={(e) => onHandleChangeUtmMedium(e)}>
            {haveCustomUtmMedium && <MenuItem value={order.utm_medium}>{order.utm_medium}</MenuItem>}
            {/* Spelling is intentional */}
            <MenuItem value="admin">admin</MenuItem>
            <MenuItem value="popup_store">popup_store</MenuItem>
          </Select>
        </SectionContainer>
      </PermissionedComponent>

      {/* Fraud should be allowed for all employees, not just employees */}
      <SectionTitleContainer>Fraud Status</SectionTitleContainer>
      <SectionContainer>
        <Select name="fraudStatus" value={fraudStatus} onChange={(e) => onHandleFraudStatusChange(e)}>
          <MenuItem value="pending_review">PENDING_REVIEW</MenuItem>
          <MenuItem value="not_fraud">NOT_FRAUD</MenuItem>
          <MenuItem value="reviewed_not_fraud">REVIEWED_NOT_FRAUD</MenuItem>
          <MenuItem value="reviewed_fraud">REVIEWED_FRAUD</MenuItem>
        </Select>
      </SectionContainer>

      {error ? <ErrorDisplay message={error.message} /> : null}

      <ButtonContainer className="button-container">
        <Button
          type="submit"
          onClick={isDirty && !isUpdating ? onSave : null}
          variant="contained"
          className={`${isDirty && !isUpdating ? null : 'disabled'}`}
          disabled={!(isDirty && !isUpdating)}
        >
          {isUpdating ? 'Saving...' : 'Save'}
        </Button>
      </ButtonContainer>
    </OrderFormContainer>
  );
}

export default OrderForm;
