import React, { useEffect, useState } from 'react';

import { Stack, TextField, Typography } from '@mui/material';

import { CommissionsConditionTypes, RuleCondition, ruleConditionsToTextMap } from '~/consts/agentHub';

interface Props {
  setCreatedConditions: (condition: RuleCondition, replace?: boolean) => void;
  conditionType: CommissionsConditionTypes;
  existingConditions: RuleCondition['value'];
}

function AgentHubCommissionsTextInput({ setCreatedConditions, conditionType, existingConditions }: Props) {
  const [conditionValue, setConditionValue] = useState<string>(
    existingConditions.map((cond) => cond.value as string).join(',') || '',
  );

  function handleSaveRule() {
    const splitValues = conditionValue.split(',');

    setCreatedConditions(
      {
        type: conditionType,
        value: splitValues.map((value) => {
          const trimmedValue = value.trim();
          return { value: trimmedValue, label: trimmedValue };
        }),
      },
      true,
    );
  }

  useEffect(() => {
    if (conditionValue.length > 0) {
      handleSaveRule();
    }
  }, [conditionValue]);

  return (
    <Stack gap={2}>
      <div>
        <TextField
          fullWidth
          value={conditionValue}
          onChange={(event) => setConditionValue(event.target.value)}
          label={ruleConditionsToTextMap.get(conditionType)}
          placeholder={ruleConditionsToTextMap.get(conditionType)}
          InputProps={{ sx: { borderRadius: 0.5 } }}
        />
        <Typography variant="caption" color="textSecondary">
          You can paste multiple {conditionType} separated by commas
        </Typography>
      </div>
    </Stack>
  );
}

export default AgentHubCommissionsTextInput;
