import React, { useMemo } from 'react';

import { Alert, AlertTitle, Box, Button, SxProps, Theme, Typography } from '@mui/material';
import { DataGridPro, GridAutosizeOptions, GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';

import useHeroPlannerSchedulesList from '~/hooks/customerCommunication/useHeroPlannerSchedulesList';
import useQuery from '~/hooks/useQuery';

import { EmptyArray } from '~/utils/arrayUtils';
import { isRequestRejected, isRequestUnresolved } from '~/utils/requestUtils';

import ScheduleDealDetailsBlock from './ScheduleOfferDetails/ScheduleDealDetailsBlock';
import ScheduleOfferDetailListItem from './ScheduleOfferDetails/ScheduleOfferDetailListItem';
import ScheduleRowActions from './ScheduleRowActions';
import ScheduleStatusChip from './ScheduleStatusChip';
import { SCHEDULE_SEARCH_PARAMS } from './SchedulesFilters';

const defaultRowHeightGetter = () => 'auto' as const;
const defaultDetailPanelHeightGetter = () => 'auto' as const;
const defaultDetailPanelContentGetter = ({ row }) => {
  return (
    <Typography variant="caption" component="pre" py={1} px={2}>
      {JSON.stringify(row, null, 4)}
    </Typography>
  );
};
const dataGridCustomSx: SxProps<Theme> = {
  '.MuiDataGrid-cell': (theme) => ({
    ...theme.typography.body2,
    paddingBlock: 1,
    '&.capitalised': { textTransform: 'capitalize' },
  }),
};

const EMAIL_VIEW_COLUMNS: Array<GridColDef<CustomerCommunication.HeroPlannerSchedulesListItem>> = [
  {
    field: 'Actions',
    width: 80,
    valueGetter: (_value, row) => row.id,
    renderCell: (
      params: GridRenderCellParams<
        CustomerCommunication.HeroPlannerSchedulesListItem,
        CustomerCommunication.HeroPlannerSchedulesListItem['id']
      >,
    ) => <ScheduleRowActions scheduleId={params.value} segment={params.row.segmentId} />,
    display: 'flex',
  },
  {
    field: 'sendDate',
    headerName: 'Send Date',
    width: 120,
    valueFormatter: (value: CustomerCommunication.HeroPlannerSchedulesListItem['sendDate']) =>
      value.format('DD-MM-YYYY'),
    display: 'flex',
  },
  { field: 'countryGroupId', headerName: 'Country Group', width: 120, cellClassName: 'capitalised', display: 'flex' },
  { field: 'countryId', headerName: 'Country', width: 100, cellClassName: 'capitalised', display: 'flex' },
  { field: 'stateId', headerName: 'State', width: 100, cellClassName: 'capitalised', display: 'flex' },
  { field: 'cityId', headerName: 'City', width: 100, cellClassName: 'capitalised', display: 'flex' },
  { field: 'segmentId', headerName: 'Segment', width: 100, cellClassName: 'capitalised', display: 'flex' },
  { field: 'emailSubjectLine', headerName: 'Subject Line', width: 240, display: 'flex' },
  { field: 'emailPreHeader', headerName: 'Pre-Header', width: 150, display: 'flex' },
  {
    field: 'emailStatus',
    headerName: 'Status',
    width: 104,
    renderCell: (
      params: GridRenderCellParams<unknown, CustomerCommunication.HeroPlannerSchedulesListItem['emailStatus']>,
    ) => <ScheduleStatusChip status={params.value} />,
    display: 'flex',
  },
  { field: 'emailBannerId', headerName: 'Banner ID', width: 150, display: 'flex' },
  {
    field: 'emailHeroDeals',
    headerName: 'Hero Deals',
    width: 250,
    renderCell: (
      params: GridRenderCellParams<
        CustomerCommunication.HeroPlannerSchedulesListItem,
        CustomerCommunication.HeroPlannerSchedulesListItem['emailHeroDeals']
      >,
    ) => (
      <div>
        <ol>
          {params.value?.map((offerId) => (
            <ScheduleOfferDetailListItem
              key={offerId}
              countryGroupId={params.row.countryGroupId}
              countryId={params.row.countryId}
              offerId={offerId}
            />
          ))}
        </ol>
      </div>
    ),
    display: 'flex',
  },
];

const PUSH_VIEW_COLUMNS: Array<GridColDef<CustomerCommunication.HeroPlannerSchedulesListItem>> = [
  {
    field: 'Actions',
    width: 80,
    valueGetter: (_value, row: CustomerCommunication.HeroPlannerSchedulesListItem) => row.id,
    renderCell: (
      params: GridRenderCellParams<
        CustomerCommunication.HeroPlannerSchedulesListItem,
        CustomerCommunication.HeroPlannerSchedulesListItem['id']
      >,
    ) => <ScheduleRowActions scheduleId={params.value} segment={params.row.segmentId} />,
    display: 'flex',
  },
  {
    field: 'sendDate',
    headerName: 'Send Date',
    width: 120,
    valueFormatter: (value: CustomerCommunication.HeroPlannerSchedulesListItem['sendDate']) =>
      value.format('DD-MM-YYYY'),
    display: 'flex',
  },
  { field: 'countryGroupId', headerName: 'Country Group', width: 120, cellClassName: 'capitalised', display: 'flex' },
  { field: 'countryId', headerName: 'Country', width: 100, cellClassName: 'capitalised', display: 'flex' },
  { field: 'stateId', headerName: 'State', width: 100, cellClassName: 'capitalised', display: 'flex' },
  { field: 'cityId', headerName: 'City', width: 100, cellClassName: 'capitalised', display: 'flex' },
  { field: 'segmentId', headerName: 'Segment', width: 100, cellClassName: 'capitalised', display: 'flex' },
  { field: 'pushTitle', headerName: 'Title', width: 150, display: 'flex' },
  { field: 'pushSubtitle', headerName: 'Subtitle', width: 150, display: 'flex' },
  { field: 'pushMessage', headerName: 'Message', width: 150, display: 'flex' },
  {
    field: 'pushStatus',
    headerName: 'Status',
    width: 104,
    renderCell: (
      params: GridRenderCellParams<unknown, CustomerCommunication.HeroPlannerSchedulesListItem['pushStatus']>,
    ) => <ScheduleStatusChip status={params.value} />,
    display: 'flex',
  },
  {
    field: 'pushHeroDeal',
    headerName: 'Deal',
    width: 220,
    valueGetter: (_value, row: CustomerCommunication.HeroPlannerSchedulesListItem) => row.emailHeroDeals[0],
    renderCell: (params: GridRenderCellParams<CustomerCommunication.HeroPlannerSchedulesListItem, string>) => (
      <ScheduleDealDetailsBlock
        countryGroupId={params.row.countryGroupId}
        countryId={params.row.countryId}
        offerId={params.value}
      />
    ),
    display: 'flex',
  },
  { field: 'pushImageId', headerName: 'Image ID', width: 150, display: 'flex' },
];

const DEALS_VIEW_COLUMNS: Array<GridColDef<CustomerCommunication.HeroPlannerSchedulesListItem>> = [
  {
    field: 'Actions',
    width: 80,
    valueGetter: (_value, row: CustomerCommunication.HeroPlannerSchedulesListItem) => row.id,
    renderCell: (
      params: GridRenderCellParams<
        CustomerCommunication.HeroPlannerSchedulesListItem,
        CustomerCommunication.HeroPlannerSchedulesListItem['id']
      >,
    ) => <ScheduleRowActions scheduleId={params.value} segment={params.row.segmentId} />,
    display: 'flex',
  },
  {
    field: 'sendDate',
    headerName: 'Send Date',
    width: 120,
    valueFormatter: (value: CustomerCommunication.HeroPlannerSchedulesListItem['sendDate']) =>
      value.format('DD-MM-YYYY'),
    display: 'flex',
  },
  { field: 'countryGroupId', headerName: 'Country Group', width: 120, cellClassName: 'capitalised', display: 'flex' },
  { field: 'countryId', headerName: 'Country', width: 100, cellClassName: 'capitalised', display: 'flex' },
  { field: 'stateId', headerName: 'State', width: 100, cellClassName: 'capitalised', display: 'flex' },
  { field: 'cityId', headerName: 'City', width: 100, cellClassName: 'capitalised', display: 'flex' },
  { field: 'segmentId', headerName: 'Segment', width: 100, cellClassName: 'capitalised', display: 'flex' },
  { field: 'emailSubjectLine', headerName: 'Subject Line', width: 240, display: 'flex' },
  {
    field: 'dealOne',
    headerName: 'Deal 1',
    width: 220,
    valueGetter: (_value, row: CustomerCommunication.HeroPlannerSchedulesListItem) => row.emailHeroDeals[0],
    renderCell: (params: GridRenderCellParams<CustomerCommunication.HeroPlannerSchedulesListItem, string>) => (
      <ScheduleDealDetailsBlock
        countryGroupId={params.row.countryGroupId}
        countryId={params.row.countryId}
        offerId={params.value}
      />
    ),
    display: 'flex',
  },
  {
    field: 'dealTwo',
    headerName: 'Deal 2',
    width: 220,
    valueGetter: (_value, row: CustomerCommunication.HeroPlannerSchedulesListItem) => row.emailHeroDeals[1],
    renderCell: (params: GridRenderCellParams<CustomerCommunication.HeroPlannerSchedulesListItem, string>) => (
      <ScheduleDealDetailsBlock
        countryGroupId={params.row.countryGroupId}
        countryId={params.row.countryId}
        offerId={params.value}
      />
    ),
    display: 'flex',
  },
  {
    field: 'dealThree',
    headerName: 'Deal 3',
    width: 220,
    valueGetter: (_value, row: CustomerCommunication.HeroPlannerSchedulesListItem) => row.emailHeroDeals[2],
    renderCell: (params: GridRenderCellParams<CustomerCommunication.HeroPlannerSchedulesListItem, string>) => (
      <ScheduleDealDetailsBlock
        countryGroupId={params.row.countryGroupId}
        countryId={params.row.countryId}
        offerId={params.value}
      />
    ),
    display: 'flex',
  },
  {
    field: 'dealFour',
    headerName: 'Deal 4',
    width: 220,
    valueGetter: (_value, row: CustomerCommunication.HeroPlannerSchedulesListItem) => row.emailHeroDeals[3],
    renderCell: (params: GridRenderCellParams<CustomerCommunication.HeroPlannerSchedulesListItem, string>) => (
      <ScheduleDealDetailsBlock
        countryGroupId={params.row.countryGroupId}
        countryId={params.row.countryId}
        offerId={params.value}
      />
    ),
    display: 'flex',
  },
];

const AUTOSIZE_OPTIONS: GridAutosizeOptions = {
  columns: ['Actions', 'countryGroupId', 'countryId', 'stateId', 'segmentId'],
};

function SchedulesTable() {
  const searchParams = useQuery();
  const { fromDate, toDate, countryGroupId, countryId, countryStateId, view } = useMemo(() => {
    const parsed = {
      fromDate: searchParams.get(SCHEDULE_SEARCH_PARAMS.FROM_DATE),
      toDate: searchParams.get(SCHEDULE_SEARCH_PARAMS.TO_DATE),
      countryGroupId: searchParams.get(SCHEDULE_SEARCH_PARAMS.COUNTRY_GROUP),
      countryId: searchParams.get(SCHEDULE_SEARCH_PARAMS.COUNTRY),
      countryStateId: searchParams.get(SCHEDULE_SEARCH_PARAMS.COUNTRY_STATE),
      view: searchParams.get(SCHEDULE_SEARCH_PARAMS.VIEW),
    };

    return parsed;
  }, [searchParams]);

  const { schedulesListReq, fetchSchedulesList } = useHeroPlannerSchedulesList(
    fromDate,
    toDate,
    countryGroupId,
    countryId,
    countryStateId,
  );

  const columns = useMemo(() => {
    if (view === 'push') return PUSH_VIEW_COLUMNS;
    if (view === 'deals') return DEALS_VIEW_COLUMNS;
    return EMAIL_VIEW_COLUMNS;
  }, [view]);

  const gridSx = useMemo(() => {
    return { ...dataGridCustomSx } as SxProps<Theme>;
  }, []);

  if (isRequestRejected(schedulesListReq)) {
    return (
      <Alert
        severity="error"
        action={
          <Button size="small" color="inherit" onClick={fetchSchedulesList}>
            Retry
          </Button>
        }
      >
        <AlertTitle>Could not fetch schedules.</AlertTitle>
        {schedulesListReq.error}
      </Alert>
    );
  }

  // TODO: Support for destination schedules push and deals
  let resultRows = schedulesListReq.result ?? EmptyArray;
  if (view === 'push' || view === 'deals') {
    // if segment is DESTINATION, remove rows
    resultRows = resultRows.filter((row) => row.segmentId !== 'DESTINATION');
  }
  // if empty rows, return alert
  if (resultRows.length === 0) {
    return (
      <Alert severity="warning">
        <AlertTitle>No schedules found. Destination schedules are not included in the table.</AlertTitle>
      </Alert>
    );
  }
  return (
    <Box height="calc(100vh - 280px)">
      <DataGridPro
        rowSelection={false}
        rows={resultRows}
        columns={columns}
        autosizeOptions={AUTOSIZE_OPTIONS}
        loading={isRequestUnresolved(schedulesListReq)}
        getRowHeight={defaultRowHeightGetter}
        getDetailPanelContent={defaultDetailPanelContentGetter}
        getDetailPanelHeight={defaultDetailPanelHeightGetter}
        density="compact"
        sx={gridSx}
      />
    </Box>
  );
}

export default SchedulesTable;
