import React, { useEffect, useState } from 'react';

import { Alert, Box } from '@mui/material';

import { getPayments } from '~/services/PaymentsService';

import { Payment } from '~/types/services/payment';

import { PaymentLine } from './PaymentLine';

interface Props {
  order: App.Order;
}

function SingleOrderPayments({ order }: Props) {
  const [payments, setPayments] = useState<Array<Payment>>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        const response = await getPayments(order.id_orders);
        setPayments(response.result);
      } catch (err) {
        setError('Failed to load payments');
      } finally {
        setIsLoading(false);
      }
    };

    fetchPayments();
  }, [order.id_orders]);

  if (isLoading) {
    return <div>Loading payments...</div>;
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ mb: 2 }}>
        {error}
      </Alert>
    );
  }

  const sales = payments.filter((payment) => payment.intent === 'sale');
  const refunds = payments.filter((payment) => payment.intent === 'refund');

  return (
    <Box sx={{ display: 'flex', gap: 4 }}>
      <Box>
        <strong>Payments</strong>
        {sales.length === 0 ? (
          <div>-</div>
        ) : (
          <div>
            {sales.map((payment, index) => (
              <PaymentLine payment={payment} key={index} />
            ))}
          </div>
        )}
      </Box>

      <Box>
        <strong>Refunds</strong>
        {refunds.length === 0 ? (
          <div>-</div>
        ) : (
          <div>
            {refunds.map((payment, index) => (
              <PaymentLine payment={payment} key={index} />
            ))}
          </div>
        )}
      </Box>
    </Box>
  );
}

export { SingleOrderPayments };
