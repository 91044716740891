import React from 'react';

import { Link } from '@mui/material';

import { InternalService } from '~/services/AccommodationService';

interface Props {
  source: InternalService;
  sourceId: string;
}

export default function InternalPropertyLink({ source, sourceId }: Props) {
  if (source === 'reservation') {
    return (
      <Link href={`/vendors/unknown/properties/${sourceId}`} target="_blank">
        {sourceId}
      </Link>
    );
  }
  return (
    <Link href={`/bedbank/properties/${sourceId}`} target="_blank">
      {sourceId}
    </Link>
  );
}
