import {
  definitions as CustomerCommunicationDefinitions,
  paths as CustomerCommunicationPaths,
} from '@luxuryescapes/contract-customer-communication';

import { json_headers, request } from '~/services/common';

import { makeCustomerCommunicationV1URL } from './customerCommunicationRequestUtils';

type CustomSchedulePatchContract =
  CustomerCommunicationPaths['/api/customer-communication/v1/custom-schedules/{scheduleId}']['patch'];
type CustomSectionOfferType = CustomerCommunicationDefinitions['offerSectionSchema'];
type CustomSectionFlightType = CustomerCommunicationDefinitions['flightSectionSchema'];
type CustomSectionBannerType = CustomerCommunicationDefinitions['bannerSectionSchema'];
type CustomSectionDreamArticleType = CustomerCommunicationDefinitions['dreamArticleSectionSchema'];
type CustomSectionDestinationType = CustomerCommunicationDefinitions['destinationSectionSchema'];
type CustomSectionCtaType = CustomerCommunicationDefinitions['ctaSectionSchema'];

function mapEditedExternalCustomSchedule(
  internal: CustomerCommunication.UnsavedCustomSchedule,
): CustomSchedulePatchContract['parameters']['body']['payload'] {
  const pushSchedules = internal.pushSchedules?.map((pushSchedule) => {
    return {
      title: pushSchedule.title,
      subtitle: pushSchedule.subtitle,
      message: pushSchedule.message,
      link: pushSchedule.link,
      imageId: pushSchedule.imageId,
      scheduleType: pushSchedule.scheduleType,
      status: 'PENDING' as const,
    };
  });

  const customSections = [];
  for (const section of internal.customSections) {
    switch (section.type) {
      case 'OFFER': {
        const sectionPayload: CustomSectionOfferType = {
          type: section.type,
          position: section.position,
          heading: section.heading || undefined,
          offerIds: section.offerIds,
        };
        customSections.push(sectionPayload);
        break;
      }
      case 'FLIGHT': {
        const sectionPayload: CustomSectionFlightType = {
          type: section.type,
          position: section.position,
          heading: section.heading || undefined,
          flights: section.flights.map((flight) => {
            return {
              departureCity: flight.departingCity,
              arrivalCity: flight.arrivalCity,
              pricePerPerson: flight.pricePerPerson,
              airlineLogoImageId: flight.imageId,
              flightUrl: flight.url,
              tripType: flight.tripType,
            };
          }),
        };
        customSections.push(sectionPayload);
        break;
      }
      case 'BANNER': {
        const sectionPayload: CustomSectionBannerType = {
          type: section.type,
          position: section.position,
          bannerName: section.bannerName,
          bannerUrl: section.bannerUrl,
          desktopImageId: section.desktopImageId,
          mobileImageId: section.mobileImageId,
        };
        customSections.push(sectionPayload);
        break;
      }
      case 'DREAM_ARTICLE': {
        const sectionPayload: CustomSectionDreamArticleType = {
          type: section.type,
          position: section.position,
          destination: section.destination,
          heading: section.heading,
          subheading: section.subheading,
          articleUrl: section.articleUrl,
          desktopImageId: section.desktopImageId,
          mobileImageId: section.mobileImageId,
        };
        customSections.push(sectionPayload);
        break;
      }
      case 'DESTINATION': {
        const sectionPayload: CustomSectionDestinationType = {
          type: section.type,
          position: section.position,
          heading: section.heading || undefined,
          destinations: section.destinations.map((destination) => {
            return {
              name: destination.name,
              imageId: destination.imageId,
              redirectUrl: destination.redirectUrl,
            };
          }),
        };
        customSections.push(sectionPayload);
        break;
      }
      case 'CALL_TO_ACTION': {
        const sectionPayload: CustomSectionCtaType = {
          type: section.type,
          position: section.position,
          ctaText: section.text,
          ctaUrl: section.url,
        };
        customSections.push(sectionPayload);
        break;
      }
    }
  }

  return {
    identifier: {
      // @ts-expect-error string is fine
      brand: internal.brandId,
      // @ts-expect-error string is fine
      country: internal.countryId,
      // @ts-expect-error string is fine
      countryGroup: internal.countryGroupId,
      // @ts-expect-error string is fine
      state: internal.stateId,
      // @ts-expect-error string is fine
      segment: internal.segmentId,
      // @ts-expect-error string is fine
      city: internal.cityId,
      // @ts-expect-error string is fine
      cadence: internal.cadenceId,
      // @ts-expect-error string is fine
      loyalty: internal.membershipId,
    },
    sendDate: internal.sendDate.format('YYYY-MM-DD'),
    type: 'DESTINATION',
    // @ts-expect-error string is fine
    destination: internal.destination,
    emailSubjectLine: internal.emailSubjectLine,
    emailPreheader: internal.emailPreheader,
    introSection: internal.introSection,
    customSections: customSections,
    status: 'PENDING',
    pushSchedules,
  };
}

async function requestPatchCustomSchedule(
  scheduleId: string,
  unsavedSchedule: CustomerCommunication.UnsavedCustomSchedule,
) {
  if (!scheduleId) {
    throw 'missing scheduleId';
  }
  if (!unsavedSchedule) {
    throw 'missing unsavedSchedule';
  }

  const url = makeCustomerCommunicationV1URL(`/custom-schedules/${scheduleId}`);

  return request(url.toString(), {
    method: 'PATCH',
    credentials: 'include',
    headers: json_headers,
    body: JSON.stringify(mapEditedExternalCustomSchedule(unsavedSchedule)),
  }).catch((error) => {
    throw JSON.stringify(error, null, 2);
  });
}

export default requestPatchCustomSchedule;
