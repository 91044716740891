import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import { Container } from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';

import cruiseLineService, { CruiseLine } from '~/services/cruises/CruiseLineService';

import CruiseLineForm from './components/CruiseLineForm/CruiseLineForm';

const CruiseLineEdit: React.FC = (): JSX.Element => {
  const { cruiseLineId } = useParams<{ cruiseLineId: string }>();
  const [cruiseLine, setCruiseLine] = useState<CruiseLine | null>(null);

  useEffect(() => {
    cruiseLineService.getCruiseLineById(cruiseLineId).then((result) => setCruiseLine(result));
  }, [cruiseLineId]);

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>Edit Cruise line</title>
      </Helmet>

      <PageHeader title="Edit Cruise line" backButton="/cruises/cruise-lines" />

      {cruiseLine && <CruiseLineForm cruiseLine={cruiseLine} />}
    </Container>
  );
};

export default CruiseLineEdit;
