import React from 'react';

import { Inventory2Outlined } from '@mui/icons-material';
import ContactsIcon from '@mui/icons-material/Contacts';
import SellIcon from '@mui/icons-material/Sell';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { Box, IconButton } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';

import Accordion from '~/components/v2/common/Accordion/Accordion';
import ReadOnlyField from '~/components/v2/common/ReadOnlyField/ReadOnlyField';
import Table from '~/components/v2/common/Table/Table';

interface Row {
  id: number;
  dealOptionName: string;
  allocation: string;
  costPrice: string;
  sellPrice: string;
  margin?: string;
}

// TODO: remove these eventually
const columns: Array<GridColDef<Row>> = [
  {
    field: 'dealOptionName',
    headerName: 'Deal Option Name',
    flex: 3,
  },
  {
    field: 'allocation',
    headerName: 'Allocation/day',
  },
  {
    field: 'costPrice',
    headerName: 'Cost Price',
  },
  {
    field: 'sellPrice',
    headerName: 'Sell Price',
  },
  {
    field: 'margin',
    headerName: 'Margin %',
  },
  {
    field: 'inventory',
    headerName: 'Inventory',
    align: 'center',
    headerAlign: 'center',
    type: 'actions',
    renderCell: () => (
      <Box display="flex" justifyContent="center">
        <IconButton>
          <Inventory2Outlined />
        </IconButton>
      </Box>
    ),
  },
];

const rows = [
  {
    id: 1,
    dealOptionName: 'Standard Package',
    allocation: '100/day',
    costPrice: '$249.99',
    sellPrice: '$399.99',
    margin: '9.70%',
  },
  {
    id: 2,
    dealOptionName: 'Premium Bundle',
    allocation: '50/day',
    costPrice: '$499.99',
    sellPrice: '$799.99',
    margin: '9.70%',
  },
  {
    id: 3,
    dealOptionName: 'Basic Plan',
    allocation: '200/day',
    costPrice: '$149.99',
    sellPrice: '$299.99',
    margin: '9.70%',
  },
  {
    id: 4,
    dealOptionName: 'Enterprise Solution',
    allocation: '25/day',
    costPrice: '$999.99',
    sellPrice: '$1,499.99',
    margin: '9.70%',
  },
  {
    id: 5,
    dealOptionName: 'Starter Kit',
    allocation: '150/day',
    costPrice: '$199.99',
    sellPrice: '$349.99',
    margin: '9.70%',
  },
  {
    id: 6,
    dealOptionName: 'Professional Package',
    allocation: '75/day',
    costPrice: '$599.99',
    sellPrice: '$899.99',
    margin: '9.70%',
  },
  {
    id: 7,
    dealOptionName: 'Economy Bundle',
    allocation: '300/day',
    costPrice: '$99.99',
    sellPrice: '$199.99',
    margin: '9.70%',
  },
  {
    id: 8,
    dealOptionName: 'Deluxe Package',
    allocation: '40/day',
    costPrice: '$799.99',
    sellPrice: '$1,299.99',
    margin: '9.70%',
  },
  {
    id: 9,
    dealOptionName: 'Value Pack',
    allocation: '250/day',
    costPrice: '$179.99',
    sellPrice: '$329.99',
    margin: '9.70%',
  },
  {
    id: 10,
    dealOptionName: 'Ultimate Bundle',
    allocation: '30/day',
    costPrice: '$899.99',
    sellPrice: '$1,399.99',
    margin: '9.70%',
  },
  {
    id: 11,
    dealOptionName: 'Basic Plus',
    allocation: '175/day',
    costPrice: '$299.99',
    sellPrice: '$499.99',
    margin: '9.70%',
  },
  {
    id: 12,
    dealOptionName: 'Premium Plus',
    allocation: '60/day',
    costPrice: '$699.99',
    sellPrice: '$999.99',
    margin: '9.70%',
  },
  {
    id: 13,
    dealOptionName: 'Starter Pro',
    allocation: '225/day',
    costPrice: '$249.99',
    sellPrice: '$449.99',
    margin: '9.70%',
  },
  {
    id: 14,
    dealOptionName: 'Enterprise Plus',
    allocation: '20/day',
    costPrice: '$1,299.99',
    sellPrice: '$1,999.99',
    margin: '9.70%',
  },
  {
    id: 15,
    dealOptionName: 'Economy Plus',
    allocation: '275/day',
    costPrice: '$149.99',
    sellPrice: '$279.99',
    margin: '9.70%',
  },
  {
    id: 16,
    dealOptionName: 'Professional Plus',
    allocation: '80/day',
    costPrice: '$649.99',
    sellPrice: '$949.99',
    margin: '9.70%',
  },
  {
    id: 17,
    dealOptionName: 'Value Plus',
    allocation: '200/day',
    costPrice: '$199.99',
    sellPrice: '$379.99',
    margin: '9.70%',
  },
  {
    id: 18,
    dealOptionName: 'Deluxe Plus',
    allocation: '45/day',
    costPrice: '$849.99',
    sellPrice: '$1,349.99',
    margin: '9.70%',
  },
  {
    id: 19,
    dealOptionName: 'Ultimate Plus',
    allocation: '35/day',
    costPrice: '$949.99',
    sellPrice: '$1,449.99',
    margin: '9.70%',
  },
  {
    id: 20,
    dealOptionName: 'Premium Pro',
    allocation: '55/day',
    costPrice: '$749.99',
    sellPrice: '$1,099.99',
    margin: '9.70%',
  },
];

export default function OpportunityInformation() {
  return (
    <>
      <Box id="deal-options">
        <Accordion title="Deal options" icon={<StarOutlineIcon />} columns={1}>
          <Table
            columns={columns}
            rows={rows}
            getRowId={(row) => row.id}
            paginationMode="client"
            dataGridProps={{
              initialState: {
                pagination: {
                  paginationModel: { page: 0, pageSize: 25 },
                },
              },
            }}
          />
        </Accordion>
      </Box>

      <Box id="additional-questions">
        <Accordion title="Additional Questions" icon={<ContactsIcon />}>
          <ReadOnlyField title="Educational Package Status" value="Educational Package for Any Purpose" type="text" />
          <ReadOnlyField title="Educational Package Wording" value="-" type="text" />
          <ReadOnlyField title="Educational Package" value="Yes" type="text" />
          <ReadOnlyField title="Educational Package Used" value="No" type="text" />
          <ReadOnlyField title="Educational Package Comments" value="Hold for Delwin OOH" type="text" />
        </Accordion>
      </Box>

      <Box id="payments">
        <Accordion title="Payments" icon={<SellIcon />}>
          <ReadOnlyField title="Payment method" value="Credit card" type="text" />
          <ReadOnlyField title="Payment milestone" value="1/10/2025" type="text" />
          <ReadOnlyField title="Price Cost Currency" value="AUD - Australian Dollar" type="text" />
        </Accordion>
      </Box>
    </>
  );
}
