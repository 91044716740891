import React from 'react';

import { DoNotDisturb, LocalAtm, PriceChange, Warning } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';

import { PromoToggleConfig, PromoToggleKey } from '~/services/PromoService';

function getPromoToggleIcon(promoToggle?: PromoToggleKey, promoToggleConfig?: PromoToggleConfig) {
  if (!promoToggleConfig) {
    return (
      <Box key={promoToggle} title={`Unknown promo toggle (no promoToggleConfig): "${promoToggle}"`}>
        <Warning />
      </Box>
    );
  }
  const configMatch = promoToggleConfig.find((tpc) => tpc.key == promoToggle);
  if (!configMatch) {
    return (
      <Box
        key={promoToggle}
        title={`Unknown promo toggle (no promoToggleConfig return via promo meta endpoint): "${promoToggle}"`}
      >
        <Warning />
      </Box>
    );
  }
  switch (promoToggle) {
    case 'allow-in-conjuction-with-membership-pricing':
      return (
        <Box key={promoToggle} sx={{ color: 'blue' }} title={configMatch.internalDescription}>
          <PriceChange />
        </Box>
      );
    case 'allow-with-membership-pricing':
      return (
        <Box key={promoToggle} sx={{ color: 'blue' }} title={configMatch.internalDescription}>
          <LocalAtm />
        </Box>
      );
    case 'no-log-amount-check':
      return (
        <Box key={promoToggle} title={configMatch.internalDescription}>
          <DoNotDisturb />
        </Box>
      );
    default:
      return (
        <Box title={`Unknown promo toggle (no promoToggleConfig): "${promoToggle}"`}>
          <Warning />
        </Box>
      );
  }
}

type PromoOrderTableCellProps = {
  promoToggles?: Array<PromoToggleKey>;
  promoToggleInfo?: PromoToggleConfig;
  direction?: 'row' | 'column';
};

function PromoToggleFormatter({ promoToggles, promoToggleInfo, direction = 'row' }: PromoOrderTableCellProps) {
  return (
    <Stack direction={direction}>
      <Typography fontWeight="bold">Promo Toggles:</Typography>
      {promoToggles?.map((toggle) => (
        <Typography key={toggle}>{getPromoToggleIcon(toggle, promoToggleInfo)}</Typography>
      ))}
    </Stack>
  );
}

export { PromoToggleFormatter };
