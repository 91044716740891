import { paths as CustomerCommunicationPaths } from '@luxuryescapes/contract-customer-communication';

import { getDayJs } from '~/services/TimeService';
import { json_headers, request } from '~/services/common';

import { makeCustomerCommunicationV1URL } from './customerCommunicationRequestUtils';

type HeroPlannerSchedulePostContract =
  CustomerCommunicationPaths['/api/customer-communication/v1/todays-escapes-schedule/hero-planner/schedules']['post'];

function mapHeroPlannerSchedule(
  external: HeroPlannerSchedulePostContract['responses']['200']['content']['application/json']['result'],
): CustomerCommunication.HeroPlannerSchedule {
  return {
    id: external.scheduleId,
    sendDate: getDayJs(external.identifiers.sendDate),
    brandId: external.identifiers.brand,
    countryGroupId: external.identifiers.countryGroup,
    countryId: external.identifiers.country,
    stateId: external.identifiers.state,
    cityId: external.identifiers.city,
    cadenceId: external.identifiers.cadence,
    membershipId: external.identifiers.loyalty,

    segmentId: external.identifiers.segment,
    emailSubjectLine: external.data.emailSubjectLine,
    emailPreHeader: external.data.emailPreheader,
    emailStatus: external.data.emailStatus,
    emailBannerId: external.data.emailBannerId,
    emailBannerPosition2Id: external.data.emailBannerPosition2Id,
    emailHeroOfferIds: external.data.emailHeroDeals,
    isApprovedForTpfm: external.data.isApprovedForTpfm,
    pushTitle: external.data.pushTitle,
    pushSubtitle: external.data.pushSubtitle,
    pushMessage: external.data.pushMessage,
    pushLink: external.data.pushLink,
    pushStatus: external.data.pushStatus,
    pushSvcImageId: external.data.pushImageId,
  };
}

function mapNewExternalHeroPlannerSchedule(
  internal: CustomerCommunication.UnsavedHeroPlannerSchedule,
): HeroPlannerSchedulePostContract['parameters']['body']['payload']['schedule'] {
  // map pushSchedules
  const pushSchedules = internal.pushSchedules?.map((pushSchedule) => {
    return {
      title: pushSchedule.title,
      subtitle: pushSchedule.subtitle,
      message: pushSchedule.message,
      link: pushSchedule.link,
      imageId: pushSchedule.imageId,
      scheduleType: pushSchedule.scheduleType,
      status: 'PENDING' as const,
    };
  });
  return {
    identifiers: {
      // @ts-expect-error string is fine
      brand: internal.brandId,
      // @ts-expect-error string is fine
      country: internal.countryId,
      // @ts-expect-error string is fine
      countryGroup: internal.countryGroupId,
      // @ts-expect-error string is fine
      state: internal.stateId,
      // @ts-expect-error string is fine
      segment: internal.segmentId,
      // @ts-expect-error string is fine
      city: internal.cityId,
      // @ts-expect-error string is fine
      cadence: internal.cadenceId,
      // @ts-expect-error string is fine
      loyalty: internal.membershipId,

      sendDate: internal.sendDate.format('YYYY-MM-DD'),
    },
    data: {
      emailSubjectLine: internal.emailSubjectLine,
      emailPreheader: internal.emailPreHeader,
      emailBannerId: internal.emailBannerId?.length >= 1 ? internal.emailBannerId : undefined,
      emailBannerPosition2Id:
        internal.emailBannerPosition2Id?.length >= 1 ? internal.emailBannerPosition2Id : undefined,
      emailHeroDeals: internal.emailHeroOfferIds,
      emailStatus: 'PENDING',
      isApprovedForTpfm: internal.isApprovedForTpfm,

      pushOfferId: internal.emailHeroOfferIds[0],
      pushTitle: internal.pushTitle,
      pushSubtitle: internal.pushSubtitle,
      pushMessage: internal.pushMessage,
      pushLink: internal.pushLink,
      pushImageId: internal.pushSvcImageId,
      pushStatus: 'PENDING',
      pushSchedules,
    },
  };
}

async function requestPostHeroPlannerSchedule(unsavedSchedule: CustomerCommunication.UnsavedHeroPlannerSchedule) {
  if (!unsavedSchedule) throw 'missing unsavedSchedule';

  const url = makeCustomerCommunicationV1URL('/todays-escapes-schedule/hero-planner/schedules');

  return request(url.toString(), {
    method: 'POST',
    credentials: 'include',
    headers: json_headers,
    body: JSON.stringify({ schedule: mapNewExternalHeroPlannerSchedule(unsavedSchedule) }),
  })
    .then((response: HeroPlannerSchedulePostContract['responses']['200']['content']['application/json']) => {
      return mapHeroPlannerSchedule(response.result);
    })
    .catch((error) => {
      throw JSON.stringify(error, null, 2);
    });
}

export default requestPostHeroPlannerSchedule;
