import React, { useCallback, useState } from 'react';

import { useSnackbar } from 'notistack';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router';

import { Box, Container } from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';

import { formatUTCDateDateTimeWidget } from '~/services/TimeService';
import dealPageService from '~/services/cruises/DealPageService';

import DealPageForm from './components/DealPageForm';
import { CruiseDealPageCreation } from './types';

function DealPageCreate() {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [fetching, setFetching] = useState(false);

  const handleSubmit = useCallback(
    async (formValues: CruiseDealPageCreation) => {
      setFetching(true);

      try {
        await dealPageService.create({
          ...formValues,
          startDate: formatUTCDateDateTimeWidget(new Date(formValues.startDate)),
          endDate: formatUTCDateDateTimeWidget(new Date(formValues.endDate)),
        });
        enqueueSnackbar('Deal page created successfully', { variant: 'success' });
        history.push('/cruises/deals');
      } catch (error) {
        enqueueSnackbar(`Error saving deal page: ${error}`, { variant: 'error' });
      } finally {
        setFetching(false);
      }
    },
    [enqueueSnackbar, history],
  );

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>Create New Deal Page</title>
      </Helmet>

      <PageHeader title="Create Deal Page" backButton="/cruises/deals" />

      <Box mt={1}>
        <DealPageForm onSubmit={handleSubmit} isFetching={fetching} />
      </Box>
    </Container>
  );
}

export default DealPageCreate;
