import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { Alert, Box, Container, Link, Tooltip, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { hasLuxPlusSubscriptionStatus } from '~/components/Membership/utils';

import DiscoveryService from '~/services/DiscoveryService';
import { getCustomerMembershipSubscriptions } from '~/services/MembershipService';
import { getPurchases } from '~/services/OrdersService';
import UsersService from '~/services/UsersService';

import Spinner from '../../Common/Spinner';
import ResolveAccountTakeoverInstructions from '../UserDetails/ResolveAccountTakeoverInstructions';
import TenancyWarningMessage from '../UserDetails/TenancyWarningMessage';

import { SingleOrder } from './SingleOrder';
import { countriesMap } from './countriesMap';
import * as utils from './utils';

type Props = RouteComponentProps<{ id_user: string }>;

const ORDERS_PER_PAGE = 50;

export default function UserPage({ match }: Props) {
  const { id_user: userId } = match.params;
  const [isLoading, setLoading] = useState(false);
  const [currentDate, setCurrentDate] = useState<string | null>(null);
  const [user, setUser] = useState<App.User | null>(null);
  const [ipCache, setIpCache] = useState<Record<string, any> | null>(null);
  const [subscriptions, setSubscriptions] = useState<App.MembershipSubscriptions | null>(null);
  const [purchases, setPurchases] = useState<Array<App.Order> | null>(null);
  const [userChanges, setUserChanges] = useState<any>(null);
  const [logins, setLogins] = useState<any>(null);
  const tenant = useSelector((state: App.State) => state.tenant);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const userData = await UsersService.getUser(userId, { brand: tenant.brand });
        const [subscriptionsResponse, purchasesResponse, loginsResponse, userHistoryResponse] = await Promise.all([
          getCustomerMembershipSubscriptions({ customerId: userData.id_member }).catch(() => {
            // Returns 404 for non-members, which triggers an error - but we don't want that.
            return { result: null };
          }),
          getPurchases({
            page: 1,
            per_page: ORDERS_PER_PAGE,
            brand: tenant.brand,
            customer_id: userData.id_member,
          }),
          UsersService.getUserLogins(userId, 100),
          UsersService.getUserHistory(userId),
        ]);

        const loginIps = loginsResponse.map((login) => login.ip_address.replace(/\/32$/, ''));
        const orderIps = purchasesResponse.result.map((order) => order.device_ip);
        const allIps = [...loginIps, ...orderIps];
        const ipLocations = await DiscoveryService.getIpLocations(allIps);
        const ipCache = ipLocations.reduce((acc, ip) => {
          acc[ip.query] = ip;
          return acc;
        }, {});

        setIpCache(ipCache);
        setUser(userData);
        setSubscriptions(subscriptionsResponse);
        setPurchases(purchasesResponse.result);
        setLogins(loginsResponse);
        setUserChanges(userHistoryResponse.history);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userId, tenant.brand]);

  if (isLoading || !user || !subscriptions || !purchases || !logins || !userChanges) {
    return <Spinner />;
  }

  const history = utils.combineHistory(logins, purchases, userChanges);

  const historyColumns: Array<GridColDef> = [
    {
      field: 'created_at',
      headerName: 'Date',
      flex: 1,
      renderCell: (params) => (
        <code>
          <strong>
            {new Date(params.value).toLocaleString('en-AU', {
              year: 'numeric',
              month: 'short',
              day: '2-digit',
            })}
          </strong>
          <br />
          {new Date(params.value).toLocaleString('en-AU', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
          })}
        </code>
      ),
      display: 'flex',
    },
    {
      field: 'relative_date',
      headerName: 'Relative Date',
      flex: 1,
      renderCell: (params) => {
        if (!currentDate) {
          return '';
        }

        const rowDate = new Date(params.row.created_at);
        const selectedDate = new Date(currentDate);

        // Get difference in days
        const diffDays = Math.round((rowDate.getTime() - selectedDate.getTime()) / (1000 * 60 * 60 * 24));

        return (
          <code>
            <strong>{diffDays}d</strong>
          </code>
        );
      },
      display: 'flex',
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 1,
      display: 'flex',
      renderCell: (params) => {
        if (params.row.type === 'order') {
          return (
            <Box>
              {params.row.type}{' '}
              {params.row.object.fraud_status === 'reviewed_fraud' && (
                <div>
                  <Alert severity="error">Fraud</Alert>
                </div>
              )}
            </Box>
          );
        }
        return params.row.type;
      },
    },
    {
      field: 'ip_location',
      headerName: 'IP Location',
      flex: 2,
      renderCell: (params) => {
        let ip;
        if (params.row.type === 'order') {
          ip = params.row.object.device_ip;
        } else if (params.row.type === 'login') {
          ip = params.row.object.ip_address.replace(/\/32$/, '');
        } else {
          return '-';
        }
        const ipInfo = ipCache[ip];
        if (!ipInfo) {
          return ip;
        }
        const { city, country, region, countryCode, query, proxy, hosting, mobile } = ipInfo;
        return (
          <div>
            <Tooltip title={`${city}, ${region}, ${country}`} placement="left">
              <>
                <span>
                  {countriesMap[countryCode]?.flag} {city}
                </span>{' '}
                <a href={`https://ip-api.com/#${query}`} target="_blank" rel="noreferrer">
                  ?
                </a>
              </>
            </Tooltip>{' '}
            <Box ml={3}>
              {proxy && (
                <Tooltip
                  title="The user was using a proxy or VPN. Fraudsters may use these to hide their location."
                  placement="left"
                >
                  <div> 🌎 proxy</div>
                </Tooltip>
              )}
              {/* Most proxys will be a hosting provider, so hosting is redundant info if proxy is true */}
              {hosting && !proxy && (
                <Tooltip
                  title="This was likely from a server, not a personal computer. Fraudsters use these to bulk-run scripts."
                  placement="left"
                >
                  <div> ⚠️ hosting</div>
                </Tooltip>
              )}
              {mobile && (
                <Tooltip title="The internet connection was from a mobile device." placement="left">
                  <div> 📱 mobile</div>
                </Tooltip>
              )}
            </Box>
          </div>
        );
      },
    },
    {
      field: 'object',
      headerName: 'Object',
      renderCell: (params) => {
        const { type, object } = params.row;
        if (type === 'order') {
          return <SingleOrder order={object} />;
        } else if (type === 'login') {
          return (
            <>
              via {object.login_method}
              {'\u00A0'}
              {object.login_method == 'sms-otp' && (
                <Tooltip title="Unless the fraudster recently changed the account phone number, this login is the real user. They logged in with a one-time password that was sent to their own phone number.">
                  <span>🟢</span>
                </Tooltip>
              )}
            </>
          );
        } else if (type === 'user_change') {
          return (
            <Box>
              {object.changed_fields.map((field) => {
                if (field === 'password') {
                  return <strong>Password changed</strong>;
                }
                return (
                  <div key={field}>
                    <strong>{field}</strong> from <em>{object.previous_values[field] || '(blank)'}</em> to{' '}
                    <em>{object.new_values[field] || '(blank)'}</em>
                  </div>
                );
              })}
              <Tooltip title="After accessing an account, fraudsters often change account details to lock the real customer out. Was there also a recent login from a new location? Or a short-lead-time booking?">
                <span>🟠</span>
              </Tooltip>
            </Box>
          );
        }
        return <div>unhandled row type</div>;
      },
      flex: 4,
      display: 'flex',
    },
  ];
  return (
    <Container maxWidth="xl">
      <Box sx={{ mb: 4 }}>
        <Typography variant="h4" gutterBottom>
          {user.fullName}
        </Typography>
        <Typography variant="body1" color="text.secondary" gutterBottom>
          <Link href={`/users/${user.id_member}`}>Back to user</Link> • {user.email} •{' '}
          {hasLuxPlusSubscriptionStatus(subscriptions) ? 'LuxPlus Member' : 'Standard Member'} •{' '}
          {user.shadow_ban_user ? 'Shadowbanned' : 'Not banned'}
        </Typography>
      </Box>
      <TenancyWarningMessage user={user} />
      <ResolveAccountTakeoverInstructions user={user} alwaysShow />
      <DataGrid
        autoHeight
        rows={history || []}
        columns={historyColumns}
        getRowId={(row) => JSON.stringify(row)} // hacky but created_at isn't unique - some rows have the exact same value which confuses the grid, it thinks they're the same row & shows duplicates
        getRowHeight={() => 'auto'}
        loading={isLoading}
        disableColumnMenu
        disableRowSelectionOnClick
        density="compact"
        onRowClick={(params) => {
          setCurrentDate(params.row.created_at);
        }}
        getCellClassName={(params) => {
          if (!currentDate || params.field !== 'relative_date') {
            return '';
          }

          const rowDate = new Date(params.row.created_at);
          const selectedDate = new Date(currentDate);

          // Check if same day
          if (rowDate.toDateString() === selectedDate.toDateString()) {
            return 'MuiDataGrid-cell--same-day';
          }

          // Get difference in days
          const diffDays = Math.abs(Math.floor((rowDate.getTime() - selectedDate.getTime()) / (1000 * 60 * 60 * 24)));

          if (diffDays <= 7) {
            return 'MuiDataGrid-cell--within-a-week';
          }
          if (diffDays <= 14) {
            return 'MuiDataGrid-cell--within-two-weeks';
          }

          return '';
        }}
        sx={{
          '& .MuiDataGrid-cell--same-day': {
            backgroundColor: '#c0e3ff !important',
          },
          '& .MuiDataGrid-cell--within-a-week': {
            backgroundColor: '#d4e8fb !important',
          },
          '& .MuiDataGrid-cell--within-two-weeks': {
            backgroundColor: '#e9f3fb !important',
          },
          '& .MuiDataGrid-row': {
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: '#f5f5f5',
            },
          },
          '& .MuiDataGrid-row:nth-of-type(even)': {
            '& .MuiDataGrid-cell': {
              backgroundColor: '#fafafa',
            },
          },
        }}
      />
    </Container>
  );
}
