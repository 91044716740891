import React, { useState } from 'react';

import { useSnackbar } from 'notistack';

import { Button, Grid, TextField } from '@mui/material';

import { ROLE_PROMO_ADMIN_USER } from '~/consts/roles';

import * as PromoService from '~/services/PromoService';

import DateTimeWidget from '../Common/Elements/DateTimeWidget';
import PageSubheader from '../Common/Elements/PageSubheader';
import PermissionedComponent from '../Common/PermissionedComponent';
import Spinner from '../Common/Spinner';

import PromoChildExport from './PromoChildExport';

type PromoBulkCreateProps = {
  promoId: string | null;
  expires_at: string | null;
  codeName: string;
};

const requiredRoles = [ROLE_PROMO_ADMIN_USER];

function PromoBulkCreate({ promoId, codeName, expires_at }: PromoBulkCreateProps) {
  const { enqueueSnackbar } = useSnackbar();

  const [numberToCreate, setNumberToCreate] = useState('10');
  const [expiresAt, setExpiresAt] = useState(new Date(expires_at));
  const [promoBulkCreatePrefix, setPromoBulkCreatePrefix] = useState(codeName);

  const [bulkCreateNumError, setBulkCreateNumError] = useState(null);

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();

    setLoading(true);
    if (new Date(expires_at) < new Date()) {
      enqueueSnackbar('Cannot bulk create promos for promos that have expired.', {
        variant: 'error',
      });
      return;
    }

    if (bulkCreateNumError !== null) {
      enqueueSnackbar(bulkCreateNumError, {
        variant: 'error',
      });
      return;
    }

    try {
      const bulkCreateResults = await PromoService.bulkCreatePromos({
        id_promo_code: promoId,
        expires_at: expiresAt.toISOString(),
        number_to_create: +numberToCreate,
        promo_prefix: promoBulkCreatePrefix,
      });

      console.warn('====PromoService.bulkCreatePromos');
      console.warn(bulkCreateResults);

      if (bulkCreateResults) {
        if (bulkCreateResults.result.promos.length == numberToCreate) {
          enqueueSnackbar(`Success! Bulk creation complete. Export ready.`, {
            variant: 'success',
          });
        } else {
          enqueueSnackbar(`Created ${bulkCreateResults.result.promos.length} Successfully. Some failed.`, {
            variant: 'success',
          });
        }

        bulkCreateResults.result.failed.forEach((f) => {
          enqueueSnackbar(f.reason || f.message || `Warning, could not create promo`, {
            variant: 'error',
          });
        });
      }
    } catch (err) {
      console.warn('====PromoService.bulkCreatePromos-CATCH');

      enqueueSnackbar(err.message || 'Error! Could not create bulk create promos.', { variant: 'error' });
    } finally {
      setLoading(false);
    }

    console.warn('====PromoService.bulkCreatePromos-END');
  };

  if (promoId == null) {
    return null;
  }

  return (
    <>
      <PageSubheader title="Promo code bulk creation" />
      <form onSubmit={handleSubmit}>
        <Grid container gap={1} mt={2}>
          <Grid item xs={2.5}>
            <TextField
              variant="outlined"
              type="text"
              label="Promo Code Prefix"
              placeholder={codeName}
              value={promoBulkCreatePrefix}
              onChange={(evt) => setPromoBulkCreatePrefix(evt.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={2.5}>
            <TextField
              variant="outlined"
              type="number"
              label="Total to Bulk Create"
              placeholder="1"
              value={numberToCreate}
              fullWidth
              error={!!bulkCreateNumError}
              helperText={bulkCreateNumError}
              onChange={(evt) => {
                const value = parseInt(evt.target.value, 1);
                if (value < 1) {
                  setBulkCreateNumError('Only positive numbers allowed');
                } else if (value <= 1000) {
                  setBulkCreateNumError(null);
                } else {
                  setBulkCreateNumError('Only 1000 allow at one time');
                }
                setNumberToCreate(evt.target.value);
              }}
            />
          </Grid>
          <Grid item xs={2.5}>
            <DateTimeWidget
              label="Expires at"
              value={expiresAt.toISOString()}
              onChange={(val) => setExpiresAt(new Date(val))}
            />
          </Grid>
          <Grid item xs={2.5}>
            {loading ? (
              <Spinner />
            ) : (
              <>
                <PermissionedComponent requiredRoles={requiredRoles}>
                  <Button variant="contained" type="submit" fullWidth>
                    {`Bulk Create ${numberToCreate} Promo Codes`}
                  </Button>
                </PermissionedComponent>
                [{requiredRoles.join(', ')} role required]
              </>
            )}
          </Grid>
        </Grid>
      </form>
      <Grid item xs={3}>
        <PromoChildExport promoId={promoId} codeName={codeName} />
      </Grid>
    </>
  );
}

export default PromoBulkCreate;
