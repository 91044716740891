import { useCallback, useEffect, useState } from 'react';

import { getFlightDeal } from '~/services/FlightsService';

export function useFlightDeal(id: string) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [data, setData] = useState<App.FlightDeal>();

  const fetchFlightDealData = useCallback(async () => {
    setLoading(true);

    try {
      const { result } = await getFlightDeal(id);
      setData(result);
    } catch (error) {
      setError(error);
    }

    setLoading(false);
  }, [id, setData, setLoading, setError]);

  useEffect(() => {
    fetchFlightDealData();
  }, [fetchFlightDealData]);

  return { loading, error, data, revalidate: fetchFlightDealData };
}
