import React from 'react';

import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router';

import { Box, Container, Tab, Tabs } from '@mui/material';

import PageHeader from '~/components/Common/Elements/PageHeader';

import useQuery from '~/hooks/useQuery';

import AtolReports from './AtolReports';
import CreditReservationList from './CreditReservationListContainer';
import FDAPage from './FDA/FDAPage';
import FlightCacheToolPage from './FlightCacheTool';
import FlightsDashboard from './FlightDashboard';
import MerchandisingFlightDeals from './FlightDeals/FlightDeals';
import { FlightForceBundles } from './FlightForceBundles';
import { FlightsCampaings } from './FlightsCampaigns/FlightsCampaigns';
import PendingReservations from './PendingReservations';

const tabsMap = {
  campaigns: {
    label: 'Campaigns',
    component: FlightsCampaings,
  },
  deals: {
    label: 'Deals',
    component: MerchandisingFlightDeals,
  },
  dashboard: {
    label: 'Dashboard',
    component: FlightsDashboard,
  },
  pending: {
    label: 'Pending',
    component: PendingReservations,
  },
  credit: {
    label: 'Credit Reservation',
    component: CreditReservationList,
  },
  'atol-reports': {
    label: 'ATOL Reports',
    component: AtolReports,
  },
  'force-bundles': {
    label: 'Force Bundles',
    component: FlightForceBundles,
  },
  fda: {
    label: 'Flights Configuration',
    component: FDAPage,
  },
  cacheTool: {
    label: 'Caching Tool',
    component: FlightCacheToolPage,
  },
};
const tabsList = Object.keys(tabsMap);

export function FlightsPageContainer() {
  const query = useQuery();
  const history = useHistory();
  const selectedTab = query.get('tab') || 'campaigns';

  const currentTab = tabsMap[selectedTab];
  const title = `Flights / ${currentTab.label}`;

  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <PageHeader title={title} />

      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={selectedTab} onChange={(_, selectedTabKey) => history.push(`/flights?tab=${selectedTabKey}`)}>
          {tabsList.map((tabKey) => (
            <Tab key={tabKey} value={tabKey} label={tabsMap[tabKey].label} />
          ))}
        </Tabs>
      </Box>

      <Box mt={4}>
        <Box component={currentTab.component} />
      </Box>
    </Container>
  );
}

export default FlightsPageContainer;
