import React from 'react';

import ContactsIcon from '@mui/icons-material/Contacts';
import PersonIcon from '@mui/icons-material/Person';
import SellIcon from '@mui/icons-material/Sell';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { Box, Stack } from '@mui/material';

import Accordion from '~/components/v2/common/Accordion/Accordion';
import ReadOnlyField from '~/components/v2/common/ReadOnlyField/ReadOnlyField';

export default function OpportunityInformation() {
  return (
    <Stack direction="column" spacing={2} sx={{ mt: 2 }}>
      <Box>
        <Accordion title="Opportunity Information" icon={<StarOutlineIcon />}>
          <ReadOnlyField title="Campaign/Market Manager" value="Maria Smith" type="text" icon={<PersonIcon />} />
          <ReadOnlyField title="Opportunity Owner" value="Sidonie Moore" type="text" icon={<PersonIcon />} />
          <ReadOnlyField title="Account Name" value="Double Six Luxury Hotel" type="text" />
          <ReadOnlyField title="Opportunity Name" value="Double Six Luxury Hotel" type="text" />
          <ReadOnlyField title="Campaign Type" value="FLASH - Flexi nights" type="text" />
          <ReadOnlyField title="LE Offer ID" value="123456" type="text" />
        </Accordion>
      </Box>

      <Box id="key-contacts">
        <Accordion title="Key contacts" icon={<ContactsIcon />}>
          <ReadOnlyField title="Escalation Name" value="Ega" type="text" />
          <ReadOnlyField title="Primary Contact Name" value="Maria Smith" type="text" />
          <ReadOnlyField title="Escalation Email" value="test@example.com" copyable type="text" />
          <ReadOnlyField title="Primary Contact Email" value="test@example.com" copyable type="text" />
          <ReadOnlyField title="Escalation Phone" value="+62123456789" copyable type="text" />
          <ReadOnlyField title="System Manager Name" value="Marilyn Franci" copyable type="text" />
          <ReadOnlyField title="Reservation Email" value="test@example.com" copyable type="text" />
          <ReadOnlyField title="System Manager Email" value="test@example.com" copyable type="text" />
          <ReadOnlyField title="Reservation Phone" value="+62123456789" copyable type="text" />
          <ReadOnlyField title="System Manager Phone" value="+62123456789" copyable type="text" />
        </Accordion>
      </Box>

      <Box id="key-commercial">
        <Accordion title="Key commercial" icon={<SellIcon />}>
          <ReadOnlyField title="Offer tier" value="Luxury Escapes - Tier 1" type="text" />
          <ReadOnlyField title="TripAdvisor Link" value="View link" type="link" href="https://www.tripadvisor.com" />
          <ReadOnlyField title="Performance Override" value="Yes" type="text" />
          <ReadOnlyField title="Refunds Permitted" value="No" type="text" />
          <ReadOnlyField title="Excluded Brands" value={['Brand 1', 'Brand 2', 'Brand 3']} type="tags" />
          <ReadOnlyField title="Excluded Countries" value={['Country 1', 'Country 2', 'Country 3']} type="tags" />
          <ReadOnlyField title="Reason for Excluded Brands" value="Reason for Exclusion" type="text" />
          <ReadOnlyField title="Reason for Excluded Countries" value="Reason for Exclusion" type="text" />
        </Accordion>
      </Box>
    </Stack>
  );
}
