import React from 'react';

import { CheckCircle, Error, HourglassEmpty, Settings } from '@mui/icons-material';
import { Chip } from '@mui/material';

import { Order } from '@luxuryescapes/contract-svc-order';

import { titleCase } from '~/utils/stringUtils';

type Props = {
  orderStatus: Order.Order['status'];
};

export function OrderStatusChip({ orderStatus }: Props) {
  const displayOrderStatus = titleCase(orderStatus.replace(/_/g, ' '));

  switch (orderStatus) {
    case 'completed':
      return <Chip icon={<CheckCircle />} color="success" size="small" label={displayOrderStatus} />;

    case 'pending':
      return <Chip icon={<HourglassEmpty />} color="warning" size="small" label={displayOrderStatus} />;

    case 'processing':
      return <Chip icon={<Settings />} color="warning" size="small" label={displayOrderStatus} />;

    case 'payment_failed':
      return <Chip icon={<Error />} color="error" size="small" label={displayOrderStatus} />;

    default:
      return <Chip size="small" label={displayOrderStatus} />;
  }
}
