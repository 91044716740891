/* eslint-disable @typescript-eslint/no-explicit-any */
import { AIContext } from '~/components/AIGenerator/AIGenerateButton';

import { json_headers, request } from './common';

const getRegionFromContext = (context: any) => {
  return context.region ?? context.country ?? context.countryCode ?? 'AU';
};

const getBaseURIForModel = (model: string) => {
  const baseURI = window.configs.API_HOST + '/api';
  switch (model) {
    case 'cruise':
      return baseURI + '/cruise/v2/content';
    case 'experience':
      return baseURI + `/experiences/ai-content`;
    default:
      return baseURI + `/ai-hotel/content/${model}`;
  }
};

export const generateWithMeta = async (context: AIContext, creativity = 2) => {
  const region = getRegionFromContext(context);
  const { model, field, ...payloadContext } = context;
  const baseURI = getBaseURIForModel(model);
  return request(`${baseURI}/${field}`, {
    method: 'POST',
    headers: json_headers,
    body: JSON.stringify({
      context: payloadContext,
      baseContent: '',
      creativity,
      region,
    }),
  });
};

export const generateWithBaseContent = async (baseContent: string, context: AIContext, creativity = 2) => {
  const region = getRegionFromContext(context);
  const { model, field, ...payloadContext } = context;
  const baseURI = getBaseURIForModel(model);
  return request(`${baseURI}/${field}`, {
    method: 'POST',
    headers: json_headers,
    body: JSON.stringify({
      context: payloadContext,
      baseContent,
      creativity,
      region,
    }),
  });
};
