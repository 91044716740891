import { ROLE_ADMIN_USER } from '~/consts/roles';

import useCurrentUser from '~/hooks/useCurrentUser';

interface Props {
  // a user must have this role
  requiredRoles?: Array<string>;
  children?: any;
}

export default function PermissionedComponent({ requiredRoles, children }: Props) {
  const { user } = useCurrentUser();
  const customRequiredRoles = requiredRoles && requiredRoles.length > 0 ? requiredRoles : [ROLE_ADMIN_USER];
  const userHasAnyRequiredRoles = user && customRequiredRoles.some((role) => user.roles.includes(role));

  if (userHasAnyRequiredRoles) {
    return children ?? null;
  }
  return null;
}
