import React, { useCallback, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

import { Attractions } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';

import ErrorDisplay from '~/components/Common/ErrorDisplay';
import PermissionedComponent from '~/components/Common/PermissionedComponent';
import Spinner from '~/components/Common/Spinner';

import { EXP_PROVIDER_DERBYSOFT_PREFIX, EXP_PROVIDER_REZDY_PREFIX, PROVIDER_OPTIONS } from '~/consts/experiences';
import { OPERATOR_CANCELLATION } from '~/consts/refund';
import { ROLE_ADMIN_USER, ROLE_EMPLOYEE_USER, ROLE_ICS_STORE_TEAM } from '~/consts/roles';

import {
  cancelOrder,
  getExperienceRefund,
  getOrderRefundInfo,
  resendConfirmationEmail,
} from '~/services/OrdersService';

import generateTransactionKey from '~/utils/generateTransactionKey';

import List from './list';

interface ExperiencesListProps {
  hasAllowedRefund: boolean;
  showRefundModal: (params: { itemId?: string }) => void;
  order: App.Order;
  refreshData: () => void;
}

const defaultRequestState = { loading: false, error: null };

export default function ExperiencesList({
  hasAllowedRefund,
  showRefundModal,
  order,
  refreshData,
}: ExperiencesListProps) {
  const tenant = useSelector((state: App.State) => state.tenant);

  const { enqueueSnackbar } = useSnackbar();

  const [refundMessage, setRefundMessage] = useState(null);
  const [refundModalOpen, setRefundModalOpen] = useState(false);
  const [experienceItem, setExperienceItem] = useState(null);
  const [resendExperiencesLoading, setResendExperiencesLoading] = useState(false);
  const [refundDataRequest, setRefundDataRequest] = useState(defaultRequestState);
  const [refundAllItemsRequest, setRefundAllItemsRequest] = useState(defaultRequestState);

  const onResendExperienceConfirmationEmail = useCallback(
    async (event) => {
      event.stopPropagation();
      setResendExperiencesLoading(true);

      const orderId = !order.items.length ? order.experience_items[0].fk_order_id : order.id_orders;
      // to send offerId for standalone purchases
      const offerId = !order.items.length ? order.experience_items[0].provider_offer_id : undefined;

      try {
        await resendConfirmationEmail(orderId, offerId);
        enqueueSnackbar('Confirmation Email resented', { variant: 'success' });
      } catch (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      } finally {
        setResendExperiencesLoading(false);
      }
    },
    [enqueueSnackbar, order],
  );

  const canResendExperienceEmail = order.experience_items.some((item) => item.status !== 'cancelled');
  const allItemsCancelled = !order.experience_items.some((item) => item.status !== 'cancelled');
  const hasOnlyRezdyItems = !order.experience_items.some((item) => item.provider !== EXP_PROVIDER_REZDY_PREFIX);
  const hasOnlyDerbysoftItems = !order.experience_items.some((item) => item.provider !== EXP_PROVIDER_DERBYSOFT_PREFIX);
  const onlyRefundAllOrderItems = hasAllowedRefund && (hasOnlyRezdyItems || hasOnlyDerbysoftItems);
  const showRefundAllOrderItemsButton =
    onlyRefundAllOrderItems &&
    !order.experience_items.some((item) => item.provider_offer_id !== order.experience_items[0].provider_offer_id);

  const handleCloseExperienceRefundModal = () => setRefundModalOpen(false);

  const showRefundAllProviderItemsModal = (experienceItem = null) => {
    const item = experienceItem
      ? order.experience_items.find((item) => item.provider_offer_id === experienceItem.provider_offer_id)
      : order.experience_items[0];
    const provider = PROVIDER_OPTIONS.find((provider) => provider.value === item.provider);

    const modalMessage = (
      <>
        <Typography color="red" fontWeight="bold">
          All {provider?.name} tickets for the offer <i>{item.title}</i> will be cancelled
        </Typography>
        This action will respect the cancellation policy of the provider.
        <br />
        {item?.cancellation_policies?.refundPolicies?.length ? (
          <>
            Refund policies:
            <br />
            {item.cancellation_policies.refundPolicies.map((policy, index) => (
              <p key={index}>{policy.periodLabel}</p>
            ))}
          </>
        ) : null}
      </>
    );
    setRefundMessage(modalMessage);
    setRefundModalOpen(true);
  };

  const showRefundItemModal = async (experienceItem) => {
    setRefundModalOpen(true);
    setRefundDataRequest((prev) => ({ ...prev, loading: true }));

    let refundData;

    try {
      refundData = await getExperienceRefund(experienceItem.id);
    } catch (error) {
      setRefundDataRequest({ error: error?.message, loading: false });
    } finally {
      setRefundDataRequest((prev) => ({ ...prev, loading: false }));
    }

    const provider = PROVIDER_OPTIONS.find((provider) => provider.value === experienceItem?.provider);

    let amount;
    let modalMessage;

    if (refundData) {
      amount = refundData?.result[experienceItem.id].refundAmount ?? 0;
      modalMessage = (
        <>
          {hasOnlyDerbysoftItems && (
            <Typography color="red" fontWeight="bold">
              Warning: All tickets for the offer <i>{experienceItem.title}</i> will be cancelled
            </Typography>
          )}
          The maximum refundable amount for this booking at this moment is ${amount}. This amount is calculated in
          real-time using vendor’s cancellation policy from {`${provider.name}`} provider.
          <br />
          <b>You'll have the option to override this amount and refund an amount of your choice on the next step.</b>
        </>
      );
    } else {
      amount = experienceItem.total;
      modalMessage = (
        <>
          Impossible to determine maximum refund amount from refund policy. Agent to make a final decision on the amount
          to be refunded.
        </>
      );
    }
    setRefundMessage(modalMessage);
  };

  const showRefundExperienceModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, experienceItem = null) => {
    e.stopPropagation();
    setExperienceItem(experienceItem);
    setRefundDataRequest(defaultRequestState);
    setRefundAllItemsRequest(defaultRequestState);

    if (onlyRefundAllOrderItems) {
      showRefundAllProviderItemsModal(experienceItem);
    } else {
      showRefundItemModal(experienceItem);
    }
  };

  const refundAllOrderItems = async (experienceItem = null) => {
    setRefundAllItemsRequest((prev) => ({ ...prev, loading: true }));
    let orderItems;

    if (experienceItem?.provider === EXP_PROVIDER_REZDY_PREFIX) {
      // for rezdy items, we need to refund all items for the same offer id
      orderItems = order.experience_items.filter((item) => item.provider_offer_id === experienceItem.provider_offer_id);
    } else if (experienceItem?.provider === EXP_PROVIDER_DERBYSOFT_PREFIX) {
      // for derbysoft items, we need to refund all items from that provider
      orderItems = order.experience_items.filter((item) => item.provider === EXP_PROVIDER_DERBYSOFT_PREFIX);
    } else {
      orderItems = order.experience_items;
    }

    try {
      await Promise.all(
        orderItems.map(async (item) => {
          const refundData = await getOrderRefundInfo({
            orderId: item.fk_order_id,
            itemId: item.id,
          });

          await cancelOrder(
            item.fk_order_id,
            item.id,
            buildItemToRefund({
              ...item,
              refundFee: refundData.item_metadata.refund_fee,
              amount: refundData.item_metadata.cash_amount,
            }),
          );
        }),
      );
      refreshData();
    } catch (error) {
      setRefundAllItemsRequest({ error: error?.message, loading: false });
    } finally {
      setRefundAllItemsRequest((prev) => ({ ...prev, loading: false }));
    }
  };

  const awareWithExperienceRefundConditions = () => {
    if (!experienceItem || onlyRefundAllOrderItems) {
      // TODO move all king of refund to the refund modal
      refundAllOrderItems(experienceItem);
    } else {
      handleCloseExperienceRefundModal();
      showRefundModal({ itemId: experienceItem.id });
    }
  };

  const hasNonRefundableDerbysoftItem = order.experience_items.some(
    (item) =>
      item.provider === EXP_PROVIDER_DERBYSOFT_PREFIX &&
      (item.ticket.fareType?.includes('Lightning Lane') || item.ticket.fareType?.includes('1 Day')),
  );

  return (
    <Box mt={2} id="experiences">
      <Accordion defaultExpanded id="experiences">
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
            <Box display="flex" alignItems="center">
              <Attractions sx={{ mr: 1 }} />
              <Typography variant="h6">Experiences ({order.experience_items.length})</Typography>
            </Box>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
              <PermissionedComponent requiredRoles={[ROLE_ADMIN_USER, ROLE_EMPLOYEE_USER, ROLE_ICS_STORE_TEAM]}>
                {showRefundAllOrderItemsButton && !hasNonRefundableDerbysoftItem && !allItemsCancelled && (
                  <Button variant="text" size="small" onClick={showRefundExperienceModal}>
                    Issue Refund
                  </Button>
                )}
                {hasNonRefundableDerbysoftItem && (
                  <Tooltip
                    title="Unfortunately, the experience is non refundable due to our partner's policy."
                    placement="top"
                    arrow
                  >
                    <Button variant="text" size="small">
                      Non-Refundable Order
                    </Button>
                  </Tooltip>
                )}
                {canResendExperienceEmail && (
                  <Button
                    disabled={resendExperiencesLoading}
                    variant="text"
                    size="small"
                    onClick={onResendExperienceConfirmationEmail}
                    startIcon={resendExperiencesLoading ? <CircularProgress size={24} /> : null}
                  >
                    Re-send confirmation email
                  </Button>
                )}
              </PermissionedComponent>
            </Stack>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <List
            tenant={tenant}
            experiences={order.experience_items}
            initialCount={order.addon_items.length + 1}
            hasAllowedRefund={showRefundAllOrderItemsButton ? false : hasAllowedRefund}
            showRefundModal={showRefundExperienceModal}
            orderBrand={order.brand}
            accommodationItems={order.accommodation_items}
          />

          <Dialog open={refundModalOpen} onClose={handleCloseExperienceRefundModal}>
            <DialogTitle>Experience Refund Warning</DialogTitle>

            {(refundDataRequest.loading || refundAllItemsRequest.loading) && <Spinner />}
            {(refundDataRequest.error || refundAllItemsRequest.error) && (
              <>
                <DialogContent>
                  <ErrorDisplay message={refundDataRequest.error || refundAllItemsRequest.error} />
                </DialogContent>

                <DialogActions>
                  <Button variant="text" onClick={handleCloseExperienceRefundModal}>
                    Close
                  </Button>
                </DialogActions>
              </>
            )}

            {refundMessage &&
              !refundDataRequest.loading &&
              !refundAllItemsRequest.loading &&
              !refundDataRequest.error &&
              !refundAllItemsRequest.error && (
                <>
                  <DialogContent>
                    <DialogContentText>{refundMessage}</DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button variant="text" onClick={handleCloseExperienceRefundModal}>
                      Close
                    </Button>
                    <Button variant="contained" onClick={awareWithExperienceRefundConditions}>
                      I'm aware
                    </Button>
                  </DialogActions>
                </>
              )}
          </Dialog>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

const buildItemToRefund = (item) => ({
  transaction_key: generateTransactionKey(),
  reason: OPERATOR_CANCELLATION,
  refund_method: 'Back To Original',
  amount: item.amount,
  comment: 'Admin refund',
  ticket_id: 'N/A',
  currency_code: item.currency,
  mark_cancelled: true,
  send_refund_notification: false,
  accounting_metadata: [
    {
      source: 'Default',
      cash_amount: item.amount,
      accounting_amount: item.total,
      charge_component_key: item.id,
      refund_fee: item.refundFee,
    },
  ],
});
