import { useCallback, useEffect, useState } from 'react';

import { getFlightTraces } from '~/services/FlightsService';

export function useFlightTraces(reservationId: string) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [data, setData] = useState<Array<App.FlightTraces>>([]);

  const fetchData = useCallback(async () => {
    setLoading(true);

    try {
      const { result } = await getFlightTraces(reservationId);
      setData(result);
    } catch (error) {
      setError(error);
    }

    setLoading(false);
  }, [setLoading, setData, setError, reservationId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { loading, error, data, revalidate: fetchData };
}
