import React, { useState } from 'react';

import { Alert, Button, Dialog, DialogContent, Stack, TextField } from '@mui/material';

import PermissionedComponent from '~/components/Common/PermissionedComponent';

import { ROLE_ADMIN_USER, ROLE_EMPLOYEE_USER, ROLE_EXPERIENCES_COORDINATOR } from '~/consts/roles';

import UsersService from '~/services/UsersService';

type Props = {
  user: App.User;
  tenant: App.Tenant;
};

export const ALLOWED_BRANDS = ['luxuryescapes', 'leagenthub'];

export default function SpoofUserBlock({ user, tenant }: Props) {
  const [spoofUrl, setSpoofUrl] = useState<string | null>(null);
  const [forStore, setForStore] = useState(false);
  const [isProcessing, setProcessing] = useState(false);

  const flushSpoofingState = () => {
    setSpoofUrl(null);
    setForStore(false);
  };

  const handleSpoofUser = async () => {
    try {
      setProcessing(true);

      const spoofUrl = await UsersService.spoofUser(user.id_member, tenant);

      navigator.clipboard.writeText(spoofUrl);

      setSpoofUrl(spoofUrl);
      setForStore(false);
    } finally {
      setProcessing(false);
    }
  };

  const handleSpoofUserStore = async () => {
    try {
      setProcessing(true);

      const spoofUrl = await UsersService.spoofUserStore(user.id_member, tenant);

      navigator.clipboard.writeText(spoofUrl);

      setSpoofUrl(spoofUrl);
      setForStore(true);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <>
      <Stack direction="row" spacing={2}>
        <PermissionedComponent requiredRoles={[ROLE_ADMIN_USER, ROLE_EMPLOYEE_USER, ROLE_EXPERIENCES_COORDINATOR]}>
          <Button variant="contained" color="success" onClick={handleSpoofUser} disabled={isProcessing}>
            Call centre link
          </Button>
        </PermissionedComponent>

        {ALLOWED_BRANDS.includes(tenant.brand) && (
          <Button variant="contained" color="success" onClick={handleSpoofUserStore} disabled={isProcessing}>
            Spoof Store link
          </Button>
        )}
      </Stack>
      {spoofUrl && (
        <Dialog open onClose={flushSpoofingState}>
          <DialogContent>
            <Stack direction="column" spacing={2} sx={{ my: 2 }}>
              <Alert severity="warning" onClose={flushSpoofingState}>
                Extreme caution, paste into incognito only
              </Alert>
              <Alert severity="info" onClose={flushSpoofingState}>
                Your link has been copied to the clipboard!
              </Alert>
              <TextField
                InputLabelProps={{ shrink: true }}
                label={(forStore ? 'Store mode spoofing' : 'Spoofing') + ` link for ${user.email}`}
                defaultValue={spoofUrl}
                multiline
                disabled
              />
            </Stack>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}
