import { SET_SHOW_DISPUTE_PROCESS } from '../actions/dispute';

const initialState = {
  showDisputeProcess: false,
};

export default function disputeReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SHOW_DISPUTE_PROCESS:
      return {
        ...state,
        showDisputeProcess: action.payload,
      };
    default:
      return state;
  }
}
