import React from 'react';

import { Link } from 'react-router-dom';

import EditIcon from '@mui/icons-material/Edit';
import { Button, Stack } from '@mui/material';
import { GridDeleteIcon, GridRenderCellParams } from '@mui/x-data-grid';

import ConfirmButton from '~/components/Common/Elements/ConfirmButton';

interface Props {
  row: GridRenderCellParams;
  onDelete: (id: string) => void;
}

export function FlightsCampaignsTableActionButtons({ row, onDelete }: Props) {
  return (
    <Stack direction="row" alignItems="center" gap={1} height="100%">
      <Button
        variant="outlined"
        component={Link}
        to={`/flights/campaigns/${row.id}`}
        style={{ minWidth: '40px' }}
        size="small"
      >
        <EditIcon />
      </Button>

      <ConfirmButton
        confirmTitle="Delete record"
        confirmQuestion="Are you sure you want to delete?"
        confirmAnswer="Yes, delete"
        onConfirm={() => onDelete(row.id as string)}
        color="error"
        variant="outlined"
        size="small"
        style={{ minWidth: '40px' }}
      >
        <GridDeleteIcon />
      </ConfirmButton>
    </Stack>
  );
}
