import React, { useCallback, useState } from 'react';

import { Box } from '@mui/material';

import useHeroPlannerCadences from '~/hooks/customerCommunication/useHeroPlannerCadences';
import useHeroPlannerMemberships from '~/hooks/customerCommunication/useHeroPlannerMemberships';

import ScheduleBrandInput from './ScheduleBrandInput';
import ScheduleCityInput from './ScheduleCityInput';
import ScheduleCountryGroupInput from './ScheduleCountryGroupInput';
import ScheduleCountryInput from './ScheduleCountryInput';
import ScheduleSegmentInput from './ScheduleSegmentInput';
import ScheduleStateInput from './ScheduleStateInput';
import ScheduleUserProfileInput from './ScheduleUserProfileInput';

interface Props {
  brandIdInputName: string;
  brandIdValue: string;
  onBrandChange: (brand: string) => void;

  countryGroupIdInputName: string;
  countryGroupIdValue: string;
  onCountryGroupChange: (countryGroup: string) => void;

  countryIdInputName: string;
  countryIdValue: string;
  onCountryChange: (country: string) => void;

  stateIdInputName: string;
  stateIdDefaultValue: string;

  cityIdInputName: string;
  cityIdDefaultValue: string;

  segmentIdInputName: string;
  segmentIdDefaultValue: string;
  onSegmentChange?: (segment: string) => void;

  cadenceIdInputName: string;
  cadenceIdDefaultValue: string;

  membershipIdInputName: string;
  membershipIdDefaultValue: string;
}

export default function ScheduleUserProfileInputsGroup({
  brandIdValue,
  brandIdInputName,
  onBrandChange,

  countryGroupIdInputName,
  countryGroupIdValue,
  onCountryGroupChange,

  countryIdInputName,
  countryIdValue,
  onCountryChange,

  stateIdInputName,
  stateIdDefaultValue = '',

  cityIdInputName,
  cityIdDefaultValue = '',

  segmentIdInputName,
  segmentIdDefaultValue = '',
  onSegmentChange,

  cadenceIdInputName,
  cadenceIdDefaultValue,

  membershipIdInputName,
  membershipIdDefaultValue,
}: Props) {
  const [stateId, setStateId] = useState<string>(stateIdDefaultValue);
  const [cityId, setCityId] = useState<string>(cityIdDefaultValue);
  const [segmentId, setSegmentId] = useState<string>(segmentIdDefaultValue);
  const [cadenceId, setCadenceId] = useState<string>(cadenceIdDefaultValue);
  const [membershipId, setMembershipId] = useState<string>(membershipIdDefaultValue);

  const handleSegmentChange = useCallback(
    (value: string) => {
      setSegmentId(value);
      onSegmentChange?.(value);
    },
    [onSegmentChange],
  );

  const useGetCadenceData = () =>
    useHeroPlannerCadences(brandIdValue, countryGroupIdValue, countryIdValue, stateId, cityId, segmentId);

  const useGetMembershipData = () =>
    useHeroPlannerMemberships(brandIdValue, countryGroupIdValue, countryIdValue, stateId, cityId, segmentId, cadenceId);

  return (
    <Box display="grid" gap={2} gridTemplateColumns="repeat(auto-fit, 200px)" alignItems="start">
      <ScheduleBrandInput name={brandIdInputName} value={brandIdValue} onChange={onBrandChange} />

      <ScheduleCountryGroupInput
        name={countryGroupIdInputName}
        value={countryGroupIdValue}
        brand={brandIdValue}
        onChange={onCountryGroupChange}
      />
      <ScheduleCountryInput
        countryGroupId={countryGroupIdValue}
        name={countryIdInputName}
        brand={brandIdValue}
        value={countryIdValue}
        onChange={onCountryChange}
      />
      <ScheduleStateInput
        countryId={countryIdValue}
        countryGroupId={countryGroupIdValue}
        brand={brandIdValue}
        name={stateIdInputName}
        value={stateId}
        onChange={setStateId}
      />
      <ScheduleCityInput
        brand={brandIdValue}
        countryId={countryIdValue}
        countryGroupId={countryGroupIdValue}
        stateId={stateId}
        name={cityIdInputName}
        value={cityId}
        onChange={setCityId}
      />

      <ScheduleSegmentInput
        cityId={cityId}
        stateId={stateId}
        countryId={countryIdValue}
        countryGroupId={countryGroupIdValue}
        brand={brandIdValue}
        name={segmentIdInputName}
        value={segmentId}
        onChange={handleSegmentChange}
      />

      {/* cadence */}
      <ScheduleUserProfileInput
        userProfileInputTitle="Cadence"
        userProfileInputId={cadenceId}
        userProfileInputName={cadenceIdInputName}
        onChange={setCadenceId}
        useHeroPlannerInputData={useGetCadenceData}
      />

      {/* membership */}
      <ScheduleUserProfileInput
        userProfileInputTitle="Membership"
        userProfileInputId={membershipId}
        userProfileInputName={membershipIdInputName}
        onChange={setMembershipId}
        useHeroPlannerInputData={useGetMembershipData}
      />
    </Box>
  );
}
