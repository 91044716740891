import React, { useCallback, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useParams } from 'react-router';

import { Cancel, CheckCircle, ContentCopy } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Alert, Box, IconButton, Tooltip } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { useAccommodationServiceFetch } from '~/components/Accommodation/hooks/useAccommodationServiceFetch';
import { useConfirmDialog } from '~/components/Common/Providers/ConfirmDialogProvider';

import { RoomResponse, deleteRoom, listRooms } from '~/services/AccommodationService';

const PAGE_SIZE = 20;

export default function AccommodationPropertyRoomsResultsTable() {
  const { enqueueSnackbar } = useSnackbar();
  const { propertyId } = useParams<{ propertyId: string }>();
  const getRooms = useCallback(() => listRooms({ propertyId }), [propertyId]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteError, setDeleteError] = useState<string | null>(null);
  const showConfirmDialog = useConfirmDialog();

  const { fetchReqState, refetch } = useAccommodationServiceFetch({
    fetchFn: getRooms,
  });

  const handleDeleteRoom = async (roomId: string) => {
    const confirmed = await showConfirmDialog({
      title: 'Delete Room?',
      description: `Are you sure you want to delete this room?`,
    });

    if (!confirmed) {
      return;
    }

    setIsDeleting(true);
    setDeleteError(null);
    try {
      await deleteRoom({ propertyId, roomId });
      refetch();
    } catch (error) {
      setDeleteError(error instanceof Error ? error.message : 'Failed to delete room');
    } finally {
      setIsDeleting(false);
    }
  };

  const handleCopyClick = (id: string) => {
    navigator.clipboard.writeText(id);
    enqueueSnackbar(`Copied to clipboard`, { variant: 'success' });
  };

  const columns: Array<GridColDef<RoomResponse>> = [
    {
      field: 'name',
      headerName: 'Room Name',
      minWidth: 200,
      display: 'flex',
      flex: 1,
      hideable: true,
    },
    {
      field: 'hasActiveLpcPackage',
      headerName: 'Has Active LPC Package',
      minWidth: 200,
      display: 'flex',
      flex: 1,
      hideable: true,
      renderCell: (params) =>
        params.row.hasActiveLpcPackage ? <CheckCircle color="success" /> : <Cancel color="error" />,
    },
    {
      field: 'suppliers',
      headerName: 'External Suppliers',
      minWidth: 200,
      display: 'flex',
      flex: 1,
      hideable: true,
      renderCell: (params) => params.row.suppliers.join(', '),
    },
    {
      field: 'id',
      headerName: 'ID',
      maxWidth: 280,
      minWidth: 240,
      display: 'flex',
      hideable: true,
      renderCell: (params) => (
        <Box>
          {params.row.id}
          <IconButton title="Copy Room ID" onClick={() => handleCopyClick(params.row.id)}>
            <ContentCopy fontSize="small" />
          </IconButton>
        </Box>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Tooltip title="Delete Room">
          <IconButton
            aria-label="delete"
            color="error"
            onClick={() => handleDeleteRoom(params.row.id)}
            disabled={isDeleting}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  return (
    <>
      {fetchReqState.status === 'failed' && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {JSON.stringify(fetchReqState.error)}
        </Alert>
      )}
      {deleteError && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {deleteError}
        </Alert>
      )}
      <DataGrid
        className="accommodation-property-rooms-results-table"
        columns={columns}
        rows={fetchReqState.status === 'succeeded' ? fetchReqState.result : []}
        rowCount={fetchReqState.status === 'succeeded' ? fetchReqState.result.length : undefined}
        getRowHeight={() => 'auto'}
        pageSizeOptions={[PAGE_SIZE]}
        pagination
        loading={fetchReqState.status === 'pending' || isDeleting}
        autoHeight
        disableColumnFilter
        disableDensitySelector
      />
    </>
  );
}
