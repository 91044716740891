import React, { useEffect, useState } from 'react';

import currencyFormatter from 'currency-formatter';
import { Link } from 'react-router-dom';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Stack, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

import { getSvcExperienceSyncData } from '~/services/ExperiencesService';

import { getPackageName } from '~/utils/experienceUtils';

import PageSubheader from '../../Common/Elements/PageSubheader';

interface Props {
  result: App.Offer;
}
export default function ComplimentaryExperiencesPane({ result }: Props) {
  const [groupedComplimentaryExperiences, setGroupedComplimentaryExperiences] = useState<Array<App.Experience>>([]);

  useEffect(() => {
    const fetchExperiences = async () => {
      const complimentaryExperiencePromises = result.packages.map(async (pkg) => {
        const data = await getSvcExperienceSyncData(pkg.id_salesforce_external);
        return {
          pkg,
          result: data,
        };
      });

      const svcComplimentaryExperiencesData = await Promise.all(complimentaryExperiencePromises);

      /*
        There's no direct link between an experience and its package, so we start by linking
        the packages to their experiences. Then, in the reducer, we collect all the package names
        for each experience and add them to the array in the main experience we're keeping.
      */
      const complimentaryExperiencesWithPackageNames = svcComplimentaryExperiencesData
        .flatMap((experience) =>
          experience.result.result.map((item: App.Experience) => ({
            ...item,
            pkg: experience.pkg,
          })),
        )
        .reduce<Array<App.Experience & { package_names: Array<string> }>>((acc, item) => {
          const existingExperience = acc.find((experience) => experience.id === item.id);

          if (existingExperience) {
            existingExperience.package_names.push(getPackageName(item.pkg));
          } else {
            acc.push({
              ...item,
              package_names: [getPackageName(item.pkg)],
            });
          }

          return acc;
        }, []);

      setGroupedComplimentaryExperiences(complimentaryExperiencesWithPackageNames);
    };
    fetchExperiences();
  }, [result.packages]);
  return (
    <Box>
      <PageSubheader title="Complimentary Experiences" />
      {groupedComplimentaryExperiences.map((experience: App.Experience) => {
        return (
          <Accordion key={experience.parentId} variant="outlined">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                color: experience.inactive ? grey[600] : 'inherit',
              }}
            >
              {experience.inactive ? `(INACTIVE) ${experience.title}` : experience.title}
            </AccordionSummary>
            <AccordionDetails>
              <Stack spacing={2}>
                <Grid container>
                  <Grid item sm={3} mr={1}>
                    <Typography component="h6" color={[grey[600]]} sx={{ fontWeight: 500 }}>
                      DISPLAY NAME
                    </Typography>
                    <Typography variant="body1" color={[grey[600]]}>
                      <Link key={experience?.id} to={`/experience/${experience?.id}`} target="_blank">
                        {experience?.title || 'View Experience'}
                      </Link>
                    </Typography>
                  </Grid>
                  <Grid item sm={3}>
                    <Typography component="h6" color={[grey[600]]} sx={{ fontWeight: 500 }}>
                      VENDOR ID
                    </Typography>
                    <Typography variant="body1">
                      <Link to={`/vendors/${experience.vendor.id}`}>{experience.vendor.id}</Link>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs>
                    <Typography component="h6" color={[grey[600]]} sx={{ fontWeight: 500 }}>
                      DESCRIPTION
                    </Typography>
                    <Typography variant="body1" color={[grey[600]]}>
                      {experience.description}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs>
                    <Typography component="h6" color={[grey[600]]} sx={{ fontWeight: 500 }}>
                      COST PRICE
                    </Typography>
                    <Typography variant="body1" color={[grey[600]]}>
                      {experience.currency}{' '}
                      {currencyFormatter.format(experience.pricing.value, {
                        code: experience.currency,
                      })}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs>
                    <Typography component="h6" color={[grey[600]]} sx={{ fontWeight: 500 }}>
                      AVAILABLE WITH PACKAGES
                    </Typography>
                    <Typography variant="body1" color={[grey[600]]}>
                      <ul>
                        {experience.package_names.map((name) => (
                          <li key={`${experience.id_salesforce_external + name}`}>{name}</li>
                        ))}
                      </ul>
                    </Typography>
                  </Grid>
                </Grid>
              </Stack>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
}
