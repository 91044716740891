import React from 'react';

import { Dayjs } from 'dayjs';
import dayjs from '~/timeInit';

import { DatePicker, DatePickerProps } from '@mui/x-date-pickers-pro';

type Props = Omit<DatePickerProps<Dayjs>, 'value' | 'onChange'> & {
  value?: string;
  onChange: (value: string) => void;
  fullWidth?: boolean;
  defaultTimezone?: string;
};

export default function DateWidget({
  value,
  onChange,
  fullWidth,
  defaultTimezone = window.configs.DEFAULT_TIMEZONE,
  ...props
}: Props) {
  const date = value ? (defaultTimezone ? dayjs(value).tz(defaultTimezone) : dayjs(value)) : null;

  return (
    <DatePicker
      slotProps={{ textField: { fullWidth } }}
      format="DD/MM/YYYY"
      {...props}
      value={date}
      onChange={(date) => onChange(date ? date.format('YYYY-MM-DD') : '')}
    />
  );
}
