import React, { forwardRef, useState } from 'react';

import { Paper, Stack, TextField } from '@mui/material';

import { DynamicSectionType } from '../../context/useDynamicSections';

import ScheduleHeaderImageInput, { parseHeaderImageFormData } from './Inputs/ScheduleHeaderImageInput';

export enum SCHEDULE_BANNER_SECTION_FORM_NAMES {
  TITLE = 'banner_section_title',
  ARTICLE_URL = 'banner_section_article_url',
}

interface Props {
  existingBannerName?: string;
  existingBannerUrl?: string;
  existingWebImageId?: string;
  existingMobileImageId?: string;
}

export function parseBannerSectionFormData(formData: FormData, position = -1) {
  const imageData = parseHeaderImageFormData(formData);
  return {
    type: DynamicSectionType.BANNER,
    bannerName: String(formData.get(SCHEDULE_BANNER_SECTION_FORM_NAMES.TITLE)),
    bannerUrl: String(formData.get(SCHEDULE_BANNER_SECTION_FORM_NAMES.ARTICLE_URL)),
    desktopImageId: imageData.webImageId,
    mobileImageId: imageData.mobileImageId,
    position,
  };
}

const ScheduleBannerSectionForm = forwardRef<HTMLFormElement, Props>((props, ref) => {
  const {
    existingBannerName = '',
    existingBannerUrl = '',
    existingWebImageId = '',
    existingMobileImageId = '',
  } = props;

  const [bannerName, setBannerName] = useState(existingBannerName);
  const [bannerUrl, setBannerUrl] = useState(existingBannerUrl);
  const [webImageId, setWebImageId] = useState(existingWebImageId);
  const [mobileImageId, setMobileImageId] = useState(existingMobileImageId);
  const [headerUrl, setHeaderUrl] = useState(''); // This is required by ScheduleHeaderImageInput but not used in banner

  return (
    <Stack component="form" ref={ref} spacing={3}>
      <Paper variant="outlined" sx={{ p: 2 }}>
        <Stack spacing={2}>
          <TextField
            fullWidth
            label="Banner Name"
            name={SCHEDULE_BANNER_SECTION_FORM_NAMES.TITLE}
            value={bannerName}
            onChange={(e) => setBannerName(e.target.value)}
            required
          />

          <TextField
            fullWidth
            label="Banner Redirect URL"
            name={SCHEDULE_BANNER_SECTION_FORM_NAMES.ARTICLE_URL}
            value={bannerUrl}
            onChange={(e) => setBannerUrl(e.target.value)}
            required
          />

          <ScheduleHeaderImageInput
            webImageId={webImageId}
            mobileImageId={mobileImageId}
            headerUrl={headerUrl}
            onWebImageIdChange={setWebImageId}
            onMobileImageIdChange={setMobileImageId}
            onHeaderUrlChange={setHeaderUrl}
            showHeaderUrl={false}
          />
        </Stack>
      </Paper>
    </Stack>
  );
});

ScheduleBannerSectionForm.displayName = 'ScheduleBannerSectionForm';
export default ScheduleBannerSectionForm;
