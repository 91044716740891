import React from 'react';

import { useLocation } from 'react-router';

import { Link, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

export interface SidebarItemProps {
  label: string;
  icon?: React.ReactNode;
  url: string;
}

export default function SidebarItem({ label, icon, url }: SidebarItemProps) {
  const { pathname, hash } = useLocation();
  const isSelected = `${pathname}${hash}` === url;

  return (
    <ListItem
      sx={{
        py: 0,
        px: 0,
      }}
    >
      <ListItemButton
        component={Link}
        href={url}
        selected={isSelected}
        sx={{
          paddingLeft: 0,
        }}
      >
        {icon && (
          <ListItemIcon
            sx={{
              '&.MuiListItemIcon-root': {
                minWidth: '40px',
              },
              color: isSelected ? 'primary.main' : 'text.secondary',
            }}
          >
            {icon}
          </ListItemIcon>
        )}
        <ListItemText primary={label} />
      </ListItemButton>
    </ListItem>
  );
}
