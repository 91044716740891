import { request } from '~/services/common';

import { makeCustomerCommunicationV1URL } from './customerCommunicationRequestUtils';
import requestDeleteCustomSchedule from './requestDeleteCustomSchedule';

async function requestDeleteHeroPlannerSchedule(scheduleId: string, isCustomSchedule?: boolean) {
  if (!scheduleId) {
    throw 'missing scheduleId';
  }

  if (isCustomSchedule) {
    return requestDeleteCustomSchedule(scheduleId);
  }

  const url = makeCustomerCommunicationV1URL(`/todays-escapes-schedule/hero-planner/schedules/${scheduleId}`);

  return request(url.toString(), {
    method: 'DELETE',
    credentials: 'include',
  }).catch((error) => {
    throw JSON.stringify(error, null, 2);
  });
}

export default requestDeleteHeroPlannerSchedule;
