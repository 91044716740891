import React, { useCallback, useState } from 'react';

import { useSnackbar } from 'notistack';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack } from '@mui/material';

import { Spinner } from '~/components/Experiences/components/Spinner';

import { ValidationErrorDetails } from '~/services/AccommodationService';

interface Props {
  open: boolean;
  onClose: () => void;
  title: string;
  description: string;
  overrideDescription: string;
  onConfirm: (validationErrors?: Array<ValidationErrorDetails>) => Promise<void>;
  onComplete: () => void;
}

export default function AccommodationConfirmWithOverrideDialog({
  open,
  onClose,
  title,
  description,
  overrideDescription,
  onConfirm,
  onComplete,
}: Props) {
  const [validationErrors, setValidationErrors] = useState<Array<ValidationErrorDetails>>([]);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleCancel = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleError = useCallback(
    (message: string) => {
      enqueueSnackbar(message, { variant: 'error' });
    },
    [enqueueSnackbar],
  );

  const handleConfirmWithOverride = useCallback(async () => {
    setLoading(true);
    try {
      await onConfirm(validationErrors);
    } catch (error) {
      handleError(error.message);
    } finally {
      onComplete();
      onClose();
      setLoading(false);
    }
  }, [onConfirm, onComplete, onClose, handleError, validationErrors]);

  const handleConfirm = useCallback(async () => {
    setLoading(true);
    try {
      await onConfirm();
      onComplete();
      onClose();
    } catch (error) {
      if (error.status === 409) {
        setValidationErrors(error.errors);
      } else {
        handleError(error.message);
      }
    } finally {
      setLoading(false);
    }
  }, [onConfirm, onComplete, onClose, handleError]);

  if (validationErrors.length > 0) {
    return (
      <Dialog open={open} onClose={handleCancel}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent sx={{ minWidth: '400px' }}>
          <Stack spacing={4}>
            <Stack spacing={2}>
              {validationErrors?.map(({ code, message }) => (
                <DialogContentText key={code}>{message}</DialogContentText>
              ))}
            </Stack>
            <DialogContentText>
              {overrideDescription && <DialogContentText>{overrideDescription}</DialogContentText>}
            </DialogContentText>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button variant="contained" onClick={handleConfirmWithOverride} color="error">
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Dialog open={open} onClose={handleCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ minWidth: '400px' }}>
        <DialogContentText>{description && <DialogContentText>{description}</DialogContentText>}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="text" disabled={loading}>
          Cancel
        </Button>
        <Button onClick={handleConfirm} variant="contained" disabled={loading}>
          {loading ? <Spinner size={15} inline /> : 'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
